import { combineReducers } from 'redux';
import { fromJS, List, Map } from 'immutable';
import unitReducers from '../views/Units/views/Unit/redux/reducers';
import { getApiLoadedReducer, getApiLoadingReducer } from '../../../redux/get-reducers';
import { communityViewsReducers } from './reducers/communityViewsReducers';
import { communityStripeIntegrationReducers } from './reducers/communityStripeIntegrationReducers';
import { SgToursReducers } from './reducers/sgToursReducers';
import { scheduledReducers } from './reducers/scheduledReducers';
import eventReducers from '../views/Community/views/Events/EditEvent/GuestRegistration/reducers/reducers';

const propertyDeviceList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_DEVICES_SUCCESS':
      return fromJS(action.json);
    case 'UPDATE_DEVICE_SUCCESS':
      let list = state;
      if (action.json.battery_level && state.size > 0) {
        list = state.update(
          state.findIndex(item => {
            return item.get('id') === action.json.id;
          }),
          item => {
            return item.set('battery_level', action.json.battery_level);
          }
        );
      }
      return list;
    default:
      return state;
  }
};

const activeProperty = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_SUCCESS':
      // window.ga('set', 'dimension1', action.json.name);
      return fromJS(action.json);
    case 'EDIT_PROPERTY_SETTINGS_SUCCESS':
      return state.merge(action.json);
    case 'CREATE_PROPERTY_MANAGER_SUCCESS':
      return state.set('staff', state.get('staff').push(fromJS(action.json)));
    case 'EDIT_PROPERTY_MANAGER_SUCCESS': {
      const index = state.get('staff').findIndex(pm => pm.getIn(['user', 'id']) === action.json.user.id);
      return state.setIn(['staff', index], fromJS(action.json));
    }
    case 'REMOVE_PROPERTY_MANAGER_SUCCESS':
      return state.set(
        'staff',
        state.get('staff').filter(staffer => staffer.get('id') !== action.propertyManagerId)
      );
    case 'CREATE_GROUPS_SUCCESS':
      return state.set('groups', state.get('groups').push(fromJS(action.json)));
    case 'EDIT_GROUPS_SUCCESS':
      const index = state.get('groups').findIndex(group => group.get('id') === action.groupId);
      return state.set('groups', state.get('groups').set(index, fromJS(action.json)));
    case 'REMOVE_GROUPS_SUCCESS':
      return state.set(
        'groups',
        state.get('groups').filter(group => group.get('id') !== action.groupId)
      );
    case 'SAVE_MAINTENANCE_CONFIGURATION_SUCCESS':
      return state.set('maintenance_configuration', fromJS(action.json));
    default:
      return state;
  }
};

const activePropertyLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_SUCCESS':
      return true;
    case 'GET_PROPERTY_REQUEST':
    case 'GET_PROPERTY_RESET':
      return false;
    default:
      return state;
  }
};

const activePropertyError = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_FAILURE':
      return true;
    case 'GET_PROPERTY_REQUEST':
    case 'GET_PROPERTY_RESET':
      return false;
    default:
      return state;
  }
};

const amenityList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_AMENITIES_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_AMENITIES_SUCCESS':
      const newState = state.push(fromJS(action.json));
      return newState.sort((a, b) => a.get('name').localeCompare(b.get('name')));
    case 'EDIT_AMENITIES_SUCCESS':
      const index = state.findIndex(amenity => amenity.get('id') === action.amenityId);
      return state.set(index, fromJS(action.json));
    case 'REMOVE_AMENITIES_SUCCESS':
      return state.filter(amenity => amenity.get('id') !== action.amenityId);
    default:
      return state;
  }
};
const activeAmenity = (state = { data: Map(), loading: true }, action) => {
  switch (action.type) {
    case 'GET_AMENITY_REQUEST':
      return { ...state, loading: true };
    case 'GET_AMENITY_SUCCESS':
      return { ...state, data: fromJS(action.json), loading: false };
    case 'GET_AMENITY_FAILURE':
      return { ...state, loading: false };
    default:
      return state;
  }
};

const vendorsList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_VENDORS_SUCCESS':
      return fromJS(action.json);
    case 'ADD_VENDOR_SUCCESS':
      return state.push(
        fromJS({
          id: action.json.id,
          name: action.json.name,
          email: action.json.emial,
          communityId: action.json.communityId,
        })
      );
    case 'DELETE_VENDOR_SUCCESS':
      const deletedVendorId = action.json.id;
      return state.filter(vendor => vendor.get('id') !== deletedVendorId);
    case 'UPDATE_VENDOR_SUCCESS':
      return state;
    default:
      return state;
  }
};

const groupList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_GROUPS_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_GROUPS_SUCCESS':
      const newState = state.push(fromJS(action.json));
      return newState.sort((a, b) => a.get('name').localeCompare(b.get('name')));
    case 'EDIT_GROUPS_SUCCESS':
      const index = state.findIndex(group => group.get('id') === action.groupId);
      return state.set(index, fromJS(action.json));
    case 'REMOVE_GROUPS_SUCCESS':
      return state.filter(group => group.get('id') !== action.groupId);
    default:
      return state;
  }
};

const postList = (state = fromJS({ results: [], total: null }), action) => {
  switch (action.type) {
    case 'GET_POSTS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const pendingPostList = (state = fromJS({ results: [], total: null }), action) => {
  switch (action.type) {
    case 'GET_PENDING_POSTS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const post = (state = false, action) => {
  switch (action.type) {
    case 'GET_POST_SUCCESS':
      return fromJS(action.json);
    case 'BAN_USER_SUCCESS':
      let banUserState = state;
      if (state.get('author_id') === action.json.banned) {
        banUserState = banUserState.setIn(['author', 'ban'], fromJS(action.json));
      }
      banUserState = banUserState.set(
        'comments',
        banUserState.get('comments').map(comment => {
          if (comment.get('author_id') === action.json.banned) {
            return comment.setIn(['author', 'ban'], fromJS(action.json));
          }
          return comment;
        })
      );
      return banUserState;
    case 'UNBAN_USER_SUCCESS':
      let unbanUserState = state;
      if (state.get('author_id') === action.meta.userId) {
        unbanUserState = unbanUserState.setIn(['author', 'ban'], null);
      }
      unbanUserState = unbanUserState.set(
        'comments',
        unbanUserState.get('comments').map(comment => {
          if (comment.get('author_id') === action.meta.userId) {
            return comment.setIn(['author', 'ban'], null);
          }
          return comment;
        })
      );
      return unbanUserState;
    default:
      return state;
  }
};

const maintenanceConfiguration = (state = null, action) => {
  switch (action.type) {
    case 'GET_MAINTENANCE_CONFIGURATION_SUCCESS':
      return fromJS(action.json.props);
    case 'GET_MAINTENANCE_CONFIGURATION_FAILURE':
      return null;
    case 'GET_MAINTENANCE_CONFIGURATION_PENDING':
      return null;
    default:
      return state;
  }
};

const maintenanceConfigurationLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_MRI_CONFIGURATION_SUCCESS':
      return false;
    case 'GET_MRI_CONFIGURATION_FAILURE':
      return false;
    case 'GET_MRI_CONFIGURATION_PENDING':
      return true;
    default:
      return state;
  }
};

const unitList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_UNITS_SEARCH_SUCCESS':
    case 'GET_UNITS_SUCCESS':
      return fromJS(action.json.results);
    case 'CREATE_UNIT_SUCCESS':
      return state.push(fromJS(action.json));
    case 'GET_UNITS_RESET':
      return List();
    default:
      return state;
  }
};

const unitListSearching = (state = false, action) => {
  switch (action.type) {
    case 'GET_UNITS_SUCCESS':
      return false;
    case 'GET_UNITS_SEARCH_SUCCESS':
      return true;
    default:
      return state;
  }
};

const unitListTotal = (state = 0, action) => {
  switch (action.type) {
    case 'GET_UNITS_SEARCH_SUCCESS':
    case 'GET_UNITS_SUCCESS':
      return fromJS(action.json.total);
    case 'CREATE_UNIT_SUCCESS':
      return state + 1;
    default:
      return state;
  }
};

const residentsListTotal = (state = 0, action) => {
  switch (action.type) {
    case 'GET_UNITS_SEARCH_SUCCESS':
    case 'GET_UNITS_SUCCESS':
      return fromJS(action.json.totalResidents);
    case 'GET_RESIDENTS_SUCCESS':
      return action.json.total || 0;
    default:
      return state;
  }
};

const unitListLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_UNITS_REQUEST':
      return true;
    case 'GET_UNITS_SUCCESS':
    case 'GET_UNITS_FAILURE':
      return false;
    default:
      return state;
  }
};

const unitListLoaded = (state = false, action) => {
  return getApiLoadedReducer('GET_UNITS')(state, action) || getApiLoadedReducer('GET_UNITS_SEARCH')(state, action);
};

const messagesList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_MESSAGES_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_MESSAGE_SUCCESS':
      return state.push(fromJS(action.json));
    case 'PIN_ANNOUNCEMENT_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const messagesListLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_MESSAGES_REQUEST':
      return true;
    case 'GET_MESSAGES_SUCCESS':
    case 'GET_MESSAGES_FAILURE':
      return false;
    default:
      return state;
  }
};

const activeMessageLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_MESSAGE_SUCCESS':
      return true;
    case 'GET_MESSAGE_REQUEST':
    case 'GET_MESSAGE_RESET':
      return false;
    default:
      return state;
  }
};

const activeMessage = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_MESSAGE_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const propertyCameraList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_CAMERAS_SUCCESS':
      return fromJS(action.json.cameras);
    default:
      return state;
  }
};

const communityDevices = (state = List(), action) => {
  switch (action.type) {
    case 'GET_COMMUNITY_DEVICES_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const communityDevicesLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_COMMUNITY_DEVICES_REQUEST':
      return true;
    case 'GET_COMMUNITY_DEVICES_SUCCESS':
    case 'GET_COMMUNITY_DEVICES_FAILURE':
      return false;
    default:
      return state;
  }
};

const mailHubs = (state = List(), action) => {
  switch (action.type) {
    case 'DELETE_MAIL_HUB_SUCCESS':
    case 'GET_MAIL_HUBS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const vacantUnitAutomation = (state = null, action) => {
  switch (action.type) {
    case 'GET_VACANT_UNIT_AUTOMATION_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const blockedResidentsList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_BLOCKED_RESIDENTS_SUCCESS':
      return fromJS(action.json);
    case 'ADD_BLOCKED_RESIDENT_SUCCESS':
      return state.push(fromJS(action.payload));
    case 'UNBLOCK_RESIDENT_SUCCESS':
      return state.filter(blockedResident => blockedResident.get('user_id') !== action.blockedResidentId);
    default:
      return state;
  }
};

const residentsList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_RESIDENTS_SUCCESS':
      return fromJS(action.json.results || action.json);
    case 'GET_RESIDENTS_FAILURE':
      return state;
    case 'ACCEPT_OR_DECLINE_DEVICE_SWITCH_SUCCESS':
      return state.merge(fromJS(action.json));
    default:
      return state;
  }
};

const residentsListLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_RESIDENTS_REQUEST':
      return true;
    case 'GET_RESIDENTS_SUCCESS':
    case 'GET_RESIDENTS_FAILURE':
      return false;
    default:
      return state;
  }
};

const residentsListError = (state = false, action) => {
  switch (action.type) {
    case 'GET_RESIDENTS_REQUEST':
    case 'GET_RESIDENTS_SUCCESS':
      return false;
    case 'GET_RESIDENTS_FAILURE':
    case 'GET_RESIDENTS_ERROR':
      return true;
    default:
      return state;
  }
};

const propertyGuests = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_GUESTS_SUCCESS':
      return fromJS(action.json);
    case 'ADD_PROPERTY_GUEST_SUCCESS':
      return state.push(fromJS(action.json));
    case 'REMOVE_PROPERTY_GUEST_SUCCESS':
      return state.filter(guest => guest.get('id') !== action.guestId);
    case 'EDIT_PROPERTY_GUEST_SUCCESS':
      const indexToUpdate = state.findIndex(guest => guest.get('id') === action.json.id);
      return state.set(indexToUpdate, fromJS(action.json));
    case 'SET_COMMUNITY_ACCESS_CODE_ON_COMMUNITY_GUEST':
      if (!state.size) return state;
      const indexOfCommunityGuestToSetCommunityCode = state.findIndex(
        communityGuest => communityGuest.get('id') === action.communityGuestId
      );
      if (indexOfCommunityGuestToSetCommunityCode === -1) return state;
      return state
        .setIn(
          [indexOfCommunityGuestToSetCommunityCode, 'access_code_id'],
          action.communityAccessCode ? action.communityAccessCode.id : null
        )
        .setIn([indexOfCommunityGuestToSetCommunityCode, 'property_access_code'], fromJS(action.communityAccessCode));
    default:
      return state;
  }
};

const propertyGuestsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_GUESTS_SUCCESS':
      return true;
    case 'GET_PROPERTY_GUESTS_REQUEST':
    case 'GET_PROPERTY_GUESTS_RESET':
      return false;
    default:
      return state;
  }
};

const unitGuestList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_UNIT_GUEST_LIST_SUCCESS':
      return fromJS(action.json);
    case 'REVOKE_UNIT_GUEST_COMMUNITY_ACCESS_SUCCESS':
      const guestIndexToUpdate = state.findIndex(guest => guest.get('id') === action.guestId);

      return state.setIn([guestIndexToUpdate, 'communityAccessStatus'], 'Revoked');
    default:
      return state;
  }
};

const unitGuestListLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_UNIT_GUEST_LIST_SUCCESS':
      return true;
    case 'GET_UNIT_GUEST_LIST_REQUEST':
    case 'GET_UNIT_GUEST_LIST_RESET':
      return false;
    default:
      return state;
  }
};

const accessControlAreas = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_AREAS_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_PROPERTY_AREA_SUCCESS':
      return state.push(fromJS(action.json)).sortBy(a => a.get('dmp_area_number'));
    case 'REMOVE_PROPERTY_AREA_REQUEST':
      return state.filter(a => action.area.get('id') !== a.get('id'));
    default:
      return state;
  }
};

const accessControlAreasLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_AREAS_SUCCESS':
      return true;
    case 'GET_PROPERTY_AREAS_REQUEST':
      return false;
    default:
      return state;
  }
};

const accessControlPanelAreas = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANEL_AREAS_SUCCESS':
      return fromJS(action.json.map(a => a.area_information));
    case 'CREATE_PROPERTY_AREA_REQUEST':
      return state.filter(
        p =>
          action.payload.dmp_area_number &&
          parseInt(p.get('number'), 10) !== parseInt(action.payload.dmp_area_number, 10)
      );
    case 'REMOVE_PROPERTY_AREA_REQUEST':
      return state.filter(p => parseInt(action.area.get('dmp_area_number'), 10) !== parseInt(p.get('number'), 10));
    case 'REMOVE_PROPERTY_PANEL_AREA_REQUEST':
      return state.filter(p => parseInt(p.get('number'), 10) !== parseInt(action.areaNumber, 10));
    default:
      return state;
  }
};

const accessControlPanelAreasLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANEL_AREAS_SUCCESS':
      return true;
    case 'GET_PROPERTY_PANEL_AREAS_REQUEST':
      return false;
    default:
      return state;
  }
};

const accessControlDoors = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_DOORS_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_PROPERTY_DOOR_SUCCESS':
      return state.push(fromJS(action.json)).sortBy(d => d.get('dmp_device_number'));
    case 'REMOVE_PROPERTY_DOOR_REQUEST':
      return state.filter(d => action.door.get('id') !== d.get('id'));
    default:
      return state;
  }
};

const accessControlDoorsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_DOORS_SUCCESS':
      return true;
    case 'GET_PROPERTY_DOORS_REQUEST':
      return false;
    default:
      return state;
  }
};

const accessControlPanelDoors = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANEL_DOORS_SUCCESS':
      return fromJS(action.json.map(d => d.device_information));
    case 'CREATE_PROPERTY_DOOR_REQUEST':
      return state.filter(
        p =>
          action.payload.dmp_device_number &&
          parseInt(p.get('lx_number') || p.get('number'), 10) !== parseInt(action.payload.dmp_device_number, 10)
      );
    case 'REMOVE_PROPERTY_DOOR_REQUEST':
      return state.filter(
        p => parseInt(action.door.get('dmp_device_number'), 10) !== parseInt(p.get('lx_number') || p.get('number'), 10)
      );
    case 'REMOVE_PROPERTY_PANEL_DOOR_REQUEST':
      return state.filter(p => parseInt(p.get('number'), 10) !== parseInt(action.doorNumber, 10));
    default:
      return state;
  }
};

const accessControlPanelDoorsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANEL_DOORS_SUCCESS':
      return true;
    case 'GET_PROPERTY_PANEL_DOORS_REQUEST':
      return false;
    default:
      return state;
  }
};

const accessControlSchedules = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_SCHEDULES_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_PROPERTY_SCHEDULE_SUCCESS':
      return state.push(fromJS(action.json)).sortBy(a => a.get('dmp_schedule_number'));
    case 'REMOVE_PROPERTY_SCHEDULE_REQUEST':
      return state.filter(a => action.schedule.get('id') !== a.get('id'));
    default:
      return state;
  }
};

const accessControlSchedulesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_SCHEDULES_SUCCESS':
      return true;
    case 'GET_PROPERTY_SCHEDULES_REQUEST':
      return false;
    default:
      return state;
  }
};

const accessControlPanelSchedules = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANEL_SCHEDULES_SUCCESS':
      return fromJS(action.json.map(t => t.time_schedule));
    case 'CREATE_PROPERTY_SCHEDULE_REQUEST':
      return state.filter(
        p =>
          action.payload.dmp_schedule_number &&
          parseInt(p.get('number'), 10) !== parseInt(action.payload.dmp_schedule_number, 10)
      );
    case 'REMOVE_PROPERTY_SCHEDULE_REQUEST':
      return state.filter(
        p => parseInt(action.schedule.get('dmp_schedule_number'), 10) !== parseInt(p.get('number'), 10)
      );
    case 'REMOVE_PROPERTY_PANEL_SCHEDULE_REQUEST':
      return state.filter(p => parseInt(p.get('number'), 10) !== parseInt(action.scheduleNumber, 10));
    default:
      return state;
  }
};

const accessControlPanelSchedulesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANEL_SCHEDULES_SUCCESS':
      return true;
    case 'GET_PROPERTY_PANEL_SCHEDULES_REQUEST':
      return false;
    default:
      return state;
  }
};

const accessControlProfiles = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_ACCESS_PROFILES_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_PROPERTY_PROFILE_SUCCESS':
      return state.push(fromJS(action.json)).sortBy(p => p.get('dmp_profile_number'));
    case 'REMOVE_PROPERTY_PROFILE_REQUEST':
      return state.filter(p => action.profile.get('id') !== p.get('id'));
    case 'EDIT_PROPERTY_PROFILE_SUCCESS':
      const indexToUpdate = state.findIndex(profile => profile.get('id') === action.profileId);
      if (indexToUpdate >= 0) {
        return state.set(indexToUpdate, fromJS(action.json));
      }
      return state;
    default:
      return state;
  }
};

const accessControlProfilesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_ACCESS_PROFILES_SUCCESS':
      return true;
    case 'GET_PROPERTY_ACCESS_PROFILES_REQUEST':
      return false;
    default:
      return state;
  }
};

const accessControlPanelProfiles = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANEL_PROFILES_SUCCESS':
      return fromJS(action.json.map(p => p.profile));
    case 'CREATE_PROPERTY_PROFILE_REQUEST':
      return state.filter(
        p =>
          action.payload.dmp_profile_number &&
          parseInt(p.get('lx_number') || p.get('number'), 10) !== parseInt(action.payload.dmp_profile_number, 10)
      );
    case 'REMOVE_PROPERTY_PROFILE_REQUEST':
      return state.filter(
        p => parseInt(action.profile.get('dmp_profile_number'), 10) !== parseInt(p.get('number'), 10)
      );
    case 'REMOVE_PROPERTY_PANEL_PROFILE_REQUEST':
      return state.filter(p => parseInt(p.get('number'), 10) !== parseInt(action.profileNumber, 10));
    default:
      return state;
  }
};

const accessControlPanelProfilesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANEL_PROFILES_SUCCESS':
      return true;
    case 'GET_PROPERTY_PANEL_PROFILES_REQUEST':
      return false;
    default:
      return state;
  }
};

const accessPoints = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_ACCESS_POINTS_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_ACCESS_POINT_SUCCESS':
      return state.push(fromJS(action.json)).sortBy(p => p.get('created_at'));
    case 'REMOVE_ACCESS_POINT_REQUEST':
      return state.filter(p => action.accessPoint.get('id') !== p.get('id'));
    default:
      return state;
  }
};

const accessPointsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_ACCESS_POINTS_SUCCESS':
      return true;
    case 'GET_PROPERTY_ACCESS_POINTS_REQUEST':
      return false;
    default:
      return state;
  }
};

const accessRequests = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_ACCESS_REQUESTS_SUCCESS':
      return fromJS(action.json.results);
    default:
      return state;
  }
};

const accessRequestsTotal = (state = null, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_ACCESS_REQUESTS_SUCCESS':
      return action.json.total;
    default:
      return state;
  }
};

const accessRequestsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_ACCESS_REQUESTS_SUCCESS':
      return true;
    case 'GET_PROPERTY_ACCESS_POINTS_REQUEST':
      return false;
    default:
      return state;
  }
};

const propertyManager = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_PROPERTY_MANAGER_SUCCESS':
      return fromJS(action.json);
    case 'GET_PROPERTY_MANAGER_RESET':
      return Map();
    case 'SET_COMMUNITY_ACCESS_CODE_ON_COMMUNITY_STAFF':
      if (state.get('id') !== action.communityStaffId) return state;
      return state
        .set('property_access_code_id', action.communityAccessCode ? action.communityAccessCode.id : null)
        .set('property_access_code', fromJS(action.communityAccessCode));
    default:
      return state;
  }
};

const propertyManagerLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_MANAGER_REQUEST':
    case 'GET_PROPERTY_MANAGER_RESET':
      return false;
    case 'GET_PROPERTY_MANAGER_SUCCESS':
      return true;
    default:
      return state;
  }
};

const mriConfiguration = (state = null, action) => {
  switch (action.type) {
    case 'GET_MRI_CONFIGURATION_SUCCESS':
      return fromJS(action.json.props);
    case 'GET_MRI_CONFIGURATION_FAILURE':
      return null;
    case 'GET_MRI_CONFIGURATION_PENDING':
      return null;
    default:
      return state;
  }
};

const mriConfigurationLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_MRI_CONFIGURATION_SUCCESS':
      return false;
    case 'GET_MRI_CONFIGURATION_FAILURE':
      return false;
    case 'GET_MRI_CONFIGURATION_PENDING':
      return true;
    default:
      return state;
  }
};

const mriIntegrationTest = (state = null, action) => {
  switch (action.type) {
    case 'TEST_MRI_CONFIGURATION_SUCCESS':
      return 'Successfully connected to MRI';
    case 'TEST_MRI_CONFIGURATION_FAILURE':
      return fromJS(action.json.message);
    case 'TEST_MRI_CONFIGURATION_RESET':
      return null;
    default:
      return state;
  }
};

const mriIntegrationTestLoading = (state = null, action) => {
  switch (action.type) {
    case 'TEST_MRI_CONFIGURATION_SUCCESS':
      return false;
    case 'TEST_MRI_CONFIGURATION_FAILURE':
      return false;
    case 'TEST_MRI_CONFIGURATION_PENDING':
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  activeAmenity,
  amenityList,
  amenityListLoaded: getApiLoadedReducer('GET_AMENITIES'),
  groupList,
  groupListLoaded: getApiLoadedReducer('GET_GROUPS'),
  activeProperty,
  activePropertyError,
  activePropertyLoaded,
  communityDevices,
  communityDevicesLoading,
  post,
  postList,
  postListLoading: getApiLoadingReducer('GET_POSTS'),
  propertyDeviceList,
  propertyDeviceListLoading: getApiLoadingReducer('GET_PROPERTY_DEVICES'),
  maintenanceConfiguration,
  maintenanceConfigurationLoading,
  unitList,
  unitListSearching,
  unitListTotal,
  unitListLoading,
  unitListLoaded,
  messagesList,
  messagesListLoading,
  activeMessageLoaded,
  activeMessage,
  propertyCameraList,
  unit: unitReducers,
  mailHubs,
  mailHubsLoading: getApiLoadingReducer('GET_MAIL_HUBS'),
  mailHubsLoaded: getApiLoadedReducer('GET_MAIL_HUBS'),
  vacantUnitAutomation,
  vacantUnitAutomationLoaded: getApiLoadedReducer('GET_VACANT_UNIT_AUTOMATION'),
  vacantUnitAutomationLoading: getApiLoadingReducer('GET_VACANT_UNIT_AUTOMATION'),
  residentsList,
  residentsListLoading,
  residentsListError,
  blockedResidentsList,
  blockedResidentsListLoading: getApiLoadingReducer('GET_BLOCKED_RESIDENTS'),
  residentsListTotal,
  propertyGuests,
  propertyGuestsLoaded,
  unitGuestList,
  unitGuestListLoaded,
  accessControlAreas,
  accessControlAreasLoaded,
  accessControlPanelAreas,
  accessControlPanelAreasLoaded,
  accessControlDoors,
  accessControlDoorsLoaded,
  accessControlPanelDoors,
  accessControlPanelDoorsLoaded,
  accessControlSchedules,
  accessControlSchedulesLoaded,
  accessControlPanelSchedules,
  accessControlPanelSchedulesLoaded,
  accessControlProfiles,
  accessControlProfilesLoaded,
  accessControlPanelProfiles,
  accessControlPanelProfilesLoaded,
  accessPoints,
  accessPointsLoaded,
  accessRequests,
  accessRequestsTotal,
  accessRequestsLoaded,
  propertyManager,
  propertyManagerLoaded,
  removePropertyManagerLoading: getApiLoadingReducer('REMOVE_PROPERTY_MANAGER'),
  pendingPostList,
  mriIntegrationTest,
  mriConfiguration,
  mriConfigurationLoading,
  mriIntegrationTestLoading,
  vendorsList,
  event: eventReducers,
  ...communityViewsReducers,
  ...communityStripeIntegrationReducers,
  ...SgToursReducers,
  ...scheduledReducers,
});
