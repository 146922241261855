import { connect } from 'react-redux';
import { getCredentialTypes } from '../../../../../../redux/actions';
import CredentialTypesList from './CredentialTypesList';

function mapStateToProps(state, props) {
  return {
    credentialTypes: state.credentialTypes,
    credentialTypesLoaded: state.credentialTypesLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCredentialTypes: () => dispatch(getCredentialTypes()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CredentialTypesList);
