export default {
  is12HoursTime: true,
  timeFormat: 'h:mm a',
  dateFormat: 'MM/DD/YYYY',
  dateFormatV2: 'MM/dd/yyyy',
  dateWithFullTimeFormat: 'MM/DD/YYYY, hh:mm:ss A',
  closed: 'Closed',
  minutes: 'minutes',
  hour: 'hour',
  hours: 'hours',
  and: 'and',
};
