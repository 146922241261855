import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSGFormContext } from '../views';
import { useValidationBlockSchema } from './useValidationBlockSchema';

export const useConfigFormBlock = ({ type, number }) => {
  const { touched, errors } = useFormikContext();
  const { formBlocks, setFormBlocks } = useSGFormContext();
  const validationBlockSchema = useValidationBlockSchema();
  const validationHandler = validationBlockSchema[type];

  const errorType = validationHandler ? validationHandler(touched, errors) : null;

  useEffect(() => {
    const blockType = formBlocks.find(block => block.number === number);

    if (!blockType) {
      formBlocks.push({ number, type });
      setFormBlocks(formBlocks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isError: !!errorType?.error,
  };
};
