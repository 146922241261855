import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import Select from 'react-select';
import Yup from 'yup';
import Loading from '../../../../../../../../common/Loading';
import Spinner from '../../../../../../../../common/Loading/SpinnerLoading';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import Switch from '../../../../../../../../common/forms/Switch';
import PageHeader from '../../../../../../../../common/PageHeader';
import Icon from '../../../../../../../../common/icons/icon';
import {
  initializePanel,
  waitForInitializeSuccess,
  refreshCommunicationOptions,
  setCommunicationOptions,
  setSystemOptions,
  setNetworkOptions,
  waitForConnection,
} from '../../utils/initialize-panel';

const SYSTEM_TYPE_OPTIONS = [
  {
    label: 'iComLNC',
    value: 'iComLNC',
  },
  {
    label: 'XT30',
    value: 'XT30',
  },
  {
    label: 'XR550',
    value: 'XR550',
  },
];

const CONNECTION_TYPE_OPTIONS = [
  {
    label: 'EASYconnect',
    value: 'persistent',
  },
  {
    label: 'EASYconnect + Cell Backup',
    value: 'persistent_w_cell_backup',
  },
];

const validationSchema = Yup.object().shape({
  system_type: Yup.string()
    .oneOf(SYSTEM_TYPE_OPTIONS.map(o => o.value))
    .required('Please enter a system type'),
  connection_type: Yup.string()
    .oneOf(CONNECTION_TYPE_OPTIONS.map(o => o.value))
    .required('Please enter a connection type'),
  panel_sim: Yup.string().when('connection_type', {
    is: type => ['cell', 'persistent_w_cell_backup'].find(t => t === type),
    then: Yup.string().required('Please enter a SIM'),
    otherwise: Yup.string().notRequired(),
  }),
  panel_sn: Yup.string()
    .trim()
    .required('Please enter a serial number'),
  dmp_734n_passphrase: Yup.string().when('system_type', {
    is: 'XR550',
    then: Yup.string()
      .min(8)
      .max(16)
      .matches(/^[A-Z0-9]*$/, '734N Passphrase must be alphanumeric')
      .label('734N Passphrase'),
    otherwise: Yup.string().notRequired(),
  }),
  dmp_734n_port: Yup.number().when('system_type', {
    is: 'XR550',
    then: Yup.number()
      .integer()
      .min(1)
      .max(65535)
      .label('734N Port')
      .typeError('734N Port must be a number')
      .required(),
    otherwise: Yup.number().required(),
  }),
});

const SHOW_FORM = 'SHOW_FORM';
const CREATE_LOADING = 'CREATE_LOADING';
const CREATE_FAILED = 'CREATE_FAILED';
const ONLINE_WAITING = 'ONLINE_WAITING';
const INITIALIZE_LOADING = 'INITIALIZE_LOADING';
const INITIALIZE_FAILED = 'INITIALIZE_FAILED';
const SET_COMM_OPTIONS_LOADING = 'SET_COMM_OPTIONS_LOADING';
const SET_COMM_OPTIONS_FAILED = 'SET_COMM_OPTIONS_FAILED';
const SET_SYSTEM_OPTIONS_LOADING = 'SET_SYSTEM_OPTIONS_LOADING';
const SET_SYSTEM_OPTIONS_FAILED = 'SET_SYSTEM_OPTIONS_FAILED';
const SET_NETWORK_OPTIONS_LOADING = 'SET_NETWORK_OPTIONS_LOADING';
const SET_NETWORK_OPTIONS_FAILED = 'SET_NETWORK_OPTIONS_FAILED';
const SUCCESS = 'SUCCESS';

class AddPanel extends Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    createPanel: PropTypes.func.isRequired,
    panel: PropTypes.object,
    pairPanelToUnit: PropTypes.func,
    getUnpairedPropertyUnits: PropTypes.func.isRequired,
    unpairedPropertyUnits: PropTypes.object,
    unpairPanelFromUnit: PropTypes.func,
    findPanelById: PropTypes.func,
  };

  state = {
    step: SHOW_FORM,
    message: null,
    newPanelId: null,
    selectedUnit: null,
    newPanel: null,
  };

  componentDidMount() {
    this.props.getUnpairedPropertyUnits();
  }
  createPanel = async values => {
    const { createPanel } = this.props;
    console.log('Creating initial panel');
    const initialPanelInfo = await createPanel(values);

    if (initialPanelInfo.response.ok) {
      const onboardedPanelInfo = await this.waitForOnboardedPanelInfo(initialPanelInfo.json.data.panel_sn, {
        job_id: initialPanelInfo.json.jobId,
      });
      if (onboardedPanelInfo.status === 'COMPLETE') {
        return onboardedPanelInfo.data;
      } else if (onboardedPanelInfo.status === 'FAIL') {
        if (onboardedPanelInfo.failMessage.match(/Missing lock for job.*/)) {
          throw new Error('The create panel operation has failed due to a timeout.');
        }
        throw new Error(onboardedPanelInfo.failMessage);
      }
    } else {
      console.log('Failed to create panel', initialPanelInfo);
      throw new Error((initialPanelInfo.json && initialPanelInfo.json.message) || 'Failed to create panel');
    }
  };

  waitForOnboardedPanelInfo = async (serialNumber, jobId) => {
    const { checkOnboardPanelStatus } = this.props;
    return new Promise((resolve, reject) => {
      let intervalHandler = setInterval(async () => {
        try {
          const response = await checkOnboardPanelStatus(serialNumber, jobId);
          const result = response.json;
          console.log('Checking Panel Status...');

          if (result.status !== 'PENDING') {
            clearInterval(intervalHandler);
            resolve(result);
          }
        } catch (e) {
          clearInterval(intervalHandler);
          reject(e);
        }
      }, 5 * 1000);
    });
  };

  waitForConnection = async (panelId, connectionType) => {
    const { checkPanelEasyConnectStatus } = this.props;

    console.log('Waiting on initial connection to panel');

    if (connectionType === 'persistent' || connectionType === 'persistent_w_cell_backup') {
      // Persistent panels use EASYConnect
      console.log('Using EASYConnect');
      let easyConnectSuccess = false;
      while (!easyConnectSuccess) {
        try {
          await new Promise(resolve => setTimeout(resolve, 3000));

          console.log('Checking EASYConnect status');

          const action = await checkPanelEasyConnectStatus(panelId);

          easyConnectSuccess = action.json && action.json.status === 'OK';
        } catch (e) {
          console.error(e);
        }
      }
      console.log('EASYConnect success');
    } else {
      // Network/Cell panels do not use EASYConnect
      console.log('Not using EASYConnect');
    }

    console.log('Made initial connection to panel');

    return true;
  };

  onCancel = () => {
    const { history, match } = this.props;
    history.replace(`/properties/${match.params.propertyId}/property-details/devices/properties`);
  };

  onSubmit = async (values, { setSubmitting }) => {
    const {
      setPanelSystemOptions,
      setPanelNetworkOptions,
      getPanelDevices,
      refreshPanelCommunicationOptions,
      setPanelCommunicationOptions,
      getJobStatus,
      updatePanel,
      initializePanel: dispatchInitializePanel,
      checkInitializePanelStatus,
      checkPanelEasyConnectStatus,
    } = this.props;
    this.setState({ step: CREATE_LOADING, message: null, newPanel: null });
    setSubmitting(false);
    let createPanelResult;
    if (values.system_type !== 'XR550') {
      delete values.dmp_734n_passphrase;
      delete values.dmp_734n_port;
      delete values.dmp_real_time_events_enabled;
    }
    try {
      createPanelResult = await this.createPanel(values);
    } catch (e) {
      this.setState({ step: CREATE_FAILED, message: e.message });
      values.dmp_734n_port = '2002';
      values.dmp_734n_passphrase = '';
      values.dmp_real_time_events_enabled = false;
      return;
    }

    const panel = createPanelResult;

    this.setState({ step: ONLINE_WAITING, newPanelId: panel.id });
    await waitForConnection(panel.id, values.connection_type, checkPanelEasyConnectStatus);

    this.setState({ step: INITIALIZE_LOADING });

    await this.props.findPanelById(panel.id).then(result => {
      this.setState({ newPanel: result.json });
    });

    try {
      const result = await initializePanel(panel.id, dispatchInitializePanel);

      await waitForInitializeSuccess(
        panel.id,
        result.json.SchedulerJobs[0]['SchedulerJobGroupId'],
        checkInitializePanelStatus
      );
    } catch (e) {
      this.setState({ step: INITIALIZE_FAILED, message: e.message });
      return;
    }

    this.setState({ step: SET_COMM_OPTIONS_LOADING });
    try {
      await refreshCommunicationOptions(panel.id, refreshPanelCommunicationOptions, getJobStatus);
      await setCommunicationOptions(panel.id, setPanelCommunicationOptions, getJobStatus);
      await updatePanel(panel.id, { dmp_is_communication_options_set: true });
    } catch (e) {
      this.setState({ step: SET_COMM_OPTIONS_FAILED, message: e.message });
      return;
    }

    this.setState({ step: SET_SYSTEM_OPTIONS_LOADING });
    try {
      const panelDevicesResult = await getPanelDevices(panel.id);
      const systemOptions = panelDevicesResult.json.systemOptions;
      systemOptions.house_code = values.dmp_house_code;
      await setSystemOptions(panel.id, systemOptions, setPanelSystemOptions, getJobStatus);
      await updatePanel(panel.id, { dmp_is_system_options_set: true });
    } catch (e) {
      this.setState({ step: SET_SYSTEM_OPTIONS_FAILED, message: e.message });
      return;
    }

    this.setState({ step: SET_NETWORK_OPTIONS_LOADING });
    try {
      if (values.system_type === 'XR550') {
        const panelDevicesResult = await getPanelDevices(panel.id);
        const networkOptions = panelDevicesResult.json.networkOptions;
        networkOptions.p_phr_734n = values.dmp_734n_passphrase;
        networkOptions.l_prt_734n = values.dmp_734n_port;
        await setNetworkOptions(panel.id, networkOptions, setPanelNetworkOptions, getJobStatus);
      }
      await updatePanel(panel.id, { dmp_is_network_options_set: true });
    } catch (e) {
      this.setState({ step: SET_NETWORK_OPTIONS_FAILED, message: e.message });
      return;
    }

    console.log('DONE!');
    this.handlePairUnit(values);
    this.setState({ step: SUCCESS });
  };
  handleChangeSelectedUnit = value => {
    this.setState(() => ({ selectedUnit: value }));
  };
  handlePairUnit = values => {
    const { pairPanelToUnit, panel } = this.props;
    const { selectedUnit } = this.state;

    if (['iComLNC', 'XT30'].includes(values.system_type)) {
      console.log('Handling Pair');

      let panelId = panel.get('id');
      if (selectedUnit) {
        pairPanelToUnit(panelId, selectedUnit.value).then(action => {
          if (action.response.ok) {
            this.props.getUnpairedPropertyUnits();
          }
        });
      }
    }
  };

  renderPairUnit = () => {
    const { unpairedPropertyUnits, panel } = this.props;

    let options = [];

    if (unpairedPropertyUnits) {
      options =
        unpairedPropertyUnits
          .filter(unit => unit.get('id') !== panel.getIn(['unit', 'id']))
          .map(unit => ({ label: unit.get('number'), value: unit.get('id') }))
          .toArray() || null;
    }

    return (
      <div className="panel__associate-to-unit">
        <Select
          closeOnSelect={true}
          onChange={this.handleChangeSelectedUnit}
          options={options}
          placeholder={'Select...'}
          value={this.state.selectedUnit}
          searchable={false}
        />
      </div>
    );
  };
  renderAccountAndSerialNumber = () => {
    if (this.state.newPanel) {
      return (
        <h4 className="h4">
          Account Number: {this.state.newPanel.dmp_account_prefix}-{this.state.newPanel.dmp_account_number} <br />
          Serial Number: {this.state.newPanel.dmp_serial_number}
        </h4>
      );
    }
  };
  render() {
    const { property } = this.props;
    const { step, message } = this.state;

    if (!property) {
      return <Loading />;
    }

    return (
      <div className="devices__panels-list">
        <PageHeader title={`Devices • ${property.get('name')}`} backLink="./" />
        <Formik
          initialValues={{
            system_type: '',
            connection_type: '',
            panel_sim: '',
            panel_sn: '',
            dmp_house_code: `${Math.floor(Math.random() * 50) + 1}`,
            dmp_734n_passphrase: '',
            dmp_734n_port: '2002',
            dmp_real_time_events_enabled: false,
          }}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleReset,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            isValid,
          }) => {
            switch (step) {
              case SHOW_FORM:
                return (
                  <div className="container">
                    <form onSubmit={handleSubmit}>
                      <div className="paper radius-top-left radius-top-right">
                        <div className="page-header subheader underline">
                          <h4 className="h4">Add Panel</h4>
                        </div>
                        <div className="device-form--body">
                          <InputScaffold label="System Type" required validation={errors.system_type}>
                            <Select
                              closeOnSelect={true}
                              onChange={value => {
                                setFieldValue('system_type', value);
                                setFieldTouched('system_type', true);
                              }}
                              options={SYSTEM_TYPE_OPTIONS}
                              placeholder="Select..."
                              value={values.system_type}
                              simpleValue={true}
                              clearable={false}
                              searchable={false}
                            />
                          </InputScaffold>
                          <InputScaffold label="Connection Type" required validation={errors.connection_type}>
                            <Select
                              closeOnSelect={true}
                              onChange={value => {
                                setFieldValue('connection_type', value);
                                setFieldTouched('connection_type', true);
                              }}
                              options={CONNECTION_TYPE_OPTIONS}
                              placeholder="Select..."
                              value={values.connection_type}
                              simpleValue={true}
                              clearable={false}
                              searchable={false}
                            />
                          </InputScaffold>
                          {(values.connection_type === 'cell' ||
                            values.connection_type === 'persistent_w_cell_backup') && (
                            <InputScaffold label="IMEI" required validation={touched.panel_sim && errors.panel_sim}>
                              <Field type="text" name="panel_sim" onChange={handleChange} value={values.panel_sim} />
                            </InputScaffold>
                          )}
                          <InputScaffold
                            label="Serial Number"
                            required
                            validation={touched.panel_sn && errors.panel_sn}
                          >
                            <Field type="text" name="panel_sn" onChange={handleChange} value={values.panel_sn} />
                          </InputScaffold>
                          <InputScaffold
                            label="House Code"
                            required
                            validation={touched.dmp_house_code && errors.dmp_house_code}
                          >
                            <Field
                              type="text"
                              name="dmp_house_code"
                              onChange={handleChange}
                              value={values.dmp_house_code}
                            />
                          </InputScaffold>
                          {(values.system_type === 'iComLNC' || values.system_type === 'XT30') && (
                            <InputScaffold label="Associate Unit">{this.renderPairUnit()}</InputScaffold>
                          )}
                          {values.system_type === 'XR550' && (
                            <React.Fragment>
                              <InputScaffold
                                label="734N Passphrase"
                                validation={touched.dmp_734n_passphrase && errors.dmp_734n_passphrase}
                                required
                              >
                                <Field
                                  type="text"
                                  maxLength={16}
                                  name="dmp_734n_passphrase"
                                  onChange={e => {
                                    let value = e.target.value || '';
                                    value = value.toUpperCase();
                                    setFieldValue('dmp_734n_passphrase', value);
                                  }}
                                  value={values.dmp_734n_passphrase}
                                />
                              </InputScaffold>
                              <InputScaffold
                                label="734N Port"
                                validation={touched.dmp_734n_port && errors.dmp_734n_port}
                                required
                              >
                                <Field
                                  type="text"
                                  name="dmp_734n_port"
                                  onChange={handleChange}
                                  value={values.dmp_734n_port}
                                />
                              </InputScaffold>
                              <Switch
                                label="Enable Real Time Events"
                                input={{
                                  name: 'dmp_real_time_events_enabled',
                                  value: !!values.dmp_real_time_events_enabled,
                                  onChange: value => {
                                    setFieldValue('dmp_real_time_events_enabled', value);
                                  },
                                }}
                                disabled={false}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <div className="paper__footer radius-bottom-left radius-bottom-right">
                        <button disabled={isSubmitting || Object.keys(errors).length || !isValid} className="button">
                          Continue
                        </button>
                        <button type="button" className="button button--secondary" onClick={this.onCancel}>
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                );
              case CREATE_LOADING:
              case INITIALIZE_LOADING:
              case SET_COMM_OPTIONS_LOADING:
              case SET_SYSTEM_OPTIONS_LOADING:
              case SET_NETWORK_OPTIONS_LOADING:
                const subheaders = {
                  CREATE_LOADING: 'Creating...',
                  INITIALIZE_LOADING: 'Initializing...',
                  SET_COMM_OPTIONS_LOADING: 'Setting communication options...',
                  SET_SYSTEM_OPTIONS_LOADING: 'Setting system options...',
                  SET_NETWORK_OPTIONS_LOADING: 'Setting network options...',
                };
                return (
                  <div className="container">
                    <div className="paper radius-top-left radius-top-right">
                      <div className="page-header subheader underline">
                        <h4 className="h4">{`Add Panel - ${subheaders[step]}`}</h4>
                      </div>
                      <div className="scanning__wrapper">
                        <div className="scanning-message">
                          {this.renderAccountAndSerialNumber()}
                          <p>The SmartExperience app needs to connect to this system for the first time.</p>
                          <p>This is a multi-step process that may take several minutes to complete.</p>
                          <p>Several connection strategies may be tried.</p>
                        </div>
                        <Spinner />
                      </div>
                    </div>
                    <div className="paper__footer radius-bottom-left radius-bottom-right">
                      <button disabled className="button">
                        {step === CREATE_LOADING ? 'Edit Panel' : 'View Panel'}
                      </button>
                      <button type="button" disabled className="button button--secondary" onClick={this.onCancel}>
                        Cancel
                      </button>
                    </div>
                  </div>
                );
              case CREATE_FAILED:
                return (
                  <div className="container">
                    <div className="paper radius-top-left radius-top-right">
                      <div className="page-header subheader underline">
                        <h4 className="h4">Add Panel - Error</h4>
                      </div>
                      <div className="panel--error">
                        <div className="panel--error__header">ERROR:</div>
                        <div className="panel--error__message">{message}</div>
                      </div>
                      <div className="font-light">
                        Please select the Edit Panel button below to resolve these errors.
                      </div>
                    </div>
                    <div className="paper__footer radius-bottom-left radius-bottom-right">
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({ step: SHOW_FORM });
                        }}
                        className="button"
                      >
                        Edit Panel
                      </button>
                      <button type="button" className="button button--secondary" onClick={this.onCancel}>
                        Cancel
                      </button>
                    </div>
                  </div>
                );
              case INITIALIZE_FAILED:
              case SET_COMM_OPTIONS_FAILED:
              case SET_SYSTEM_OPTIONS_FAILED:
              case SET_NETWORK_OPTIONS_FAILED:
                return (
                  <div className="container">
                    <div className="paper radius-top-left radius-top-right">
                      <div className="page-header subheader underline">
                        <h4 className="h4">Add Panel - Error</h4>
                      </div>
                      <div className="panel--error">
                        <div className="panel--error__header">ERROR:</div>
                        <div className="panel--error__message">{message}</div>
                      </div>
                      <div className="font-light">
                        <p>Something went wrong, please try some of the options below: </p>
                        <ol>
                          <li>1. Restart The Panel</li>
                          <br />
                          <li>2. Wait 2 Minutes</li>
                          <br />
                          <li>3. Refresh The Page</li>
                          <br />
                          <li>4. Try Onboarding Again</li>
                        </ol>

                        <p> Please select the View Panel button below to resolve these errors.</p>
                      </div>
                    </div>
                    <div className="paper__footer radius-bottom-left radius-bottom-right">
                      <button
                        type="button"
                        onClick={() => {
                          const { history, match } = this.props;
                          history.replace(
                            `/properties/${match.params.propertyId}/property-details/devices/properties/panels/${this.state.newPanelId}`
                          );
                        }}
                        className="button"
                      >
                        View Panel
                      </button>
                      <button type="button" className="button button--secondary" onClick={this.onCancel}>
                        Cancel
                      </button>
                    </div>
                  </div>
                );
              case ONLINE_WAITING:
                return (
                  <div className="container">
                    <div className="paper radius-top-left radius-top-right">
                      <div className="page-header subheader underline">
                        <h4 className="h4">Add Panel - Connecting...</h4>
                      </div>
                      <div className="scanning__wrapper">
                        <div className="scanning-message">
                          <p>
                            Make sure that your panel is connected to the network either via ethernet or cellular
                            network
                          </p>
                        </div>
                        <Spinner />
                      </div>
                    </div>
                    <div className="paper__footer radius-bottom-left radius-bottom-right">
                      <button disabled className="button">
                        Continue
                      </button>
                      <button type="button" className="button button--secondary" onClick={this.onCancel}>
                        Cancel
                      </button>
                    </div>
                  </div>
                );
              case SUCCESS:
                return (
                  <div className="container">
                    <div className="paper radius-top-left radius-top-right">
                      <div className="page-header subheader underline">
                        <h4 className="h4">Add Panel - Complete</h4>
                      </div>
                      <div className="scanning__wrapper">
                        <Icon className="finished" icon="Finished" />
                        <div className="job-status added">New Panel has been added!</div>
                      </div>
                    </div>
                    <div className="paper__footer radius-bottom-left radius-bottom-right">
                      <button
                        type="button"
                        onClick={() => {
                          const { history, match } = this.props;
                          history.replace(
                            `/properties/${match.params.propertyId}/property-details/devices/panels/${this.state.newPanelId}/add-device`
                          );
                        }}
                        disabled={this.state.newPanelId === null}
                        className="button"
                      >
                        Add Device
                      </button>
                      <button
                        type="button"
                        className="button"
                        onClick={e => {
                          handleReset(e);
                          this.setState({
                            step: SHOW_FORM,
                          });
                        }}
                      >
                        Add Another Panel
                      </button>
                      <button type="button" className="button button--secondary" onClick={this.onCancel}>
                        Finish
                      </button>
                    </div>
                  </div>
                );
              default:
                return null;
            }
          }}
        />
      </div>
    );
  }
}

export default AddPanel;
