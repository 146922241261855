import React, { useRef, useState, useLayoutEffect } from 'react';
import { SGTooltip } from '../../forms';

export const useTablesTextTruncate = text => {
  const ref = useRef();
  const minContent = text && text?.split(' ').length > 2;
  const [isTextTruncate, setIsTextTruncate] = useState(false);

  useLayoutEffect(() => {
    if (ref.current) {
      const { scrollWidth, offsetWidth, offsetHeight } = ref.current;
      const { offsetHeight: containerHeight } = ref.current.parentElement;
      setIsTextTruncate(offsetWidth < scrollWidth || offsetHeight > containerHeight);
    }
  }, []);

  return (
    text && (
      <SGTooltip placement="top" content={text} disabled={!isTextTruncate} minContent={minContent}>
        <span ref={ref}>{text}</span>
      </SGTooltip>
    )
  );
};
