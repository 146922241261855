import React from 'react';
import PropTypes from 'prop-types';

export const SGTdTour = ({ units }) => (
  <div className="td-tour">
    {units.map(({ unit: { id, number } }) => (
      <span className="td-tour-unit" key={id}>
        {number}
      </span>
    ))}
  </div>
);

SGTdTour.propTypes = {
  units: PropTypes.array,
};
