import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

export const SGLinkToTour = () => {
  const { propertyId } = useParams();

  return (
    <>
      <div className="detail-label">TOUR</div>
      <Link className="scheduledDetail_units" to={`/properties/${propertyId}/units/tours/automated/edit/settings`}>
        All available model units
      </Link>
    </>
  );
};
