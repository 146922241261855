import React, { Component } from 'react';

class GuestItem extends Component {
  render() {
    const { guestItem, addAdditionalNum, deleteAdditionalNum, selectGuestItem } = this.props;

    return (
      <tr>
        <td>{guestItem.first_name + ' ' + guestItem.last_name}</td>
        <td>{guestItem.unit_number}</td>
        <td>{guestItem.email}</td>
        <td className="guestitem-additional-td">
          <span
            onClick={() => {
              deleteAdditionalNum(guestItem);
            }}
          >
            -
          </span>
          <input type="text" className="guestitem-additional-input" value={guestItem.additionalNum} disabled />
          <span
            onClick={() => {
              addAdditionalNum(guestItem);
            }}
          >
            +
          </span>
        </td>
        <td className="guestitem-additional-td-input">
          <input
            type="checkbox"
            checked={guestItem.selected}
            onChange={() => {
              selectGuestItem(guestItem);
            }}
          />
        </td>
      </tr>
    );
  }
}

export default GuestItem;
