import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Formik } from 'formik';
import Yup from 'yup';

import Switch from '../../../../../../../../../../common/forms/Switch';
import InputScaffold from '../../../../../../../../../../common/forms/InputScaffold';
import ToggleScaffold from '../../../../../../../../../../common/forms/ToggleScaffold';
import Icon from '../../../../../../../../../../common/icons/icon';
import Spinner from '../../../../../../../../../../common/Loading/SpinnerLoading';
import Scanner from './Scanner';

const SENSOR_HARDWARE_TYPE = 'SENSOR';

const SHOW_FORM = 'SHOW_FORM';
const ZONE_REFRESH_PENDING = 'ZONE_REFRESH_PENDING';
const ZONE_ADDITION_PENDING = 'ZONE_ADDITION_PENDING';
const DEVICE_CREATE_PENDING = 'DEVICE_CREATE_PENDING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';

class AddDMPDevice extends Component {
  state = {
    devicesLoaded: false,
    devicesError: false,
    initialDeviceName: SENSOR_HARDWARE_TYPE,
    scanning: false,
    formState: SHOW_FORM,
    formError: null,
    nextAvailableZone: null,
    uid: null,
  };

  componentDidMount() {
    this.getDevicesCount();
  }

  getDevicesCount = async () => {
    const { getPanelDevices } = this.props;

    const action = await getPanelDevices();

    if (action.response.ok) {
      const numberOfSensors = action.json.devices.filter(d => d.dmp_hardware_type === SENSOR_HARDWARE_TYPE).length;

      this.setState({
        devicesLoaded: true,
        initialDeviceName:
          numberOfSensors > 0 ? `${SENSOR_HARDWARE_TYPE} ` + (numberOfSensors + 1) : SENSOR_HARDWARE_TYPE,
      });
    } else {
      this.setState({
        devicesError: true,
      });
    }
  };

  createDMPDevice = async payload => {
    const { createDMPDevice, getJobStatus } = this.props;
    console.log('Creating zone');

    const jobRequestResult = await createDMPDevice(payload);
    if (jobRequestResult.response.ok && jobRequestResult.json.job) {
      console.log('Got create job');

      this.setState({
        nextAvailableZone: jobRequestResult.json.nextAvailableZone,
        uid: jobRequestResult.json.uid,
      });

      let refreshing = true;
      while (refreshing) {
        await new Promise(resolve => setTimeout(resolve, 3000));

        console.log('Checking create zone status');

        const jobStatusResult = await getJobStatus(jobRequestResult.json.job.uuid);

        if (jobStatusResult.response.ok && jobStatusResult.json) {
          if (jobStatusResult.json.status === 'success') {
            console.log('Finished creating zone');
            return jobStatusResult.json;
          } else if (jobStatusResult.json.status === 'pending') {
            refreshing = true;
          } else {
            refreshing = false;
          }
        }
      }
    }

    console.log('Creating new zone failed');
    throw new Error('Creating new zone failed');
  };

  refreshPanelZones = async () => {
    const { refreshPanelZones, getJobStatus } = this.props;

    console.log('Refreshing zones');

    const jobRequestResult = await refreshPanelZones();
    if (jobRequestResult.response.ok && jobRequestResult.json.job) {
      console.log('Got refresh job');

      let refreshing = true;
      while (refreshing) {
        await new Promise(resolve => setTimeout(resolve, 3000));

        console.log('Checking refresh panel zones status');

        const jobStatusResult = await getJobStatus(jobRequestResult.json.job.uuid);

        if (jobStatusResult.response.ok && jobStatusResult.json) {
          if (jobStatusResult.json.status === 'success') {
            console.log('Finished refreshing panel zones');
            return jobStatusResult.json;
          } else if (jobStatusResult.json.status === 'pending') {
            refreshing = true;
          } else {
            refreshing = false;
          }
        }
      }
    }

    console.log('Refreshing panel zones failed');
    throw new Error('Refreshing panel zones failed');
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const { createPanelDevice } = this.props;

    setSubmitting(false);

    try {
      this.setState({ formState: ZONE_REFRESH_PENDING });
      await this.refreshPanelZones();

      this.setState({ formState: ZONE_ADDITION_PENDING });
      await this.createDMPDevice(values);

      console.log('Creating device in SmartExperience');
      this.setState({ formState: DEVICE_CREATE_PENDING });
      const { uid, nextAvailableZone } = this.state;
      const action = await createPanelDevice({
        id: uid,
        zone: nextAvailableZone,
        last_number: null,
        name: values.name,
        mastercode: null,
        dmp_hardware_type: 'SENSOR',
        dmp_hardware_subtype: 'STANDARD',
        is_community_device: values.is_community_device,
        is_motion_sensor: values.is_motion_sensor,
        hide_battery_status: values.hide_battery_status,
      });
      if (action.response.ok) {
        console.log('Device created');
        this.setState({ formState: SUCCESS });
      } else {
        console.error(action.response);
        this.setState({
          formState: ERROR,
          formError:
            (action.response.json && action.response.json.message) ||
            'Something went wrong when adding the device to SmartExperience',
        });
      }
    } catch (err) {
      this.setState({
        formState: ERROR,
        formError: err.message || 'Something went wrong when adding the new zone to the panel',
      });
    }
  };

  renderSuccess = () => {
    return (
      <Fragment>
        <div className="paper radius-top-left radius-top-right pairing-screen">
          <div className="page-header subheader underline">
            <h4 className="h4">Adding SmartExperience Device</h4>
          </div>
          <div className="scanning__wrapper">
            <Icon className="finished" icon="Finished" />
            <div className="job-status added">New SmartExperience device has been added!</div>
          </div>
        </div>
        <div className="paper__footer radius-bottom-left radius-bottom-right">
          <div className="button--error__wrapper">
            <button
              className="button"
              type="button"
              onClick={() => {
                this.setState(
                  {
                    devicesLoaded: false,
                    devicesError: false,
                    scanning: false,
                    formState: SHOW_FORM,
                    nextAvailableZone: null,
                    uid: null,
                  },
                  () => {
                    this.getDevicesCount();
                  }
                );
              }}
            >
              Add Another Device
            </button>
            <Link className="button button--secondary" to={`./`}>
              Finish
            </Link>
          </div>
        </div>
      </Fragment>
    );
  };

  renderError = () => {
    return (
      <Fragment>
        <div className="paper radius-top-left radius-top-right pairing-screen">
          <div className="page-header subheader underline">
            <h4 className="h4">Adding SmartExperience Device</h4>
          </div>
          <div className="input-validation">{this.state.formError}</div>
        </div>
        <div className="paper__footer radius-bottom-left radius-bottom-right">
          <div className="button--error__wrapper">
            <button
              className="button"
              type="button"
              onClick={() => {
                this.setState(
                  {
                    devicesLoaded: false,
                    devicesError: false,
                    scanning: false,
                    formState: SHOW_FORM,
                    nextAvailableZone: null,
                    uid: null,
                  },
                  () => {
                    this.getDevicesCount();
                  }
                );
              }}
            >
              Try Again
            </button>
            <Link className="button button--secondary" to={`./`}>
              Cancel
            </Link>
          </div>
        </div>
      </Fragment>
    );
  };

  renderPending = state => {
    const statusMessages = {
      ZONE_REFRESH_PENDING: 'Refreshing panel zones...',
      ZONE_ADDITION_PENDING: 'Adding zone to panel...',
      DEVICE_CREATE_PENDING: 'Creating SmartExperience device...',
    };
    return (
      <Fragment>
        <div className="paper radius-top-left radius-top-right pairing-screen">
          <div className="page-header subheader underline">
            <h4 className="h4">Adding SmartExperience Device</h4>
          </div>
          <div className="scanning__wrapper">
            <div className="scanning-message">Adding new SmartExperience device</div>
            <Spinner />
            <div className="job-status">{statusMessages[state]}</div>
          </div>
        </div>
        <div className="paper__footer radius-bottom-left radius-bottom-right">
          <div className="button--error__wrapper">
            <button disabled={true} className="button">
              Continue
            </button>
          </div>
        </div>
      </Fragment>
    );
  };

  renderForm = () => {
    const { match } = this.props;
    const { initialDeviceName, scanning } = this.state;

    const isWireless = match.params.deviceType === 'wireless';

    const validationSchema = Yup.object().shape({
      serial_number: isWireless
        ? Yup.string()
            .trim()
            .required('Please enter a serial number')
        : Yup.string().notRequired(),
      name: Yup.string()
        .trim()
        .max(120, 'Device label must be 120 characters or less')
        .required('Please enter a device label'),
    });

    return (
      <Formik
        initialValues={{
          serial_number: '',
          name: initialDeviceName,
          is_wireless: isWireless,
          is_motion_sensor: true,
          is_community_device: false,
          hide_battery_status: false,
        }}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        render={({ values, errors, handleSubmit, isSubmitting, setFieldValue, handleChange, handleBlur, touched }) => (
          <form onSubmit={handleSubmit}>
            <div className="paper radius-top-left radius-top-right">
              <div className="page-header subheader underline">
                <h4 className="h4">Add New SmartExperience Device</h4>
              </div>
              <div className="device-form--body">
                {isWireless && (
                  <Fragment>
                    <InputScaffold
                      label="Serial Number"
                      required
                      validation={touched.serial_number && errors.serial_number}
                    >
                      <div className="device-scan__wrapper">
                        <input
                          type="text"
                          name="serial_number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.serial_number}
                        />
                        <button
                          className="button"
                          type="button"
                          onClick={() => {
                            this.setState(state => ({ scanning: !state.scanning }));
                          }}
                        >
                          {scanning ? 'Stop' : 'Scan'}
                        </button>
                      </div>
                    </InputScaffold>
                    {scanning && (
                      <Scanner
                        onDetected={result => {
                          if (result.codeResult && result.codeResult.code) {
                            this.setState({ scanning: false });
                            setFieldValue('serial_number', result.codeResult.code);
                          }
                        }}
                        onError={e => {
                          this.setState({ scanning: false });
                          window.alert(`Error starting barcode scanner: ${e.message || 'Unknown Error'}`);
                        }}
                      />
                    )}
                  </Fragment>
                )}
                <InputScaffold label="Device Label" required validation={touched.name && errors.name}>
                  <input
                    type="text"
                    name="name"
                    maxLength="120"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <div className="input-scaffold__footer">Resident will see this label in the app</div>
                </InputScaffold>
                <div className="radio--input__wrapper area__wrapper">
                  <div className="input__wrapper--label">Type</div>
                  <div className="radio--container">
                    <ToggleScaffold label="Motion">
                      <input
                        type="radio"
                        name="is_motion_sensor"
                        onChange={() => {
                          setFieldValue('is_motion_sensor', true);
                        }}
                        checked={values.is_motion_sensor === true}
                      />
                    </ToggleScaffold>
                    <ToggleScaffold label="Other">
                      <input
                        type="radio"
                        name="is_motion_sensor"
                        onChange={() => {
                          setFieldValue('is_motion_sensor', false);
                        }}
                        checked={values.is_motion_sensor === false}
                      />
                    </ToggleScaffold>
                  </div>
                </div>
                <div className="radio--input__wrapper area__wrapper">
                  <div className="input__wrapper--label">Area</div>
                  <div className="radio--container">
                    <ToggleScaffold label="Unit">
                      <input
                        type="radio"
                        name="is_community_device"
                        onChange={() => {
                          setFieldValue('is_community_device', false);
                        }}
                        checked={values.is_community_device === false}
                      />
                    </ToggleScaffold>
                    <ToggleScaffold label="Community">
                      <input
                        type="radio"
                        name="is_community_device"
                        onChange={() => {
                          setFieldValue('is_community_device', true);
                        }}
                        checked={values.is_community_device === true}
                      />
                    </ToggleScaffold>
                  </div>
                </div>
                {isWireless && (
                  <Switch
                    label="Hide Battery Status"
                    input={{
                      name: 'hide_battery_status',
                      value: !!values.hide_battery_status,
                      onChange: value => {
                        setFieldValue('hide_battery_status', value);
                      },
                    }}
                    disabled={false}
                  />
                )}
              </div>
            </div>
            <div className="paper__footer radius-bottom-left radius-bottom-right">
              <div className="button--error__wrapper">
                <button disabled={isSubmitting || Object.keys(errors).length} className="button">
                  Continue
                </button>
                <Link className="button button--secondary--plain" to={`./`}>
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        )}
      />
    );
  };

  renderContents = () => {
    const { devicesLoaded, devicesError, formState } = this.state;

    if (devicesError) {
      return <div className="input-validation">Failed to fetch panel devices. Please go back and try again.</div>;
    }

    if (!devicesLoaded) {
      return <Spinner />;
    }

    if (formState === SHOW_FORM) {
      return this.renderForm();
    }

    if (
      formState === ZONE_ADDITION_PENDING ||
      formState === DEVICE_CREATE_PENDING ||
      formState === ZONE_REFRESH_PENDING
    ) {
      return this.renderPending(formState);
    }

    if (formState === SUCCESS) {
      return this.renderSuccess();
    }

    return this.renderError();
  };

  render() {
    const { property } = this.props;

    return (
      <div className="add-device--wizard">
        <div className="page-header page-header__custom">
          <div className="container">
            <Link to={`./`} className="back-arrow">
              <Icon icon="ArrowLeft" />
              Back
            </Link>
            <h1 className="h1">Devices &bull; {property.get('name')}</h1>
          </div>
        </div>
        <div className="container">{this.renderContents()}</div>
      </div>
    );
  }
}

export default withRouter(AddDMPDevice);
