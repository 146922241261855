import { connect } from 'react-redux';

import ProfilesList from './ProfilesList';
import {
  getProfiles,
  getPropertyPanelAreas,
  removeProfile,
  getPanelProfiles,
  editProfile,
} from '../../../../redux/actions';

function mapStateToProps(state) {
  return {
    accessControlProfiles: state.property.accessControlProfiles,
    accessControlProfilesLoaded: state.property.accessControlProfilesLoaded,
    isAdmin: !!state.user.get('roles').find(r => r.get('role') === 'ADMIN'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getProfiles: () => dispatch(getProfiles(ownProps.match.params.propertyId)),
    getPropertyPanelAreas: () => dispatch(getPropertyPanelAreas(ownProps.match.params.propertyId)),
    removeProfile: areaId => dispatch(removeProfile(ownProps.match.params.propertyId, areaId)),
    getPanelProfiles: () => dispatch(getPanelProfiles(ownProps.match.params.propertyId)),
    editProfile: (profileId, payload) => dispatch(editProfile(ownProps.match.params.propertyId, profileId, payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesList);
