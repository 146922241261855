import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List } from 'immutable';

const PrivateSection = ({ roles, userRoles, children, renderFallback = () => null }) => {
  const authorized = userRoles.some(role => roles.includes(role));

  return authorized ? children : renderFallback();
};

const mapStateToProps = state => ({
  userRoles: state.roles,
});

PrivateSection.propTypes = {
  renderFallback: PropTypes.func,
  roles: PropTypes.array.isRequired,
  userRoles: PropTypes.instanceOf(List).isRequired,
  children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps)(PrivateSection);
