import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../../../../common/NotFound';
import { AmenitiesList } from './AmenitiesList';
import { AddAmenity } from './AddAmenity';
import { EditAmenity } from './EditAmenity';

const Amenities = ({ property, match }) => {
  if (!property.get('admin_enabled_community')) {
    return <NotFound />;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={AmenitiesList} />
      <Route path={`${match.path}/add`} component={AddAmenity} />
      <Route path={`${match.path}/:amenityId`} component={EditAmenity} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default connect(state => ({ property: state.property.activeProperty }))(Amenities);
