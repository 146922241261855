import { connect } from 'react-redux';
import VendorEventsList from './VendorEventsList';

function mapStateToProps(state) {
  return {
    userId: state.user.get('id'),
  };
}

export default connect(mapStateToProps)(VendorEventsList);
