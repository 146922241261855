import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GroupItem from './GroupItem';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import Loading from '../../../../../../common/Loading';
import SubHeader from '../../../../../../common/SubHeader';

import Yup from 'yup';
import { Formik } from 'formik';

import InputScaffold from '../../../../../../common/forms/InputScaffold';

class Groups extends Component {
  state = {
    showAdd: false,
  };

  toggleShowAdd = () => this.setState(state => ({ showAdd: !state.showAdd }));

  componentWillMount() {
    this.props.getGroups();
  }

  renderPlaceholder() {
    const { t } = this.props;

    return <EmptyPlaceholder buttonText={t('main.addGroup')} onClick={this.toggleShowAdd} />;
  }

  render() {
    const { property, groupsList, groupListLoaded, editGroups, removeGroups, createGroups, t } = this.props;
    const { showAdd } = this.state;

    if (!groupListLoaded) {
      return <Loading />;
    }

    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .trim()
        .max('64', t('errors.requiredName'))
        .required(t('errors.maxName')),
    });

    const mappedList = groupsList.map(group => {
      return (
        <GroupItem
          getGroups={this.props.getGroups}
          createGroups={createGroups}
          editGroups={editGroups}
          removeGroups={removeGroups}
          validationSchema={validationSchema}
          key={group.get('id')}
          data={group}
        />
      );
    });

    return (
      <div>
        <div className="container">
          <SubHeader
            title={t('main.title')}
            actions={[
              groupsList.size > 0 && (
                <button className="button" disabled={showAdd} onClick={this.toggleShowAdd}>
                  {t('main.addGroup')}
                </button>
              ),
            ]}
          />
          <div>
            {mappedList.size === 0 && !showAdd && this.renderPlaceholder()}
            {showAdd ? (
              <Formik
                initialValues={{
                  name: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  const payload = { name: values.name };
                  return createGroups(property.get('id'), payload)
                    .then(action => {
                      if (action.response.ok) {
                        return this.toggleShowAdd();
                      } else {
                        const message = action.response.status === 422 ? action.json.message : 'Could not edit group';
                        actions.setErrors({ name: message });
                        actions.setSubmitting(false);
                      }
                    })
                    .catch(err => actions.setErrors({ submitting: 'Submission Failed' }));
                }}
                render={({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} className="add-group-form">
                    <InputScaffold label={t('item.groupName')} required validation={errors.name}>
                      <input type="text" name="name" onChange={handleChange} value={values.name} />
                    </InputScaffold>

                    <div>{errors.submitting}</div>
                    <button type="submit" disabled={isSubmitting || Object.keys(errors).length} className="button">
                      {t('save', { ns: 'buttons' })}
                    </button>
                    <button className="button button--secondary" onClick={this.toggleShowAdd}>
                      {t('cancel', { ns: 'buttons' })}
                    </button>
                  </form>
                )}
              />
            ) : null}

            {mappedList.size > 0 && (
              <table className="table-groups-list">
                <thead>
                  <tr>
                    <th>{t('main.name')}</th>
                    <th>{t('main.numberOfInputs')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{mappedList}</tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Groups.propTypes = {
  groupsList: PropTypes.object.isRequired,
  groupListLoaded: PropTypes.bool,
  getGroups: PropTypes.func.isRequired,
  createGroups: PropTypes.func.isRequired,
  editGroups: PropTypes.func.isRequired,
};

export default Groups;
