import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import Yup from 'yup';

import InputScaffold from '../../../../../common/forms/InputScaffold';

class AreaForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    submitFunc: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  handleSubmit = async (values, { setSubmitting, setStatus }) => {
    const { submitFunc, onSuccess } = this.props;

    setStatus(null);
    const action = await submitFunc(values);
    setSubmitting(false);

    if (action.response.ok) {
      onSuccess();
    } else {
      setStatus((action.json && action.json.message) || 'Unknown error');
    }
  };

  render() {
    const { initialValues, onCancel } = this.props;

    return (
      <Formik
        initialValues={
          initialValues || {
            name: '',
          }
        }
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .trim()
            .min(3)
            .max(32)
            .label('Name')
            .required('Please enter an Area Name'),
        })}
        onSubmit={this.handleSubmit}
        render={({ values, errors, status, handleChange, handleSubmit, touched, isSubmitting, isValid }) => {
          const isDisabled = isSubmitting || Object.keys(errors).length || !isValid;

          return (
            <form onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius-top-right">
                <div className="page-header subheader underline">
                  <h4 className="h4">Area Information</h4>
                </div>
                <div className="device-form--body">
                  <InputScaffold label="Area Name" required validation={touched.name && errors.name}>
                    <Field type="text" name="name" onChange={handleChange} value={values.name} />
                  </InputScaffold>
                </div>
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <button disabled={isDisabled} className="button">
                  {isSubmitting ? 'Saving Area...' : 'Save Area'}
                </button>
                <button type="button" className="button button--secondary" onClick={onCancel}>
                  Cancel
                </button>
                {status && <div className="input-validation center">{status}</div>}
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export default AreaForm;
