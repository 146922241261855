import { createSelector } from 'reselect';

const unitSelector = state => state.property.unit.activeUnit;

export const selectActiveResident = createSelector(
  unitSelector,
  (state, residentId) => residentId,
  (unit, residentId) => unit.get('residents').find(resident => resident.get('id') === residentId)
);

export const selectUnitHasSmartLock = createSelector(unitSelector, unit => {
  const unitDevices = unit.getIn(['panel', 'devices']);
  if (!unitDevices || !unitDevices.size) {
    return false;
  }

  return unitDevices.reduce((acc, curr) => acc || curr.get('dmp_hardware_type') === 'LOCK', false);
});
