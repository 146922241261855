import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import CredentialTypesList from './views/CredentialTypesList';
import EditCredentialType from './views/EditCredentialType';
import AddCredentialType from './views/AddCredentialType';

class CredentialTypes extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}/`} component={CredentialTypesList} />
        <Route exact path={`${match.path}/:credentialTypeId/edit`} component={EditCredentialType} />
        <Route exact path={`${match.path}/add`} component={AddCredentialType} />
      </Switch>
    );
  }
}

CredentialTypes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default CredentialTypes;
