import React, { Component } from 'react';

export default class ComponentLoading extends Component {
  render() {
    return (
      <div className="ellipsis__wrapper">
        <div className="ellipsis">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      </div>
    );
  }
}
