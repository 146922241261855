import React from 'react';
import Icon from '../../../../../../../../../../common/icons/icon';

export const ResidentRevokeModal = ({ name, isOpen, onCancel, onRevoke, message, onConfirm, onChangeMessage }) => (
  <>
    {isOpen && (
      <div className="modal modal--visible revokeModel-div">
        <div className="modal__children">
          <div className="modal__body">
            <div className="modal__body--text">
              <div className="paper radius-top-left radius-top-right revokeModel-top-div">
                <div className="revokeModel-top-title1">Are you sure you want to revoke access from {name}?</div>
                <div className="revokeModel-top-title2">They will no longer be able take a self-guided tour.</div>
                <div className="revokeModel-top-title3">
                  An automated email will be sent to the prospective resident letting them know their access has been
                  revoked.
                </div>
              </div>
            </div>
            <div className="button-container-deny">
              <button
                className="button-red"
                type="button"
                onClick={() => {
                  if (onRevoke) onRevoke();
                  if (onConfirm) onConfirm(true);
                }}
              >
                <Icon icon="DenyIcon" />
                Revoke Access
              </button>
              <button
                className="button-no"
                type="button"
                onClick={() => {
                  onCancel();
                  if (onConfirm) onConfirm(false);
                }}
              >
                No, Don't Revoke
              </button>
            </div>
            <div className="revokeModel-bottom-div resident-revoke-bottom-div">
              <div className="revokeModel-bottom-title-div radius-top-left radius-top-right">
                <div className="revokeModel-bottom-title1">Please list the reason for revoking access</div>
                <div className="revokeModel-bottom-title2">This message will be sent to the prospective resident.</div>
              </div>
              <textarea
                className="sg-message-textarea"
                rows="9"
                maxLength="255"
                placeholder="Type message here…"
                value={message}
                onChange={onChangeMessage}
              />
            </div>
          </div>
        </div>
        <button type="button" className="modal__overlay" onClick={onCancel} />
      </div>
    )}
  </>
);
