import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch, Route } from 'react-router-dom';

import NotFound from '../../common/NotFound';
import { Cameras } from './views/Cameras';
import { AddCamera } from './views/AddCamera';
import { EditCamera } from './views/EditCamera';
import { CameraInfo } from './views/CameraInfo';
import { Settings } from './views/Settings';
import { ArchivedVideo } from './views/ArchivedVideo';
import { Notifications } from './views/Notifications';

export const CommunityViews = ({ match }) => {
  return (
    <div>
      <Switch>
        <Route exact path={match.path} render={() => <Redirect to={`${match.url}/cameras`} />} />
        <Route path={`${match.path}/settings`} component={Settings} />
        <Route exact path={`${match.path}/cameras`} component={Cameras} />
        <Route exact path={`${match.path}/cameras/add`} component={AddCamera} />
        <Route path={`${match.path}/cameras/:cameraId/edit`} component={EditCamera} />
        <Route path={`${match.path}/cameras/:cameraId/info`} component={CameraInfo} />
        <Route path={`${match.path}/archived-video`} component={ArchivedVideo} />
        <Route path={`${match.path}/notifications`} component={Notifications} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

CommunityViews.propTypes = {
  match: PropTypes.object.isRequired,
};
