import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { Formik, Field } from 'formik';
import Yup from 'yup';

import FailedCodeSyncModal from './components/FailedCodeSyncModal';
import Loading from '../../../../../../../../common/Loading';
import Icon from '../../../../../../../../common/icons/icon';
import Device from './Device';
import NotificationStatusIndicator from '../../../../../../../../common/forms/NotificationStatusIndicator';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import InitializeStatusBar from './InitializeStatusBar';
import {
  initializePanel,
  waitForInitializeSuccess,
  refreshCommunicationOptions,
  setCommunicationOptions,
  waitForConnection,
  setSystemOptions,
  setNetworkOptions,
} from '../../utils/initialize-panel';
import AlertMessage from '../../../../../../../../common/AlertMessage';
import PanelSyncOptions from './components/PanelSyncOptions';

class PropertyPanel extends Component {
  static propTypes = {
    panel: PropTypes.object,
    propertyId: PropTypes.string.isRequired,
    property: PropTypes.object,
    panelDevicesLoading: PropTypes.bool.isRequired,
    getPanelDevices: PropTypes.func.isRequired,
    unrecognizedDevices: PropTypes.object,
    panelDevicesError: PropTypes.bool.isRequired,
    createPanelDevice: PropTypes.func.isRequired,
    recognizedDevices: PropTypes.object,
    updatePanelDevice: PropTypes.func.isRequired,
    removePanelDevice: PropTypes.func.isRequired,
    pairPanelToUnit: PropTypes.func.isRequired,
    getUnpairedPropertyUnits: PropTypes.func.isRequired,
    unpairedPropertyUnits: PropTypes.object,
    panelDevicesPairing: PropTypes.bool.isRequired,
    unpairPanelFromUnit: PropTypes.func.isRequired,
    panelDevicePairingStatus: PropTypes.object,
    getResidents: PropTypes.func,
    getPanelUnitCodes: PropTypes.func,
  };
  state = {
    selectedUnit: '',
    updateStatus: '',
    showDelete: false,
    userPermission: {},
    unitResidents: [],
    failedCodeSyncs: null,
    failedCodeModalVisible: false,
  };
  componentDidMount() {
    const { userPermissionList } = this.props;
    const userPermission = {
      onboardDeviceToSmartHomeController: userPermissionList.includes('ONBOARD_DEVICE_TO_SMART_HOME_CONTROLLER'),
      onboardSmartHomeControllerToUnit: userPermissionList.includes('ONBOARD_SMART_HOME_CONTROLLER_TO_UNIT'),
      removeDeviceFromSmartHomeController: userPermissionList.includes(
        'REMOVE/DELETE_DEVICE_FROM_SMART_HOME_CONTROLLER'
      ),
      viewTheStatusOfSmartHomeDevicesOccupiedUnit: userPermissionList.includes(
        'VIEW_THE_STATUS_OF_SMART_HOME_DEVICES_OCCUPIED_UNIT'
      ),
      viewTheStatusOfSmartHomeDevicesVacantUnit: userPermissionList.includes(
        'VIEW_THE_STATUS_OF_SMART_HOME_DEVICES_VACANT_UNIT'
      ),
      viewResidentContactInformation: userPermissionList.includes('VIEW_RESIDENT_CONTACT_INFORMATION'),
      ViewUnit: userPermissionList.includes('VIEW_UNIT'),
    };

    this.setState({ userPermission: userPermission });

    this.props.getPanelDevices().then(action => {
      if (action && action.response.ok && action.json.unit) {
        this.setState(() => ({
          selectedUnit: {
            label: action.json.unit.number,
            value: action.json.unit.id,
          },
        }));
      } else {
        this.setState(() => ({
          selectedUnit: null,
        }));
      }
    });
    this.props.getUnpairedPropertyUnits();

    this.getResidentsInUnit();

    this.getFailedUnitCodes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.panelDevicePairingStatus !== this.props.panelDevicePairingStatus) {
      this.setState({ updateStatus: this.props.panelDevicePairingStatus.message });
    }
  }

  getFailedUnitCodes = async () => {
    await this.props.getPanelUnitCodes(this.props.match.params.panelId).then(res => {
      this.setState({ failedCodeSyncs: res.json });
    });
  };
  clearUpdate = () => this.setState({ updateStatus: '' });
  handleFetchDevices = () => {
    this.props.getPanelDevices();
  };

  handleChangeSelectedUnit = value => {
    this.setState(() => ({ selectedUnit: value }));
  };
  handlePairUnit = () => {
    const { pairPanelToUnit, getPanelDevices, getUnpairedPropertyUnits, unpairPanelFromUnit, panel } = this.props;
    const { selectedUnit } = this.state;
    if (selectedUnit) {
      if (panel.get('unit')) {
        unpairPanelFromUnit(panel.getIn(['unit', 'id']))
          .then(() => pairPanelToUnit(selectedUnit.value))
          .then(action => {
            if (action.response.ok) {
              getPanelDevices();
              getUnpairedPropertyUnits();
            } else {
              this.setState(() => ({
                selectedUnit: null,
              }));
            }
          });
      } else {
        pairPanelToUnit(selectedUnit.value).then(action => {
          if (action.response.ok) {
            getPanelDevices();
            getUnpairedPropertyUnits();
          }
        });
      }
    } else {
      unpairPanelFromUnit(panel.getIn(['unit', 'id'])).then(action => {
        if (action.response.ok) {
          getPanelDevices();
          getUnpairedPropertyUnits();
        }
      });
    }
  };
  handleDeletePanel = () => {
    if (this.props.panel.get('unit'))
      this.props.unpairPanelFromUnit(this.props.panel.getIn(['unit', 'id'])).then(() => {
        this.props.deletePanel().then(() => {
          this.props.history.push(`/properties/${this.props.property.get('id')}/property-details/devices/properties`);
        });
      });
    this.props.deletePanel().then(() => {
      this.props.history.push(`/properties/${this.props.property.get('id')}/property-details/devices/properties`);
    });
  };

  renderSystemOptions = () => {
    const { panel, setPanelSystemOptions, getJobStatus } = this.props;

    return (
      <div className="system-options__wrapper">
        <h3 className="h3">Update System Options</h3>
        <Formik
          initialValues={{
            house_code: panel.getIn(['systemOptions', 'house_code']) || '',
          }}
          validationSchema={Yup.object().shape({
            house_code: Yup.number()
              .typeError('House code must be a number')
              .integer('House code must be an integer')
              .min(1, 'House code must be 1 or greater')
              .max(50, 'House code must be 50 or less')
              .required('Please enter a house code'),
          })}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            setStatus(null);

            const submitValues = panel.get('systemOptions') ? panel.get('systemOptions').toJS() : {};

            submitValues.house_code = values.house_code;

            const jobRequestResult = await setPanelSystemOptions(panel.get('id'), submitValues);

            if (jobRequestResult.response.ok && jobRequestResult.json.job) {
              let refreshing = true;
              while (refreshing) {
                await new Promise(resolve => setTimeout(resolve, 1000));

                const jobStatusResult = await getJobStatus(jobRequestResult.json.job.uuid);

                if (jobStatusResult.response.ok && jobStatusResult.json) {
                  if (jobStatusResult.json.status === 'success') {
                    break;
                  } else if (jobStatusResult.json.status === 'pending') {
                    refreshing = true;
                  } else {
                    refreshing = false;
                    setStatus(
                      jobStatusResult.json && jobStatusResult.json.message
                        ? jobStatusResult.json.message
                        : 'Something went wrong. Please try again.'
                    );
                  }
                }
              }
            } else {
              setStatus(
                jobRequestResult.json && jobRequestResult.json.message
                  ? jobRequestResult.json.message
                  : 'Something went wrong. Please try again.'
              );
            }

            setSubmitting(false);
          }}
          render={({ values, errors, handleSubmit, isSubmitting, handleBlur, handleChange, status, touched }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <InputScaffold label="House Code" required validation={touched.house_code && errors.house_code}>
                  <div className="device-house-code__wrapper">
                    <input
                      type="text"
                      name="house_code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.house_code}
                    />
                    <button disabled={isSubmitting || Object.keys(errors).length} className="button">
                      Save
                    </button>
                  </div>
                </InputScaffold>
              </div>
              <div>
                <NotificationStatusIndicator type="Failure" message={status} hideNotification={!status} />
              </div>
            </form>
          )}
        />
      </div>
    );
  };

  renderNetworkOptions = () => {
    const { panel, setPanelNetworkOptions, getJobStatus } = this.props;

    return (
      <div className="system-options__wrapper">
        <h3 className="h3">Update Network Options</h3>
        <Formik
          initialValues={{
            house_code: panel.getIn(['systemOptions', 'house_code']) || '',
            p_phr_734n: panel.getIn(['networkOptions', 'p_phr_734n']) || '',
            l_prt_734n: panel.getIn(['networkOptions', 'l_prt_734n']) || '',
          }}
          validationSchema={Yup.object().shape({
            p_phr_734n: Yup.string()
              .min(8)
              .max(16)
              .matches(/^[A-Z0-9]*$/, '734N Passphrase must be alphanumeric')
              .label('734N Passphrase'),
            l_prt_734n: Yup.number()
              .integer()
              .min(1)
              .max(65535)
              .label('734N Port')
              .typeError('734N Port must be a number')
              .required(),
          })}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            setStatus(null);

            const submitValues = panel.get('networkOptions') ? panel.get('networkOptions').toJS() : {};

            submitValues.p_phr_734n = values.p_phr_734n;
            submitValues.l_prt_734n = values.l_prt_734n;

            const jobRequestResult = await setPanelNetworkOptions(panel.get('id'), submitValues);

            if (jobRequestResult.response.ok && jobRequestResult.json.job) {
              let refreshing = true;
              while (refreshing) {
                await new Promise(resolve => setTimeout(resolve, 1000));

                const jobStatusResult = await getJobStatus(jobRequestResult.json.job.uuid);

                if (jobStatusResult.response.ok && jobStatusResult.json) {
                  if (jobStatusResult.json.status === 'success') {
                    break;
                  } else if (jobStatusResult.json.status === 'pending') {
                    refreshing = true;
                  } else {
                    refreshing = false;
                    setStatus(
                      jobStatusResult.json && jobStatusResult.json.message
                        ? jobStatusResult.json.message
                        : 'Something went wrong. Please try again.'
                    );
                  }
                }
              }
            } else {
              setStatus(
                jobRequestResult.json && jobRequestResult.json.message
                  ? jobRequestResult.json.message
                  : 'Something went wrong. Please try again.'
              );
            }

            setSubmitting(false);
          }}
          render={({ values, errors, handleSubmit, isSubmitting, handleChange, status, touched, setFieldValue }) => (
            <form className="network-options__wrapper" onSubmit={handleSubmit}>
              <div>
                <InputScaffold label="734N Passphrase" validation={touched.p_phr_734n && errors.p_phr_734n} required>
                  <Field
                    type="text"
                    maxLength={16}
                    name="p_phr_734n"
                    onChange={e => {
                      let value = e.target.value || '';
                      value = value.toUpperCase();
                      setFieldValue('p_phr_734n', value);
                    }}
                    value={values.p_phr_734n}
                  />
                </InputScaffold>
                <InputScaffold label="734N Port" validation={touched.l_prt_734n && errors.l_prt_734n} required>
                  <Field type="text" name="l_prt_734n" onChange={handleChange} value={values.l_prt_734n} />
                </InputScaffold>
              </div>

              <div className="network-options--button__wrapper">
                <button disabled={isSubmitting || Object.keys(errors).length} className="button">
                  Save
                </button>
                <NotificationStatusIndicator type="Failure" message={status} hideNotification={!status} />
              </div>
            </form>
          )}
        />
      </div>
    );
  };
  getResidentsInUnit = async () => {
    const { getResidents, panel } = this.props;
    const { unitResidents } = this.state;

    let residents;

    await getResidents().then(result => {
      residents = result.json.results;
    });

    for (let resident of residents) {
      if (resident.unit_id === panel.getIn(['unit', 'id'])) {
        unitResidents.push(resident);
      }
    }
  };

  renderRecognizedDevices = () => {
    const {
      recognizedDevices,
      updatePanelDevice,
      removePanelDevice,
      panel,
      removeVideoDoorbellDevice,
      unpairVideoDoorbellDevice,
      property,
    } = this.props;
    const approvedDevices = property.get('enable_video_doorbells')
      ? recognizedDevices
      : recognizedDevices.filter(device => device.get('dmp_hardware_type') !== 'DOORBELL');

    const { userPermission, unitResidents } = this.state;

    let show_device_list = false;
    if (unitResidents?.length === 0) {
      show_device_list = userPermission.viewTheStatusOfSmartHomeDevicesVacantUnit;
    } else {
      show_device_list = userPermission.viewTheStatusOfSmartHomeDevicesOccupiedUnit;
    }

    if (!approvedDevices?.size) {
      return null;
    }
    return (
      <div className="devices__recognized">
        <h3 className="h3">Recognized</h3>
        {show_device_list && (
          <div className="device--list">
            {approvedDevices.map(device => (
              <Device
                name={device.get('name')}
                key={device.get('id')}
                id={device.get('id')}
                type={device.get('dmp_hardware_type')}
                subtype={device.get('dmp_hardware_subtype')}
                isCommunityDevice={device.get('is_community_device')}
                hideBatteryStatus={device.get('hide_battery_status')}
                submitAction={updatePanelDevice}
                removeAction={
                  device.get('dmp_hardware_type') === 'DOORBELL' ? removeVideoDoorbellDevice : removePanelDevice
                }
                unpairVideoDoorbellDevice={unpairVideoDoorbellDevice}
                isMissing={!device.has('zwaveSetup') && panel.get('dmp_account_prefix') !== 0}
                zone={device.get('zone')}
                mastercode={device.get('mastercode')}
                removeDeviceFromSmartHomeController={userPermission.removeDeviceFromSmartHomeController}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  renderUnrecognizedDevices = () => {
    const { unrecognizedDevices, createPanelDevice, property } = this.props;
    const { userPermission, unitResidents } = this.state;
    const approvedDevices = property.get('enable_video_doorbells')
      ? unrecognizedDevices
      : unrecognizedDevices.filter(device => device.get('dmp_hardware_type') !== 'DOORBELL');
    let show_device_list = false;
    if (unitResidents?.length === 0) {
      show_device_list = userPermission.viewTheStatusOfSmartHomeDevicesVacantUnit;
    } else {
      show_device_list = userPermission.viewTheStatusOfSmartHomeDevicesOccupiedUnit;
    }

    if (!approvedDevices?.size) {
      return null;
    }

    return (
      <div className="devices__unrecognized">
        <h3 className="h3">Unrecognized</h3>
        {show_device_list && (
          <div className="device--list">
            {approvedDevices.map(device => (
              <Device
                name={null}
                key={device.get('name')}
                id={device.get('name')}
                type={device.get('node_type')}
                subtype={device.get('node_subtype')}
                isCommunityDevice={null}
                hideBatteryStatus={device.get('hide_battery_status')}
                submitAction={createPanelDevice}
                zone={parseInt(device.get('zone'), 10)}
                mastercode={null}
                removeDeviceFromSmartHomeController={userPermission.removeDeviceFromSmartHomeController}
              />
            ))}
          </div>
        )}
      </div>
    );
  };
  renderHeader = () => {
    return (
      <div className="page-header page-header__custom">
        <div className="container">
          <Link to={`../`} className="back-arrow">
            <Icon icon="ArrowLeft" />
            Back
          </Link>
          <h1 className="h1">Devices &bull; {this.props.property?.get('name')}</h1>
        </div>
      </div>
    );
  };

  renderPairUnit = () => {
    const { unpairedPropertyUnits, panel, panelDevicesPairing } = this.props;
    const { selectedUnit, userPermission } = this.state;
    const deleteRequirements = [];
    let options = [];
    let disabledButton = false;

    if (panel.get('property')) {
      return (
        <div className="panel__associate-to-unit">
          <AlertMessage msg="This panel is a property master panel and cannot be associated with a unit or deleted." />
          {panel.getIn(['systemOptions', 'house_code']) && this.renderSystemOptions()}
          {panel.get('dmp_system_type') === 'XR550' && this.renderNetworkOptions()}
        </div>
      );
    }

    if (!unpairedPropertyUnits || this.state.selectedUnit === '') {
      disabledButton = true;
    }

    if (unpairedPropertyUnits) {
      options =
        unpairedPropertyUnits
          .filter(unit => unit.get('id') !== panel.getIn(['unit', 'id']))
          .map(unit => ({ label: unit.get('number'), value: unit.get('id') }))
          .toArray() || null;
    }

    if (panel.get('unit')) {
      deleteRequirements.push('Panel associated with unit');
      options.unshift({
        value: panel.getIn(['unit', 'id']),
        label: panel.getIn(['unit', 'number']),
      });
    }

    return (
      <div className="panel__associate-to-unit">
        <h3 className="h3">Associate with a Unit</h3>
        <div className="choose-unit__scaffold">
          <div className="react-select-scaffold">
            <div className="label">Choose a Unit</div>
            <Select
              closeOnSelect={true}
              onChange={this.handleChangeSelectedUnit}
              options={options}
              placeholder={'Unit'}
              value={this.state.selectedUnit}
              disabled={!userPermission.onboardSmartHomeControllerToUnit}
            />
          </div>
          <button
            className="button"
            onClick={this.handlePairUnit}
            disabled={
              panelDevicesPairing ||
              (!panel.get('unit') && this.state.selectedUnit === null) ||
              (selectedUnit && selectedUnit.value === panel.getIn(['unit', 'id'])) ||
              disabledButton
            }
          >
            {panel.get('unit') && this.state.selectedUnit === null ? 'Disassociate' : 'Associate'}
          </button>
        </div>
        {panel.getIn(['systemOptions', 'house_code']) && this.renderSystemOptions()}
        {panel.get('dmp_system_type') === 'XR550' && this.renderNetworkOptions()}
        <div className="delete-panel__scaffold">
          <h3 className="h3">Delete this panel</h3>
          {this.renderDeletePanel(deleteRequirements)}
        </div>
      </div>
    );
  };

  renderDeletePanel = (deleteRequirements = []) => {
    if (this.state.showDelete) {
      return (
        <div>
          <p className="font-small text-error person-card__remove-text">
            This panel may just be temporarily offline.
            <br />
            Are you sure you want to <strong className="strong">delete this panel?</strong>
          </p>
          <button
            className="button button--destructive"
            style={{ marginRight: '12px' }}
            onClick={this.handleDeletePanel}
          >
            Yes, Delete
          </button>
          <button
            type="button"
            className="button button--secondary--destructive"
            onClick={() => {
              this.setState({ showDelete: false });
            }}
          >
            Cancel
          </button>
        </div>
      );
    }
    return (
      <div className="delete-panel__scaffold">
        {deleteRequirements.length > 0 ? (
          <div className="delete-reqs__wrapper">
            <div className="delete-reqs--title">Can't delete because:</div>
            <div className="delete-reqs--list">
              {deleteRequirements.map(req => (
                <div className="delete-req" key={req}>
                  • {req}
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <button
          className="button button--destructive"
          type="button"
          onClick={() => {
            this.setState({ showDelete: true });
          }}
          disabled={deleteRequirements.length > 0}
        >
          Delete Panel
        </button>
      </div>
    );
  };

  renderEmptyState() {
    return (
      <div className="empty-state">
        <h1 className="h1">No Devices Found</h1>
        <p>
          If you are adding Z-wave devices to this panel for the first time, we recommend starting with a device that is
          a Z-wave repeater (typically a light switch). This will make the addition of subsequent Z-wave devices easier
          and faster.
        </p>
        <Icon icon="Robot" />
      </div>
    );
  }

  initializePanel = async () => {
    const {
      panel,
      initializePanel: dispatchInitializePanel,
      checkInitializePanelStatus,
      checkPanelEasyConnectStatus,
    } = this.props;

    this.setState({
      initializeInProgress: true,
      initializeError: null,
      initializeSuccess: false,
    });

    try {
      await waitForConnection(panel.get('id'), panel.dmp_connection_type, checkPanelEasyConnectStatus);
      const result = await initializePanel(panel.get('id'), dispatchInitializePanel);

      await waitForInitializeSuccess(
        panel.get('id'),
        result.json.SchedulerJobs[0]['SchedulerJobGroupId'],
        checkInitializePanelStatus
      );

      this.setState({
        initializeInProgress: false,
        initializeSuccess: true,
      });
    } catch (e) {
      this.setState({
        initializeInProgress: false,
        initializeError: e.message,
      });
      return;
    }

    await this.setPanelCommunicationOptions();
  };

  setPanelCommunicationOptions = async () => {
    const {
      match,
      setPanelCommunicationOptions,
      getJobStatus,
      updatePanel,
      refreshPanelCommunicationOptions,
    } = this.props;

    this.setState({
      communicationOptionsInProgress: true,
      communicationOptionsError: null,
      communicationOptionsSuccess: false,
    });

    try {
      await refreshCommunicationOptions(match.params.panelId, refreshPanelCommunicationOptions, getJobStatus);
      await setCommunicationOptions(match.params.panelId, setPanelCommunicationOptions, getJobStatus);
      await updatePanel(match.params.panelId, { dmp_is_communication_options_set: true });
      this.setState({
        communicationOptionsInProgress: false,
        communicationOptionsSuccess: true,
        panelInitializedSuccess: true,
      });
    } catch (e) {
      this.setState({
        communicationOptionsInProgress: false,
        communicationOptionsError: e.message,
      });
      return;
    }

    await this.setPanelSystemOptions();
  };

  setPanelSystemOptions = async () => {
    const { match, setPanelSystemOptions, getJobStatus, updatePanel, panel } = this.props;

    this.setState({
      systemOptionsInProgress: true,
      systemOptionsError: null,
      systemOptionsSuccess: false,
    });

    try {
      const submitValues = panel.get('systemOptions') ? panel.get('systemOptions').toJS() : {};

      submitValues.house_code = panel.get('dmp_house_code');

      await setSystemOptions(match.params.panelId, submitValues, setPanelSystemOptions, getJobStatus);
      await updatePanel(match.params.panelId, { dmp_is_system_options_set: true });
      this.setState({
        systemOptionsInProgress: false,
        systemOptionsSuccess: true,
        communicationOptionsSuccess: true,
        panelInitializedSuccess: true,
      });
    } catch (e) {
      this.setState({
        systemOptionsInProgress: false,
        systemOptionsError: e.message,
      });
      return;
    }

    await this.setPanelNetworkOptions();
  };

  setPanelNetworkOptions = async () => {
    const { match, setPanelNetworkOptions, getJobStatus, updatePanel, panel } = this.props;

    this.setState({
      networkOptionsInProgress: true,
      networkOptionsError: null,
      networkOptionsSuccess: false,
    });

    try {
      if (panel.get('dmp_system_type') === 'XR550') {
        const submitValues = panel.get('networkOptions') ? panel.get('networkOptions').toJS() : {};

        submitValues.p_phr_734n = panel.get('dmp_734n_passphrase');
        submitValues.l_prt_734n = panel.get('dmp_734n_port');

        await setNetworkOptions(match.params.panelId, submitValues, setPanelNetworkOptions, getJobStatus);
      }
      await updatePanel(match.params.panelId, { dmp_is_network_options_set: true });
      this.setState(
        {
          networkOptionsInProgress: false,
          networkOptionsSuccess: true,
          systemOptionsSuccess: true,
          communicationOptionsSuccess: true,
          panelInitializedSuccess: true,
        },
        this.componentDidMount
      );
    } catch (e) {
      this.setState({
        networkOptionsInProgress: false,
        networkOptionsError: e.message,
      });
      return;
    }
  };

  renderFailedInitialize = () => {
    const { panel } = this.props;

    const {
      initializeInProgress,
      initializeError,
      initializeSuccess,
      communicationOptionsInProgress,
      communicationOptionsError,
      communicationOptionsSuccess,
      systemOptionsSuccess,
      systemOptionsInProgress,
      systemOptionsError,
      networkOptionsSuccess,
      networkOptionsInProgress,
      networkOptionsError,
    } = this.state;

    let initializeStatus = 'FAILURE';

    if (panel.get('dmp_is_initialized') || initializeSuccess) {
      initializeStatus = 'SUCCESS';
    } else if (initializeInProgress) {
      initializeStatus = 'STARTED';
    } else if (initializeError) {
      initializeStatus = 'FAILURE';
    }

    let communicationOptionStatus = 'PENDING';

    if (panel.get('dmp_is_communication_options_set') || communicationOptionsSuccess) {
      communicationOptionStatus = 'SUCCESS';
    } else if (communicationOptionsInProgress) {
      communicationOptionStatus = 'STARTED';
    } else if (communicationOptionsError) {
      communicationOptionStatus = 'FAILURE';
    } else if (initializeStatus === 'SUCCESS') {
      communicationOptionStatus = 'FAILURE';
    }

    let systemOptionsStatus = 'PENDING';

    if (panel.get('dmp_is_system_options_set') || systemOptionsSuccess) {
      systemOptionsStatus = 'SUCCESS';
    } else if (systemOptionsInProgress) {
      systemOptionsStatus = 'STARTED';
    } else if (systemOptionsError) {
      systemOptionsStatus = 'FAILURE';
    } else if (communicationOptionStatus === 'SUCCESS') {
      systemOptionsStatus = 'FAILURE';
    }

    let networkOptionsStatus = 'PENDING';

    if (panel.get('dmp_is_network_options_set') || networkOptionsSuccess) {
      networkOptionsStatus = 'SUCCESS';
    } else if (networkOptionsInProgress) {
      networkOptionsStatus = 'STARTED';
    } else if (networkOptionsError) {
      networkOptionsStatus = 'FAILURE';
    } else if (systemOptionsStatus === 'SUCCESS') {
      networkOptionsStatus = 'FAILURE';
    }

    let isSuccessful =
      (panel.get('dmp_is_initialized') || initializeSuccess) &&
      (panel.get('dmp_is_communication_options_set') || communicationOptionsSuccess) &&
      (panel.get('dmp_is_system_options_set') || systemOptionsSuccess) &&
      (panel.get('dmp_is_network_options_set') || networkOptionsSuccess);

    return (
      <div className="failed-initialized__wrapper">
        <InitializeStatusBar status={initializeStatus} onButtonClick={this.initializePanel}>
          {initializeStatus === 'FAILURE' ? 'Failed to Initialize' : 'Initializing'}
        </InitializeStatusBar>
        <InitializeStatusBar status={communicationOptionStatus} onButtonClick={this.setPanelCommunicationOptions}>
          Setting Communication Options
        </InitializeStatusBar>
        <InitializeStatusBar status={systemOptionsStatus} onButtonClick={this.setPanelSystemOptions}>
          Setting System Options
        </InitializeStatusBar>
        <InitializeStatusBar status={networkOptionsStatus} onButtonClick={this.setPanelNetworkOptions}>
          Setting Network Options
        </InitializeStatusBar>
        <InitializeStatusBar status={isSuccessful ? 'SUCCESS' : 'PENDING'}>Added Successfully</InitializeStatusBar>
      </div>
    );
  };

  renderModal() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FailedCodeSyncModal
          setModalVisible={() => this.setState({ failedCodeModalVisible: !this.state.failedCodeModalVisible })}
          getFailedCodes={() => this.getFailedUnitCodes()}
          failedCodes={this.state.failedCodeSyncs}
        />
      </div>
    );
  }
  render() {
    const { panel, match, panelDevicesError, panelDevicesLoading, panelDevicePairingStatus } = this.props;
    const { updateStatus, panelInitializedSuccess, userPermission } = this.state;

    if (panelDevicesLoading) {
      return <Loading />;
    }

    if (panelDevicesError) {
      return (
        <div>
          {this.renderHeader()}
          <div className="container">
            <p>An error occurred while refreshing the panel.</p>
            <button className="button" style={{ marginRight: '12px' }} type="button" onClick={this.handleFetchDevices}>
              Try Again
            </button>
            {this.renderDeletePanel()}
          </div>
        </div>
      );
    }

    if (
      !panel.get('dmp_is_initialized') ||
      !panel.get('dmp_is_communication_options_set') ||
      !panel.get('dmp_is_system_options_set') ||
      !panel.get('dmp_is_network_options_set')
    ) {
      return (
        <div>
          {this.renderHeader()}
          <div className="container">
            {this.renderFailedInitialize()}
            {this.renderDeletePanel()}
          </div>
        </div>
      );
    }

    const unrecognizedDevices = this.renderUnrecognizedDevices();
    const recognizedDevices = this.renderRecognizedDevices();

    const isClubhousePanel = !!panel.get('property');
    const unitNumber = panel.getIn(['unit', 'id']) ? (
      <h4 className="h4">
        Unit #:{' '}
        {userPermission.ViewUnit ? (
          <Link to={`/properties/${panel.get('property_id')}/units/${panel.getIn(['unit', 'id'])}`}>
            {panel.getIn(['unit', 'number'])}
          </Link>
        ) : (
          <>{panel.getIn(['unit', 'number'])}</>
        )}
      </h4>
    ) : (
      <h4 className="h4">Unit #: none</h4>
    );

    return (
      <div className="devices__device-list">
        {this.renderHeader()}

        {this.state.failedCodeModalVisible && this.renderModal()}
        <div className="container">
          <div className="page-header subheader panel-subheader">
            <div className="page-header__title">
              <div>
                <h4 className="h4">Panel SN: {panel.get('dmp_serial_number')}</h4>
                <h4 className="h4">
                  Account #: {panel.get('dmp_account_prefix')}-{panel.get('dmp_account_number')}
                </h4>

                {isClubhousePanel ? <h4 className="h4">Clubhouse</h4> : unitNumber}
                {panelInitializedSuccess && this.renderFailedInitialize()}
              </div>
            </div>

            <div className="page-header__actions">
              <div className="add-remove-device__wrapper">
                {userPermission.removeDeviceFromSmartHomeController && (
                  <Link className="button button--outline--destructive" to={`${match.url}/remove-device`}>
                    Remove Device
                  </Link>
                )}
                {userPermission.onboardDeviceToSmartHomeController && (
                  <Link className="button" to={`${match.url}/add-device`}>
                    Add Device
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="dual--device-list">
            {unrecognizedDevices}
            {recognizedDevices}
          </div>
          {!unrecognizedDevices && !recognizedDevices && this.renderEmptyState()}
          {this.renderPairUnit()}
          <NotificationStatusIndicator
            hideNotification={!updateStatus}
            message={panelDevicePairingStatus.message}
            type={panelDevicePairingStatus.success ? 'Success' : 'Failure'}
            updateFunc={this.clearUpdate}
          />
          <PanelSyncOptions
            panelId={panel.get('id')}
            setModalVisible={() => this.setState({ failedCodeModalVisible: !this.state.failedCodeModalVisible })}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(PropertyPanel);
