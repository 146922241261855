import React, { useEffect, useState } from 'react';
import PropertyPageHeader from '../../../../../common/PropertyPageHeader';
import NotFound from '../../../common/NotFound';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  propertySelector,
  communityViewsCamerasSelector,
  communityViewsStreamsSelector,
} from '../../../redux/selectors';
import { getCommunityViewsCameras, requestCommunityViewsStream } from '../../../redux/actions/communityViewsActions';
import { HourBasedBarGraph } from '../../../../../common/HourBasedBarGraph/HourBasedBarGraph';
import { HLSVideoPlayer } from '../../../../../common/HLSVideoPlayer/HLSVideoPlayer';
import Icon from '../../../../../common/icons/icon';

export function CameraInfo({ match }) {
  const { cameraId, propertyId } = match.params;
  const dispatch = useDispatch();

  const property = useSelector(propertySelector);
  const cameras = useSelector(communityViewsCamerasSelector);
  const streams = useSelector(communityViewsStreamsSelector);
  const camera = cameras?.find(camera => camera.id === cameraId);

  useEffect(() => {
    dispatch(getCommunityViewsCameras(propertyId));
  }, [propertyId, dispatch]);

  useEffect(() => {
    if (typeof camera !== 'undefined' && camera.enabled_streaming) {
      dispatch(requestCommunityViewsStream(cameraId, propertyId));
    }
  }, [cameraId, camera, dispatch, propertyId]);

  if (typeof property !== 'undefined' && typeof camera === 'undefined') {
    return <NotFound />;
  }
  const currentStream = streams ? streams[cameraId] : undefined;

  return (
    <>
      <PropertyPageHeader title={camera.camera_name} backLink="../" />
      <div className="container">
        <div className="paper radius-top-left radius-top-right radius-bottom-left radius-bottom-right">
          {currentStream && camera.enabled_streaming && <HLSVideoPlayer url={currentStream} />}
          {camera.enabled_video_archiving && (
            <div className="camera-archiving-enabled">
              <Icon icon="InfoOutline" />
              <span>Archiving Enabled</span>
            </div>
          )}
          {camera.enabled_people_counting && (
            <ActivityIndicator data={camera.people_count_hourly_averages} width={360} startHour={6} endHour={22} />
          )}
        </div>
      </div>
    </>
  );
}

function ActivityIndicator({
  data,
  startHour,
  endHour,
  width,
  labeledHours = [8, 12, 16, 20],
  selectedDay = new Date().getDay(),
}) {
  function onClickDay(day) {
    return () => {
      setCurrentDay(day);
    };
  }

  function hourIsWithinViewingWindow(hour) {
    return hour > startHour + 1 && hour < endHour - 1;
  }

  const [currentDay, setCurrentDay] = useState(selectedDay);

  const dataByDayAndHour = data.reduce(
    (acc, { day, hour, number_of_counts, sum }) => ({
      ...acc,
      [day]: { ...acc[day], [hour]: number_of_counts === 0 ? 0 : sum / number_of_counts },
    }),
    {}
  );

  const trimmedData = Object.entries(dataByDayAndHour[currentDay] ?? {}).reduce(
    (acc, [hour, average]) => (hourIsWithinViewingWindow(hour) ? { ...acc, [hour]: average } : acc),
    {}
  );

  return (
    <div className="activity-indicator-container">
      <h4 className="h4">Popular Times</h4>
      <div className="activitiy-indicator-labels">
        <ul>
          <li>
            <button className={currentDay === 0 ? 'selected' : ''} onClick={onClickDay(0)}>
              S
            </button>
          </li>
          <li>
            <button className={currentDay === 1 ? 'selected' : ''} onClick={onClickDay(1)}>
              M
            </button>
          </li>
          <li>
            <button className={currentDay === 2 ? 'selected' : ''} onClick={onClickDay(2)}>
              T
            </button>
          </li>
          <li>
            <button className={currentDay === 3 ? 'selected' : ''} onClick={onClickDay(3)}>
              W
            </button>
          </li>
          <li>
            <button className={currentDay === 4 ? 'selected' : ''} onClick={onClickDay(4)}>
              T
            </button>
          </li>
          <li>
            <button className={currentDay === 5 ? 'selected' : ''} onClick={onClickDay(5)}>
              F
            </button>
          </li>
          <li>
            <button className={currentDay === 6 ? 'selected' : ''} onClick={onClickDay(6)}>
              S
            </button>
          </li>
        </ul>
      </div>
      <div className="activity-indicator-data">
        {data && (
          <HourBasedBarGraph
            width={width}
            height={150}
            startHour={startHour}
            endHour={endHour}
            labeledHours={labeledHours}
            data={trimmedData}
          />
        )}
      </div>
    </div>
  );
}
