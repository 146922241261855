import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import AdminForm from '../../AdminForm';
import Loading from '../../../../common/Loading';

class EditAdminUser extends Component {
  static propTypes = {
    adminId: PropTypes.string.isRequired,
    admin: PropTypes.instanceOf(Map),
    getAdmins: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
  };

  state = {
    userPermission: {},
    error: null,
  };

  componentDidMount() {
    const { adminList, getAdmins, userPermissionList } = this.props;
    const userPermission = {
      editAdminUserRoleOrPermissions: userPermissionList.includes('EDIT_ADMIN_USER_ROLE_OR_PERMISSIONS'),
      deleteAdminUser: userPermissionList.includes('DELETE_ADMIN_USER'),
    };
    this.setState({ userPermission: userPermission });

    if (!adminList.size) {
      getAdmins();
    }
    this.props.getRolesByRoleLevel('ADMIN');
  }

  render() {
    const { adminId, admin, removeAdmin, loading, history, roleOptions, user } = this.props;
    const { userPermission, error } = this.state;

    if (loading) {
      return <Loading />;
    }

    if (!admin) {
      return 'User does not exist';
    }
    return (
      <div>
        <AdminForm
          adminId={adminId}
          userPermission={userPermission}
          error={error}
          roleOptions={roleOptions}
          user={user}
          onSubmit={values => {
            return this.props.editAdminUser(values).then(action => {
              if (action.response.ok) {
                history.push('/admin-management');
              } else {
                this.setState({ error: action.json.message });
              }
            });
          }}
          initialValues={{
            first_name: admin.get('first_name'),
            last_name: admin.get('last_name'),
            email: admin.get('email'),
            phone: admin.get('phone') || '',
            company_name: admin.get('company_name') || '',
            role_id: admin.get('role_id') || '',
            updated_by: user.get('id'),
          }}
          removeAdmin={removeAdmin}
        />
      </div>
    );
  }
}

export default EditAdminUser;
