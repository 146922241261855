import React, { Component } from 'react';
import InputScaffold from '../../../../../common/forms/InputScaffold';

import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css';
import 'react-day-picker/lib/style.css';
import { formatPhoneNumber } from '../../../../../common/utils/helpers';
import Select from 'react-select';
import Search from '../../../../../common/icons/Search';
import PropTypes from 'prop-types';
import Loading from '../../../../../common/Loading';

import UserForm from '../../../common/UserForm/UserForm';
import Close from '../../../../../common/icons/Close';

class User extends Component {
  static propTypes = {
    addUsers: PropTypes.func.isRequired,
    editUsers: PropTypes.func.isRequired,
    removeUsers: PropTypes.func.isRequired,
    rolesListLoading: PropTypes.bool,
  };
  state = {
    error: null,
    userInfo: null,
    userId: null,
    search: '',
    searchType: '',
    searchClose: '',
    type: 'list',
    tooltip: '',
    userLoading: false,
    userPermission: {},
    bottomHeight: 0,
    selectedRole: '',
    selecedtProperty: '',
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }
  componentDidMount() {
    const { userPermissionList } = this.props;
    const userPermission = {
      EditRolesPermissionUser: userPermissionList.includes('EDIT_ROLES_&_PERMISSIONS_USER'),
      DeleteRolesPermissionsUser: userPermissionList.includes('DELETE_ROLES_&_PERMISSIONS_USER'),
    };
    this.setState({ userPermission: userPermission, searchType: '' });
    this.props.getRoles();
    this.props.getPropertys();
  }

  onSearchUser = event => {
    if (this.state.search !== '' || this.state.selectedRole !== '' || this.state.selecedtProperty !== '') {
      this.setState({ searchClose: 'Close', searchType: 'search', type: 'list', error: null });
      const payload = {};
      payload.role_id =
        this.state.selectedRole === '' || this.state.selectedRole === null ? '*' : this.state.selectedRole;
      payload.property_id =
        this.state.selecedtProperty === '' || this.state.selecedtProperty === null ? '*' : this.state.selecedtProperty;
      payload.username = this.state.search === '' ? '*' : this.state.search;
      this.props.getUsers(payload);
    } else {
      this.setState({ searchType: '' });
    }
  };

  updateAfterDelete = payload => {
    this.props.getUsers(payload);
  };
  onUpdateSearch = event => {
    this.setState({ search: event.target.value, type: 'list', searchClose: '' });
  };

  onSearchClose = event => {
    this.setState({
      search: '',
      searchType: '',
      type: 'list',
      searchClose: '',
      selectedRole: '',
      selecedtProperty: '',
    });
  };

  updateUserList = (afterDelete, payload) => {
    if (afterDelete) {
      this.updateAfterDelete(payload);
    }
    this.setState({ type: 'list' });
  };

  onEditUser = (user, e) => {
    if (user.get('users_roles')) {
      if (user.get('users_roles').get('role') === 'PROPERTY_MANAGER' && user.get('properties').size > 0) {
        this.props.getRolesByUser(user.get('id'));
      } else {
        this.props.getRolesByRoleLevel(user.get('users_roles').get('role'));
      }
    } else {
      this.props.getRolesByRoleLevel(null);
    }
    if (user.get('role').size === 0) {
      this.props.getGroupPermission();
    }
    this.setState(
      {
        type: 'edit',
        userInfo: user,
        userId: user.get('id'),
        userLoading: true,
      },
      () => {
        this.setState({ userLoading: false });
      }
    );
  };
  onMouseOver = (id, e) => {
    this.setState({ tooltip: id });
    let titleHeight = 125;
    let offsetTop = e.target.offsetTop;
    let browserHeight =
      e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.clientHeight;
    let bottomHeight = browserHeight - titleHeight - offsetTop - 45;
    if (offsetTop > bottomHeight) {
      this.setState({ bottomHeight: offsetTop, upwardOffset: offsetTop > bottomHeight });
    } else {
      this.setState({ bottomHeight: bottomHeight, upwardOffset: offsetTop > bottomHeight });
    }
  };
  onMouseOverTips = e => {
    let clientHeight = e.target.clientHeight;
    this.setState({ offsetHeight: clientHeight - 27 });
  };
  onMouseOut = e => {
    this.setState({ tooltip: '', bottomHeight: 0, offsetHeight: 0 });
  };
  onKeyEnter = event => {
    if (event.key === 'Enter') {
      this.onSearchUser();
    }
  };

  formatName = (name, length = 12) => {
    return name?.length > length ? `${name.substr(0, length)}...` : name;
  };

  render() {
    const { user, users, removeUsers, roleOptions, permissionList, roleOptions2, propertyOptions } = this.props;

    const getOptions = options =>
      options.map(option => ({
        ...option,
        label: option.label.length > 30 ? `${option.label.substr(0, 30)}...` : option.label,
      }));

    const {
      error,
      userInfo,
      userId,
      search,
      searchType,
      searchClose,
      type,
      tooltip,
      userLoading,
      userPermission,
      bottomHeight,
      upwardOffset,
      offsetHeight,
      selectedRole,
      selecedtProperty,
    } = this.state;
    if (userLoading) {
      return <Loading />;
    }
    const sty = {
      maxHeight: bottomHeight + 'px',
      top: (upwardOffset ? (offsetHeight === 0 ? -27 : -offsetHeight) : 0) + 'px',
    };
    return (
      <div className="container PR-Container">
        {type === 'list' && (
          <div>
            <div className="RP-User-Search">
              <div className="RP-User-Search-Title">User Search</div>
              <div className="RP-User-Search-Out-Div">
                <InputScaffold>
                  <input
                    name="RolesAndPermissions"
                    className="RP-User-Search-MaskedInput RP-User-Search-Main-Input"
                    value={search}
                    onChange={this.onUpdateSearch}
                    placeholder="Search Users…"
                    autoComplete="off"
                    onKeyPress={this.onKeyEnter}
                    maxLength="25"
                  />
                  <div
                    className="RP-User-Search-icons"
                    onClick={searchClose === '' ? this.onSearchUser : this.onSearchClose}
                  >
                    {searchClose === '' ? <Search /> : <Close />}
                  </div>
                </InputScaffold>
                <InputScaffold className="RP-User-Search-role">
                  <Select
                    options={getOptions(roleOptions2)}
                    placeholder={roleOptions2.length ? 'Select a role' : 'No role available'}
                    value={selectedRole}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        this.setState({
                          selectedRole: selectedOption.value,
                          searchClose: '',
                        });
                      } else {
                        this.setState({
                          selectedRole: '',
                          searchClose: '',
                        });
                      }
                    }}
                  />
                </InputScaffold>
                <InputScaffold className="RP-User-Search-property">
                  <Select
                    options={getOptions(propertyOptions)}
                    placeholder={propertyOptions.length ? 'Select a property' : 'No property available'}
                    value={selecedtProperty}
                    onChange={selectedOption => {
                      if (selectedOption) {
                        this.setState({
                          selecedtProperty: selectedOption.value,
                          searchClose: '',
                        });
                      } else {
                        this.setState({
                          selecedtProperty: '',
                          searchClose: '',
                        });
                      }
                    }}
                  />
                </InputScaffold>
              </div>
            </div>
            {searchType === 'search' && (
              <div>
                <div className="RP-User-Search-Info"> User search results for “{search}”</div>
                {users.size > 0 && (
                  <div className="RP-User-Table">
                    <div className="RP-User-Table-Title">
                      <div className="RP-User-Title-Name">NAME</div>
                      <div style={{ width: '100px' }}>
                        <div className="RP-User-Title-Role">COMPANY</div>
                      </div>
                      <div>ROLE</div>
                      <div>ROLE TYPE</div>
                      <div>PROPERTY</div>
                      <div>PHONE NUMBER</div>
                      <div className="RP-User-Title-Edit" />
                    </div>

                    {users.map((user, index) => {
                      if (user.get('role').size > 0) {
                        console.log(user.get('role'));
                      }
                      return (
                        <div className="RP-User-Table-List" key={index}>
                          <div className="RP-User-Table-Name">
                            <div className="RP-User-Table-Name-Top">
                              {this.formatName(user.get('first_name'))}
                              &nbsp;
                              {this.formatName(user.get('last_name'), 20)}
                            </div>
                            <div className="RP-User-Table-Name-Bottom">{user.get('email')}</div>
                          </div>
                          <div
                            style={{ width: '100px' }}
                            className="RP-User-Table-Company"
                            onMouseEnter={this.onMouseOver.bind(this, user.get('email'))}
                            onMouseLeave={this.onMouseOut.bind(this)}
                          >
                            <div>{this.formatName(user.get('company_name'))}</div>

                            {tooltip === user.get('email') && user?.get('company_name')?.length > 12 && (
                              <div className="RP-User-Table-Tips" onMouseEnter={this.onMouseOverTips.bind(this)}>
                                <b className="RP-User-Table-Tips-Title">{user.get('company_name')}</b>
                              </div>
                            )}
                          </div>
                          {user.get('role').size > 0 ? (
                            user.get('role').map((role, i) => (
                              <div
                                style={{ width: '100px' }}
                                key={i}
                                onMouseEnter={this.onMouseOver.bind(this, user.get('id'))}
                                onMouseLeave={this.onMouseOut.bind(this)}
                                ref={this.scrollRef}
                              >
                                <div className="RP-User-Table-Role" key={i}>
                                  {role.get('role_name')}
                                </div>
                                {tooltip === user.get('id') && (
                                  <div
                                    className="RP-User-Table-Tips"
                                    style={sty}
                                    onMouseEnter={this.onMouseOverTips.bind(this)}
                                  >
                                    <b className="RP-User-Table-Tips-Title">
                                      {user.get('first_name')}&nbsp;{user.get('last_name')}
                                    </b>
                                    {role.get('group').size > 0 &&
                                      role.get('group').map((group, x) => (
                                        <div className="RP-User-Table-Tips-Group" key={x}>
                                          <div className="RP-User-Table-Tips-GroupTitle">{group.get('group')}</div>
                                          {group.get('permissions').size &&
                                            group.get('permissions').map((permissions, y) => (
                                              <div className="RP-User-Table-Tips-GroupContent" key={y}>
                                                {permissions.get('permission_name')}
                                              </div>
                                            ))}
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div style={{ width: '100px' }}>
                              <div className="RP-User-Table-Role">&nbsp;</div>
                            </div>
                          )}
                          <div className="RP-User-Table-RoleType">
                            {(user.get('users_roles') && user.get('users_roles').get('role')) || ''}
                          </div>
                          <div className="RP-User-Table-Property">
                            {user.get('properties').size > 0 &&
                              user
                                .get('properties')
                                .get(0)
                                .get('name')}
                          </div>
                          <div className="RP-User-Table-Phone">{formatPhoneNumber(user.get('phone'))}</div>
                          <div
                            className="RP-User-Table-Edit"
                            onClick={userPermission.EditRolesPermissionUser ? this.onEditUser.bind(this, user) : null}
                            style={{
                              cursor: userPermission.EditRolesPermissionUser ? 'pointer' : 'default',
                            }}
                          >
                            {userPermission.EditRolesPermissionUser && 'Edit'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {type === 'edit' && (
          <div className="RP-User-Edit">
            <div className="RP-User-Left">
              <div className="RP-User-Search">
                <div className="RP-User-Search-Title">User Search</div>
                <InputScaffold className="RP-User-Search-InputScaffold">
                  <input
                    name="RolesAndPermissions"
                    className="RP-User-Search-MaskedInput RP-User-Search-Left"
                    value={search}
                    onChange={this.onUpdateSearch}
                    placeholder="Search Users…"
                    autoComplete="off"
                    onKeyPress={this.onKeyEnter}
                    maxLength="25"
                  />
                  <div className="RP-User-Search-icons" onClick={this.onSearchUser}>
                    <Search />
                  </div>
                </InputScaffold>
              </div>
              {searchType === 'search' && (
                <div className="RP-User-Search-Info"> User search results for “{search}” </div>
              )}
              <div className="RP-User-Table">
                <div className="RP-User-Table-Title">
                  <div className="RP-User-Title-Name">NAME</div>
                  <div className="RP-User-Title-Edit" />
                </div>
                {users.map((user, index) => {
                  return (
                    <div
                      className="RP-User-Table-List"
                      key={index}
                      style={{
                        background: user.get('id') === userId && '#1d90ff',
                        color: user.get('id') === userId && '#FFFFFF',
                      }}
                    >
                      <div className="RP-User-Table-Name">
                        <div
                          className="RP-User-Table-Name-Top"
                          style={{ color: user.get('id') === userId && '#FFFFFF', width: '450px' }}
                        >
                          {this.formatName(user.get('first_name'))}
                          &nbsp;
                          {this.formatName(user.get('last_name'), 20)}
                        </div>
                        <div
                          className="RP-User-Table-Name-Bottom"
                          style={{ color: user.get('id') === userId && '#FFFFFF' }}
                        >
                          {user.get('email')}
                        </div>
                      </div>
                      <div
                        className="RP-User-Table-Edit"
                        onClick={userPermission.EditRolesPermissionUser ? this.onEditUser.bind(this, user) : null}
                        style={{
                          color: user.get('id') === userId && '#FFFFFF',
                          opacity: user.get('id') === userId && 1,
                        }}
                      >
                        {userPermission.EditRolesPermissionUser && 'Edit'}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="RP-User-Right">
              <div className="RP-User-Edit-Title">Edit User</div>
              <div className="RP-User-Edit-Info">
                <UserForm
                  search={this.state.search}
                  propertyId={this.state.selecedtProperty}
                  role={this.state.selectedRole}
                  error={error}
                  isAdd="edit"
                  updateUserList={this.updateUserList}
                  roleOptions={roleOptions}
                  userInfo={userInfo}
                  userPermission={userPermission}
                  property={
                    userInfo.get('properties').size > 0
                      ? userInfo
                          .get('properties')
                          .get(0)
                          .get('name')
                      : ''
                  }
                  initialValues={{
                    id: userInfo.get('id'),
                    first_name: userInfo.get('first_name') || '',
                    last_name: userInfo.get('last_name') || '',
                    company_name: userInfo.get('company_name') || '',
                    role_id:
                      userInfo.get('role').size > 0
                        ? userInfo
                            .get('role')
                            .get(0)
                            .get('id')
                        : '',
                    // property:
                    //   userInfo.get('properties').size > 0
                    //     ? userInfo
                    //         .get('properties')
                    //         .get(0)
                    //         .get('name')
                    //     : '',
                    email: userInfo.get('email') || '',
                    phone: userInfo.get('phone') || '',
                    updated_by: user.get('id'),
                  }}
                  onSubmit={values => {
                    return this.props.editUsers(values).then(action => {
                      if (action.response.ok) {
                        this.onSearchUser();
                      } else {
                        this.setState({ error: action.json.message });
                      }
                    });
                  }}
                  removeUsers={removeUsers}
                />

                <div className="RP-User-Edit-Title">
                  {userInfo.get('first_name')} {userInfo.get('last_name')} Permissions
                </div>
                <div className="RP-User-Edit-Permissions">
                  <div className="RP-User-Edit-Permissions-Info">
                    {userInfo.get('role').size > 0
                      ? userInfo.get('role').map(role =>
                          role.get('group').map((group, x) => (
                            <div className="RP-User-Edit-Permissions-Group" key={x}>
                              <div className="RP-User-Edit-Permissions-GroupTitle">{group.get('group')}</div>
                              {group.get('permissions').size &&
                                group.get('permissions').map((permissions, y) => (
                                  <div className="permission-item" key={y}>
                                    {permissions.get('permission_name')}
                                  </div>
                                ))}
                            </div>
                          ))
                        )
                      : permissionList.map((group, x) => (
                          <div className="RP-User-Edit-Permissions-Group" key={x}>
                            <div className="RP-User-Edit-Permissions-GroupTitle">{group.get('group')}</div>
                            {group.get('permissions').size > 0 &&
                              group.get('permissions').map((permissions, y) => (
                                <div className="permission-item" key={y}>
                                  {permissions.get('permission_name')}
                                </div>
                              ))}
                          </div>
                        ))}
                    {userInfo.get('role').size > 0 &&
                      userInfo
                        .get('role')
                        .get(0)
                        .get('group').size %
                        3 ===
                        2 && <div className="RP-User-Edit-Permissions-Group" />}
                    {permissionList.size > 0 && permissionList.size % 3 === 2 && (
                      <div className="RP-User-Edit-Permissions-Group" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default User;
