import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icons/icon';
import AlertMessage from '../AlertMessage';

class ModalAlert extends Component {
  state = {
    message: null,
  };
  render() {
    const {
      title,
      body,
      error,
      link,
      linkTitle,
      confirmButtonTitle,
      cancelButtonTitle,
      handleConfirm,
      handleCancel,
      visible,
      destructive,
      inputForm,
      inputFormPlaceholder,
      icon,
    } = this.props;
    return visible ? (
      <div className="modal modal--visible">
        <div className="modal__children">
          <div className="modal__header">
            {destructive ? (
              <div className="modal__header--destructive">
                <Icon icon="Warning" />
                <h3>{title}</h3>
              </div>
            ) : (
              <h3>{title}</h3>
            )}
            <div className="modal__close" onClick={handleCancel}>
              <Icon icon="Clear" />
            </div>
          </div>
          <div className="modal__body">
            <div className="modal__body--text">{body}</div>
            {inputForm && (
              <textarea
                placeholder={inputFormPlaceholder}
                maxLength="255"
                rows={8}
                onChange={e => {
                  this.setState({ message: e.target.value });
                }}
              />
            )}
            {link && (
              <div className="modal__link">
                <a href={link}>
                  <span>{linkTitle}</span>
                  <span>
                    <Icon icon="ArrowRightNarrow" />
                  </span>
                </a>
              </div>
            )}
            {error && <AlertMessage msg={error} type="error" />}
          </div>
          <div className="modal__button--container">
            {confirmButtonTitle && !destructive && (
              <button className="button" type={'button'} onClick={() => handleConfirm(this.state.message)}>
                {icon}
                {confirmButtonTitle}
              </button>
            )}
            {confirmButtonTitle && destructive && (
              <button
                className="button button--destructive"
                type="button"
                onClick={() => handleConfirm(this.state.message)}
              >
                {confirmButtonTitle}
              </button>
            )}
            {cancelButtonTitle && (
              <button className="button button--secondary" type="button" onClick={handleCancel}>
                {cancelButtonTitle}
              </button>
            )}
          </div>
        </div>
        <button type="button" className="modal__overlay" onClick={handleCancel} />
      </div>
    ) : null;
  }
}

ModalAlert.propTypes = {
  title: PropTypes.string,
  body: PropTypes.node,
  error: PropTypes.string,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  confirmButtonTitle: PropTypes.string,
  cancelButtonTitle: PropTypes.string,
  inputFormPlaceholder: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
  visible: PropTypes.bool,
  icon: PropTypes.node,
};

export default ModalAlert;
