import React from 'react';
import { CopyLinkBtn } from '../../../../../../../../../../common/forms/CopyLinkBtn';
import config from '../../../../../../../../../../common/config';
import { useSelector } from 'react-redux';
import { selectTourToken } from '../../../../../../../../redux/selectors';

export const SGFormTourRegistration = () => {
  const token = useSelector(selectTourToken);

  return (
    <div className="sg-message-container m-t30">
      <CopyLinkBtn title="FILLME-TOURS-DOMAIN" link={`${config.tourFormUrl}/self-guided-tours/${token}`} />
    </div>
  );
};
