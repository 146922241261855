import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import VendorsList from './views/VendorsList';
import AddVendor from './views/AddVendor';
import EditVendor from './views/EditVendor';
import NotFound from '../../../../common/NotFound';

class Vendors extends Component {
  render() {
    const { match, property } = this.props;
    if (!property.get('enabled_events') || !property.get('admin_enabled_community')) {
      return <NotFound />;
    }

    return (
      <div>
        <Switch>
          <Route exact path={`${match.path}/`} component={VendorsList} />
          <Route exact path={`${match.path}/add`} component={AddVendor} />
          <Route exact path={`${match.path}/:vendorId/edit`} component={EditVendor} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

Vendors.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Vendors;
