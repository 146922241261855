export default {
  is12HoursTime: false,
  timeFormat: 'HH:mm',
  dateFormat: 'DD.MM.YYYY',
  dateFormatV2: 'dd.MM.yyyy',
  dateWithFullTimeFormat: 'DD.MM.YYYY, HH:mm:ss',
  closed: 'Закрыто',
  minutes: 'минут',
  hour: 'час',
  hours: 'часов',
  and: 'и',
};
