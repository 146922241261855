import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import moment from 'moment';
import client from '../../../../../../../../../common/client';
import Loading from '../../../../../../../../../common/Loading/SpinnerLoading';
import { Link } from 'react-router-dom';

function PanelSyncOptions({ panelId, setModalVisible }) {
  const [isStartingSync, setIsStartingSync] = useState(false);
  const { isLoading, error, data } = useQuery(
    ['panelSyncStatus', panelId],
    () => client.get(`dmp-panels/${panelId}/sync-status`).then(res => res.json()),
    {
      refetchInterval: 5000,
      retry: false,
    }
  );

  function PanelSyncOptionsWrapper({ children }) {
    return (
      <div className="panel__section">
        <h3 className="h3">Sync Panel</h3>
        <p>{children}</p>
        <button
          className="button"
          disabled={isStartingSync}
          onClick={async () => {
            try {
              setIsStartingSync(true);
              await client.post(`dmp-panels/${panelId}/syncs`);
            } catch (e) {
              console.error(e);
            } finally {
              setIsStartingSync(false);
            }
          }}
        >
          Sync Panel
        </button>
        {data?.status !== 'SUCCESS' && (
          <Link style={{ margin: '20px' }} onClick={() => setModalVisible()}>
            View Failures
          </Link>
        )}
      </div>
    );
  }

  if (isLoading) {
    return (
      <PanelSyncOptionsWrapper>
        <Loading />
      </PanelSyncOptionsWrapper>
    );
  } else if (error) {
    return <PanelSyncOptionsWrapper>Error fetching sync status</PanelSyncOptionsWrapper>;
  } else if (data.statusCode === 404) {
    return (
      <PanelSyncOptionsWrapper>
        Last status: <strong className="strong">Never synced!</strong>
      </PanelSyncOptionsWrapper>
    );
  }

  if (data.status === 'FAIL') {
    return (
      <PanelSyncOptionsWrapper>
        Last status: <strong className="strong">{data.status}</strong>
        <span title={data.at}>({moment(data.at).calendar()})</span>
        Last successful sync: <span title={data.lastSuccessSync}> ({moment(data.lastSuccessSync).calendar()}) </span>
      </PanelSyncOptionsWrapper>
    );
  }
  if (data.status === 'SUCCEEDED_WITH_FAIURES') {
    return (
      <PanelSyncOptionsWrapper>
        Last status: <strong className="strong">SUCCEEDED WITH FAILURES</strong>
        <span title={data.at}>({moment(data.at).calendar()})</span>
      </PanelSyncOptionsWrapper>
    );
  }
  return (
    <PanelSyncOptionsWrapper>
      Last status: <strong className="strong">{data.status}</strong>
      <span title={data.at}>({moment(data.at).calendar()})</span>
    </PanelSyncOptionsWrapper>
  );
}

PanelSyncOptions.propTypes = {
  panelId: PropTypes.string.isRequired,
  setModalVisible: PropTypes.func,
};

export default PanelSyncOptions;
