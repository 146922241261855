import React from 'react';

function ArrowShortRight() {
  /* eslint-disable max-len */
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 -6.99382e-07L16 8L8 16L6.575 14.6L12.175 9L6.11959e-07 9L7.86805e-07 7L12.175 7L6.575 1.4L8 -6.99382e-07Z" />
    </svg>
  );
  /* eslint-enable */
}

export default ArrowShortRight;
