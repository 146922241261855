import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getProfiles, getPropertyAreas, getPropertySchedules } from '../../../../../../redux/actions';
import { getModelUnits, getStaffMembers, getTour } from '../../../../../../redux/actions/sgToursActions';

export const useFetchTour = () => {
  const { propertyId } = useParams();
  const [isLoad, setIsLoad] = useState(false);
  const [isTourExists, setIstourExists] = useState(false);
  const dispatch = useDispatch();

  const loadData = async () => {
    dispatch(getModelUnits(propertyId));
    dispatch(getStaffMembers(propertyId));
    dispatch(getProfiles(propertyId));
    dispatch(getPropertyAreas(propertyId));
    dispatch(getPropertySchedules(propertyId));
    const result = await dispatch(getTour(propertyId));

    if (result.response.ok) {
      setIstourExists(true);
    }

    setIsLoad(true);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId]);

  return {
    isLoad,
    isTourExists,
  };
};
