import { useRef } from 'react';

export const useGuest = () => {
  const guest = useRef({ id: '', first_name: '', last_name: '' });

  return {
    handleChangeGuest: currentGuest => (guest.current = currentGuest),
    guest: guest.current,
  };
};
