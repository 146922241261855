import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Yup from 'yup';
import MaskedInput from 'react-input-mask';

import { getPhoneMask, trimAndSetNullFalsyValues } from '../../common/utils/helpers';
import PageHeader from '../../common/PageHeader/index';
import SubHeader from '../../common/SubHeader';
import InputScaffold from '../../common/forms/InputScaffold';
import Delete from '../../views/RolesAndPermissions/common/Delete/index';
import Select from 'react-select';
import AlertMessage from '../../common/AlertMessage';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .max(64, 'First Name must be 64 characters or less')
    .required('Please enter a name'),
  last_name: Yup.string()
    .trim()
    .max(64, 'Last Name must be 64 characters or less')
    .required('Please enter a name'),
  email: Yup.string()
    .trim()
    .email('Must be a valid email address')
    .max(255)
    .required('Please enter an email address'),
  phone: Yup.string().matches(/^[1]\d{10}|[0,2-9]\d{9}$/, 'Must be a valid phone number'),
});

class AdminForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    adminId: PropTypes.string,
    removeAdmin: PropTypes.func,
  };

  static defaultProps = {
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company_name: '',
    },
  };

  state = {
    confirmDelete: false,
  };

  onSubmit = (values, { setSubmitting }) => {
    const { adminId, user, onSubmit } = this.props;
    if (values.role) {
      if (!adminId) {
        values.created_by = user.get('first_name') + ' ' + user.get('last_name');
      } else {
        values.updated_by = user.get('first_name') + ' ' + user.get('last_name');
      }
    }

    if (!values.role_id) {
      delete values.role_id;
    }

    onSubmit(trimAndSetNullFalsyValues(values));
    setSubmitting(false);
  };

  navigateToList = () => this.props.history.push('/admin-management');

  removeAdmin = () => {
    this.props.removeAdmin();
    this.navigateToList();
  };

  render() {
    const { initialValues, adminId, removeAdmin, error, roleOptions, userPermission } = this.props;
    const { confirmDelete } = this.state;

    return (
      <div className="admin-property-manager">
        <PageHeader title={`${adminId ? 'Edit' : 'Add'} Admin User`} white backLink={adminId ? '../' : './'} />
        <Formik
          enbaleReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            touched,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className="container property-manager-form admin-property-manager-form">
              {error && <AlertMessage msg={error} type="error" />}
              <div>
                <div className="paper radius-top-left radius-top-right AdminForm">
                  <SubHeader title={`${adminId ? 'Edit' : 'Add'} Admin User`} />
                  <div className="input-scaffold__wrapper-row" style={{ maxWidth: '900px' }}>
                    <InputScaffold label="first name" required validation={touched.first_name && errors.first_name}>
                      <input
                        type="text"
                        name="first_name"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.first_name}
                      />
                    </InputScaffold>
                    <InputScaffold
                      label="last name"
                      required
                      validation={touched.last_name && errors.last_name}
                      className="manager-form-ml-15"
                    >
                      <input
                        type="text"
                        name="last_name"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.last_name}
                      />
                    </InputScaffold>
                    <InputScaffold
                      label="ROLE"
                      required
                      validation={touched.role_id && errors.role_id}
                      className="manager-form-ml-15"
                    >
                      <Select
                        options={roleOptions}
                        placeholder={roleOptions.length ? 'Select a role' : 'No role available'}
                        onChange={value =>
                          userPermission.editAdminUserRoleOrPermissions && setFieldValue('role_id', value)
                        }
                        value={values.role_id}
                        simpleValue={true}
                        disabled={!userPermission.editAdminUserRoleOrPermissions}
                      />
                    </InputScaffold>
                  </div>
                  <InputScaffold label="email address" required validation={touched.email && errors.email}>
                    <input
                      type="text"
                      name="email"
                      maxLength="255"
                      onChange={handleChange}
                      value={values.email}
                      disabled={adminId}
                    />
                  </InputScaffold>
                  <div className="input-scaffold__wrapper-row">
                    <InputScaffold label="phone number" validation={touched.phone && errors.phone}>
                      <MaskedInput
                        mask={getPhoneMask(values.phone)}
                        name="phone"
                        onChange={event => {
                          setFieldValue('phone', event.target.value.replace(/[^0-9]+/g, ''));
                        }}
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                    </InputScaffold>
                    <InputScaffold label="company_name" validation={touched.company_name && errors.company_name}>
                      <input
                        type="text"
                        name="company_name"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.company_name}
                      />
                    </InputScaffold>
                  </div>
                </div>
              </div>

              <div className="paper__footer radius-bottom-left radius-bottom-right">
                {confirmDelete && (
                  <div className="AdminUser_Delete">
                    <Delete
                      message="Are you sure you want to <b>delete this admin</b>?"
                      deleteFunc={this.removeAdmin}
                      onCancel={() => this.setState({ confirmDelete: false })}
                    />
                  </div>
                )}
                <div style={{ marginLeft: 0 }}>
                  <button
                    type="submit"
                    disabled={confirmDelete || isSubmitting || Object.keys(errors).length}
                    className="button"
                  >
                    {adminId ? 'Save' : 'Create'} Admin
                  </button>
                  {userPermission.deleteAdminUser && adminId && removeAdmin && (
                    <button
                      type="button"
                      className="button button--destructive admin-button--destructive"
                      onClick={() => this.setState({ confirmDelete: true })}
                      disabled={confirmDelete}
                    >
                      Remove Admin
                    </button>
                  )}
                  <button
                    type="button"
                    className="button button--secondary admin-button--cancel"
                    onClick={this.navigateToList}
                    disabled={confirmDelete}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withRouter(AdminForm);
