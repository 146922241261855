import React from 'react';

import PlaceholderRequestDetailsButton from '../../common/PlaceholderRequestDetailsButton';

import Intercom from '../../common/images/color/intercom.png';
import MobileAccess from '../../common/images/color/mobile_access.png';
import Safety from '../../common/images/color/safety.png';
import VisitorsBW from '../../common/images/bw/visitors.png';
import GuestManagementBW from '../../common/images/bw/guest_management.png';
import IntegratedHomeBW from '../../common/images/bw/integrated_home.png';
import MobileCredentialsBW from '../../common/images/bw/mobile_credentials.png';

export default function AccessControlPlaceholder(props) {
  const learnMoreButton = <PlaceholderRequestDetailsButton feature="Smart Access" />;
  return (
    <div className="smart-placeholder__page smart-home">
      <h1>Smart Access</h1>
      <p className="smart-placeholder--main-p">
        Did you know that SmartExperience also offers smart access features for your residents, including video intercom
        and visitor/guest management?
      </p>
      <div className="main-points__wrapper">
        <div className="main-points">
          <div className="point--image__container">
            <img src={Intercom} alt="Intercom" />
          </div>
          <h4>Modern convenience</h4>
          <p className="points-description">
            Bring your property into the future with a powerful video intercom and access control solution.
          </p>
        </div>
        <div className="main-points">
          <div className="point--image__container">
            <img src={MobileAccess} alt="Mobile Access" />
          </div>
          <h4>Mobile access</h4>
          <p className="points-description">
            Gives residents the ability to manage visitors and answer gate calls right from their resident mobile app.
          </p>
        </div>
        <div className="main-points">
          <div className="point--image__container">
            <img src={Safety} alt="Safety" />
          </div>
          <h4>Safety and security</h4>
          <p className="points-description">
            Discourage unwanted guests through integrated access control, video intercom, and visitor management.
          </p>
        </div>
      </div>
      <p className="smart-placeholder--main-p second">
        Secure your property while still helping residents and their visitors feel welcome with a modern smart access
        platform.
      </p>
      {learnMoreButton}
      <h2>SmartExperience's Smart Access features include:</h2>
      <div className="secondary-points__wrapper">
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={VisitorsBW} alt="Visitors" />
          </div>
          <div>
            <h4>Visitor Management</h4>
            <p className="points-description">
              Increase safety by giving your residents a powerful set of tools for managing visitors requesting access
              to the property.
            </p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={GuestManagementBW} alt="Guest Management" />
          </div>
          <div>
            <h4>Guest Management</h4>
            <p className="points-description">
              Allow residents to securely manage both property and unit access for guests—and to immediately remove it
              when no longer needed.
            </p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={IntegratedHomeBW} alt="Integrated Home" />
          </div>
          <div>
            <h4>Integrated Home Access</h4>
            <p className="points-description">
              Implement a single, integrated solution that controls access from the property gate through to the
              resident’s front door.
            </p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={MobileCredentialsBW} alt="Mobile Credentials" />
          </div>
          <div>
            <h4>Mobile Credentials</h4>
            <p className="points-description">
              Bring your property into the future by allowing residents to use their mobile phone as a credential at any
              access point.
            </p>
          </div>
        </div>
      </div>
      {learnMoreButton}
    </div>
  );
}
