import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../../../../common/Loading';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import DetailBit from '../../../../../../common/DetailBit';
import moment from 'moment';
import SubHeader from '../../../../../../common/SubHeader';

class VacantUnits extends Component {
  state = {
    userPermission: {},
  };
  componentDidMount() {
    const { userPermissionList } = this.props;
    const userPermission = {
      editVacantUnitAutomationRules: userPermissionList.includes('EDIT_VACANT_UNIT_AUTOMATION_RULES'),
    };
    this.setState({ userPermission: userPermission });
    this.props.getVacantUnitAutomation();
  }

  getEditPath() {
    return `${this.props.match.url}/edit`;
  }

  formatTimeForDisplay(timeValue) {
    return (timeValue && moment(timeValue, 'HH:mm').format('h:mm a')) || 'Not Scheduled';
  }

  renderAutomationInfo() {
    const values = this.props.vacantUnitAutomation.toJS();
    return (
      <div className="paper radius-top-left radius-top-right">
        <div className="paper__section details__section">
          <h4 className="h4">Device Schedules</h4>
          <DetailBit label="Lights Out Time" value={this.formatTimeForDisplay(values.lights_out)} />
          <DetailBit label="Lock Doors Time" value={this.formatTimeForDisplay(values.lock_doors)} />
          <DetailBit label="Set Thermostat Time" value={this.formatTimeForDisplay(values.set_thermostat)} />
          {values.set_thermostat
            ? [
                <DetailBit key="temp" label="Temperature" value={<span>{values.thermostat_temp}&ordm;</span>} />,
                <DetailBit key="thermostat-mode" label="Mode" value={values.thermostat_mode} />,
                <DetailBit key="fan-mode" label="Fan Mode" value={values.fan_mode} />,
              ]
            : null}
          <DetailBit label="Door Code" value={values.door_code || 'Not Set'} />
        </div>
      </div>
    );
  }

  renderNoAutomationMessage() {
    return <EmptyPlaceholder buttonText="Configure Automation Settings" linkTo={this.getEditPath()} />;
  }

  render() {
    const { vacantUnitAutomationLoaded, vacantUnitAutomationLoading, vacantUnitAutomation } = this.props;
    const { userPermission } = this.state;
    if (!vacantUnitAutomationLoaded || vacantUnitAutomationLoading) {
      return <Loading />;
    }
    let vacantUnitAutomation_t = vacantUnitAutomation === false ? false : true;
    return (
      <div className="vacant-units--page container">
        <SubHeader
          title="Vacant Units"
          actions={[
            vacantUnitAutomation_t && userPermission.editVacantUnitAutomationRules && (
              <Link to={this.getEditPath()} className="button">
                Edit Automation Settings
              </Link>
            ),
          ]}
        />
        {vacantUnitAutomation ? this.renderAutomationInfo() : this.renderNoAutomationMessage()}
      </div>
    );
  }
}

export default VacantUnits;
