import React from 'react';

function LogoNestOne() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="37" viewBox="0 0 144.81 73.68">
      <g>
        <path
          fill="#fff"
          d="M34.77,65.41H29.52c-.57,0-1.16,0-1.38.64a1.47,1.47,0,0,0,.5,1.57c1.63,1.63,3.66,1.81,5.78,1.62a15.26,15.26,0,0,0,3.37-.84,2,2,0,0,1,2.75,1.23c.48,1.1.21,2-1.14,2.51-4.58,1.87-9.18,2.45-13.49-.63-3.07-2.2-4.22-7-2.91-11a9.33,9.33,0,0,1,8.61-6.72c5.58-.2,9.07,2.28,10.37,7.34.85,3.37.15,4.29-3.27,4.28Z"
        />
        <path
          fill="#fff"
          d="M18.68,66.33c0,1.53,0,3.06,0,4.59s-.74,2.34-2.11,2.34A2.1,2.1,0,0,1,14.12,71c-.07-2.58,0-5.17,0-7.76,0-4-3.42-6.17-7.09-4.49-1.93.88-2.24,2.66-2.29,4.48-.07,2.55,0,5.1,0,7.65,0,1.76-.81,2.5-2.59,2.44C1,73.24.13,72.72.14,71.64c0-4-.55-8.1.47-12.07.92-3.6,3.64-5.22,7.09-5.69a10.35,10.35,0,0,1,7.89,1.74,7.75,7.75,0,0,1,3.06,6.23c.07,1.49,0,3,0,4.48Z"
        />
        <path
          fill="#fff"
          d="M53.92,73.52c-2.5-.16-5-.34-7.29-1.56-1.24-.67-1.58-1.46-1.1-2.57a1.86,1.86,0,0,1,2.72-1.15A23.58,23.58,0,0,0,53,69.46a5.78,5.78,0,0,0,3-.3c.65-.26,1.22-.6,1.2-1.38s-.63-1.12-1.3-1.27c-1.66-.4-3.33-.73-5-1.12a6.21,6.21,0,0,1-4.76-3.71,5.45,5.45,0,0,1,3.06-7c3.81-1.62,7.56-1.07,11.18.68a1.6,1.6,0,0,1,.84,2.28A1.93,1.93,0,0,1,58.71,59a16,16,0,0,0-4.59-.87,5.43,5.43,0,0,0-2.36.34,1.34,1.34,0,0,0-.87,1.16,1.27,1.27,0,0,0,.76,1.12,5.37,5.37,0,0,0,2.16.69,26.75,26.75,0,0,1,4.26.94,5.44,5.44,0,0,1,4.19,5.06A5.78,5.78,0,0,1,58,72.9,11.85,11.85,0,0,1,53.92,73.52Z"
        />
        <path
          fill="#fff"
          d="M66,20.78V2.31C66,.49,66.61-.11,68.42,0a1.68,1.68,0,0,1,1.81,1.64c.06.8.07,1.6.07,2.4q0,17.15,0,34.3a11.15,11.15,0,0,1-.1,1.63A2,2,0,0,1,68,42a2,2,0,0,1-2-2.21c0-3.49,0-7,0-10.48Z"
        />
        <path
          fill="#1e90ff"
          d="M136.59,65c-2,0-3.94,0-5.91,0s-2.46,1-1.54,2.72a7.81,7.81,0,0,0,7.5,3.51,12.32,12.32,0,0,0,4.6-1.29,4,4,0,0,1,.6-.25c.58-.2,1.17-.29,1.54.35s0,1.06-.4,1.45a4,4,0,0,1-1.07.75,13.31,13.31,0,0,1-12,0c-3.76-1.87-5.41-6.86-4-11.4,1.58-5.23,6.66-8,12.28-6.7,4.6,1,6.7,4.78,6.66,9,0,1.21-.73,1.83-2.09,1.85-2,0-4.09,0-6.13,0Z"
        />
        <path
          fill="#1e90ff"
          d="M88.73,53.77c5,0,8.83,2.74,10,7.18s-.48,9-4.07,11.1c-2.45,1.44-5.21,1.48-8,1.32-3.74-.23-7.08-3.55-7.93-6.93C77.08,60.14,81,53.58,88.73,53.77Z"
        />
        <path
          fill="#fff"
          d="M72.94,73.26c-2.57,0-4.84-.69-6.23-3.08a7.68,7.68,0,0,1-.92-3.89q-.06-8.21,0-16.4c0-1.5.75-2.17,2.25-2.17s2.33.67,2.36,2.19c0,.77,0,1.54,0,2.3.11,1.33.6,1.77,1.94,1.86.5,0,1,0,1.52.12a1.75,1.75,0,0,1,1.74,1.86A1.92,1.92,0,0,1,74,58.3a8.81,8.81,0,0,1-1.85.11c-1.17.06-1.79.59-1.81,1.74-.05,2.08-.06,4.15,0,6.23a2.68,2.68,0,0,0,2.37,2.81l1,.19c1.19.26,1.73.86,1.71,1.91a1.9,1.9,0,0,1-1.84,1.93C73.38,73.25,73.16,73.25,72.94,73.26Z"
        />
        <path
          fill="#1e90ff"
          d="M103.2,65.66a47.74,47.74,0,0,1,.14-5.34,6.94,6.94,0,0,1,4.17-5.49,10.8,10.8,0,0,1,9.88.15,7.2,7.2,0,0,1,4,6.09c.31,3.2.11,6.41.15,9.61a5,5,0,0,1-.06.54c-.13.9-.41,1.71-1.51,1.63s-1.22-.92-1.23-1.76c0-2.84,0-5.68,0-8.52-.06-2.36-.73-4.46-3.08-5.6-2.88-1.4-6.88-.64-8.56,1.64a5,5,0,0,0-1,3c0,2.88,0,5.76,0,8.63a7.26,7.26,0,0,1-.2,1.62,1.13,1.13,0,0,1-1.07.94,1.26,1.26,0,0,1-1.31-.78,2.66,2.66,0,0,1-.26-1.27C103.2,69.23,103.2,67.67,103.2,65.66Z"
        />
        <path
          fill="#1c1d21"
          d="M32.39,61.44a20.56,20.56,0,0,1-3-.12c-1.08-.24-1.33-.93-.66-1.82,1.51-2,5.9-2.07,7.43-.13.76,1,.5,1.7-.69,2A20.61,20.61,0,0,1,32.39,61.44Z"
        />
        <path
          fill="#1c1d21"
          d="M135.25,62.15c-1.6,0-3.2,0-4.8,0s-1.95-.78-1.35-2.07A6.48,6.48,0,0,1,135,56.25a6.12,6.12,0,0,1,6.32,3.35c.86,1.64.35,2.51-1.47,2.56-1.53,0-3.06,0-4.59,0Z"
        />
        <path
          fill="#1c1d21"
          d="M81.31,63.57a6.9,6.9,0,0,1,7.36-7.28c4.55,0,7.41,2.91,7.44,7.47,0,4.36-2.94,7.31-7.34,7.32C84.16,71.08,81.31,68.21,81.31,63.57Z"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default LogoNestOne;
