import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addAdminUser } from '../../../../redux/actions';
import AddAdminUser from './AddAdminUser';
import { getRolesByRoleLevel } from '../../../RolesAndPermissions/redux/actions';
import { selectRoleOptions2 } from '../../../RolesAndPermissions/redux/selectors';
import { getUserPermission } from '../../../../redux/selectors';

function mapStateToProps(state, props) {
  return {
    roleOptions: selectRoleOptions2(state),
    user: state.user,
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addAdminUser: payload => dispatch(addAdminUser(payload)),
    getRolesByRoleLevel: name => dispatch(getRolesByRoleLevel(name)),
  };
}

const AddAdminUserContainer = connect(mapStateToProps, mapDispatchToProps)(AddAdminUser);

export default withRouter(AddAdminUserContainer);
