import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

export const SGAccessLog = ({ accessLog }) => (
  <>
    <div className="scheduledDetail_access_log">
      <span className="scheduledDetail_access_log_label">Access log</span>
      <span className="scheduledDetail_access_log_sublabel">{accessLog.length} instances</span>
    </div>
    <div className="scheduledDetail_value">
      <table className="table-units-list detail-accesslog-table">
        <thead>
          <tr>
            <th>LOCATION</th>
            <th>DATE</th>
            <th>TIME</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {accessLog.map(item => {
            return (
              <tr key={item.id}>
                <td>{item?.door?.name || item?.telephone_entry_device?.name || 'Unknown'}</td>
                <td>{moment(item.created_at).format('MM/DD/YY')}</td>
                <td>{moment(item.created_at).format('h:mm A')}</td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </>
);

SGAccessLog.propTypes = {
  accessLog: PropTypes.array,
};
