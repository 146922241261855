import { connect } from 'react-redux';
import {
  createPanelDevice,
  cancelPanelJobs,
  requestZwaveDevice,
  getJobStatus,
  getPanelDevices,
} from '../../../../redux/actions';
import AddDevice from './AddDevice';
import { getProperties } from '../../../../../../../../../../redux/actions';
import { selectActiveProperty } from '../../../../../../../../../../redux/selectors';
function mapStateToProps(state, props) {
  return {
    panel: state.devices.panelDevices,
    property: selectActiveProperty(state, props),
    propertiesLoaded: state.propertiesLoaded,
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    getProperties: () => dispatch(getProperties()),
    getPanelDevices: () => dispatch(getPanelDevices(ownProps.match.params.propertyId, ownProps.match.params.panelId)),
    createPanelDevice: payload =>
      dispatch(createPanelDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    cancelPanelJobs: payload =>
      dispatch(cancelPanelJobs(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    requestZwaveDevice: payload =>
      dispatch(requestZwaveDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    getJobStatus: payload => {
      return dispatch(getJobStatus(ownProps.match.params.propertyId, payload));
    },
  };
}

const AddDeviceContainer = connect(mapStateToProps, mapDispatchToProps)(AddDevice);
export default AddDeviceContainer;
