import React from 'react';

function SGSwitchOn() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="132" height="44" viewBox="0 0 126 44">
      <g fill="none" fillRule="evenodd">
        <rect width="131" height="43" x=".5" y=".5" fill="#CBF0C8" stroke="#60B05C" rx="21" />
        <circle cx="110" cy="22" r="18" fill="#FFF" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default SGSwitchOn;
