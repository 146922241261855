import ImportUnits from './ImportUnits';
import { connect } from 'react-redux';
import { uploadUnitCsv } from '../../../../redux/actions';
import compose from 'lodash/flowRight';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadUnitCsv: file => uploadUnitCsv(ownProps.match.params.propertyId, file),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ImportUnits);
