import { connect } from 'react-redux';

import EditDoor from './EditDoor';
import { editDoor, getPropertyAreas, getDoors } from '../../../../redux/actions';

function mapStateToProps(state, ownProps) {
  return {
    property: state.property.activeProperty,
    accessControlAreas: state.property.accessControlAreas,
    accessControlAreasLoaded: state.property.accessControlAreasLoaded,
    matchingDoor: state.property.accessControlDoors.find(door => door.get('id') === ownProps.match.params.doorId),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    editDoor: payload => dispatch(editDoor(ownProps.match.params.propertyId, ownProps.match.params.doorId, payload)),
    getPropertyAreas: () => dispatch(getPropertyAreas(ownProps.match.params.propertyId)),
    getDoors: () => dispatch(getDoors(ownProps.match.params.propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDoor);
