import { connect } from 'react-redux';
import Discussion from './Discussion';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
  };
}

const DiscussionContainer = connect(mapStateToProps)(Discussion);

export default DiscussionContainer;
