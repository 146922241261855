import React from 'react';

function ExternalLink() {
  /* eslint-disable max-len */
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
      <path d="M15 3L21 3 21 9" />
      <path d="M10 14L21 3" />
    </svg>
  );
  /* eslint-enable */
}

export default ExternalLink;
