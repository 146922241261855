import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import moment from 'moment';
import querystring from 'query-string';
import localstorage from 'store2';
import classNames from 'classnames';

import config from '../../../../../../common/config';
import SubHeader from '../../../../../../common/SubHeader';
import Loading from '../../../../../../common/Loading';
import Pager from '../../../../../../common/Pager';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import AccessRequestFilterForm from '../../components/AccessRequestFilterForm';
import Icon from '../../../../../../common/icons/icon';

const PAGE_SIZE = 30;
const USER_TYPE_STRINGS = {
  RESIDENT: 'Resident',
  RESIDENT_GUEST: 'Guest',
  PROPERTY_STAFF: 'Staff',
  PROPERTY_GUEST: 'Property Guest',
};

class AccessRequests extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    doors: PropTypes.instanceOf(List),
    doorsLoaded: PropTypes.bool.isRequired,
    getDoors: PropTypes.func.isRequired,
    accessRequests: PropTypes.instanceOf(List),
    accessRequestsLoaded: PropTypes.bool.isRequired,
    getAccessRequests: PropTypes.func.isRequired,
  };

  state = {
    isShowingFilters: false,
    name: '',
    userCode: '',
    accessPointId: '',
    startDate: '',
    endDate: '',
  };

  componentDidMount() {
    const { getDoors, getTelephoneEntryDevices } = this.props;

    this.updateSearch();
    getTelephoneEntryDevices();
    getDoors();
  }

  componentDidUpdate(prevProps) {
    const { getAccessRequests, location } = this.props;
    if (prevProps.location.search !== location.search) {
      this.setState({
        isShowingFilters: false,
      });
      getAccessRequests(querystring.parse(location.search));
    }
  }

  updateSearch = () => {
    const { getAccessRequests, location } = this.props;

    const locationObj = querystring.parse(location.search);

    getAccessRequests({
      ...locationObj,
    });
  };

  render() {
    const {
      accessRequests,
      doors,
      doorsLoaded,
      accessRequestsLoaded,
      accessRequestsTotal,
      history,
      location,
      match,
      telephoneEntryDevices,
      telephoneEntryDevicesLoaded,
      property,
    } = this.props;
    const { isShowingFilters } = this.state;

    const locationObj = querystring.parse(location.search);

    if (!doorsLoaded || !accessRequestsLoaded || !telephoneEntryDevicesLoaded) {
      return <Loading />;
    }

    return (
      <div className="page--access-requests">
        <div className="container">
          <SubHeader
            title="Access Log"
            size={24}
            actions={[
              <button
                type="button"
                className="button button--outline csv-button"
                onClick={() => {
                  const qs = {
                    token: localstorage.get('idToken'),
                    ...locationObj,
                  };

                  delete qs.page;
                  delete qs.page_size;

                  window.location = `${config.apiUrl}properties/${
                    match.params.propertyId
                  }/access-requests.csv?${querystring.stringify(qs)}`;
                }}
              >
                Download CSV
              </button>,
              <button
                type="button"
                className="button button--outline button--icon"
                onClick={() => {
                  this.setState(state => ({ isShowingFilters: !state.isShowingFilters }));
                }}
              >
                <div className="button--children">
                  <span>Filters</span>
                  <Icon icon="Filter" />
                </div>
              </button>,
            ]}
          />
        </div>

        {isShowingFilters && (
          <AccessRequestFilterForm
            doors={doors}
            telephoneEntryDevices={telephoneEntryDevices}
            communityHasDMPMasterPanel={!!property.get('clubhouse_panel_id')}
            initialValues={{
              name: locationObj.name || '',
              user_code: locationObj.user_code || '',
              start_date: locationObj.start_date ? moment(locationObj.start_date).toDate() : '',
              end_date: locationObj.end_date ? moment(locationObj.end_date).toDate() : '',
              door_id: locationObj.door_id || '',
              telephone_entry_device_id: locationObj.telephone_entry_device_id || '',
            }}
            onCancel={() => {
              this.setState({ isShowingFilters: false });
            }}
            onSubmit={values => {
              history.push({
                pathname: location.pathname,
                search: querystring.stringify({
                  ...values,
                  page: 0,
                  page_size: PAGE_SIZE,
                }),
              });
            }}
          />
        )}

        <div className="container recog--container">
          <div className="recog__recognized">
            {accessRequests.size > 0 ? (
              <div className="access-requests--table">
                <table className="table-units-list">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Location</th>
                      <th>Date</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessRequests.map(accessRequest => (
                      <tr key={accessRequest.get('id')}>
                        <td className="access-point__details-cell">
                          <div className="flex-top">
                            <div className="access-point__name">{accessRequest.get('name') || 'Unknown'}</div>
                            <div
                              className={classNames(
                                'access-point__status',
                                accessRequest.get('access_granted') ? 'granted' : 'denied'
                              )}
                            >
                              {accessRequest.get('access_granted') ? 'GRANTED' : 'DENIED'}
                            </div>
                          </div>
                          <div className="access-point__user-type">
                            {USER_TYPE_STRINGS[accessRequest.get('user_type')] || 'Unknown'}
                          </div>
                        </td>
                        <td>
                          {accessRequest.getIn(['door', 'name']) ||
                            accessRequest.getIn(['telephone_entry_device', 'name']) ||
                            'Unknown'}
                        </td>
                        <td>{moment(accessRequest.get('created_at')).format('MM/DD/YY')}</td>
                        <td>{moment(accessRequest.get('created_at')).format('h:mm A')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pager
                  history={history}
                  initialPage={parseInt(locationObj.page, 10) || 0}
                  forcePage={parseInt(locationObj.page, 10) || 0}
                  location={location}
                  pageSize={PAGE_SIZE}
                  totalItems={accessRequestsTotal}
                />
              </div>
            ) : (
              <EmptyPlaceholder>0 results found</EmptyPlaceholder>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AccessRequests;
