import React from 'react';
import classNames from 'classnames';
import {
  SGBtnBack,
  SGButton,
  useFetchGuest,
  ResidentAccessModal,
  useResidentAccessModal,
  useResidentAccessDetail,
  SGDetailHeader,
  SGDetailInfo,
  SGDetailImages,
  SGLinkToTour,
  useFetchTourGuests,
} from '../../../common';
import Icon from '../../../../../../../../../common/icons/icon';

import Loading from '../../../../../../../../../common/Loading';
import {
  ResidentGuestLimitModal,
  useResidentGuestLimitModal,
} from '../../../common/views/modals/ResidentGuestLimitModal';

export const SGRejectedDetails = () => {
  const { isLoad, guest } = useFetchGuest();
  const { isLoad: isScheduledLoad, dataLoaded: scheduledDataLoaded } = useFetchTourGuests({
    search: '',
    sort_by: 0,
    type: 'scheduled',
  });
  const { isOpen, handleOpen, handleCancel } = useResidentAccessModal();
  const { handleLimitModalClose, handleLimitModalOpen, isLimitOpen } = useResidentGuestLimitModal();

  const { handleEnableAccess } = useResidentAccessDetail({
    guestId: guest?.id,
    historyEnableAccessReplaceTo: `/automated/detail/scheduled/${guest?.id}`,
  });

  if (isLoad || isScheduledLoad) return <Loading />;

  const {
    first_name,
    last_name,
    imageUrl,
    tour: { status },
  } = guest;
  const handleApproveClick = scheduledDataLoaded?.length < 19 ? handleOpen : handleLimitModalOpen;

  return (
    <>
      <div className="m-t20 p-l20">
        <SGBtnBack btnTitle="Return to previous page" />
      </div>
      <div className="detail-page-content">
        <SGDetailHeader
          info={!status && <SGDetailInfo {...guest} tabName="rejected" />}
          status={status}
          title="Rejected Tour"
          name={`${first_name} ${last_name}`}
        >
          <SGButton
            className={classNames('detail-button-enable', { button: !status })}
            title="Enable Access"
            iconComponent={<Icon icon="EnableAccessWhite" />}
            handleClick={handleApproveClick}
            disabled={!status}
          />
          {!status && (
            <p className="sg-detail-header-right-info-text">
              To enable access, please re-enable Self-Guided Tours in Tour settings.
            </p>
          )}
        </SGDetailHeader>
        {status && <SGDetailInfo {...guest} tabName="rejected" />}
        <SGDetailImages imageUrl={imageUrl} />
        <SGLinkToTour />
      </div>
      <ResidentAccessModal
        visible={isOpen}
        name={` ${first_name} ${last_name}`}
        onCancel={handleCancel}
        onEnable={handleEnableAccess}
      />
      <ResidentGuestLimitModal width={940} close={handleLimitModalClose} visible={isLimitOpen} />
    </>
  );
};
