import React from 'react';

function GarageClosed() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M18 24V14H6v10H2V11.058h.113L12 0l9.887 11.058H22V24h-4zM8 18v-2h8v2H8zm0 4v-2h8v2H8z" />
    </svg>
  );
  /* eslint-enable */
}

export default GarageClosed;
