import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const SGPageHeader = ({ status, title, subTitle, note, topRow, rightColumn }) => (
  <div className={classnames('sg-page-header', { 'tour-disabled': status })}>
    <div className="content">
      {topRow}
      <div className="title">{title}</div>
      <div className="subtitle">{subTitle}</div>
      {note && <div className="note">{note}</div>}
    </div>
    {rightColumn}
  </div>
);

SGPageHeader.propTypes = {
  status: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  note: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  topRow: PropTypes.node,
  rightColumn: PropTypes.node,
};
