import { connect } from 'react-redux';
import { getPendingPosts, approvePost, rejectPost } from '../../../../redux/actions';
import PendingPostList from './PendingPostList';
import { getUserPermission } from '../../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    discussionList: state.property.discussionList,
    loading: state.property.postListLoading,
    property: state.property.activeProperty,
    pendingPostList: state.property.pendingPostList.get('results'),
    pendingPostTotal: state.property.pendingPostList.get('total'),
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPendingPosts: (propertyId, page, pageSize) => dispatch(getPendingPosts(propertyId, page, pageSize)),
    approvePost: (propertyId, postId) => dispatch(approvePost(propertyId, postId)),
    rejectPost: (propertyId, postId, payload) => dispatch(rejectPost(propertyId, postId, payload)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    approvePost: postId => dispatchProps.approvePost(stateProps.property.get('id'), postId),
    rejectPost: (postId, comment) =>
      dispatchProps.rejectPost(stateProps.property.get('id'), postId, { comment: comment }),
    getPendingPosts: (page, pageSize) => dispatchProps.getPendingPosts(stateProps.property.get('id'), page, pageSize),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PendingPostList);
