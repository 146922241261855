import React, { Component } from 'react';
import InputScaffold from '../../../../../common/forms/InputScaffold';
import MaskedInput from 'react-input-mask';

import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css';
import 'react-day-picker/lib/style.css';

import Close from '../../../../../common/icons/Close';
import Search from '../../../../../common/icons/Search';
import PropTypes from 'prop-types';
import RolesForm from '../../../common/RolesForm/RolesForm';
import Loading from '../../../../../common/Loading';

class Roles extends Component {
  static propTypes = {
    addRoles: PropTypes.func.isRequired,
    editRoles: PropTypes.func.isRequired,
    removeRole: PropTypes.func.isRequired,
    rolesListLoading: PropTypes.bool,
  };
  state = {
    error: null,
    id: '',
    role: '',
    search: '',
    searchType: '',
    isAddRole: 'list',
    permissionIdList: [],
    initialValues: {
      role_name: '',
      role_level: '',
      role_description: '',
      property_id: '',
      update_by: '',
    },
    property_select_show: false,
  };

  componentDidMount() {
    this.props.getRoles();
    this.props.getGroupPermission().then(() => {
      const { permissionList } = this.props;
      let IdList = [];
      for (let i = 0; i < permissionList.size; i++) {
        let permissions = permissionList.get(i).get('permissions');
        IdList = [...IdList, ...permissions];
      }
      this.setState({ permissionIdList: IdList });
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          update_by: this.props.user.get('id'),
        },
      });
    });
    this.props.getRolesLevel();
    this.props.getPropertys();
  }

  onUpdateSearch = event => {
    this.setState({ search: event.target.value, id: '', role: '', searchType: '' });
    if (event.target.value === '') {
      this.componentDidMount();
    }
  };
  onCloseSearch = event => {
    this.setState({ isAddRole: 'list', id: '', role: '', search: '', searchType: '' });
    this.componentDidMount();
  };
  onSearchRoles = event => {
    this.setState({ isAddRole: 'list' });
    this.setState({ searchType: 'in roles' });
    this.props.getRolesByName(this.state.search);
  };
  onSearchPermissions = event => {
    this.setState({ searchType: 'in permissions', role: 'ALL ROLES' });
    this.props.getRoleByPermission(this.state.search);
    if (this.state.search !== '') {
      this.props.getPermissionByConditions('ALL ROLES', this.state.search);
    }
  };

  onUpdateRolePermission = (id, role, e) => {
    this.setState({ isAddRole: 'list', id: id, role: role });
    if (id !== 'ALL ROLES') {
      this.props.getRoleById(id);
    }
    if (this.state.searchType === 'in permissions') {
      if (this.state.search !== '') {
        this.props.getPermissionByConditions(id, this.state.search);
      }
    } else {
      this.props.getRolePermission(id);
    }
  };
  onAddRoles = event => {
    this.setState({ isAddRole: 'add', error: null, id: '', role: '', property_select_show: false });
    this.props.getRoles();
  };
  onEditRoles = (id, role, e) => {
    this.setState({ isAddRole: 'edit', error: null, id: id, role: role });
    this.props.getRoleById(id).then(() => {
      this.props.getUserRolePermission(id);
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          role_name: this.props.rolesInfo.get('role_name'),
          role_level: this.props.rolesInfo.get('role_level'),
          role_description: this.props.rolesInfo.get('role_description'),
          property_id: this.props.rolesInfo.get('property_id') === null ? '' : this.props.rolesInfo.get('property_id'),
        },
      });
      if (this.props.rolesInfo.get('role_level') === 'PROPERTY_MANAGER') {
        this.update_property_select_show(true);
      } else {
        this.update_property_select_show(false);
      }
    });
    e.stopPropagation();
  };
  updateRoleList = () => {
    this.componentDidMount();
    if (this.state.searchType === 'in roles') {
      this.setState({ id: '', role: '' });
      this.props.getRoleById(null);
      this.props.getRolesByName(this.state.search);
    }
    if (this.state.searchType === 'in permissions') {
      this.props.getRoleByPermission(this.state.search);
      this.props.getPermissionByConditions('ALL ROLES', this.state.search);
    }
    if (this.state.id !== '' && this.state.id !== 'ALL ROLES') {
      this.props.getRoleById(this.state.id);
      this.props.getRolePermission(this.state.id);
    }

    this.setState({ isAddRole: 'list' });
  };
  updateRoleDel = () => {
    if (this.state.searchType === 'in roles') {
      this.props.getRolesByName(this.state.search);
    }
    if (this.state.searchType === 'in permissions') {
      this.props.getRoleByPermission(this.state.search);
      this.props.getPermissionByConditions('ALL ROLES', this.state.search);
    }
    this.setState({ error: null, id: '', role: '', isAddRole: 'list' });
    this.componentDidMount();
  };

  onRoleUpdatePermission = (permissionId, isChecked, id) => {
    const { rolesList } = this.props;
    if (id !== '' && rolesList.size > 0) {
      if (isChecked) {
        this.props.removeRolePermissionByRolePermissionId(id, permissionId).then(() => {
          if (this.state.search !== '' && this.state.searchType === 'in permissions') {
            this.props.getRoleByPermission(this.state.search);
            if (this.state.search !== '') {
              this.props.getPermissionByConditions(this.state.id || 'ALL ROLES', this.state.search);
            }
          } else {
            this.props.getRolePermission(id);
          }
        });
      } else {
        this.props.addRolesPermission({ role_id: id, permission_id: permissionId }).then(() => {
          this.props.getRolePermission(id);
        });
      }
    }
  };

  onCheckedSelectAll = isChecked => {
    const { rolePermissionList } = this.props;
    let pIDList = [];
    let id = this.state.id;
    if (id !== '') {
      this.state.permissionIdList.forEach(function(permission) {
        pIDList.push(permission.get('id'));
      });
      if (isChecked) {
        for (let i = 0; i < rolePermissionList.size; i++) {
          let rolePermissionId = rolePermissionList.get(i).get('id');
          this.props.removeRolesPermission(rolePermissionId).then(() => {
            this.props.getRolePermission(id);
            if (this.state.search !== '') {
              this.props.getPermissionByConditions('ALL ROLES', this.state.search);
            }
          });
        }
      } else {
        rolePermissionList.forEach(function(permission) {
          pIDList.splice(
            pIDList.findIndex(item => item === permission.get('permission_id')),
            1
          );
        });
        for (let i in pIDList) {
          this.props.addRolesPermission({ permission_id: pIDList[i], role_id: id }).then(() => {
            this.props.getRolePermission(id);
          });
        }
      }
      this.props.getRolePermission(id);
    }
  };

  renderRolesButton = (role, item) => {
    const { userPermissionList } = this.props;
    return (
      <div
        className={
          role === item.get('id')
            ? 'button Roles-Permissions-button Roles-Permissions-button-blue'
            : 'button Roles-Permissions-button'
        }
        onClick={this.onUpdateRolePermission.bind(this, item.get('id'), item.get('role_name'))}
      >
        <div className="textHidden">{item.get('role_name')}</div>
        {userPermissionList.includes('EDIT_ROLE') && (
          <b
            className="Roles-Permissions-button-Edit"
            onClick={this.onEditRoles.bind(this, item.get('id'), item.get('role_name'))}
          >
            Edit
          </b>
        )}
      </div>
    );
  };

  update_property_select_show = is_show => {
    this.setState({
      property_select_show: is_show,
    });
  };

  render() {
    const {
      rolesList,
      inRolesList,
      permissionList,
      inPermissionList,
      rolePermissionList,
      rolesInfo,
      removeRole,
      rolesListLoading,
      user,
      rolesOptions,
      userPermissionList,
      userRolePermission,
      propertyOptions,
    } = this.props;
    const {
      error,
      id,
      role,
      search,
      searchType,
      isAddRole,
      permissionIdList,
      initialValues,
      property_select_show,
    } = this.state;

    const userPermission = {
      CreateNewRole: userPermissionList.includes('CREATE_NEW_ROLE'),
      EditRole: userPermissionList.includes('EDIT_ROLE'),
      EditRolesPermissions: userPermissionList.includes('EDIT_ROLES_&_PERMISSIONS'),
      DeleteRole: userPermissionList.includes('DELETE_ROLE'),
    };

    return (
      <div className="container PR-Container">
        <div className="Roles-Permissions__left Roles-Permissions-box Roles-Permissions__top">
          <InputScaffold>
            <MaskedInput
              name="RolesAndPermissions"
              className="RolesAndPermissions"
              value={search}
              onChange={this.onUpdateSearch}
              placeholder="Search Roles & Permissions"
              list="SearchList"
              autoComplete="off"
              maxLength="25"
            />
            <div className="RolesAndPermissions-icons" onClick={this.onCloseSearch}>
              {search === '' ? <Search /> : <Close />}
            </div>
            {search === '' ? null : searchType.length > 0 ? null : (
              <div className="RolesAndPermissions-Search">
                <div onClick={this.onSearchRoles} className="RolesAndPermissions-Search-Top">
                  {search} in roles
                </div>
                <div onClick={this.onSearchPermissions} className="RolesAndPermissions-Search-bottom">
                  {search} in permissions
                </div>
              </div>
            )}
            {searchType.length === 0 ? null : search.length > 30 ? null : (
              <div className="RolesAndPermissions-Tips" style={{ marginLeft: this.state.search.length * 9 - 415 }}>
                {searchType}
              </div>
            )}
          </InputScaffold>
        </div>
        {!inRolesList.size && searchType === 'in roles' ? (
          <h1 className="Roles-Permissions-Found">NO RESULTS FOUND</h1>
        ) : (
          <div className="Roles-Permissions-Container">
            <div className="Roles-Permissions__left Roles-Permissions-box">
              <div className="Roles-Permissions-box__header">Roles</div>
              <div className="Roles-Permissions__Roles_left">
                {searchType === 'in permissions' ? (
                  <div className="Roles-Permissions-button-div">
                    <div
                      className={
                        role === 'ALL ROLES'
                          ? 'button Roles-Permissions-button Roles-Permissions-button-blue'
                          : 'button Roles-Permissions-button'
                      }
                      onClick={this.onUpdateRolePermission.bind(this, 'ALL ROLES', 'ALL ROLES')}
                    >
                      ALL ROLES
                    </div>
                  </div>
                ) : null}
                {rolesList.size > 0 &&
                  searchType === '' &&
                  rolesList.map((item, index) => {
                    return (
                      <div className="Roles-Permissions-button-div" key={index}>
                        {this.renderRolesButton(id, item)}
                      </div>
                    );
                  })}
                {inRolesList.size > 0 &&
                  searchType !== '' &&
                  inRolesList.map((item, index) => {
                    return (
                      <div className="Roles-Permissions-button-div" key={index}>
                        {this.renderRolesButton(id, item)}
                      </div>
                    );
                  })}
              </div>
              {userPermission.CreateNewRole && searchType === '' ? (
                <div className="Roles-Permissions-button-div">
                  <div
                    className="button Roles-Permissions-button button Roles-Permissions-button-add"
                    onClick={this.onAddRoles}
                  >
                    Add +
                  </div>
                </div>
              ) : null}
            </div>

            {isAddRole === 'list' ? (
              <div className="Roles-Permissions__right">
                {!rolesListLoading ? <Loading /> : ''}
                <div style={{ visibility: !rolesListLoading ? 'hidden' : 'visible' }}>
                  {searchType !== 'in permissions' ? (
                    <div>
                      <div className="Roles-Permissions-box__header">
                        {id && rolesInfo.get('role_name')} Permissions
                      </div>
                      <div className="Roles-Permissions-box__text">
                        {role === '' ? 'Select a role to view permissions' : rolesInfo.get('role_description')}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="Roles-Permissions-box__header">Search results for "{search}" in permissions</div>
                    </div>
                  )}
                  <div className="container property-manager-form">
                    <div className="checkbox-list--item Roles-Permissions-checkbox ">
                      {id !== '' && searchType !== 'in permissions' && (
                        <label className="Roles-Permissions-SelectAll">
                          <input
                            type="checkbox"
                            name="SelectAll"
                            readOnly={true}
                            onClick={this.onCheckedSelectAll.bind(
                              this,
                              permissionIdList.length === rolePermissionList.size
                            )}
                            checked={permissionIdList.length === rolePermissionList.size}
                            disabled={!userPermission.EditRolesPermissions}
                          />
                          <span className="label">Select All</span>
                        </label>
                      )}
                      <div className="Roles-Permissions-Checkbox-Container">
                        {role !== '' &&
                          role !== 'ALL ROLES' &&
                          permissionList.size > 0 &&
                          searchType !== 'in permissions' &&
                          permissionList.map((item, index) => (
                            <div className="Roles-Permissions-Checkbox Roles-Permissions-box" key={index}>
                              <div className="checkbox__header">{item.get('group')}</div>
                              {item.get('permissions').size ? (
                                item.get('permissions').map((n, i) => (
                                  <div className="checkbox-list--item" key={i}>
                                    <label>
                                      <div className="Roles-Permissions-checkbox-div">
                                        <div>
                                          <input
                                            type="checkbox"
                                            readOnly={true}
                                            checked={rolePermissionList.some(
                                              item => item.get('permission_id') === n.get('id') && role !== ''
                                            )}
                                            onClick={
                                              userPermission.EditRolesPermissions
                                                ? this.onRoleUpdatePermission.bind(
                                                    this,
                                                    n.get('id'),
                                                    rolePermissionList.some(
                                                      item => item.get('permission_id') === n.get('id')
                                                    ),
                                                    id
                                                  )
                                                : null
                                            }
                                            disabled={!userPermission.EditRolesPermissions}
                                          />
                                        </div>
                                        <span className="label Roles-Permissions-inline-block">
                                          {n.get('permission_name')}
                                        </span>
                                      </div>
                                    </label>
                                  </div>
                                ))
                              ) : (
                                <h1 className="checkbox-list--item">No Permissions</h1>
                              )}
                              {item.get('permissions').size % 3 === 2 && <div className="checkbox-list--item" />}
                            </div>
                          ))}
                        {inPermissionList.size > 0 &&
                          searchType === 'in permissions' &&
                          inPermissionList.map((item, index) => (
                            <div className="Roles-Permissions-Checkbox-search Roles-Permissions-box" key={index}>
                              <div className="checkbox__header">{item.get('role_name')}</div>
                              {item.get('permission').size
                                ? item.get('permission').map((n, i) => (
                                    <div className="checkbox-list--item" key={i}>
                                      <label>
                                        <input
                                          type="checkbox"
                                          readOnly={true}
                                          checked={true}
                                          onClick={
                                            userPermission.EditRolesPermissions
                                              ? this.onRoleUpdatePermission.bind(
                                                  this,
                                                  n.get('id'),
                                                  true,
                                                  item.get('id')
                                                )
                                              : null
                                          }
                                          disabled={!userPermission.EditRolesPermissions}
                                        />
                                        <span className="label">{n.get('permission_name')}</span>
                                      </label>
                                    </div>
                                  ))
                                : null}
                            </div>
                          ))}
                        {permissionList.size % 3 === 2 && (
                          <div className="Roles-Permissions-Checkbox Roles-Permissions-box" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="Roles-Permissions__right Roles-Permissions_Form">
                <div className="Roles-Permissions-box__header">
                  {isAddRole === 'add' ? <span>Add Role</span> : <span>{rolesInfo.get('role_name')} Settings</span>}
                </div>
                <div className="Roles-Permissions-box__text">
                  {isAddRole === 'add' ? (
                    <span>Fill out the fields below to add a new role.</span>
                  ) : (
                    <span>Edit information in the fields below or delete a role using the Delete Role button.</span>
                  )}
                </div>
                {isAddRole === 'add' ? (
                  <RolesForm
                    error={error}
                    userPermission={userPermission}
                    isAddRole={isAddRole}
                    rolesOptions={rolesOptions}
                    propertyOptions={propertyOptions}
                    isChangeRoleLevel={true}
                    updateRoleList={this.updateRoleList}
                    initialValues={{
                      role_name: '',
                      role_level: '',
                      role_description: '',
                      property_id: '',
                      create_by: user.get('id'),
                    }}
                    update_property_select_show={this.update_property_select_show}
                    property_select_show={property_select_show}
                    onSubmit={values => {
                      return this.props.addRoles(values).then(action => {
                        if (action.response.ok) {
                          this.updateRoleList();
                        } else {
                          this.setState({ error: action.json.message });
                        }
                      });
                    }}
                  />
                ) : (
                  <div>
                    <RolesForm
                      error={error}
                      userPermission={userPermission}
                      isAddRole={isAddRole}
                      rolesOptions={rolesOptions}
                      id={id}
                      rolesInfo={rolesInfo}
                      isChangeRoleLevel={userRolePermission.size === 0}
                      updateRoleList={this.updateRoleList}
                      updateRoleDel={this.updateRoleDel}
                      initialValues={initialValues}
                      update_property_select_show={this.update_property_select_show}
                      property_select_show={property_select_show}
                      propertyOptions={propertyOptions}
                      onSubmit={values => {
                        return this.props.editRoles(id, values).then(action => {
                          if (action.response.ok) {
                            this.updateRoleList();
                          } else {
                            this.setState({ error: action.json.message });
                          }
                        });
                      }}
                      removeRole={removeRole}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Roles;
