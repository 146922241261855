import React from 'react';

function AddIcon() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="147.539" height="147.539" viewBox="0 0 147.539 147.539">
      <g id="Group_1167" data-name="Group 1167" transform="translate(-462.759 -2074.427)">
        <path
          id="Union_2"
          data-name="Union 2"
          d="M1313.526,12738.968V12685.2H1259.76a10,10,0,1,1,0-20h53.766v-53.769a10,10,0,0,1,20,0v53.769H1387.3a10,10,0,1,1,0,20h-53.769v53.769a10,10,0,1,1-20,0Z"
          transform="translate(-787 -10527.003)"
          fill="#fff"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default AddIcon;
