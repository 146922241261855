import React from 'react';

function TrashIcon() {
  /* eslint-disable max-len */
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5001 0.937351H9.75081L9.45623 0.39001C9.39404 0.272763 9.29816 0.174128 9.1794 0.105222C9.06063 0.0363153 8.9237 -0.000124694 8.78403 8.35533e-06H5.21329C5.07386 -0.000632692 4.93706 0.0356257 4.81862 0.104616C4.70019 0.173606 4.60492 0.272528 4.54377 0.39001L4.25008 0.938188H0.499904C0.367476 0.938188 0.240458 0.98745 0.146734 1.07516C0.0530092 1.16287 0.000236479 1.28187 0 1.40602L0 2.34336C0 2.46766 0.0526683 2.58687 0.146419 2.67477C0.240169 2.76266 0.367321 2.81204 0.499904 2.81204H13.5001C13.6327 2.81204 13.7598 2.76266 13.8536 2.67477C13.9473 2.58687 14 2.46766 14 2.34336V1.40602C14 1.28172 13.9473 1.16251 13.8536 1.07462C13.7598 0.986729 13.6327 0.937351 13.5001 0.937351ZM1.66218 13.6819C1.68603 14.0389 1.85414 14.374 2.13227 14.6189C2.41041 14.8638 2.77765 15.0001 3.15922 15H10.8363C11.2179 15.0001 11.5851 14.8638 11.8633 14.6189C12.1414 14.374 12.3095 14.0389 12.3334 13.6819L12.9957 3.75022H0.999809L1.66218 13.6819Z"
        fill="white"
      />
    </svg>
  );
  /* eslint-enable */
}

export default TrashIcon;
