import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icons/icon';

class Kebab extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleDomClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDomClick);
  }

  handleDomClick = e => {
    if (!this.ref.contains(e.target) && this.props.menuOpened) {
      this.props.onDisengage();
    }
  };

  renderIcon() {
    const { vertical } = this.props;

    return (
      <button
        className="button button--kebab"
        type="button"
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <Icon icon="Kebab" style={{ transform: vertical ? 'rotate(90deg)' : 'unset' }} />
      </button>
    );
  }

  render() {
    const { children, menuOpened } = this.props;

    return (
      <div
        className="kebab"
        ref={node => {
          this.ref = node;
        }}
      >
        {this.renderIcon()}
        {menuOpened ? children : null}
      </div>
    );
  }
}

Kebab.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onDisengage: PropTypes.func.isRequired,
  menuOpened: PropTypes.bool.isRequired,
};
export default Kebab;
