import React from 'react';
import PropTypes from 'prop-types';

export const NonRemovablePopup = ({ message, onCancel }) => (
  <div className="delete__wrapper">
    <div className="text-error remove-text">{message}</div>
    <div className="delete__button-container">
      <button className="button button--secondary--destructive" type="button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  </div>
);

NonRemovablePopup.prototypes = {
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
};
