import React from 'react';
import PropTypes from 'prop-types';
import { useTablesTextTruncate } from '..';

export const SGTdName = ({ name, identification, startDate, endDate }) => {
  const truncatedText = useTablesTextTruncate(name);

  return (
    <div className="td-name">
      {truncatedText}
      {identification && <span className="identification">Identification</span>}
      {startDate && (
        <span className="date">
          {startDate} - {endDate}
        </span>
      )}
    </div>
  );
};

SGTdName.propTypes = {
  name: PropTypes.string,
  identification: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};
