import React from 'react';

function ChevronDown() {
  /* eslint-disable max-len */
  return (
    <svg height="24" width="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
    </svg>
  );
  /* eslint-enable */
}

export default ChevronDown;
