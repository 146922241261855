import { connect } from 'react-redux';
import { getCredentialTypes } from '../../../../../../redux/actions';
import EditCredentialType from './EditCredentialType';
import { selectActiveCredentialType } from '../../../../../../redux/selectors';
import { editCredentialType } from '../../../../../../redux/actions';

function mapStateToProps(state, props) {
  return {
    credentialType: selectActiveCredentialType(state, props),
    credentialTypesLoaded: state.credentialTypesLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getCredentialTypes: () => dispatch(getCredentialTypes()),
    editCredentialType: payload => dispatch(editCredentialType(ownProps.match.params.credentialTypeId, payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCredentialType);
