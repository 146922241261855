import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  SGPageHeader,
  SGTdContact,
  SGTdName,
  SGTdText,
  SGTdButton,
  useFetchTourGuests,
  SGSortSearchRow,
  useSortSearch,
  useToDetail,
  REVOKE_ACCESS,
  ResidentRevokeModal,
  useResidentRevokeModal,
  useFetchListResidentAccess,
  usePhoneFormating,
} from '../../../common';
import Loading from '../../../../../../../../../common/Loading';
import Icon from '../../../../../../../../../common/icons/icon';
import { useGuest } from '../hooks';

export const SGScheduledTab = () => {
  const { isLoad, dataLoaded, propertyId, refetch } = useFetchTourGuests({
    search: '',
    sort_by: 0,
    type: 'scheduled',
  });

  const { dataResult, total, ...rest } = useSortSearch(dataLoaded);
  const { goToDetail } = useToDetail();
  const getFormattedPhone = usePhoneFormating();

  const {
    handleCancel,
    handleConfirmRevoke,
    handleOpen,
    isConfirmRevoke,
    isOpen,
    handleChangeMessage,
    message,
  } = useResidentRevokeModal();

  const { guest, handleChangeGuest } = useGuest();

  useFetchListResidentAccess({
    guestId: guest.id,
    isFetching: isConfirmRevoke,
    reason: message,
    onFetchSuccess: () => {
      refetch();
      handleCancel();
    },
    type: REVOKE_ACCESS,
  });

  if (isLoad) return <Loading />;

  return (
    <div className="sg-tab-content container m-b30">
      <SGPageHeader
        title="Scheduled Self-Guided Tours"
        subTitle="This is a list of upcoming self-guided tours by prospective residents.
        You can revoke their access at any time."
      />

      <SGSortSearchRow total={`${total} upcoming`} {...rest} />

      <div className="tab-content">
        <table className="tab-table">
          <thead>
            <tr>
              <th>NAME</th>
              <th>CONTACT</th>
              <th>TOUR</th>
              <th>NOTES</th>
              <th width="225px"></th>
            </tr>
          </thead>
          <tbody>
            {dataResult.map(({ id, first_name, last_name, phone, email, apartment_messages, start_date, end_date }) => (
              <tr key={id} className="tab-tr" onClick={() => goToDetail('scheduled', id)}>
                <td className="tab-td">
                  <SGTdName
                    name={`${first_name} ${last_name}`}
                    identification
                    startDate={moment(start_date).format('MM.DD.YY')}
                    endDate={moment(end_date).format('MM.DD.YY')}
                  />
                </td>
                <td className="tab-td">
                  <SGTdContact phone={getFormattedPhone(phone)} email={email} />
                </td>
                <td className="tab-td" onClick={e => e.stopPropagation()}>
                  <Link className="all_units_link" to={`/properties/${propertyId}/units/tours/automated/edit/settings`}>
                    All available model units
                  </Link>
                </td>
                <td className="tab-td">
                  <SGTdText text={apartment_messages} />
                </td>
                <td
                  className="tab-td"
                  onClick={e => {
                    e.stopPropagation();
                    handleOpen();
                    handleChangeGuest({ id, first_name, last_name });
                  }}
                >
                  <SGTdButton className="td-revoke" icon={<Icon icon="RedCirclewLine" />} title="Revoke Access" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <ResidentRevokeModal
          onChangeMessage={handleChangeMessage}
          onCancel={handleCancel}
          onConfirm={handleConfirmRevoke}
          isOpen={isOpen}
          name={`${guest.first_name} ${guest.last_name}`}
        />
        {!dataResult.length && <div className="empty-text">There are no tours at the moment.</div>}
      </div>
    </div>
  );
};
