import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const SGCheckboxListItem = ({ name, value, className, checked, title, subTitle, onChange }) => (
  <li className="checkbox-list-item">
    <label className={classNames('list-item', { blue: checked })}>
      <div className="list-item-checkbox">
        <input onChange={onChange} type="checkbox" name={name} className={className} checked={checked} value={value} />
      </div>
      <div className="list-item-text-block">
        <span className="list-item-title">{title}</span>
        <span className="list-item-subtitle">{subTitle}</span>
      </div>
    </label>
  </li>
);

SGCheckboxListItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
