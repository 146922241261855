import React from 'react';
import { bool, func, string } from 'prop-types';
import Icon from '../../../../../../../../../common/icons/icon';

export const SGToggle = ({ handleSwitch, isEnabled, titleOn, titleOff }) => (
  <div className="toggle-icon" onClick={handleSwitch}>
    <Icon className="amenity-closed--toggle" icon={isEnabled ? 'SGSwitchOn' : 'SGSwitchOff'} />
    <p className={isEnabled ? 'toggle-enabled' : 'toggle-disabled'}>{isEnabled ? titleOn : titleOff}</p>
  </div>
);

SGToggle.propTypes = {
  handleSwitch: func.isRequired,
  isEnabled: bool.isRequired,
  titleOn: string.isRequired,
  titleOff: string.isRequired,
};
