import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCommunityStripeConnectAccount,
  createCommunityStripeConnectAccount,
} from '../../../../../redux/actions/communityStripeIntegrationActions';
import ConnectionStatusIndicator from '../../../../../../../common/StripeIntegration/ConnectionStatusIndicator';
import ExternalLink from '../../../../../../../common/icons/ExternalLink';

const CommunityStripeIntegration = () => {
  const community = useSelector(state => state.property.activeProperty);
  const communityStripeConnectAccount = useSelector(state => state.property.communityStripeConnectAccount);
  const communityStripeConnectAccountLoaded = useSelector(state => state.property.communityStripeConnectAccountLoaded);
  const communityStripeConnectAccountLink = useSelector(state => state.property.communityStripeConnectAccountLink);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommunityStripeConnectAccount(community.get('id').toString()));
  }, [dispatch, community]);

  useEffect(() => {
    if (communityStripeConnectAccountLink) {
      window.location = communityStripeConnectAccountLink.get('url');
    }
  }, [communityStripeConnectAccountLink]);

  const renderConnectButton = () => {
    if (!communityStripeConnectAccountLoaded) {
      return <div>Loading Stripe Connection Status...</div>;
    }

    if (!communityStripeConnectAccount || communityStripeConnectAccount.get('connected') === false) {
      return (
        <button
          className="button connect-to-stripe--button"
          onClick={() => {
            dispatch(createCommunityStripeConnectAccount(community.get('id').toString()));
          }}
        >
          Connect to Stripe
        </button>
      );
    }
  };

  const renderConnectionStatusIndicator = () => {
    if (communityStripeConnectAccount) {
      return (
        <ConnectionStatusIndicator
          isConnected={communityStripeConnectAccount.get('connected')}
          stripeAccountId={communityStripeConnectAccount.get('stripe_account_id')}
        />
      );
    }
  };

  return (
    <section className="paper__section community-stripe-settings__wrapper">
      <h4 className="h4">Connect to a Stripe Account</h4>
      <p className="help-text tighter">To connect to a Stripe account, follow the steps in this article:</p>
      <div className="external-link">
        <a
          href="https://support.stripe.com/questions/getting-started-with-stripe-create-or-connect-an-account"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to connect to a Stripe Account
        </a>
        <ExternalLink />
      </div>
      <p className="help-text tighter">Once completed successfully, you'll see the status change below.</p>
      {renderConnectionStatusIndicator()}
      {renderConnectButton()}
    </section>
  );
};

export default CommunityStripeIntegration;
