import client from '../../../../common/client';

export const getModelUnits = propertyId => ({
  promise: client.get(`properties/${propertyId}/units?unit_type=model`),
  type: 'GET_MODAL_UNITS',
});

export const getStaffMembers = propertyId => ({
  promise: client.get(`properties/${propertyId}/staff`),
  type: 'GET_STAFF_MEMBERS',
});

export const addTour = (propertyId, payload) => ({
  promise: client.post(`properties/${propertyId}/tours`, payload),
  type: 'ADD_TOUR',
});

export const editTour = (propertyId, tourId, payload) => ({
  promise: client.patch(`properties/${propertyId}/tours/${tourId}`, payload),
  type: 'EDIT_TOUR',
});

export const getTour = propertyId => ({
  promise: client.get(`properties/${propertyId}/tours`),
  type: 'GET_TOUR',
});

export const deleteTour = (propertyId, tourId) => ({
  promise: client.delete(`properties/${propertyId}/tours/${tourId}`),
  type: 'DELETE_TOUR',
});

export const changeTourStatus = (propertyId, tourId, text) => ({
  promise: client.post(`properties/${propertyId}/tours/${tourId}`, { text: text }),
  type: 'CHANGE_TOUR_STATUS',
});
