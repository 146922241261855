import React from 'react';

function Minus() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M19 13H5v-2h14v2z" />
    </svg>
  );
  /* eslint-enable */
}

export default Minus;
