import React from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../../../../../../../../../common/Loading';
import { SGCheckboxList } from './SGCheckboxList';
import { selectAccessControlProfileOptions } from '../../../../../../../../redux/selectors';
import { SGProfileForm } from './SGProfileForm';
import { selectStaffMembers } from '../../../../../../../../redux/reducers/sgToursReducers';
import { useFormikContext } from 'formik';

export const SGFormUnitsStaff = () => {
  const { values, setFieldValue } = useFormikContext();
  const profiles = useSelector(selectAccessControlProfileOptions);
  const staffMembersData = useSelector(selectStaffMembers);

  if (!staffMembersData) return <Loading />;

  return (
    <div className="sg-units-staff">
      <SGCheckboxList
        isRadio
        title="ACCESS PROFILES"
        onChange={({ active }) => {
          setFieldValue('profile', active?.value || '');
        }}
        items={profiles.map(({ value, label }) => ({
          name: 'profile',
          value,
          title: label,
          checked: values.profile === value,
        }))}
      />
      <SGProfileForm />
    </div>
  );
};
