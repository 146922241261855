import Yup from 'yup';
import moment from 'moment';

export const getEditEventDetailsValidationSchema = (event_date, start_time) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .max(255, 'Title must be 255 characters or fewer')
      .required('Please enter a title'),
    description: Yup.string()
      .trim()
      .max(2000, 'Description must be 2000 characters or fewer')
      .required('Please enter a description'),
    location: Yup.string()
      .trim()
      .max(120, 'Location must be 120 characters or fewer')
      .required('Please enter a location'),
    min_attendees: Yup.number()
      .min(0, 'Please enter a number between 0-10000')
      .max(10000, 'Please enter a number between 0-10000')
      .required('Please enter a number')
      .test('valid_min_max_range', 'Min attendees cannot be greater than max', function(value) {
        return value <= this.parent.max_attendees;
      }),
    max_attendees: Yup.number()
      .min(1, 'Please enter a number between 1-10000')
      .max(10000, 'Please enter a number between 1-10000')
      .required('Please enter a number'),
    waitlist_allowed: Yup.boolean(),
    pending_duration: Yup.number().when('waitlist_allowed', {
      is: val => {
        return val === true;
      },
      then: Yup.number()
        .min(1, 'Please enter a number between 1-12')
        .max(12, 'Please enter a number between 1-12')
        .required('Please enter a number'),
    }),
    rsvp_cutoff_date: Yup.date()
      .test('is-in-future', 'Date must be today or later', value => {
        return moment(value).isSameOrAfter(moment().startOf('day'));
      })
      .test('valid_date_range', 'Cut-off date cannot exceed the event date', function(value) {
        value = moment(value).format('YYYY-MM-DD');
        return moment(value).isSameOrBefore(moment(event_date));
      })
      .required('Please enter a cut-off date'),
    rsvp_cutoff_time: Yup.string()
      .nullable()
      .required('Please enter a cut-off time')
      .test('is-in-future', 'RSVP cut-off time cannot be in the past', function(value) {
        const cutoffDate = moment(this.parent.rsvp_cutoff_date).format('YYYY-MM-DD');
        const cutoffTime = moment(value).format('HH:mm');
        const cutoffDateTime = moment(cutoffDate + ' ' + cutoffTime);

        return !value || cutoffDateTime.isSameOrAfter(moment());
      })
      .test('valid_time_range', "Cut-off time cannot exceed the event's start time", function(value) {
        const eventDate = moment(event_date).format('YYYY-MM-DD');
        const eventTime = moment(start_time).format('HH:mm');
        const eventDateTime = moment(eventDate + ' ' + eventTime);

        const cutoffDate = moment(this.parent.rsvp_cutoff_date).format('YYYY-MM-DD');
        const cutoffTime = moment(value).format('HH:mm');
        const cutoffDateTime = moment(cutoffDate + ' ' + cutoffTime);

        return cutoffDateTime.isSameOrBefore(eventDateTime);
      }),
    confirmation_details: Yup.string().max(2000, 'The confirmation instructions must be less than 2000 characters.'),
  });
