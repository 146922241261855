import client from '../../client';
import { useMutation, useQueryClient } from 'react-query';
import { USE_UNIT_ACCESS_CODE_BY_ID_KEY } from '../queries/useUnitAccessCodeById';

export const useUnitAccessCodeMutations = (entity, accessCodeKey, setAccessCodeReduxAction, payload) => {
  const queryClient = useQueryClient();

  const create = useCreateUnitAccessCode();
  const remove = useRemoveUnitAccessCode();
  const resetCode = useResetUnitAccessCode();
  const sendReminder = useSendUnitAccessCodeReminder();

  const resetMutations = () => {
    create.reset();
    remove.reset();
    resetCode.reset();
    sendReminder.reset();
  };

  return {
    error: create.error || remove.error || resetCode.error || sendReminder.error,
    isError: create.isError || remove.isError || resetCode.isError || sendReminder.isError,
    isLoading: create.isLoading || remove.isLoading || resetCode.isLoading || sendReminder.isLoading,
    isSuccess: create.isSuccess || remove.isSuccess || resetCode.isSuccess || sendReminder.isSuccess,

    create: () => {
      resetMutations();
      create.mutate(payload, {
        onSuccess: data => {
          setAccessCodeReduxAction(entity.get('id'), data);
          queryClient.setQueryData([USE_UNIT_ACCESS_CODE_BY_ID_KEY, data.id], data);
        },
      });
    },
    remove: () => {
      resetMutations();
      remove.mutate(entity.get(accessCodeKey), {
        onSuccess: () => {
          setAccessCodeReduxAction(entity.get('id'), null);
          queryClient.invalidateQueries([USE_UNIT_ACCESS_CODE_BY_ID_KEY, entity.get(accessCodeKey)], {
            exact: true,
            refetchInactive: false,
          });
        },
      });
    },
    resetCode: () => {
      resetMutations();
      resetCode.mutate(entity.get(accessCodeKey), {
        onSuccess: data => {
          queryClient.setQueryData([USE_UNIT_ACCESS_CODE_BY_ID_KEY, data.id], data);
        },
      });
    },
    sendReminder: () => {
      resetMutations();
      sendReminder.mutate(entity.get(accessCodeKey));
    },
  };
};

export const useCreateUnitAccessCode = () => {
  return useMutation(async payload => {
    const response = await client.post(`unit-access-codes`, payload);
    if (!response.ok) throw new Error('There was an error when attempting to create the code.');
    return response.json();
  });
};

const useRemoveUnitAccessCode = () => {
  return useMutation(async unitAccessCodeId => {
    const response = await client.delete(`unit-access-codes/${unitAccessCodeId}`);
    if (!response.ok) throw new Error('There was an error when attempting to remove the code.');
  });
};

const useResetUnitAccessCode = () => {
  return useMutation(async unitAccessCodeId => {
    const response = await client.post(`unit-access-codes/${unitAccessCodeId}/reset`);
    if (!response.ok) throw new Error('There was an error when attempting to reset the code.');
    return response.json();
  });
};

const useSendUnitAccessCodeReminder = () => {
  return useMutation(async unitAccessCodeId => {
    const response = await client.post(`unit-access-codes/${unitAccessCodeId}/reminder`);
    if (!response.ok) throw new Error('There was an error when attempting to send the reminder.');
  });
};
