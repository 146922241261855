import React, { Component, Fragment } from 'react';
import { Formik } from 'formik';
import Yup from 'yup';
import PropTypes from 'prop-types';

import NotificationStatusIndicator from '../../../../../../common/forms/NotificationStatusIndicator';
import Switch from '../../../../../../common/forms/Switch';
import { trimAndPruneFalsyValues } from '../../../../../../common/utils/helpers';
// import SubHeader from '../../../../../../common/SubHeader';
import ModalAlert from '../../../../../../common/ModalAlert';
import InputScaffold from '../../../../../../common/forms/InputScaffold';

class ResidentFeatures extends Component {
  state = {
    formStatus: { message: '' },
    showDisableDiscussionModal: false,
  };

  handleToggle = (setFieldValue, field, val) => {
    // const toggledFeature = field.substr(field.indexOf('_') + 1);
    // window.ga('send', 'event', 'property feature', val ? 'enabled' : 'disabled', toggledFeature);
    setFieldValue(field, val);
  };

  renderToggleSwitch(values, setFieldValue, label, name) {
    return (
      <Switch
        label={label}
        input={{
          value: values[name],
          name,
          onChange: this.handleToggle.bind(this, setFieldValue, name),
        }}
      />
    );
  }

  renderDisableDiscussionReviewModal = (resetForm, values) => {
    const { t } = this.props;

    return (
      <ModalAlert
        title={t('modalTitle')}
        body={t('modalBody')}
        linkTitle={t('modalLinkTitle')}
        link="../community/pending-discussions"
        confirmButtonTitle={t('confirm', { ns: 'buttons' })}
        cancelButtonTitle={t('cancel', { ns: 'buttons' })}
        handleConfirm={() => {
          this.props.approvePendingPosts();
          this.submitForm(values);
        }}
        handleCancel={() => {
          resetForm();
          this.setState({ showDisableDiscussionModal: false });
        }}
        visible={this.state.showDisableDiscussionModal}
      />
    );
  };

  handleFormSubmit = (values, actions) => {
    const { property } = this.props;
    // show modal confirming disabling discussion board reviews
    if (property.get('enabled_discussion_board_review') === true && values.enabled_discussion_board_review === false) {
      this.setState({ showDisableDiscussionModal: true });
    } else {
      this.submitForm(values, actions);
    }
  };

  submitForm = (values, actions) => {
    const { t } = this.props;
    this.setState(() => ({ formStatus: { message: '' } }));
    const submitValues = trimAndPruneFalsyValues(values);
    return this.props.editProperty(submitValues, this.props.property.get('id')).then(response => {
      if (actions) {
        actions.setSubmitting(false);
      }
      if (response.response.ok) {
        this.setState(() => ({
          showDisableDiscussionModal: false,
          formStatus: { message: t('savedConfirmation'), status: 'Success' },
        }));
      } else {
        this.setState(() => ({
          formStatus: {
            message: response.json.statusCode + ' - ' + response.json.message,
            status: 'Failure',
          },
        }));
      }
    });
  };

  render() {
    const { property, t } = this.props;

    const validationSchema = Yup.object().shape({
      enabled_discussion: Yup.boolean().test(
        'at-least-one-type-enabled',
        'At least one post type must be enabled',
        function(value) {
          return !(
            value &&
            !this.parent.enabled_discussion_event &&
            !this.parent.enabled_discussion_general &&
            !this.parent.enabled_discussion_recommendation &&
            !this.parent.enabled_discussion_sale
          );
        }
      ),
      scheduled_unit_guest_max_duration: Yup.number()
        .integer()
        .min(1, t('minDurationNote'))
        .max(999, t('maxDurationNote')),
    });

    return (
      <div>
        <Formik
          initialValues={{
            enabled_discussion: property.get('enabled_discussion'),
            enabled_discussion_general: property.get('enabled_discussion_general'),
            enabled_discussion_board_review: property.get('enabled_discussion_board_review'),
            enabled_discussion_sale: property.get('enabled_discussion_sale'),
            enabled_discussion_recommendation: property.get('enabled_discussion_recommendation'),
            enabled_discussion_event: property.get('enabled_discussion_event'),
            enabled_messages: property.get('enabled_messages'),
            enabled_events: property.get('enabled_events'),
            enabled_maintenance: property.get('enabled_maintenance'),
            enabled_resident_community_guests: property.get('enabled_resident_community_guests'),
            enabled_community_guest_access_one_day: property.get('enabled_community_guest_access_one_day'),
            enabled_community_guest_access_scheduled: property.get('enabled_community_guest_access_scheduled'),
            enabled_community_guest_access_full_time: property.get('enabled_community_guest_access_full_time'),
            scheduled_unit_guest_max_duration: property.get('scheduled_unit_guest_max_duration'),
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={this.handleFormSubmit}
          render={({ resetForm, values, errors, handleSubmit, isSubmitting, setFieldValue }) => {
            return (
              <Fragment>
                {this.renderDisableDiscussionReviewModal(resetForm, values)}
                <div className="container">
                  <form onSubmit={handleSubmit}>
                    <div className="paper radius-top-left radius-top-right">
                      <section className="paper__section resident-app-features">
                        <h4 className="h4">{t('title')}</h4>
                        <p className="font-small">{t('description')}</p>

                        {property.get('admin_enabled_community') &&
                          this.renderToggleSwitch(values, setFieldValue, t('announcements'), 'enabled_messages')}
                        {property.get('admin_enabled_community') &&
                          this.renderToggleSwitch(values, setFieldValue, t('discussion'), 'enabled_discussion')}
                        {errors.enabled_discussion ? (
                          <div className="input-validation">{errors.enabled_discussion}</div>
                        ) : null}
                        {property.get('admin_enabled_community') && values.enabled_discussion ? (
                          <Fragment>
                            {this.renderToggleSwitch(
                              values,
                              setFieldValue,
                              t('discussionBoardReview'),
                              'enabled_discussion_board_review'
                            )}
                            {this.renderToggleSwitch(
                              values,
                              setFieldValue,
                              t('generalPosts'),
                              'enabled_discussion_general'
                            )}
                            {this.renderToggleSwitch(
                              values,
                              setFieldValue,
                              t('forSalePosts'),
                              'enabled_discussion_sale'
                            )}
                            {this.renderToggleSwitch(
                              values,
                              setFieldValue,
                              t('recommendationPosts'),
                              'enabled_discussion_recommendation'
                            )}
                            {this.renderToggleSwitch(
                              values,
                              setFieldValue,
                              t('eventPosts'),
                              'enabled_discussion_event'
                            )}
                          </Fragment>
                        ) : null}
                        {property.get('admin_enabled_community') &&
                          this.renderToggleSwitch(values, setFieldValue, t('events'), 'enabled_events')}
                        {this.renderToggleSwitch(values, setFieldValue, t('maintenance'), 'enabled_maintenance')}
                        {property.get('admin_enabled_access_control') &&
                          this.renderToggleSwitch(
                            values,
                            setFieldValue,
                            t('residentCommunityGuests'),
                            'enabled_resident_community_guests'
                          )}
                        {property.get('admin_enabled_access_control') && values.enabled_resident_community_guests && (
                          <Fragment>
                            {this.renderToggleSwitch(
                              values,
                              setFieldValue,
                              t('fullTimeGuestSchedules'),
                              'enabled_community_guest_access_full_time'
                            )}
                            {this.renderToggleSwitch(
                              values,
                              setFieldValue,
                              t('scheduledGuestSchedules'),
                              'enabled_community_guest_access_scheduled'
                            )}
                            {this.renderToggleSwitch(
                              values,
                              setFieldValue,
                              t('oneDayGuestSchedules'),
                              'enabled_community_guest_access_one_day'
                            )}
                          </Fragment>
                        )}
                        <InputScaffold
                          label={t('maxGuestScheduleDuration')}
                          validation={errors.scheduled_unit_guest_max_duration}
                        >
                          <input
                            type="numeric"
                            maxLength={3}
                            name="scheduled_unit_guest_max_duration"
                            onChange={event => {
                              setFieldValue(
                                'scheduled_unit_guest_max_duration',
                                Number(event.target.value.replace(/[^0-9]+/g, '')).toString()
                              );
                            }}
                            value={values.scheduled_unit_guest_max_duration}
                          />
                        </InputScaffold>
                      </section>
                    </div>

                    <div className="paper__footer radius-bottom-left radius-bottom-right">
                      <button disabled={isSubmitting} type="submit" className="button">
                        {t('saveSettings')}
                      </button>
                      <NotificationStatusIndicator
                        hideNotification={!this.state.formStatus.message}
                        message={this.state.formStatus.message}
                        type={this.state.formStatus.status}
                      />
                    </div>
                  </form>
                </div>
              </Fragment>
            );
          }}
        />
      </div>
    );
  }
}
ResidentFeatures.propTypes = {
  property: PropTypes.object.isRequired,
  editProperty: PropTypes.func.isRequired,
};

export default ResidentFeatures;
