import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';

import SubHeader from '../../../../../../common/SubHeader';
import Delete from '../../../../../../common/Delete';
import Loading from '../../../../../../common/Loading';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../common/Kebab/index';
import Icon from '../../../../../../common/icons/icon';
import UnrecognizedDoors from './UnrecognizedDoors';

class DoorsList extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    accessControlDoors: PropTypes.instanceOf(List),
    accessControlDoorsLoaded: PropTypes.bool.isRequired,
    getDoors: PropTypes.func.isRequired,
    getPanelDoors: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getDoors();
  }

  state = {
    openKebabDoorId: null,
    deleteConfirmationDoorId: null,
    error: false,
  };

  getCommType = commType => {
    switch (commType.toUpperCase()) {
      case 'K':
        return 'AX Bus';
      case 'N':
        return 'Network';
      case 'W':
        return 'Wireless';
      default:
        return commType;
    }
  };

  onKebabClick = doorId => this.setState({ openKebabDoorId: doorId });

  closeKebab = () => this.setState({ openKebabDoorId: null });

  renderKebab(door) {
    const { match, history } = this.props;
    const { openKebabDoorId } = this.state;

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(door.get('id'))}
          onDisengage={this.closeKebab}
          menuOpened={door.get('id') === openKebabDoorId}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={() => {
                history.push(`${match.url}/${door.get('id')}`);
                this.closeKebab();
              }}
            >
              <Icon icon="Edit" />
              Edit
            </div>
            <div
              className="kebab__menu--item"
              onClick={() => this.setState({ deleteConfirmationDoorId: door.get('id'), openKebabDoorId: null })}
            >
              <Icon icon="Remove" />
              Delete
            </div>
          </div>
        </Kebab>
      </div>
    );
  }

  renderDeleteConfirmation = door => {
    const { removeDoor } = this.props;
    return (
      <div>
        <Delete
          onCancel={() => this.setState({ deleteConfirmationDoorId: null, openKebabDoorId: null })}
          deleteFunc={async () => {
            const action = await removeDoor(door);
            if (!action.response.ok) {
              this.setState({
                error: (action.json && action.json.message) || 'Unknown error occurred while deleting device',
                deleteConfirmationDoorId: null,
                openKebabDoorId: null,
              });
            }
          }}
          message={
            <span>
              Are you sure you want to <strong className="strong">remove this door</strong>?
            </span>
          }
        />
      </div>
    );
  };

  renderAccessAreas(door) {
    const areas = door.get('areas');
    return areas.size > 3 ? (
      <div style={{ columnCount: 2, margin: 10, marginLeft: 0, width: 'fit-content' }}>
        {areas.map(area => (
          <li style={{ marginBottom: 10, listStyleType: 'none' }} key={area.get('id')}>
            {area.get('name')}
          </li>
        ))}
      </div>
    ) : (
      <ul>
        {areas.map(area => (
          <li style={{ margin: '10px', marginLeft: '0px' }} key={area.get('id')}>
            {area.get('name')}
          </li>
        ))}
      </ul>
    );
  }

  render() {
    const { match, accessControlDoors, accessControlDoorsLoaded, getPanelDoors, getDoors } = this.props;
    const { error, deleteConfirmationDoorId } = this.state;

    if (!accessControlDoorsLoaded) {
      return <Loading />;
    }

    return (
      <div className="doors-list--page">
        <div className="container">
          <SubHeader
            title="Doors"
            size={24}
            actions={[
              <button
                type="button"
                className="button button--outline"
                onClick={() => {
                  getDoors();
                  getPanelDoors();
                }}
              >
                Fetch Doors
              </button>,
              accessControlDoors.size !== 0 && (
                <Link to={`${match.url}/add-door`} className="button">
                  Add New Door
                </Link>
              ),
            ]}
          />
          {error && <div className="input-validation">{error}</div>}

          <div className="recog--container">
            <div className="recog__recognized">
              <h4 className="h4">Recognized</h4>
              {accessControlDoors.size > 0 ? (
                <table className="table-units-list">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Device Number</th>
                      <th>Communication Type</th>
                      <th>Access Areas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessControlDoors.map(door => (
                      <tr key={door.get('id')}>
                        <td>{door.get('name')}</td>
                        <td>{door.get('dmp_device_number')}</td>
                        <td>{this.getCommType(door.get('dmp_device_communication_type'))}</td>
                        <td>{this.renderAccessAreas(door)}</td>
                        {
                          <td className="table__align-right">
                            {deleteConfirmationDoorId === door.get('id')
                              ? this.renderDeleteConfirmation(door)
                              : this.renderKebab(door)}
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <EmptyPlaceholder buttonText="Add New Door" linkTo={`${match.url}/add-door`} />
              )}
            </div>
            <div className="recog__unrecognized">
              <h4 className="h4">Unrecognized</h4>
              <div className="help-text">Doors must have Device Type "Door" and Communication Type "AX Bus"</div>
              <UnrecognizedDoors />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DoorsList;
