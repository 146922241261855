import { connect } from 'react-redux';
import {
  createPanel,
  checkPanelEasyConnectStatus,
  checkOnboardPanelStatus,
  initializePanel,
  checkInitializePanelStatus,
  refreshPanelCommunicationOptions,
  getJobStatus,
  setPanelCommunicationOptions,
  updatePanel,
  getPanelDevices,
  setPanelSystemOptions,
  setPanelNetworkOptions,
  pairPanelToUnit,
  unpairPanelFromUnit,
  findPanelById,
} from '../../redux/actions';
import { getUnpairedPropertyUnits } from '../../../../../../../../redux/actions';
import AddPanel from './AddPanel';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    panel: state.devices.panelDevices,
    unpairedPropertyUnits: state.unpairedPropertyUnits,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    createPanel: payload => dispatch(createPanel(ownProps.match.params.propertyId, payload)),
    checkOnboardPanelStatus: (serialNumber, payload) =>
      dispatch(checkOnboardPanelStatus(ownProps.match.params.propertyId, serialNumber, payload)),
    checkPanelEasyConnectStatus: panelId =>
      dispatch(checkPanelEasyConnectStatus(ownProps.match.params.propertyId, panelId)),
    initializePanel: panelId => dispatch(initializePanel(ownProps.match.params.propertyId, panelId)),
    checkInitializePanelStatus: (panelId, groupId) =>
      dispatch(checkInitializePanelStatus(ownProps.match.params.propertyId, panelId, groupId)),
    refreshPanelCommunicationOptions: panelId =>
      dispatch(refreshPanelCommunicationOptions(ownProps.match.params.propertyId, panelId)),
    getJobStatus: jobId => dispatch(getJobStatus(ownProps.match.params.propertyId, jobId)),
    setPanelCommunicationOptions: panelId =>
      dispatch(setPanelCommunicationOptions(ownProps.match.params.propertyId, panelId)),
    updatePanel: (panelId, payload) => dispatch(updatePanel(ownProps.match.params.propertyId, panelId, payload)),
    getPanelDevices: panelId => dispatch(getPanelDevices(ownProps.match.params.propertyId, panelId)),
    setPanelSystemOptions: (panelId, payload) =>
      dispatch(setPanelSystemOptions(ownProps.match.params.propertyId, panelId, payload)),
    setPanelNetworkOptions: (panelId, payload) =>
      dispatch(setPanelNetworkOptions(ownProps.match.params.propertyId, panelId, payload)),
    getUnpairedPropertyUnits: () => dispatch(getUnpairedPropertyUnits(ownProps.match.params.propertyId)),
    pairPanelToUnit: (panelId, unitId) => dispatch(pairPanelToUnit(panelId, unitId)),
    unpairPanelFromUnit: unitId => dispatch(unpairPanelFromUnit(ownProps.match.params.panelId, unitId)),
    findPanelById: panelId => dispatch(findPanelById(ownProps.match.params.propertyId, panelId)),
  };
}

const AddPanelContainer = connect(mapStateToProps, mapDispatchToProps)(AddPanel);

export default AddPanelContainer;
