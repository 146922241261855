import React from 'react';
import { SGModal } from '../../modals/SGModal';
import { SGBtnBack } from '../../shared';

export const ResidentGuestLimitModal = ({ width, visible, close, withBackHandler }) => (
  <SGModal visible={visible} width={width} handleCancel={close}>
    <div className="limit-modal-popup-container">
      <div className="limit-modal-popup-button-container">
        <SGBtnBack btnTitle="Back" handler={withBackHandler && close} />
      </div>
      <h1 className="detail-modal-popup-title">Maximum Scheduled Tours Reached</h1>
      <p className="text-center limit-modal-popup-message">You can only approve 19 tours at one time.</p>
      <p className="text-center limit-modal-popup-message strong">
        You can no longer approve pending tours until a spot opens up.
      </p>
    </div>
  </SGModal>
);
