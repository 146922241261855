import React from 'react';
import PropTypes from 'prop-types';

export const SGTdStatus = ({ status }) => (
  <div className="td-status">
    {status ? <span className="td-status-success">Completed</span> : <span className="td-status-failure">No show</span>}
  </div>
);

SGTdStatus.propTypes = {
  status: PropTypes.bool,
};
