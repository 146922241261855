import { connect } from 'react-redux';

import AccessPointsList from './AccessPointsList';
import { getAccessPoints, removeAccessPoint } from '../../../../redux/actions';
import { getUserPermission } from '../../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessPoints: state.property.accessPoints,
    accessPointsLoaded: state.property.accessPointsLoaded,
    isAdmin: !!state.user.get('roles').find(r => r.get('role') === 'ADMIN'),
    userPermissionList: getUserPermission(state),
    roles: state.user.get('role'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getAccessPoints: () => dispatch(getAccessPoints(ownProps.match.params.propertyId)),
    removeAccessPoint: areaId => dispatch(removeAccessPoint(ownProps.match.params.propertyId, areaId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessPointsList);
