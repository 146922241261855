import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const SGTdButton = ({ className, icon, title }) => (
  <div className={classNames('td-button', className)}>
    {icon}
    <span className="button-label">{title}</span>
  </div>
);

SGTdButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
