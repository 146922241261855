import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useQuery } from 'react-query';
import client from '../../../../../../common/client';
import { useHistory, useParams } from 'react-router-dom';

export const USE_GET_AMENITIES_LIST = 'getAmenitiesList';
export const USE_GET_AMENITY_BY_ID = 'getAmenityById';
export const USE_AMENITY_RESERVATIONS = 'amenityReservations';
export const USE_AMENITY_HAS_RESERVATIONS = 'amenityHasReservations';

export const useCreateAmenity = communityId => {
  const { propertyId } = useParams();

  const amenitiesList = useAmenitiesList(propertyId);

  return useMutation(async formValues => {
    let name = formValues.name;

    let duplicateName = amenitiesList.data
      .filter(amenity => name.toString().toLowerCase() === amenity.name.toString().toLowerCase())
      .map(amenity => amenity.name);

    if (duplicateName.length > 0) {
      throw new Error('Duplicate Amenity Names Are Not Allowed');
    }
    const response = await client.post(`amenities`, {
      ...formValues,
      community_id: communityId,
    });

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useAmenitiesList = communityId => {
  return useQuery(
    [USE_GET_AMENITIES_LIST, communityId],
    async () => {
      const response = await client.get(`amenities?community_id=${communityId}`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      staleTime: 30000,
      enabled: typeof communityId === 'string',
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );
};

export const useRefetchAmenitiesList = communityId => {
  const client = useQueryClient();
  return () => client.refetchQueries([USE_GET_AMENITIES_LIST, communityId]);
};

export const useGetAmenityById = amenityId => {
  return useQuery(
    [USE_GET_AMENITY_BY_ID, amenityId],
    async () => {
      const response = await client.get(`amenities/${amenityId}`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      staleTime: 30000,
      enabled: typeof amenityId === 'string',
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );
};

export const useRefetchAmenityById = amenityId => {
  const client = useQueryClient();
  return useCallback(() => client.refetchQueries([USE_GET_AMENITY_BY_ID, amenityId]), [client, amenityId]);
};

export const useAmenityReservations = (amenityId, page, pageSize) => {
  return useQuery(
    [USE_AMENITY_RESERVATIONS, amenityId, page, pageSize],
    async () => {
      const response = await client.get(`amenities/${amenityId}/reservations?page=${page}&pageSize=${pageSize}`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      keepPreviousData: true,
      staleTime: 5000,
      enabled: typeof amenityId === 'string',
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );
};

export const useRefetchAmenityReservationsById = (amenityId, page, pageSize) => {
  const client = useQueryClient();
  return () => client.refetchQueries([USE_AMENITY_RESERVATIONS, amenityId, page, pageSize]);
};

export const useUpdateAmenity = amenityId => {
  return useMutation(async formValues => {
    const response = await client.patch(`amenities/${amenityId}`, formValues);

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useDeleteAmenity = amenityId => {
  return useMutation(async () => {
    const response = await client.delete(`amenities/${amenityId}`);

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }
  });
};

export const useNavigateToEditAmenityPage = id => {
  const history = useHistory();
  const { propertyId } = useParams();

  return useCallback(() => {
    history.push(`/properties/${propertyId}/community/amenities/${id}`);
  }, [id, propertyId, history]);
};

export const useAddTemporaryClosing = amenityId => {
  return useMutation(async temporaryClosingData => {
    const response = await client.post(`amenities/${amenityId}/closings`, temporaryClosingData);

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useDeleteTemporaryClosing = amenityId => {
  return useMutation(async temporaryClosingId => {
    const response = await client.delete(`amenities/${amenityId}/closings/${temporaryClosingId}`);

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useCancelAmenityReservation = (amenityId, reservationId) => {
  return useMutation(async cancelData => {
    const response = await client.post(
      `amenities/${amenityId}/reservations/${reservationId}/cancel-reservation`,
      cancelData
    );

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useAmenityHasReservations = amenityId => {
  return useQuery(
    [USE_AMENITY_HAS_RESERVATIONS, amenityId],
    async () => {
      const response = await client.get(`amenities/${amenityId}/has-reservations`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      keepPreviousData: true,
      staleTime: 5000,
      enabled: typeof amenityId === 'string',
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );
};

export const useCloseAmenity = amenityId => {
  return useMutation(async () => {
    const response = await client.patch(`amenities/${amenityId}/closed`, {
      closed: true,
    });

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useOpenAmenity = amenityId => {
  return useMutation(async () => {
    const response = await client.patch(`amenities/${amenityId}/closed`, {
      closed: false,
    });

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useCreateClosureAnnouncement = communityId => {
  return useMutation(async announcementData => {
    const response = await client.post(`properties/${communityId}/messages`, announcementData);

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};
