import client from '../../../common/client';

export const getRoles = () => {
  return {
    promise: client.get(`role/get-roles`),
    type: 'GET_ROLES',
  };
};
export const getRolesByName = name => {
  return {
    promise: client.get(`role/get-roles-by-name/${name}`),
    type: 'GET_ROLES_NAME',
  };
};

export const getGroupPermission = () => {
  return {
    promise: client.get(`group-permission/get-group-permission`),
    type: 'GET_GROUP_PERMISSION',
  };
};

export const getRolePermission = role => {
  return {
    promise: client.get(`group-permission/get-role-permission/${role}`),
    type: 'GET_ROLE_PERMISSION',
  };
};

export const getRoleByPermission = permission => {
  return {
    promise: client.get(`group-permission/get-permission-role/${permission}`),
    type: 'GET_ROLE_BY_PERMISSION',
  };
};

export const getPermissionByConditions = (role, permission) => {
  return {
    promise: client.get(`role/get-permission-by-conditions/${role}/${permission}`),
    type: 'GET_PERMISSION_BY_CONDITIONS',
  };
};

export const addRoles = data => {
  const payload = {
    ...data,
  };
  return {
    promise: client.post(`role-management/roles`, payload),
    type: 'CREATE_ROLES',
  };
};

export const getRoleById = role => {
  const payload = {};
  return {
    promise: client.get(`role-management/roles/${role}`, payload),
    type: 'GET_ROLE_BY_ID',
  };
};

export const editRoles = (role, data) => {
  const payload = {
    ...data,
  };
  return {
    promise: client.post(`role-management/roles/${role}`, payload),
    type: 'EDIT_ROLES',
  };
};

export const removeRoles = role => {
  return {
    promise: client.delete(`role-management/roles/${role}`),
    type: 'REMOVE_ROLES',
  };
};

export const addRolesPermission = data => {
  const payload = {
    ...data,
  };
  return {
    promise: client.post(`role-management/role-permission`, payload),
    type: 'ADD_ROLES_PERMISSION',
  };
};

export const removeRolesPermission = id => {
  return {
    promise: client.delete(`role-management/role-permission/${id}`),
    type: 'REMOVE_ROLES_PERMISSION',
  };
};

export const removeRolePermissionByRolePermissionId = (role, permissionId) => {
  return {
    promise: client.delete(`role-management/role-permission/${role}/${permissionId}`),
    type: 'REMOVE_ROLES_PERMISSION_BY_ROLES_PERMISSION_ID',
  };
};
export const getUserRolePermission = (roleId, data) => {
  const payload = {
    ...data,
  };
  return {
    promise: client.get(`role-management/user-role-permission/${roleId}`, payload),
    type: 'GET_USER_ROLE_PERMISSION',
  };
};

//Permission

export const getGroup = () => {
  return {
    promise: client.get(`group-permission/get-groups`),
    type: 'GET_GROUP',
  };
};

export const getGroupPermissionByName = permission => {
  return {
    promise: client.get(`group-permission/get-group-permission-by-name/${permission}`),
    type: 'GET_GROUP_PERMISSION_BY_NAME',
  };
};

export const getPermissionById = id => {
  const payload = {};
  return {
    promise: client.get(`permission-management/permission/${id}`, payload),
    type: 'GET_PERMISSION_BY_ID',
  };
};

export const addPermission = data => {
  const payload = {
    ...data,
  };
  return {
    promise: client.post(`permission-management/permission`, payload),
    type: 'CREATE_PERMISSION',
  };
};

export const editPermission = (PermissionId, data) => {
  const payload = {
    ...data,
  };
  return {
    promise: client.post(`permission-management/permission/${PermissionId}`, payload),
    type: 'EDIT_PERMISSION',
  };
};

export const addGroup = data => {
  const payload = {
    ...data,
  };
  return {
    promise: client.post(`group-permission/create-group`, payload),
    type: 'CREATE_GROUP',
  };
};
export const updateGroup = data => {
  const payload = {
    ...data,
  };
  return {
    promise: client.post(`group-permission/update-group`, payload),
    type: 'EDIT_GROUP',
  };
};

//User
export const getUsers = payload => {
  return {
    promise: client.get(`user/get-users/${payload.username}/${payload.role_id}/${payload.property_id}`),
    type: 'GET_USERS',
  };
};

export const addUsers = data => {
  const payload = {
    ...data,
  };
  return {
    promise: client.post(`user/create-users`, payload),
    type: 'CREATE_USERS',
  };
};

export const editUsers = data => {
  const payload = {
    ...data,
  };
  return {
    promise: client.patch(`user/edit-users`, payload),
    type: 'EDIT_USERS',
  };
};

export const removeUsers = id => {
  return {
    promise: client.delete(`user/del-users/${id}`),
    type: ' ',
  };
};
export const getRolesByRoleLevel = rolelevel => {
  return {
    promise: client.get(`role/get-roles-by-rolelevel/${rolelevel}`),
    type: 'GET_ROLES_NAME_BY_LEVEL',
  };
};

export const getRolesByProperty = property_id => {
  return {
    promise: client.get(`role/get-roles-by-property/${property_id}`),
    type: 'GET_ROLES_NAME_BY_PROPERTY',
  };
};

export const getRolesByUser = user_id => {
  return {
    promise: client.get(`role/get-roles-by-user/${user_id}`),
    type: 'GET_ROLES_NAME_BY_USER',
  };
};

export const getRolesLevel = data => {
  return {
    promise: client.get(`role-management/role-level`),
    type: 'GET_ROLES_LEVEL',
  };
};

export const getPropertys = data => {
  return {
    promise: client.get(`role-management/properties`),
    type: 'GET_PROPERTYS',
  };
};
