import React from 'react';

import PlaceholderRequestDetailsButton from '../../common/PlaceholderRequestDetailsButton';

import RevenueBoost from '../../common/images/color/revenue_boost.png';
import CostSavings from '../../common/images/color/cost_savings.png';
import Retention from '../../common/images/color/retention.png';
import SmartDevicesBW from '../../common/images/bw/smart_devices.png';
import SmartSensorsBW from '../../common/images/bw/smart_sensors.png';
import HomeAccessBW from '../../common/images/bw/home_access.png';
import SmartHomeBW from '../../common/images/bw/smart_home.png';

export default function UnitAutomationPlaceholder(props) {
  const learnMoreButton = <PlaceholderRequestDetailsButton feature="Smart Home" />;
  return (
    <div className="smart-placeholder__page smart-home">
      <h1>Smart Home</h1>
      <p className="smart-placeholder--main-p">
        Did you know that SmartExperience also offers smart home features that deliver comfort, convenience, and peace
        of mind to your residents?
      </p>
      <div className="main-points__wrapper">
        <div className="main-points">
          <div className="point--image__container">
            <img src={RevenueBoost} alt="Revenue Boost" />
          </div>
          <h4>Revenue boost</h4>
          <p className="points-description">
            Bring your property into the future with a powerful video intercom and access control solution.
          </p>
        </div>
        <div className="main-points">
          <div className="point--image__container">
            <img src={CostSavings} alt="Cost Savings" />
          </div>
          <h4>Cost savings</h4>
          <p className="points-description">
            Gives residents the ability to manage visitors and answer gate calls right from their resident mobile app.
          </p>
        </div>
        <div className="main-points">
          <div className="point--image__container">
            <img src={Retention} alt="Retention" />
          </div>
          <h4>Increased conversion &amp; retention</h4>
          <p className="points-description">
            Win and keep residents by delivering comfort, convenience, and peace of mind through smart technology.
          </p>
        </div>
      </div>
      <p className="smart-placeholder--main-p second">
        Deliver the modern smart home experience your residents are seeking with secure and effective smart devices and
        smart sensors.
      </p>
      {learnMoreButton}
      <h2>SmartExperience's Smart Home features include:</h2>
      <div className="secondary-points__wrapper">
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={SmartDevicesBW} alt="Smart Devices" />
          </div>
          <div>
            <h4>Smart Devices</h4>
            <p className="points-description">
              Give your residents the ability to control smart devices (such as a light, lock, or thermostat) directly
              from their phone.
            </p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={SmartSensorsBW} alt="Smart Sensors" />
          </div>
          <div>
            <h4>Smart Sensors</h4>
            <p className="points-description">
              Deliver peace of mind and help residents feel connected to their homes through motion detectors, door
              contacts, and other sensors.
            </p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={HomeAccessBW} alt="Home Access" />
          </div>
          <div>
            <h4>Home Access Control</h4>
            <p className="points-description">
              Increase security, save staff time, and reduce hardware costs with a keyless entry system for residents
              and their guests.
            </p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={SmartHomeBW} alt="SmartH ome" />
          </div>
          <div>
            <h4>Smart Home Controller</h4>
            <p className="points-description">
              Improve resident experience and reduce cybersecurity risk through a stable and secure smart home
              controller hub in each unit.
            </p>
          </div>
        </div>
      </div>
      {learnMoreButton}
    </div>
  );
}
