import { useFormik } from 'formik';
import { useValidationFormSchema } from './useValidationFormSchema';

const defaultValues = {
  units: [],
  tour_staff: [],
  profile: '',
  weekdays: [],
  start_time: null,
  end_time: null,
  tour_count: null,
  pre_message: '',
  pre_send_type: '',
  post_message: '',
  post_send_type: '',
  notified_staff: [],
};

export const useSGTourForm = (initialValues, onSubmit, onCancel) => {
  const validationSchema = useValidationFormSchema();
  const form = useFormik({ onSubmit, onCancel, initialValues: initialValues || defaultValues, validationSchema });
  return { form };
};
