export default {
  'Discussion Board': 'Доска обсуждений',
  Posts: 'Сообщений',
  Post: 'Сообщение',
  HEADLINE: 'ЗАГОЛОВОК',
  TYPE: 'ТИП',
  AUTHOR: 'АВТОР',
  'POSTED DATE': 'ДАТА ПУБЛИКАЦИИ',
  'Filter by type:': 'Фильтровать',
  All: 'Все',
  General: 'Общие',
  'For Sale': 'Для продажи',
  Event: 'Мероприятия',
  Recommendation: 'Рекомендации',
  'Recommendation Type': 'Тип рекомендации',
  'Download CSV': 'Скачать CSV',
  'Add Post': 'Добавить сообщение',
  Back: 'Назад',
  'SELECT POST TYPE': 'ВЫБЕРИТЕ ТИП СООБЩЕНИЙ',
  BODY: 'СООБЩЕНИЕ',
  LINK: 'ССЫЛКА',
  Price: 'Цена',
  'PRICE (IN DOLLARS)': 'ЦЕНА (В ДОЛЛАРАХ)',
  Start: 'Начало',
  End: 'Конец',
  'START DATE': 'ДАТА НАЧАЛА',
  'START TIME': 'ВРЕМЯ НАЧАЛА',
  'END DATE': 'ДАТА ОКОНЧАНИЯ',
  'END TIME': 'ВРЕМЯ ОКОНЧАНИЯ',
  LOCATION: 'МЕСТОПОЛОЖЕНИЕ',
  Request: 'Просьба',
  Offer: 'Предложение',
  'Upload Discussion Post Images': 'Загрузите изображение сообщения',
  'Upload an image or multiple to show off this Discussion Post':
    'Загрузите изображение или несколько изображений чтобы продемонстрировать это обсуждение',
  'Drop your files here, or click to browse': 'Перетащите сюда ваши файлы или нажмите, чтобы открыть проводник',
  'SUPPORTED FILES': 'ПОДДЕРЖИВАЕМЫЕ ФАЙЛЫ',
  'PNG or JPG | 1MB or less': 'PNG или JPG | 1 MB или меньше',
  Submit: 'Отправить',
  'Crop your uploaded image': 'Обрежьте ваше изображение',
  Confirm: 'Подтвердить',
  Cancel: 'Отменить',
  'Posted by': 'Опубликовано пользователем',
  Type: 'Тип',
  Link: 'Ссылка',
  Comments: 'Комментарии',
  'ADD A COMMENT': 'ДОБАВИТЬ КОММЕНТАРИЙ',
  'Add a comment': 'Добавить комментарий',
  'Comment text': 'Текст комментария',
  Comment: 'Комментировать',
  'MM/DD/YYYY, hh:mm:ss A': 'DD.MM.YYYY, H:mm:ss',
  'MMM D, YYYY [at] hh:mm A': 'DD.MM.YYYY [в] H:mm:ss',
  'MMM DD, YYYY': 'DD.MM.YYYY',
  'h:mm a': 'H:mm',
};
