import React, { Component } from 'react';
import SubHeader from '../../../../../../../../common/SubHeader';
import { formatPhoneNumber } from '../../../../../../../../common/utils/helpers';
import Avatar from '../../../../../../../../common/Avatar/index';
import Icon from '../../../../../../../../common/icons/icon';
import Kebab from '../../../../../../../../common/Kebab';
import Loading from '../../../../../../../../common/Loading/index';
import Delete from '../../../../../../../../common/Delete/index';

const EmptyPropertyGuestList = ({ onAddPropertyGuest }) => (
  <div className="zero-content">
    <button onClick={onAddPropertyGuest} className="button">
      Add Property Guest
    </button>
  </div>
);

class PropertyGuestsList extends Component {
  state = {
    openKebabGuestId: null,
    deleteConfirmationGuestId: null,
  };

  onKebabClick = guestId => this.setState({ openKebabGuestId: guestId });

  closeKebab = () => this.setState({ openKebabGuestId: null });

  onAddPropertyGuest = () => {
    this.props.history.push(`property-guests/guests/add`);
  };

  renderDeleteConfirmation = guestId => {
    const { removePropertyGuest, t } = this.props;
    return (
      <Delete
        onCancel={() => this.setState({ deleteConfirmationGuestId: null })}
        deleteFunc={() => removePropertyGuest(guestId)}
        message={<span dangerouslySetInnerHTML={{ __html: t('main.removeConfirmation') }} />}
      />
    );
  };

  renderIdentificationColumn = guest => {
    const fullName = `${guest.get('first_name')} ${guest.get('last_name')}`;

    return (
      <div className="pm-list__name-column">
        <div>
          <Avatar name={fullName} imageSource={guest.get('profile')} />
        </div>
        <div>
          <div className="pm-list__name-column__name"> {fullName} </div>
          <div className="pm-list__name-column__email">{guest.get('email')}</div>
        </div>
      </div>
    );
  };

  renderKebab = guest => {
    const { match, history, t } = this.props;
    const { openKebabGuestId } = this.state;
    const editUrl = `${match.url}/guests/${guest.get('id')}/edit`;

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(guest.get('id'))}
          onDisengage={this.closeKebab}
          menuOpened={guest.get('id') === openKebabGuestId}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={() => {
                history.push(editUrl);
                this.closeKebab();
              }}
            >
              <Icon icon="Edit" />
              {t('edit', { ns: 'buttons' })}
            </div>
            <div
              className="kebab__menu--item"
              onClick={() => {
                this.setState({ deleteConfirmationGuestId: guest.get('id') });
                this.closeKebab();
              }}
            >
              <Icon icon="Remove" />
              {t('remove', { ns: 'buttons' })}
            </div>
          </div>
        </Kebab>
      </div>
    );
  };

  render() {
    const { propertyGuests, propertyGuestsLoaded, t } = this.props;
    const { deleteConfirmationGuestId } = this.state;

    if (!propertyGuestsLoaded) {
      return <Loading />;
    }

    return (
      <div className="table-units-list__wrapper unit-table--container">
        <div className="container">
          <SubHeader
            title={t('main.title')}
            actions={
              propertyGuests.size === 0
                ? null
                : [
                    <button className="button" onClick={this.onAddPropertyGuest}>
                      {t('main.addButtonTitle')}
                    </button>,
                  ]
            }
          />
          {propertyGuests.size === 0 ? (
            <EmptyPropertyGuestList onAddPropertyGuest={this.onAddPropertyGuest} />
          ) : (
            <table className="table-units-list">
              <thead>
                <tr>
                  <th>{t('main.tableHeaders.name')}</th>
                  <th>{t('main.tableHeaders.phone')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {propertyGuests.map(guest => {
                  return (
                    <tr key={guest.get('id')}>
                      <td>{this.renderIdentificationColumn(guest)}</td>
                      <td>
                        <div>{formatPhoneNumber(guest.get('phone'))}</div>
                      </td>
                      <td className="table__align-right">
                        {deleteConfirmationGuestId === guest.get('id')
                          ? this.renderDeleteConfirmation(guest.get('id'))
                          : this.renderKebab(guest)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

PropertyGuestsList.propTypes = {};
export default PropertyGuestsList;
