import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import PageHeader from '../../../../../../common/PageHeader';
import AreaForm from '../../components/AreaForm';

class AddArea extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    property: PropTypes.instanceOf(Map),
  };

  handleSuccess = () => {
    this.props.history.push('./');
  };

  handleCancel = () => {
    this.props.history.push('./');
  };

  render() {
    const { addArea } = this.props;

    return (
      <div>
        <PageHeader title="Add Area" white backLink="./" />
        <div className="container">
          <AreaForm
            initialValues={{
              name: '',
            }}
            submitFunc={addArea}
            onCancel={this.handleCancel}
            onSuccess={this.handleSuccess}
          />
        </div>
      </div>
    );
  }
}

export default AddArea;
