import { useEffect, useState } from 'react';

export const useResidentAccessModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmAccess, setIsConfirmAccess] = useState(false);

  useEffect(() => {
    if (!isOpen && isConfirmAccess) {
      setIsConfirmAccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    handleCancel: () => setIsOpen(false),
    handleOpen: () => setIsOpen(true),
    handleConfirmAccess: type => setIsConfirmAccess(type),
    isConfirmAccess,
    isOpen,
  };
};
