import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import OfferForm from '../../common/forms/OfferForm';
import Loading from '../../common/Loading/index';
import { trimAndSetNullFalsyValues } from '../../common/utils/helpers';

export default class EditOffer extends Component {
  componentDidMount() {
    this.props.getProperties().then(() => {
      this.props.getOffer();
    });
  }
  render() {
    const { activeOffer, editOffer, history, loaded } = this.props;
    if (loaded) {
      return (
        <OfferForm
          initialValues={{
            company_name: activeOffer.company_name,
            teaser_copy: activeOffer.teaser_copy,
            details: activeOffer.details || '',
            offer_code: activeOffer.offer_code || '',
            website: activeOffer.website || '',
            phone: activeOffer.phone || '',
            address: activeOffer.address,
            first_name: activeOffer.first_name || '',
            last_name: activeOffer.last_name || '',
            email: activeOffer.email || '',
            contact_phone: activeOffer.contact_phone || '',
            properties: activeOffer.properties.map(property => property.id),
            start_date: new Date(moment.utc(activeOffer.start_date).format('MMM D, YYYY')),
            end_date: new Date(moment.utc(activeOffer.end_date).format('MMM D, YYYY')),
            image_id: activeOffer.image_id || null,
            image_size: 0,
          }}
          saveFunction={values => {
            const submitValues = values;
            if (!submitValues.image_id && !submitValues.image_data) {
              submitValues.image_id = null;
            }
            return editOffer(trimAndSetNullFalsyValues(values)).then(action => {
              if (action.response.ok) {
                history.push('/offers');
              }
            });
          }}
          imageUrl={activeOffer.image && activeOffer.image.url}
          {...this.props}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

EditOffer.propTypes = {
  editOffer: PropTypes.func.isRequired,
  getOffer: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
  propertyList: PropTypes.array.isRequired,
};
