import { connect } from 'react-redux';
import { selectorIsAdmin } from '../../../../../../../../redux/selectors';
import { createAmenities, getAmenities, removeAmenities, editAmenities } from '../../../../../../redux/actions';
import Amenities from './Amenities';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    amenitiesList: state.property.amenityList,
    amenityListLoaded: state.property.amenityListLoaded,
    isAdmin: selectorIsAdmin(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAmenities: propertyId => dispatch(getAmenities(propertyId)),
    createAmenities: (propertyId, payload) => dispatch(createAmenities(propertyId, payload)),
    removeAmenities: (propertyId, amenityId) => dispatch(removeAmenities(propertyId, amenityId)),
    editAmenities: (propertyId, amenityId, payload) => dispatch(editAmenities(propertyId, amenityId, payload)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getAmenities: () => dispatchProps.getAmenities(ownProps.match.params.propertyId),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Amenities);
