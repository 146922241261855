import Client, { jwtPlugin } from '@synapsestudios/fetch-client';
import { createPlugin } from '@synapsestudios/fetch-client-goalie';
import localstorage from 'store2';
import store from './store';
import config from './config';

const client = new Client({ url: config.apiUrl });

client.addPlugin(createPlugin('>=0.8.0 <2.0.0', store));
client.addPlugin(jwtPlugin);
client.setJwtTokenGetter(() => localstorage.get('idToken'));

const logout = () => {
  localstorage.clearAll();
  return (window.location.href = [
    config.auth.domain,
    `/user/logout?post_logout_redirect_uri=`,
    config.auth.postLogoutRedirectUri,
  ].join(''));
};

client.on('AUTH_FAILED', request => logout());

client.on('AUTH_EXPIRED', request => logout());

export default client;
