import { connect } from 'react-redux';
import AccessControl from './AccessControl';
import { getUserPermission } from '../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
    userPermissionList: getUserPermission(state),
  };
}

const AccessControlContainer = connect(mapStateToProps)(AccessControl);

export default AccessControlContainer;
