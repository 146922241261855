import { connect } from 'react-redux';
import PropertyDetails from './PropertyDetails';
import { getUserPermission } from '../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
    userPermissionList: getUserPermission(state),
  };
}

const PropertyDetailsContainer = connect(mapStateToProps)(PropertyDetails);

export default PropertyDetailsContainer;
