import { fromJS } from 'immutable';

export const communityViewsReducers = {
  communityViewsCameras: (state = null, action) => {
    switch (action.type) {
      case 'GET_COMMUNITY_VIEWS_CAMERAS_SUCCESS':
      case 'EDIT_COMMUNITY_VIEWS_CAMERA_SUCCESS':
      case 'ADD_COMMUNITY_VIEWS_CAMERA_SUCCESS':
        return action.json;
      case 'REMOVE_COMMUNITY_VIEWS_CAMERA_SUCCESS':
        return state?.filter(camera => camera.id !== action.json.id);
      case 'GET_COMMUNITY_VIEWS_CAMERAS_FAILURE':
        return null;
      case 'GET_COMMUNITY_VIEWS_CAMERAS_PENDING':
        return null;
      default:
        return state;
    }
  },

  communityViewsCurrentStreams: (state = null, action) => {
    switch (action.type) {
      case 'REQUEST_COMMUNITY_VIEWS_STREAM_SUCCESS':
        const { url } = action.json;
        return { ...state, [action.cameraId]: url };
      case 'REQUEST_COMMUNITY_VIEWS_STREAM_FAILURE':
        return null;
      default:
        return state;
    }
  },

  videoProcessingServerConfig: (state = null, action) => {
    switch (action.type) {
      case 'REQUEST_VIDEO_PROCESSING_SERVER_CONFIG_SUCCESS':
      case 'UPDATE_VIDEO_PROCESSING_SERVER_CONFIG_SUCCESS':
        return fromJS(action.json);
      case 'REQUEST_VIDEO_PROCESSING_SERVER_CONFIG_FAILURE':
        return null;
      default:
        return state;
    }
  },

  videoProcessingServerConfigLoading: (state = false, action) => {
    switch (action.type) {
      case 'REQUEST_VIDEO_PROCESSING_SERVER_CONFIG':
        return true;
      case 'UPDATE_VIDEO_PROCESSING_SERVER_CONFIG_SUCCESS':
      case 'UPDATE_VIDEO_PROCESSING_SERVER_CONFIG_FAILURE':
        return false;
      default:
        return state;
    }
  },

  communityViewsCamerasLoading: (state = false, action) => {
    switch (action.type) {
      case 'GET_COMMUNITY_VIEWS_CAMERAS':
        return true;
      case 'GET_COMMUNITY_VIEWS_CAMERAS_SUCCESS':
      case 'GET_COMMUNITY_VIEWS_CAMERAS_FAILURE':
        return false;
      default:
        return state;
    }
  },

  communityViewsArchiveDownload: (state = null, action) => {
    switch (action.type) {
      case 'REQUEST_ARCHIVED_DOWNLOAD_SUCCESS':
      case 'CHECK_ARCHIVED_DOWNLOAD_STATUS_SUCCESS':
        return { ...action.json };
      default:
        return state;
    }
  },

  communityViewsNotificationContacts: (state = [], action) => {
    switch (action.type) {
      case 'UPDATE_COMMUNITY_VIEWS_NOTIFICATION_CONTACTS_SUCCESS':
      case 'GET_COMMUNITY_VIEWS_NOTIFICATION_CONTACTS_SUCCESS':
        return [...action.json];
      default:
        return state;
    }
  },
};
