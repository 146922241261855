import React from 'react';

export const SGToursTip = () => (
  <div className="toursTip tours-ready">
    <section className="container">
      <div className="paper tours-ready_container text-center tours-ready_shadow tours-tip-container">
        <h1 className="h1 m-t40 strong toursTip-title">Want to set up Self-Guided Tours for your property?</h1>
        <h5 className="p h5 m-t25 toursTip-contact">
          Please contact a SmartExperience Representative
          <br />
          1-844-717-3777
        </h5>
        <h5 className="font-light h5 m-tb20 toursTip-bottom">
          Please navigate back to this page for tour configuration <br />
          once your property has been activated.
        </h5>
      </div>
    </section>
  </div>
);
