import React from 'react';
import ArrowLeft from '../../../../../../../common/icons/icon';

export const SGToursNoAutomated = ({ history }) => (
  <div>
    <button onClick={history.goBack} className="back-arrow back-arrow-link">
      <span>
        <ArrowLeft icon="ArrowLeft" />
      </span>
      Return to previous page
    </button>
    <h1>SGToursNoAutomated</h1>
  </div>
);
