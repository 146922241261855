import { connect } from 'react-redux';
import {
  getPanelDevices,
  getUnassignedVideoDoorbells,
  assignVideoDoorbellToUnit,
  unpairVideoDoorbellDevice,
} from '../../../../redux/actions';
import AddVideoDoorbell from './AddVideoDoorbell';

function mapStateToProps(state, props) {
  return {
    panel: state.devices.panelDevices,
    recognizedDevices: state.devices.panelDevices.get('devices'),
    panelDevicesLoading: state.devices.panelDevicesLoading,
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPanelDevices: () => dispatch(getPanelDevices(ownProps.match.params.propertyId, ownProps.match.params.panelId)),
    getUnassignedVideoDoorbells: () => dispatch(getUnassignedVideoDoorbells()),
    assignVideoDoorbellToUnit: payload => dispatch(assignVideoDoorbellToUnit(payload)),
    unpairVideoDoorbellDevice: deviceId => dispatch(unpairVideoDoorbellDevice(deviceId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoDoorbell);
