import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import Yup from 'yup';
import InputScaffold from '../../../../../../common/forms/InputScaffold';
import { propertySelector, communityViewsCamerasSelector } from '../../../../redux/selectors';
import { getCommunityViewsCameras } from '../../../../redux/actions/communityViewsActions';
const defaultValues = {
  enabled_people_counting: false,
  enabled_streaming: false,
  enabled_video_archiving: false,
  camera_name: '',
  incoming_stream_url: '',
};

export function CameraForm({ initialValues = {}, onSubmit, onCancel }) {
  const dispatch = useDispatch();
  const property = useSelector(propertySelector);
  const cameras = useSelector(communityViewsCamerasSelector);

  const validationSchema = Yup.object().shape({
    camera_name: Yup.string()
      .trim()
      .test(
        'camera-name-unique',
        'A camera with that name already exists',
        value => value === initialValues.camera_name || !cameras?.map(camera => camera.camera_name).includes(value)
      )
      .max(64, 'Name must be 64 characters or less')
      .required('Please enter a name'),
    incoming_stream_url: Yup.string().required('URL is required'),
    enabled_people_counting: Yup.boolean()
      .test(
        'oneOfRequired',
        'Either Enable Streaming, Enable Video Archiving, or Enable People Counting must be selected',
        function(item) {
          return (
            this.parent.enabled_streaming || this.parent.enabled_video_archiving || this.parent.enabled_people_counting
          );
        }
      )
      .default(false)
      .required(),
    enabled_video_archiving: Yup.boolean()
      .test(
        'oneOfRequired',
        'Either Enable Streaming, Enable Video Archiving, or Enable People Counting must be selected',
        function(item) {
          return (
            this.parent.enabled_streaming || this.parent.enabled_video_archiving || this.parent.enabled_people_counting
          );
        }
      )
      .default(false)
      .required(),
    enabled_streaming: Yup.boolean()
      .test(
        'oneOfRequired',
        'Either Enable Streaming, Enable Video Archiving, or Enable People Counting must be selected',
        function(item) {
          return (
            this.parent.enabled_streaming || this.parent.enabled_video_archiving || this.parent.enabled_people_counting
          );
        }
      )
      .default(false)
      .required(),
  });

  useEffect(() => {
    dispatch(getCommunityViewsCameras(property.get('id').toString()));
  }, [property, dispatch]);

  return (
    <div className="container camera-form">
      <Formik
        initialValues={{ ...defaultValues, ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          validateForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="paper radius-top-left radius-top-right">
              <h4 className="h4">Add Camera</h4>
              <div className="property-details--form">
                <InputScaffold label="Name" required validation={touched.camera_name && errors.camera_name}>
                  <input
                    type="text"
                    name="camera_name"
                    maxLength="64"
                    onChange={handleChange}
                    value={values.camera_name}
                  />
                </InputScaffold>

                <InputScaffold
                  label="URL (Local Stream For NVR Camera)"
                  required
                  validation={touched.incoming_stream_url && errors.incoming_stream_url}
                >
                  <input
                    type="text"
                    name="incoming_stream_url"
                    maxLength="64"
                    onChange={handleChange}
                    value={values.incoming_stream_url}
                  />
                </InputScaffold>

                <InputScaffold
                  validation={
                    errors.enabled_streaming || errors.enabled_video_archiving || errors.enabled_people_counting
                  }
                />
                <div className="checkbox-list">
                  <div className="checkbox-list--item">
                    <label>
                      <input
                        type="checkbox"
                        checked={values.enabled_people_counting}
                        onChange={handleChange}
                        name="enabled_people_counting"
                      />
                      Enable People Counting
                    </label>
                  </div>

                  <div className="checkbox-list--item">
                    <label>
                      <input
                        type="checkbox"
                        checked={values.enabled_video_archiving}
                        onChange={handleChange}
                        name="enabled_video_archiving"
                      />
                      Enable Video Archiving
                    </label>
                  </div>

                  <div className="checkbox-list--item">
                    <label>
                      <input
                        type="checkbox"
                        checked={values.enabled_streaming}
                        onChange={handleChange}
                        name="enabled_streaming"
                      />
                      Enable Streaming
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="paper__footer radius-bottom-left radius-bottom-right">
              <button type="submit" disabled={isSubmitting} className="button">
                Save Camera
              </button>
              <button type="button" className="button button--secondary" onClick={onCancel}>
                Cancel
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
}
