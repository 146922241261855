import React from 'react';
import { SGBtnBack, useFetchGuest } from '../../../common';
import Loading from '../../../../../../../../../common/Loading';
import { SGDetailHeader, SGDetailInfo, SGLinkToTour, SGDetailImages, SGAccessLog } from '../../../common';

export const SGPastDetails = () => {
  const { isLoad, guest } = useFetchGuest();

  if (isLoad) return <Loading />;

  const { access_log, first_name, last_name, imageUrl } = guest;

  return (
    <>
      <div className="m-t20 p-l20">
        <SGBtnBack btnTitle="Return to previous page" />
      </div>
      <div className="detail-page-content">
        <SGDetailHeader title="Past Tour" name={`${first_name} ${last_name}`} />
        <SGDetailInfo {...guest} />
        <SGDetailImages imageUrl={imageUrl} />
        <SGLinkToTour />
        {!!access_log.length && <SGAccessLog accessLog={access_log} />}
      </div>
    </>
  );
};
