import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';

import SubHeader from '../../../../../../common/SubHeader';
import Delete from '../../../../../../common/Delete';
import Loading from '../../../../../../common/Loading';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../common/Kebab/index';
import Icon from '../../../../../../common/icons/icon';
import UnrecognizedProfiles from './UnrecognizedProfiles';

class ProfilesList extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    accessControlProfiles: PropTypes.instanceOf(List),
    accessControlProfilesLoaded: PropTypes.bool.isRequired,
    getProfiles: PropTypes.func.isRequired,
    getPanelProfiles: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getProfiles();
  }

  state = {
    openKebabProfileId: null,
    deleteConfirmationProfileId: null,
    error: false,
  };

  onKebabClick = profileId => this.setState({ openKebabProfileId: profileId });

  closeKebab = () => this.setState({ openKebabProfileId: null });

  renderKebab(profile) {
    const { match, history, isAdmin } = this.props;
    const { openKebabProfileId } = this.state;

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(profile.get('id'))}
          onDisengage={this.closeKebab}
          menuOpened={profile.get('id') === openKebabProfileId}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={() => {
                history.push(`${match.url}/${profile.get('id')}`);
                this.closeKebab();
              }}
            >
              <Icon icon="Edit" />
              Edit
            </div>
            {isAdmin && (
              <div
                className="kebab__menu--item"
                onClick={() =>
                  this.setState({ deleteConfirmationProfileId: profile.get('id'), openKebabProfileId: null })
                }
              >
                <Icon icon="Remove" />
                Delete
              </div>
            )}
          </div>
        </Kebab>
      </div>
    );
  }

  renderDeleteConfirmation = profile => {
    const { removeProfile } = this.props;
    return (
      <div>
        <Delete
          onCancel={() => this.setState({ deleteConfirmationProfileId: null, openKebabProfileId: null })}
          deleteFunc={async () => {
            const action = await removeProfile(profile);
            if (!action.response.ok) {
              this.setState({
                error: (action.json && action.json.message) || 'Unknown error occurred while deleting profile',
                deleteConfirmationProfileId: null,
                openKebabProfileId: null,
              });
            }
          }}
          message={
            <span>
              Are you sure you want to <strong className="strong">remove this profile</strong>?
            </span>
          }
        />
      </div>
    );
  };

  renderUserProfileCheckboxes = (label, field) => {
    const { accessControlProfiles, editProfile } = this.props;

    const profileOptions = accessControlProfiles.toJS().map(profile => ({
      value: profile.id,
      label: profile.name,
    }));
    return (
      <div className="user-profile-checkbox--container">
        <h5 className="h5">{label}</h5>
        {profileOptions.map(option => {
          const isChecked = !!accessControlProfiles.find(
            profile => profile.get('id') === option.value && profile.get(field) === true
          );

          const enabledProfiles = accessControlProfiles.filter(profile => profile.get(field) === true);

          return (
            <div className="checkbox-list--item" key={option.value}>
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => {
                    editProfile(option.value, {
                      [field]: !isChecked,
                    });
                  }}
                  name={option.value}
                  disabled={!isChecked && enabledProfiles.size >= 4}
                />
                <span htmlFor={option.value} className="label">
                  {option.label}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      match,
      accessControlProfiles,
      accessControlProfilesLoaded,
      getPanelProfiles,
      getProfiles,
      isAdmin,
    } = this.props;
    const { error, deleteConfirmationProfileId } = this.state;

    if (!accessControlProfilesLoaded) {
      return <Loading />;
    }

    return (
      <div className="profiles-list--page">
        <div className="container">
          <SubHeader
            title="Access Profiles"
            size={24}
            actions={[
              <button
                type="button"
                className="button button--outline"
                onClick={() => {
                  getProfiles();
                  isAdmin && getPanelProfiles();
                }}
              >
                Fetch Profiles
              </button>,
              isAdmin && (
                <Link to={`${match.url}/add-profile`} className="button">
                  Add New Profile
                </Link>
              ),
            ]}
          />

          {error && <div className="input-validation">{error}</div>}

          <div className="recog--container">
            <div className="recog__recognized">
              <h4 className="h4">Recognized</h4>
              {accessControlProfiles.size > 0 ? (
                <table className="table-units-list">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {accessControlProfiles.map(profile => (
                      <tr key={profile.get('id')}>
                        <td>{profile.get('name')}</td>
                        {
                          <td className="table__align-right">
                            {deleteConfirmationProfileId === profile.get('id')
                              ? this.renderDeleteConfirmation(profile)
                              : this.renderKebab(profile)}
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <EmptyPlaceholder buttonText="Add Profile" linkTo={`${match.url}/add-profile`} />
              )}
            </div>

            {isAdmin && (
              <div className="recog__unrecognized">
                <h4 className="h4">Unrecognized</h4>
                <UnrecognizedProfiles />
              </div>
            )}

            <div className="master-panel__user-types">
              <h4 className="h4">Default Access Profiles</h4>
              <div className="columns--container">
                {this.renderUserProfileCheckboxes('Staff', 'is_staff_default')}
                {this.renderUserProfileCheckboxes('Property Guests', 'is_property_guest_default')}
                {this.renderUserProfileCheckboxes('Residents', 'is_resident_default')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilesList;
