import { connect } from 'react-redux';

import AddProfile from './AddProfile';
import { addProfile, getPropertyAreas, getPropertySchedules } from '../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlAreas: state.property.accessControlAreas,
    accessControlAreasLoaded: state.property.accessControlAreasLoaded,
    accessControlSchedules: state.property.accessControlSchedules,
    accessControlSchedulesLoaded: state.property.accessControlSchedulesLoaded,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addProfile: payload => dispatch(addProfile(ownProps.match.params.propertyId, payload)),
    getPropertyAreas: () => dispatch(getPropertyAreas(ownProps.match.params.propertyId)),
    getPropertySchedules: () => dispatch(getPropertySchedules(ownProps.match.params.propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProfile);
