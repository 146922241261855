import client from '../../../../common/client';

export const getCommunityViewsCameras = communityId => {
  return {
    promise: client.get(`properties/${communityId}/cameras`),
    type: 'GET_COMMUNITY_VIEWS_CAMERAS',
  };
};

export const addCommunityViewsCamera = (cameraData, communityId) => {
  return {
    promise: client.post(`properties/${communityId}/cameras`, cameraData),
    type: 'ADD_COMMUNITY_VIEWS_CAMERA',
  };
};

export const editCommunityViewsCamera = (cameraData, communityId) => {
  return {
    promise: client.put(`properties/${communityId}/cameras`, cameraData),
    type: 'EDIT_COMMUNITY_VIEWS_CAMERA',
  };
};

export const removeCommunityViewsCamera = (cameraId, communityId) => {
  return {
    promise: client.delete(`properties/${communityId}/cameras/${cameraId}`),
    type: 'REMOVE_COMMUNITY_VIEWS_CAMERA',
    cameraId,
  };
};

export const getCommunityViewsCameraInfo = (cameraId, communityId) => {
  return {
    promise: client.get(`properties/${communityId}/cameras/${cameraId}`),
    type: 'GET_COMMUNITY_VIEWS_CAMERA',
    cameraId,
  };
};

export const requestCommunityViewsStream = (cameraId, communityId) => {
  return {
    promise: client.get(`properties/${communityId}/cameras/${cameraId}/stream`),
    type: 'REQUEST_COMMUNITY_VIEWS_STREAM',
    cameraId,
  };
};

export const requestVideoProcessingServerConfig = communityId => {
  return {
    promise: client.get(`properties/${communityId}/community-views/config`),
    type: 'REQUEST_VIDEO_PROCESSING_SERVER_CONFIG',
  };
};

export const updateVideoProcessingServerConfig = (newConfig, communityId) => {
  return {
    promise: client.put(`properties/${communityId}/community-views/config`, newConfig),
    type: 'UPDATE_VIDEO_PROCESSING_SERVER_CONFIG',
  };
};

export const requestArchivedDownload = (downloadRequest, communityId) => {
  return {
    promise: client.post(`properties/${communityId}/community-views/download/request`, downloadRequest),
    type: 'REQUEST_ARCHIVED_DOWNLOAD',
  };
};

export const checkArchivedDownloadStatus = (statusRequest, communityId) => {
  return {
    promise: client.get(
      `properties/${communityId}/cameras/${statusRequest.camera_id}/download/${statusRequest.download_id}`
    ),
    type: 'CHECK_ARCHIVED_DOWNLOAD_STATUS',
  };
};

export const getCommunityViewsNotificationContacts = communityId => {
  return {
    promise: client.get(`properties/${communityId}/community-views/notification-contacts`),
    type: 'GET_COMMUNITY_VIEWS_NOTIFICATION_CONTACTS',
  };
};

export const updateCommunityViewsNotificationContacts = (notificationContacts, communityId) => {
  return {
    promise: client.put(`properties/${communityId}/community-views/notification-contacts`, notificationContacts),
    type: 'UPDATE_COMMUNITY_VIEWS_NOTIFICATION_CONTACTS',
  };
};
