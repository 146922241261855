import React, { Component } from 'react';
import * as _find from 'lodash/find';
import * as _findIndex from 'lodash/findIndex';
import Icon from '../../common/icons/icon';

export default class MultiSelect extends Component {
  state = { addedArr: [], selectedArr: [] };

  handleClick = (index, item) => {
    if (!_find(this.state.selectedArr, ['id', item.id])) {
      this.setState(state => {
        return { selectedArr: [...state.selectedArr, { id: item.id, index: index }] };
      });
    } else {
      const newArr = [...this.state.selectedArr];
      newArr.splice(_findIndex(this.state.selectedArr, ['id', item.id]), 1);
      this.setState(() => {
        return { selectedArr: newArr };
      });
    }
  };
  handleAdd = () => {
    const tempArr = [];
    this.state.selectedArr.map(selected => {
      return tempArr.push(this.props.firstList[selected.index].id);
    });
    this.setState({ selectedArr: [] });
    this.props.onChange(this.props.addedArr.concat(tempArr));
  };
  removeFromList = index => {
    const newArr = [...this.props.addedArr];
    newArr.splice(index, 1);
    this.props.onChange(newArr);
  };

  render() {
    const { firstList, buttonText = 'Add', addedArr } = this.props;

    return (
      <div className="multiselect">
        <div className="multiselect-box">
          <div className="label">Available Properties</div>
          <ul>
            {firstList.map((e, index) => {
              if (this.props.addedArr.indexOf(e.id) < 0) {
                return (
                  <li
                    className={_find(this.state.selectedArr, ['id', e.id]) ? 'selected' : ''}
                    onClick={this.handleClick.bind(this, index, e)}
                    key={index}
                  >
                    {e.public_name}
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>

        <div className="multiselect-button">
          <button type="button" onClick={this.handleAdd} className="button">
            {buttonText} <Icon icon="ArrowLeft" />
          </button>
        </div>

        <div className="multiselect-box">
          <div className="label">Selected Properties</div>
          <ul>
            {addedArr.length ? (
              addedArr.map((e, index) => {
                return (
                  <li key={index}>
                    {_find(firstList, ['id', e]).public_name}
                    <button type="button" onClick={this.removeFromList.bind(this, index)} className="clear-button">
                      <Icon icon="CircleX" />
                    </button>
                  </li>
                );
              })
            ) : (
              <li>
                No properties selected. Select one of the Available Properties then click the Add button to add one.
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}
