import userMenu from './common/userMenu';
import buttons from './common/buttons';
import time from './common/time';
import errors from './common/errors';
import navigation from './common/navigation';
import events from './community/events';
import discussions from './community/discussions';
import amenities from './community/amenities';
import announcements from './community/announcements';
import communitySettings from './settings/communitySettings';
import pendingDiscussions from './community/pendingDiscussions';
import units from './unitsAndResidents/units';
import residents from './unitsAndResidents/residents';
import residentMessages from './residentMessages/residentMessages';
import residentGuests from './accessControl/residentGuests';
import propertyGuests from './accessControl/propertyGuests';
import propertyManagers from './propertyDetails/propertyManagers';
import residentFeatures from './propertyDetails/residentFeatures';
import unitGroups from './propertyDetails/unitGroups';

export default {
  userMenu,
  buttons,
  time,
  errors,
  navigation,
  events,
  discussions,
  amenities,
  announcements,
  communitySettings,
  pendingDiscussions,
  units,
  residents,
  residentMessages,
  residentGuests,
  propertyGuests,
  propertyManagers,
  residentFeatures,
  unitGroups,
};
