import { useState } from 'react';

export const useResidentApproveModal = () => {
  const [isApproveOpen, setIsApproveOpen] = useState(false);

  return {
    isApproveOpen,
    handleApproveCancel: () => setIsApproveOpen(false),
    handleApproveOpen: () => setIsApproveOpen(true),
  };
};
