import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Yup from 'yup';
import { Formik } from 'formik';
import Select from 'react-select';

import InputScaffold from './InputScaffold';
import { trimAndSetNullFalsyValues, isValidUUID } from '../utils/helpers';
import Switch from './Switch';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(70, 'Name must be 70 characters or less')
    .required('Please enter a name'),
  id: Yup.string()
    .trim()
    .required('Please enter a UUID')
    .test('is-uuid', 'Must be a valid UUID', value => isValidUUID(value)),
});

class TelephoneEntryDeviceForm extends Component {
  state = { submitError: null };

  setSubmitError = err => this.setState({ submitError: err });

  render() {
    const { initialValues, onSubmitClick, onCancelClick, propertyOptions, disableIdField } = this.props;
    const { submitError } = this.state;

    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            return onSubmitClick(trimAndSetNullFalsyValues(values)).catch(err => {
              actions.setSubmitting(false);
              return this.setSubmitError(err);
            });
          }}
          render={({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, touched }) => (
            <form onSubmit={handleSubmit}>
              {submitError && (
                <div className="form-error__wrapper">
                  <span className="form-error--label">Error:&nbsp;</span>
                  {submitError}
                </div>
              )}
              <div className="paper radius-top-left radius-top-right">
                <div className="property-details--form">
                  <InputScaffold label="Name" required validation={touched.name && errors.name}>
                    <input type="text" name="name" maxLength="64" onChange={handleChange} value={values.name} />
                  </InputScaffold>
                  <InputScaffold label="UUID" required validation={touched.id && errors.id}>
                    <input
                      type="text"
                      id="id"
                      disabled={disableIdField}
                      onChange={handleChange}
                      value={values.id}
                      maxLength="36"
                    />
                  </InputScaffold>
                  <div className="react-select-scaffold">
                    <div className="label">Property</div>
                    <Select
                      closeOnSelect={true}
                      onChange={value => setFieldValue('property_id', value)}
                      options={propertyOptions}
                      placeholder="Select a Property"
                      value={values.property_id}
                      simpleValue={true}
                      clearable={false}
                    />
                  </div>
                  <Switch
                    label={'Enable Resident Directory'}
                    input={{
                      value: values.enable_resident_directory,
                      name: 'enable_resident_directory',
                      onChange: selectedValue => setFieldValue('enable_resident_directory', selectedValue),
                    }}
                    disabled={false}
                  />
                  <Switch
                    label={'Enable Numeric Codes'}
                    input={{
                      value: values.enable_numeric_codes,
                      name: 'enable_numeric_codes',
                      onChange: selectedValue => setFieldValue('enable_numeric_codes', selectedValue),
                    }}
                    disabled={false}
                  />
                  <Switch
                    label={'Enable QR Codes'}
                    input={{
                      value: values.enable_qr_codes,
                      name: 'enable_qr_codes',
                      onChange: selectedValue => setFieldValue('enable_qr_codes', selectedValue),
                    }}
                    disabled={false}
                  />
                  <Switch
                    label={'Enable Call a Manager'}
                    input={{
                      value: values.enable_call_manager,
                      name: 'enable_call_manager',
                      onChange: selectedValue => setFieldValue('enable_call_manager', selectedValue),
                    }}
                    disabled={false}
                  />
                  <Switch
                    label={'Enable Video Calls'}
                    input={{
                      value: values.enable_video_calls,
                      name: 'enable_video_calls',
                      onChange: selectedValue => setFieldValue('enable_video_calls', selectedValue),
                    }}
                    disabled={false}
                  />
                </div>
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <div>
                  <button disabled={isSubmitting} className="button">
                    Save
                  </button>
                  <button type="button" className="button button--secondary" onClick={onCancelClick}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

TelephoneEntryDeviceForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default TelephoneEntryDeviceForm;
