import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editTour, deleteTour } from '../../../../../../../redux/actions/sgToursActions';

export const useSGFormAutomatedEdit = (propertyId, history) => {
  const [submitSettings, setSubmitSettings] = useState({ message: '', type: '' });
  const [isAlertCancel, setIsAlertCancel] = useState(false);
  const [isTourChanged, setIsTourChanged] = useState(false);
  const [tourChangeModalSubtitle, setTourChangeModalSubtitle] = useState(null);
  const [tourChangedValues, setTourChangedValues] = useState(null);

  const dispatch = useDispatch();

  const tour = useSelector(state => state.property.tour).toJS();

  const onSubmit = async values => {
    const { isUnitChanged, isTimeDateDaysChanged } = tourChangeHandler(values);
    if (isTimeDateDaysChanged || isUnitChanged) {
      setTourChangedValues(values);
      setTourChangeModalSubtitle({ isTimeDateDaysChanged, isUnitChanged });
      setIsTourChanged(true);
    } else confirmSubmit(values);
  };

  const tourChangeHandler = values => {
    const newUnits = values.units;
    const oldUnits = tour.units;
    const newDays = values.weekdays;
    const oldDays = tour.weekdays;
    const isUnitChanged = newUnits.length !== oldUnits.length || !newUnits.every(unit => oldUnits.includes(unit));
    const isTimeDateDaysChanged =
      newDays.length !== oldDays.length ||
      !newDays.every(unit => oldDays.includes(unit)) ||
      values.start_time !== tour.start_time ||
      values.end_time !== tour.end_time ||
      values.tour_count.number !== tour.time_count ||
      values.tour_count.type !== tour.time_type;

    return { isUnitChanged, isTimeDateDaysChanged };
  };

  const confirmSubmit = async values => {
    const { tour_count, ...dataValues } = tourChangedValues || values;

    const result = await dispatch(
      editTour(propertyId, tour.id, {
        ...dataValues,
        time_count: Number(tour_count.number),
        time_type: tour_count.type,
      })
    );

    if (!result.response || !result.response.ok) {
      setSubmitSettings({
        type: 'Failure',
        message: 'Server error',
      });
    } else {
      setSubmitSettings({ message: 'Succesfully', type: 'Success' });
    }

    setTourChangedValues(null);
    closeTourChangedModal();
  };

  const closeTourChangedModal = () => {
    setIsTourChanged(false);
  };

  const onDelete = async () => {
    const result = await dispatch(deleteTour(propertyId, tour.id));

    if (result.json) {
      history.push(`/properties/${propertyId}/units`);
    }
  };

  const onCancel = () => history.go(0);

  return {
    onCancel,
    onDelete,
    onSubmit,
    confirmSubmit,
    closeTourChangedModal,
    tour,
    submitSettings,
    isAlertCancel,
    isTourChanged,
    tourChangeModalSubtitle,
    setIsAlertCancel,
  };
};
