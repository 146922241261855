import { createSelector } from 'reselect';

const groupsSelector = state => state.RolesAndPermissions.groups;
export const selectGroupOptions = createSelector(groupsSelector, groups => {
  return groups
    .map(group => {
      return { value: group.get('id').toString(), label: group.get('name') };
    })
    .toJS();
});

const roleSelector = state => state.RolesAndPermissions.rolesList;
export const selectRoleOptions = createSelector(roleSelector, roles => {
  return roles
    .map(role => {
      return { value: role.get('id').toString(), label: role.get('role_name') };
    })
    .insert(0, { value: '', label: 'Unassigned' })
    .toJS();
});

export const selectRoleOptions3 = createSelector(roleSelector, roles => {
  return (
    roles
      .map(role => {
        return { value: role.get('id').toString(), label: role.get('role_name') };
      })
      // .insert(0, { value: '', label: 'Unassigned' })
      .toJS()
  );
});

const roleSelector2 = state => state.RolesAndPermissions.roleSelect;
export const selectRoleOptions2 = createSelector(roleSelector2, roles => {
  return roles
    .map(role => {
      return { value: role.get('id').toString(), label: role.get('role_name') };
    })
    .insert(0, { value: '', label: 'Unassigned' })
    .toJS();
});

const roleLevelSelect = state => state.RolesAndPermissions.roleLevels;
export const selectRolesOptions = createSelector(roleLevelSelect, roles => {
  return (
    roles
      .map(role => {
        return { value: role.get('role'), label: role.get('role') };
      })
      // .insert(0, { value: '', label: 'Unassigned' })
      .toJS()
  );
});

const propertySelect = state => state.RolesAndPermissions.propertys;
export const selectPropertyOptions = createSelector(propertySelect, propertys => {
  return (
    propertys
      .map(property => {
        return { value: property.get('id'), label: property.get('name') };
      })
      // .insert(0, { value: '', label: 'Unassigned' })
      .toJS()
  );
});
