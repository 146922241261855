import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import DropNCrop from '@synapsestudios/react-drop-n-crop';
import Yup from 'yup';
import Geosuggest from 'react-geosuggest';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MaskedInput from 'react-input-mask';
import { Link, Prompt } from 'react-router-dom';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';

import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css';
import 'react-day-picker/lib/style.css';

import Icon from '../../common/icons/icon';

import MultiSelect from './MultiSelect';
import InputScaffold from './InputScaffold';
import { getPhoneMask, normalizeUrl } from '../utils/helpers';

const maxImageBytes = 1000000;
const imageSizeRestriction = {
  width: 1500,
  height: 1000,
};

const validationSchema = Yup.object().shape({
  company_name: Yup.string()
    .trim()
    .max(64, 'Business Name must be 64 characters or less.')
    .required('Please enter a Business Name'),
  teaser_copy: Yup.string()
    .trim()
    .max(80, 'Teaser Copy must be 80 characters or less.')
    .required('Please enter Teaser Copy'),
  details: Yup.string()
    .trim()
    .max(1200, 'Details must be 1200 characters or less'),
  offerCode: Yup.string()
    .trim()
    .max(64, 'Offer Code must be 64 characters or less'),
  website: Yup.string()
    .trim()
    .transform(value => {
      return normalizeUrl(value);
    })
    .url('Must be a valid URL')
    .max(255, 'Website must be 255 characters or less'),
  phone: Yup.string().matches(/^[1]\d{10}|[0,2-9]\d{9}$/, 'Must be a valid phone number'),
  address: Yup.string()
    .trim()
    .max(200, 'Address must be less than 200 characters')
    .required('Address is required'),
  first_name: Yup.string()
    .trim()
    .max(64, 'First Name must be 64 characters or less'),
  last_name: Yup.string()
    .trim()
    .max(64, 'Last Name must be 64 characters or less'),
  email: Yup.string()
    .trim()
    .email('Email must be a valid email')
    .max(64, 'Email must be 64 characters or less'),
  contact_phone: Yup.string().matches(/^[1]\d{10}|[0,2-9]\d{9}$/, 'Must be a valid phone number'),
  start_date: Yup.date()
    .test('is-in-future', 'Date must be today or later', value => {
      return moment(value).isSameOrAfter(moment().startOf('day'));
    })
    .typeError('Invalid date format')
    .required('Invalid date format'),
  end_date: Yup.date()
    .typeError('Invalid date format')
    .min(Yup.ref('start_date'), 'End date cannot be before start date')
    .required('Invalid date format'),
  image_size: Yup.number().max(1, obj => {
    const value = obj.value;
    return `Must be less than 1MB. Your image is currently ${value}MB`;
  }),
});

const InstructionsComponent = () => (
  <div>
    <p>Drag an image here to upload or select a file from your device</p>
  </div>
);

class OfferForm extends Component {
  state = {
    imageSrc: '',
    blockTransition: false,
    imageData: {
      result: null,
      filename: null,
      filetype: null,
      src: '',
      error: null,
    },
  };

  componentDidMount() {
    if (this.props.imageUrl) {
      this.setState({ imageSrc: this.props.imageUrl });
    }
  }
  onSuggestSelect = (values, validateForm, suggest) => {
    if (suggest && suggest.gmaps) {
      values.address = suggest.gmaps.formatted_address;
    } else {
      values.address = '';
    }
    validateForm();
    if (suggest && suggest.location) {
      values.lat = suggest.location.lat;
      values.lng = suggest.location.lng;
    }
  };
  setTransitionBlocked = dirty => {
    if (dirty) {
      this.setState({ blockTransition: true });
    }
  };
  removeImage = setFieldValue => {
    setFieldValue('image_data', '');
    setFieldValue('image_id', '');
    setFieldValue('image_size', '');
    this.setState({
      imageSrc: '',
      imageData: {
        result: null,
        filename: null,
        filetype: null,
        src: '',
        error: null,
      },
    });
  };
  addProperties = (setFieldvalue, val) => {
    setFieldvalue('properties', val);
  };
  handleFromChange = (setFieldValue, from) => {
    setFieldValue('start_date', from);
  };
  handleToChange = (setFieldValue, to) => {
    this.setState({ to });
    setFieldValue('end_date', to);
  };

  stringToByte = str => {
    let size = new Blob([str]).size;
    return size;
  };

  checkImageSize = image => {
    var stringLength = image.result;
    let imageMb = this.stringToByte(stringLength) / 1000000;
    imageMb = Math.round(imageMb * 100) / 100;
    return imageMb;
  };

  onUpdateImage = value => {
    this.setState({ imageData: value });
  };
  render() {
    const { saveFunction, initialValues } = this.props;
    const { imageSrc, imageData, blockTransition } = this.state;
    return (
      <div>
        <Prompt
          when={blockTransition}
          message={location => `You have unsaved changes, would you like to discard them?`}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            this.setState({ blockTransition: false });
            if (imageData.src) {
              values.image_data = imageData.result;
            }
            if (values.properties) {
              values.properties = values.properties.map(val => {
                return { id: val };
              });
            }
            if (values.website) {
              const re = new RegExp('^(http|https)://', 'i');
              values.website = re.test(values.website) ? values.website : 'http://' + values.website;
            }
            return saveFunction(values);
          }}
          render={({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            validateForm,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            status,
            touched,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="admin-page-header">
                <div className="container">
                  <Link to={'/offers'} className="back-arrow">
                    <Icon icon="ArrowLeft" />
                    Back
                  </Link>
                  <div className="add-offer-header">
                    <div className="add-offer-header__title">
                      <h1 className="h1">{initialValues.company_name ? initialValues.company_name : 'New Offer'}</h1>
                    </div>

                    <div className="add-offer-header__dates">
                      <InputScaffold label="Start Date" required validation={touched.start_date && errors.start_date}>
                        <DayPickerInput
                          inputProps={{
                            autoComplete: 'false',
                          }}
                          name="test"
                          value={values.start_date ? values.start_date : ''}
                          placeholder="From"
                          format="LL"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          dayPickerProps={{
                            selectedDays: [values.start_date, { from: values.start_date, to: values.end_date }],
                            disabledDays: { after: values.end_date },
                            toMonth: values.end_date,
                            modifiers: { start: values.start_date, end: values.end_date },
                            numberOfMonths: 1,
                          }}
                          onBlur={this.setTransitionBlocked.bind(this, dirty)}
                          onDayChange={this.handleFromChange.bind(this, setFieldValue)}
                        />
                      </InputScaffold>

                      <span className="InputFromTo-to">
                        <InputScaffold label="End Date" required validation={touched.start_date && errors.end_date}>
                          <DayPickerInput
                            inputProps={{
                              autoComplete: 'false',
                            }}
                            value={values.end_date ? values.end_date : ''}
                            placeholder="To"
                            format="LL"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              selectedDays: [values.start_date, { from: values.start_date, to: values.end_date }],
                              disabledDays: { before: values.start_date },
                              modifiers: { start: values.start_date, end: values.end_date },
                              month: values.start_date ? values.start_date : null,
                              fromMonth: values.start_date ? values.start_date : null,
                              numberOfMonths: 1,
                            }}
                            onBlur={this.setTransitionBlocked.bind(this, dirty)}
                            onDayChange={this.handleToChange.bind(this, setFieldValue)}
                          />
                        </InputScaffold>
                      </span>
                      <button disabled={isSubmitting} type="submit" className="button">
                        Save Offer
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="offer-details">
                  <div className="offer-details__left offer-details-box">
                    <div className="offer-details-box__header">Offer Details</div>

                    <div className="offer-details-box__content">
                      <InputScaffold
                        label="Business Name"
                        required
                        validation={touched.company_name && errors.company_name}
                      >
                        <input
                          onBlur={this.setTransitionBlocked.bind(this, dirty)}
                          type="text"
                          name="company_name"
                          maxLength="64"
                          onChange={handleChange}
                          value={values.company_name || ''}
                        />
                      </InputScaffold>
                      <InputScaffold
                        label="Teaser Copy"
                        required
                        validation={touched.teaser_copy && errors.teaser_copy}
                      >
                        <textarea
                          onBlur={this.setTransitionBlocked.bind(this, dirty)}
                          rows="3"
                          name="teaser_copy"
                          maxLength="80"
                          onChange={handleChange}
                          value={values.teaser_copy || ''}
                        />
                      </InputScaffold>
                      <InputScaffold label="Details" validation={touched.details && errors.details}>
                        <textarea
                          rows="3"
                          name="details"
                          maxLength="1200"
                          onChange={handleChange}
                          value={values.details || ''}
                        />
                      </InputScaffold>
                      <InputScaffold label="Offer Code" validation={touched.offer_code && errors.offer_code}>
                        <input
                          onBlur={this.setTransitionBlocked.bind(this, dirty)}
                          type="text"
                          name="offer_code"
                          maxLength="64"
                          onChange={({ target }) => {
                            setFieldValue('offer_code', target.value.replace(/[^a-z0-9]/gi, '').toUpperCase());
                          }}
                          value={values.offer_code || ''}
                        />
                      </InputScaffold>
                      <InputScaffold label="Website" validation={touched.website && errors.website}>
                        <input
                          type="text"
                          name="website"
                          maxLength="2083"
                          onChange={handleChange}
                          onBlur={() => setFieldTouched('website', true)}
                          value={values.website || ''}
                        />
                      </InputScaffold>
                      <InputScaffold label="Phone" validation={touched.phone && errors.phone}>
                        <MaskedInput
                          mask={getPhoneMask(values.phone)}
                          name="phone"
                          onChange={event => {
                            setFieldValue('phone', event.target.value.replace(/[^0-9]+/g, ''));
                          }}
                          onBlur={handleBlur}
                          value={values.phone}
                        />
                      </InputScaffold>
                      <InputScaffold label="Address" validation={touched.address && errors.address} required>
                        <Geosuggest
                          autoComplete={Math.random()
                            .toString(36)
                            .substring(3, 9)}
                          onBlur={this.setTransitionBlocked.bind(this, dirty)}
                          onSuggestSelect={this.onSuggestSelect.bind(this, values, validateForm)}
                          initialValue={values.address}
                        />
                      </InputScaffold>
                    </div>
                  </div>

                  <div className="offer-details__right">
                    <div className="offer-details-box">
                      <div className="offer-details-box__header">Featured Image</div>

                      <div className="offer-details-box__content" style={{ marginTop: '24px' }}>
                        {imageSrc ? (
                          <div className="image--uploaded__wrapper">
                            <img className="offer-featured-img" alt="Featured Offer" src={imageSrc} />
                          </div>
                        ) : (
                          <div className="image-uploader">
                            <div className="label">Image Upload</div>
                            <DropNCrop
                              onChange={value => {
                                setFieldValue('image_size', this.checkImageSize(value));
                                this.onUpdateImage(value);
                              }}
                              canvasHeight="300px"
                              canvasWidth="450px"
                              value={imageData}
                              maxFileSize={maxImageBytes}
                              instructions={<InstructionsComponent />}
                              cropperOptions={{
                                aspectRatio: imageSizeRestriction.width / imageSizeRestriction.height,
                                guides: false,
                                rotatable: false,
                                scalable: false,
                                minContainerheight: 300,
                              }}
                            >
                              {' '}
                              <p className="image-validation">
                                {status && status.imageValidation ? status.imageValidation : ''}
                              </p>
                              <Icon icon="Image" />
                              <p>Drag an image here to upload or select a file from your device</p>
                            </DropNCrop>
                          </div>
                        )}
                        <InputScaffold validation={errors.image_size}>
                          <input type="hidden" name="image_size" onChange={handleChange} />
                        </InputScaffold>
                        {(imageSrc || imageData.src) && (
                          <p>
                            <button
                              className="button"
                              type="button"
                              onClick={this.removeImage.bind(this, setFieldValue)}
                            >
                              Remove Image
                            </button>
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="offer-details-box">
                      <div className="offer-details-box__header">Contact Details</div>

                      <div className="offer-details-box__content">
                        <InputScaffold label="First Name" validation={touched.first_name && errors.first_name}>
                          <input
                            type="text"
                            name="first_name"
                            maxLength="64"
                            onChange={handleChange}
                            value={values.first_name || ''}
                          />
                        </InputScaffold>
                        <InputScaffold label="Last Name" validation={touched.last_name && errors.last_name}>
                          <input
                            type="text"
                            name="last_name"
                            maxLength="64"
                            onChange={handleChange}
                            value={values.last_name || ''}
                          />
                        </InputScaffold>
                        <InputScaffold label="Email" validation={touched.email && errors.email}>
                          <input
                            type="text"
                            name="email"
                            maxLength="64"
                            onChange={handleChange}
                            value={values.email || ''}
                          />
                        </InputScaffold>
                        <InputScaffold label="Phone" validation={touched.contact_phone && errors.contact_phone}>
                          <MaskedInput
                            mask={getPhoneMask(values.contact_phone)}
                            name="phone"
                            onChange={event => {
                              setFieldValue('contact_phone', event.target.value.replace(/[^0-9]+/g, ''));
                            }}
                            onBlur={handleBlur}
                            value={values.contact_phone}
                          />
                        </InputScaffold>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="offer-properties offer-details-box">
                  <div className="offer-details-box__header">Select Properties</div>
                  <div className="offer-details-box__content">
                    <MultiSelect
                      firstList={this.props.propertyList}
                      addedArr={values.properties}
                      onChange={this.addProperties.bind(this, setFieldValue)}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

OfferForm.propTypes = {
  imageUrl: PropTypes.string,
  initialValues: PropTypes.object,
  saveFunction: PropTypes.func.isRequired,
  propertyList: PropTypes.array,
};
export default withRouter(OfferForm);
