import React from 'react';
import compose from 'lodash/flowRight';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import {
  SGToursFromAutomatedCreate,
  SGNavTabs,
  SGPastDetails,
  SGPendingDetails,
  SGRejectedDetails,
  SGScheduledDetails,
} from './views';

const SGToursAutomated = ({ match }) => {
  const tour = useSelector(state => state.property.tour);

  return (
    <Switch>
      <Route exact path={`${match.path}/create`} component={SGToursFromAutomatedCreate} />
      <Route exact path={`${match.path}/detail/pending/:visitorId`} component={SGPendingDetails} />
      <Route exact path={`${match.path}/detail/scheduled/:visitorId`} component={SGScheduledDetails} />
      <Route exact path={`${match.path}/detail/past/:visitorId`} component={SGPastDetails} />
      <Route exact path={`${match.path}/detail/rejected/:visitorId`} component={SGRejectedDetails} />
      {tour && <Route path={`${match.path}/edit`} component={SGNavTabs} />}
    </Switch>
  );
};

export default compose(withRouter)(SGToursAutomated);
