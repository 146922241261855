import React from 'react';
import { compose, createStore, applyMiddleware } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';
import { createDevTools } from 'redux-devtools';
import LogMonitor from 'redux-devtools-log-monitor';
import DockMonitor from 'redux-devtools-dock-monitor';

import apiRequestMiddleware from './utils/apiRequestMiddleware';
import reducers from '../redux/reducers';

// Apply Redux middleware
let storeEnhancer = compose(responsiveStoreEnhancer, applyMiddleware(apiRequestMiddleware, thunk));

// Apply Redux DevTools in development only
let DevTools = null;

const process_env = JSON.parse(process.env.ENV_VARS || null) || process.env;

const { NODE_ENV } = process_env;

if (NODE_ENV === 'development') {
  DevTools = createDevTools(
    <DockMonitor toggleVisibilityKey="ctrl-h" changePositionKey="ctrl-q" defaultIsVisible={false}>
      <LogMonitor theme="tomorrow" />
    </DockMonitor>
  );
  storeEnhancer = compose(storeEnhancer, DevTools.instrument());
}

const reduxStore = storeEnhancer(createStore)(reducers);

export { reduxStore as default, DevTools };
