export const helpFromManagementMocksRu = [
  {
    id: 1,
    residentName: 'Елизавета Ларионова',
    unitNumber: '101',
    type: 'Вопрос',
    description: 'Нет электричества в квартире, исправьте пожалуйста!',
    accessInstructions: 'Пароль от двери в квартиру: 4659 Щиток находится слева от входной двери внутри квартиры',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'New',
    typeClient: 'Резидент',
  },
  {
    id: 2,
    residentName: 'Иван Сергеев',
    unitNumber: '104',
    type: 'Нужна помощь',
    description: 'Нет электричества в квартире, исправьте пожалуйста!',
    accessInstructions: 'Пароль от двери в квартиру: 4659 Щиток находится слева от входной двери внутри квартиры',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'Resolved',
    typeClient: 'Менеджер',
  },
  {
    id: 3,
    residentName: 'Ева Ильинская',
    unitNumber: '203',
    type: 'Проблемы с соседями',
    description: 'Нет электричества в квартире, исправьте пожалуйста!',
    accessInstructions: 'Пароль от двери в квартиру: 4659 Щиток находится слева от входной двери внутри квартиры',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'Resolved',
    typeClient: 'Подрядчик',
  },
  {
    id: 4,
    residentName: 'Иван Сергеев',
    unitNumber: '205',
    type: 'Проблемы со зданием',
    description: 'Нет электричества в квартире, исправьте пожалуйста!',
    accessInstructions: 'Пароль от двери в квартиру: 4659 Щиток находится слева от входной двери внутри квартиры',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'Resolved',
    typeClient: 'Подрядчик',
  },
  {
    id: 5,
    residentName: 'Иван Сергеев',
    unitNumber: '202',
    type: 'Другое',
    description: 'Нет электричества в квартире, исправьте пожалуйста!',
    accessInstructions: 'Пароль от двери в квартиру: 4659 Щиток находится слева от входной двери внутри квартиры',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'Resolved',
    typeClient: 'Подрядчик',
  },
];

export const helpFromManagementMocksEng = [
  {
    id: 1,
    residentName: 'Charles Cramer',
    unitNumber: '101',
    type: 'Question',
    description: 'No electricity in the apartment, please fix!',
    accessInstructions:
      'Door password: 4659. The electrical panel is located to the left of the entrance door inside the apartment.',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'New',
    typeClient: 'Resident',
  },
  {
    id: 2,
    residentName: 'Jane Baldwin',
    unitNumber: '104',
    type: 'Need Help',
    description: 'No electricity in the apartment, please fix!',
    accessInstructions:
      'Door password: 4659. The electrical panel is located to the left of the entrance door inside the apartment.',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'Resolved',
    typeClient: 'Manager',
  },
  {
    id: 3,
    residentName: 'Austin Fleming',
    unitNumber: '203',
    type: 'Neighbor Problems',
    description: 'No electricity in the apartment, please fix!',
    accessInstructions:
      'Door password: 4659. The electrical panel is located to the left of the entrance door inside the apartment.',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'Resolved',
    typeClient: 'Contractor',
  },
  {
    id: 4,
    residentName: 'Agnes Gardner',
    unitNumber: '205',
    type: 'Building Issues',
    description: 'No electricity in the apartment, please fix!',
    accessInstructions:
      'Door password: 4659. The electrical panel is located to the left of the entrance door inside the apartment.',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'Resolved',
    typeClient: 'Contractor',
  },
  {
    id: 5,
    residentName: 'Dylan Carter',
    unitNumber: '202',
    type: 'Other',
    description: 'No electricity in the apartment, please fix!',
    accessInstructions:
      'Door password: 4659. The electrical panel is located to the left of the entrance door inside the apartment.',
    permission: true,
    photo: 'https://bb.lv/engine/client/content/articles/mega/162013483350331928463eb508bcd022f3e2f6006f654.jpg',
    status: 'Resolved',
    typeClient: 'Contractor',
  },
];

export const maintenanceRequestsMocksRu = [
  {
    id: 1,
    residentName: 'Наталия Шилова',
    unitNumber: '105',
    type: 'Электричество',
    description: 'Сделайте, пожалуйста, полную уборку квартиры. Спасибо!',
    accessInstructions: 'Пароль от двери в квартиру: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'New',
    typeClient: 'Подрядчик',
  },
  {
    id: 2,
    residentName: 'Иван Сергеев',
    unitNumber: '101',
    type: 'Сантехника',
    description: 'Сделайте, пожалуйста, полную уборку квартиры. Спасибо!',
    accessInstructions: 'Пароль от двери в квартиру: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'Responded',
    typeClient: 'Менеджер',
  },
  {
    id: 3,
    residentName: 'Ева Ильинская',
    unitNumber: '201',
    type: 'Кондиционер и отопление',
    description: 'Сделайте, пожалуйста, полную уборку квартиры. Спасибо!',
    accessInstructions: 'Пароль от двери в квартиру: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'Pending',
    typeClient: 'Резидент',
  },
  {
    id: 4,
    residentName: 'Иван Сергеев',
    unitNumber: '104',
    type: 'Газ',
    description: 'Сделайте, пожалуйста, полную уборку квартиры. Спасибо!',
    accessInstructions: 'Пароль от двери в квартиру: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'Resolved',
    typeClient: 'Резидент',
  },
  {
    id: 5,
    residentName: 'Иван Сергеев',
    unitNumber: '106',
    type: 'Освещение',
    description: 'Сделайте, пожалуйста, полную уборку квартиры. Спасибо!',
    accessInstructions: 'Пароль от двери в квартиру: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'Problem',
    typeClient: 'Резидент',
  },
];

export const maintenanceRequestsMocksEng = [
  {
    id: 1,
    residentName: 'Charles Cramer',
    unitNumber: '105',
    type: 'Electricity',
    description: 'Please do a complete cleaning of the apartment. Thank you!',
    accessInstructions: 'Password for the door to the apartment: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'New',
    typeClient: 'Contractor',
  },
  {
    id: 2,
    residentName: 'Jane Baldwin',
    unitNumber: '101',
    type: 'Plumbing',
    description: 'Please do a complete cleaning of the apartment. Thank you!',
    accessInstructions: 'Password for the door to the apartment: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'Responded',
    typeClient: 'Manager',
  },
  {
    id: 3,
    residentName: 'Austin Fleming',
    unitNumber: '201',
    type: 'Air conditioning and heating',
    description: 'Please do a complete cleaning of the apartment. Thank you!',
    accessInstructions: 'Password for the door to the apartment: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'Pending',
    typeClient: 'Resident',
  },
  {
    id: 4,
    residentName: 'Agnes Gardner',
    unitNumber: '104',
    type: 'Gas',
    description: 'Please do a complete cleaning of the apartment. Thank you!',
    accessInstructions: 'Password for the door to the apartment: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'Resolved',
    typeClient: 'Resident',
  },
  {
    id: 5,
    residentName: 'Dylan Carter',
    unitNumber: '106',
    type: 'Lighting',
    description: 'Please do a complete cleaning of the apartment. Thank you!',
    accessInstructions: 'Password for the door to the apartment: 9869',
    permission: true,
    photo: 'https://mebelit.com.ua/wp-content/uploads/2019/02/divan-oblako-1800-fs1.jpg',
    status: 'Problem',
    typeClient: 'Resident',
  },
];
