import React from 'react';

export const SGToursNoPermission = () => (
  <div className="toursTip tours-ready">
    <section className="container">
      <div className="paper tours-ready_container text-center tours-ready_shadow tours-tip-container">
        <h1 className="h1 m-t40 strong toursTip-title">No permissions</h1>
      </div>
    </section>
  </div>
);
