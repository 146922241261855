import React from 'react';
import PropTypes from 'prop-types';

export const InputNumber = ({ name, onChange, onBlur, maxLength, value, placeholder }) => {
  const onInputChange = e => {
    if (/^([1-9]\d*)*$/.test(e.target.value)) {
      onChange(e);
    }
  };

  return (
    <input
      type="text"
      name={name}
      maxLength={maxLength}
      onChange={onInputChange}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
    />
  );
};

InputNumber.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  value: PropTypes.string,
};
