import React from 'react';
import SubHeader from '../SubHeader';
import EmptyPlaceholder from '../EmptyPlaceholder';

export default function AccessProfiles({
  profileOptions,
  propertyId,
  setFieldValue,
  selectedProfiles,
  errors,
  touched,
  userPermission,
}) {
  return (
    <div className="access-profiles__section">
      <SubHeader underline required title="Access Profiles" />
      {!profileOptions || !profileOptions.length ? (
        <EmptyPlaceholder buttonText="Access Profiles" linkTo={`/properties/${propertyId}/access-control/profiles`}>
          <div>Access Profiles are required but none are available.</div>
        </EmptyPlaceholder>
      ) : (
        <>
          <div className="checkbox-list">
            {profileOptions.map(option => {
              const isChecked = !!selectedProfiles.find(profile => profile === option.value);
              const permission = userPermission;
              return (
                <div className="checkbox-list--item" key={option.value}>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => {
                        if (isChecked) {
                          setFieldValue(
                            'profiles',
                            selectedProfiles.filter(profile => profile !== option.value)
                          );
                        } else {
                          setFieldValue('profiles', [...selectedProfiles, option.value]);
                        }
                      }}
                      name={option.value}
                      disabled={
                        (!isChecked && selectedProfiles.length >= 4) ||
                        (permission && !permission.ChangResidentAccessControlProfile)
                      }
                    />
                    <span htmlFor={option.value} className="label">
                      {option.label}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
          {touched && touched.profiles && errors && errors.profiles && (
            <div className="input-validation">{errors.profiles}</div>
          )}
        </>
      )}
    </div>
  );
}
