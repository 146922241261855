import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import PageHeader from '../../../../common/PageHeader';

class AccessControlList extends Component {
  render() {
    return (
      <div>
        <PageHeader title="">
          <div className="page-header__navigation-links">
            <Link to={`/access-control/credential-types`}>Credential Types</Link>
            <Link to={`/access-control/telephone-entry-devices`}>Telephone Entry Devices</Link>
          </div>
        </PageHeader>
      </div>
    );
  }
}

const AccessControlListContainer = connect()(AccessControlList);

export default withRouter(AccessControlListContainer);
