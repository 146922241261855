import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import Yup from 'yup';
import MaskedInput from 'react-input-mask';
import InputScaffold from '../../../../../../common/forms/InputScaffold';
import { trimAndSetNullFalsyValues, getPhoneMask } from '../../../../../../common/utils/helpers';
import NotificationStatusIndicator from '../../../../../../common/forms/NotificationStatusIndicator';
import ToggleScaffold from '../../../../../../common/forms/ToggleScaffold';
import SubHeader from '../../../../../../common/SubHeader';

export default function MaintenanceForm(props) {
  const dispatch = useDispatch();
  const [formStatus, setFormStatus] = useState('');
  const {
    community,
    maintenanceConfiguration,
    editMaintenanceConfiguration,
    saveMaintenanceConfiguration,
    mriConfiguration,
  } = props;

  const emailSchema = Yup.string()
    .trim()
    .email('Must be a valid email address')
    .max('64', 'Email may only be 64 characters or less')
    .nullable(true);

  const validationSchema = Yup.object().shape(
    {
      email: emailSchema.when('secondary_email', {
        is: value => !!value,
        then: Yup.string().required('Primary email is required if a secondary email is provided'),
      }),
      secondary_email: emailSchema.when('email', {
        is: email => email?.length,
        then: Yup.string().notOneOf([Yup.ref('email')], 'This email is already used in this form'),
      }),
      phone: Yup.string()
        .matches(/^[1]\d{10}|[0,2-9]\d{9}$/, 'Must be a valid phone number')
        .nullable(true),
      secondary_phone: Yup.string()
        .matches(/^[1]\d{10}|[0,2-9]\d{9}$/, 'Must be a valid phone number')
        .nullable(true)
        .when('phone', {
          is: phone => phone?.length,
          then: Yup.string().notOneOf([Yup.ref('phone')], 'This phone number is already used in this form'),
        }),
      label: Yup.string()
        .trim()
        .max('36', 'Label may only have 36 characters or less')
        .nullable(true),
      description: Yup.string()
        .trim()
        .max('280', 'Description may only have 280 characters or less')
        .nullable(true),
    },
    [['email', 'secondary_email']]
  );

  const initialValues = maintenanceConfiguration
    ? {
        email: maintenanceConfiguration.get('email'),
        secondary_email: maintenanceConfiguration.get('secondaryEmail'),
        phone: maintenanceConfiguration.get('phone'),
        secondary_phone: maintenanceConfiguration.get('secondaryPhone'),
        label: maintenanceConfiguration.get('label'),
        description: maintenanceConfiguration.get('description'),
        dispatch_destination: maintenanceConfiguration.get('dispatchDestination'),
      }
    : {
        dispatch_destination: 'DISABLED',
      };

  function save(communityId, values, actions) {
    values.property_id = communityId;
    dispatch(saveMaintenanceConfiguration(communityId, trimAndSetNullFalsyValues(values)))
      .then(action => {
        if (action.response.ok) {
          setFormStatus({ message: 'Maintenance Settings Saved!', status: 'Success' });
        } else {
          setFormStatus({ message: action.json.statusCode + ' - ' + action.json.message, status: 'Failure' });
        }
        actions.setSubmitting(false);
        return actions.setStatus('Successfully Updated');
      })
      .catch(err => actions.setErrors({ submitting: 'Update Failed' }));
  }

  function update(communityId, values, actions) {
    values.property_id = communityId;
    dispatch(editMaintenanceConfiguration(communityId, trimAndSetNullFalsyValues(values)))
      .then(action => {
        if (action.response.ok) {
          setFormStatus({ message: 'Maintenance Settings Saved!', status: 'Success' });
        } else {
          setFormStatus({ message: action.json.statusCode + ' - ' + action.json.message, status: 'Failure' });
        }
        actions.setSubmitting(false);
        return actions.setStatus('Successfully Updated');
      })
      .catch(err => actions.setErrors({ submitting: 'Update Failed' }));
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setFormStatus({ message: '' });
        if (maintenanceConfiguration) {
          update(community.get('id'), values, actions);
        } else {
          save(community.get('id'), values, actions);
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, status }) => (
        <div className="container">
          <form className="maintenance-form" onSubmit={handleSubmit}>
            <div className="page-header subheader">
              <h4 className="h4">Maintenance</h4>
            </div>
            <div className="paper radius-top-left radius-top-right">
              <section className="paper__section">
                <SubHeader title="Maintenance Ticketing" underline paperSubheader />
                <div className="radio--container radio--container__column">
                  <ToggleScaffold label="Disabled">
                    <input
                      type="radio"
                      name="disabled"
                      checked={values.dispatch_destination === 'DISABLED'}
                      onChange={() => {
                        setFieldValue('dispatch_destination', 'DISABLED');
                      }}
                    />
                  </ToggleScaffold>
                  <ToggleScaffold label="Email">
                    <input
                      type="radio"
                      name="enable_email"
                      checked={values.dispatch_destination === 'ENABLE_EMAIL'}
                      onChange={() => {
                        setFieldValue('dispatch_destination', 'ENABLE_EMAIL');
                      }}
                    />
                  </ToggleScaffold>
                  {mriConfiguration && mriConfiguration.get('enabled') && (
                    <ToggleScaffold label="MRI">
                      <input
                        type="radio"
                        name="enable_mri"
                        checked={values.dispatch_destination === 'ENABLE_MRI'}
                        onChange={() => {
                          setFieldValue('dispatch_destination', 'ENABLE_MRI');
                        }}
                      />
                    </ToggleScaffold>
                  )}
                </div>

                {values.dispatch_destination !== 'DISABLED' && (
                  <div>
                    {(values.dispatch_destination === 'ENABLE_EMAIL' ||
                      values.dispatch_destination === 'ENABLE_MRI') && (
                      <div>
                        {values.dispatch_destination === 'ENABLE_EMAIL' && (
                          <div>
                            <InputScaffold label="Email" validation={errors.email}>
                              <p>
                                When residents create a Maintenance ticket on SmartExperience, they will be sent to this
                                email. If you use a software like Yardi or Entrata, use the same email that those
                                maintenance forms/teams use. If you leave this blank, residents will not be able to
                                submit maintenance tickets in the app.
                              </p>
                              <input type="text" name="email" onChange={handleChange} value={values.email} />
                            </InputScaffold>
                            <SubHeader title="Maintenance Contact Information" underline paperSubheader nested />
                            <InputScaffold label="Secondary Email" validation={errors.secondary_email}>
                              <input
                                type="text"
                                name="secondary_email"
                                onChange={handleChange}
                                value={values.secondary_email}
                              />
                            </InputScaffold>
                          </div>
                        )}
                        {(values.dispatch_destination === 'ENABLE_EMAIL' ||
                          values.dispatch_destination === 'ENABLE_MRI') && (
                          <div>
                            <InputScaffold label="Maintenance Phone Number" validation={errors.phone}>
                              <MaskedInput
                                mask={getPhoneMask(values.phone)}
                                name="phone"
                                onChange={event => {
                                  setFieldValue('phone', event.target.value.replace(/[^0-9]+/g, ''));
                                }}
                                value={values.phone}
                              />
                            </InputScaffold>

                            <InputScaffold label="Secondary Phone Number" validation={errors.secondary_phone}>
                              <MaskedInput
                                mask={getPhoneMask(values.secondary_phone)}
                                name="secondary_phone"
                                onChange={event => {
                                  setFieldValue('secondary_phone', event.target.value.replace(/[^0-9]+/g, ''));
                                  !event.target.value && setFieldValue('label', '');
                                }}
                                value={values.secondary_phone}
                              />
                            </InputScaffold>

                            <InputScaffold label="Secondary Phone Number Label" validation={errors.label}>
                              <input
                                type="text"
                                name="label"
                                maxLength={36}
                                onChange={handleChange}
                                value={values.secondary_phone ? values.label : ''}
                                disabled={!values.secondary_phone}
                              />
                            </InputScaffold>

                            <InputScaffold
                              label="Description of Maintenance Phone Numbers"
                              validation={errors.description}
                            >
                              <input
                                type="text"
                                name="description"
                                maxLength={280}
                                onChange={handleChange}
                                value={values.description}
                              />
                            </InputScaffold>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </section>
            </div>

            <div className="paper__footer radius-bottom-left radius-bottom-right">
              <button type="submit" disabled={isSubmitting || Object.keys(errors).length} className="button">
                Save Maintenance Settings
              </button>
              <NotificationStatusIndicator
                hideNotification={!formStatus.message}
                message={formStatus.message}
                type={formStatus.status}
              />
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
}
