import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import SpinnerLoading from '../../../../../../../common/Loading/SpinnerLoading';
import EmptyPlaceholder from '../../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../../common/Kebab/index';
import Icon from '../../../../../../../common/icons/icon';
import Delete from '../../../../../../../common/Delete';

class UnrecognizedProfiles extends Component {
  static propTypes = {
    accessControlPanelProfiles: PropTypes.instanceOf(List),
    accessControlPanelProfilesLoaded: PropTypes.bool.isRequired,
    accessControlProfilesLoaded: PropTypes.bool.isRequired,
    getPanelProfiles: PropTypes.func.isRequired,
    addProfile: PropTypes.func.isRequired,
    removePanelProfile: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const action = await this.props.getPanelProfiles();

    if (!action.response.ok) {
      this.setState({
        error: (action.json && action.json.message) || 'Unknown error occurred while getting panel profiles',
      });
    }
  }

  state = {
    openKebabProfileNumber: null,
    deleteConfirmationProfileNumber: null,
    error: false,
  };

  onKebabClick = profileNumber => this.setState({ openKebabProfileNumber: profileNumber });

  renderKebab(profile) {
    const { addProfile } = this.props;
    const { openKebabProfileNumber } = this.state;

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(profile.get('number'))}
          onDisengage={() => this.setState({ openKebabProfileNumber: null })}
          menuOpened={profile.get('number') === openKebabProfileNumber}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={async () => {
                const action = await addProfile({
                  name: profile.get('name'),
                  dmp_profile_number: profile.get('number'),
                });
                if (!action.response.ok) {
                  this.setState({
                    error: (action.json && action.json.message) || 'Unknown error occurred while adding panel profile',
                  });
                }
              }}
            >
              <Icon icon="Add" />
              Import
            </div>
            <div
              className="kebab__menu--item"
              onClick={() =>
                this.setState({ deleteConfirmationProfileNumber: profile.get('number'), openKebabProfileId: null })
              }
            >
              <Icon icon="Remove" />
              Delete
            </div>
          </div>
        </Kebab>
      </div>
    );
  }

  renderDeleteConfirmation = profile => {
    const { removePanelProfile } = this.props;

    return (
      <div>
        <Delete
          onCancel={() => this.setState({ deleteConfirmationProfileNumber: null, openKebabProfileNumber: null })}
          deleteFunc={async () => {
            const action = await removePanelProfile(profile.get('number'));
            if (!action.response.ok) {
              this.setState({
                error: (action.json && action.json.message) || 'Unknown error occurred while removing panel profile',
              });
            }
          }}
          message={
            <div>
              Are you sure you want to <strong className="strong">remove this profile</strong>?
            </div>
          }
        />
      </div>
    );
  };

  render() {
    const { accessControlPanelProfiles, accessControlPanelProfilesLoaded, accessControlProfilesLoaded } = this.props;
    const { deleteConfirmationProfileNumber } = this.state;

    if (!accessControlPanelProfilesLoaded || !accessControlProfilesLoaded) {
      return <SpinnerLoading />;
    }

    return (
      <div>
        {accessControlPanelProfiles.size > 0 ? (
          <table className="table-units-list">
            <thead>
              <tr>
                <th>Name</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {accessControlPanelProfiles.map(profile => (
                <tr key={profile.get('number')}>
                  <td>{profile.get('name')}</td>
                  {
                    <td className="table__align-right">
                      {deleteConfirmationProfileNumber === profile.get('number')
                        ? this.renderDeleteConfirmation(profile)
                        : this.renderKebab(profile)}
                    </td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyPlaceholder>All profiles have been imported.</EmptyPlaceholder>
        )}
      </div>
    );
  }
}

export default UnrecognizedProfiles;
