import * as moment from 'moment';
import React, { useEffect } from 'react';
import TimePicker from 'rc-time-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import SubHeader from '../../../../../common/SubHeader/index';
import InputScaffold from '../../../../../common/forms/InputScaffold';
import Spinner from '../../../../../common/Loading/SpinnerLoading';
import Icon from '../../../../../common/icons/icon';
import Select from 'react-select';
import Yup from 'yup';
import { Formik } from 'formik';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  communityViewsCamerasSelector,
  propertySelector,
  communityViewsArchivedVideoDownloadSelector,
  communityViewsVideoProcessingServerSelector,
  videoProcessingServerConfigLoadingSelector,
} from '../../../redux/selectors';
import {
  checkArchivedDownloadStatus,
  getCommunityViewsCameras,
  requestArchivedDownload,
  requestVideoProcessingServerConfig,
} from '../../../redux/actions/communityViewsActions';

const validationSchema = Yup.object().shape({
  camera_id: Yup.string().required('Please select a Camera'),
  start_date: Yup.number().required('A valid start date is required'),
  start_time: Yup.number()
    .required('A valid start time is required')
    .max(Date.now(), 'Start Date and Time must be in the past'),
  duration: Yup.number()
    .required('A duration is required')
    .min(30, 'Duration must be at least 30 seconds')
    .max(900, 'Duration must be less than 900 seconds (15 minutes)'),
});

const defaultValues = {
  duration: 30,
  start_date: Date.now(),
  start_time: Date.now(),
};

export function ArchivedVideo() {
  const dispatch = useDispatch();
  const property = useSelector(propertySelector);
  const cameras = useSelector(communityViewsCamerasSelector);
  const download = useSelector(communityViewsArchivedVideoDownloadSelector);
  const videoProcessingServer = useSelector(communityViewsVideoProcessingServerSelector);
  const videoProcessingServerLoading = useSelector(videoProcessingServerConfigLoadingSelector);

  function onSubmit(values, { setSubmitting }) {
    setSubmitting(false);
    dispatch(requestArchivedDownload(values, property.get('id')));
  }

  function onDateOrTimeChanged(date_timestamp, time_timestamp, setFieldValue) {
    const date = new Date(date_timestamp);
    const time = new Date(time_timestamp);
    const val = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), 0, 0);

    setFieldValue('start_date', val.getTime());
    setFieldValue('start_time', val.getTime());
  }

  useEffect(() => {
    dispatch(getCommunityViewsCameras(property.get('id').toString()));
    dispatch(requestVideoProcessingServerConfig(property.get('id').toString()));
  }, [property, dispatch]);

  useEffect(() => {
    const pendingDownloadInterval = setInterval(() => {
      if (download?.status === 'pending' || download?.status === 'initialized') {
        dispatch(
          checkArchivedDownloadStatus({ camera_id: download.camera.id, download_id: download.id }, property.get('id'))
        );
      }
    }, 2000);

    return () => clearInterval(pendingDownloadInterval);
  }, [download, dispatch, property]);

  return (
    <div className="container">
      <SubHeader title={'Archived Video'} />

      {videoProcessingServerLoading && <span>Loading Community Views Configuration...</span>}
      {!videoProcessingServerLoading && videoProcessingServer ? (
        <Formik
          initialValues={{ ...defaultValues }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
          render={({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            setFieldTouched,
            validateForm,
          }) => (
            <form onSubmit={handleSubmit} className="archived-video-form">
              <div className="paper radius-top-left radius-top-right">
                <SubHeader title="Download Archived Video" underline paperSubheader />
                <InputScaffold label="Camera" required validation={errors.camera_id}>
                  <Select
                    closeOnSelect={true}
                    onChange={value => setFieldValue('camera_id', value)}
                    options={cameras?.reduce(
                      (acc, camera) => [...acc, { label: camera.camera_name, value: camera.id }],
                      []
                    )}
                    placeholder="Cameras..."
                    value={values.camera_id}
                    simpleValue={true}
                    clearable={false}
                    searchable={false}
                  />
                </InputScaffold>
                <InputScaffold label="Video Start Date" required validation={touched.start_date && errors.start_date}>
                  <DayPickerInput
                    inputProps={{
                      autoComplete: 'false',
                    }}
                    name="start_date"
                    value={values.start_date ? new Date(values.start_date) : new Date()}
                    placeholder=""
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      disabledDays: { after: new Date() },
                    }}
                    onDayChange={to => {
                      const date = to;
                      date.setHours(0);
                      onDateOrTimeChanged(date, values.start_time, setFieldValue);
                      setFieldTouched('start_date', true);
                    }}
                  />
                </InputScaffold>
                <InputScaffold label="Video Start Time" required validation={errors.start_time}>
                  <TimePicker
                    name="start_time"
                    showSecond={false}
                    value={moment(values.start_time ?? Date.now())}
                    onChange={time => {
                      const timeVal = time.toDate();
                      onDateOrTimeChanged(values.start_date, timeVal, setFieldValue);
                      setFieldTouched('start_time', true);
                    }}
                    format="h:mm a"
                    allowEmpty={true}
                    use12Hours
                    inputReadOnly
                    className="time-picker"
                    minuteStep={1}
                  />
                </InputScaffold>
                <InputScaffold
                  label="Duration of Video (Seconds)"
                  validation={touched.duration && errors.duration}
                  required
                >
                  <input type="number" name="duration" maxLength="4" onChange={handleChange} value={values.duration} />
                </InputScaffold>

                {!download && (
                  <div className="download-request-status no-download-requests">
                    <span>No Download Requests</span>
                  </div>
                )}

                {download && (download.status === 'pending' || download.status === 'initialized') && (
                  <div className="download-request-status download-request-pending">
                    <span>Download Request Processing</span>
                    <Spinner />
                  </div>
                )}

                {download && download.status === 'ready' && (
                  <div className="download-request-status download-request-ready">
                    <span>Download Ready</span>
                    <a href={download.url}>
                      <Icon icon="Download" />
                    </a>
                  </div>
                )}

                {download && download.status === 'no-data' && (
                  <div className="download-request-status download-request-error">
                    <span>No video data available for selected date and duration</span>
                    <Icon icon="ErrorExclamation" />
                  </div>
                )}
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <button type="submit" disabled={isSubmitting} className="button">
                  Submit
                </button>
              </div>
            </form>
          )}
        />
      ) : (
        <span>
          Please configure a valid Video Processing Server host under Settings before attempting to download Archived
          Video
        </span>
      )}
    </div>
  );
}
