import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import SpinnerLoading from '../../../../../../../common/Loading/SpinnerLoading';
import EmptyPlaceholder from '../../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../../common/Kebab/index';
import Icon from '../../../../../../../common/icons/icon';
import Delete from '../../../../../../../common/Delete';

class UnrecognizedAreas extends Component {
  static propTypes = {
    accessControlPanelAreas: PropTypes.instanceOf(List),
    accessControlPanelAreasLoaded: PropTypes.bool.isRequired,
    accessControlAreasLoaded: PropTypes.bool.isRequired,
    getPropertyPanelAreas: PropTypes.func.isRequired,
    addArea: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const action = await this.props.getPropertyPanelAreas();

    if (!action.response.ok) {
      this.setState({
        error: (action.json && action.json.message) || 'Unknown error occurred while getting panel areas',
      });
    }
  }

  state = {
    openKebabAreaNumber: null,
    deleteConfirmationAreaNumber: null,
    error: false,
  };

  onKebabClick = areaNumber => this.setState({ openKebabAreaNumber: areaNumber });

  renderKebab(area) {
    const { addArea } = this.props;
    const { openKebabAreaNumber } = this.state;

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(area.get('number'))}
          onDisengage={() => this.setState({ openKebabAreaNumber: null })}
          menuOpened={area.get('number') === openKebabAreaNumber}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={async () => {
                const action = await addArea({
                  name: area.get('name'),
                  dmp_area_number: area.get('number'),
                });
                if (!action.response.ok) {
                  this.setState({
                    error: (action.json && action.json.message) || 'Unknown error occurred while adding panel areas',
                  });
                }
              }}
            >
              <Icon icon="Add" />
              Import
            </div>
            <div
              className="kebab__menu--item"
              onClick={() => this.setState({ deleteConfirmationAreaNumber: area.get('number'), openKebabAreaId: null })}
            >
              <Icon icon="Remove" />
              Delete
            </div>
          </div>
        </Kebab>
      </div>
    );
  }

  renderDeleteConfirmation = area => {
    const { removePanelArea } = this.props;

    return (
      <div>
        <Delete
          onCancel={() => this.setState({ deleteConfirmationAreaNumber: null, openKebabAreaId: null })}
          deleteFunc={async () => {
            const action = await removePanelArea(area.get('number'));
            if (!action.response.ok) {
              this.setState({
                error: (action.json && action.json.message) || 'Unknown error occurred while removing panel areas',
              });
            }
          }}
          message={
            <div>
              Are you sure you want to <strong className="strong">remove this area</strong>?
            </div>
          }
        />
      </div>
    );
  };

  render() {
    const { accessControlPanelAreas, accessControlPanelAreasLoaded, accessControlAreasLoaded } = this.props;
    const { deleteConfirmationAreaNumber } = this.state;

    if (!accessControlPanelAreasLoaded || !accessControlAreasLoaded) {
      return <SpinnerLoading />;
    }

    return (
      <div>
        {accessControlPanelAreas.size > 0 ? (
          <table className="table-units-list">
            <thead>
              <tr>
                <th>Name</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {accessControlPanelAreas.map(area => (
                <tr key={area.get('number')}>
                  <td>{area.get('name')}</td>
                  {
                    <td className="table__align-right">
                      {deleteConfirmationAreaNumber === area.get('number')
                        ? this.renderDeleteConfirmation(area)
                        : this.renderKebab(area)}
                    </td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyPlaceholder>All areas have been imported.</EmptyPlaceholder>
        )}
      </div>
    );
  }
}

export default UnrecognizedAreas;
