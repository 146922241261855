import MailHubs from './MailHubs';
import { connect } from 'react-redux';
import { getMailHubs, removeMailHub } from '../../../../redux/actions';

const mapStateToProps = (state, ownProps) => ({
  propertyId: ownProps.match.params.propertyId,
  mailHubs: state.property.mailHubs,
  mailHubsLoaded: state.property.mailHubsLoaded,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMailHubs: () => dispatch(getMailHubs(ownProps.match.params.propertyId)),
  removeMailHub: mailHubId => dispatch(removeMailHub(ownProps.match.params.propertyId, mailHubId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailHubs);
