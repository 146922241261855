import { createSelector } from 'reselect';
import { List } from 'immutable';

const selectZwaveSetups = state => state.devices.panelDevices.get('zwaveSetups');

const selectNonZwaveSetups = state => state.devices.panelDevices.get('nonZwaveSetups');

export const selectDevices = state => state.devices.panelDevices.get('devices');

export const selectUnrecognizedDevices = createSelector(
  selectZwaveSetups,
  selectNonZwaveSetups,
  selectDevices,
  (zwaveSetups, nonZwaveSetups, devices) => {
    const filter = node => !devices.find(device => device.get('id') === node.get('name'));
    return zwaveSetups && nonZwaveSetups && devices
      ? zwaveSetups.filter(filter).concat(nonZwaveSetups.filter(filter))
      : List();
  }
);
