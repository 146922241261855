import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';

import SpinnerLoading from '../../../../../../common/Loading/SpinnerLoading';
import PageHeader from '../../../../../../common/PageHeader';
import AccessPointForm from '../../components/AccessPointForm';

class AddAccessPoint extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    property: PropTypes.instanceOf(Map),
    accessControlDoors: PropTypes.instanceOf(List).isRequired,
    hasMasterPanel: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (this.props.hasMasterPanel) {
      this.props.getDoors();
    }
  }

  handleSuccess = () => {
    this.props.history.push('./');
  };

  handleCancel = () => {
    this.props.history.push('./');
  };

  render() {
    const { addAccessPoint, accessControlDoors, accessControlDoorsLoaded, hasMasterPanel } = this.props;

    const isLoaded = !hasMasterPanel || accessControlDoorsLoaded;

    return (
      <div>
        <PageHeader title="Add Access Point" white backLink="./" />
        <div className="container">
          {isLoaded ? (
            <AccessPointForm
              submitFunc={addAccessPoint}
              onCancel={this.handleCancel}
              onSuccess={this.handleSuccess}
              doors={accessControlDoors.toJS()}
              enableAccessControlDoorInput={hasMasterPanel}
            />
          ) : (
            <SpinnerLoading />
          )}
        </div>
      </div>
    );
  }
}

export default AddAccessPoint;
