import { connect } from 'react-redux';
import PermissionsList from './PermissionsList';
import {
  getGroupPermission,
  getGroupPermissionByName,
  getPermissionById,
  addPermission,
  editPermission,
  addGroup,
  updateGroup,
  getGroup,
} from '../../../../../redux/actions';
import { selectGroupOptions } from '../../../../../redux/selectors';
import { getUserPermission } from '../../../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    permissionList: state.RolesAndPermissions.permissionList,
    inPermissionList: state.RolesAndPermissions.inPermissionList,
    permissionInfo: state.RolesAndPermissions.permissionInfo,
    permissionListLoading: state.RolesAndPermissions.permissionListLoading,
    groupOptions: selectGroupOptions(state),
    user: state.user,
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGroupPermission: () => dispatch(getGroupPermission()),
    getGroup: () => dispatch(getGroup()),
    getGroupPermissionByName: permission => dispatch(getGroupPermissionByName(permission)),
    getPermissionById: id => dispatch(getPermissionById(id)),
    addPermission: payload => dispatch(addPermission(payload)),
    editPermission: (PermissionId, payload) => dispatch(editPermission(PermissionId, payload)),
    addGroup: payload => dispatch(addGroup(payload)),
    updateGroup: payload => dispatch(updateGroup(payload)),
  };
}

const PermissionsContainer = connect(mapStateToProps, mapDispatchToProps)(PermissionsList);

export default PermissionsContainer;
