import React from 'react';
import PropTypes from 'prop-types';

export const SGButton = ({ title, iconComponent, className, handleClick, disabled }) => (
  <button disabled={disabled} className={className} onClick={handleClick} type="button">
    {iconComponent}
    <span className="button-title">{title}</span>
  </button>
);

SGButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.oneOf([
    'detail-button-revoke',
    'detail-button-enable',
    'detail-button-enable button',
    'button btn-approve',
    'btn-scroll',
    'button detail-button-to-past',
    'button-no',
    'button-positive btn-approve p-lr36',
    'button-positive btn-approve',
    'button-red',
  ]),
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
};
