import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import PropertyGuestsList from './views/PropertyGuestsList';
import AddPropertyGuest from './views/AddPropertyGuest';
import EditPropertyGuest from './views/EditPropertyGuest';
import NotFound from '../../../../../NotFound';

class PropertyGuests extends Component {
  componentDidMount() {
    this.props.getPropertyGuests();
    this.props.getCredentialTypes();
  }

  render() {
    const { match, property } = this.props;

    if (!property.get('admin_enabled_access_control')) {
      return <NotFound />;
    }

    return (
      <div>
        <Switch>
          <Route exact path={`${match.path}/`} component={PropertyGuestsList} />
          <Route exact path={`${match.path}/guests/add`} component={AddPropertyGuest} />
          <Route exact path={`${match.path}/guests/:guestId/edit`} component={EditPropertyGuest} />
        </Switch>
      </div>
    );
  }
}

PropertyGuests.propTypes = {
  match: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
};
export default PropertyGuests;
