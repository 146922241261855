import React, { Component } from 'react';
import Icon from '../../../../../../../../../common/icons/icon';
import PropTypes from 'prop-types';
import { List } from 'immutable';

class FailedCodeSyncModal extends Component {
  componentDidMount() {
    this.props.getFailedCodes();
  }
  render() {
    const { failedCodes, setModalVisible } = this.props;
    return (
      <div className="modal modal--visible amenity-summary-modal modal__overlay">
        <div className="modal__children summary--modal__children" style={{ maxHeight: '80%' }}>
          <div className="modal__header">
            <h3>Failed Code Syncs</h3>
            <div className="modal__close" onClick={() => setModalVisible()}>
              <Icon icon="Clear" />
            </div>
          </div>
          <div className="modal__body">
            <div className="table__wrapper">
              <table className="table-list" style={{ margin: '50px', width: '90%' }}>
                <tr>
                  <th style={{ textAlign: 'left', color: '#7a8a99' }}>Name</th>
                  <th style={{ color: '#7a8a99' }}>Code</th>
                  <th style={{ color: '#7a8a99' }}>Slot</th>
                  <th style={{ color: '#7a8a99' }}>Action</th>
                </tr>
                <br />
                <tbody>
                  {failedCodes.map(failedCode => {
                    return (
                      <tr key={failedCode.id}>
                        <td>{failedCode.name}</td>
                        <td style={{ textAlign: 'center' }}>{failedCode.code}</td>
                        <td style={{ textAlign: 'center' }}>{failedCode.slot}</td>
                        <td style={{ textAlign: 'center' }}>{failedCode.action}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FailedCodeSyncModal.propTypes = {
  failedCodes: PropTypes.instanceOf(List).isRequired,
  getFailedCodes: PropTypes.func.isRequired,
};

export default FailedCodeSyncModal;
