import { connect } from 'react-redux';
import { createUnit } from '../../../../redux/actions';
import { deleteUnit } from '../Unit/redux/actions';
import { selectGroupOptions } from '../../../../redux/selectors';
import { selectorIsAdmin } from '../../../../../../redux/selectors';
import AddUnit from './AddUnit';

function mapStateToProps(state) {
  return {
    groupOptions: selectGroupOptions(state),
    isAdmin: selectorIsAdmin(state),
    property: state.property.activeProperty,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    createUnit: (values, propertyId) => dispatch(createUnit(values, propertyId)),
    deleteUnit: () => dispatch(deleteUnit(ownProps.match.params.propertyId, ownProps.match.params.unitId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUnit);
