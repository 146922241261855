import { connect } from 'react-redux';
import { getProperty, createDemo } from '../Property/redux/actions';
import CreateDemo from './CreateDemo';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProperty: propertyId => dispatch(getProperty(propertyId)),
    createDemo: (values, propertyId) => dispatch(createDemo(values, propertyId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getProperty: () => dispatchProps.getProperty(ownProps.match.params.propertyId),
    createDemo: values => dispatchProps.createDemo(values, ownProps.match.params.propertyId),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateDemo);
