import { createSelector } from 'reselect';

export const propertySelector = state => state.property.activeProperty;
export const unitSelector = state => state.property.unit.activeUnit;
export const communityViewsCamerasSelector = state => state.property.communityViewsCameras;
export const communityViewsStreamsSelector = state => state.property.communityViewsCurrentStreams;
export const communityViewsAverageActivitySelector = state => state.property.averageActivity;
export const communityViewsVideoProcessingServerSelector = state => state.property.videoProcessingServerConfig;
export const communityViewsArchivedVideoDownloadSelector = state => state.property.communityViewsArchiveDownload;
export const communityViewsNotificationContactsSelector = state => state.property.communityViewsNotificationContacts;
export const vendorsSelector = state => state.property.vendorsList;
export const videoProcessingServerConfigLoadingSelector = state => state.property.videoProcessingServerConfigLoading;
export const communityViewsCamerasLoadingSelector = state => state.property.communityViewsCamerasLoading;

const messageSelector = state => state.property.activeMessage;
const credentialTypeSelector = state => state.credentialTypes;
const propertyGuestSelector = state => state.property.propertyGuests;

export const selectVendor = createSelector(
  vendorsSelector,
  (state, vendorId) => vendorId,
  (vendors, vendorId) => vendors.find(vendor => vendor.get('id') === vendorId)
);

export const selectGroupOptions = createSelector(propertySelector, activeProperty =>
  activeProperty
    .get('groups')
    .map(group => {
      return { value: group.get('id'), label: group.get('name') };
    })
    .toJS()
);

export const selectChosenGroupOptions = createSelector(unitSelector, activeUnit =>
  activeUnit
    .get('groups')
    .map(group => {
      return { value: group.get('id'), label: group.get('name') };
    })
    .toJS()
);

export const selectMessageGroups = createSelector(messageSelector, activeMessage =>
  activeMessage.has('groups')
    ? activeMessage
        .get('groups')
        .map(group => {
          return group.get('id');
        })
        .toJS()
    : null
);

export const selectActivePropertyManager = createSelector(
  propertySelector,
  (state, props) => props.match.params.propertyManagerId,
  (activeProperty, propertyManagerId) => {
    return activeProperty.get('staff').find(staffMember => staffMember.getIn(['user', 'id']) === propertyManagerId);
  }
);

export const selectCredentialTypes = createSelector(credentialTypeSelector, credentialTypes => credentialTypes.toJS());

export const selectCredentialTypesForProperty = createSelector(propertySelector, activeProperty =>
  activeProperty.get('credential_types').toJS()
);

export const selectActivePropertyGuest = createSelector(
  propertyGuestSelector,
  (state, guestId) => guestId,
  (guests, guestId) => {
    return guests.find(guest => guest.get('id') === guestId);
  }
);

export const selectAccessControlProfileOptions = createSelector(
  state => state.property.accessControlProfiles,
  accessProfiles =>
    accessProfiles
      .map(profile => ({
        value: profile.get('id'),
        label: profile.get('name'),
      }))
      .toJS()
);

export const selectDefaultAccessControlProfilesForUserType = createSelector(
  state => state.property.accessControlProfiles,
  (state, userType) => userType,
  (accessProfiles, userType) =>
    accessProfiles
      .filter(profile => profile.get(`is_${userType}_default`))
      .map(profile => profile.get('id'))
      .toJS()
);

export const selectTourToken = state => {
  const tour = state?.property?.tour;
  return tour ? tour.get('token') : '';
};
