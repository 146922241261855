import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Delete from '../Delete';

const DeleteWithPrompt = ({ disabled, message, deleteFunc, buttonLabel }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const renderDeleteConfirmation = () => {
    return (
      <div className="paper__footer radius-bottom-left radius-bottom-right t-removef-btn" style={{ display: 'block' }}>
        <Delete
          onCancel={() => setShowConfirm(false)}
          deleteFunc={() => {
            setShowConfirm(false);
            deleteFunc();
          }}
          message={message}
        />
      </div>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <button
        disabled={disabled}
        type="button"
        className="button button--destructive"
        onClick={() => setShowConfirm(true)}
      >
        {buttonLabel}
      </button>
      <div style={{ position: 'absolute', bottom: '100%', left: '100%' }}>
        {showConfirm && renderDeleteConfirmation()}
      </div>
    </div>
  );
};

DeleteWithPrompt.propTypes = {
  deleteFunc: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  message: PropTypes.node,
  hasError: PropTypes.bool,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
};

DeleteWithPrompt.defaultProps = {
  message: (
    <span>
      Are you sure you want to <strong className="strong">remove this</strong>?
    </span>
  ),
  hasError: false,
  buttonLabel: 'Delete',
  disabled: false,
};

export default DeleteWithPrompt;
