import { connect } from 'react-redux';

import AddDoor from './AddDoor';
import { addDoor, getPropertyAreas } from '../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlAreas: state.property.accessControlAreas,
    accessControlAreasLoaded: state.property.accessControlAreasLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addDoor: payload => dispatch(addDoor(ownProps.match.params.propertyId, payload)),
    getPropertyAreas: () => dispatch(getPropertyAreas(ownProps.match.params.propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDoor);
