import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Avatar extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    imageSource: PropTypes.string,
  };

  state = { error: false };

  render() {
    const { name, email, imageSource } = this.props;
    // Initials are set
    let initials = [];

    // Check if the name is null. If it is, proceed with using the email for the avatar
    if (name === null || name === '' || name === 'null null') {
      // If the email is null, set initials to LB as a default
      if (email === null || email === '') {
        initials[0] = 'L';
        initials[1] = 'B';
      }
      // Else if the second character in the email is the @, set a single initial
      else if (email.charAt(1) === '@') {
        initials[0] = email.charAt(0);
        initials[1] = ' ';
      }
      // Else, set the first two initials to the first two characters of the email
      else {
        initials[0] = email.charAt(0);
        initials[1] = email.charAt(1);
      }
    }
    // If not null, set the initials to the name and further check the name
    else {
      initials = name.split(' ').map(i => i[0]);

      // If the second initial is null, set the initial to be empty space
      if (initials[1] === null) {
        initials[1] = ' ';
      }
    }

    return (
      <div
        className="avatar"
        style={{ backgroundImage: imageSource && !this.state.error ? `url(${imageSource})` : null }}
      >
        {!imageSource && `${initials[0]}${initials[1]}`}
      </div>
    );
  }
}
