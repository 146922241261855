import { connect } from 'react-redux';
import AddCredentialType from './AddCredentialType';
import { selectActiveCredentialType } from '../../../../../../redux/selectors';
import { createCredentialType } from '../../../../../../redux/actions';

function mapStateToProps(state, props) {
  return {
    credentialType: selectActiveCredentialType(state, props),
    credentialTypesLoaded: state.credentialTypesLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    createCredentialType: payload => dispatch(createCredentialType(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCredentialType);
