import React from 'react';
import { withRouter } from 'react-router-dom';
import { SGBtnBack, SGPageHeader } from '../../../common';
import {
  SGTourForm,
  SGGroupButtons,
  SGBlockErrors,
  SGFormBlock,
  SGFormUnits,
  SGFormUnitsStaff,
  SGFormAvailableTimes,
  SGFormPreTourMessage,
  SGFormNotifiedStaff,
} from '../../../common';
import ModalAlert from '../../../../../../../../../common/ModalAlert';

import { useSGFormAutomatedCreate } from '../hooks';
import Icon from '../../../../../../../../../common/icons/icon';

const SGToursFormAutomatedCreate = ({ history, match }) => {
  const { propertyId } = match.params;

  const { onSubmit, onCancel, isAlertCancel, setIsAlertCancel } = useSGFormAutomatedCreate(propertyId, history);

  return (
    <div className="tours-automated container">
      <SGPageHeader
        topRow={<SGBtnBack btnTitle="Return to previous page" />}
        title="Self-Guided Tour Setup"
        subTitle="The Self-Guided Tours feature allows prospective residents to schedule a tour on their own without property staff involvement. They’ll automatically receive a community access code and door codes for model units."
      />
      <SGTourForm onSubmit={onSubmit} onCancel={onCancel}>
        <SGFormBlock
          number="1"
          type="units"
          required
          title="Select which units you want to make model units"
          subTitle="The vacant units you select will become the model
         units potential residents can tour. You can also select any
         vacant units that you would like to make tourable.
         This units are subject to change frequently."
        >
          <SGFormUnits />
        </SGFormBlock>
        <SGFormBlock
          number="2"
          type="profile"
          required
          title="Select an access profile"
          subTitle="The access profile selected will define authorized and restricted areas on your property to the potential
            residents. You can select a default access profile or you can create a new one."
        >
          <SGFormUnitsStaff />
        </SGFormBlock>
        <SGFormBlock number="3" type="availabilityTimes" required title="Select self-guided tour availability times">
          <SGFormAvailableTimes />
        </SGFormBlock>
        <SGFormBlock
          number="4"
          type="pretourMessage"
          title="Write pre-tour message to prospective resident"
          subTitle="This message will be sent to the prospective resident when
        they register for a self-guided tour. You can add information they
        should know before the tour, such as directions, parking instructions,
         and community details."
        >
          <SGFormPreTourMessage />
        </SGFormBlock>

        <SGFormBlock
          required
          number="5"
          type="notifiedStaff"
          title="Choose which staff members get notified"
          subTitle="Select which staff members get notified through email when
         a new prospective residents fills out a tour form."
        >
          <SGFormNotifiedStaff />
        </SGFormBlock>
        <div className="sg-group-btns container">
          <div className="sg-paper-btns">
            <SGGroupButtons
              isCreateForm={true}
              iconSaveComponent={<Icon icon="ArrowShortRight" />}
              iconCancelComponent={<Icon icon="CancelIcon" />}
              submitButtonTitle="Configure Self-Guided Tours"
              cancelButtonTitle="Cancel Configuration"
              onCancel={() => setIsAlertCancel(true)}
            />
            <SGBlockErrors className="m-t15" />
          </div>
        </div>
      </SGTourForm>
      <ModalAlert
        title="Are you sure you want to cancel this Self-Guided Tour configuration?"
        confirmButtonTitle="Yes, Cancel"
        cancelButtonTitle="No, Don’t Cancel"
        handleConfirm={onCancel}
        handleCancel={() => setIsAlertCancel(false)}
        visible={isAlertCancel}
        icon={<Icon icon="DenyIcon" />}
      />
    </div>
  );
};

export default withRouter(SGToursFormAutomatedCreate);
