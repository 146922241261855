import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DetailBit extends Component {
  render() {
    const { label, value } = this.props;

    return (
      <div className="details--bit">
        <div className="details--bit__label">{label}</div>
        <div className="details--bit__value">{value}</div>
      </div>
    );
  }
}

DetailBit.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
};
export default DetailBit;
