import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeTourStatus } from '../../../../../../../../../redux/actions/sgToursActions';

export const useToggleHeader = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const { status, id } = useSelector(state => state.property.tour).toJS();

  const handleSwitch = () => {
    if (status) {
      setModalOpen(true);
    } else {
      dispatch(changeTourStatus(propertyId, id));
    }
  };

  const handleDisableTour = async text => {
    await dispatch(changeTourStatus(propertyId, id, text));
    setModalOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return {
    isModalOpen,
    status,
    handleSwitch,
    handleDisableTour,
    handleModalClose,
  };
};
