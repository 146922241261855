import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../common/icons/icon';

/**
 * If you're building a new component, you should probably use StatelessNotificationStatusIndicator instead, because
 * this component relies on syncing props to internal state, which is unreliable.
 */
class NotificationStatusIndicator extends Component {
  state = { hideNotification: true };

  componentDidUpdate(prevProps) {
    if (prevProps.hideNotification !== this.props.hideNotification) {
      this.setState(() => ({ hideNotification: this.props.hideNotification }));
    }
  }
  handleClick = () => {
    const { updateFunc } = this.props;
    if (updateFunc) {
      return updateFunc();
    }
    this.setState(() => ({ hideNotification: true }));
  };

  renderProperDisplay = () => {
    let className = '',
      msgIcon = '';
    switch (this.props.type) {
      case 'Success':
        className = 'save-message save-message-success';
        msgIcon = 'Check';
        break;
      case 'Failure':
        className = 'save-message save-message-failure';
        msgIcon = 'Error';
        break;
      case 'Informational':
        className = 'save-message save-message--info';
        msgIcon = 'Info';
        break;
      default:
        break;
    }
    return (
      <div className={className}>
        {msgIcon ? <Icon icon={msgIcon} /> : null}
        {this.props.message}
        <button className={'Notification_' + this.props.type} type="button" onClick={this.handleClick}>
          <Icon icon="Close" />
        </button>
      </div>
    );
  };

  render() {
    return (
      <div className="notification-status-indicator__wrapper" hidden={this.state.hideNotification}>
        {this.renderProperDisplay()}
      </div>
    );
  }
}

NotificationStatusIndicator.propTypes = {
  type: PropTypes.oneOf(['Success', 'Failure', 'Informational']),
  hideNotification: PropTypes.bool,
  updateFunc: PropTypes.func,
};
NotificationStatusIndicator.defaultProps = {
  type: 'Informational',
};

export default NotificationStatusIndicator;
