import React from 'react';
import Select from 'react-select';
import { SGAvailableSpots, SGSearch } from '../../../common';

export const SGSortSearchRow = ({
  searchValue,
  sortOption,
  sortOptions,
  total,
  availableSpots,
  handleInputSearchChange,
  handleInputClearValue,
  handleChangeSortOption,
}) => (
  <div className="sg-sort-row">
    <div className="sg-sort-left">
      <div className="sg-sort-total">{total}</div>

      <div className="divider vertical" />
      <SGAvailableSpots availableSpots={availableSpots} />
      <div className="sg-sort-select-container">
        <span className="sg-sort-label">Sort By:</span>
        <Select
          className="sg-sort-select"
          name="sortBy"
          value={sortOption}
          onChange={handleChangeSortOption}
          options={sortOptions}
          searchable={false}
        />
      </div>
    </div>

    <SGSearch
      searchValue={searchValue}
      handleInputSearchChange={handleInputSearchChange}
      handleInputClearValue={handleInputClearValue}
    />
  </div>
);
