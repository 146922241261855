import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import PropertyManagerForm from '../../common/PropertyManagerForm';
import Loading from '../../../../../../../../common/Loading/index';

class AddPropertyManager extends Component {
  componentDidMount() {
    const { getProfiles, property } = this.props;

    if (property.get('clubhouse_panel_id') !== null) {
      getProfiles();
    }
    // this.props.getRolesByRoleLevel('PROPERTY_MANAGER');
    this.props.getRolesByProperty(property.get('id'));
  }
  render() {
    const {
      addPropertyManager,
      removePropertyManager,
      accessControlProfilesLoaded,
      accessControlProfileOptions,
      accessControlProfileDefaults,
      property,
      roleOptions,
      userPermissionList,
    } = this.props;

    if (property.get('clubhouse_panel_id') !== null && !accessControlProfilesLoaded) {
      return <Loading />;
    }

    return (
      <PropertyManagerForm
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          company_name: '',
          approved_purchaser: false,
          phone_country_code: '1',
          profiles: accessControlProfileDefaults,
          enable_new_discussion_board_post_email: false,
          enable_daily_discussion_board_activity_email: false,
          role_id: '',
        }}
        roleOptions={roleOptions}
        handleSubmit={addPropertyManager}
        handleRemove={removePropertyManager}
        profileOptions={accessControlProfileOptions}
        propertyHasMasterPanel={property.get('clubhouse_panel_id') !== null}
        propertyHasAccessControl={property.get('admin_enabled_access_control')}
        userPermissionList={userPermissionList}
      />
    );
  }
}

AddPropertyManager.propTypes = {
  property: PropTypes.instanceOf(Immutable.Map).isRequired,
  propertyManagers: PropTypes.object.isRequired,
};

export default AddPropertyManager;
