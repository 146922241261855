import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import Icon from '../../common/icons/icon';
import classNames from 'classnames';
import logo from '../../static/logo.png';
import Sidebar from '../../common/Sidebar';
import UserMenu from '../../common/UserMenu';
import PrivateSection from '../../common/PrivateSection';

const routes = [
  { route: 'properties', title: 'Properties' },
  { route: 'add-property', title: 'Add Property' },
  { route: 'devices', title: 'Devices' },
  { route: 'access-control', title: 'Access Control' },
  { route: 'offers', title: 'Offers' },
  { route: 'financials', title: 'Financials' },
  { route: 'reports', title: 'Reports' },
  { route: 'admin-management', title: 'Admin Management' },
  { route: 'roles-and-permissions', title: 'Roles & Permissions' },
];

class AdminLayout extends Component {
  state = {
    isMobileNavOpen: false,
  };

  toggleMobileNav = () => {
    this.setState({
      isMobileNavOpen: !this.state.isMobileNavOpen,
    });
  };

  onCloseUnderlay = () => {
    this.setState({
      isMobileNavOpen: false,
    });
  };

  renderUnderlay() {
    if (this.state.isMobileNavOpen) {
      return <button className="underlay" onClick={this.onCloseUnderlay} />;
    }
  }

  renderMobileNavigation() {
    const mobileClasses = {
      drawer__nav: true,
      'drawer__nav--open': this.state.isMobileNavOpen,
    };

    return (
      <div className="navigation--mobile">
        <div className="hamburger" onClick={this.toggleMobileNav}>
          <Icon icon="Hamburger" />
        </div>
        <Link className="navigation--mobile__logo logo" to="/">
          <img src={logo} className="logo-image" alt="Smart Experience logo" />
        </Link>
        <nav className={classNames(mobileClasses)}>
          <div className="hamburger hamburger-open" onClick={this.toggleMobileNav}>
            <Icon icon="Clear" />
          </div>
          <PrivateSection roles={['ADMIN', 'PROPERTY_MANAGER']}>
            <NavLink
              className="drawer__nav--link"
              to="/properties"
              activeClassName="current"
              onClick={this.toggleMobileNav}
            >
              <span className="nav-link">
                <Icon icon="Building" />
                <span>Properties</span>
              </span>
            </NavLink>
          </PrivateSection>
          <PrivateSection roles={['ADMIN']}>
            <NavLink
              className="drawer__nav--link"
              to="/access-control"
              activeClassName="current"
              onClick={this.toggleMobileNav}
            >
              <span className="nav-link">
                <Icon icon="Power" />
                <span>Access Control</span>
              </span>
            </NavLink>
          </PrivateSection>
          <PrivateSection roles={['ADMIN']}>
            <NavLink
              className="drawer__nav--link"
              to="/offers"
              activeClassName="current"
              onClick={this.toggleMobileNav}
            >
              <span className="nav-link">
                <Icon icon="MapMarker" />
                <span>Offers</span>
              </span>
            </NavLink>
          </PrivateSection>
          <PrivateSection roles={['ADMIN']}>
            <NavLink
              className="drawer__nav--link"
              to="/financials"
              activeClassName="current"
              onClick={this.toggleMobileNav}
            >
              <span className="nav-link">
                {/* @DESIGN - financials icon */}
                <Icon icon="MapMarker" />
                <span>Financials</span>
              </span>
            </NavLink>
          </PrivateSection>
          <PrivateSection roles={['ADMIN']}>
            <NavLink
              className="drawer__nav--link"
              to="/reports"
              activeClassName="current"
              onClick={this.toggleMobileNav}
            >
              <span className="nav-link">
                <Icon icon="ReportClipboard" />
                <span>Reports</span>
              </span>
            </NavLink>
          </PrivateSection>
          <PrivateSection roles={['ADMIN']}>
            <NavLink
              className="drawer__nav--link"
              to="/roles-and-permissions"
              activeClassName="current"
              onClick={this.toggleMobileNav}
            >
              <span className="nav-link">
                <Icon icon="RPicongray" />
                <span>Roles and Permissions</span>
              </span>
            </NavLink>
          </PrivateSection>
          <PrivateSection roles={['ADMIN']}>
            <NavLink
              className="drawer__nav--link"
              to="/admin-management"
              activeClassName="current"
              onClick={this.toggleMobileNav}
            >
              <span className="nav-link">
                <Icon icon="Person" />
                <span>Admin Management</span>
              </span>
            </NavLink>
          </PrivateSection>
          <PrivateSection roles={['VENDOR']}>
            <NavLink to="/events" activeClassName="current">
              <span className="nav-link">
                <Icon icon="Building" />
                Events
              </span>
            </NavLink>
          </PrivateSection>
          <PrivateSection roles={['VENDOR']}>
            <NavLink to="/stripe-settings" activeClassName="current">
              <span className="nav-link">
                <Icon icon="Cog" />
                Stripe Settings
              </span>
            </NavLink>
          </PrivateSection>
        </nav>
      </div>
    );
  }

  render() {
    const { children, isAdmin, location, userPermissionList, t } = this.props;

    const pathname = location.pathname.substr(1).split('/')[0];
    const route = routes.find(route => route.route === pathname);
    const title = route ? route.title : null;
    const userPermission = {
      properties: userPermissionList.includes('VIEW_PROPERTY_PAGE'),
      accessControl: userPermissionList.includes('VIEW_ACCESS_CONTROL_PAGE'),
      offers: userPermissionList.includes('VIEW_OFFERS_PAGE'),
      financials: userPermissionList.includes('VIEW_FINANCIALS_PAGE'),
      reports: userPermissionList.includes('VIEW_REPORTS_PAGE'),
      rolesAndPermissions: userPermissionList.includes('VIEW_ROLES_AND_PERMISSIONS_PAGE'),
      // userPermissionList.includes('VIEW_ROLE') ||
      // userPermissionList.includes('VIEW_PERMISSION') ||
      // userPermissionList.includes('VIEW_USER'),
      adminManagement: userPermissionList.includes('VIEW_ADMIN_MANAGEMENT_PAGE'),
    };

    return (
      <div className="app__container">
        {this.renderMobileNavigation()}
        <Sidebar>
          <div className="sidebar__header">
            <Link to="/" className="logo">
              <img src={logo} className="logo-image" alt="Smart Experience logo" />
            </Link>
          </div>
          {userPermission.properties && (
            <PrivateSection roles={['ADMIN', 'PROPERTY_MANAGER']}>
              <NavLink to="/properties" activeClassName="current">
                <span className="nav-link">
                  <Icon icon="Building" />
                  {t('property')}
                </span>
              </NavLink>
            </PrivateSection>
          )}
          {userPermission.accessControl && (
            <PrivateSection roles={['ADMIN']}>
              <NavLink to="/access-control" activeClassName="current">
                <span className="nav-link">
                  <Icon icon="Power" />
                  {t('accessControl')}
                </span>
              </NavLink>
            </PrivateSection>
          )}
          {userPermission.offers && (
            <PrivateSection roles={['ADMIN']}>
              <NavLink to="/offers" activeClassName="current">
                <span className="nav-link">
                  <Icon icon="MapMarker" />
                  {t('offers')}
                </span>
              </NavLink>
            </PrivateSection>
          )}
          {userPermission.financials && (
            <PrivateSection roles={['ADMIN']}>
              <NavLink to="/financials" activeClassName="current">
                <span className="nav-link">
                  <Icon icon="Money" />
                  {t('financials')}
                </span>
              </NavLink>
            </PrivateSection>
          )}
          {userPermission.reports && (
            <PrivateSection roles={['ADMIN']}>
              <NavLink to="/reports" activeClassName="current">
                <span className="nav-link">
                  <Icon icon="ReportClipboard" />
                  {t('reports')}
                </span>
              </NavLink>
            </PrivateSection>
          )}
          {userPermission.rolesAndPermissions && (
            <PrivateSection roles={['ADMIN']}>
              {/*<NavLink to="/roles-and-permissions" activeClassName="current">*/}
              <NavLink
                to={
                  userPermissionList.includes('VIEW_ROLE')
                    ? '/roles-and-permissions'
                    : userPermissionList.includes('VIEW_PERMISSION')
                    ? '/roles-and-permissions/permissions'
                    : userPermissionList.includes('VIEW_USER')
                    ? '/roles-and-permissions/permissions/user'
                    : '/roles-and-permissions'
                }
                activeClassName="current"
              >
                <span className="nav-link">
                  <Icon icon="RPicongray" />
                  {t('rolesAndPermissions')}
                </span>
              </NavLink>
            </PrivateSection>
          )}
          {userPermission.adminManagement && (
            <PrivateSection roles={['ADMIN']}>
              <NavLink to="/admin-management" activeClassName="current">
                <span className="nav-link">
                  <Icon icon="Person" />
                  {t('adminManagement')}
                </span>
              </NavLink>
            </PrivateSection>
          )}
          <PrivateSection roles={['VENDOR']}>
            <NavLink to="/events" activeClassName="current">
              <span className="nav-link">
                <Icon icon="Building" />
                {t('events')}
              </span>
            </NavLink>
          </PrivateSection>
          <PrivateSection roles={['VENDOR']}>
            <NavLink to="/stripe-settings" activeClassName="current">
              <span className="nav-link">
                <Icon icon="Cog" />
                {t('stripeSettings')}
              </span>
            </NavLink>
          </PrivateSection>
        </Sidebar>
        <div className="content-pane">
          <UserMenu isAdmin={isAdmin} title={title} />
          {children}
        </div>
        {this.renderUnderlay()}
      </div>
    );
  }
}

AdminLayout.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
};

export default AdminLayout;
