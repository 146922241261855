import { connect } from 'react-redux';
import { editProperty } from '../../../../redux/actions';
import Settings from './Settings';
import { selectorIsAdmin } from '../../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    isAdmin: selectorIsAdmin(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editProperty: (values, propertyId) => dispatch(editProperty(values, propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
