import React, { Component } from 'react';
import Logout from './Logout';

export default class NoAccess extends Component {
  state = {
    loggingOut: false,
  };

  onClickLogout = () => this.setState({ loggingOut: true });

  render() {
    const { loggingOut } = this.state;

    if (loggingOut) {
      return <Logout />;
    }

    return (
      <div className="res--no-access__wrapper">
        <img src="https://littlebird-static.s3.amazonaws.com/lb_logo.png" alt="smart experience logo" />
        <h1 className="h1">Download Mobile App</h1>
        <p>
          SmartExperience is currently available on Android and iOS. You can download the SmartExperience app for your
          mobile device through the links below.
        </p>
        <div className="no-access__mobile-cta">
          <a href="https://itunes.apple.com/us/app/littlebird-living/id1380917042">
            <img src="https://littlebird-static.s3.amazonaws.com/app-store-badge.png" alt="download on the app store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.levelup.littlebird">
            <img src="https://littlebird-static.s3.amazonaws.com/google-play-badge.png" alt="get it on google play" />
          </a>
        </div>
        <p>
          Trying to login as a Property Manager? Logout below and login using the credentials for your Property
          Management account.
        </p>
        <button className="button" type="button" onClick={this.onClickLogout}>
          Logout
        </button>
      </div>
    );
  }
}
