import React from 'react';
import classNames from 'classnames';

import InfoOutline from '../icons/InfoOutline';
import Error from '../icons/Error';
import Check from '../icons/Check';
import Alert from '../icons/Alert';

const AlertMessage = ({ msg, type, icon }) => {
  const alertMessageClasses = {
    'alert-message': true,
    'alert-message--error': type === 'error',
    'alert-message--warning': type === 'warning',
    'alert-message--success': type === 'success',
    'alert-message--icon': icon,
  };

  return (
    <div className={classNames(alertMessageClasses)}>
      {!type && icon && <InfoOutline />}
      {type === 'error' && icon && <Error />}
      {type === 'warning' && icon && <Alert />}
      {type === 'success' && icon && <Check />}
      <div className="alert-message--content">{msg}</div>
    </div>
  );
};

export default AlertMessage;
