export function trimAndPruneFalsyValues(obj, exceptKeys = []) {
  const pruned = {};

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string' && !exceptKeys.includes(key)) {
      const trimmedValue = obj[key].trim();

      if (trimmedValue) {
        pruned[key] = trimmedValue;
      }
    } else {
      pruned[key] = obj[key];
    }
  });

  return pruned;
}

export function trimAndSetNullFalsyValues(obj) {
  var newObj = {};
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'string') {
      newObj[key] = setFalsyString(obj[key]);
    } else if (Array.isArray(obj[key])) {
      newObj[key] = obj[key].map(value => {
        if (typeof value === 'string') {
          return setFalsyString(value);
        }
        if (typeof value === 'object') {
          return trimAndSetNullFalsyValues(value);
        }
        return value;
      });
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export function setFalsyString(str) {
  if (str === null) {
    return null;
  }
  if (typeof str !== 'string') {
    return str;
  }
  // make falsy string null, otherwise set it to the trimmedString
  const trimmedString = str.trim();
  if (trimmedString.length === 0) {
    return null;
  }
  return trimmedString;
}

export function getPhoneMask(value) {
  return '(999) 999-9999';
}

const uuidV4Matcher = /^[0-9a-f]{8}([:-]?)[0-9a-f]{4}([:-]?)[0-9a-f]{4}([:-]?)[0-9a-f]{4}([:-]?)[0-9a-f]{12}$/i;

export function isValidUUID(string) {
  if (!string) return false;
  return string.match(uuidV4Matcher);
}

export const formatPhoneNumber = phoneString => {
  if (!phoneString) return phoneString;
  return phoneString.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const normalizeUrl = url => {
  const re = new RegExp('^(http|https)://', 'i');
  const trimmedUrl = url.replace(/www\./i, '').toLowerCase();

  return re.test(trimmedUrl) ? trimmedUrl : `http://${trimmedUrl}`;
};
