import React from 'react';
import { Toggle } from 'synfrastructure';
import PropTypes from 'prop-types';
import ToggleScaffold from '../forms/ToggleScaffold';
import SwitchOff from '../icons/SwitchOff';
import SwitchOn from '../icons/SwitchOn';

const Switch = props => {
  return (
    <ToggleScaffold label={props.label} className="toggle--switch">
      <Toggle
        id={props.input.name}
        type="checkbox"
        checked={!!props.input.value}
        onChange={() => {
          props.input.onChange(!props.input.value);
        }}
        disabled={props.disabled || props.input.disabled}
        checkedIcon={<SwitchOn />}
        uncheckedIcon={<SwitchOff />}
      />
    </ToggleScaffold>
  );
};
export default Switch;

Switch.propTypes = {
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};
