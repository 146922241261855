import React from 'react';
import VendorDetailsForm from '../../../../../../../../common/forms/VendorDetailsForm';
import { getUserPermission } from '../../../../../../../../redux/selectors';
import { connect } from 'react-redux';

const EditVendor = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ property, updateVendor, history, vendor, deleteVendor, userPermissionList }) => {
    const userPermission = {
      'Edit/Remove Vendor': userPermissionList.includes('EDIT/REMOVE_VENDOR'),
    };
    if (!vendor) {
      history.push(history.push(`/properties/${property.get('id')}/community/vendors`));
    }

    const deleteFunction = async () => {
      await deleteVendor(vendor.get('id'), property.get('id'));
      history.goBack();
    };

    const isAssignedEvents = vendor?.get('isAssignedEvents');
    return (
      <div>
        <VendorDetailsForm
          submitFunction={values => {
            return updateVendor(vendor.get('id'), values, property.get('id')).then(res => {
              if (res.response.ok) {
                return history.push(`/properties/${property.get('id')}/community/vendors`);
              }
              throw res.json.message;
            });
          }}
          history={history}
          mode="edit"
          initialValues={{
            name: vendor ? vendor.get('name') : '',
          }}
          deleteFunction={deleteFunction}
          isAssignedEvents={isAssignedEvents}
          editRemovePermission={userPermission['Edit/Remove Vendor']}
        />
      </div>
    );
  }
);

export default EditVendor;
