import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, useParams, Switch, Route, Redirect } from 'react-router-dom';
import { Tab, TabsContainer } from '../../../../../../../../../common/Tabs';
import { SGToursFormAutomatedEdit } from '.';
import { getTour } from '../../../../../../../redux/actions/sgToursActions';
import Loading from '../../../../../../../../../common/Loading';
import { SGPendingTab } from './SGPendingTab';
import { SGScheduledTab } from './SGScheduledTab';
import { SGPastTab } from './SGPastTab';
import { SGRejectedTab } from './SGRejectedTab';
import { useTourStatus } from '../hooks';

const tabs = [
  {
    label: 'Pending Tours',
    route: `pending`,
  },
  {
    label: 'Scheduled Tours',
    route: 'scheduled',
  },
  {
    label: 'Past Tours Log',
    route: 'past',
  },
  {
    label: 'Rejected Tours',
    route: 'rejected',
  },
  {
    label: 'Tour Settings',
    route: 'settings',
  },
];

const SGNavTabs = ({ match, location, history }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const { status, tourTabs } = useTourStatus(tabs);

  useEffect(() => {
    dispatch(getTour(propertyId));
    setLoading(false);
  }, [dispatch, propertyId]);

  if (loading) return <Loading />;

  return (
    <div className="sg-nav-tabs">
      <TabsContainer>
        {tourTabs.map((tab, i) => (
          <Tab
            key={tab.label}
            label={tab.label}
            isActive={location.pathname.endsWith(tab.route)}
            onPress={() => history.push(`${match.url}/${tab.route}`)}
          />
        ))}
      </TabsContainer>
      <Switch>
        <Route exact path={match.path} render={() => <Redirect to={`${match.url}/${tourTabs[0].route}`} />} />
        {status && <Route exact path={`${match.path}/pending`} component={SGPendingTab} />}
        {status && <Route exact path={`${match.path}/scheduled`} component={SGScheduledTab} />}
        <Route exact path={`${match.path}/past`} component={SGPastTab} />
        <Route exact path={`${match.path}/rejected`} component={SGRejectedTab} />
        <Route path={`${match.path}/settings`} component={SGToursFormAutomatedEdit} />
        <Route path="*" render={() => <Redirect to={`${match.url}/${tourTabs[0].route}`} />} />
      </Switch>
    </div>
  );
};

SGNavTabs.propTypes = {
  match: PropTypes.object,
};

export default withRouter(SGNavTabs);
