import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../../../../../common/icons/icon';

export const SGToursReady = ({ match }) => (
  <div className="tours-ready">
    <section className="container">
      <div className="paper tours-ready_container text-center tours-ready_shadow">
        <h1 className="h1 strong">Ready to set up Self-Guided tours?</h1>
        <h5 className="p h5 m-t25 ">
          The Self-Guided Tours feature allows prospective residents to schedule a tour on their own without property
          staff involvement. They’ll automatically receive a community access code and door codes for model units.
        </h5>
        <h5 className="font-light h5 m-tb20">
          Design your self-guided tour experience with availability schedules and custom messaging.
        </h5>
        <Link
          className="button button--icon self-guided_button"
          to={`/properties/${match.params.propertyId}/units/tours/automated/create`}
        >
          <div className="button--children">
            <span>Configure self-guided tour</span>
            <Icon icon="ArrowShortRight" />
          </div>
        </Link>
      </div>
    </section>
  </div>
);
