import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Yup from 'yup';

import AdminLayout from '../../layouts/AdminLayout';
import Loading from '../../common/Loading';
import EditPropertyForm from './EditPropertyForm';
import { trimAndPruneFalsyValues } from '../../common/utils/helpers';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(64, 'Name must be 64 characters or less')
    .required('Please enter a name'),
  property_type: Yup.string().required('Please select a property type'),
  levelup_property_id: Yup.number()
    .typeError('ID must be a number')
    .nullable(true),
  address: Yup.string()
    .trim()
    .max(255, 'Address must be 255 characters or less'),
  city: Yup.string()
    .trim()
    .max(255, 'City must be 255 characters or less'),
  state: Yup.string().trim(),
  zip: Yup.string().min(5, 'Invalid ZIP'),
  dmp_customer_id: Yup.number()
    .typeError('ID must be a number')
    .moreThan(0, 'DMP Customer ID must be a number greater than 0'),
  total_deferred_monthly_limit: Yup.number()
    .typeError('The limit must be a number')
    .min(0)
    .max(99999999)
    .nullable(true),
  monthly_deferred_payment_limit: Yup.number()
    .typeError('The limit must be a number')
    .min(0)
    .max(99999999)
    .nullable(true),
  admin_enabled_community_views: Yup.boolean(),
  admin_enabled_mailhubs: Yup.boolean(),
  device_change_length: Yup.number()
    .integer()
    .min(1, 'Cannot be less than 1 day')
    .max(999, 'Cannot be more than 999 days'),
});

class EditProperty extends Component {
  state = { submitError: null, videoDoorbells: null };

  componentDidMount() {
    const { getProperty, getCredentialTypes, getUnpairedPropertyPanels, getDevicesForProperty } = this.props;
    getProperty();
    getCredentialTypes();
    getUnpairedPropertyPanels();
    getDevicesForProperty();
  }

  submitFunc = async (values, actions) => {
    const {
      dmp_customer_id,
      name,
      property_type,
      levelup_property_id,
      total_deferred_monthly_limit,
      monthly_deferred_payment_limit,
      credential_types,
      clubhouse_panel_id,
      admin_enabled_unit_automation,
      admin_enabled_access_control,
      admin_enabled_community,
      admin_enabled_community_views,
      admin_enabled_mri,
      admin_enabled_mailhubs,
      enable_tracking,
      enable_video_doorbells,
      enabled_new_device_notifications,
      device_change_length,
      admin_enabled_self_guided_tour,
    } = values;
    const { editProperty, history, adminEditedVideoDoorbellFeature, videoDoorbells } = this.props;
    const submitValues = {
      name,
      property_type,
      levelup_property_id,
      dmp_customer_id,
      total_deferred_monthly_limit,
      monthly_deferred_payment_limit,
      credential_types: credential_types.map(obj => ({ id: obj.id })),
      clubhouse_panel_id,
      admin_enabled_unit_automation,
      admin_enabled_access_control,
      admin_enabled_community,
      admin_enabled_mri,
      admin_enabled_community_views: admin_enabled_community_views && admin_enabled_community,
      admin_enabled_mailhubs: admin_enabled_mailhubs,
      enable_tracking,
      enable_video_doorbells,
      enabled_new_device_notifications,
      device_change_length,
      admin_enabled_self_guided_tour: admin_enabled_self_guided_tour,
    };

    if (!submitValues.admin_enabled_community) {
      submitValues.enabled_messages = false;
    }
    if (!submitValues.admin_enabled_community) {
      submitValues.enabled_events = false;
    }
    if (videoDoorbells && enable_video_doorbells) {
      await adminEditedVideoDoorbellFeature(true);
    } else if (videoDoorbells && !enable_video_doorbells) {
      await adminEditedVideoDoorbellFeature(false);
    }
    editProperty(trimAndPruneFalsyValues(submitValues)).then(action => {
      if (action.response.ok) {
        return history.goBack();
      }
      actions.setSubmitting(false);
      return this.setState({ submitError: action.json.message });
    });
  };

  handleCancel = () => this.props.history.goBack();

  render() {
    const {
      loaded,
      property,
      credentialTypesLoaded,
      credentialTypes,
      unpairedPropertyPanelsLoaded,
      unpairedPropertyPanels,
    } = this.props;

    if (!loaded || !credentialTypesLoaded || !unpairedPropertyPanelsLoaded) {
      return <Loading />;
    }

    const initialValues = {
      name: property.get('name'),
      property_type: property.get('property_type'),
      address: property.get('address'),
      city: property.get('city'),
      state: property.get('state'),
      zip: property.get('zip'),
      levelup_property_id: property.get('levelup_property_id') || '',
      monthly_deferred_payment_limit: property.get('monthly_deferred_payment_limit'),
      total_deferred_monthly_limit: property.get('total_deferred_monthly_limit'),
      dmp_customer_id: property.get('dmp_customer_id') || '',
      credential_types: property.get('credential_types').toJS(),
      clubhouse_panel_id: property.get('clubhouse_panel_id'),
      admin_enabled_unit_automation: property.get('admin_enabled_unit_automation'),
      admin_enabled_access_control: property.get('admin_enabled_access_control'),
      admin_enabled_community: property.get('admin_enabled_community'),
      admin_enabled_mri: property.get('admin_enabled_mri'),
      admin_enabled_community_views: property.get('admin_enabled_community_views'),
      admin_enabled_mailhubs: property.get('admin_enabled_mailhubs'),
      enable_tracking: property.get('enable_tracking'),
      enable_video_doorbells: property.get('enable_video_doorbells'),
      enabled_new_device_notifications: property.get('enabled_new_device_notifications'),
      device_change_length: property.get('device_change_length'),
      admin_enabled_self_guided_tour: property.get('admin_enabled_self_guided_tour'),
    };

    const unpairedPanelOptions = unpairedPropertyPanels.toJS();

    if (property.get('clubhouse_panel')) {
      unpairedPanelOptions.unshift(property.get('clubhouse_panel').toJS());
    }

    return (
      <AdminLayout>
        <div className="page-header">
          <div className="container">
            <div className="page-header__title">
              <h1 className="h1">Property Details</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <EditPropertyForm
            initialValues={initialValues}
            unpairedPropertyPanels={unpairedPanelOptions}
            credentialTypes={credentialTypes}
            validationSchema={validationSchema}
            submitFunc={this.submitFunc}
            submitError={this.state.submitError}
            onCancel={this.handleCancel}
          />
        </div>
      </AdminLayout>
    );
  }
}

EditProperty.propTypes = {
  editProperty: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
};

export default EditProperty;
