import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';

import Loading from '../../../../../../common/Loading';
import Delete from '../../../../../../common/Delete';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../common/Kebab/index';
import Icon from '../../../../../../common/icons/icon';
import UnrecognizedAreas from './UnrecognizedAreas';

class AreasList extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    accessControlAreas: PropTypes.instanceOf(List),
    accessControlAreasLoaded: PropTypes.bool.isRequired,
    getPropertyAreas: PropTypes.func.isRequired,
    getPropertyPanelAreas: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getPropertyAreas();
  }

  state = {
    openKebabDoorId: null,
    deleteConfirmationAreaId: null,
  };

  onKebabClick = areaId => this.setState({ openKebabAreaId: areaId });

  closeKebab = () => this.setState({ openKebabAreaId: null });

  renderKebab(area) {
    const { match, history } = this.props;
    const { openKebabAreaId } = this.state;

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(area.get('id'))}
          onDisengage={this.closeKebab}
          menuOpened={area.get('id') === openKebabAreaId}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={() => {
                history.push(`${match.url}/${area.get('id')}`);
                this.closeKebab();
              }}
            >
              <Icon icon="Edit" />
              Edit
            </div>
            <div
              className="kebab__menu--item"
              onClick={() => this.setState({ deleteConfirmationAreaId: area.get('id'), openKebabAreaId: null })}
            >
              <Icon icon="Remove" />
              Delete
            </div>
          </div>
        </Kebab>
      </div>
    );
  }

  renderDeleteConfirmation = area => {
    const { removeArea } = this.props;

    const hasDoors = area.get('doors') && area.get('doors').size > 0;
    const hasProfiles = area.get('profiles') && area.get('profiles').size > 0;

    const isDisabled = hasDoors || hasProfiles;

    return (
      <div className="areas-list--delete-confirmation__wrapper">
        <Delete
          disabled={isDisabled}
          onCancel={() => this.setState({ deleteConfirmationAreaId: null, openKebabAreaId: null })}
          deleteFunc={async () => {
            const action = await removeArea(area);
            if (!action.response.ok) {
              this.setState({
                error: (action.json && action.json.message) || 'Unknown error occurred while deleting area',
                deleteConfirmationAreaId: null,
                openKebabAreaId: null,
              });
            }
          }}
          message={
            <div>
              {!isDisabled ? (
                <div className="delete-confirmation-title">
                  Are you sure you want to <strong className="strong">remove this area</strong>?
                </div>
              ) : (
                <div className="delete-reqs__wrapper">
                  {hasDoors && (
                    <div>
                      <div className="delete-reqs--title">
                        Can't delete because these doors still reference this area:
                      </div>
                      <div className="delete-reqs--list">
                        {area
                          .get('doors')
                          .toJS()
                          .map(door => (
                            <div className="delete-req" key={door.id}>
                              • {door.name}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  {hasProfiles && (
                    <div>
                      <div className="delete-reqs--title">
                        Can't delete because these profiles still reference this area:
                      </div>
                      <div className="delete-reqs--list">
                        {area
                          .get('profiles')
                          .toJS()
                          .map(profile => (
                            <div className="delete-req" key={profile.id}>
                              • {profile.name}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          }
        />
      </div>
    );
  };

  render() {
    const { match, accessControlAreas, accessControlAreasLoaded, getPropertyPanelAreas } = this.props;
    const { deleteConfirmationAreaId } = this.state;

    if (!accessControlAreasLoaded) {
      return <Loading />;
    }

    return (
      <div className="container areas-list--page">
        <div className="page-header subheader">
          <div className="page-header__title">
            <h4 className="h4">Areas</h4>
          </div>

          <div className="page-header__actions">
            <button
              type="button"
              className="button button--outline"
              onClick={() => {
                this.props.getPropertyAreas();
                getPropertyPanelAreas();
              }}
            >
              Fetch Areas
            </button>
            {accessControlAreas.size > 0 && (
              <Link to={`${match.url}/add-area`} className="button">
                Add New Area
              </Link>
            )}
          </div>
        </div>

        <div className="areas__container">
          <div className="areas--recognized">
            <h4 className="h4">Recognized</h4>
            {accessControlAreas.size > 0 ? (
              <table className="table-units-list">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {accessControlAreas.map(area => (
                    <tr key={area.get('id')}>
                      <td>{area.get('name')}</td>
                      {
                        <td className="table__align-right">
                          {deleteConfirmationAreaId === area.get('id')
                            ? this.renderDeleteConfirmation(area)
                            : this.renderKebab(area)}
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <EmptyPlaceholder buttonText="Add Area" linkTo={`${match.url}/add-area`} />
            )}
          </div>

          <div className="areas--unrecognized">
            <h4 className="h4">Unrecognized</h4>
            <UnrecognizedAreas />
          </div>
        </div>
      </div>
    );
  }
}

export default AreasList;
