import Yup from 'yup';
import moment from 'moment';

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .max(120, 'Title must be 120 characters or fewer')
    .required('Please enter a title'),
  description: Yup.string()
    .trim()
    .max(2000, 'Description must be 2000 characters or fewer')
    .required('Please enter a description'),
  location: Yup.string()
    .trim()
    .max(120, 'Location must be 120 characters or fewer')
    .required('Please enter a location'),
  event_date: Yup.date()
    .test('is-in-future', 'Date must be today or later', value => {
      return moment(value).isSameOrAfter(moment().startOf('day'));
    })
    .required('Please enter an event date'),
  start_time: Yup.string()
    .nullable()
    .required('Please enter a start time')
    .test('is-in-future', 'Start time cannot be in the past', function(value) {
      const eventDate = moment(this.parent.event_date).format('YYYY-MM-DD');
      const eventTime = moment(value).format('HH:mm');
      const eventDateTime = moment(eventDate + ' ' + eventTime);

      return !value || eventDateTime.isSameOrAfter(moment());
    }),
  end_time: Yup.string()
    .nullable()
    .required('Please enter an end time')
    .test('must-be-after-start-time', 'End time must be after start time', function(value) {
      return !moment(this.parent.start_time, 'ddd MMM DD Y HH:mm:ss zZZ').isAfter(
        moment(value, 'ddd MMM DD Y HH:mm:ss zZZ')
      );
    })
    .test('different-than-start-time', 'End time must be different than start time', function(value) {
      return !moment(this.parent.start_time, 'ddd MMM DD Y HH:mm:ss zZZ').isSame(
        moment(value, 'ddd MMM DD Y HH:mm:ss zZZ')
      );
    }),
  image_data: Yup.object().shape({
    result: Yup.string().nullable(),
    filename: Yup.string().nullable(),
    filetype: Yup.string().nullable(),
    src: Yup.string(),
    error: Yup.string().nullable(),
  }),
  min_attendees: Yup.number()
    .min(0, 'Please enter a number between 0-10000')
    .max(10000, 'Please enter a number between 0-10000')
    .required('Please enter a number')
    .test('valid_min_max_range', 'Min attendees cannot be greater than max', function(value) {
      return value <= this.parent.max_attendees;
    }),
  max_attendees: Yup.number()
    .min(1, 'Please enter a number between 1-10000')
    .max(10000, 'Please enter a number between 1-10000')
    .required('Please enter a number'),
  waitlist_allowed: Yup.boolean(),
  pending_duration: Yup.number().when('waitlist_allowed', {
    is: val => {
      return val === true;
    },
    then: Yup.number()
      .min(1, 'Please enter a number between 1-12')
      .max(12, 'Please enter a number between 1-12')
      .required('Please enter a number'),
  }),
  rsvp_cutoff_date: Yup.date()
    .test('is-in-future', 'Date must be today or later', value => {
      return moment(value).isSameOrAfter(moment().startOf('day'));
    })
    .test('valid_date_range', 'Cut-off date cannot exceed the event date', function(value) {
      return moment(value, 'ddd MMM DD Y').isSameOrBefore(moment(this.parent.event_date, 'ddd MMM DD Y'));
    })
    .required('Please enter a cut-off date'),
  rsvp_cutoff_time: Yup.string()
    .nullable()
    .required('Please enter a cut-off time')
    .test('is-in-future', 'RSVP cut-off time cannot be in the past', function(value) {
      const cutoffDate = moment(this.parent.rsvp_cutoff_date).format('YYYY-MM-DD');
      const cutoffTime = moment(value).format('HH:mm');
      const cutoffDateTime = moment(cutoffDate + ' ' + cutoffTime);

      return !value || cutoffDateTime.isSameOrAfter(moment());
    })
    .test('valid_time_range', "Cut-off time cannot exceed the event's start time", function(value) {
      const eventDate = moment(this.parent.event_date).format('YYYY-MM-DD');
      const eventTime = moment(this.parent.start_time).format('HH:mm');
      const eventDateTime = moment(eventDate + ' ' + eventTime);

      const cutoffDate = moment(this.parent.rsvp_cutoff_date).format('YYYY-MM-DD');
      const cutoffTime = moment(value).format('HH:mm');
      const cutoffDateTime = moment(cutoffDate + ' ' + cutoffTime);

      return cutoffDateTime.isSameOrBefore(eventDateTime);
    }),
  price_amount: Yup.number()
    .typeError('Please enter a price for the paid event')
    .test('paid_event_must_have_price', 'Please enter a price for the paid event', function(value) {
      return !this.parent.is_paid_event || parseFloat(value) > 0;
    }),
  confirmation_details: Yup.string().max(2000, 'The confirmation instructions must be less than 2000 characters.'),
  refund_cutoff_date: Yup.date()
    .when('price_amount', {
      is: val => val > 0,
      then: Yup.date().required('Please enter a refund cut-off date.'),
    })
    .nullable()
    .test('is-in-future', 'Refund date must be today or later', function(value) {
      return !this.parent.is_paid_event || !value || moment(value).isSameOrAfter(moment().startOf('day'));
    })
    .test('valid_date_range', 'Refund date must be before or at the event date', function(value) {
      return !this.parent.is_paid_event || moment(value).isSameOrBefore(moment(this.parent.event_date));
    }),
  refund_cutoff_time: Yup.string()
    .nullable()
    .when('price_amount', {
      is: val => val > 0,
      then: Yup.string().required(),
    })
    .test('is-in-future', 'Refund time cannot be in the past', function(value) {
      const cutoffDate = moment(this.parent.refund_cutoff_date).format('YYYY-MM-DD');
      const cutoffTime = moment(value).format('HH:mm');
      const cutoffDateTime = moment(cutoffDate + ' ' + cutoffTime);

      return !this.parent.is_paid_event || !value || cutoffDateTime.isSameOrAfter(moment());
    })
    .test('valid-time-frame', "Refund time must be before or at the event's start time", function(value) {
      const eventDate = moment(this.parent.event_date).format('YYYY-MM-DD');
      const eventTime = moment(this.parent.start_time).format('HH:mm');
      const eventDateTime = moment(eventDate + ' ' + eventTime);

      const cutoffDate = moment(this.parent.refund_cutoff_date).format('YYYY-MM-DD');
      const cutoffTime = moment(value).format('HH:mm');
      const cutoffDateTime = moment(cutoffDate + ' ' + cutoffTime);

      return !this.parent.is_paid_event || cutoffDateTime.isSameOrBefore(eventDateTime) || !this.parent.is_paid_event;
    }),
  cancel_fee_percent: Yup.number()
    .typeError('Please enter a cancelation fee %.')
    .when('price_amount', {
      is: val => val > 0,
      then: Yup.number()
        .typeError('Please enter a cancelation fee %.')
        .required('Please enter a cancelation fee %.'),
    })
    .min(0, 'Please enter a cancelation fee % between 0-100')
    .max(100, 'Please enter a cancelation fee % between 0-100')
    .nullable(),
  vendor_id: Yup.string().nullable(),
  community_fee_percent: Yup.number()
    .typeError('Please enter a community fee %.')
    .when('vendor_id', {
      is: val => !!val,
      then: Yup.number()
        .typeError('Please enter a community fee %.')
        .required('Please enter a community fee %.'),
    })
    .min(0, 'Please enter a community fee % between 0-100')
    .max(100, 'Please enter a community fee % between 0-100')
    .nullable(),
});
