import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
class AmenitiesForm extends Component {
  handleCancel = () => {
    this.props.switchMode('view');
    if (!this.props.shortenItem) this.props.toggleShortenItem();
  };

  render() {
    const { data, editAmenities, switchMode, validationSchema } = this.props;
    return (
      <Fragment>
        <Formik
          initialValues={{
            name: data.get('name'),
            description: data.get('description'),
            terms_conditions: data.get('terms_conditions'),
            id: data.get('id'),
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const payload = {
              name: values.name,
              description: values.description,
              terms_conditions: values.terms_conditions,
            };
            return editAmenities(data.get('property_id'), values.id, payload)
              .then(() => {
                return switchMode('view');
              })
              .catch(err => actions.setErrors({ submitting: 'Update Failed' }));
          }}
          render={({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="add-amenity-form">
              <div className="paper radius-top-left radius-top-right">
                <InputScaffold label="Name" required validation={errors.name}>
                  <input type="text" name="name" onChange={handleChange} value={values.name} />
                </InputScaffold>

                <InputScaffold label="Description" validation={errors.description}>
                  <textarea name="description" onChange={handleChange} value={values.description} />
                </InputScaffold>

                <InputScaffold label="Terms and Conditions" validation={errors.terms_conditions}>
                  <textarea name="terms_conditions" onChange={handleChange} value={values.terms_conditions} />
                </InputScaffold>
              </div>

              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <div>{errors.submitting}</div>
                <button type="submit" disabled={isSubmitting || Object.keys(errors).length} className="button">
                  Update
                </button>
                <button className="button button--secondary" onClick={this.handleCancel}>
                  Cancel
                </button>
              </div>
            </form>
          )}
        />
      </Fragment>
    );
  }
}

AmenitiesForm.propTypes = {
  data: PropTypes.object,
  editAmenities: PropTypes.func.isRequired,
  shortenItem: PropTypes.bool.isRequired,
  switchMode: PropTypes.func.isRequired,
  toggleShortenItem: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default AmenitiesForm;
