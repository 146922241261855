import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import NotFound from '../../../../common/NotFound';
import PostList from './views/PostList';
import ViewPost from './views/ViewPost';
import CreatePost from './views/CreatePost';

class Discussion extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  render() {
    const { match, property } = this.props;
    if (!property.get('enabled_discussion')) {
      return <NotFound />;
    }
    return (
      <div>
        <Switch>
          <Route exact path={`${match.path}/`} component={PostList} />
          <Route exact path={`${match.path}/add`} component={CreatePost} />
          <Route exact path={`${match.path}/:postId`} component={ViewPost} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default Discussion;
