import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Yup from 'yup';
import { trimAndPruneFalsyValues } from '../../../../common/utils/helpers';
import InputScaffold from '../../../../common/forms/InputScaffold';
import Delete from '../../common/Delete/index';
import AlertMessage from '../../../../common/AlertMessage';
import moment from 'moment';
import Select from 'react-select';

const validationSchema = Yup.object().shape({
  role_name: Yup.string()
    .trim()
    .max(32, 'ROLE NAME must be 64 characters or less')
    .required('Please enter a role name'),
  role_level: Yup.string()
    .trim()
    .required('Please select a role level'),
  role_description: Yup.string()
    .trim()
    .max(255, 'ROLE DESCRIPTION must be 255 characters or less')
    .required('Please enter a role description'),
});

class RolesForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    removeRole: PropTypes.func,
    updateRoleList: PropTypes.func,
    updateRoleDel: PropTypes.func,
  };

  state = {
    confirmDelete: false,
    confirmSave: false,
    delError: '',
    roleLevelError: false,
    property_select_show: false,
  };

  onSubmit = (values, { setSubmitting }) => {
    const { onSubmit } = this.props;
    this.setState({ confirmSave: true });
    onSubmit(trimAndPruneFalsyValues(values)).catch(res => {
      this.setState({ roleLevelError: false });
      setSubmitting(false);
    });
  };

  navigateToList = () => this.props.updateRoleList();

  removeRole = (role, e) => {
    this.props.removeRole(role).then(action => {
      if (action.response.ok) {
        this.props.updateRoleDel();
      } else {
        this.setState({ confirmDelete: false, delError: action.json.message });
      }
    });
  };

  render() {
    const {
      initialValues,
      error,
      id,
      rolesInfo,
      removeRole,
      isAddRole,
      rolesOptions,
      userPermission,
      isChangeRoleLevel,
      propertyOptions,
      property_select_show,
      update_property_select_show,
    } = this.props;
    const { confirmDelete, confirmSave, delError, roleLevelError } = this.state;

    return (
      <div>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            render={({ values, errors, handleChange, handleSubmit, touched, isSubmitting, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="container property-manager-form role-property-manager-form">
                {error && <AlertMessage msg={error} type="error" />}
                {delError && <AlertMessage msg={delError} type="error" />}
                {values.role_name !== '' &&
                  values.role_description !== '' &&
                  !(Object.keys(errors).find(value => value === 'role_level') && Object.keys(errors).length === 1) &&
                  Object.keys(errors).length > 0 && (
                    <AlertMessage msg="The character limit has been reached." type="error" />
                  )}
                <div>
                  <div className="paper radius-top-left radius-top-right RolesAndPermissions-Form-Top">
                    <InputScaffold label="ROLE NAME" required validation={touched.role_name && errors.role_name}>
                      <input
                        type="text"
                        name="role_name"
                        maxLength="32"
                        onChange={({ target }) => {
                          setFieldValue('role_name', target.value);
                          if (isSubmitting) {
                            this.setState({ confirmSave: false });
                          }
                        }}
                        value={values.role_name}
                      />
                    </InputScaffold>
                    <InputScaffold label="ROLE LEVEL" required validation={touched.role_level && errors.role_level}>
                      <Select
                        options={rolesOptions}
                        placeholder={rolesOptions.length ? 'Select a role level' : 'No role available'}
                        onChange={value => {
                          if (isAddRole === 'add' || isChangeRoleLevel) {
                            setFieldValue('role_level', value);
                          } else {
                            this.setState({ roleLevelError: true });
                          }
                          if (value === 'PROPERTY_MANAGER') {
                            update_property_select_show(true);
                            setFieldValue('property_id', '');
                          } else {
                            update_property_select_show(false);
                            setFieldValue('property_id', '');
                          }
                        }}
                        value={values.role_level}
                        simpleValue={true}
                      />
                      {roleLevelError && (
                        <div className="input-validation">Can't change because: • Role has users </div>
                      )}
                    </InputScaffold>
                    {property_select_show && (
                      <InputScaffold label="PROPERTY">
                        <Select
                          options={propertyOptions}
                          placeholder={propertyOptions.length ? 'Select a property' : 'No property available'}
                          onChange={value => {
                            value === null && (value = '');
                            setFieldValue('property_id', value);
                          }}
                          value={values.property_id}
                          simpleValue={true}
                        />
                      </InputScaffold>
                    )}
                    <div>
                      <InputScaffold
                        label="ROLE DESCRIPTION"
                        required
                        validation={touched.role_description && errors.role_description}
                      >
                        <textarea
                          name="role_description"
                          onChange={handleChange}
                          maxLength="255"
                          value={values.role_description === null ? '' : values.role_description}
                        />
                      </InputScaffold>
                      <div />
                    </div>
                  </div>
                </div>
                <div className="paper__footer radius-bottom-left radius-bottom-right RolesAndPermissions-Form-bottom">
                  {isAddRole === 'edit' && !isChangeRoleLevel && (
                    <div className="RolesAndPermissions-Form-RoleLevel">Can't delete because: • Role has users</div>
                  )}
                  {confirmDelete && (
                    <div>
                      <Delete
                        message="Are you sure you want to <b>delete this role</b>?"
                        deleteFunc={this.removeRole.bind(this, id)}
                        onCancel={() => this.setState({ confirmDelete: false })}
                      />
                    </div>
                  )}
                  <div className="From-Button">
                    <div className="From-Save-Button">
                      <button type="submit" disabled={confirmSave || confirmDelete} className="button button-min160">
                        {isAddRole === 'edit' ? 'Save' : 'Create'}
                      </button>
                      <button
                        type="button"
                        className="button button--secondary"
                        onClick={this.navigateToList}
                        disabled={confirmDelete}
                        style={{ marginLeft: '20px' }}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="From-Delete-Button">
                      {isAddRole === 'edit' && userPermission.DeleteRole && removeRole && (
                        <button
                          type="button"
                          className="button button--destructive RolesAndPermissions-Form-Delete"
                          onClick={() => this.setState({ confirmDelete: true })}
                          disabled={!isChangeRoleLevel || confirmDelete}
                        >
                          DELETE ROLE
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="Roles-Permissions_Form-updated" style={{ marginLeft: '-24px', marginTop: '-10px' }}>
                  {rolesInfo &&
                    rolesInfo.get('update_by') &&
                    'Last Modified by: ' +
                      (rolesInfo.getIn(['updateUser', 'first_name']).length > 12
                        ? rolesInfo.getIn(['updateUser', 'first_name']).substr(0, 12) + '...'
                        : rolesInfo.getIn(['updateUser', 'first_name'])) +
                      ' ' +
                      (rolesInfo.getIn(['updateUser', 'last_name']).length > 20
                        ? rolesInfo.getIn(['updateUser', 'last_name']).substr(0, 20) + '...'
                        : rolesInfo.getIn(['updateUser', 'last_name'])) +
                      ' on ' +
                      moment(rolesInfo.get('updated_at')).format('MM/DD/YY')}
                </div>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(RolesForm);
