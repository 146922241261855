export function getApiLoadedReducer(prefix) {
  return function(state = false, action) {
    switch (action.type) {
      case `${prefix}_SUCCESS`:
        return true;
      case `${prefix}_FAILURE`:
      case `${prefix}_ERROR`:
      case `${prefix}_REQUEST`:
      case `${prefix}_RESET`:
        return false;
      default:
        return state;
    }
  };
}

export function getApiLoadingReducer(prefix) {
  return function(state = false, action) {
    switch (action.type) {
      case `${prefix}_REQUEST`:
        return true;
      case `${prefix}_SUCCESS`:
      case `${prefix}_FAILURE`:
      case `${prefix}_ERROR`:
      case `${prefix}_RESET`:
        return false;
      default:
        return state;
    }
  };
}
