import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NotFound from './common/NotFound';
import { Switch, Route } from 'react-router-dom';
import RolesAndPermissionsMain from './views/RolesAndPermissionsMain';

class RolesAndPermissions extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/`} component={RolesAndPermissionsMain} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

RolesAndPermissions.propTypes = {
  match: PropTypes.object.isRequired,
};

export default RolesAndPermissions;
