import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import Loading from '../../../../../../../../common/Loading';
import SubHeader from '../../../../../../../../common/SubHeader';
import EmptyPlaceholder from '../../../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../../../common/Kebab/index';
import Icon from '../../../../../../../../common/icons/icon';
import Delete from '../../../../../../../../common/Delete';
import { getUserPermission } from '../../../../../../../../redux/selectors';
import { connect } from 'react-redux';
import { NonRemovablePopup } from './NonRemovablePopup';

const VendorsList = connect(state => ({ userPermissionList: getUserPermission(state) }))(
  ({ loading, property, match, vendorsList, getVendors, propertyId, history, deleteVendor, userPermissionList }) => {
    const userPermission = {
      Add_Vendor: userPermissionList.includes('ADD_VENDOR'),
      'Edit/Remove Vendor': userPermissionList.includes('EDIT/REMOVE_VENDOR'),
    };
    const hasFetchedVendors = useRef(false);
    useEffect(() => {
      if (!hasFetchedVendors.current) {
        getVendors(propertyId);
        hasFetchedVendors.current = true;
      }
    });

    const [pendingDeleteVendorId, setPendingDeleteVendorId] = useState(null);

    const renderDeleteConfirmation = hasEvent => {
      return hasEvent ? (
        <NonRemovablePopup
          onCancel={() => setPendingDeleteVendorId(null)}
          message="Cannot delete a Vendor that is assigned to Events."
        />
      ) : (
        <Delete
          onCancel={() => setPendingDeleteVendorId(null)}
          deleteFunc={() => {
            deleteVendor(pendingDeleteVendorId, propertyId);
            setPendingDeleteVendorId(null);
          }}
          message={
            <span>
              Are you sure you want to <strong className="strong">remove this Vendor</strong>?
            </span>
          }
        />
      );
    };

    const [openKebabUserId, setOpenKebabUserId] = useState(null);

    const renderKebab = (vendor, editRemoveVendorPermission) => {
      const vendorId = vendor.get('id');
      const isAssignedEvents = vendor.get('isAssignedEvents');

      const editUrl = `${match.url}/${vendorId}/edit`;

      return (
        <div>
          {editRemoveVendorPermission && (
            <Kebab
              onClick={() => setOpenKebabUserId(vendorId)}
              onDisengage={() => setOpenKebabUserId(null)}
              menuOpened={vendorId === openKebabUserId}
            >
              <div className="kebab__menu">
                <div
                  className="kebab__menu--item"
                  onClick={() => {
                    history.push(editUrl, {
                      ...history.location.state,
                      vendor,
                    });
                    setOpenKebabUserId(null);
                  }}
                >
                  <Icon icon="Edit" />
                  Edit
                </div>
                {!isAssignedEvents && (
                  <div
                    className="kebab__menu--item"
                    onClick={() => {
                      setOpenKebabUserId(null);
                      setPendingDeleteVendorId(vendorId);
                    }}
                  >
                    <Icon icon="Remove" />
                    Remove
                  </div>
                )}
              </div>
            </Kebab>
          )}
        </div>
      );
    };

    const renderVendorTable = (propertyId, editRemoveVendorPermission) => {
      return (
        <div className="table-units-list__wrapper unit-table--container">
          <table className="table-units-list">
            <thead>
              <tr>
                <th className="first-column">Vendor</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {vendorsList.map(vendor => {
                const vendorId = vendor.get('id');
                const hasEvent = vendor.get('isAssignedEvents');
                return (
                  <tr key={vendorId}>
                    <td className="first-column">
                      <Link to={`/properties/${propertyId}/community/vendors/${vendorId}/edit`}>
                        {vendor.get('name')}
                      </Link>
                    </td>
                    <td className="table__align-right">
                      {renderKebab(vendor, editRemoveVendorPermission)}
                      {vendorId === pendingDeleteVendorId ? renderDeleteConfirmation(hasEvent) : ''}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    };

    if (loading) {
      return <Loading />;
    }

    return (
      <div className="messages-list__wrapper">
        <div className="container">
          {!vendorsList.size ? (
            <div>
              <SubHeader title="Vendors" />
              <EmptyPlaceholder buttonText="Add Vendor" linkTo={userPermission.Add_Vendor ? `${match.url}/add` : ''} />
            </div>
          ) : (
            <SubHeader
              title={'Vendors'}
              actions={[
                <>
                  {userPermission.Add_Vendor && (
                    <Link className="button" to={`${match.url}/add`}>
                      Add Vendor
                    </Link>
                  )}
                </>,
              ]}
            />
          )}

          {vendorsList.size > 0 && renderVendorTable(propertyId, userPermission['Edit/Remove Vendor'])}
        </div>
      </div>
    );
  }
);

export default VendorsList;
