import { connect } from 'react-redux';
import { getProperties } from '../../../../redux/actions';
import { selectPropertiesForDropdown } from '../../../../redux/selectors';
import DailyReport from './DailyReport';

function mapStateToProps(state, props) {
  return {
    propertiesList: selectPropertiesForDropdown(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProperties: () => dispatch(getProperties()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DailyReport);
