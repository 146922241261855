import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import FeatherCopy from '../../common/icons/FeatherСopy';

export const CopyLinkBtn = ({ title, link }) => {
  const [isCopy, setCopy] = useState(false);
  const linkRef = useRef(null);

  const copyToClipboard = async link => {
    try {
      await navigator.clipboard.writeText(link);
      setCopy(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <button
      type="button"
      ref={linkRef}
      data-link={link}
      className="copy-link-btn"
      onClick={() => copyToClipboard(link)}
    >
      <FeatherCopy />
      <span className="copy-text">Copy link</span>
      <span className="copy-title">{!isCopy ? title : 'Copied!'}</span>
    </button>
  );
};

CopyLinkBtn.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
