import { connect } from 'react-redux';
import TelephoneEntryDeviceAuthTokens from './TelephoneEntryDeviceAuthTokens';
import { createTelephoneEntryDeviceToken, revokeTelephoneEntryDeviceToken } from '../../../../../../../redux/actions';

function mapStateToProps(state, props) {
  return {
    telephoneEntryDeviceAuthTokensLoaded: state.telephoneEntryDeviceAuthTokensLoaded,
    telephoneEntryDeviceAuthTokens: state.telephoneEntryDeviceAuthTokens,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { telephoneEntryDeviceId } = ownProps;
  return {
    createTelephoneEntryDeviceToken: payload =>
      dispatch(createTelephoneEntryDeviceToken(telephoneEntryDeviceId, payload)),
    onRevokeClick: id => dispatch(revokeTelephoneEntryDeviceToken(telephoneEntryDeviceId, id)),
    createToken: expirationDate => dispatch(createTelephoneEntryDeviceToken(telephoneEntryDeviceId, expirationDate)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TelephoneEntryDeviceAuthTokens);
