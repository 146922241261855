import React, { Component } from 'react';
import { Formik } from 'formik';
import InputScaffold from '../../../../../../common/forms/InputScaffold';
import TimePicker from 'rc-time-picker';
import Loading from '../../../../../../common/Loading';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NotificationStatusIndicator from '../../../../../../common/forms/NotificationStatusIndicator';
import { nearestMinutes } from '../../../../../../common/utils/time-utils';

const TIME_VALUE_KEYS = ['lights_out', 'lock_doors', 'set_thermostat'];

class EditVacantUnits extends Component {
  state = {
    error: false,
  };

  componentDidMount() {
    if (!this.props.vacantUnitAutomationLoaded && !this.props.vacantUnitAutomationLoading) {
      this.props.getVacantUnitAutomation();
    }
  }

  getVacantUnitsDisplayPath() {
    return `/properties/${this.props.propertyId}/automation/vacant-units`;
  }

  clearError = () => this.setState({ error: '' });

  onSubmit = values => {
    const payload = {};
    TIME_VALUE_KEYS.forEach(key => {
      if (values[key]) {
        payload[key] = values[key].format('HH:mm');
      } else {
        payload[key] = null;
      }
    });
    if (payload.set_thermostat) {
      payload.thermostat_temp = values.thermostat_temp;
      payload.thermostat_mode = values.thermostat_mode;
      payload.fan_mode = values.fan_mode;
    }
    payload.door_code = values.door_code;
    this.props.setVacantUnitAutomation(payload).then(action => {
      if (action.response.ok) {
        this.props.history.push(this.getVacantUnitsDisplayPath());
      } else {
        const message =
          action.response.status < 400
            ? action.json.message
            : 'Could not process request at this time. Try again later.';
        this.setState({ error: message });
      }
    });
  };

  getInitialValue(key, defaultValue = null) {
    const { vacantUnitAutomation } = this.props;
    if (vacantUnitAutomation && vacantUnitAutomation.get(key)) {
      if (TIME_VALUE_KEYS.indexOf(key) > -1) {
        return moment(vacantUnitAutomation.get(key), 'HH:mm');
      }
      return vacantUnitAutomation.get(key);
    }
    return defaultValue;
  }

  render() {
    const { vacantUnitAutomationLoaded } = this.props;
    if (!vacantUnitAutomationLoaded) {
      return <Loading />;
    }

    return (
      <div className="vacant-units--page container">
        <div className="page-header subheader">
          <h4 className="h4">Vacant Units</h4>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            lights_out: this.getInitialValue('lights_out'),
            lock_doors: this.getInitialValue('lock_doors'),
            set_thermostat: this.getInitialValue('set_thermostat'),
            thermostat_temp: this.getInitialValue('thermostat_temp', 70),
            thermostat_mode: this.getInitialValue('thermostat_mode', 'AUTO'),
            fan_mode: this.getInitialValue('fan_mode', 'AUTO'),
            door_code: this.getInitialValue('door_code', ''),
          }}
          onSubmit={this.onSubmit}
          render={({ values, setFieldValue, handleChange, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="paper radius-top-left radius-top-right">
                  <div className="paper__section">
                    <h4 className="h4">Device Schedules</h4>
                    <InputScaffold label="Lights Out Time">
                      <TimePicker
                        name="lights_out"
                        showSecond={false}
                        value={values.lights_out}
                        onChange={time => {
                          setFieldValue('lights_out', nearestMinutes(30, time));
                        }}
                        format="h:mm a"
                        allowEmpty={true}
                        use12Hours
                        inputReadOnly
                        className="time-picker"
                        minuteStep={30}
                      />
                    </InputScaffold>
                    <InputScaffold label="Lock Doors Time">
                      <TimePicker
                        name="lock_doors"
                        showSecond={false}
                        value={values.lock_doors}
                        onChange={time => {
                          setFieldValue('lock_doors', nearestMinutes(30, time));
                        }}
                        format="h:mm a"
                        allowEmpty={true}
                        use12Hours
                        inputReadOnly
                        className="time-picker"
                        minuteStep={30}
                      />
                    </InputScaffold>
                    <InputScaffold label="Set Thermostat Time">
                      <TimePicker
                        name="set_thermostat"
                        showSecond={false}
                        value={values.set_thermostat}
                        onChange={time => {
                          setFieldValue('set_thermostat', nearestMinutes(30, time));
                        }}
                        format="h:mm a"
                        allowEmpty={true}
                        use12Hours
                        inputReadOnly
                        className="time-picker"
                        minuteStep={30}
                      />
                      <InputScaffold label="Thermostat Temp">
                        <input
                          type="number"
                          min="60"
                          max="93"
                          name="thermostat_temp"
                          value={values.thermostat_temp}
                          onChange={handleChange}
                          disabled={values.set_thermostat === null}
                        />
                      </InputScaffold>
                      <InputScaffold label="Thermostat Mode">
                        <select
                          name="thermostat_mode"
                          value={values.thermostat_mode}
                          onChange={handleChange}
                          disabled={values.set_thermostat === null}
                        >
                          <option value="OFF">Off</option>
                          <option value="AUTO">Auto</option>
                          <option value="HEAT">Heat</option>
                          <option value="COOL">Cool</option>
                        </select>
                      </InputScaffold>
                      <InputScaffold label="Fan Mode">
                        <select
                          name="fan_mode"
                          value={values.fan_mode}
                          onChange={handleChange}
                          disabled={values.set_thermostat === null}
                        >
                          <option value="AUTO">Auto</option>
                          <option value="ON">On</option>
                        </select>
                      </InputScaffold>
                    </InputScaffold>
                  </div>
                  <div className="paper__section">
                    <h4 className="h4">Set Door Code</h4>
                    <InputScaffold label="Door Code">
                      <input
                        type="text"
                        name="door_code"
                        maxLength="4"
                        pattern="^\d{4}$"
                        title="4 digit door code"
                        value={values.door_code}
                        onChange={handleChange}
                      />
                    </InputScaffold>
                  </div>
                </div>
                <div className="paper__footer radius-bottom-left radius-bottom-right">
                  <button className="button" type="submit">
                    Submit
                  </button>
                  <Link to={this.getVacantUnitsDisplayPath()}>
                    <button className="button button--secondary" type="button">
                      Cancel
                    </button>
                  </Link>
                  <NotificationStatusIndicator
                    hideNotification={!this.state.error}
                    message={this.state.error}
                    type="Failure"
                    updateFunc={this.clearError}
                  />
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

export default EditVacantUnits;
