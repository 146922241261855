import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TimePicker from 'rc-time-picker';
import { nearestMinutes } from '../../../../../../../../common/utils/time-utils';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { List, Map, fromJS } from 'immutable';
import moment from 'moment';
import Icon from '../../../../../../../../common/icons/icon';
import {
  clampForMode,
  getDisplayTempForStatus,
  getSetpointValues,
  getTempRangeForMode,
} from '../../../../../../../../common/utils/thermostat-utils';

const MIN_BRIGHTNESS = 0;
const MAX_BRIGHTNESS = 99;
const DEFAULT_TEMP = 70;
const DEFAULT_MODE = 'AUTO';
const DEFAULT_FAN = 'AUTO';
export function getDisplayedBrightness(value) {
  return parseInt((value / MAX_BRIGHTNESS) * 100, 10);
}

class ScheduleDeviceRow extends Component {
  static propTypes = {
    deviceType: PropTypes.oneOf(['THERMOSTAT', 'LIGHT']).isRequired,
    schedule: PropTypes.instanceOf(Map),
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    editing: PropTypes.node,
  };

  constructor(props) {
    super(props);

    if (props.schedule) {
      this.state = {
        days: props.schedule.get('days'),
        time: moment(props.schedule.get('time'), 'HH:mm:ss'),
      };

      if (props.deviceType === 'THERMOSTAT') {
        const displayTemp = getDisplayTempForStatus(props.schedule.get('payload'));
        this.state.payload = fromJS({
          temp: displayTemp === '--' ? DEFAULT_TEMP : displayTemp,
          mode: props.schedule.getIn(['payload', 'mode']) || DEFAULT_MODE,
          fan_mode: props.schedule.getIn(['payload', 'fan_mode']) || DEFAULT_FAN,
        });
      } else {
        this.state.payload = props.schedule.get('payload');
      }
    } else {
      this.state = {
        days: List(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']),
        time: moment('00:00', 'HH:mm'),
      };
      if (props.deviceType === 'THERMOSTAT') {
        this.state.payload = fromJS({
          temp: 70,
          mode: DEFAULT_MODE,
          fan_mode: DEFAULT_FAN,
        });
      } else {
        this.state.payload = fromJS({ level: 50 });
      }
    }
  }

  getDisplayedTemp() {
    if (this.state.payload.get('mode') === 'OFF') {
      return '--';
    }
    return this.state.payload.get('temp');
  }

  incrementTempDisabled() {
    const mode = this.state.payload.get('mode');
    const temp = this.state.payload.get('temp');
    return mode === 'OFF' || temp >= getTempRangeForMode(mode)[1];
  }

  decrementTempDisabled() {
    const mode = this.state.payload.get('mode');
    const temp = this.state.payload.get('temp');
    return mode === 'OFF' || temp <= getTempRangeForMode(mode)[0];
  }

  changeMode = mode => {
    this.setState(prevState => {
      let payload = prevState.payload;
      payload = payload.set('mode', mode);
      payload = payload.set('temp', clampForMode(payload.get('temp'), mode));
      return { payload };
    });
  };

  onSubmit = () => {
    const payload = {
      time: this.state.time.format('HH:mm'),
      days: this.state.days.toJS(),
    };

    if (this.props.schedule) {
      payload.enabled = true;
    }

    if (this.props.deviceType === 'THERMOSTAT') {
      payload.payload = Object.assign(
        { mode: this.state.payload.get('mode'), fan_mode: this.state.payload.get('fan_mode') },
        getSetpointValues(this.state.payload.get('temp'), this.state.payload.get('mode'))
      );
    } else {
      payload.payload = this.state.payload.toJS();
    }

    this.props.onSubmit(payload, this.props.schedule).then(() => {
      this.props.onSuccess();
    });
  };

  onClickDay(value) {
    this.setState(prevState => {
      let newPickedDays = prevState.days.toJS();
      if (prevState.days.indexOf(value) > -1) {
        newPickedDays = newPickedDays.filter(day => day !== value);
      } else {
        newPickedDays.push(value);
      }
      return { days: fromJS(newPickedDays) };
    });
  }

  renderDayPickerDay(label, value) {
    const className = classnames({
      'schedule-day-picker-day': true,
      'schedule-day-picker-day--selected': this.state.days.indexOf(value) > -1,
    });

    return (
      <div key={value} className={className} onClick={() => this.onClickDay(value)}>
        {label}
      </div>
    );
  }

  renderDayPicker() {
    return (
      <div className="schedule--date-picker">
        <div className="schedule-field--label">Days</div>
        <div className="schedule--date-picker__days-list">
          {this.renderDayPickerDay('Su', 'Sun')}
          {this.renderDayPickerDay('M', 'Mon')}
          {this.renderDayPickerDay('T', 'Tue')}
          {this.renderDayPickerDay('W', 'Wed')}
          {this.renderDayPickerDay('Th', 'Thu')}
          {this.renderDayPickerDay('F', 'Fri')}
          {this.renderDayPickerDay('Sa', 'Sat')}
        </div>
      </div>
    );
  }

  renderDeviceSpecificFields() {
    if (this.props.deviceType === 'THERMOSTAT') {
      return (
        <div className="schedule--device-specific-fields ds-therm">
          <div className="device__thermostat-controls--temp-set">
            <div className="schedule-field--label">Temperature</div>
            <div className="device__thermostat-controls--temp">
              <button
                className="button"
                disabled={this.decrementTempDisabled()}
                onClick={() =>
                  this.setState(prevState => ({
                    payload: prevState.payload.set('temp', prevState.payload.get('temp') - 1),
                  }))
                }
              >
                <div className="button--children">
                  <Icon icon="Minus" />
                </div>
              </button>
              <div className="thermostat-controls--temp-digits">{this.getDisplayedTemp()}</div>
              <button
                className="button"
                disabled={this.incrementTempDisabled()}
                onClick={() =>
                  this.setState(prevState => ({
                    payload: prevState.payload.set('temp', prevState.payload.get('temp') + 1),
                  }))
                }
              >
                <div className="button--children">
                  <Icon icon="Plus" />
                </div>
              </button>
            </div>
          </div>
          <div className="device__thermostat-controls--mode">
            <div className="schedule-field--label">Mode</div>
            <div className="input-scaffold">
              <select
                value={this.state.payload.get('mode')}
                onChange={event => {
                  const value = event.target.value;
                  this.changeMode(value);
                }}
              >
                <option value="AUTO">Auto</option>
                <option value="HEAT">Heat</option>
                <option value="COOL">Cool</option>
                <option value="OFF">Off</option>
              </select>
            </div>
          </div>
          <div className="device__thermostat-controls--fan">
            <div className="schedule-field--label">Fan</div>
            <div className="input-scaffold">
              <select
                value={this.state.payload.get('fan_mode')}
                onChange={event => {
                  const value = event.target.value;
                  this.setState(prevState => ({ payload: prevState.payload.set('fan_mode', value) }));
                }}
              >
                <option value="AUTO">Auto</option>
                <option value="ON">On</option>
              </select>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="schedule--device-specific-fields ds-light">
        <div className="schedule-field--label__compound">
          <div className="schedule-field--label">Brightness</div>
          <div>{getDisplayedBrightness(this.state.payload.get('level'))}%</div>
        </div>
        <Slider
          min={MIN_BRIGHTNESS}
          max={MAX_BRIGHTNESS}
          onChange={value =>
            this.setState(prevState => {
              return { payload: prevState.payload.set('level', value) };
            })
          }
          value={this.state.payload.get('level')}
        />
      </div>
    );
  }

  renderTimePicker() {
    return (
      <div className="schedule--time-picker">
        <div className="schedule-field--label">Time</div>
        <TimePicker
          name="time"
          showSecond={false}
          value={this.state.time}
          onChange={time => this.setState({ time: nearestMinutes(30, time) })}
          format="h:mm a"
          allowEmpty={false}
          use12Hours
          inputReadOnly
          className="time-picker"
          minuteStep={30}
        />
      </div>
    );
  }

  render() {
    const { editing } = this.props;
    return (
      <div className="schedule-device-form">
        <h4 className="h4">{editing ? 'Edit Schedule' : 'Add Schedule'}</h4>
        <div className="schedule-device-form--inliner">
          <div className="schedule-device-form__flex">
            {this.renderDayPicker()}
            {this.renderTimePicker()}
            {this.renderDeviceSpecificFields()}
          </div>
          <div className="schedule-device-form__button-wrapper">
            <button className="button button--secondary" onClick={this.props.onCancel}>
              Cancel
            </button>
            <button className="button" onClick={this.onSubmit} disabled={this.state.days.size === 0}>
              Save Schedule
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduleDeviceRow;
