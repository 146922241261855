import React, { useRef, useEffect } from 'react';
import Hls from 'hls.js';

export function HLSVideoPlayer({ url }) {
  const playerRef = useRef(null);

  useEffect(() => {
    const player = playerRef.current;
    player.autoPlay = true;
    const hls = new Hls({
      enableWorker: true,
      enableStreaming: true,
    });
    hls.loadSource(url);
    hls.attachMedia(player);

    return () => {
      try {
        player.pause();
        hls.destroy();
      } catch (e) {
        console.log(e);
      }
    };
  }, [url]);

  return <video controls className="videoCanvas" ref={playerRef} autoPlay={false} />;
}
