import React, { Component } from 'react';

import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css';
import 'react-day-picker/lib/style.css';
import PropTypes from 'prop-types';
import InputScaffold from '../../../../../../../common/forms/InputScaffold';
import Search from '../../../../../../../common/icons/Search';
import Close from '../../../../../../../common/icons/Close';
import Tips from '../../../../../../../common/icons/Tips';
import PermissionsForm from '../../../../../common/PermissionsForm/PermissionsForm';
import GroupForm from '../../../../../common/GroupForm/GroupForm';
import Loading from '../../../../../../../common/Loading';
import moment from 'moment';
import Icon from '../../../../../../../common/icons/icon';
import Kebab from '../../../../../../../common/Kebab';

class PermissionsList extends Component {
  static propTypes = {
    addPermission: PropTypes.func.isRequired,
    editPermission: PropTypes.func.isRequired,
    permissionListLoading: PropTypes.bool,
    addGroup: PropTypes.func.isRequired,
    updateGroup: PropTypes.func.isRequired,
  };
  state = {
    error: null,
    search: '',
    searchType: '',
    tooltip: '',
    type: 'list',
    groupId: '',
    PermissionId: '',
    PermissionName: '',
    scrollTop: 0,
    userPermission: {},
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
  }
  componentDidMount() {
    const { userPermissionList } = this.props;
    const userPermission = {
      CreateNewPermission: userPermissionList.includes('CREATE_NEW_PERMISSION'),
      EditPermission: userPermissionList.includes('EDIT_PERMISSION'),
      CreateNewGroup: userPermissionList.includes('CREATE_NEW_GROUP'),
      EditGroup: userPermissionList.includes('EDIT_GROUP'),
    };
    this.setState({ userPermission: userPermission });

    this.props.getGroup();
    this.props.getGroupPermission();
  }
  onKeyEnter = event => {
    if (event.key === 'Enter') {
      this.onSearchPermissions();
    }
  };

  updatePermissionList = () => {
    this.props.getGroup();
    this.props.getGroupPermission();
    this.setState({
      search: '',
      searchType: '',
      tooltip: '',
      type: 'list',
      groupId: '',
      group: '',
      PermissionId: '',
      PermissionName: '',
      openKebabGroupId: null,
    });
  };
  onSearchPermissions = event => {
    if (this.state.search !== '') {
      this.setState({ searchType: 'close' });
      this.props.getGroupPermissionByName(this.state.search);
    } else {
      this.props.getGroupPermission();
    }
  };
  onUpdateSearch = event => {
    if (event.target.value === '') {
      this.props.getGroupPermission();
      this.setState({ searchType: '' });
    } else {
      this.setState({ searchType: 'search' });
    }
    this.setState({ search: event.target.value });
  };
  onCloseSearch = event => {
    this.setState({ search: '', searchType: '' });
    this.props.getGroupPermission();
  };

  onEditPermissions = (permission, group, e) => {
    this.setState({
      type: 'editPermission',
      error: null,
      PermissionId: permission.get('id'),
      groupId: permission.get('group_id'),
      group: group,
      PermissionName: permission.get('permission_name'),
    });
    this.props.getPermissionById(permission.get('id'));
    e.stopPropagation();
  };
  onScrollHandle = e => {
    const el = e.target;
    // console.log(el.scrollTop)
    this.setState({ scrollTop: el.scrollTop });
  };

  onMouseOver = (id, e) => {
    let scrollTop = e.currentTarget.parentNode.parentNode.parentNode.parentNode.scrollTop;
    this.setState({ tooltip: id, scrollTop: scrollTop });
  };

  onKebabClick = userId => this.setState({ openKebabGroupId: userId });
  closeKebab = () => this.setState({ openKebabGroupId: null });

  renderPermission = Permission => {
    const { searchType } = this.state;
    let updated_by = '';
    let new_updated_by = '';
    let updated_at = '';
    for (let i = 0; i < Permission.size; i++) {
      if (Permission.get(i).get('updated_by') !== null) {
        if (Permission.get(i).getIn(['user', 'first_name']) && Permission.get(i).getIn(['user', 'last_name'])) {
          new_updated_by =
            (Permission.get(i).getIn(['user', 'first_name']).length > 12
              ? Permission.get(i)
                  .getIn(['user', 'first_name'])
                  .substr(0, 12) + '...'
              : Permission.get(i).getIn(['user', 'first_name'])) +
            ' ' +
            (Permission.get(i).getIn(['user', 'last_name']).length > 20
              ? Permission.get(i)
                  .getIn(['user', 'last_name'])
                  .substr(0, 20) + '...'
              : Permission.get(i).getIn(['user', 'last_name']));
        }
        if (updated_by === '') {
          updated_by = new_updated_by;
          updated_at = Permission.get(i).get('updated_at');
        } else {
          let d1 = new Date(updated_at).getTime();
          let d2 = new Date(Permission.get(i).get('updated_at')).getTime();
          if (d1 < d2) {
            updated_by = new_updated_by;
            updated_at = Permission.get(i).get('updated_at');
          }
        }
      }
    }
    return (
      <div className="Roles-Permissions_Form-updated-Permissions">
        {searchType !== 'close' &&
          updated_by !== '' &&
          'Last modified by: ' + updated_by + ' on ' + moment(updated_at).format('MM/DD/YY')}
      </div>
    );
  };

  renderGroupPermission = GroupPermission => {
    const { tooltip, scrollTop, openKebabGroupId, searchType, userPermission } = this.state;
    let top = -35 - scrollTop;
    const sty = {
      top: top + 'px',
    };
    // console.log(scrollTop)
    return (
      <div className="GroupPermissions-List">
        {GroupPermission.size > 0 &&
          GroupPermission.map((item, index) => (
            <div className="GroupPermissions-Container" key={index}>
              <div className="GroupPermissions-Group">
                <div className="GroupPermissions-Group-left">
                  <div className="textHidden">{item.get('group')}</div>
                  {/*{item.get('group').length > 17 ? item.get('group').substr(0, 17) + '...' : item.get('group')}*/}
                </div>
                <div className="GroupPermissions-Group-right">
                  {userPermission.EditGroup && searchType !== 'close' && (
                    <Kebab
                      onClick={() => this.onKebabClick(item.get('id'))}
                      onDisengage={this.closeKebab}
                      menuOpened={item.get('id') === openKebabGroupId}
                    >
                      <div className="kebab__menu">
                        <div
                          className="kebab__menu--item"
                          onClick={() =>
                            this.setState({
                              type: 'editGroup',
                              error: null,
                              groupId: item.get('id'),
                              group: item.get('group'),
                            })
                          }
                        >
                          <Icon icon="Edit" />
                          Edit
                        </div>
                      </div>
                    </Kebab>
                  )}
                </div>
              </div>
              <div className="GroupPermissions-Height" ref={this.scrollRef} onScroll={this.onScrollHandle}>
                {item.get('permissions').size > 0 &&
                  item.get('permissions').map((n, i) => (
                    <div className="GroupPermissions-Permissions" key={i}>
                      <div className="button GroupPermissions-button" onMouseOut={() => this.setState({ tooltip: '' })}>
                        <div className="textHidden">{n.get('permission_name')}</div>
                        <div>
                          <u
                            className="GroupPermissions-button-Tips"
                            onMouseOver={this.onMouseOver.bind(this, n.get('id'))}
                          >
                            <Tips fill="#dadada" />
                          </u>
                        </div>
                        <div>
                          {userPermission.EditPermission && (
                            <b
                              className="GroupPermissions-button-Edit"
                              onClick={
                                userPermission.EditPermission
                                  ? this.onEditPermissions.bind(this, n, item.get('group'))
                                  : null
                              }
                            >
                              Edit
                            </b>
                          )}
                        </div>
                      </div>
                      {tooltip === n.get('id') && (
                        <div className="GroupPermissions-button-Tips-Div">
                          <div className="GroupPermissions-button-Tips-Container" style={sty}>
                            <p>{n.get('permission_description')}</p>
                            <div className="GroupPermissions-button-Tips-Arrow" />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              {userPermission.CreateNewPermission && searchType !== 'close' && (
                <div
                  className="button GroupPermissions-button-add"
                  onClick={() =>
                    this.setState({
                      type: 'addPermissions',
                      error: null,
                      groupId: item.get('id'),
                      group: item.get('group'),
                    })
                  }
                >
                  ADD+
                </div>
              )}
              {this.renderPermission(item.get('permissions'))}
            </div>
          ))}
        {GroupPermission.size % 3 > 0 && <div className="GroupPermissions-Container" />}
        {GroupPermission.size % 3 === 1 && <div className="GroupPermissions-Container" />}
      </div>
    );
  };

  render() {
    const { permissionList, inPermissionList, permissionInfo, permissionListLoading, groupOptions, user } = this.props;
    const { error, search, searchType, type, PermissionId, groupId, group, userPermission } = this.state;

    if (!permissionListLoading && userPermission) {
      return <Loading />;
    }
    return (
      <div className="container PR-Container">
        <div className="Roles-Permissions-Permissions">
          {type === 'list' && (
            <div>
              <div className="Roles-Permissions__top">
                <InputScaffold>
                  <input
                    name="RolesAndPermissions"
                    className="RolesAndPermissions"
                    value={search}
                    onChange={this.onUpdateSearch}
                    placeholder="Search Permissions..."
                    autoComplete="off"
                    onKeyPress={this.onKeyEnter}
                    maxLength="25"
                  />
                  <div
                    className="RolesAndPermissions-icons"
                    onClick={searchType !== 'close' ? this.onSearchPermissions : this.onCloseSearch}
                  >
                    {searchType !== 'close' ? <Search /> : <Close />}
                  </div>
                </InputScaffold>
              </div>
              <div className="Permissions-Top">
                {searchType !== 'close' ? (
                  <div className="Permissions-Top-Text">
                    <div className="Roles-Permissions-box__header">Permissions</div>
                    <div className="Roles-Permissions-box__text">
                      Edit, view and add permissions for different property roles.
                    </div>
                  </div>
                ) : (
                  <div className="Permissions-Top-Text">
                    <div className="Roles-Permissions-box__header">Search Results For "{search}" In Permissions</div>
                  </div>
                )}
                <div className="Permissions-Top-Button">
                  {userPermission.CreateNewGroup && searchType !== 'close' && (
                    <button className="button" onClick={() => this.setState({ type: 'addGroup', error: null })}>
                      Create New Group
                    </button>
                  )}
                </div>
              </div>

              {searchType !== 'close'
                ? this.renderGroupPermission(permissionList)
                : this.renderGroupPermission(inPermissionList)}
            </div>
          )}

          {type === 'addPermissions' && (
            <div className="Roles-Permissions-PermissionsForm">
              <div className="Permissions-Top">
                <div className="Permissions-Top-Text">
                  <div className="Roles-Permissions-box__header">Create New Permission</div>
                </div>
              </div>
              <div className="Permissions-Form">
                <PermissionsForm
                  error={error}
                  isAdd="add"
                  groupOptions={groupOptions}
                  permissionList={permissionList}
                  updatePermissionList={this.updatePermissionList}
                  initialValues={{
                    group_id: groupId,
                    permission_name: '',
                    permission_description: '',
                    target: '',
                  }}
                  onSubmit={values => {
                    return this.props.addPermission(values).then(action => {
                      if (action.response.ok) {
                        this.updatePermissionList();
                      } else {
                        this.setState({ error: action.json.message });
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
          {type === 'editPermission' && (
            <div className="Roles-Permissions-PermissionsForm">
              <div className="Permissions-Top">
                <div className="Permissions-Top-Text">
                  <div className="Roles-Permissions-box__header">
                    Edit "{permissionInfo.get('permission_name')}" Permission
                  </div>
                </div>
              </div>
              <div className="Permissions-Form">
                <PermissionsForm
                  error={error}
                  isAdd="edit"
                  groupOptions={groupOptions}
                  updatePermissionList={this.updatePermissionList}
                  initialValues={{
                    group_id: groupId,
                    permission_name: permissionInfo.get('permission_name') || '',
                    permission_description: permissionInfo.get('permission_description') || '',
                    target: permissionInfo.get('target') || '',
                    updated_by: user.get('id'),
                  }}
                  onSubmit={values => {
                    return this.props.editPermission(PermissionId, values).then(action => {
                      if (action.response.ok) {
                        this.updatePermissionList();
                      } else {
                        this.setState({ error: action.json.message });
                      }
                    });
                  }}
                />
              </div>
              <div className="Roles-Permissions_Form-updated">
                {permissionInfo.get('updated_by') &&
                  permissionInfo.get('updated_at') &&
                  'Last Modified by: ' +
                    (permissionInfo.getIn(['updateUser', 'first_name']).length > 12
                      ? permissionInfo.getIn(['updateUser', 'first_name']).substr(0, 12) + '...'
                      : permissionInfo.getIn(['updateUser', 'first_name'])) +
                    ' ' +
                    (permissionInfo.getIn(['updateUser', 'last_name']).length > 20
                      ? permissionInfo.getIn(['updateUser', 'last_name']).substr(0, 20) + '...'
                      : permissionInfo.getIn(['updateUser', 'last_name'])) +
                    ' on ' +
                    moment(permissionInfo.get('updated_at')).format('MM/DD/YY')}
              </div>
            </div>
          )}
          {type === 'addGroup' && (
            <div className="Roles-Permissions-PermissionsForm">
              <div className="Permissions-Top">
                <div className="Permissions-Top-Text">
                  <div className="Roles-Permissions-box__header">Create New Group</div>
                  <div className="Roles-Permissions-box__text">Example: Admin Management, Reports, Local Offers</div>
                </div>
              </div>
              <div className="Permissions-Form">
                <GroupForm
                  error={error}
                  isAdd="add"
                  updatePermissionList={this.updatePermissionList}
                  initialValues={{
                    group: '',
                  }}
                  onSubmit={values => {
                    return this.props.addGroup(values).then(action => {
                      if (action.response.ok) {
                        this.updatePermissionList();
                      } else {
                        this.setState({ error: action.json.message });
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
          {type === 'editGroup' && (
            <div className="Roles-Permissions-PermissionsForm">
              <div className="Permissions-Top">
                <div className="Permissions-Top-Text">
                  <div className="Roles-Permissions-box__header">Edit Group</div>
                  <div className="Roles-Permissions-box__text">Example: Admin Management, Reports, Local Offers</div>
                </div>
              </div>
              <div className="Permissions-Form">
                <GroupForm
                  error={error}
                  isAdd="edit"
                  updatePermissionList={this.updatePermissionList}
                  initialValues={{
                    id: groupId,
                    group: group,
                  }}
                  onSubmit={values => {
                    return this.props.updateGroup(values).then(action => {
                      if (action.response.ok) {
                        this.updatePermissionList();
                      } else {
                        this.setState({ error: action.json.message });
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PermissionsList;
