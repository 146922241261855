import { connect } from 'react-redux';

import SchedulesList from './SchedulesList';
import { getPropertySchedules, getPropertyPanelSchedules, removeSchedule } from '../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlSchedules: state.property.accessControlSchedules,
    accessControlSchedulesLoaded: state.property.accessControlSchedulesLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPropertySchedules: () => dispatch(getPropertySchedules(ownProps.match.params.propertyId)),
    getPropertyPanelSchedules: () => dispatch(getPropertyPanelSchedules(ownProps.match.params.propertyId)),
    removeSchedule: scheduleId => dispatch(removeSchedule(ownProps.match.params.propertyId, scheduleId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulesList);
