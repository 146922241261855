import { connect } from 'react-redux';
import { selectVendor } from '../../../../../../redux/selectors';
import { updateVendor, deleteVendor } from '../../../../../../redux/actions';
import EditVendor from './EditVendor';

function mapStateToProps(state, props) {
  return {
    property: state.property.activeProperty,
    vendor: selectVendor(state, props.match.params.vendorId),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateVendor: (vendorId, data, communityId) => dispatch(updateVendor(vendorId, data, communityId)),
    deleteVendor: (vendorId, communityId) => dispatch(deleteVendor(vendorId, communityId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps);
}

const EditVendorContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditVendor);

export default EditVendorContainer;
