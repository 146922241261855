import React from 'react';

function SGSwitchOff() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="132" height="44" viewBox="0 0 126 44">
      <g fill="none" fillRule="evenodd">
        <rect width="131" height="43" x=".5" y=".5" fill="#F4D7D6" stroke="#DD9F9B" rx="21" />
        <circle cx="23" cy="22" r="18" fill="#FFF" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default SGSwitchOff;
