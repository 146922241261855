import React from 'react';
export default () => (
  <div className="goalie__wrapper">
    <div className="goalie--info-box">
      <svg width="60" height="60">
        <g fill="none" fill-rule="evenodd">
          <rect fill="#FFD900" width="60" height="60" rx="12" />
          <path
            d="M15.412 13.067h-.757v2.095h-.394v-2.095H13.5v-.322h1.912v.322zm.892.076h.012l.79 2.019h.252l.79-2.019h.011v2.019h.395v-2.417h-.601l-.71 1.835-.717-1.835h-.62v2.417h.394l.004-2.019zm27.78 22.304c-1.12 7.087-8.29 12.853-15.987 12.853h-5.79L15.234 54l6.86-43.382A1.918 1.918 0 0 1 23.986 9h7.763c4.462 0 7.855 2.624 8.71 6.284l4.569 2.474-4.834 2.237a10.89 10.89 0 0 1-2.263 3.838c4.39 2.063 6.963 6.495 6.152 11.614zm-17.866-12.96h3.395c2.387 0 4.612-1.788 4.96-3.987.347-2.199-1.316-3.987-3.7-3.987h-3.406l-1.249 7.974zm5.073 5.622h-5.949l-2.304 14.684h5.93c4.396 0 8.494-3.29 9.133-7.342.64-4.052-2.414-7.342-6.81-7.342z"
            fill="#333"
            fill-rule="nonzero"
          />
        </g>
      </svg>
      <h2 className="h2">New Updates are Available</h2>
      <p>This version of SmartExperience needs to be updated.</p>
      <button className="button" onClick={() => window.location.reload(true)}>
        Update Now
      </button>
    </div>
  </div>
);
