import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropertyPanelsList from './views/PropertyPanelsList';
import { getProperties } from '../../../../../../redux/actions';
import Loading from '../../../../../../common/Loading';

class Devices extends Component {
  componentDidMount() {
    this.props.getProperties();
  }
  render() {
    const { match, propertiesLoaded } = this.props;

    if (!propertiesLoaded) {
      return <Loading />;
    }

    return (
      <Switch>
        <Route exact path={`${match.path}`} component={PropertyPanelsList} />
      </Switch>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    propertiesLoaded: state.propertiesLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProperties: () => dispatch(getProperties()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
