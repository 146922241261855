import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Yup from 'yup';
import { getPhoneMask, trimAndSetNullFalsyValues } from '../../../../common/utils/helpers';
import InputScaffold from '../../../../common/forms/InputScaffold';
import AlertMessage from '../../../../common/AlertMessage';
import MaskedInput from 'react-input-mask';
import Select from 'react-select';
import Delete from '../../common/Delete/index';
import moment from 'moment';

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .max(64, 'First Name must be 64 characters or less')
    .required('Please enter a name'),
  last_name: Yup.string()
    .trim()
    .max(64, 'Last Name must be 64 characters or less')
    .required('Please enter a last_name'),
  company_name: Yup.string()
    .trim()
    .max(64),
  email: Yup.string()
    .trim()
    .email('Must be a valid email address')
    .max(255)
    .required('Please enter an email address'),
  phone: Yup.string().matches(/^[1]\d{10}|[0,2-9]\d{9}$/, 'Must be a valid phone number'),
});

class UserForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    updateUserList: PropTypes.func,
    removeUsers: PropTypes.func,
  };

  state = {
    confirmDelete: false,
  };

  onSubmit = (values, { setSubmitting }) => {
    const { onSubmit } = this.props;

    // delete values.property;

    onSubmit(trimAndSetNullFalsyValues(values)).catch(res => {
      setSubmitting(false);
    });
  };

  navigateToList = (afterdelete, payload) => this.props.updateUserList(afterdelete, payload);

  removeUsers = (id, e) => {
    const { role, propertyId, search } = this.props;

    this.props.removeUsers(id).then(() => {
      const uuidValid = '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$';
      const payload = {};

      payload.role_id = role === '' || role === null ? 'all' : role;

      payload.property_id =
        propertyId === '' || propertyId === null || !propertyId.match(uuidValid) ? 'all' : propertyId;

      payload.username = search === '' || search === null ? 'all' : search;

      this.navigateToList(true, payload);
    });
  };

  render() {
    const { initialValues, error, isAdd, roleOptions, userInfo, property, userPermission } = this.props;
    const { confirmDelete } = this.state;
    return (
      <div>
        <Formik
          enbaleReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            handleChange,
            handleSubmit,
            touched,
            isSubmitting,
            setFieldValue,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit} className="property-manager-form">
              {error && <AlertMessage msg={error} type="error" />}
              {/*{Object.keys(errors).length > 0 && <AlertMessage msg={Object.values(errors)[0]} type="error" />}*/}
              <div className="paper radius-top-left radius-top-right  RolesAndPermissions-Form-Top">
                <div className="input-scaffold__wrapper-row">
                  <InputScaffold label="first name" required validation={touched.first_name && errors.first_name}>
                    <input
                      type="text"
                      name="first_name"
                      maxLength="64"
                      onChange={handleChange}
                      value={values.first_name}
                    />
                  </InputScaffold>
                  <InputScaffold label="last name" required validation={touched.last_name && errors.last_name}>
                    <input
                      type="text"
                      name="last_name"
                      maxLength="64"
                      onChange={handleChange}
                      value={values.last_name}
                    />
                  </InputScaffold>
                </div>
                <div className="input-scaffold__wrapper-row">
                  <InputScaffold label="ROLE" validation={touched.role_id && errors.role_id}>
                    <Select
                      options={roleOptions}
                      placeholder={roleOptions.length ? 'Select a role' : 'No role available'}
                      onChange={value => setFieldValue('role_id', value)}
                      value={values.role_id}
                      simpleValue={true}
                    />
                  </InputScaffold>
                  <InputScaffold label="company name" validation={errors.company_name}>
                    <input
                      type="text"
                      name="company_name"
                      maxLength="64"
                      onChange={handleChange}
                      value={values.company_name}
                    />
                  </InputScaffold>
                </div>
                <InputScaffold label="email address" required validation={touched.email && errors.email}>
                  <input
                    type="text"
                    name="email"
                    maxLength="255"
                    onChange={handleChange}
                    value={values.email}
                    disabled={true}
                  />
                </InputScaffold>
                <div className="input-scaffold__wrapper-row">
                  <InputScaffold label="phone number" validation={touched.phone && errors.phone}>
                    <MaskedInput
                      mask={getPhoneMask(values.phone)}
                      name="phone"
                      onChange={event => {
                        setFieldValue('phone', event.target.value.replace(/[^0-9]+/g, ''));
                      }}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                  </InputScaffold>
                  <InputScaffold label="PROPERTY" validation={touched.property && errors.property}>
                    <input
                      type="text"
                      name="property"
                      maxLength="64"
                      placeholder="N/A"
                      onChange={handleChange}
                      value={property}
                      disabled={true}
                    />
                  </InputScaffold>
                </div>
              </div>

              <div className="paper__footer radius-bottom-left radius-bottom-right RolesAndPermissions-Form-bottom">
                {confirmDelete && (
                  <Delete
                    message="Are you sure you want to <b>delete this user</b>?"
                    deleteFunc={this.removeUsers.bind(this, values.id)}
                    onCancel={() => this.setState({ confirmDelete: false })}
                  />
                )}
                <div className="RP-Form-User-Bottom" style={{ marginLeft: 0 }}>
                  <button
                    type="submit"
                    disabled={confirmDelete || isSubmitting || Object.keys(errors).length}
                    className="button button-min160"
                  >
                    {isAdd === 'edit' ? 'Save' : 'Create'}
                  </button>
                  {userPermission.DeleteRolesPermissionsUser && (
                    <button
                      type="button"
                      className="button button--destructive RP-From-Delete"
                      onClick={() => this.setState({ confirmDelete: true })}
                      disabled={confirmDelete}
                      style={{ height: '50px' }}
                    >
                      Delete
                    </button>
                  )}
                  <button
                    type="button"
                    className="button button--secondary"
                    onClick={() => this.navigateToList(false)}
                    style={{ height: '50px' }}
                    disabled={confirmDelete}
                  >
                    Cancel
                  </button>
                </div>
                <div className="RP-Form-User-updated">
                  {userInfo.get('updated_by') &&
                    'Last Modified by: ' +
                      (userInfo.getIn(['edituser', 'first_name']).length > 12
                        ? userInfo.getIn(['edituser', 'first_name']).substr(0, 12) + '...'
                        : userInfo.getIn(['edituser', 'first_name'])) +
                      ' ' +
                      (userInfo.getIn(['edituser', 'last_name']).length > 20
                        ? userInfo.getIn(['edituser', 'last_name']).substr(0, 20) + '...'
                        : userInfo.getIn(['edituser', 'last_name'])) +
                      ' on ' +
                      moment(userInfo.get('updated_at')).format('MM/DD/YY')}
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withRouter(UserForm);
