import React, { useEffect } from 'react';
import SubHeader from '../../../../../common/SubHeader';
import Yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import InputScaffold from '../../../../../common/forms/InputScaffold';
import Icon from '../../../../../common/icons/icon';
import {
  propertySelector,
  communityViewsNotificationContactsSelector,
  communityViewsVideoProcessingServerSelector,
  videoProcessingServerConfigLoadingSelector,
} from '../../../redux/selectors';
import {
  getCommunityViewsNotificationContacts,
  requestVideoProcessingServerConfig,
  updateCommunityViewsNotificationContacts,
} from '../../../redux/actions/communityViewsActions';

const phoneRegex = /^$|^(\+\d{1,3})?\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .test('has-sms', 'Either email or SMS (Phone) number is required', function(value) {
      const { sms } = this.parent;
      if (!sms) {
        return !!value;
      }
      return true;
    })
    .email('Please enter a valid email address'),
  sms: Yup.string()
    .test('has-email', 'Either email or SMS (Phone) number is required', function(value) {
      const { email } = this.parent;
      if (!email) {
        return !!value;
      }
      return true;
    })
    .matches(phoneRegex, 'Please enter a valid SMS number'),
});

const defaultValues = {
  email: '',
  sms: '',
};

export function Notifications() {
  const dispatch = useDispatch();
  const property = useSelector(propertySelector);
  const videoProcessingServer = useSelector(communityViewsVideoProcessingServerSelector);
  const videoProcessingServerLoading = useSelector(videoProcessingServerConfigLoadingSelector);
  const notificationContacts = useSelector(communityViewsNotificationContactsSelector);

  function onSubmit(values, { setSubmitting, resetForm }) {
    setSubmitting(false);
    resetForm();
    const newVals = [
      ...notificationContacts,
      { ...values, video_processing_server_id: videoProcessingServer.get('id') },
    ];
    dispatch(updateCommunityViewsNotificationContacts(newVals, property.get('id')));
  }

  function removeContact(id) {
    return () => {
      dispatch(
        updateCommunityViewsNotificationContacts(
          notificationContacts.filter(notificationContact => notificationContact.id !== id),
          property.get('id')
        )
      );
    };
  }

  useEffect(() => {
    dispatch(requestVideoProcessingServerConfig(property.get('id').toString()));
    dispatch(getCommunityViewsNotificationContacts(property.get('id').toString()));
  }, [property, dispatch]);

  return (
    <div className="container">
      <SubHeader title={'Notifications'} />
      {videoProcessingServerLoading && <span>Loading Community Views Configuration... </span>}
      {!videoProcessingServerLoading && videoProcessingServer ? (
        <div className="paper radius-top-left radius-top-right">
          <Formik
            initialValues={{ ...defaultValues }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
            render={({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              setFieldTouched,
              validateForm,
            }) => (
              <form onSubmit={handleSubmit} className="archived-video-form">
                <SubHeader title="Add Person" paperSubheader underline />
                <InputScaffold label="Email" required validation={touched.email && errors.email}>
                  <input type="email" name="email" maxLength="64" onChange={handleChange} value={values.email} />
                </InputScaffold>
                <InputScaffold label="SMS" required validation={touched.sms && errors.sms}>
                  <input type="tel" name="sms" onChange={handleChange} value={values.sms} />
                </InputScaffold>
                <button type="submit" disabled={isSubmitting} className="button button--outline button--icon">
                  <div className="button--children">
                    <Icon icon="Add" />
                    <span>Add Person</span>
                  </div>
                </button>
              </form>
            )}
          />
          <ul className="notification-recipients--list">
            {notificationContacts.map(notificationSetting => (
              <li className="notification-recipient" key={notificationSetting.id}>
                <div className="details">
                  <div>
                    <span className="detail-label">Email:</span> {notificationSetting.email}
                  </div>
                  <div>
                    <span className="detail-label">SMS:</span> {notificationSetting.sms}
                  </div>
                </div>
                <button
                  className="button button--icon button--secondary--destructive"
                  onClick={removeContact(notificationSetting.id)}
                >
                  <div className="button--children">
                    <Icon icon="Close" />
                    <span>Remove</span>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <span>
          Please configure a valid Video Processing Server host under Settings before adding Notification Contacts.
        </span>
      )}
    </div>
  );
}
