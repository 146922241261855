import React from 'react';

function VideoDoorbell() {
  /* eslint-disable max-len */
  return (
    <svg id="Capa_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="m373.684 511.998h-235.368c-17.899 0-32.409-14.51-32.409-32.409v-284.15l2.351-2.439v-160.589c0-17.899 14.51-32.409 32.409-32.409h229.258c17.899 0 32.409 14.51 32.409 32.409v160.256l3.76 2.772v284.15c-.001 17.899-14.511 32.409-32.41 32.409z"
          fill="#7a6d79"
        />
        <g>
          <g>
            <circle cx="184.496" cy="431.996" fill="#433f43" r="10" />
          </g>
          <g>
            <circle cx="220.248" cy="431.996" fill="#433f43" r="10" />
          </g>
          <g>
            <circle cx="256" cy="431.996" fill="#433f43" r="10" />
          </g>
          <g>
            <circle cx="291.752" cy="431.996" fill="#433f43" r="10" />
          </g>
          <g>
            <circle cx="327.504" cy="431.996" fill="#433f43" r="10" />
          </g>
          <g>
            <circle cx="184.496" cy="464.263" fill="#433f43" r="10" />
          </g>
          <g>
            <circle cx="220.248" cy="464.263" fill="#433f43" r="10" />
          </g>
          <g>
            <circle cx="256" cy="464.263" fill="#433f43" r="10" />
          </g>
          <g>
            <circle cx="291.752" cy="464.263" fill="#433f43" r="10" />
          </g>
          <g>
            <circle cx="327.504" cy="464.263" fill="#433f43" r="10" />
          </g>
        </g>
        <path
          d="m406.089 33.268v445.465c0 18.374-14.894 33.268-33.268 33.268h-20.529c18.374 0 33.268-14.894 33.268-33.268v-445.465c0-18.374-14.894-33.268-33.267-33.268h20.529c18.373 0 33.267 14.894 33.267 33.268z"
          fill="#685e68"
        />
        <path
          d="m406.089 33.268v162.171h-300.178v-162.171c0-18.374 14.894-33.268 33.267-33.268h233.644c18.373 0 33.267 14.894 33.267 33.268z"
          fill="#f3f0f3"
        />
        <path
          d="m406.089 33.268v162.171h-20.529v-162.171c0-18.374-14.894-33.268-33.267-33.268h20.529c18.373 0 33.267 14.894 33.267 33.268z"
          fill="#e2dee2"
        />
        <circle cx="256" cy="97.719" fill="#7a6d79" r="64.154" />
        <path
          d="m320.154 97.719c0 35.434-28.72 64.154-64.154 64.154-11.609 0-22.5-3.09-31.892-8.479 5.112 1.314 10.46 2.012 15.982 2.012 35.434 0 64.154-28.72 64.154-64.154 0-23.824-12.985-44.61-32.262-55.675 27.704 7.093 48.172 32.231 48.172 62.142z"
          fill="#685e68"
        />
        <circle cx="256" cy="97.719" fill="#433f43" r="30.464" />
        <path
          d="m286.465 97.719c0 16.824-13.641 30.465-30.465 30.465-9.177 0-17.409-4.065-22.983-10.48 2.731.811 5.615 1.242 8.612 1.242 16.824 0 30.465-13.642 30.465-30.465 0-7.647-2.813-14.637-7.483-19.985 12.626 3.705 21.854 15.387 21.854 29.223z"
          fill="#353335"
        />
        <path
          d="m330.14 408.385h-148.28c-6.76 0-12.24-5.48-12.24-12.24v-153.858c0-6.76 5.48-12.24 12.24-12.24h148.28c6.76 0 12.24 5.48 12.24 12.24v153.858c0 6.76-5.48 12.24-12.24 12.24z"
          fill="#c4e2ff"
        />
        <path
          d="m342.377 242.615v153.21c0 6.939-5.625 12.564-12.564 12.564h-18.476c6.939 0 12.564-5.625 12.564-12.564v-153.21c0-6.939-5.625-12.564-12.564-12.564h18.476c6.939 0 12.564 5.625 12.564 12.564z"
          fill="#95d2fc"
        />
        <path
          d="m298.273 324.459v-22.976c0-23.034-18.06-42.505-41.085-43.137-23.89-.655-43.462 18.515-43.462 42.257v23.856l-6.417 18.155c-1.962 5.551 2.156 11.374 8.043 11.374h40.648 40.647c5.888 0 10.006-5.823 8.043-11.374z"
          fill="#fec165"
        />
        <path
          d="m296.648 353.986h-18.476c5.892 0 10.008-5.82 8.047-11.373l-6.426-18.158v-22.972c0-20.293-14.011-37.815-33.083-42.126 3.357-.749 6.867-1.109 10.48-1.006 23.024.626 41.079 20.098 41.079 43.132v22.972l6.426 18.158c1.961 5.553-2.155 11.373-8.047 11.373z"
          fill="#fdb441"
        />
        <path
          d="m256.73 380.102h-1.46c-13.625 0-24.67-11.045-24.67-24.67v-1.444h50.8v1.444c0 13.625-11.045 24.67-24.67 24.67z"
          fill="#fea613"
        />
        <path
          d="m281.405 353.986v.718c0 14.022-11.373 25.395-25.405 25.395-3.654 0-7.124-.77-10.265-2.166 8.92-3.931 15.14-12.862 15.14-23.229v-.718z"
          fill="#fe9901"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default VideoDoorbell;
