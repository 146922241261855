import React from 'react';
import {
  SGPageHeader,
  SGTdName,
  SGTdContact,
  SGTdText,
  SGTdFakeBtn,
  SGSortSearchRow,
  useFetchTourGuests,
  useSortSearch,
  useToDetail,
  usePhoneFormating,
} from '../../../common';
import Loading from '../../../../../../../../../common/Loading';

export const SGPendingTab = () => {
  const { isLoad, dataLoaded } = useFetchTourGuests({
    search: '',
    sort_by: 0,
    type: 'pending',
  });
  const { isLoad: isScheduledLoad, dataLoaded: scheduledDataLoaded } = useFetchTourGuests({
    search: '',
    sort_by: 0,
    type: 'scheduled',
  });
  const { goToDetail } = useToDetail();
  const { dataResult, total, ...rest } = useSortSearch(dataLoaded);
  const getFormattedPhone = usePhoneFormating();

  if (isLoad || isScheduledLoad) return <Loading />;

  const availableSpots = 19 - scheduledDataLoaded?.length;
  const fakeBtnTitle = window.innerWidth <= 1400 ? 'Approve or Deny' : 'View to Approve or Deny';

  return (
    <div className="sg-tab-content pending container m-b30">
      <SGPageHeader
        title="Pending Self-Guided Tours"
        subTitle="This is a list of prospective residents who signed up for a tour on the web form. You can view their information
        including identification and contact information. Here you can approve or deny the tour before the potential
        resident has access."
        note="Note: You can only schedule 19 tours."
      />

      <SGSortSearchRow total={`${total} pending`} {...rest} availableSpots={availableSpots} />

      <div className="tab-content">
        <table className="tab-table">
          <thead>
            <tr>
              <th>NAME</th>
              <th>CONTACT</th>
              <th>NOTES</th>
            </tr>
          </thead>
          <tbody>
            {dataResult.map(({ id, first_name, last_name, phone, email, apartment_messages }) => (
              <tr key={id} className="tab-tr" onClick={() => goToDetail('pending', id)}>
                <td className="tab-td">
                  <SGTdName name={`${first_name} ${last_name}`} />
                </td>
                <td className="tab-td">
                  <SGTdContact phone={getFormattedPhone(phone)} email={email} />
                </td>
                <td className="tab-td">
                  <SGTdText text={apartment_messages} />
                </td>
                <td className="tab-td">
                  <SGTdFakeBtn title={fakeBtnTitle} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {!dataResult.length && <div className="empty-text">There are no tours at the moment.</div>}
      </div>
    </div>
  );
};
