import React from 'react';
import ModalAlert from '../../../../../../../../../../common/ModalAlert';
import { SGDisableTourDialog } from '../index';
import { SGToggle } from '../SGToggle';
import { useToggleHeader } from './hooks';

export const SGToggleTour = () => {
  const { isModalOpen, status, handleSwitch, handleDisableTour, handleModalClose } = useToggleHeader();

  return (
    <div className="toggle-section">
      <div className="tours-selector_top p-l20">
        <div className="toggle-flex">
          <h1 className="h5 strong">Self-Guided Tour Availability</h1>
          <SGToggle handleSwitch={handleSwitch} isEnabled={status} titleOn="Enabled" titleOff="Disabled" />
        </div>
        <div className="toggle-container">
          <h5 className="toggle-text m-t10">
            You can enable or disable self-guided tours at any time. Disabling will revoke all scheduled tours and
            prevent new tours from being scheduled.
          </h5>
        </div>
      </div>

      <ModalAlert
        body={<SGDisableTourDialog />}
        confirmButtonTitle="Disable Tours"
        cancelButtonTitle="No, Don’t Disable"
        inputForm
        inputFormPlaceholder="Type your message here…"
        handleConfirm={handleDisableTour}
        handleCancel={handleModalClose}
        visible={isModalOpen}
      />
    </div>
  );
};
