import BatteryLevels from './BatteryLevels';
import { connect } from 'react-redux';
import { updatePanelDevice } from '../../../PropertyDetails/views/Devices/redux/actions';
import { getDevicesForProperty } from '../../../../redux/actions';
import { getUserPermission } from '../../../../../../redux/selectors';

const mapStateToProps = (state, ownProps) => ({
  devices: state.property.propertyDeviceList,
  devicesLoading: state.property.propertyDeviceListLoading,
  property: state.property.activeProperty,
  userPermissionList: getUserPermission(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getDevices: () => dispatch(getDevicesForProperty(ownProps.match.params.propertyId)),
  updatePanelDevice: (panelId, deviceId, values) =>
    dispatch(updatePanelDevice(ownProps.match.params.propertyId, panelId, deviceId, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatteryLevels);
