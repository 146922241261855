import React, { Component } from 'react';
import PropertyGuestForm from '../../components/PropertyGuestForm';
import Loading from '../../../../../../../../common/Loading/index';
import { PropTypes } from 'prop-types';

class AddPropertyGuest extends Component {
  componentDidMount() {
    this.props.getProfiles();
  }
  render() {
    const {
      addPropertyGuest,
      accessControlProfileOptions,
      accessControlProfilesLoaded,
      accessControlProfileDefaults,
      property,
    } = this.props;

    if (!accessControlProfilesLoaded) {
      return <Loading />;
    }

    return (
      <div>
        <PropertyGuestForm
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            phone_country_code: '1',
            profiles: accessControlProfileDefaults,
          }}
          profileOptions={accessControlProfileOptions}
          handleSubmit={addPropertyGuest}
          propertyHasMasterPanel={property.get('clubhouse_panel_id') !== null}
        />
      </div>
    );
  }
}

AddPropertyGuest.propTypes = {
  addPropertyGuest: PropTypes.func.isRequired,
};
export default AddPropertyGuest;
