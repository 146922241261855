import React from 'react';
import { any, string, bool } from 'prop-types';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';

export const SGTooltip = ({ content, children, placement, disabled, arrow, minContent }) => (
  <Tippy
    arrow={arrow}
    placement={placement}
    content={content}
    disabled={disabled}
    className={classNames('tippy', { 'min-content': !minContent })}
  >
    {children}
  </Tippy>
);

SGTooltip.propTypes = {
  content: any.isRequired,
  children: any.isRequired,
  placement: string.isRequired,
  disabled: bool,
  minContent: bool,
};
