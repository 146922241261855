export default {
  main: {
    resident: 'Resident',
    residents: 'Residents',
    placeholder: 'Search Residents',
    clearSearch: 'Clear Search',
    registeredDevices: 'Registered devices', //!
    unregisteredDevices: 'Unregistered devices', //!
    tableHeaders: {
      resident: 'RESIDENT',
      unit: 'UNIT',
      smartHome: 'SMART HOME',
      roommates: 'ROOMMATES',
      linkedDevice: 'Linked Device Switch Request', //!
    },

    noRoommates: 'No roommates',
    acceptDevice: 'Accept Device',
    rejectDevice: 'Reject Device',
    anErrorOccurred: 'An error occurred',
    noResults: 'There are no results for your search criteria.',
  },

  edit: {
    title: 'Edit Resident',
    success: 'Resident Edited Successfully',
    error: 'Problem Editing Resident',
    informationTab: {
      title: 'Resident Information',
    },
    accessCodesTab: {
      title: 'Access Codes',
      generate: 'Generate',
      show: 'Show',
      hide: 'Hide',
      sendReminder: 'Send Reminder',
      reset: 'Reset',
      remove: 'Remove',

      modalAlert: {
        title: 'Remove Access Code?',
        body: 'Are you sure you want to remove this access code?',
        confirmButtonTitle: 'Remove Access Code',
      },

      unit: {
        name: 'Unit Access Code',
        disabledText: 'Unit Missing Smart Lock',
        noCodePlaceholder: 'No Unit Access Code',
      },

      community: {
        name: 'Community Access Code',
        noCodePlaceholder: 'No Community Access Code',
      },
    },
    credentialsTab: {
      title: 'Credentials',
      confirmLeaving: 'Are you sure you want to leave? You have unsaved changes.', //!
      save: 'Save Credentials',
      saving: 'Saving Credentials...',
      success: 'Credentials Edited Successfully',
      error: 'Problem Editing Credentials',
      addCredential: 'Add Credential',
      credentialType: 'CREDENTIAL TYPE',
      errorLength: 'Credential code must be 5 characters',
      code: 'CODE',
      errorRequired: 'Please enter a code',
      note: 'NOTE',
      leave: 'Leave a note',
      remove: 'Remove',
      savingCredentials: 'Saving Credentials...',
    },
  },
};
