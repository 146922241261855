import React from 'react';

import PlaceholderRequestDetailsButton from '../../common/PlaceholderRequestDetailsButton';

import ResidentExperience from '../../common/images/color/resident_experience.png';
import Communication from '../../common/images/color/communication.png';
import TechAmenities from '../../common/images/color/tech_amenities.png';
import EventsBW from '../../common/images/bw/events.png';
import AnnouncementsBW from '../../common/images/bw/announcements.png';
import AmenityBW from '../../common/images/bw/amenity.png';
import LocalDealsBW from '../../common/images/bw/local_deals.png';
import CommunityBoardBW from '../../common/images/bw/community_board.png';

export default function CommunityPlaceholder(props) {
  const learnMoreButton = <PlaceholderRequestDetailsButton feature="Smart Community" />;
  return (
    <div className="smart-placeholder__page smart-home">
      <h1>Smart Community</h1>
      <p className="smart-placeholder--main-p">
        Did you know that SmartExperience also offers smart community features that help your residents feel more
        connected to the place where they live?
      </p>
      <div className="main-points__wrapper">
        <div className="main-points">
          <div className="point--image__container">
            <img src={ResidentExperience} alt="Resident Experience" />
          </div>
          <h4>Seamless resident experience</h4>
          <p className="points-description">
            Create a unified experience by bringing commonly-used community features together in a single app.
          </p>
        </div>
        <div className="main-points">
          <div className="point--image__container">
            <img src={Communication} alt="Communication" />
          </div>
          <h4>Effective communication</h4>
          <p className="points-description">
            Reach residents right on their phones instead of dealing with ignored emails and discarded flyers.
          </p>
        </div>
        <div className="main-points">
          <div className="point--image__container">
            <img src={TechAmenities} alt="Tech Amenities" />
          </div>
          <h4>Modern tech amenities</h4>
          <p className="points-description">
            Enhance resident appeal with integrated smart features extended throughout the entire community.
          </p>
        </div>
      </div>
      <p className="smart-placeholder--main-p second">
        Encourage your residents to connect with neighbors, amenities, and management through in-app smart community
        features.
      </p>
      {learnMoreButton}
      <h2>SmartExperience's Smart Community features include:</h2>
      <div className="secondary-points__wrapper">
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={EventsBW} alt="Events" />
          </div>
          <div>
            <h4>Events</h4>
            <p className="points-description">
              Increase resident engagement in community events through mobile notifications and app-based RSVP.
            </p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={AnnouncementsBW} alt="Announcements" />
          </div>
          <div>
            <h4>Announcements</h4>
            <p className="points-description">
              Boost resident awareness of the latest community news and updates with targeted announcements and mobile
              notifications.
            </p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={AmenityBW} alt="Amenities" />
          </div>
          <div>
            <h4>Amenity Details &amp; Reservations</h4>
            <p className="points-description">Let residents learn more about your community’s amenities.</p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={LocalDealsBW} alt="Local Deals" />
          </div>
          <div>
            <h4>Local Deals</h4>
            <p className="points-description">
              Get your residents connected with their local neighborhood with special offers from merchants near your
              community.
            </p>
          </div>
        </div>
        <div className="secondary-points">
          <div className="point--image__container">
            <img src={CommunityBoardBW} alt="Community Board" />
          </div>
          <div>
            <h4>Community Board</h4>
            <p className="points-description">
              Empower your residents to post items for sale, ask for recommendations, and organize events through a
              convenient community board.
            </p>
          </div>
        </div>
      </div>
      {learnMoreButton}
    </div>
  );
}
