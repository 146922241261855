import React from 'react';
import PropTypes from 'prop-types';

export const SGAvailableSpots = ({ availableSpots }) => {
  const isRender = typeof availableSpots !== 'undefined';
  const spotsTitle = availableSpots !== 1 ? 'spots' : 'spot';

  return isRender ? (
    <>
      <div className="sg-sort-total available">{`${availableSpots} ${spotsTitle} available`}</div>
      <div className="divider vertical"></div>
    </>
  ) : null;
};

SGAvailableSpots.propTypes = {
  availableSpots: PropTypes.number,
};
