import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import PageHeader from '../../../../common/PageHeader';

class ReportList extends Component {
  render() {
    return (
      <div>
        <PageHeader title="">
          <div className="page-header__navigation-links">
            <Link to={`/reports/daily`}>Daily Report</Link>
            <Link to={`/reports/activity-log`}>Activity Log</Link>
          </div>
        </PageHeader>
      </div>
    );
  }
}

const ReportListContainer = connect()(ReportList);

export default withRouter(ReportListContainer);
