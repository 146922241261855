import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import Yup from 'yup';

import InputScaffold from '../../../../../common/forms/InputScaffold';
import Icon from '../../../../../common/icons/icon';

class ProfileForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    submitFunc: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    areas: PropTypes.array.isRequired,
    schedules: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
  };

  handleSubmit = async (values, { setSubmitting, setStatus }) => {
    const { submitFunc, onSuccess, user } = this.props;

    const submitValues = { ...values };

    const isAdmin = !!user.roles.find(r => r.role === 'ADMIN');

    if (!isAdmin) {
      delete submitValues.name;
      delete submitValues.access_areas;
    }

    setStatus(null);
    const action = await submitFunc(submitValues);
    setSubmitting(false);

    if (action.response.ok) {
      onSuccess();
    } else {
      setStatus((action.json && action.json.message) || 'Unknown error');
    }
  };

  render() {
    const { initialValues, onCancel, areas, schedules, user } = this.props;

    const isAdmin = !!user.roles.find(r => r.role === 'ADMIN');

    return (
      <Formik
        initialValues={
          initialValues || {
            name: '',
            access_areas: [],
            schedules: [],
          }
        }
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .trim()
            .min(3)
            .max(32)
            .label('Name')
            .required('Please enter an Access Profile Name'),
          access_areas: Yup.array(),
          schedules: Yup.array(),
        })}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          status,
          handleChange,
          handleSubmit,
          touched,
          isSubmitting,
          isValid,
          setFieldValue,
          setFieldTouched,
        }) => {
          const isDisabled = isSubmitting || Object.keys(errors).length || !isValid;

          return (
            <form onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius-top-right">
                <div className="page-header subheader underline">
                  <h4 className="h4">Access Profile Information</h4>
                </div>
                <div className="profile-form--body">
                  <InputScaffold label="Access Profile Name" required validation={touched.name && errors.name}>
                    <Field type="text" name="name" disabled={!isAdmin} onChange={handleChange} value={values.name} />
                  </InputScaffold>
                  <div className="areas-checkbox-list--container">
                    <div className="page-header subheader underline">
                      <h4 className="h4">Areas</h4>
                    </div>
                    {touched.access_areas && errors.access_areas && (
                      <div className="input-validation">{errors.access_areas}</div>
                    )}
                    {areas.map(option => {
                      const isChecked = !!values.access_areas.find(area => option.id === area.id);

                      return (
                        <div className="checkbox-list--item" key={option.id}>
                          <label>
                            <input
                              type="checkbox"
                              checked={isChecked}
                              disabled={!isAdmin}
                              onChange={() => {
                                if (isChecked) {
                                  setFieldValue(
                                    'access_areas',
                                    values.access_areas.filter(area => option.id !== area.id)
                                  );
                                } else {
                                  setFieldValue('access_areas', values.access_areas.concat(option));
                                }
                                setFieldTouched('access_areas', true);
                              }}
                              name={option.id}
                            />
                            <span htmlFor={option.id} className="label">
                              {option.name}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="schedules-checkbox-list--container">
                    <div className="page-header subheader underline">
                      <h4 className="h4">Schedules</h4>
                    </div>
                    {touched.schedules && errors.schedules && (
                      <div className="input-validation">{errors.schedules}</div>
                    )}
                    {schedules.map(option => {
                      const isChecked = !!values.schedules.find(schedule => option.id === schedule.id);

                      return (
                        <div className="checkbox-list--item" key={option.id}>
                          <label>
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={() => {
                                if (isChecked) {
                                  setFieldValue(
                                    'schedules',
                                    values.schedules.filter(schedule => option.id !== schedule.id)
                                  );
                                } else {
                                  setFieldValue('schedules', values.schedules.concat(option));
                                }
                                setFieldTouched('schedules', true);
                              }}
                              name={option.id}
                            />
                            <span htmlFor={option.id} className="label">
                              {option.name}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <button disabled={isDisabled} className="button btn-save-profile">
                  <Icon icon="SaveIcon" />
                  Save Access Profile
                </button>
                <button type="button" className="button button--secondary btn-cancel-access-profile" onClick={onCancel}>
                  Cancel
                </button>
                {status && <div className="input-validation center">{status}</div>}
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export default ProfileForm;
