import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import PageHeader from '../../../../../../common/PageHeader';
import ScheduleForm from '../../components/ScheduleForm';

class AddSchedule extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    property: PropTypes.instanceOf(Map),
  };

  handleSuccess = () => {
    this.props.history.push('./');
  };

  handleCancel = () => {
    this.props.history.push('./');
  };

  render() {
    const { addSchedule } = this.props;

    return (
      <div>
        <PageHeader title="Add Schedule" white backLink="./" />
        <div className="container">
          <ScheduleForm submitFunc={addSchedule} onCancel={this.handleCancel} onSuccess={this.handleSuccess} />
        </div>
      </div>
    );
  }
}

export default AddSchedule;
