import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import Yup from 'yup';
import Select from 'react-select';

import InputScaffold from '../../../../../common/forms/InputScaffold';
import validNumbers from '../../../../../shared/valid-xr550-device-numbers';

class DoorForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    submitFunc: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    areas: PropTypes.array.isRequired,
  };

  handleSubmit = async (values, { setSubmitting, setStatus }) => {
    const { submitFunc, onSuccess, initialValues } = this.props;

    if (initialValues) {
      delete values.dmp_device_number;
    }

    setStatus(null);
    const action = await submitFunc(values);
    setSubmitting(false);

    if (action.response.ok) {
      onSuccess();
    } else {
      setStatus((action.json && action.json.message) || 'Unknown error');
    }
  };

  render() {
    const { initialValues, onCancel, areas } = this.props;

    return (
      <Formik
        initialValues={
          initialValues || {
            name: '',
            dmp_device_number: '',
            dmp_device_communication_type: 'K',
            serial_num: '',
            strike_delay: '0',
            strike_time: '5',
            areas: [],
          }
        }
        validationSchema={Yup.object().shape({
          dmp_device_communication_type: Yup.string(),
          dmp_device_number: Yup.string()
            .trim()
            .oneOf(validNumbers.map(n => n.toString()))
            .required('Please enter a Device Number')
            .label('Device Number'),
          serial_num: Yup.string().when('dmp_device_communication_type', {
            is: 'W',
            then: Yup.string()
              .trim()
              .label('Serial Number')
              .required('Please enter a Serial Number'),
            otherwise: Yup.string().notRequired(),
          }),
          name: Yup.string()
            .trim()
            .min(3)
            .max(32)
            .label('Name')
            .required('Please enter a Door Name'),
          strike_time: Yup.number()
            .typeError('Strike Time must be a number')
            .integer('Strike Time must be an integer')
            .positive('Strike Time must be positive')
            .required('Please enter a Strike Time')
            .label('Strike Time'),
          strike_delay: Yup.number()
            .typeError('Strike Delay must be a number')
            .integer('Strike Delay must be an integer')
            .positive('Strike Delay must be positive')
            .required('Please enter a Strike Delay')
            .label('Strike Delay'),
          areas: Yup.array().min(1, 'Please select an Area'),
        })}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          status,
          handleChange,
          handleSubmit,
          touched,
          isSubmitting,
          isValid,
          setFieldValue,
          setFieldTouched,
        }) => {
          const isDisabled = isSubmitting || Object.keys(errors).length || !isValid;

          return (
            <form className="door-form" onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius-top-right">
                <div className="page-header subheader underline">
                  <h4 className="h4">Door Information</h4>
                </div>
                <div className="device-form--body">
                  <InputScaffold
                    label="Device Number"
                    required
                    validation={touched.dmp_device_number && errors.dmp_device_number}
                  >
                    <Field
                      disabled={!!initialValues}
                      type="text"
                      name="dmp_device_number"
                      onChange={handleChange}
                      value={values.dmp_device_number}
                    />
                  </InputScaffold>
                  <InputScaffold
                    label="Communication Type"
                    required
                    validation={touched.dmp_device_communication_type && errors.dmp_device_communication_type}
                  >
                    <Select
                      disabled={isSubmitting}
                      clearable={false}
                      closeOnSelect={true}
                      name="dmp_device_communication_type"
                      value={values.dmp_device_communication_type}
                      onChange={selection => {
                        setFieldValue('dmp_device_communication_type', selection.value);
                        setFieldTouched('dmp_device_communication_type', true);
                      }}
                      searchable={false}
                      options={[
                        {
                          label: '734 Module',
                          value: 'K',
                        },
                        {
                          label: '734N/734N-POE Module',
                          value: 'N',
                        },
                        {
                          label: '1134 Module',
                          value: 'W',
                        },
                      ]}
                    />
                  </InputScaffold>
                  {values.dmp_device_communication_type === 'W' && (
                    <InputScaffold label="Serial Number" required validation={touched.serial_num && errors.serial_num}>
                      <Field
                        type="text"
                        name="serial_num"
                        disabled={isSubmitting}
                        onChange={handleChange}
                        value={values.serial_num}
                      />
                    </InputScaffold>
                  )}

                  <InputScaffold label="Door Name" required validation={touched.name && errors.name}>
                    <Field
                      type="text"
                      name="name"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      value={values.name}
                    />
                  </InputScaffold>
                  <InputScaffold label="Strike Time" required validation={touched.strike_time && errors.strike_time}>
                    <Field
                      type="text"
                      name="strike_time"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      value={values.strike_time}
                    />
                  </InputScaffold>
                  <InputScaffold label="Strike Delay" required validation={touched.strike_delay && errors.strike_delay}>
                    <Field
                      type="text"
                      name="strike_delay"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      value={values.strike_delay}
                    />
                  </InputScaffold>
                  <div className="areas-available">
                    <h5 className="h5">
                      Areas Available In Master Panel <span className="required">*</span>
                    </h5>
                    {touched.areas && errors.areas && <div className="input-validation">{errors.areas}</div>}
                    {areas.map(option => {
                      const isChecked = !!values.areas.find(area => option.id === area);

                      return (
                        <div className="checkbox-list--item" key={option.id}>
                          <label>
                            <input
                              type="checkbox"
                              checked={isChecked}
                              disabled={isSubmitting}
                              onChange={() => {
                                if (isChecked) {
                                  setFieldValue(
                                    'areas',
                                    values.areas.filter(area => option.id !== area)
                                  );
                                } else {
                                  setFieldValue('areas', values.areas.concat(option.id));
                                }
                                setTimeout(() => {
                                  setFieldTouched('areas', true);
                                });
                              }}
                              name={option.id}
                            />
                            <span htmlFor={option.id} className="label">
                              {option.name}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <div className="bottom--flex-container">
                  <div className="button__wrapper">
                    <button disabled={isDisabled} className="button">
                      {isSubmitting ? 'Saving Door...' : 'Save Door'}
                    </button>
                    <button type="button" className="button button--secondary" onClick={onCancel}>
                      Cancel
                    </button>
                  </div>
                </div>
                {status && <div className="input-validation center">{status}</div>}
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export default DoorForm;
