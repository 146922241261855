import { connect } from 'react-redux';
import ViewPost from './ViewPost';
import {
  banUserForComment,
  banUserForPost,
  getPost,
  createComment,
  deleteComment,
  deletePost,
  unbanUser,
} from '../../../../../../redux/actions';
import { getUserPermission } from '../../../../../../../../redux/selectors';

const mapStateToProps = (state, ownProps) => ({
  post: state.property.post,
  property: state.property.activeProperty,
  userPermissionList: getUserPermission(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPost: propertyId => dispatch(getPost(propertyId, ownProps.match.params.postId)),
  createComment: (propertyId, payload) => dispatch(createComment(propertyId, ownProps.match.params.postId, payload)),
  deletePost: () => dispatch(deletePost(ownProps.match.params.postId)),
  deleteComment: commentId => dispatch(deleteComment(commentId)),
  banUserForPost: postId => dispatch(banUserForPost(postId)),
  banUserForComment: commentId => dispatch(banUserForComment(commentId)),
  unbanUser: userId => dispatch(unbanUser(userId)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    getPost: () => dispatchProps.getPost(stateProps.property.get('id')),
    createComment: payload => dispatchProps.createComment(stateProps.property.get('id'), payload),
  });

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ViewPost);
