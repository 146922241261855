import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Map } from 'immutable';

import AmenitiesList from './view/AmenitiesList';
import NotFound from '../../../../common/NotFound';

class Amenities extends Component {
  render() {
    const { match } = this.props;

    return (
      <div>
        <Switch>
          <Route exact path={`${match.path}/`} component={AmenitiesList} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

Amenities.propTypes = {
  match: PropTypes.object.isRequired,
  property: PropTypes.instanceOf(Map),
};

export default Amenities;
