import React, { Component } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import PropertyManagerForm from '../../common/PropertyManagerForm';
import Loading from '../../../../../../../../common/Loading/index';
import { CommunityStaffAccessCodes } from '../../../../../../../../common/AccessCodes/CommunityStaffAccessCodes';

class EditPropertyManager extends Component {
  componentDidMount() {
    const { getProfiles, property, getCredentialTypes, getPropertyManager } = this.props;

    if (property.get('clubhouse_panel_id') !== null) {
      getProfiles();
    }
    getCredentialTypes();
    getPropertyManager();
    // this.props.getRolesByRoleLevel('PROPERTY_MANAGER');
    this.props.getRolesByProperty(property.get('id'));
  }

  componentWillUnmount() {
    this.props.resetPropertyManager();
  }

  render() {
    const {
      propertyManager,
      propertyManagerLoaded,
      editPropertyManager,
      credentialTypes,
      removePropertyManager,
      accessControlProfilesLoaded,
      accessControlProfileOptions,
      property,
      roleOptions,
      userPermissionList,
    } = this.props;

    if (!propertyManagerLoaded || (property.get('clubhouse_panel_id') !== null && !accessControlProfilesLoaded)) {
      return <Loading />;
    }

    return (
      <PropertyManagerForm
        initialValues={{
          first_name: propertyManager.getIn(['user', 'first_name']),
          last_name: propertyManager.getIn(['user', 'last_name']),
          email: propertyManager.getIn(['user', 'email']),
          phone: propertyManager.getIn(['user', 'phone']) || '',
          company_name: propertyManager.getIn(['user', 'company_name']) || '',
          approved_purchaser: propertyManager.get('approved_purchaser'),
          phone_country_code: '1',
          enable_new_discussion_board_post_email: propertyManager.get('enable_new_discussion_board_post_email'),
          enable_daily_discussion_board_activity_email: propertyManager.get(
            'enable_daily_discussion_board_activity_email'
          ),
          property_credentials: propertyManager.get('property_credentials')
            ? propertyManager.get('property_credentials').toJS()
            : [],
          profiles: [
            propertyManager.get('access_control_profile_id1'),
            propertyManager.get('access_control_profile_id2'),
            propertyManager.get('access_control_profile_id3'),
            propertyManager.get('access_control_profile_id4'),
          ].filter(p => p !== null),
          role_id: propertyManager.get('user').get('role').size
            ? propertyManager
                .get('user')
                .get('role')
                .get(0)
                .get('id')
            : '',
        }}
        roleOptions={roleOptions}
        handleSubmit={editPropertyManager}
        editing={true}
        credentialTypes={credentialTypes}
        removePropertyManager={removePropertyManager}
        profileOptions={accessControlProfileOptions}
        propertyHasMasterPanel={property.get('clubhouse_panel_id') !== null}
        propertyHasAccessControl={property.get('admin_enabled_access_control')}
        userPermissionList={userPermissionList}
      >
        {property.get('admin_enabled_access_control') ? <CommunityStaffAccessCodes /> : null}
      </PropertyManagerForm>
    );
  }
}

EditPropertyManager.propTypes = {
  property: PropTypes.instanceOf(Map).isRequired,
  propertyManager: PropTypes.object.isRequired,
  removePropertyManager: PropTypes.func.isRequired,
  editPropertyManager: PropTypes.func.isRequired,
  credentialTypes: PropTypes.array.isRequired,
};

export default EditPropertyManager;
