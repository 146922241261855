import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';

import PropertyLayout from '../../layouts/PropertyLayout';
import Community from './views/Community';
import Units from './views/Units';
import Settings from './views/PropertyDetails';
import NotFound from './common/NotFound';
import Loading from '../../common/Loading';
import UnitAutomation from './views/UnitAutomation';
import AccessControl from './views/AccessControl';
import AccessControlPlaceholder from './views/AccessControlPlaceholder';
import CommunityPlaceholder from './views/CommunityPlaceholder';
import UnitAutomationPlaceholder from './views/UnitAutomationPlaceholder';
import { CommunityViews } from './views/CommunityViews/CommunityViews';
import ResidentMessages from './views/ResidentMessages';

class Property extends Component {
  componentDidMount() {
    this.props.getProperty();
    // this.props.getProperty().then(res => {
    //   window.ga('send', 'events', 'category', 'action', {
    //     dimension1: res.json.id,
    //   });
    // });
    this.props.getTour();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.propertyId !== this.props.match.params.propertyId) {
      this.props.getProperty();
      this.props.getTour();
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      // window.ga('set', 'dimension1', this.props.property.get('name'));
    }
  }

  componentWillUnmount() {
    this.props.resetProperty();
  }

  render() {
    const { isAdmin, property, propertyError, loaded, match, propertyCount, userPermissionList } = this.props;

    const userPermission = {
      // Units & Residents
      unitsAndResidents:
        userPermissionList.includes('VIEW_UNITS_LIST_PAGE') || userPermissionList.includes('VIEW_RESIDENT_LIST'),
      UnitList: userPermissionList.includes('VIEW_UNITS_LIST_PAGE'),
      ResidentList: userPermissionList.includes('VIEW_RESIDENT_LIST'),
      // Smart Home
      BatteryLevels: userPermissionList.includes('VIEW_DEVICE_BATTERY_PAGE'),
      VacantUnits: userPermissionList.includes('VIEW_VACANT_UNIT_AUTOMATION_PAGE'),
      // SmartAccess
      PropertyGuests: userPermissionList.includes('VIEW_PROPERTY_GUESTS'),
      ResidentGuests: userPermissionList.includes('VIEW_UNIT_GUESTS'),
      AccessLog: userPermissionList.includes('VIEW_ACCESS_LOG_PAGE'),
      Areas: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      Doors: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      Schedules: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      AccessProfiles: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      AccessPoints: userPermissionList.includes('VIEW_ACCESS_POINTS'),
      AccessSetUp: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),

      // Smart Community
      smartCommunity: userPermissionList.includes('VIEW_SMART_COMMUNITY'),
      Amenities: true,
      Announcements: true,
      Events: true,
      Vendors: true,
      // Settings
      PortalUsers: userPermissionList.includes('VIEW_PORTAL_USERS_PAGE'),
      CommunitySettings: userPermissionList.includes('VIEW_COMMUNITY_SETTINGS_PAGE'),
      ResidentFeatures: userPermissionList.includes('VIEW_RESIDENT_FEATURES_PAGE'),
      Maintenance: userPermissionList.includes('VIEW_MAINTENANCE'),
      UnitGroups: userPermissionList.includes('VIEW_UNIT_GROUPS'),
      MailHubs: userPermissionList.includes('VIEW_MAIL_HUBS'),
      Devices: userPermissionList.includes('VIEW_DEVICES_PAGE'),
      Settings:
        userPermissionList.includes('VIEW_PORTAL_USERS_PAGE') ||
        userPermissionList.includes('VIEW_COMMUNITY_SETTINGS_PAGE') ||
        userPermissionList.includes('VIEW_RESIDENT_FEATURES_PAGE') ||
        userPermissionList.includes('VIEW_MAINTENANCE') ||
        userPermissionList.includes('VIEW_UNIT_GROUPS') ||
        userPermissionList.includes('VIEW_MAIL_HUBS') ||
        userPermissionList.includes('VIEW_DEVICES_PAGE'),
    };
    let url = '';
    if (userPermission.UnitList) {
      url = match.url + '/units/unit-list';
    } else if (userPermission.ResidentList) {
      url = match.url + '/units/resident-list';
    } else if (userPermission.BatteryLevels) {
      url = match.url + '/automation/battery-levels';
    } else if (userPermission.VacantUnits) {
      url = match.url + '/automation/vacant-units';
      /* }else if(userPermission.PropertyGuests){
           url = match.url + '/access-control/property-guests';
       }else if(userPermission.ResidentGuests){
           url = match.url + '/access-control/resident-guests';
       }else if(userPermission.AccessLog){
           url = match.url + '/access-control/access-requests';
       }else if(userPermission.Areas){
           url = match.url + '/access-control/areas';
       }else if(userPermission.Doors){
           url = match.url + '/access-control/doors';
       }else if(userPermission.Schedules){
           url = match.url + '/access-control/schedules';
       }else if(userPermission.AccessProfiles){
           url = match.url + '/access-control/profiles';
       }else if(userPermission.AccessPoints){
           url = match.url + '/access-control/access-points';
       }else if(userPermission.smartCommunity){
           url = match.url + '/community/amenities';
       }else if(userPermission.Settings){
       if(userPermission.Devices){
           url = match.url + '/property-details/devices/properties';
       }
       if(userPermission.MailHubs){
           url = match.url + '/property-details/mail-hubs';
       }
       if(userPermission.UnitGroups){
           url = match.url + '/property-details/groups';
       }
       if(userPermission.Maintenance){
           url = match.url + '/property-details/maintenance';
       }
       if(userPermission.ResidentFeatures){
           url = match.url + '/property-details/resident-features';
       }
       if(userPermission.CommunitySettings){
           url = match.url + '/property-details/settings';
       }
       if(userPermission.PortalUsers){
           url = match.url + '/property-details/property-managers';
       }*/
    } else {
      url = match.url + '/access-control';
    }

    if (loaded) {
      return (
        <PropertyLayout propertyCount={propertyCount} property={property} isAdmin={isAdmin}>
          <Switch>
            {/* <Route exact path={match.path} render={() => <Redirect to={`${match.url}/units`} />} /> */}
            <Redirect exact from={match.path} to={url} />
            <Route path={`${match.path}/resident-messages`} component={ResidentMessages} />
            <Route
              path={`${match.path}/community`}
              component={property.get('admin_enabled_community') ? Community : CommunityPlaceholder}
            />
            <Route
              path={`${match.path}/community-views`}
              component={property.get('admin_enabled_community_views') ? CommunityViews : NotFound}
            />
            <Route path={`${match.path}/units`} component={Units} />
            <Route path={`${match.path}/property-details`} component={Settings} />
            <Route
              path={`${match.path}/access-control`}
              component={property.get('admin_enabled_access_control') ? AccessControl : AccessControlPlaceholder}
            />
            <Route
              path={`${match.path}/automation`}
              component={property.get('admin_enabled_unit_automation') ? UnitAutomation : UnitAutomationPlaceholder}
            />
            <Route component={NotFound} />
          </Switch>
        </PropertyLayout>
      );
    } else if (propertyError) {
      return <Route component={NotFound} />;
    } else {
      return <Loading />;
    }
  }
}

Property.propTypes = {
  match: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  propertyCount: PropTypes.number.isRequired,
};
export default Property;
