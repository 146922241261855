import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Yup from 'yup';
import InputScaffold from '../../../../../../../../../../common/forms/InputScaffold';
import NotificationStatusIndicator from '../../../../../../../../../../common/forms/NotificationStatusIndicator';
import { Link } from 'react-router-dom';
import Icon from '../../../../../../../../../../common/icons/icon';
import DoorbellSearch from '../../../../../../../../../../common/DoorbellSearch';
import Loading from '../../../../../../../../../../common/Loading';

const validationSchema = Yup.object().shape({
  serial_number: Yup.string()
    .trim()
    .max(16, 'Serial number must be 16 characters or less')
    .required('Please enter a recognized serial number'),
});

class AddVideoDoorbell extends Component {
  static propTypes = {};
  state = {
    name: 'Video Doorbell',
    serial_number: '',
    devicesCount: {},
    devicesLoaded: false,
    unpairedVideoDoorbells: [],
    serialNumbers: [],
    loading: false,
    finished: false,
    doorbellAssigned: false,
    onboardingError: false,
    deviceId: null,
  };
  componentWillMount() {
    this.props.getPanelDevices().then(action => {
      if (action.json.devices) {
        action.json.devices.forEach(device => {
          if (device.dmp_hardware_type === 'DOORBELL') {
            this.setState(() => ({
              doorbellAssigned: true,
              deviceId: device.id,
            }));
          }
        });
      }
    });
    if (!this.state.doorbellAssigned) {
      this.props.getUnassignedVideoDoorbells().then(m => {
        this.setState(() => ({
          unpairedVideoDoorbells: m.json,
        }));
        this.setState({
          serialNumbers: this.state.unpairedVideoDoorbells.map(vdb => {
            return vdb.id;
          }),
        });
      });
    }
  }
  componentDidMount() {
    this.getDevicesCount();
  }

  getDevicesCount = () => {
    this.props.getPanelDevices().then(action => {
      if (action.response.ok) {
        const type_arr = action.json.devices.map(d => d.dmp_hardware_type);
        const type_obj = {};
        for (const t of type_arr) {
          type_obj[t] = type_arr.reduce((n, x) => n + (x === t), 0);
        }
        this.setState(() => ({
          devicesCount: type_obj,
          devicesLoaded: true,
        }));
      }
    });
  };
  validateSerialNumber = serialNumber => {
    if (this.state.serialNumbers.includes(serialNumber)) {
      return true;
    }
    return false;
  };

  handleAddSubmit = payload => {
    this.setState({
      loading: true,
    });
    if (this.validateSerialNumber(payload.serial_number)) {
      this.props
        .assignVideoDoorbellToUnit({
          doorbellName: payload.name,
          doorbellId: payload.serial_number,
          panelId: payload.panel_id,
        })
        .then(action => {
          if (action.response.ok) {
            this.setState({
              loading: false,
              finished: true,
            });
          } else {
            this.setState({
              onboardingError: true,
              loading: false,
              finished: true,
            });
          }
        });
    } else {
      this.setState({
        onboardingError: true,
        loading: false,
        finished: true,
      });
    }
  };

  handleResetState = () => {
    this.props.getUnassignedVideoDoorbells().then(m => {
      this.setState(() => ({
        unpairedVideoDoorbells: m.json,
      }));
    });
    this.setState({
      serialNumbers: this.state.unpairedVideoDoorbells.map(vdb => {
        return vdb.id;
      }),
    });
    this.setState({ finished: false, onboardingError: false, loading: false, deviceId: null });
  };

  handleUnpairDoorbell = doorbellId => {
    this.setState({
      loading: true,
      doorbellAssigned: false,
    });
    this.props.unpairVideoDoorbellDevice(doorbellId).then(action => {
      if (action.response.ok) {
        this.handleResetState();
      }
    });
  };

  render() {
    return (
      <div className="add-device--wizard">
        {this.renderHeader()}
        <div className="container">
          {this.props.panelDevicesLoading ? (
            <Loading />
          ) : this.state.doorbellAssigned ? (
            this.renderDoorbellAlreadyAssigned()
          ) : this.state.unpairedVideoDoorbells.length > 0 ? (
            !this.state.finished ? (
              this.renderAddDevice()
            ) : this.state.onboardingError ? (
              this.renderOnboardingError()
            ) : (
              this.renderDoorbellPairingSuccessful()
            )
          ) : (
            this.renderNoDoorbellsAvailable()
          )}
        </div>
      </div>
    );
  }

  renderHeader = () => {
    return (
      <div className="page-header page-header__custom">
        <div className="container">
          <Link to={`./`} className="back-arrow">
            <Icon icon="ArrowLeft" />
            Back
          </Link>
          <h1 className="h1">Add Device</h1>
          <div className="add-doorbell-header-container">
            <h4 className="h4" style={{ marginRight: '10px' }}>
              Panel #: {this.props.panel.get('dmp_serial_number')}
            </h4>

            <h4 className="h4" style={{ marginRight: '10px' }}>
              Account #: {this.props.panel.get('dmp_account_prefix')}-{this.props.panel.get('dmp_account_number')}
            </h4>
            <h4 className="h4">Unit #: {this.props.panel.getIn(['unit', 'number'])}</h4>
          </div>
        </div>
      </div>
    );
  };

  renderDoorbellPairingSuccessful = () => {
    return (
      <div className="paper radius-top-left radius-top-right">
        <div className="add-doorbell-result-container">
          <Icon className="add-doorbell-result-icon" icon="Accept" />
          <h1 className="h1">Video doorbell successfully paired to unit!</h1>
          <Link to={`./`} style={{ marginTop: '10px' }}>
            <button className="button">Finish Setup</button>
          </Link>
        </div>
      </div>
    );
  };

  renderOnboardingError = () => {
    return (
      <div className="paper radius-top-left radius-top-right">
        <div className="add-doorbell-result-container">
          <Icon className="add-doorbell-result-icon" icon="Decline" />
          <h1 className="h1">The video doorbell failed to pair to the unit.</h1>
          <div style={{ marginTop: '10px' }}>
            <Link to={`./`} style={{ marginRight: '20px' }}>
              <button className="button button--outline--primary">Cancel Setup</button>
            </Link>
            <button
              className="button"
              onClick={() => {
                this.handleResetState();
              }}
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderNoDoorbellsAvailable = () => {
    return (
      <div className="paper radius-top-left radius-top-right">
        <div className="add-doorbell-result-container">
          <Icon className="add-doorbell-result-icon" icon="VideoDoorbell" />
          <h1 className="h1">There are no available video doorbells to pair.</h1>
          <Link to={`./`} style={{ marginTop: '10px' }}>
            <button className="button">Back</button>
          </Link>
        </div>
      </div>
    );
  };

  renderDoorbellAlreadyAssigned = () => {
    return (
      <div className="paper radius-top-left radius-top-right">
        <div className="add-doorbell-result-container">
          <Icon className="add-doorbell-result-icon" icon="VideoDoorbell" />
          <h1 className="h1">A video doorbell has already been paired to this unit.</h1>
          <div style={{ marginTop: '10px' }}>
            <button
              className="button button--outline--destructive"
              style={{ marginRight: '20px' }}
              onClick={() => {
                this.handleUnpairDoorbell(this.state.deviceId);
              }}
            >
              Unpair Video Doorbell
            </button>
            <Link className="button" to={`./`}>
              Back To Devices
            </Link>
          </div>
        </div>
      </div>
    );
  };

  renderAddDevice() {
    return (
      <Formik
        initialValues={{
          serial_number: '',
          name: 'Video Doorbell',
        }}
        validationSchema={validationSchema}
        onSubmit={() => {
          this.handleAddSubmit({
            name: this.state.name,
            serial_number: this.state.serial_number,
            panel_id: this.props.panel.get('id'),
          });
        }}
        onSuccess={() => {}}
        render={({ errors, handleSubmit, setFieldValue, status }) => (
          <form onSubmit={handleSubmit}>
            <div className="paper radius-top-left radius-top-right">
              <div className="page-header subheader underline">
                <h4 className="h4">Add A Video Doorbell</h4>
              </div>
              <div className="device-form--body">
                <section>
                  Type in the serial number associated with the device below.
                  <InputScaffold label="Serial Number" required validation={errors.serial_number}>
                    {this.state.unpairedVideoDoorbells.length > 0 && (
                      <DoorbellSearch
                        suggestions={this.state.unpairedVideoDoorbells}
                        onSelectSuggestion={itemSelected => {
                          this.setState({ serial_number: itemSelected });
                          setFieldValue('serial_number', itemSelected);
                        }}
                        onChange={value => {
                          this.setState({ serial_number: value });
                        }}
                      />
                    )}
                  </InputScaffold>
                </section>
              </div>
            </div>
            <div className="paper__footer radius-bottom-left radius-bottom-right">
              <NotificationStatusIndicator type="Failure" message={status} hideNotification={!status} />
              <div className="button--error__wrapper">
                <button disabled={this.state.loading || Object.keys(errors).length} className="button">
                  Add Device
                </button>
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

export default withRouter(AddVideoDoorbell);
