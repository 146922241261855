import { connect } from 'react-redux';
import { getResidents } from '../../../../../../../Property/redux/actions';
import {
  getPanelDevices,
  createPanelDevice,
  updatePanelDevice,
  removePanelDevice,
  pairPanelToUnit,
  unpairPanelFromUnit,
  deletePanel,
  setPanelSystemOptions,
  getJobStatus,
  initializePanel,
  checkInitializePanelStatus,
  refreshPanelCommunicationOptions,
  setPanelCommunicationOptions,
  updatePanel,
  checkPanelEasyConnectStatus,
  setPanelNetworkOptions,
  unpairVideoDoorbellDevice,
  removeVideoDoorbellDevice,
} from '../../redux/actions';
import { getUnpairedPropertyUnits, getPanelUnitCodes } from '../../../../../../../../redux/actions';
import { getUserPermission } from '../../../../../../../../redux/selectors';
import { selectUnrecognizedDevices } from '../../redux/selectors';
import PropertyPanel from './PropertyPanel';
function mapStateToProps(state, props) {
  return {
    panel: state.devices.panelDevices,
    property: state.property.activeProperty,
    panelDevicesLoading: state.devices.panelDevicesLoading,
    unrecognizedDevices: selectUnrecognizedDevices(state, props),
    panelDevicesError: state.devices.panelDevicesError,
    recognizedDevices: state.devices.panelDevices.get('devices'),
    unpairedPropertyUnits: state.unpairedPropertyUnits,
    panelDevicesPairing: state.devices.panelDevicesPairing,
    panelDevicePairingStatus: state.devices.panelDevicePairingStatus,
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPanelDevices: () => dispatch(getPanelDevices(ownProps.match.params.propertyId, ownProps.match.params.panelId)),
    createPanelDevice: payload =>
      dispatch(createPanelDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    updatePanelDevice: payload => {
      const { id, ...restPayload } = payload;
      return dispatch(
        updatePanelDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, id, restPayload)
      );
    },
    getResidents: () => dispatch(getResidents(ownProps.match.params.propertyId)),
    deletePanel: () => dispatch(deletePanel(ownProps.match.params.propertyId, ownProps.match.params.panelId)),
    removePanelDevice: id =>
      dispatch(removePanelDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, id)),
    unpairVideoDoorbellDevice: doorbellId => dispatch(unpairVideoDoorbellDevice(doorbellId)),
    removeVideoDoorbellDevice: doorbellId => dispatch(removeVideoDoorbellDevice(doorbellId)),
    pairPanelToUnit: unitId => dispatch(pairPanelToUnit(ownProps.match.params.panelId, unitId)),
    unpairPanelFromUnit: unitId => dispatch(unpairPanelFromUnit(ownProps.match.params.panelId, unitId)),
    getUnpairedPropertyUnits: () => dispatch(getUnpairedPropertyUnits(ownProps.match.params.propertyId)),
    checkPanelEasyConnectStatus: panelId =>
      dispatch(checkPanelEasyConnectStatus(ownProps.match.params.propertyId, panelId)),
    initializePanel: panelId => dispatch(initializePanel(ownProps.match.params.propertyId, panelId)),
    checkInitializePanelStatus: (panelId, groupId) =>
      dispatch(checkInitializePanelStatus(ownProps.match.params.propertyId, panelId, groupId)),
    refreshPanelCommunicationOptions: panelId =>
      dispatch(refreshPanelCommunicationOptions(ownProps.match.params.propertyId, panelId)),
    getJobStatus: jobId => dispatch(getJobStatus(ownProps.match.params.propertyId, jobId)),
    setPanelCommunicationOptions: panelId =>
      dispatch(setPanelCommunicationOptions(ownProps.match.params.propertyId, panelId)),
    updatePanel: (panelId, payload) => dispatch(updatePanel(ownProps.match.params.propertyId, panelId, payload)),
    setPanelSystemOptions: (panelId, payload) =>
      dispatch(setPanelSystemOptions(ownProps.match.params.propertyId, panelId, payload)),
    setPanelNetworkOptions: (panelId, payload) =>
      dispatch(setPanelNetworkOptions(ownProps.match.params.propertyId, panelId, payload)),
    getPanelUnitCodes: panelId => dispatch(getPanelUnitCodes(ownProps.match.params.propertyId, panelId)),
  };
}

const PropertyPanelContainer = connect(mapStateToProps, mapDispatchToProps)(PropertyPanel);

export default PropertyPanelContainer;
