import Yup from 'yup';

export const uniqueProperty = {
  schemaConstructor: Yup.object,
  name: 'uniqueProperty',
  method: function(propertyName, message) {
    return this.test('unique', message, function(value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);

      const subOptions = options.slice(0, currentIndex);

      if (subOptions.some(option => option[propertyName] === value[propertyName])) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }

      return true;
    });
  },
};
