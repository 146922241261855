import { useFormikContext } from 'formik';
import { useSGFormContext } from '../views/SGFormProvider';
import { useValidationBlockSchema } from './useValidationBlockSchema';

export const useBlockErrors = () => {
  const { touched, errors } = useFormikContext();
  const validationBlockSchema = useValidationBlockSchema();

  const { formBlocks } = useSGFormContext();

  const blockErrors = formBlocks
    .map(({ number, type }) => {
      const validation = validationBlockSchema[type];
      const errorType = validation ? validation(touched, errors) : null;

      return {
        number,
        error: !!errorType?.error,
      };
    })
    .filter(({ error }) => error);

  return {
    blockErrors,
    isErrors: !!blockErrors.length,
    isDelLength: blockErrors.length - 1,
  };
};
