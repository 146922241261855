import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BatteryIndicator extends Component {
  percentageClass = () => {
    const { percentage } = this.props;
    const percentParsed = parseInt(percentage, 10);
    switch (true) {
      case percentParsed === 0:
        return 'no-battery';
      case percentParsed < 10:
        return 'battery-status--red battery-0';
      case percentParsed < 15:
        return 'battery-status--red battery-10';
      case percentParsed < 25:
        return 'battery-status--red battery-20';
      case percentParsed < 35:
        return 'battery-status--yellow battery-30';
      case percentParsed < 45:
        return 'battery-status--green battery-40';
      case percentParsed < 55:
        return 'battery-status--green battery-50';
      case percentParsed < 65:
        return 'battery-status--green battery-60';
      case percentParsed < 75:
        return 'battery-status--green battery-70';
      case percentParsed < 85:
        return 'battery-status--green battery-80';
      case percentParsed < 95:
        return 'battery-status--green battery-90';
      case percentParsed <= 100:
        return 'full-green';
      default:
        return 'na';
    }
  };

  render() {
    const { percentage } = this.props;
    const iconClass = this.percentageClass();
    const percentParsed = parseInt(percentage, 10);

    return (
      <div className="battery-indicator">
        <div className={`battery ${iconClass}`} />
        {iconClass !== 'na' && (
          <div className="battery-indicator__percentage">{iconClass === 'no-battery' ? 0 : percentParsed}%</div>
        )}
      </div>
    );
  }
}

BatteryIndicator.propTypes = {
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BatteryIndicator;
