import React from 'react';

function HorizontalDragDrop() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M5.65698 9.17157L4.24276 7.75735.00012207 12 4.24276 16.2426 5.65698 14.8284 3.82858 13H10.0001V11H3.82851L5.65698 9.17157zM14.0001 11V13H20.1716L18.3432 14.8284 19.7574 16.2426 24.0001 12 19.7574 7.75735 18.3432 9.17157 20.1717 11H14.0001z" />
    </svg>
  );
  /* eslint-enable */
}

export default HorizontalDragDrop;
