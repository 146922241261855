export default {
  main: {
    title: 'Группы апартаментов',
    addGroup: 'Добавить группу',
    name: 'ИМЯ',
    numberOfInputs: 'КОЛИЧЕСТВО АПАРТАМЕНТОВ',
  },

  item: {
    removeConfirmation: 'Вы уверены что хотите <strong className="strong">удалить эту группу</strong>?',
    groupName: 'НАЗВАНИЕ ГРУППЫ',
  },

  errors: {
    requiredName: 'Имя может содержать не более 64 символов.',
    maxName: 'Пожалуйста, введите имя',
  },
};
