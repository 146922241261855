import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStripeAccount } from '../../views/Property/redux/actions/communityStripeIntegrationActions';
import cn from 'classnames';

const ConnectionStatusIndicator = props => {
  const { isConnected, stripeAccountId } = props;
  const stripeData = useSelector(state => state.property.stripeAccountInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStripeAccount(stripeAccountId));
  }, [dispatch, stripeAccountId]);

  let businessName =
    stripeData && stripeData.getIn(['business_profile', 'name'])
      ? `with ${stripeData.getIn(['business_profile', 'name'])}`
      : '';

  const indicator = () => {
    if (isConnected) {
      return (
        <div className="stripe-connection-status-indicator__wrapper">
          <p>Stripe Connection {businessName}</p>
          <div className="indicator-flex">
            <div className="indicator-level">{'ON'}</div>
            <div className={cn('indicator', 'on')}></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="stripe-connection-status-indicator__wrapper">
          <p>Stripe Connection</p>
          <div className="indicator-flex">
            <div className="indicator-level">{'OFF'}</div>
            <div className={cn('indicator', 'off')}></div>
          </div>
        </div>
      );
    }
  };

  return indicator();
};

export default ConnectionStatusIndicator;
