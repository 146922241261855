import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import NotFound from '../../../../common/NotFound';
import { AddEvent } from './AddEvent';
import { EditEvent } from './EditEvent';
import { EventList } from './EventList';

const Events = ({ property, match }) => {
  if (!property.get('enabled_events') || !property.get('admin_enabled_community')) {
    return <NotFound />;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={EventList} />
      <Route path={`${match.path}/add`} component={AddEvent} />
      <Route path={`${match.path}/:eventId`} component={EditEvent} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default connect(state => ({ property: state.property.activeProperty }))(Events);
