import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExternalLink from '../../common/icons/ExternalLink';
import SubHeader from '../../common/SubHeader';
import { getVendorStripeConnectAccount, createVendorStripeConnectAccount } from './redux/actions';
import ConnectionStatusIndicator from '../../common/StripeIntegration/ConnectionStatusIndicator';
import Loading from '../../common/Loading/SpinnerLoading';
import { useVendorDetails } from '../VendorEventsList/hooks';

const VendorStripeSettings = () => {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.user.get('id'));

  const vendor = useVendorDetails(userId);
  const vendorStripeConnectAccount = useSelector(state => state.vendorStripeConnect.stripeAccount);
  const vendorStripeConnectAccountLink = useSelector(state => state.vendorStripeConnect.stripeAccountLink);
  const loadingStripeAccount = useSelector(state => state.vendorStripeConnect.loadingStripeAccount);
  const fetchingStripeAccountLink = useSelector(state => state.vendorStripeConnect.fetchingAccountLink);
  const vendorId = vendor?.data?.id ?? null;

  useEffect(() => {
    if (vendorId) {
      dispatch(getVendorStripeConnectAccount(vendorId));
    }
  }, [dispatch, vendorId]);

  useEffect(() => {
    if (vendorStripeConnectAccountLink) {
      window.location = vendorStripeConnectAccountLink.get('url');
    }
  }, [vendorStripeConnectAccountLink]);

  const renderConnectButton = account => {
    if (loadingStripeAccount) {
      return <Loading />;
    }

    const button = (
      <div className="vendor--stripe-settings--connect__wrapper">
        <button
          className="button"
          disabled={fetchingStripeAccountLink}
          onClick={() => {
            dispatch(createVendorStripeConnectAccount(vendorId));
          }}
        >
          <div className="button--children">
            <span>{fetchingStripeAccountLink ? 'Fetching Stripe Account' : 'Connect to Stripe'}</span>
            {fetchingStripeAccountLink && <Loading />}
          </div>
        </button>
      </div>
    );

    if (!account || account.get('connected') === false) {
      return button;
    }
  };

  const renderConnectionStatusIndicator = account => {
    if (account) {
      return (
        <ConnectionStatusIndicator
          isConnected={account.get('connected')}
          stripeAccountId={vendorStripeConnectAccount.get('stripe_account_id')}
        />
      );
    }
  };

  return (
    <div className="container">
      {/* 33eac9e6-a426-4000-a1ea-74da55859c42   <-- current vendor id i'm testing with */}
      <SubHeader title="Stripe Settings" />

      <div className="paper radius-top-left radius-top-right">
        <h4 className="h4">Connect to a Stripe Account</h4>
        <p className="help-text tighter">To connect to a Stripe account, follow the steps in this article:</p>
        <div className="external-link">
          <a
            href="https://support.stripe.com/questions/getting-started-with-stripe-create-or-connect-an-account"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to connect to a Stripe Account
          </a>
          <ExternalLink />
        </div>
        <p className="help-text tighter">Once completed successfully, you'll see the status change below.</p>
        <div className="stripe-connection-status-button__wrapper">
          {renderConnectionStatusIndicator(vendorStripeConnectAccount)}
          {renderConnectButton(vendorStripeConnectAccount)}
        </div>
      </div>
    </div>
  );
};

export default VendorStripeSettings;
