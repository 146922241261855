export default {
  addAnnouncementsBtn: 'Add Announcements',
  pageTitle: 'Announcements',
  titleColumn: 'TITLE',
  groupColumn: 'GROUP(S)',
  postedDateColumn: 'POSTED DATE',
  downloadCSVBtn: 'Download CSV',
  announcementDetailsTitle: 'Announcement Details',
  fieldTitleAnnouncement: 'ANNOUNCEMENT TITLE',
  fieldAnnouncementDescription: 'ANNOUNCEMENT DESCRIPTION',
  labelGroups: 'GROUPS',
  allResidents: 'All Residents',
  helperAnnouncementText: 'After saving, notifications for this announcement will be sent immediately',
  postAnnouncementBtn: 'Post Announcement',
  cancelAnnouncementBtn: 'Cancel',
  postedAt: 'Posted at',
  saveAnnouncementBtn: 'Save Announcement',
  removeAnnouncementBtn: 'Remove Announcement',
  deletePopupMassage_1: 'Are you sure you want to',
  deletePopupMassage_2: 'remove this announcement',
};
