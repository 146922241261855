import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AdminForm from '../../AdminForm';

class AddAdminUser extends Component {
  static propTypes = {
    addAdminUser: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { userPermissionList } = this.props;
    const userPermission = {
      editAdminUserRoleOrPermissions: userPermissionList.includes('EDIT_ADMIN_USER_ROLE_OR_PERMISSIONS'),
      deleteAdminUser: userPermissionList.includes('DELETE_ADMIN_USER'),
    };
    this.setState({ userPermission: userPermission });
    this.props.getRolesByRoleLevel('ADMIN');
  }
  state = {
    userPermission: {},
    error: null,
  };

  render() {
    const { addAdminUser, history, roleOptions, user } = this.props;
    const { userPermission, error } = this.state;

    return (
      <AdminForm
        userPermission={userPermission}
        error={error}
        roleOptions={roleOptions}
        user={user}
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          role_id: '',
          created_by: user.get('id'),
        }}
        onSubmit={values => {
          return addAdminUser(values).then(action => {
            if (action.response.ok) {
              history.push(`/admin-management/${action.json.id}/edit`);
            } else {
              this.setState({ error: action.json.message });
            }
          });
        }}
      />
    );
  }
}

export default AddAdminUser;
