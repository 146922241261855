import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';

import SubHeader from '../../../../../../common/SubHeader';
import Delete from '../../../../../../common/Delete';
import Loading from '../../../../../../common/Loading';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../common/Kebab/index';
import Icon from '../../../../../../common/icons/icon';

class AccessPointsList extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    accessPoints: PropTypes.instanceOf(List),
    accessPointsLoaded: PropTypes.bool.isRequired,
    getAccessPoints: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const { getAccessPoints } = this.props;

    getAccessPoints();
  }

  state = {
    openKebabAccessPointId: null,
    deleteConfirmationAccessPointId: null,
    error: false,
  };

  onKebabClick = accessPointId => this.setState({ openKebabAccessPointId: accessPointId });

  closeKebab = () => this.setState({ openKebabAccessPointId: null });

  renderKebab(accessPoint) {
    const { match, history, isAdmin, userPermissionList, roles } = this.props;
    const { openKebabAccessPointId } = this.state;
    const controlAccessPoints =
      userPermissionList.find(permission => permission === 'CONTROL_ACCESS_POINTS') && roles.size > 0;
    return isAdmin || controlAccessPoints ? (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(accessPoint.get('id'))}
          onDisengage={this.closeKebab}
          menuOpened={accessPoint.get('id') === openKebabAccessPointId}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={() => {
                history.push(`${match.url}/${accessPoint.get('id')}`);
                this.closeKebab();
              }}
            >
              <Icon icon="Edit" />
              Edit
            </div>
            {
              <div
                className="kebab__menu--item"
                onClick={() =>
                  this.setState({
                    deleteConfirmationAccessPointId: accessPoint.get('id'),
                    openKebabAccessPointId: null,
                  })
                }
              >
                <Icon icon="Remove" />
                Delete
              </div>
            }
          </div>
        </Kebab>
      </div>
    ) : null;
  }

  renderDeleteConfirmation = accessPoint => {
    const { removeAccessPoint } = this.props;
    return (
      <div className="delete-confirmation__wrapper">
        <Delete
          onCancel={() => this.setState({ deleteConfirmationAccessPointId: null, openKebabAccessPointId: null })}
          deleteFunc={async () => {
            const action = await removeAccessPoint(accessPoint);
            if (!action.response.ok) {
              this.setState({
                error: (action.json && action.json.message) || 'Unknown error occurred while deleting access point',
                deleteConfirmationAccessPointId: null,
                openKebabAccessPointId: null,
              });
            }
          }}
          message={
            <span>
              Are you sure you want to <strong className="strong">remove this access point</strong>?
            </span>
          }
        />
      </div>
    );
  };

  render() {
    const { match, accessPoints, accessPointsLoaded, getAccessPoints, isAdmin, userPermissionList, roles } = this.props;
    const { error, deleteConfirmationAccessPointId } = this.state;
    const controlAccessPoints =
      userPermissionList.find(permission => permission === 'CONTROL_ACCESS_POINTS') && roles.size > 0;
    if (!accessPointsLoaded) {
      return <Loading />;
    }

    return (
      <div className="access-points-list--page">
        <div className="container">
          <SubHeader
            title="Access Points"
            size={24}
            actions={[
              <button
                type="button"
                className="button button--outline"
                onClick={() => {
                  getAccessPoints();
                }}
              >
                Fetch Access Points
              </button>,
              accessPoints.size > 0 && (isAdmin || controlAccessPoints) && (
                <Link to={`${match.url}/add-access-point`} className="button">
                  Add New Access Point
                </Link>
              ),
            ]}
          />

          {error && <div className="input-validation">{error}</div>}

          <div className="recog--container">
            <div className="recog__recognized">
              {accessPoints.size > 0 ? (
                <table className="table-units-list">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {accessPoints.map(accessPoint => (
                      <tr key={accessPoint.get('id')}>
                        <td>{accessPoint.get('name')}</td>
                        {
                          <td className="table__align-right kebab-cell">
                            {deleteConfirmationAccessPointId === accessPoint.get('id')
                              ? this.renderDeleteConfirmation(accessPoint)
                              : this.renderKebab(accessPoint)}
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : isAdmin || controlAccessPoints ? (
                <EmptyPlaceholder buttonText="Add Access Point" linkTo={`${match.url}/add-access-point`} />
              ) : (
                <EmptyPlaceholder>There are no Access Points</EmptyPlaceholder>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccessPointsList;
