import React from 'react';

function CancelIcon() {
  /* eslint-disable max-len */
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.49937 3.38814e-06C6.81836 3.38814e-06 5.17509 0.498483 3.77738 1.43241C2.37966 2.36633 1.29028 3.69375 0.64698 5.2468C0.00368267 6.79986 -0.164633 8.5088 0.163317 10.1575C0.491267 11.8062 1.30075 13.3207 2.48941 14.5093C3.67807 15.698 5.19251 16.5075 6.84123 16.8354C8.48994 17.1634 10.1989 16.9951 11.7519 16.3518C13.305 15.7085 14.6324 14.6191 15.5663 13.2214C16.5003 11.8237 16.9987 10.1804 16.9987 8.49937C16.9997 7.38294 16.7806 6.27727 16.3538 5.24563C15.927 4.21398 15.301 3.27662 14.5116 2.48719C13.7221 1.69775 12.7848 1.07173 11.7531 0.644948C10.7215 0.218168 9.6158 -0.000993873 8.49937 3.38814e-06ZM12.7491 11.5511L11.5511 12.7491L8.49937 9.69858L5.4489 12.7491L4.25095 11.5511L7.30142 8.49937L4.24969 5.4489L5.44764 4.25095L8.49937 7.30142L11.5511 4.24969L12.7491 5.44764L9.69858 8.49937L12.7491 11.5511Z"
        fill="#D9605C"
      />
    </svg>
  );
  /* eslint-enable */
}

export default CancelIcon;
