import React from 'react';

function FeatherСopy() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.998" height="15.998" viewBox="0 0 15.998 15.998">
      <g id="Icon_feather-copy" data-name="Icon feather-copy" transform="translate(1 1)">
        <path
          id="Path_480"
          data-name="Path 480"
          d="M14.9,13.5h6.3a1.4,1.4,0,0,1,1.4,1.4v6.3a1.4,1.4,0,0,1-1.4,1.4H14.9a1.4,1.4,0,0,1-1.4-1.4V14.9A1.4,1.4,0,0,1,14.9,13.5Z"
          transform="translate(-8.601 -8.601)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_481"
          data-name="Path 481"
          d="M5.1,12.1H4.4A1.4,1.4,0,0,1,3,10.7V4.4A1.4,1.4,0,0,1,4.4,3h6.3a1.4,1.4,0,0,1,1.4,1.4v.7"
          transform="translate(-3 -3)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default FeatherСopy;
