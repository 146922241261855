import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ENABLE_ACCESS, REVOKE_ACCESS } from '../../..';
import { getScheduleTourUnitGuestRevoke } from '../../../../../../../../redux/actions/scheduledAction';

export const useResidentAccessDetail = ({
  guestId,
  reason,
  historyEnableAccessReplaceTo,
  historyEnableAccessRedirectTo,
  historyRevokeAccessReplaceTo,
  historyRevokeAccessRedirectTo,
}) => {
  const history = useHistory();

  const { propertyId } = useParams();

  const dispatch = useDispatch();

  const handleFetchResidentAccess = async (guestId, type, reason) => {
    const result = await dispatch(
      getScheduleTourUnitGuestRevoke(propertyId, guestId, {
        type,
        reason,
      })
    );

    return result;
  };

  const handleRedirect = (replaceTo, redirectTo) => {
    const redirectPath = `/properties/${propertyId}/units/tours${replaceTo || redirectTo}`;
    replaceTo ? history.replace(redirectPath) : history.push(redirectPath);
  };

  return {
    handleEnableAccess: async () => {
      await handleFetchResidentAccess(guestId, ENABLE_ACCESS);
      handleRedirect(historyEnableAccessReplaceTo, historyEnableAccessRedirectTo);
    },
    handleRevokeAccess: async () => {
      await handleFetchResidentAccess(guestId, REVOKE_ACCESS, reason);
      handleRedirect(historyRevokeAccessReplaceTo, historyRevokeAccessRedirectTo);
    },
  };
};
