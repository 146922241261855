export default {
  typeOfMockData: 'EN',
  maintenanceRequestsTitle: 'Maintenance Requests',
  helpFromManagementTitle: 'Help From Management',

  tableHeaders: {
    authorID: 'ID',
    authorName: 'AUTHOR’S NAME',
    authorUnit: 'AUTHOR’S UNIT',
    requestType: 'REQUEST TYPE',
    status: 'STATUS',
    authorType: 'CLIENT TYPE',
  },

  statuses: {
    new: 'New',
    responded: 'Responded',
    pending: 'Pending',
    resolved: 'Resolved',
    problem: 'Problem',
  },

  details: {
    description: 'Description',
    accessInstructions: 'Access Instructions',
    permission: 'Permission',
    photos: 'Photos',
    addResponsePlaceholder: 'Add a response...',
    responseButton: 'Response',
    responseField: 'Response',
    yes: 'Yes',
    no: 'No',
  },
};
