import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { getUserPermission } from '../../../../../../../../redux/selectors';

export const useTourRouter = isTourExists => {
  const isTourPermission = useSelector(getUserPermission).includes('SHOW_SELF-GUIDED_TOURS');

  const property = useSelector(state => state.property.activeProperty);

  const isEnabledTour = property.get('admin_enabled_self_guided_tour');

  const { url } = useRouteMatch();

  const tourRouter = tourRoute => ({ tourRoute, isEnabledTour });

  if (!isTourPermission) {
    return tourRouter(`${url}/no-permission`);
  }

  if (!isEnabledTour) {
    return tourRouter(`${url}/tours-tip`);
  }

  if (isTourExists) {
    return tourRouter(`${url}/automated/edit`);
  }

  if (!isTourExists) {
    return tourRouter(`${url}/tour-ready`);
  }
};
