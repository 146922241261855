export default {
  back: 'Back',
  next: 'Next',
  cancel: 'Cancel',
  confirm: 'Confirm',
  saving: 'Saving...',
  save: 'Save',
  ok: 'OK',
  verify: 'Verify',
  yes: 'Yes',
  tryAgain: 'Try Again',
  remove: 'Remove',
  apply: 'Apply',
  edit: 'Edit',
  update: 'Update',
};
