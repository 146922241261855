import { connect } from 'react-redux';
import UnitAutomation from './UnitAutomation';
import { getUserPermission } from '../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    userPermissionList: getUserPermission(state),
  };
}

const UnitAutomationContainer = connect(mapStateToProps)(UnitAutomation);

export default UnitAutomationContainer;
