import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createFeatureDetailsRequest } from '../../redux/actions';

export default function PlaceholderRequestDetailsButton({ feature }) {
  const property = useSelector(state => state.property.activeProperty);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  return isMessageSent ? (
    <div>Thank you, your message has been sent. A representative from SmartExperience will contact you shortly.</div>
  ) : (
    <button
      type="button"
      className="button button--yellow smart--button"
      disabled={isLoading}
      onClick={() => {
        setIsLoading(true);
        dispatch(createFeatureDetailsRequest(property.get('id'), feature)).then(() => {
          setIsMessageSent(true);
          setIsLoading(false);
        });
      }}
    >
      Learn more about {feature}
    </button>
  );
}
