import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class ReadOnlyField extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  };

  render() {
    const { label, value } = this.props;
    return (
      <div className="details--read-only">
        <div className="read-only--label">{label}</div>
        <div className="read-only--value">{value}</div>
      </div>
    );
  }
}
