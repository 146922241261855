import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { SGTourSuccessfully, SGToursAutomated, SGToursReady, SGToursTip, SGToursNoPermission } from './views';
import { useFetchTour, useTourRouter } from './common';
import Loading from '../../../../../../common/Loading';

const SGTours = () => {
  const { isLoad, isTourExists } = useFetchTour();
  const { path } = useRouteMatch();

  const { tourRoute, isEnabledTour } = useTourRouter(isTourExists);

  if (!isLoad) return <Loading />;

  return (
    <Switch>
      <Route exact path={path} render={() => <Redirect to={tourRoute} />} />
      <Route exact path={`${path}/tour-ready`} component={SGToursReady} />
      {isEnabledTour && <Route path={`${path}/automated`} component={SGToursAutomated} />}
      <Route exact path={`${path}/create/automated/successfully`} component={SGTourSuccessfully} />
      <Route path={`${path}/tours-tip`} component={SGToursTip} />
      <Route path={`${path}/no-permission`} component={SGToursNoPermission} />
      <Route exact path="*" render={() => <Redirect to={tourRoute} />} />
    </Switch>
  );
};

export default SGTours;
