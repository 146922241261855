import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import MaintenanceRequests from './views/MaintenanceRequests';
import HelpFromManagement from './views/HelpFromManagement';
import NotFound from '../../common/NotFound';

const ResidentMessages = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/maintenance-requests`} />
      <Route path={`${path}/maintenance-requests`} component={MaintenanceRequests} />
      <Route path={`${path}/help-from-management`} component={HelpFromManagement} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default ResidentMessages;
