import { connect } from 'react-redux';
import { getVendors, deleteVendor } from '../../../../../../redux/actions';
import VendorsList from './VendorsList';

function mapStateToProps(state) {
  return {
    vendorsList: state.property.vendorsList, // NOTE FORM HERE
    loading: state.property.messagesListLoading,
    property: state.property.activeProperty,
    propertyId: state.property.activeProperty.get('id'),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVendors: propertyId => dispatch(getVendors(propertyId)),
    deleteVendor: (vendorId, communityId) => dispatch(deleteVendor(vendorId, communityId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getVendors: () => dispatchProps.getVendors(stateProps.property.get('id')),
  });
}

const VendorsListContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(VendorsList);

export default VendorsListContainer;
