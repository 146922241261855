export default {
  main: {
    title: 'Unit Groups',
    addGroup: 'Add Group',
    name: 'NAME',
    numberOfInputs: '# OF UNITS',
  },

  item: {
    removeConfirmation: 'Are you sure you want to <strong className="strong">remove this group</strong>?', //!
    groupName: 'GROUP NAME',
  },

  errors: {
    requiredName: 'Name may only be 64 characters or less',
    maxName: 'Please enter a name',
  },
};
