export default {
  mainScreen: {
    title: 'Удобства',
    noAmenitiesText:
      'В вашем сообществе не добавлено никаких удобств. Нажмите кнопку "Добавить удобства", чтобы начать работу.', //?
    addAmenityButton: 'Добавить удобство',
    downloadCsvButton: 'Скачать CSV',
    free: 'Бесплатно',
    amenitiesTable: {
      amenityNameColumnTitle: 'НАЗВАНИЕ УДОБСТВА',
      availabilityColumnTitle: 'ДОСТУПНОСТЬ',
      reservableColumnTitle: 'РЕЗЕРВИРУЕМЫЙ',
      feeColumnTitle: 'ПЛАТЕЖ',
      maxOccupancyColumnTitle: 'ВМЕСТИМОСТЬ', //?
    },
  },

  amenityPage: {
    title: 'Добавить удобство',
    simpleTitle: 'Удобство',
    saveButton: 'Сохранить удобство',
    loading: 'Загрузка...',
    tags: {
      open: 'Открыто',
      closed: 'Закрыто',
    },
    tabs: {
      amenityDetails: {
        title: 'Подробности об удобстве',
        sections: {
          amenityDetails: {
            title: 'Подробности об удобстве',
            nameField: 'НАЗВАНИЕ',
            descriptionField: 'ОПИСАНИЕ',
            maxOccupancyField: 'ВМЕСТИМОСТЬ', //?
          },
          termsOfUse: {
            title: 'Правила использования',
            termOfUseField: 'ПРАВИЛА ИСПОЛЬЗОВАНИЯ',
          },
        },
      },
      images: {
        title: 'Изображения',
        none: 'Отсутствует', //?
        sections: {
          upload: {
            title: 'Загрузить изображения удобства',
            helperText: 'Загрузите изображение или несколько изображений, чтобы продемонстрировать это удобство',
            dragAndDrop: {
              title: 'Перетащите сюда ваши файлы или нажмите, чтобы открыть проводник', //?
              subtitle: 'ПОДДЕРЖИВАЕМЫЕ ФАЙЛЫ',
              condition: 'PNG или JPG | 1 MB или меньше',
            },
          },
          cropperModal: {
            title: 'Обрежьте ваше изображение',
          },
          amenity: {
            title: 'Изображения удобства',
            subtitle:
              'Вы можете изменить порядок изображений в том порядке, в котором вы хотите, чтобы они отображались в приложении, или удалить их. Разрешается не более 5 изображений.',
          },
        },
        errors: {
          custom: 'Не удалось принять {{fileName}}. Размер файла превышает 1 MB.',
          tooManyItems: 'Не удалось принять {{fileName}}. Слишком много файлов.',
        },
      },
      availability: {
        title: 'Доступность',
        subtitle: 'График доступности',
        weeks: {
          sun: 'воскресенье',
          mon: 'понедельник',
          tue: 'вторник',
          wed: 'среда',
          thu: 'четверг',
          fri: 'пятница',
          sat: 'суббота',
        },
        sections: {
          daysAndHours: {
            title: 'Дни и часы',
            subtitle: 'Выберите тип доступности для этого удобства',
            radioOptions: {
              none: 'НЕДОСТУПЕН', //?
              '24/7': 'КРУГЛОСУТОЧНО', //?
              daily: 'ЕЖЕДНЕВНО',
              custom: 'НАСТРОИТЬ ВРУЧНУЮ', //?
            },
            subsections: {
              daily: {
                title: 'Ежедневно',
                subtitle: 'Введите время открытия и время закрытия',
                openTimeField: 'ВРЕМЯ ОТКРЫТИЯ',
                closeTimeField: 'ВРЕМЯ ЗАКРЫТИЯ',
              },
              custom: {
                title: 'Настроить вручную', //?
                subtitle:
                  'Выберите дни недели, в которые это удобство будет работать. Любой день, который не выбран, будет помечен как «Закрытый». Любой выбранный день позволит вам установить ежедневный график.',
                daysOfWeek: {
                  sun: 'ВС',
                  mon: 'ПН',
                  tue: 'ВТ',
                  wed: 'СР',
                  thu: 'ЧТ',
                  fri: 'ПТ',
                  sat: 'СБ',
                },
                openingTimeField: 'ВРЕМЯ ОТКРЫТИЯ',
                closingTimeField: 'ВРЕМЯ ЗАКРЫТИЯ',
              },
            },
          },
          temporaryClosings: {
            title: 'Временное закрытие', //?
            subtitle: 'Временное закрытие можно добавить после создания удобства', //?
            editSubtitle:
              'Установите любое заранее определенное временное закрытие, выбрав дату закрытия и дату открытия.',
            saveClosingButton: 'Сохранить временное закрытие', //?
            addTemporaryClosingButton: 'Добавить временное закрытие', //?
            nameField: 'НАЗВАНИЕ ЗАКРЫТИЯ',
            closeDateField: 'ДАТА ЗАКРЫТИЯ',
            openDateField: 'ДАТА ОТКРЫТИЯ',
            closes: 'Закрывается',
            reopens: 'Открывается',
          },
        },
      },
      reservationType: {
        title: 'Тип бронирования',
        subtitle: 'Выберите, смогут ли жильцы зарезервировать это удобство',
        limitedSubtitle: 'Измените дни и часы доступности, чтобы бронирования стали доступными.',
        details: 'Детали бронирования',
        types: {
          default: {
            title: 'БЕЗ РЕЗЕРВАЦИИ', //?
          },
          free: {
            title: 'БЕСПЛАТНО',
            minReservationField: 'МИНИМАЛЬНОЕ ВРЕМЯ БРОНИРОВАНИЯ',
            minReservationPlaceholder: 'Выберите минимальную длительность бронирования',
            maxReservationField: 'МАКСИМАЛЬНОЕ ВРЕМЯ БРОНИРОВАНИЯ',
            maxReservationPlaceholder: 'Выберите максимальную длительность бронирования',
            cancelationPolicy: {
              title: 'Политика отмены',
              subtitle: 'Здесь вы можете описать политику отмены и возврата средств',
              field: 'ПОЛИТИКА ОТМЕНЫ',
            },
          },
          paid: {
            title: 'ПЛАТНО',
            pricePerIncrementField: 'ЦЕНА ЗА ВРЕМЯ', //?
            refundField: 'ВРЕМЯ ВОЗВРАТА (ЧАСЫ)',
            cancelationFeeField: 'ПЛАТА ЗА ОТМЕНУ',
            cancelationPolicy: {
              title: 'Политика отмены и возврата средств',
              refundField: 'ПОЛИТИКА ВОЗВРАТА',
            },
          },
        },
      },
      reservations: {
        title: 'Бронирования',
        failedStatus: 'Произошла ошибка при загрузке бронирований.',
        noReservations: 'Нет доступных бронирований для отображения.',
        tableHeaders: {
          residentName: 'Имя жителя',
          dateOfReservation: 'Дата бронирования',
          timeOfReservation: 'Время бронирования',
        },
        cancelReservation: 'Отменить бронирование',
        past: 'прошло',
      },
    },
    errors: {
      amenityDetailsFields: {
        enterTitle: 'Пожалуйста, введите заголовок',
        enterDescription: 'Пожалуйста, введите описание',
      },
      amenityAvailabilityFields: {
        closingTimes: 'Время закрытия должно наступать после времени открытия.',
        selectDays: 'Пожалуйста, выберите день(дни) и время открытия/закрытия.',
      },
      amenityReservationTypeFields: {
        minimumReservation: 'Минимальное время бронирования должно быть введено.',
        maximumReservation: 'Максимальное время бронирования должно быть введено.',
        cancelationPolicyMustBeSet: 'Политика отмены должна быть введена.',
        pricePerTimeIncrement: 'Цена за время должна быть введена.', //?
        cancelationFeeProviding: 'Плата за отмену должна быть введена.',
        refundPolicyMustBeSet: 'Политика возврата должна быть введена.',
      },
    },
    modals: {
      cancelCreating: {
        title: 'Отменить создание удобства',
        subtitle: 'Вы уверены, что хотите отменить создание этого удобства?',
        confirmCanceling: 'Отменить создание удобства',
      },
      confirmCreating: {
        title: 'Временное закрытие',
        subtitle:
          'Вы уверены, что хотите создать это временное закрытие? Все бронирования, сделанные в это время, будут автоматически отменены.',
        confirm: 'Создать временное закрытие',
      },
      preview: {
        title: 'Предпросмотр нового удобства',
        subtitle: 'Прежде чем сохранить, просмотрите это новое удобство',
      },
      amenityListItem: {
        edit: 'Редактировать удобство',
        delete: 'Удалить удобство',
      },
      deleteTemporaryClosing: {
        title: 'Удалить временное закрытие?',
        subtitle: 'Вы уверены, что хотите удалить это временное закрытие',
        cancel: 'Нет, не удалять',
        submit: 'Да, удалить временное закрытие',
      },
      unsavedChanges: {
        title: 'Несохраненные изменения',
        subtitle: 'Вы уверены, что хотите покинуть эту страницу? Все несохраненные изменения будут потеряны.',
        submit: 'Да, я уверен(а)',
      },
      delete: {
        title: 'Удалить удобство?',
        subtitle: 'Вы уверены, что хотите удалить это удобство?',
        cancel: 'Нет, не удалять',
        submit: 'Да, удалить удобство',
      },
      close: {
        title: 'Закрыть удобство',
        subtitle:
          'Вы можете опубликовать объявление, чтобы проинформировать жителей о закрытии. Все существующие бронирования для этого удобства будут отменены.',
        announcementTitleField: 'НАЗВАНИЕ ОБЪЯВЛЕНИЯ',
        announcementField: 'ОБЪЯВЛЕНИЕ',
        submit: 'Закрыть удобство',
      },
      cancelReservation: {
        title: 'Отменить бронирование',
        body:
          'Для отмены бронирования жителя, пожалуйста, укажите причину отмены, которая будет отправлена жителю по электронной почте.',
        field: 'Причина отмены',
        submit: 'Отменить бронирование',
        reasonErrors: {
          length: 'Причина должна содержать 2000 символов или менее',
          required: 'Пожалуйста, укажите причину',
        },
      },
    },
    messages: {
      edited: 'Удобство успешно отредактировано!',
    },
  },
};
