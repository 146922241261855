export default {
  main: {
    headerTitle: 'Пользователи вебсайта', //?
    addPortalUser: 'Добавить пользователя вебсайта', //?

    tableHeader: {
      name: 'ИМЯ',
      role: 'РОЛЬ',
      phoneNumber: 'НОМЕР ТЕЛЕФОНА',
    },

    removeConfirmation: 'Вы уверены что хотите <strong className="strong">удалить этого пользователя</strong>?',
  },

  details: {
    addPortalUser: 'Добавить пользователя вебсайта', //?
    editPortalUser: 'Редактировать пользователя вебсайта', //?

    staffInformation: 'Информация о персонале', //?
    emailSettings: 'Настройки электронной почты',
    accessProfiles: 'Профили доступа',
    accessCodes: 'Коды доступа',
    saveButton: 'Сохранить пользователя вебсайта', //?
    removeButton: 'Удалить пользователя вебсайта', //?

    removeConfirmation: 'Вы уверены что хотите <strong className="strong">удалить этого пользователя</strong>?',

    fields: {
      firstName: 'ИМЯ',
      lastName: 'ФАМИЛИЯ',
      role: 'РОЛЬ',
      selectRole: 'Выберите роль',
      unavailableRole: 'Нет доступной роли',
      roles: {
        unassigned: 'Неназначенный',
      },
      email: 'ЭЛЕКТРОННАЯ ПОЧТА',
      phoneNumber: 'НОМЕР ТЕЛЕФОНА',
      companyName: 'НАЗВАНИЕ КОМПАНИИ',
      approvedPurchaser: 'ОДОБРЕННЫЙ ПОКУПАТЕЛЬ',
      enableDiscussionBoardEmails: 'ВКЛЮЧИТЬ ЭЛЕКТРОННУЮ РАССЫЛКУ О ДОСКЕ ОБСУЖДЕНИЙ', //?
      enableDailyDiscussionBoardActivityEmails:
        'ВКЛЮЧИТЬ ЭЛЕКТРОННУЮ РАССЫЛКУ О ЕЖЕДНЕВНОЙ АКТИВНОСТИ НА ДОСКЕ ОБСУЖДЕНИЙ', //?
    },

    errors: {
      requiredFirstName: 'Введите имя пожалуйста',
      maxFirstName: 'Имя должно содержать не более 64 символов',
      requiredLastName: 'Введите фамилию пожалуйста',
      maxLastName: 'Фамилия должна содержать не более 64 символов',
      requiredEmail: 'Введите электронную почту пожалуйста',
      requiredPhoneNumber: 'Введите номер телефона пожалуйста',
      invalidPhoneNumber: 'Должен быть действительный номер телефона',

      enterCode: 'Введите код',
      invalidCodeLength: 'Код учетной записи должен состоять из 5 символов.',
      invalidCodeNumber: 'Код должен находиться в диапазоне от 00000 до 65535.',
      selectType: 'Выберите тип учетных данных',
      invalidNoteLength: 'Заметка к учетным данным должны содержать не более 255 символов.',
      uniqueProperty: 'Код должен быть уникальным',
      profiles: 'Должен быть выбран хотя бы один вариант профиля доступа.',
    },
  },
};
