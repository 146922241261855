import React, { Component } from 'react';
import Icon from '../../../../../../../../../../common/icons/icon';

class SGFormUnitsRemoveModel extends Component {
  render() {
    const { visible, item, onCancel, removeUnit } = this.props;

    if (!visible) return null;

    return (
      <div className="modal modal--visible revokeModel-div revokeEnableModel-div SGFormUnitsRemoveModel-div">
        <div className="modal__children">
          <div className="modal__body">
            <div className="modal__body--text">
              <div className="paper radius-top-left radius-top-right revokeModel-top-div">
                <div className="SGFormUnitsRemoveModel-top-title1">
                  Are you sure you want to remove {item.number} from being a model unit?
                </div>
                <div className="SGFormUnitsRemoveModel-top-title2">
                  Removing this unit will prevent prospective residents from accessing it during a self-guided tour.
                </div>
              </div>
            </div>
            <div className="button-container-deny">
              <button className="button-red" type="button" onClick={removeUnit}>
                <Icon icon="TrashIcon" />
                Remove Unit
              </button>
              <button className="button-no" type="button" onClick={onCancel}>
                No, Don’t Remove
              </button>
            </div>
          </div>
        </div>
        <button type="button" className="modal__overlay" onClick={onCancel} />
      </div>
    );
  }
}

export default SGFormUnitsRemoveModel;
