import { connect } from 'react-redux';
import { getOffers, removeOffer } from '../../redux/actions';
import { withRouter } from 'react-router-dom';
import OffersList from './OffersList';
import { getUserPermission } from '../../redux/selectors';

function mapStateToProps(state) {
  return {
    list: state.offerList,
    loading: state.offersLoading,
    total: state.offersTotal,
    searching: state.offersSearching,
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getOffers: params => dispatch(getOffers(params)),
    removeOffer: offerId => dispatch(removeOffer(offerId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getOffers: params => dispatchProps.getOffers(params),
  });
}

const OffersListContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(OffersList);

export default withRouter(OffersListContainer);
