export default {
  main: {
    title: 'Гости жителя',
    downloadCsv: 'Скачать CSV',
    filters: 'Фильтры',
  },

  list: {
    noResidents: 'У жителя нет гостей',

    tableHeaders: {
      residentName: 'ИМЯ ЖИТЕЛЯ',
      residentUnit: 'АПАРТАМЕНТЫ ЖИТЕЛЯ',
      guestName: 'ИМЯ ГОСТЯ',
      guestAccessDateRange: 'ДИАПАЗОН ДАТ ГОСТЕВОГО ДОСТУПА',
      scheduleType: 'ТИП РАСПИСАНИЯ',
      status: 'СТАТУС',
    },

    revoke: 'Отозвать',
    accessLog: 'История доступа',
  },

  filter: {
    residentName: 'ИМЯ ЖИТЕЛЯ',
    residentUnit: 'АПАРТАМЕНТЫ ЖИТЕЛЯ',
    guestName: 'ИМЯ ГОСТЯ',
    status: 'СТАТУС',
    guestsAccessStart: 'НАЧАЛО ДОСТУПА ГОСТЯ',
    guestsAccessEnd: 'ОКОНЧАНИЕ ДОСТУПА ГОСТЯ',
    statuses: {
      active: 'Активный',
      inactive: 'Неактивный',
      future: 'Будущий',
      revoked: 'Отозванный',
    },

    dateError: 'Дата начала должна быть раньше даты окончания',
  },

  alertModal: {
    title: 'Отозвать доступ к жилому комплексу?',
    body:
      'Введите комментарий для вашего жителя, чтобы сообщить ему, почему его гостю был отозван доступ к сообществу.', //?
    confirmButtonTitle: 'Отозвать',
  },
};
