export default {
  'Pending Discussions': 'Ожидаемые обсуждения',
  'No Pending Discussions right now.': 'На данный момент нет ожидаемых обсуждений.',
  "Author's Name": 'ИМЯ АВТОРА',
  "Author's Unit #": 'АПАРТАМЕНТЫ АВТОРА',
  'Post Type': 'ТИП СООБЩЕНИЯ',
  Headline: 'ЗАГОЛОВОК',
  Body: 'СООБЩЕНИЕ',
  'See Post Body': 'ПОКАЗАТЬ ТЕКСТ СООБЩЕНИЯ',
  Type: 'Тип',
  'Reject Post?': 'Отклонить сообщение?',
  'Enter a comment to your resident to let them know why their post was rejected.':
    'Введите комментарий вашему жителю, чтобы сообщить ему, почему его сообщение было отклонено.',
  Reject: 'Отклонить',
  Cancel: 'Отмена',
  'Former Resident': 'Бывший житель',
  General: 'Общие',
  'For Sale': 'Для продажи',
  Event: 'Мероприятия',
  Recommendation: 'Рекомендации',
};
