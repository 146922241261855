import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch, Route } from 'react-router-dom';

import NotFound from '../../common/NotFound';
import Groups from './views/Groups';
import PropertyManagers from './views/PropertyManagers';
import Settings from './views/Settings';
import Maintenance from './views/Maintenance';
import MailHubs from './views/MailHubs';
import EditMailHub from './views/EditMailHub';
import ResidentFeatures from './views/ResidentFeatures';
import devices from './views/Devices';
import AddPanel from './views/Devices/views/AddPanel';
import PropertyPanel from './views/Devices/views/PropertyPanel';
import AddDevice from './views/Devices/views/PropertyPanel/views/AddDevice';
import AddDMPDevice from './views/Devices/views/PropertyPanel/views/AddDMPDevice';
import AddVideoDoorbell from './views/Devices/views/PropertyPanel/views/AddVideoDoorbell';
import AddZWaveDevice from './views/Devices/views/PropertyPanel/views/AddZWaveDevice';
import RemoveZWaveDevice from './views/Devices/views/PropertyPanel/views/RemoveZWaveDevice';
import Integrations from './views/Integrations';

class PropertyDetails extends Component {
  render() {
    const { match, property, userPermissionList } = this.props;
    const userPermission = {
      PortalUsers: userPermissionList.includes('VIEW_PORTAL_USERS_PAGE'),
      CommunitySettings: userPermissionList.includes('VIEW_COMMUNITY_SETTINGS_PAGE'),
      ResidentFeatures: userPermissionList.includes('VIEW_RESIDENT_FEATURES_PAGE'),
    };
    let url = '';
    if (userPermission.PortalUsers) {
      url = match.url + '/property-managers';
    } else if (userPermission.CommunitySettings) {
      url = match.url + '/settings';
    } else if (userPermission.ResidentFeatures) {
      url = match.url + '/resident-features';
    } else {
      url = match.url + '/maintenance';
    }

    return (
      <Switch>
        <Route exact path={match.path} render={() => <Redirect to={url} />} />
        <Route exact path={`${match.path}/settings`} component={Settings} />
        <Route exact path={`${match.path}/maintenance`} component={Maintenance} />
        <Route path={`${match.path}/property-managers`} component={PropertyManagers} />
        <Route exact path={`${match.path}/groups`} component={Groups} />
        <Route exact path={`${match.path}/mail-hubs`} component={MailHubs} />
        <Route exact path={`${match.path}/mail-hubs/create`} component={EditMailHub} />
        <Route exact path={`${match.path}/mail-hubs/edit/:mailHubId`} component={EditMailHub} />
        <Route exact path={`${match.path}/resident-features`} component={ResidentFeatures} />
        {property.get('admin_enabled_mri') && (
          <Route exact path={`${match.path}/integrations`} component={Integrations} />
        )}
        <Route exact path={`${match.path}/devices`} component={devices} />
        <Route exact path={`${match.path}/devices/properties`} component={devices} />
        <Route exact path={`${match.path}/devices/properties/add-panel`} component={AddPanel} />
        <Route exact path={`${match.path}/devices/properties/panels/:panelId`} component={PropertyPanel} />
        <Route exact path={`${match.path}/devices/properties/panels/:panelId/add-device`} component={AddDevice} />
        <Route
          exact
          path={`${match.path}/devices/properties/panels/:panelId/add-zwave-device`}
          component={AddZWaveDevice}
        />
        <Route
          exact
          path={`${match.path}/devices/properties/panels/:panelId/add-:deviceType(wired|wireless)-device`}
          component={AddDMPDevice}
        />
        <Route
          exact
          path={`${match.path}/devices/properties/panels/:panelId/add-video-doorbell`}
          component={AddVideoDoorbell}
        />
        <Route
          exact
          path={`${match.path}/devices/properties/panels/:panelId/remove-device`}
          component={RemoveZWaveDevice}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

PropertyDetails.propTypes = {
  match: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
};
export default PropertyDetails;
