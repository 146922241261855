import moment from 'moment';
import * as yup from 'yup';

const validationSchema = yup.object({
  units: yup
    .array()
    .of(yup.string())
    .required('This field is required'),
  profile: yup.string().required('This field is required'),
  weekdays: yup
    .array()
    .of(yup.string())
    .required('At least one day must be selected'),
  start_time: yup
    .string()
    .nullable()
    .required('Start time must be selected'),
  end_time: yup
    .string()
    .nullable()
    .required('End time must be selected')
    .test('is-greater', 'End time must be after start time', function(value) {
      const { start_time } = this.parent;
      return moment(value, 'HH:mm').isAfter(moment(start_time, 'HH:mm'));
    }),
  tour_count: yup
    .object()
    .nullable()
    .required('Time period must be selected'),
  pre_message: yup
    .string()
    .test(
      'length validation all and text',
      'You need to add a message text if a notification option is selected. Max length is 150 characters.',
      function(value) {
        const preSendTypeValue = this.resolve(yup.ref('pre_send_type'));

        if (preSendTypeValue === 'all' || preSendTypeValue === 'text') {
          return value?.length <= 150;
        }
        return true;
      }
    )
    .test(
      'length validation email',
      'You need to add a message text if a notification option is selected. Max length is 300 characters.',
      function(value) {
        const preSendTypeValue = this.resolve(yup.ref('pre_send_type'));

        if (preSendTypeValue === 'email') {
          return value?.length <= 300;
        }
        return true;
      }
    ),
  pre_send_type: yup
    .string()
    .nullable()
    .when('pre_message', {
      is: pre_message => !!pre_message,
      then: yup.string().required('Please select one of the message options'),
    }),
  post_message: yup.string(),
  post_send_type: yup.string(),
  notified_staff: yup
    .array()
    .of(yup.string())
    .required('This field is required'),
});

export const useValidationFormSchema = () => validationSchema;
