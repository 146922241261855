import { useQuery } from 'react-query';
import client from '../../../../../../../../common/client';

export const USE_ADMIN_EVENT_FINANCIALS_KEY = 'adminFinancials';

export const useAdminFinancials = () => {
  return useQuery(
    [USE_ADMIN_EVENT_FINANCIALS_KEY],
    async () => {
      const response = await client.get(`financials`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      staleTime: 5 * 60000,
    }
  );
};
