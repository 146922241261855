import React from 'react';

function Unpair() {
  /* eslint-disable max-len */
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2871_18729"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2871_18729)">
        <path
          d="M31.5278 27.7222L29.3334 25.5C30.6482 25.213 31.7362 24.5718 32.5973 23.5764C33.4584 22.581 33.889 21.4167 33.889 20.0833C33.889 18.5463 33.3473 17.2361 32.264 16.1527C31.1806 15.0694 29.8704 14.5277 28.3334 14.5277H21.8056V11.75H28.3334C30.6389 11.75 32.6042 12.5625 34.2292 14.1875C35.8542 15.8125 36.6667 17.7778 36.6667 20.0833C36.6667 21.7592 36.1968 23.2824 35.257 24.6528C34.3172 26.0232 33.0741 27.0463 31.5278 27.7222ZM25.3056 21.4722L22.5278 18.6945H26.5278V21.4722H25.3056ZM33.6112 37.6667L2.52783 6.58333L4.50004 4.61112L35.5834 35.6945L33.6112 37.6667ZM18.6112 28.3333H11.6667C9.36115 28.3333 7.39587 27.5208 5.77087 25.8958C4.14587 24.2708 3.33337 22.3056 3.33337 20C3.33337 18.0278 3.93986 16.2824 5.15283 14.7639C6.36578 13.2454 7.91669 12.2778 9.80558 11.8611L12.3889 14.4444H11.6667C10.1297 14.4444 8.81949 14.9861 7.73612 16.0694C6.65279 17.1528 6.11112 18.463 6.11112 20C6.11112 21.537 6.65279 22.8472 7.73612 23.9306C8.81949 25.0139 10.1297 25.5556 11.6667 25.5556H18.6112V28.3333ZM13.4722 21.3889V18.6111H16.5695L19.3334 21.3889H13.4722Z"
          fill="gray"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Unpair;
