import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../../../../common/PageHeader';
import TelephoneEntryDeviceForm from '../../../../../../common/forms/TelephoneEntryDeviceForm';

class AddTelephoneEntryDevice extends Component {
  componentDidMount() {
    this.props.getProperties();
  }

  render() {
    const { createTelephoneEntryDevice, history, propertyOptions } = this.props;

    return (
      <div>
        <PageHeader title="Add Telephone Entry Device" backLink="./" />
        <div className="container">
          <TelephoneEntryDeviceForm
            initialValues={{
              name: '',
              id: '',
              property_id: '',
              enable_resident_directory: false,
              enable_numeric_codes: false,
              enable_qr_codes: false,
              enable_call_manager: false,
            }}
            onSubmitClick={submitValues => {
              return createTelephoneEntryDevice(submitValues).then(async res => {
                if (res.response.ok) {
                  return history.push(`/access-control/telephone-entry-devices/${res.json.id}/edit/`);
                }
                throw res.json.message;
              });
            }}
            onCancelClick={() => history.goBack()}
            propertyOptions={propertyOptions}
          />
        </div>
      </div>
    );
  }
}

AddTelephoneEntryDevice.propTypes = {
  history: PropTypes.object.isRequired,
  propertyOptions: PropTypes.array.isRequired,
};

export default AddTelephoneEntryDevice;
