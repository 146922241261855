import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icons/icon';

class ConfirmButton extends Component {
  static propTypes = {
    confirmText: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    iconButton: PropTypes.bool,
  };

  static defaultProps = {
    confirmText: 'Are you sure you want to delete?',
  };

  state = {
    confirming: false,
  };

  renderConfirmation() {
    const { confirmText } = this.props;

    if (this.state.confirming) {
      return (
        <div className="delete-confirmation">
          <p>{confirmText}</p>
          <div className="delete-confirmation--button-wrapper">
            <button className="button button--destructive" type="button" onClick={this.props.onConfirm}>
              Yes
            </button>
            <button
              className="button"
              type="button"
              onClick={() => this.setState(prevState => ({ confirming: false }))}
            >
              No
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { iconButton } = this.props;

    if (iconButton) {
      return (
        <div className="delete-button--container">
          <div className="delete-button--icon" onClick={() => this.setState(prevState => ({ confirming: true }))}>
            <Icon icon="Remove" />
          </div>
          {this.renderConfirmation()}
        </div>
      );
    } else {
      return (
        <div className="delete-button--container">
          <button
            className="button button--icon button--plain delete-button-main"
            onClick={() => this.setState(prevState => ({ confirming: true }))}
            type="button"
          >
            <div className="button--children">
              <Icon icon="Remove" />
              {this.props.children}
            </div>
          </button>
          {this.renderConfirmation()}
        </div>
      );
    }
  }
}

export default ConfirmButton;
