import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Yup from 'yup';
import { trimAndSetNullFalsyValues } from '../../../../common/utils/helpers';
import InputScaffold from '../../../../common/forms/InputScaffold';
import AlertMessage from '../../../../common/AlertMessage';
import Select from 'react-select';

const validationSchema = Yup.object().shape({
  permission_name: Yup.string()
    .trim()
    .max(32, 'permission Name must be 32 characters or less')
    .required('Please enter a permission name'),
  permission_description: Yup.string()
    .trim()
    .max(255, 'permission description must be 255 characters or less')
    .required('Please enter a permission description'),
  group_id: Yup.string()
    .trim()
    .typeError('Please select a permission group')
    .required('Please select a permission group'),
  target: Yup.string()
    .trim()
    .max(64, 'permission Name must be 64 characters or less')
    .required('Please enter a target'),
});

class PermissionsForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    updatePermissionList: PropTypes.func,
  };

  state = {
    confirmDelete: false,
    confirmSave: false,
  };

  onSubmit = (values, { setSubmitting }) => {
    const { onSubmit } = this.props;
    this.setState({ confirmSave: true });
    onSubmit(trimAndSetNullFalsyValues(values)).catch(res => {
      setSubmitting(false);
    });
  };

  navigateToList = () => this.props.updatePermissionList();

  render() {
    const { initialValues, error, isAdd, groupOptions } = this.props;
    const { confirmSave } = this.state;
    return (
      <div>
        <Formik
          enbaleReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          render={({ values, errors, handleChange, handleSubmit, touched, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="container property-manager-form">
              {error && <AlertMessage msg={error} type="error" />}
              <div>
                <div className="paper radius-top-left radius-top-right RolesAndPermissions-Form-Top">
                  <InputScaffold
                    label="PERMISSION NAME"
                    required
                    validation={touched.permission_name && errors.permission_name}
                  >
                    <input
                      type="text"
                      name="permission_name"
                      maxLength="32"
                      onChange={({ target }) => {
                        setFieldValue('permission_name', target.value);
                        if (isSubmitting) {
                          this.setState({ confirmSave: false });
                        }
                      }}
                      value={values.permission_name}
                    />
                  </InputScaffold>
                  <div>
                    <InputScaffold
                      label="PERMISSION DESCRIPTION"
                      required
                      validation={touched.permission_description && errors.permission_description}
                    >
                      <textarea
                        name="permission_description"
                        maxLength="255"
                        onChange={handleChange}
                        value={values.permission_description === null ? '' : values.permission_description}
                      />
                    </InputScaffold>
                    <div>
                      <InputScaffold
                        label="PERMISSIONS GROUP"
                        required
                        validation={touched.group_id && errors.group_id}
                      >
                        <Select
                          options={groupOptions}
                          placeholder={groupOptions.length ? 'Select a group' : 'No groups available'}
                          onChange={value => setFieldValue('group_id', value)}
                          value={values.group_id}
                          simpleValue={true}
                        />
                      </InputScaffold>
                    </div>
                    <InputScaffold label="target" required validation={touched.target && errors.target}>
                      <input
                        type="text"
                        name="target"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.target === null ? '' : values.target}
                      />
                    </InputScaffold>

                    <div />
                  </div>
                </div>
              </div>

              <div className="paper__footer radius-bottom-left radius-bottom-right RolesAndPermissions-Form-bottom">
                <div className="From-Save-Button">
                  <button
                    type="submit"
                    disabled={confirmSave && (isSubmitting || Object.keys(errors).length)}
                    className="button button-min160"
                  >
                    {isAdd === 'edit' ? 'Save' : 'Create'}
                  </button>
                  <button type="button" className="button button--secondary" onClick={this.navigateToList}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withRouter(PermissionsForm);
