import { connect } from 'react-redux';
import { getPropertyPanels, createPropertyPanel, browsePanels } from '../../redux/actions';
import PropertyPanelsList from './PropertyPanelsList';

function mapStateToProps(state, props) {
  return {
    panels: state.devices.panels,
    property: state.property.activeProperty,
    properties: state.properties,
    searching: state.devices.panelListSearching,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPropertyPanels: propertyId => dispatch(getPropertyPanels(propertyId)),
    createPropertyPanel: (propertyId, dmpSerialNumber) => dispatch(createPropertyPanel(propertyId, dmpSerialNumber)),
    browsePanels: (propertyId, params) => dispatch(browsePanels(propertyId, params)),
  };
}

const PropertyPanelsListContainer = connect(mapStateToProps, mapDispatchToProps)(PropertyPanelsList);

export default PropertyPanelsListContainer;
