import { Formik } from 'formik';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Yup from 'yup';
import InputScaffold from '../../../../../common/forms/InputScaffold';
import SubHeader from '../../../../../common/SubHeader';
import { communityViewsVideoProcessingServerSelector, propertySelector } from '../../../redux/selectors';
import {
  requestVideoProcessingServerConfig,
  updateVideoProcessingServerConfig,
} from '../../../redux/actions/communityViewsActions';
import { normalizeUrl } from '../../../../../common/utils/helpers';

const validationSchema = Yup.object().shape({
  stream_time_limit: Yup.number()
    .typeError('Stream Time Limit must be a number')
    .required('Stream Time Limit is required')
    .min(1, 'Stream Time Limit must be greater than zero')
    .max(9999, 'Maximum Stream Time Limit exceeded')
    .nullable(false),
  host: Yup.string()
    .trim()
    .transform(value => {
      return normalizeUrl(value);
    })
    .url('Video Processing Server must be a valid URL with https scheme')
    .nullable(false)
    .required('A valid Video Processing Server URL is required'),
});

export const Settings = ({ history }) => {
  const dispatch = useDispatch();
  const property = useSelector(propertySelector);
  const videoProcessingServerConfig = useSelector(communityViewsVideoProcessingServerSelector);
  const [submitError, setSubmitError] = useState();
  const host = videoProcessingServerConfig?.get('host');

  useEffect(() => {
    dispatch(requestVideoProcessingServerConfig(property.get('id')));
  }, [property, dispatch, host]);

  const onSubmit = useCallback(
    (values, actions) => {
      dispatch(updateVideoProcessingServerConfig(values, property.get('id'))).then(action => {
        if (action.response.ok) {
          history.goBack();
        }
        actions.setSubmitting(false);
        setSubmitError(action.json.message);
      });
    },
    [dispatch, property, history]
  );

  function onCancel() {
    history.goBack();
  }

  return (
    <div className="container">
      <SubHeader title="Community Views Settings" />
      <Formik
        enableReinitialize={true}
        initialValues={{
          stream_time_limit: videoProcessingServerConfig?.get('stream_time_limit') ?? 30,
          host: host || '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          handleBlur,
          touched,
          validateForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="paper radius-top-left radius-top-right">
              <div className="property-details--form">
                {submitError && (
                  <div className="form-error__wrapper">
                    <span className="form-error--label">Error:&nbsp;</span>
                    {submitError}
                  </div>
                )}
                <Fragment>
                  <InputScaffold
                    required
                    label="Video Stream Time Limit (Seconds)"
                    validation={touched.stream_time_limit && errors.stream_time_limit}
                  >
                    <input
                      type="number"
                      name="stream_time_limit"
                      maxLength="4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.stream_time_limit}
                    />
                  </InputScaffold>

                  <InputScaffold required label="Video Processing Server Host" validation={touched.host && errors.host}>
                    <input
                      type="text"
                      name="host"
                      maxLength="64"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.host}
                    />
                  </InputScaffold>
                </Fragment>
              </div>
            </div>
            <div className="paper__footer radius-bottom-left radius-bottom-right">
              <button disabled={isSubmitting} className="button">
                Save Configuration
              </button>
              <button type="button" className="button button--secondary" onClick={onCancel}>
                Cancel
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
};
