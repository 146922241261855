import { useQuery } from 'react-query';
import client from '../../common/client';

export const USE_VENDOR_DETAILS_KEY = 'vendorDetails';
export const USE_VENDOR_EVENT_LIST = 'vendorEventList';

export const useVendorDetails = userId => {
  return useQuery(
    [USE_VENDOR_DETAILS_KEY, userId],
    async () => {
      const response = await client.get(`vendor/${userId}`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      enabled: typeof userId === 'string',
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
    }
  );
};

export const useVendorEventList = vendorId => {
  return useQuery(
    [USE_VENDOR_DETAILS_KEY, vendorId],
    async () => {
      const response = await client.get(`events/vendor/${vendorId}`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      enabled: typeof vendorId === 'string',
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
    }
  );
};
