import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UnrecognizedDoors from './UnrecognizedDoors';
import { getPanelDoors, addDoor, removePanelDoor } from '../../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlDoors: state.property.accessControlDoors,
    accessControlDoorsLoaded: state.property.accessControlDoorsLoaded,
    accessControlPanelDoors: state.property.accessControlPanelDoors.filter(
      p =>
        !state.property.accessControlDoors.find(
          a => parseInt(p.get('lx_number') || p.get('number'), 10) === a.get('dmp_device_number')
        )
    ),
    accessControlPanelDoorsLoaded: state.property.accessControlPanelDoorsLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPanelDoors: () => dispatch(getPanelDoors(ownProps.match.params.propertyId)),
    addDoor: payload => dispatch(addDoor(ownProps.match.params.propertyId, payload, true)),
    removePanelDoor: doorNumber => dispatch(removePanelDoor(ownProps.match.params.propertyId, doorNumber)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnrecognizedDoors));
