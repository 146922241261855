import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';

import Switch from '../../../../../../../../../../common/forms/Switch';
import InputScaffold from '../../../../../../../../../../common/forms/InputScaffold';
import ToggleScaffold from '../../../../../../../../../../common/forms/ToggleScaffold';
import NotificationStatusIndicator from '../../../../../../../../../../common/forms/NotificationStatusIndicator';
import MaskedInput from 'react-input-mask';
import { Link } from 'react-router-dom';
import Icon from '../../../../../../../../../../common/icons/icon';
import Select from 'react-select';
import Spinner from '../../../../../../../../../../common/Loading/SpinnerLoading';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(120, 'Device label must be 120 characters or less')
    .required('Please enter a device label'),
  device_label_type: Yup.string()
    .trim()
    .required('Please select a device type'),
  mastercode: Yup.number().nullable(true),
});

class AddZWaveDevice extends Component {
  static propTypes = {
    cancelPanelJobs: PropTypes.func.isRequired,
  };
  state = {
    showAddForm: true,
    showPairing: false,
    jobStatus: '',
    newDeviceJSON: {},
    pairingError: '',
    name: '',
    devicesCount: {},
    devicesLoaded: false,
    deviceType: '',
  };

  componentDidMount() {
    this.getDevicesCount();
  }

  componentWillUnmount() {
    if (this.interval) {
      this.cancelZwave();
    }
  }

  getDevicesCount = () => {
    this.props.getPanelDevices().then(action => {
      if (action.response.ok) {
        const type_arr = action.json.devices.map(d => d.dmp_hardware_type);
        const type_obj = {};
        for (const t of type_arr) {
          type_obj[t] = type_arr.reduce((n, x) => n + (x === t), 0);
        }
        this.setState(() => ({
          devicesCount: type_obj,
          devicesLoaded: true,
        }));
      }
    });
  };

  handleAddSubmit = payload => {
    this.props.requestZwaveDevice({ name: payload.name, location: payload.location }).then(action => {
      if (action.response.ok) {
        this.setState({
          showAddForm: false,
          showPairing: true,
          jobStatus: '',
          newDeviceJSON: payload,
          pairingError: '',
        });
        if (action.json.status === 'pending') {
          this.interval = setInterval(() => this.jobStatusLoop(action.json.job_number, 'add'), 1000);
        }
      } else {
        this.setState({ pairingError: 'Failed to submit form' });
      }
    });
  };

  updateLabel = (type, setFieldValue) => {
    const newLabel = this.state.devicesCount[type] ? type + ' ' + (this.state.devicesCount[type] + 1).toString() : type;
    this.setState({ name: newLabel });
    return setFieldValue('name', newLabel);
  };

  cancelZwave = () => {
    const { cancelPanelJobs, panel } = this.props;
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = false;
    }
    cancelPanelJobs({
      dmp_account_number: panel.get('dmp_account_number'),
      dmp_account_prefix: panel.get('dmp_account_prefix'),
    });
  };

  jobStatusLoop = (jobId, jobType) => {
    const { createPanelDevice, removePanelDevice, panel } = this.props;
    this.props
      .getJobStatus(jobId)
      .then(action => {
        if (action.json.status === 'error') {
          this.cancelZwave();
          this.setState({
            pairingError: `Panel returned error${action.json.error_code ? ' - ' + action.json.error_code : ''}: ${
              action.json.error_message
            }`,
          });
        }
        const success = action.json && action.json.status === 'success' ? true : false;
        let stage = action.json && action.json.stage ? action.json.stage : 'none';

        if (
          !success &&
          !['none', 'listen_ready', 'device_found', 'device_removed', 'device_added'].some(substring =>
            stage.includes(substring)
          )
        ) {
          this.cancelZwave();
          this.setState({ pairingError: 'Unexpected Stage - ' + stage });
        } else if (success) {
          clearInterval(this.interval);
          this.interval = false;
          const dmpDevice =
            action.json.response && action.json.response.nodes && action.json.response.nodes.length > 0
              ? action.json.response.nodes[0]
              : false;

          if (!dmpDevice) {
            this.setState({ pairingError: 'Orphaned device successfully excluded' });
          }

          if (jobType === 'add') {
            createPanelDevice({
              id: dmpDevice.name,
              zone: dmpDevice.zone,
              last_number: dmpDevice.number,
              name: this.state.newDeviceJSON.name,
              mastercode: this.state.newDeviceJSON.mastercode || null,
              dmp_hardware_type: this.state.deviceType,
              dmp_hardware_subtype: dmpDevice.node_subtype,
              is_community_device: this.state.newDeviceJSON.is_community_device,
              hide_battery_status: this.state.newDeviceJSON.hide_battery_status,
            })
              .then(action => {
                if (action.response.ok) {
                  this.setState({ jobStatus: 'success' });
                } else {
                  this.setState({
                    pairingError:
                      'Has this device already been paired? Go back to the Devices list and check the Unrecognized Devices to add this device.',
                  });
                }
              })
              .catch(err => {
                this.setState({
                  pairingError: 'Go back to the Devices list and check the Unrecognized Devices to add this device.',
                });
              });
          } else if (jobType === 'remove') {
            if (
              panel
                .get('devices')
                .toJS()
                .some(device => device.id === dmpDevice.name)
            ) {
              removePanelDevice(dmpDevice.name)
                .then(() => {
                  this.setState({ jobStatus: 'success' });
                })
                .catch(err => {
                  this.setState({ pairingError: 'Failed to remove device' });
                });
            } else {
              this.setState({ pairingError: 'Failed to find device to remove' });
            }
          }
        }
        if (!success && this.state.jobStatus !== stage) {
          this.setState({ jobStatus: stage });
        }
      })
      .catch(err => {
        this.cancelZwave();
        this.setState({ pairingError: 'Failed to get job status' });
      });
  };

  render() {
    return (
      <div className="add-device--wizard">
        {this.renderHeader()}
        <div className="container">
          {this.state.showAddForm && this.renderAddDevice()}
          {this.state.showPairing && this.renderPairing()}
        </div>
      </div>
    );
  }

  renderHeader = () => {
    return (
      <div className="page-header page-header__custom">
        <div className="container">
          <Link to={`./`} className="back-arrow">
            <Icon icon="ArrowLeft" />
            Back
          </Link>
          <h1 className="h1">Add New Z-Wave Device</h1>
        </div>
      </div>
    );
  };

  renderPairingError = () => {
    return (
      <div className="input-validation">
        <p>Failed to pair new device: {this.state.pairingError}</p>
      </div>
    );
  };

  renderAddDevice() {
    return (
      <Formik
        initialValues={{
          device_label_type: '',
          name: '',
          is_community_device: false,
          hide_battery_status: false,
          mastercode: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          setSubmitting(false);
          setStatus(null);
          if (typeof values.is_community_device === 'undefined') {
            values.is_community_device = true;
          }
          if (values.device_label_type !== 'LEAK_SENSOR') {
            delete values.location;
          }
          delete values.device_label_type;
          this.handleAddSubmit(values);
        }}
        onSuccess={() => this.setState(state => ({ showAddForm: false }))}
        render={({ values, errors, handleSubmit, isSubmitting, setFieldValue, status }) => (
          <form onSubmit={handleSubmit}>
            <div className="paper radius-top-left radius-top-right">
              <div className="page-header subheader underline">
                <h4 className="h4">Add New Device</h4>
              </div>
              <div className="device-form--body">
                <section>
                  <InputScaffold label="Choose a device" required validation={errors.device_label_type}>
                    <Select
                      name={'device_label_type'}
                      disabled={!this.state.devicesLoaded}
                      placeHolder={'Device Type...'}
                      options={[
                        { value: 'BARRIER_OPERATOR', label: 'Barrier' },
                        { value: 'LIGHT', label: 'Light' },
                        { value: 'LOCK', label: 'Lock' },
                        { value: 'SENSOR', label: 'Sensor' },
                        { value: 'LEAK_SENSOR', label: 'Leak Sensor' },
                        { value: 'THERMOSTAT', label: 'Thermostat' },
                        { value: 'OUTLET', label: 'Outlet' },
                      ]}
                      onChange={value => {
                        const nextValue = value ? value.value : '';
                        setFieldValue('device_label_type', nextValue);
                        setTimeout(() => {
                          this.updateLabel(nextValue, setFieldValue);
                        });
                        this.setState({ deviceType: value.value });
                      }}
                      value={values.device_label_type}
                      clearable={false}
                      searchable={false}
                      inputProps={{ readOnly: true }}
                    />
                  </InputScaffold>
                  <InputScaffold label="Device Label" required validation={errors.name}>
                    <input
                      type="text"
                      name="name"
                      maxLength="120"
                      autoFocus
                      onChange={e => {
                        const text = e.target.value;
                        this.setState(state => ({ name: text }));
                        setFieldValue('name', text);
                      }}
                      value={this.state.name || ''}
                    />
                    <div className="input-scaffold__footer">Resident will see this label in the app</div>
                  </InputScaffold>
                  <div className="radio--input__wrapper area__wrapper">
                    <div className="input__wrapper--label">Area</div>
                    <div className="radio--container">
                      <ToggleScaffold label="Unit">
                        <input
                          type="radio"
                          name="is_community_device"
                          onChange={() => {
                            setFieldValue('is_community_device', false);
                          }}
                          checked={values.is_community_device === false}
                        />
                      </ToggleScaffold>
                      <ToggleScaffold label="Community">
                        <input
                          type="radio"
                          name="is_community_device"
                          onChange={() => {
                            setFieldValue('is_community_device', true);
                          }}
                          checked={values.is_community_device === true}
                        />
                      </ToggleScaffold>
                    </div>
                  </div>
                  {values.device_label_type === 'LOCK' && (
                    <InputScaffold label="Master Code" validation={errors.mastercode}>
                      <MaskedInput
                        mask={'99999999'}
                        name="mastercode"
                        maskChar={null}
                        onChange={event => {
                          setFieldValue('mastercode', event.target.value.replace(/[^0-9]+/g, ''));
                        }}
                        value={values.mastercode}
                      />
                    </InputScaffold>
                  )}
                  {values.device_label_type === 'LEAK_SENSOR' && (
                    <InputScaffold label="Location" required validation={errors.location}>
                      <input
                        type="text"
                        name="location"
                        maxLength="120"
                        autoFocus
                        onChange={e => {
                          const text = e.target.value;
                          this.setState(state => ({ location: text }));
                          setFieldValue('location', text);
                        }}
                        value={this.state.location || ''}
                      />
                    </InputScaffold>
                  )}
                  <Switch
                    label="Hide Battery Status"
                    input={{
                      name: 'hide_battery_status',
                      value: !!values.hide_battery_status,
                      onChange: value => setFieldValue('hide_battery_status', value),
                    }}
                    disabled={false}
                  />
                </section>
              </div>
            </div>
            <div className="paper__footer radius-bottom-left radius-bottom-right">
              <NotificationStatusIndicator type="Failure" message={status} hideNotification={!status} />
              <div className="button--error__wrapper">
                <button disabled={isSubmitting || Object.keys(errors).length} className="button">
                  Continue
                </button>
                <Link className="button button--secondary--plain" to={`./`}>
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        )}
      />
    );
  }

  renderPairing() {
    return (
      <Fragment>
        <div className="paper radius-top-left radius-top-right pairing-screen">
          <div className="page-header subheader underline">
            <h4 className="h4">Adding Z-Wave Device</h4>
          </div>
          {this.state.pairingError === '' ? (
            <div>
              <div className="font-light">
                Prepare your z-wave device for inclusion following the instructions provided with the device
              </div>
              <div className="scanning__wrapper">
                {this.state.jobStatus !== 'success' ? (
                  <div className="scanning-message">
                    Scanning for new Z-Wave device. Activate the device
                    <br />
                    following the instructions provided with the device.
                  </div>
                ) : null}
                {this.state.jobStatus !== 'success' ? <Spinner /> : <Icon className="finished" icon="Finished" />}
                {(this.state.jobStatus === '' || this.state.jobStatus === 'none') && (
                  <div className="job-status">Connecting to panel...</div>
                )}
                {this.state.jobStatus === 'listen_ready' && <div className="job-status">Scanning...</div>}
                {this.state.jobStatus === 'device_found' && <div className="job-status">Device Found...</div>}
                {this.state.jobStatus === 'success' && (
                  <div className="job-status added">New {this.state.deviceType} device has been added!</div>
                )}
              </div>
            </div>
          ) : (
            this.renderPairingError()
          )}
        </div>
        <div className="paper__footer radius-bottom-left radius-bottom-right">
          <div className="button--error__wrapper">
            {this.state.jobStatus !== 'success' && this.state.pairingError === '' ? (
              <Fragment>
                <button disabled={true} className="button">
                  Include New Device
                </button>
                <button
                  className="button button--secondary"
                  type="button"
                  onClick={() => {
                    this.setState(state => ({ showPairing: false, showAddForm: true }));
                    if (this.interval) {
                      this.cancelZwave();
                    }
                  }}
                >
                  Cancel
                </button>
              </Fragment>
            ) : (
              <Fragment>
                <button
                  className="button"
                  type="button"
                  onClick={() => {
                    this.setState(state => ({ showPairing: false, showAddForm: true, devicesLoaded: false, name: '' }));
                    this.getDevicesCount();
                    if (this.interval) {
                      this.cancelZwave();
                    }
                  }}
                >
                  Add Another Device
                </button>
                <Link className="button button--secondary" to={`./`}>
                  Finish
                </Link>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AddZWaveDevice);
