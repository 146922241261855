import React from 'react';
import PropTypes from 'prop-types';
import { useTablesTextTruncate } from '../details';

export const SGTdText = ({ text }) => {
  const truncatedText = useTablesTextTruncate(text);

  return <div className="td-text">{truncatedText}</div>;
};

SGTdText.propTypes = {
  title: PropTypes.string,
};
