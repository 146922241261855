import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import Select from 'react-select';
import 'rc-time-picker/assets/index.css';
import { weekDays, startTimeDefault, endTimeDefault, timePeriod } from '../../../constants';
import InputScaffold from '../../../../../../../../../../common/forms/InputScaffold';
import Icon from '../../../../../../../../../../common/icons/icon';
import { SGTooltip, SGCheckbox } from '.';
import { useFormikContext } from 'formik';
import { useTourTimePeriod } from '../hooks';

export const SGFormAvailableTimes = () => {
  const { values, setFieldValue, isSubmitting, touched, errors, setFieldTouched } = useFormikContext();
  const { tourTimePeriod, LimitInfo } = useTourTimePeriod(timePeriod);

  const [isAllDay, setAllDay] = useState(() => {
    const { start_time, end_time } = values;

    return start_time === '00:00' && end_time === '23:59';
  });

  useEffect(() => {
    //set default start_time and end_time - checkbox selected
    if (isAllDay) {
      setFieldValue('start_time', startTimeDefault);
      setFieldValue('end_time', endTimeDefault);
      setFieldTouched('start_time', false);
      setFieldTouched('end_time', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllDay]);

  const handleChangeWeekDay = ({ target }) => {
    const { id, checked, name } = target;

    if (checked) {
      setFieldValue(name, [...values.weekdays, id]);
    } else {
      setFieldValue(
        name,
        values.weekdays.filter(item => item !== id)
      );
    }
  };

  const handleChangeTimePeriod = option => {
    if (option) {
      setFieldValue('tour_count', {
        number: option.number,
        type: option.type,
        label: option.label,
      });
    } else {
      setFieldValue('tour_count', null);
    }
  };

  const sortedNameOfDays = useMemo(() => {
    const filteredDays = values.weekdays.reduce(
      (result, code) => [...result, ...weekDays.filter(item => item.code === code)],
      []
    );
    const sortedNameOfDays = filteredDays.sort((a, b) => a.id - b.id);
    return sortedNameOfDays.map(({ title }) => title).join(', ');
  }, [values.weekdays]);

  const renderPickedDate = () => {
    const { weekdays, start_time, end_time, tour_count } = values;

    if (!start_time || !end_time || !tour_count || !weekdays.length)
      return <p className="font-small margin-clear-all">Your tour times are not set up yet.</p>;

    return (
      <p className="font-small margin-clear-all">
        Your tour will be open <span className="strong">{sortedNameOfDays}</span> from{' '}
        <span className="strong">{moment(start_time, 'hh:mm').format('LT')}</span> to{' '}
        <span className="strong">{moment(end_time, 'hh:mm').format('LT')}</span> within a{' '}
        <span className="strong">{tour_count?.label}</span> time period.
      </p>
    );
  };

  return (
    <div className="sg-times">
      <div className="divider horizontal m-t25 m-b25" />
      {renderPickedDate()}
      <div className="divider horizontal m-t25 m-b25" />
      <p className="font-small margin-clear-all sg-times-text-icon m-t40 m-b40">
        <Icon icon="Calendar" />
        <span className="text">What days of the week should self-guided tours be available?</span>
        {touched.weekdays && errors.weekdays && <span className="error-text">&nbsp;{errors.weekdays}</span>}
      </p>

      <div className="sg-times-row">
        {weekDays.map(({ id, title, code }) => (
          <SGCheckbox
            className={classNames('sg-checkbox sg-day-checkbox', {
              checked: values.weekdays.includes(code),
            })}
            key={id}
            id={code}
            name="weekdays"
            day={title}
            checked={values.weekdays.includes(code)}
            handleChange={handleChangeWeekDay}
          >
            <span>{title}</span>
          </SGCheckbox>
        ))}
      </div>
      <div className="divider horizontal m-t25 m-b25" />

      <p className="font-small margin-clear-all sg-times-text-icon m-t40 m-b40">
        <Icon icon="Clock" />
        <span className="text">Between what times should self-guided tours be available?</span>
      </p>

      <SGCheckbox
        checked={isAllDay}
        className="sg-checkbox sg-times-all-day-checkbox"
        handleChange={() => setAllDay(!isAllDay)}
      >
        <span>
          Open all day <span className="regular">(24H)</span>
        </span>
      </SGCheckbox>

      <div className="double-input__wrapper m-b40">
        <InputScaffold
          className={classNames('double-input-item', {
            'error-border': touched.start_time && errors.start_time,
          })}
          label="Start Time"
          required
          // validation={touched.start_time && errors.start_time}
        >
          <TimePicker
            name="start_time"
            showSecond={false}
            value={values.start_time && moment(values.start_time, 'HH:mm')}
            onChange={time => setFieldValue('start_time', moment(time).format('HH:mm'))}
            format="h:mm a"
            allowEmpty={false}
            use12Hours
            inputReadOnly
            minuteStep={15}
            disabled={isSubmitting || isAllDay}
          />
        </InputScaffold>
        <InputScaffold
          className={classNames('double-input-item', {
            'error-border': touched.end_time && errors.end_time,
          })}
          label="End Time"
          required
        >
          <TimePicker
            name="end_time"
            showSecond={false}
            value={values.end_time && moment(values.end_time, 'HH:mm')}
            onChange={time => setFieldValue('end_time', moment(time).format('HH:mm'))}
            format="h:mm a"
            allowEmpty={false}
            use12Hours
            inputReadOnly
            minuteStep={15}
            disabled={isSubmitting || isAllDay}
          />
        </InputScaffold>
      </div>

      <div className="divider horizontal m-t25 m-b25" />

      <p className="font-small margin-clear-all sg-times-text-icon m-t40 m-b15">
        <Icon icon="Calendar" />
        <span className="text text-tooltip">
          What time period would you like prospective residents to retain access to your community?
        </span>
        <SGTooltip
          placement="right"
          content="Based on the time period selected, residents can have access
          within selected tour availability times frames."
          minContent={true}
        >
          <span>
            <Icon icon="InfoOutline" />
          </span>
        </SGTooltip>
      </p>

      <div className="double-input__wrapper m-b40">
        <InputScaffold
          className={classNames('double-input-item', {
            'error-border': touched.tour_count && errors.tour_count,
          })}
          required
        >
          <Select
            name="tour_count"
            value={
              values.tour_count && {
                label: values.tour_count.label,
              }
            }
            onChange={handleChangeTimePeriod}
            options={tourTimePeriod}
            searchable={false}
          />
        </InputScaffold>
        {LimitInfo}
      </div>
    </div>
  );
};
