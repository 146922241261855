import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Yup from 'yup';

import AdminLayout from '../../layouts/AdminLayout';
import Loading from '../../common/Loading';
import CreateDemoForm from './CreateDemoForm';
import { trimAndPruneFalsyValues } from '../../common/utils/helpers';
import moment from 'moment-timezone';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(64, 'Name must be 64 characters or less')
    .required('Please enter a name'),
  destruction_date: Yup.date().required('Please enter a date'),
});

class CreateDemo extends Component {
  state = { submitError: null };

  componentDidMount() {
    const { getProperty } = this.props;
    getProperty();
  }

  submitFunc = (values, actions) => {
    const { name, destruction_date } = values;
    const { createDemo, history } = this.props;
    const submitValues = {
      name,
      destruction_date,
    };
    createDemo(trimAndPruneFalsyValues(submitValues)).then(action => {
      if (action.response.ok) {
        return history.push(`/properties/${action.json.id}`);
      }
      actions.setSubmitting(false);
      return this.setState({ submitError: action.json.message });
    });
  };

  handleCancel = () => this.props.history.goBack();

  render() {
    const { loaded, property } = this.props;

    if (!loaded) {
      return <Loading />;
    }

    const initialValues = {
      name: property.get('name') + ' Demo',
      destruction_date: moment()
        .local()
        .add(1, 'week')
        .format('MMMM D, YYYY'),
    };

    return (
      <AdminLayout>
        <div className="page-header">
          <div className="container">
            <div className="page-header__title">
              <h1 className="h1">Create New Demo Property</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <CreateDemoForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            submitFunc={this.submitFunc}
            submitError={this.state.submitError}
            onCancel={this.handleCancel}
          />
        </div>
      </AdminLayout>
    );
  }
}

CreateDemo.propTypes = {
  createDemo: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
};

export default CreateDemo;
