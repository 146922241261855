import { connect } from 'react-redux';
import Vendors from './Vendors';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
  };
}

const VendorsContainer = connect(mapStateToProps)(Vendors);

export default VendorsContainer;
