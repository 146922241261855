import React from 'react';
import { SGModal } from '../SGModal';
import { SGButton } from '../../shared';
import Icon from '../../../../../../../../../../common/icons/icon';

export const ResidentDenyModal = ({ width, visitorName, visible, handleCancel, handleDeny }) => (
  <SGModal visible={visible} width={width} handleCancel={handleCancel}>
    <div className="detail-modal-popup-container">
      <h1 className="detail-modal-popup-title">Are you sure you want to deny tour from {visitorName}?</h1>
      <span className="detail-modal-popup-subtitle">They will no longer be able take a self-guided tour.</span>
      <p className="text-center detail-modal-popup-message">
        An automated email will be sent to the prospective resident letting them <br />
        know their tour has been denied.
      </p>
      <div className="button-container-deny">
        <SGButton
          className="button-red"
          title="Deny Tour"
          iconComponent={<Icon icon="DenyIcon" />}
          handleClick={handleDeny}
        />
        <button className="button-no" onClick={handleCancel}>
          No, Don’t Deny
        </button>
      </div>
      <p className="text-center detail-modal-popup-message">Denied Tours will be logged in the Rejected tours tab.</p>
    </div>
  </SGModal>
);
