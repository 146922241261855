import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Label } from 'synfrastructure';

class InputScaffold extends Component {
  render() {
    const { children, className, disabled, label, required, validation, helperText } = this.props;

    const scaffoldClasses = {
      'input-scaffold': true,
      [`${className}`]: className,
    };

    const labelClasses = {
      input: true,
      'input--disabled': disabled,
    };

    return (
      <div className={classNames(scaffoldClasses)}>
        <Label className={classNames(labelClasses)}>
          {label ? (
            <span className="input-label">
              {label}
              {label && required && <span className="input-required">*</span>}
            </span>
          ) : null}
          {helperText && <div className="input-helper-text">{helperText}</div>}
          {children}
        </Label>
        {validation ? <div className="input-validation">{validation}</div> : null}
      </div>
    );
  }
}

InputScaffold.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  validation: PropTypes.any,
  helperText: PropTypes.string,
};

InputScaffold.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  label: null,
  required: false,
  validation: null,
};

export default InputScaffold;
