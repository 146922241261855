import { combineReducers } from 'redux';
import { fromJS, Map } from 'immutable';

const panels = (state = null, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANELS_SUCCESS':
    case 'GET_PANELS_SEARCH_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_PROPERTY_PANEL_SUCCESS':
      return state.push(fromJS(action.json));
    case 'PANEL_DELETE_SUCCESS':
      return state.filter(panel => panel.get('id') !== action.panel_id);
    default:
      return state;
  }
};

const panelDevices = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_PANEL_DEVICES_SUCCESS':
      const nextState = fromJS(action.json);

      // Attach zwave information to their respective LittleBird devices
      return action.json.dmp_account_prefix !== 0 && nextState.has('zwaveSetups')
        ? nextState.set(
            'devices',
            nextState.get('devices').map(device =>
              device.set(
                'zwaveSetup',
                nextState.get('zwaveSetups').find(node => node.get('name') === device.get('id'))
              )
            )
          )
        : nextState;
    case 'CREATE_DEVICE_SUCCESS':
      const newDevice = fromJS(action.json);

      // Attach zwave information to newly created devices
      return state.set(
        'devices',
        state.get('devices').push(
          newDevice.set(
            'zwaveSetup',
            state.get('zwaveSetups').find(node => node.get('name') === newDevice.get('id'))
          )
        )
      );
    case 'CREATE_DMP_DEVICE_SUCCESS':
      const newDMPDevice = fromJS(action.json);

      // Attach zwave information to newly created devices
      return state.set(
        'devices',
        state.get('devices').push(
          newDMPDevice.set(
            'zwaveSetup',
            state.get('zwaveSetups').find(node => node.get('name') === newDMPDevice.get('id'))
          )
        )
      );
    case 'UPDATE_DEVICE_SUCCESS':
      const updatedDevice = fromJS(action.json);
      if (!state || !state.get('devices')) {
        return state;
      }

      return state.set(
        'devices',
        state
          .get('devices')
          .map(device => (device.get('id') === updatedDevice.get('id') ? device.merge(updatedDevice) : device))
      );
    case 'REMOVE_DEVICE_SUCCESS':
      return state.set(
        'devices',
        state.get('devices').filter(device => device.get('id') !== action.deviceId)
      );
    case 'DELETE_DOORBELL_SUCCESS':
    case 'UNPAIR_DOORBELL_SUCCESS':
      return state.set(
        'devices',
        state.get('devices').filter(device => device.get('id') !== action.doorbellId)
      );
    case 'PAIR_PANEL_TO_UNIT_SUCCESS':
    case 'UNPAIR_PANEL_FROM_UNIT_SUCCESS':
      return state.mergeDeep(fromJS(action.json));
    default:
      return state;
  }
};

const panelListSearching = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTY_PANELS_SUCCESS':
      return false;
    case 'GET_PANELS_SEARCH_SUCCESS':
      return true;
    default:
      return state;
  }
};

const panelDevicesPairing = (state = false, action) => {
  switch (action.type) {
    case 'PAIR_PANEL_TO_UNIT_REQUEST':
    case 'UNPAIR_PANEL_FROM_UNIT_REQUEST':
      return true;
    case 'PAIR_PANEL_TO_UNIT_SUCCESS':
    case 'UNPAIR_PANEL_FROM_UNIT_SUCCESS':
    case 'PAIR_PANEL_TO_UNIT_FAILURE':
    case 'UNPAIR_PANEL_FROM_UNIT_FAILURE':
      return false;
    default:
      return state;
  }
};

const panelDevicePairingStatus = (state = { success: false, message: '' }, action) => {
  switch (action.type) {
    case 'PAIR_PANEL_TO_UNIT_SUCCESS':
      return { success: true, message: 'Successfully paired' };
    case 'UNPAIR_PANEL_FROM_UNIT_SUCCESS':
      return { success: true, message: 'Successfully unpaired' };
    case 'PAIR_PANEL_TO_UNIT_FAILURE':
      return {
        success: false,
        message: 'Unable to associate the panel to its assigned unit. Please contact SmartExperience support.',
      };
    case 'UNPAIR_PANEL_FROM_UNIT_FAILURE':
      return {
        success: false,
        message: 'Unable to disassociate the panel from its assigned unit. Please contact SmartExperience support.',
      };
    default:
      return state;
  }
};

const panelDevicesLoading = (state = true, action) => {
  switch (action.type) {
    case 'GET_PANEL_DEVICES_REQUEST':
      return true;
    case 'GET_PANEL_DEVICES_SUCCESS':
    case 'GET_PANEL_DEVICES_FAILURE':
    case 'GET_PANEL_DEVICES_ERROR':
      return false;
    default:
      return state;
  }
};

const panelDevicesError = (state = false, action) => {
  switch (action.type) {
    case 'GET_PANEL_DEVICES_FAILURE':
    case 'GET_PANEL_DEVICES_ERROR':
      return true;
    case 'GET_PANEL_DEVICES_SUCCESS':
    case 'GET_PANEL_DEVICES_REQUEST':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  panels,
  panelDevices,
  panelDevicesError,
  panelDevicesLoading,
  panelDevicesPairing,
  panelListSearching,
  panelDevicePairingStatus,
});
