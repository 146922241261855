import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

export const SgToursReducers = {
  modelUnits: (state = null, action) => {
    switch (action.type) {
      case 'GET_MODAL_UNITS':
      case 'GET_MODAL_UNITS_SUCCESS':
        return fromJS(action.json);
      case 'GET_MODAL_UNITS_FAILURE':
        return null;
      default:
        return state;
    }
  },

  staffMembers: (state = null, action) => {
    switch (action.type) {
      case 'GET_STAFF_MEMBERS':
      case 'GET_STAFF_MEMBERS_SUCCESS':
        return fromJS(action.json.results);
      case 'GET_STAFF_MEMBERS_FAILURE':
        return null;
      default:
        return state;
    }
  },

  tour: (state = null, action) => {
    switch (action.type) {
      case 'CHANGE_TOUR_STATUS_SUCCESS':
      case 'GET_TOUR_SUCCESS':
      case 'EDIT_TOUR_SUCCESS':
        return fromJS(action.json);
      case 'GET_TOUR_FAILURE':
        return null;
      default:
        return state;
    }
  },

  guest: (state = null, action) => {
    switch (action.type) {
      case 'GET_RESIDENT_BY_ID_GUEST_SUCCESS':
        return action.json;
      case 'GET_RESIDENT_BY_ID_GUEST_FAILURE':
        return null;
      default:
        return state;
    }
  },
};

export const selectModelUnits = createSelector(
  state => state.property.modelUnits,
  modelUnits => modelUnits && modelUnits.toJS()
);

export const selectStaffMembers = createSelector(
  state => state.property.staffMembers,
  staffMembers => staffMembers && staffMembers.toJS()
);
