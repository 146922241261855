import React, { Component } from 'react';
import MaskedInput from 'react-input-mask';
import Icon from '../../../../../../../../common/icons/icon.js';
import Close from '../../../../../../../../common/icons/Close';
import Search from '../../../../../../../../common/icons/Search';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import GuestItem from './GuestItem';
import ConfirmModel from './ConfirmModel';
import UploadModel from './UploadModel';
import RegisterResultModel from './RegisterResultModel';
import CSVImportResultModel from './CSVImportResultModel';

class GuestRegistration extends Component {
  state = {
    duplicate: false,
    allSelect: false,
    isSearched: false,
    csvImportResultShow: false,
    importResultList: [],
    registerResultShow: false,
    registerResultSuccess: true,
    confirmShow: false,
    uploadShow: false,
    search: '',
    guestList: [],
    selectedGuestList: [],
  };

  componentDidMount() {
    this.getGuestList();
  }

  getGuestList = search => {
    if (!search) search = 'all';
    this.props.getGuestList(search).promise.then(response => {
      if (response.ok) {
        response.json().then(json => {
          json.map(item => {
            item.selected = false;
            item.additionalNum = 0;
            return item;
          });
          this.setState({
            guestList: json,
            selectedGuestList: [],
          });
        });
      }
    });
  };

  onUpdateSearch = value => {
    this.setState({
      search: value,
      isSearched: false,
    });
  };

  onCloseSearch = () => {
    this.setState({
      search: '',
      isSearched: false,
    });
    this.getGuestList();
  };

  onSearchUser = () => {
    if (this.state.search !== '') {
      this.setState({ isSearched: true });
      this.getGuestList(this.state.search);
    }
  };

  addSelectedGuestList = item => {
    const { selectedGuestList, guestList } = this.state;
    const newSelectedGuestList = [...selectedGuestList, item];
    if (guestList.length === newSelectedGuestList.length) {
      this.setState({
        allSelect: true,
      });
    }
    this.setState({
      selectedGuestList: newSelectedGuestList,
    });
  };

  delSelectedGuestList = item => {
    const { selectedGuestList } = this.state;
    const newarr = selectedGuestList.filter(item1 => {
      return item1.id !== item.id;
    });
    this.setState({
      selectedGuestList: newarr,
      allSelect: false,
    });
  };

  getSelectedGuestList = () => {
    const { selectedGuestList } = this.state;
    return selectedGuestList;
  };

  back = () => {
    const {
      setshowRegister,
      setLastCanceledRegistration,
      refetchEventRegistrationList,
      refetchEventWaitList,
    } = this.props;
    setLastCanceledRegistration(null);
    refetchEventRegistrationList();
    refetchEventWaitList();
    setshowRegister(false);
  };

  selectAll = () => {
    const { guestList, allSelect } = this.state;
    const newGuestList = guestList.map(guest => {
      guest.selected = !allSelect;
      return guest;
    });
    let selectedGuestList;
    if (!allSelect) {
      selectedGuestList = newGuestList;
    } else {
      selectedGuestList = [];
    }
    this.setState({
      allSelect: !allSelect,
      guestList: newGuestList,
      selectedGuestList: selectedGuestList,
    });
  };

  addAdditionalNum = item => {
    const { guestList } = this.state;
    const newGuestList = guestList.map(guestItem => {
      if (guestItem.id === item.id) {
        guestItem.additionalNum += 1;
      }
      return guestItem;
    });
    this.setState({
      guestList: newGuestList,
    });
  };

  deleteAdditionalNum = item => {
    const { guestList } = this.state;
    const newGuestList = guestList.map(guestItem => {
      if (guestItem.id === item.id) {
        if (guestItem.additionalNum >= 1) {
          guestItem.additionalNum -= 1;
        }
      }
      return guestItem;
    });
    this.setState({
      guestList: newGuestList,
    });
  };

  selectGuestItem = item => {
    item.selected = !item.selected;
    const { guestList } = this.state;
    const newGuestList = guestList.map(guestItem => {
      if (guestItem.id === item.id) return item;
      return guestItem;
    });
    if (item.selected) {
      this.addSelectedGuestList(item);
    } else {
      this.delSelectedGuestList(item);
    }
    this.setState({
      guestList: newGuestList,
    });
  };

  getAddReigsterParams = () => {
    const selectedGuestList = this.getSelectedGuestList();
    const res = selectedGuestList.map(item => {
      let new_item = {};
      new_item.tickets = item.additionalNum;
      new_item.resident_id = item.id;
      new_item.first_name = item.first_name;
      new_item.last_name = item.last_name;
      return new_item;
    });
    return { residents: res };
  };

  confirmModelConfirm = () => {
    this.registerUser('pre').then(res => {
      if (res.status === 200) {
        this.setState({
          confirmShow: false,
          registerResultShow: true,
          registerResultSuccess: true,
        });
      } else {
        res.json().then(json => {
          if (json.statusCode === 406) {
            this.setState({
              confirmShow: false,
              registerResultShow: true,
              registerResultSuccess: false,
              duplicate: false,
            });
          } else {
            this.setState({
              confirmShow: false,
              registerResultShow: true,
              registerResultSuccess: false,
              duplicate: true,
            });
          }
        });
      }
    });
  };

  //type == pre,increase,waitlist
  registerUser = type => {
    const playload = this.getAddReigsterParams();
    return this.props.eventRegister(type, playload).promise;
  };

  onKeyEnter = event => {
    if (event.key === 'Enter') {
      this.onSearchUser();
    }
  };

  render() {
    const {
      search,
      selectedGuestList,
      confirmShow,
      uploadShow,
      registerResultShow,
      registerResultSuccess,
      isSearched,
      allSelect,
      importResultList,
      csvImportResultShow,
      guestList,
      duplicate,
    } = this.state;
    const { event, t } = this.props;

    return (
      <div>
        <CSVImportResultModel
          onVerify={() => {
            this.props.setshowRegister(false);
            this.props.refetchEventWaitList();
            this.props.refetchEventRegistrationList();
          }}
          onCancel={() => {
            this.setState({
              csvImportResultShow: false,
            });
          }}
          visible={csvImportResultShow}
          importResultList={importResultList}
          t={t}
        ></CSVImportResultModel>
        <RegisterResultModel
          t={t}
          event={event}
          onCancel={e => {
            e.preventDefault();
            this.setState({
              registerResultShow: false,
            });
            this.getGuestList();
          }}
          onIncreaseMax={() => {
            return this.registerUser('increase').then(res => {
              if (res.status === 200) {
                this.setState({
                  confirmShow: false,
                  registerResultShow: true,
                  registerResultSuccess: true,
                });
              } else {
                res.json().then(json => {
                  if (json.statusCode === 406) {
                    this.setState({
                      confirmShow: false,
                      registerResultShow: true,
                      registerResultSuccess: false,
                      duplicate: false,
                    });
                  } else {
                    this.setState({
                      confirmShow: false,
                      registerResultShow: true,
                      registerResultSuccess: false,
                      duplicate: true,
                    });
                  }
                });
              }
            });
          }}
          onWaitList={() => {
            return this.registerUser('waitlist').then(res => {
              if (res.status === 200) {
                this.setState({
                  confirmShow: false,
                  registerResultShow: true,
                  registerResultSuccess: true,
                });
              } else {
                res.json().then(json => {
                  if (json.statusCode === 406) {
                    this.setState({
                      confirmShow: false,
                      registerResultShow: true,
                      registerResultSuccess: false,
                      duplicate: false,
                    });
                  } else {
                    this.setState({
                      confirmShow: false,
                      registerResultShow: true,
                      registerResultSuccess: false,
                      duplicate: true,
                    });
                  }
                });
              }
            });
          }}
          onVerify={() => {
            this.props.setshowRegister(false);
            this.props.refetchEventWaitList();
            this.props.refetchEventRegistrationList();
          }}
          visible={registerResultShow}
          success={registerResultSuccess}
          duplicate={duplicate}
          OnOk={() => {
            this.getGuestList();
            this.setState({
              registerResultShow: false,
              duplicate: false,
            });
          }}
        ></RegisterResultModel>
        <UploadModel
          onCancel={() => {
            this.setState({
              uploadShow: false,
            });
          }}
          onSubmit={file => {
            return this.props
              .uploadEventCsv(file)
              .promise.then(response => {
                if (response.ok) {
                  response.json().then(json => {
                    this.setState({
                      uploadShow: false,
                      csvImportResultShow: true,
                      importResultList: json,
                    });
                  });
                  return { message: 'Success importing CSV' };
                } else {
                  const data = response.json();
                  return data;
                }
              })
              .catch(e => {
                return { message: 'Error importing CSV' };
              });
          }}
          visible={uploadShow}
          t={t}
        ></UploadModel>
        <ConfirmModel
          t={t}
          onCancel={() => {
            this.setState({
              confirmShow: false,
            });
          }}
          onConfirm={this.confirmModelConfirm}
          selectedGuestList={this.getSelectedGuestList()}
          visible={confirmShow}
        ></ConfirmModel>
        <div className="guestRegistration-header-outdiv">
          <div className="guestRegistration-title-outdiv">
            <h2>{t('guestRegistrationTab.title')}</h2>
            <div className="guestRegistration-title-subcontent">{t('guestRegistrationTab.body')}</div>
            <div className="guestRegistration-title-search-outdiv">
              <InputScaffold>
                <MaskedInput
                  name="searchResidents"
                  className="RolesAndPermissions"
                  value={search}
                  onChange={e => {
                    this.onUpdateSearch(e.target.value);
                  }}
                  placeholder={t('guestRegistrationTab.inputPlaceholder')}
                  list="SearchList"
                  autoComplete="off"
                  maxLength="25"
                  onKeyPress={this.onKeyEnter}
                />
                <div
                  className="RolesAndPermissions-icons"
                  onClick={!isSearched ? this.onSearchUser : this.onCloseSearch}
                >
                  {!isSearched ? <Search /> : <Close />}
                </div>
              </InputScaffold>
            </div>
          </div>
          <div className="guestRegistration-button-outdiv">
            <button
              type="submit"
              className="button"
              onClick={() => {
                this.setState({
                  uploadShow: true,
                });
              }}
              disabled={selectedGuestList.length > 0 ? true : false}
            >
              {t('guestRegistrationTab.importCsvButton')}
            </button>
            <button
              type="submit"
              className="button"
              onClick={() => {
                this.setState({
                  confirmShow: true,
                });
              }}
              disabled={selectedGuestList.length > 0 ? false : true}
            >
              {t('guestRegistrationTab.registerButton')}
            </button>
          </div>
          <div className="back-arrow guestRegistration-back-button" onClick={this.back}>
            <Icon icon="ArrowLeft" />
            {t('back', { ns: 'buttons' })}
          </div>
        </div>
        <div className="guestRegistration-content-outdiv">
          {isSearched && <div className="RP-User-Search-Info"> User search results for “{search}”</div>}

          <table className="table-units-list guestRegistration-table">
            <thead>
              <tr>
                <th>{t('guestRegistrationTab.regName')}</th>
                <th>{t('guestRegistrationTab.regUnit')}</th>
                <th>{t('guestRegistrationTab.regEmail')}</th>
                <th>{t('guestRegistrationTab.regAdditional')}</th>
                <th>
                  <input
                    type="checkbox"
                    onChange={e => {
                      this.selectAll(e);
                    }}
                    checked={allSelect}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {guestList.map(guest => (
                <GuestItem
                  key={guest.id}
                  guestItem={guest}
                  delSelectedGuestList={this.delSelectedGuestList}
                  addSelectedGuestList={this.addSelectedGuestList}
                  addAdditionalNum={this.addAdditionalNum}
                  deleteAdditionalNum={this.deleteAdditionalNum}
                  selectGuestItem={this.selectGuestItem}
                />
              ))}
            </tbody>
          </table>
          <button
            type="submit"
            className="button guestRegistration-register_bottom_button"
            onClick={() => {
              this.setState({
                confirmShow: true,
              });
            }}
            disabled={selectedGuestList.length > 0 ? false : true}
          >
            {t('guestRegistrationTab.registerButton')}
          </button>
        </div>
      </div>
    );
  }
}

GuestRegistration.propTypes = {};

export default GuestRegistration;
