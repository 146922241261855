import { useFormikContext } from 'formik';
import React from 'react';
import InputScaffold from '../../../../../../../../../../common/forms/InputScaffold';
import RadioFormField from '../../../../../../../../../../common/forms/RadioFormField';

export const SGFormPreTourMessage = () => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } = useFormikContext();
  const switchMaxLength = () => {
    if (values.pre_send_type === 'all') return 150;
    if (values.pre_send_type === 'message') return 150;
    if (values.pre_send_type === 'email') return 300;
    return 150;
  };

  return (
    <div className="sg-message-container m-t30">
      <InputScaffold validation={touched.pre_message && errors.pre_message}>
        <textarea
          className="sg-message-textarea"
          name="pre_message"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.pre_message}
          rows="9"
          maxLength={switchMaxLength()}
          placeholder="Type initial message to prospective residents here…"
        />
      </InputScaffold>
      <div className="areas-available">
        <div className="checkbox-list--item m-t30 or-container">
          <RadioFormField
            className="pre-tour-radio"
            error={touched.pre_message && errors.pre_send_type}
            isLabelСolor
            onChange={activeField => {
              setFieldValue('pre_send_type', activeField ? activeField.value : activeField);
            }}
            fields={[
              {
                id: 1,
                name: 'pre_send_type',
                value: 'all',
                label: 'Send as both email and text message',
                labelText: '',
              },
              {
                id: 2,
                name: 'pre_send_type',
                value: 'text',
                label: 'Send as text message only',
                labelText: '',
              },
              {
                id: 3,
                name: 'pre_send_type',
                value: 'email',
                label: 'Send as email only',
                labelText: '',
              },
            ]}
            activeValue={values.pre_send_type}
          />
        </div>
      </div>
    </div>
  );
};
