import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import NotFound from '../../../../common/NotFound';
import HelpFromManagementList from './views/HelpFromManagementList';
import HelpFromManagementItem from './views/HelpFromManagementItem';

const HelpFromManagement = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={HelpFromManagementList} />
      <Route path={`${path}/:helpFromManagementId`} component={HelpFromManagementItem} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default HelpFromManagement;
