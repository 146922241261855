export const TEMP_RANGE_PLUS_OR_MINUS = 2;
export const MIN_THERMO_TEMP = 58;
export const MAX_THERMO_TEMP = 95;

export function getDisplayTempForStatus(status) {
  switch (status.get('mode')) {
    case 'OFF':
      return '--';
    case 'COOL':
      return parseInt(status.get('setpoint_cooling'), 10);
    case 'HEAT':
      return parseInt(status.get('setpoint_heating'), 10);
    case 'AUTO':
      return Math.floor(
        (parseInt(status.get('setpoint_cooling'), 10) + parseInt(status.get('setpoint_heating'), 10)) / 2
      );
    default:
      return '--';
  }
}

export function getSetpointValues(temp, mode) {
  const setpointValues = {};
  if (mode === 'AUTO') {
    setpointValues.setpoint_cooling = temp + TEMP_RANGE_PLUS_OR_MINUS;
    setpointValues.setpoint_heating = temp - TEMP_RANGE_PLUS_OR_MINUS;
  } else if (mode === 'COOL') {
    setpointValues.setpoint_cooling = temp;
  } else if (mode === 'HEAT') {
    setpointValues.setpoint_heating = temp;
  }
  return setpointValues;
}

export function getTempRangeForMode(mode) {
  const min = mode === 'AUTO' ? MIN_THERMO_TEMP + TEMP_RANGE_PLUS_OR_MINUS : MIN_THERMO_TEMP;
  const max = mode === 'AUTO' ? MAX_THERMO_TEMP - TEMP_RANGE_PLUS_OR_MINUS : MAX_THERMO_TEMP;
  return [min, max];
}

export function clamp(value, min = MIN_THERMO_TEMP, max = MAX_THERMO_TEMP) {
  return Math.min(Math.max(value, min), max);
}

export function clampForMode(value, mode) {
  const tempRange = getTempRangeForMode(mode);
  return clamp(value, tempRange[0], tempRange[1]);
}
