import { connect } from 'react-redux';
import { getProperties, getOffer, editOffer } from '../../redux/actions';
import { selectActiveOffer, selectProperties } from '../../redux/selectors';
import EditOffer from './EditOffer';

function mapStateToProps(state) {
  return {
    propertyList: selectProperties(state),
    activeOffer: selectActiveOffer(state),
    loaded: state.activeOfferLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editOffer: (values, offerId) => dispatch(editOffer(values, offerId)),
    getProperties: () => dispatch(getProperties()),
    getOffer: offerId => dispatch(getOffer(offerId)),
  };
}
function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getOffer: () => dispatchProps.getOffer(ownProps.match.params.offerId),
    editOffer: values => dispatchProps.editOffer(values, ownProps.match.params.offerId),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditOffer);
