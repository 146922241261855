import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import querystring from 'query-string';
import Loading from '../../../../../../../../common/Loading';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import SubHeader from '../../../../../../../../common/SubHeader';
import { SearchText, SearchList } from '../../../../../../../../common/Search';

class PropertyPanelsList extends Component {
  static propTypes = {
    panels: PropTypes.object,
    property: PropTypes.object.isRequired,
    getPropertyPanels: PropTypes.func.isRequired,
    createPropertyPanel: PropTypes.func.isRequired,
    browsePanels: PropTypes.func.isRequired,
  };
  state = {
    isAddPanelFormVisible: false,
    isAddPanelVirtualFormVisible: false,
    newPanelSN: '',
    newPanelError: null,
    newPanelLoading: false,
  };
  newPanelRef = React.createRef();
  componentDidMount() {
    const { property, location } = this.props;
    this.updateData(property.get('id'), location);
  }
  componentDidUpdate(prevProps) {
    const { property, location } = this.props;
    if (prevProps.location.search !== location.search) {
      return this.updateData(property.get('id'), location);
    }
  }
  onClickClearSearch = () => {
    const { history, location } = this.props;
    return history.push(location.pathname);
  };

  updateData = (propertyId, location) => {
    if (location.search) {
      const search = querystring.parse(location.search);
      delete search.sectionName;
      if (!['ready', 'installed', 'sortSN', 'sortMostRecent'].includes(search.filter)) {
        delete search.filter;
      }
      return this.props.browsePanels(propertyId, search);
    }
    this.props.getPropertyPanels(propertyId);
  };
  toggleAddPanelForm = () => {
    this.setState(state => ({
      isAddPanelFormVisible: !state.isAddPanelFormVisible,
      isAddPanelVirtualFormVisible: false,
    }));
  };
  toggleAddPanelFormVirtual = () => {
    this.setState(state => ({
      isAddPanelVirtualFormVisible: !state.isAddPanelVirtualFormVisible,
      isAddPanelFormVisible: false,
    }));
  };
  onNewPanelSNChange = e => {
    this.setState({ newPanelSN: e.target.value });
  };
  onAddPanel = e => {
    const { match, createPropertyPanel, history } = this.props;
    e.preventDefault();
    this.setState({ newPanelError: null, newPanelLoading: true });
    createPropertyPanel(match.params.propertyId, this.state.newPanelSN).then(action => {
      if (action.response.ok) {
        this.setState({ newPanelSN: '', newPanelLoading: false }, () => {
          history.push(`${match.url}/panels/${action.json.id}`);
        });
      } else {
        this.setState(
          { newPanelError: action.json.message || 'An unknown error occurred', newPanelLoading: false },
          () => this.newPanelRef.current && this.newPanelRef.current.select()
        );
      }
    });
  };
  onAddPanelVirtual = e => {
    const { match, createPropertyPanel, history } = this.props;
    e.preventDefault();
    this.setState({ newPanelError: null, newPanelLoading: true });
    createPropertyPanel(match.params.propertyId, this.state.newPanelSN).then(action => {
      if (action.response.ok) {
        this.setState({ newPanelSN: '', newPanelLoading: false }, () => {
          history.push(`${match.url}/panels/${action.json.id}`);
        });
      } else {
        this.setState(
          { newPanelError: action.json.message || 'An unknown error occurred', newPanelLoading: false },
          () => this.newPanelRef.current && this.newPanelRef.current.select()
        );
      }
    });
  };
  onCancel = () => {
    this.setState({
      isAddPanelFormVisible: false,
      isAddPanelVirtualFormVisible: false,
      newPanelSN: '',
      newPanelError: null,
    });
  };
  renderAddPanelForm = () => {
    return (
      <form onSubmit={this.onAddPanel}>
        <InputScaffold
          label="Enter Panel SN"
          required
          disabled={this.state.newPanelLoading}
          validation={this.state.newPanelError}
        >
          <div className="input-scaffold__button--wrapper panel-sn--wrapper">
            <div className="panel-sn--input-btn">
              <input
                autoFocus
                ref={this.newPanelRef}
                type="text"
                value={this.state.newPanelSN}
                onChange={this.onNewPanelSNChange}
              />
              <button disabled={!this.state.newPanelSN} className="button" type="submit">
                Add Panel
              </button>
            </div>
            <button
              disabled={this.state.newPanelLoading}
              className="button button--secondary"
              type="button"
              onClick={this.onCancel}
            >
              Cancel
            </button>
          </div>
        </InputScaffold>
      </form>
    );
  };

  renderAddPanelVirtualForm = (panels_size, property_name) => {
    return (
      <form onSubmit={this.onAddPanelVirtual}>
        <InputScaffold label="Create Virtual Panel" disabled={true} validation={this.state.newPanelError}>
          <div className="input-scaffold__button--wrapper">
            <input
              autoFocus
              ref={this.newPanelRef}
              type="text"
              value={property_name + ' ' + (panels_size + 1)}
              onChange={this.onNewPanelSNChange}
            />
            <button disabled={!this.state.newPanelSN} className="button" type="submit">
              Add Virtual Panel
            </button>
            <button
              disabled={this.state.newPanelLoading}
              className="button button--secondary"
              type="button"
              onClick={this.onCancel}
            >
              Cancel
            </button>
          </div>
        </InputScaffold>
      </form>
    );
  };

  renderPlaceholder() {
    const { searching } = this.props;
    return (
      <div>
        {searching ? (
          <h6 className="no-results smallPadding"> There are no results for your search criteria. </h6>
        ) : (
          <h6 className="no-results smallPadding"> There are no panels added. </h6>
        )}
      </div>
    );
  }

  renderPanels = () => {
    const { panels, match } = this.props;

    return (
      <table className="table-list">
        <thead>
          <tr>
            <th scope="col">Panl SN</th>
            <th scope="col">Status</th>
            <th scope="col">Account #</th>
            <th scope="col">Unit #</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {panels.map(panel => (
            <tr key={panel.get('id')}>
              <td>{panel.get('dmp_serial_number')}</td>
              <td>{panel.get('unit') || panel.get('property') ? 'Installed' : 'Ready'}</td>
              <td>
                {panel.get('dmp_account_prefix')}-{panel.get('dmp_account_number')}
              </td>
              <td>{panel.getIn(['unit', 'number']) || (panel.get('property') && 'Master') || 'none'}</td>
              <td className="table__align-right">
                <Link className="table-row__action-button" to={`${match.url}/panels/${panel.get('id')}`}>
                  {/* {(panel.getIn(['unit', 'number']) && 'View Panel') || (!panel.get('property') && 'View Panel')} */}
                  View Panel
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render() {
    const { panels, property, match, searching } = this.props;
    if (!panels || !property) {
      return <Loading />;
    }

    return (
      <div className="devices__panels-list">
        <div className="container">
          {(searching || (!searching && panels.size > 0)) && <SearchText name="q" placeHolder="Search Devices" />}
          {searching ? (
            <button type="button" className="button" onClick={this.onClickClearSearch} style={{ marginTop: '10px' }}>
              Clear Search
            </button>
          ) : null}

          <SubHeader
            title={`${panels.size} Panel${panels.size === 1 ? '' : 's'}`}
            actions={[
              <div className="panel-subheader--actions" style={{ whiteSpace: 'nowrap' }}>
                {(panels.size > 0 || searching) && (
                  <div
                    className="status-filter__container"
                    style={{ minWidth: '200px', display: 'flex', flexDirection: 'row' }}
                  >
                    <span
                      className="status-filter--label"
                      style={{ paddingRight: '10px', display: 'block', margin: 'auto' }}
                    >
                      Filter By:{' '}
                    </span>
                    <SearchList
                      name="filter"
                      placeHolder="Filters..."
                      style={{ minWidth: '200px' }}
                      options={[
                        { value: '', label: 'None' },
                        { value: 'ready', label: 'Ready' },
                        { value: 'installed', label: 'Installed' },
                        { value: 'sortSN', label: 'Sort By Serial Number' },
                        { value: 'sortMostRecent', label: 'Sort Most Recent' },
                      ]}
                    />
                  </div>
                )}
                <Link
                  to={`${match.url}/add-panel`}
                  className="button"
                  style={{
                    whiteSpace: 'nowrap',
                    height: 'max-content',
                    display: 'block',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  Add New Panel
                </Link>
                <button
                  disabled={this.state.isAddPanelFormVisible}
                  className="button"
                  onClick={this.toggleAddPanelForm}
                  style={{
                    whiteSpace: 'nowrap',
                    height: 'max-content',
                    display: 'block',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  Add Existing Panel
                </button>
              </div>,
            ]}
          />
          {this.state.isAddPanelFormVisible && this.renderAddPanelForm()}
          {this.state.isAddPanelVirtualFormVisible &&
            this.renderAddPanelVirtualForm(this.props.panels.size, this.props.property.get('name'))}
          {panels.size > 0 ? this.renderPanels() : this.renderPlaceholder()}
        </div>
      </div>
    );
  }
}

export default withRouter(PropertyPanelsList);
