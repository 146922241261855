import React, { Component } from 'react';
import Quagga from 'quagga';

export default class Scanner extends Component {
  componentDidMount() {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: 1280,
            height: 720,
            facing: 'environment',
          },
        },
        locator: {
          patchSize: 'small',
          halfSample: true,
        },
        numOfWorkers: 4,
        frequency: 10,
        decoder: {
          readers: ['code_93_reader'],
          multiple: false,
        },
        locate: true,
      },
      function(err) {
        if (err) {
          this.props.onError(err);
          return console.error(err);
        }
        Quagga.start();
      }
    );
    Quagga.onDetected(this._onDetected);
  }

  componentWillUnmount() {
    Quagga.offDetected(this._onDetected);
    Quagga.stop();
  }

  _onDetected = result => {
    this.props.onDetected(result);
  };

  render() {
    // ID and className are used by QuaggaJS
    return <div id="interactive" className="viewport" />;
  }
}
