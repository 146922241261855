import { useQuery } from 'react-query';
import client from '../../client';

export const USE_UNIT_ACCESS_CODE_BY_ID_KEY = 'unitAccessCode';

export const useUnitAccessCodeById = unitAccessCodeId =>
  useQuery(
    [USE_UNIT_ACCESS_CODE_BY_ID_KEY, unitAccessCodeId],
    async () => {
      const response = await client.get(`unit-access-codes/${unitAccessCodeId}`);
      if (!response.ok) throw new Error('There was an error when attempting to retrieve the code.');
      return response.json();
    },
    {
      enabled: false,
      staleTime: 30000,
    }
  );
