import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Yup from 'yup';
import { Formik } from 'formik';
import InputScaffold from './InputScaffold';
import { trimAndSetNullFalsyValues } from '../utils/helpers';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(120, 'Name must be 120 characters or less')
    .required('Name is required'),
  internal_part_number: Yup.string()
    .trim()
    .max(255, 'Internal Part Number must be 255 characters or less')
    .required('Internal Part Number is required'),
  manufacturer_part_number: Yup.string()
    .trim()
    .max(255, 'Manufacturer Part Number must be 255 characters or less')
    .required('Manufacturer Part Number is required'),
  credential_payload_length: Yup.number()
    .typeError('Payload Length must be a number')
    .moreThan(0, 'Payload Length must be a number greater than 0')
    .max(2147483647, 'Payload Length must be a number less than 2,147,483,648')
    .required('Payload Length is required'),
  user_code_start_position: Yup.number()
    .typeError('User Code Start Position must be a number')
    .positive('User Code Start Position must be a positive number')
    .max(2147483647, 'User Code Start Position must be a number less than 2,147,483,648')
    .required('User Code Start Position is required'),
  user_code_length: Yup.number()
    .integer('User Code Length must be a valid integer between 1 and 2,147,483,648')
    .typeError('User Code Length must be a number')
    .moreThan(0, 'User Code Length must be a number greater than 0')
    .required('User Code Length is required'),
  site_code_required: Yup.boolean(),
  site_code_start_position: Yup.number().when('site_code_required', {
    is: val => val === true,
    then: Yup.number()
      .max(2147483647, 'User Code Start Position must be a number less than 2,147,483,648')
      .positive('Site Code Start Position must be a positive number')
      .required('Site Code Start Position is required'),
    otherwise: Yup.number().nullable(),
  }),
  site_code_length: Yup.number().when('site_code_required', {
    is: val => val === true,
    then: Yup.number()
      .moreThan(0, 'Site Code Length must be a number greater than 0')
      .integer('Site Code Length must be a valid integer between 1 and 2,147,483,648')
      .required('Site Code Length is required'),
    otherwise: Yup.number().nullable(),
  }),
});

class CredentialTypeForm extends Component {
  state = { submitError: null };

  setSubmitError = err => this.setState({ submitError: err });

  render() {
    const { initialValues, onSubmitClick, onCancelClick } = this.props;
    const { submitError } = this.state;

    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) =>
            onSubmitClick(trimAndSetNullFalsyValues(values)).catch(err => {
              actions.setSubmitting(false);
              return this.setSubmitError(err);
            })
          }
          render={({ values, errors, handleChange, handleSubmit, isSubmitting, setFieldValue, touched }) => (
            <form onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius-top-right">
                <div className="property-details--form">
                  {submitError && (
                    <div className="form-error__wrapper">
                      <span className="form-error--label">Error:&nbsp;</span>
                      {submitError}
                    </div>
                  )}
                  <InputScaffold label="Name" required validation={touched.name && errors.name}>
                    <input type="text" name="name" maxLength="64" onChange={handleChange} value={values.name} />
                  </InputScaffold>

                  <InputScaffold
                    required
                    label="Internal Part #"
                    validation={touched.internal_part_number && errors.internal_part_number}
                  >
                    <input
                      type="text"
                      id="internal_part_number"
                      maxLength="255"
                      onChange={handleChange}
                      value={values.internal_part_number}
                    />
                  </InputScaffold>
                  <InputScaffold
                    required
                    label="Manufacturer Part #"
                    validation={touched.manufacturer_part_number && errors.manufacturer_part_number}
                  >
                    <input
                      type="text"
                      id="manufacturer_part_number"
                      maxLength="255"
                      onChange={handleChange}
                      value={values.manufacturer_part_number}
                    />
                  </InputScaffold>
                  <InputScaffold
                    required
                    label="Payload Length"
                    validation={touched.credential_payload_length && errors.credential_payload_length}
                  >
                    <input
                      type="number"
                      id="credential_payload_length"
                      maxLength="64"
                      onChange={handleChange}
                      value={values.credential_payload_length}
                    />
                  </InputScaffold>
                  <InputScaffold
                    required
                    label="User Code Start Position"
                    validation={touched.user_code_start_position && errors.user_code_start_position}
                  >
                    <input
                      type="number"
                      id="user_code_start_position"
                      onChange={handleChange}
                      value={values.user_code_start_position}
                    />
                  </InputScaffold>
                  <InputScaffold
                    required
                    label="User Code Length"
                    validation={touched.user_code_length && errors.user_code_length}
                  >
                    <input
                      type="number"
                      id="user_code_length"
                      onChange={handleChange}
                      value={values.user_code_length}
                    />
                  </InputScaffold>
                  <div className="checkbox-list--item">
                    <label>
                      <input
                        type="checkbox"
                        id="site_code_required"
                        onChange={e => {
                          setFieldValue('site_code_required', e.target.checked);

                          if (!e.target.checked) {
                            setFieldValue('site_code_start_position', '');
                            setFieldValue('site_code_length', '');
                          }
                        }}
                        checked={values.site_code_required}
                      />
                      <span>Site Code</span>
                    </label>
                  </div>
                  {values.site_code_required && [
                    <InputScaffold
                      required
                      label="Site Code Start Position"
                      key="site_code_start_position"
                      validation={touched.site_code_start_position && errors.site_code_start_position}
                    >
                      <input
                        type="number"
                        id="site_code_start_position"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.site_code_start_position}
                      />
                    </InputScaffold>,
                    <InputScaffold
                      required
                      label="Site Code Length"
                      key="site_code_length"
                      validation={touched.site_code_length && errors.site_code_length}
                    >
                      <input
                        type="number"
                        id="site_code_length"
                        maxLength="64"
                        onChange={handleChange}
                        value={values.site_code_length}
                      />
                    </InputScaffold>,
                  ]}
                </div>
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <button disabled={isSubmitting} className="button">
                  Save Credential Type
                </button>
                <button type="button" className="button button--secondary" onClick={onCancelClick}>
                  Cancel
                </button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

CredentialTypeForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

export default CredentialTypeForm;
