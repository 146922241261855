import { Component } from 'react';
import store from 'store2';
import uuid from 'uuid';
import { withRouter } from 'react-router-dom';
import config from '../config';

class Login extends Component {
  componentDidMount() {
    // Detect when user is navigating back to cached state in Mobile Safari and redirect
    // to the route that brought us here, to prevent rendering a blank screen
    window.onpageshow = event => {
      if (event.persisted) {
        this.props.history.goBack();
      }
    };

    const nonce = uuid();
    store.set('nonce', nonce);

    document.location.replace(
      [
        config.auth.domain,
        `/op/auth?client_id=`,
        config.auth.clientId,
        '&response_type=',
        config.auth.responseType,
        '&scope=',
        config.auth.scope,
        '&redirect_uri=',
        config.auth.redirectUri,
        '&nonce=',
        nonce,
      ].join('')
    );
  }

  render() {
    return null;
  }
}

export default withRouter(Login);
