import React from 'react';
import InputScaffold from '../../../../../../../../../common/forms/InputScaffold';
import Search from '../../../../../../../../../common/icons/Search';
import Close from '../../../../../../../../../common/icons/Close';

export const SGSearch = ({ searchValue, handleInputSearchChange, handleInputClearValue }) => (
  <InputScaffold className="sg-unit-search">
    <input
      name="units"
      value={searchValue}
      onChange={handleInputSearchChange}
      placeholder="Search"
      autoComplete="off"
      maxLength="25"
    />
    <div className="sg-unit-search-icons" onClick={handleInputClearValue}>
      {!searchValue ? <Search /> : <Close />}
    </div>
  </InputScaffold>
);
