import client from '../../../common/client';
import config from '../../../common/config';
import querystring from 'querystring';

export const getProperty = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}`),
    type: 'GET_PROPERTY',
  };
};

export const resetProperty = () => {
  return {
    type: 'GET_PROPERTY_RESET',
  };
};

export const createUnit = (payload, propertyId) => {
  return {
    promise: client.post(`properties/${propertyId}/units`, payload),
    type: 'CREATE_UNIT',
  };
};

export const editProperty = (payload, propertyId) => {
  return {
    promise: client.patch(`properties/${propertyId}`, payload),
    type: 'EDIT_PROPERTY_SETTINGS',
  };
};

export const createDemo = (payload, propertyId) => {
  return {
    promise: client.post(`properties/${propertyId}/create-demo`, payload),
    type: 'CREATE_DEMO',
  };
};

export const browseUnits = (propertyId, params = {}) => {
  const queryParams = params ? '?' + querystring.stringify(params) : '';
  return {
    promise: client.get(`properties/${propertyId}/units${queryParams}`),
    type: params.q || params.group_id || params.filter ? 'GET_UNITS_SEARCH' : 'GET_UNITS',
  };
};

export const resetUnits = () => {
  return {
    type: 'GET_UNITS_RESET',
  };
};

export const getAmenities = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/amenities`),
    type: 'GET_AMENITIES',
  };
};

export const getAmenity = (propertyId, amenityId) => {
  return {
    promise: client.get(`properties/${propertyId}/amenities/${amenityId}`),
    type: 'GET_AMENITY',
  };
};

export const editAmenities = (propertyId, amenityId, payload) => {
  return {
    promise: client.put(`properties/${propertyId}/amenities/${amenityId}`, payload),
    type: 'EDIT_AMENITIES',
    amenityId,
  };
};

export const getMaintenanceConfiguration = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/maintenance/configuration`),
    type: 'GET_MAINTENANCE_CONFIGURATION',
  };
};

export const saveMaintenanceConfiguration = (propertyId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/maintenance/configuration`, payload),
    type: 'SAVE_MAINTENANCE_CONFIGURATION',
  };
};

export const editMaintenanceConfiguration = (propertyId, payload) => {
  return {
    promise: client.put(`properties/${propertyId}/maintenance/configuration`, payload),
    type: 'EDIT_MAINTENANCE_CONFIGURATION',
  };
};

export const createAmenities = (propertyId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/amenities`, payload),
    type: 'CREATE_AMENITIES',
  };
};

export const removeAmenities = (propertyId, amenityId) => {
  return {
    promise: client.delete(`properties/${propertyId}/amenities/${amenityId}`),
    type: 'REMOVE_AMENITIES',
    amenityId,
  };
};

export const getGroups = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/groups`),
    type: 'GET_GROUPS',
  };
};

export const editGroups = (propertyId, groupId, payload) => {
  return {
    promise: client.put(`properties/${propertyId}/groups/${groupId}`, payload),
    type: 'EDIT_GROUP',
    groupId,
  };
};

export const createGroups = (propertyId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/groups`, payload),
    type: 'CREATE_GROUPS',
  };
};

export const removeGroups = (propertyId, groupId) => {
  return {
    promise: client.delete(`properties/${propertyId}/groups/${groupId}`),
    type: 'REMOVE_GROUPS',
    groupId,
  };
};

export const createMessage = (payload, propertyId) => {
  return {
    promise: client.post(`properties/${propertyId}/messages`, payload),
    type: 'CREATE_MESSAGE',
  };
};

export const editMessage = (payload, messageId, propertyId) => {
  return {
    promise: client.patch(`properties/${propertyId}/messages/${messageId}`, payload),
    type: 'EDIT_MESSAGE',
  };
};

export const removeMessage = (messageId, propertyId) => {
  return {
    promise: client.delete(`properties/${propertyId}/messages/${messageId}`),
    type: 'REMOVE_MESSAGE',
  };
};

export const getAnnouncements = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/messages`),
    type: 'GET_MESSAGES',
  };
};

export const pinAnnouncement = (propertyId, announcementId, pin) => {
  return {
    promise: client.patch(`communities/${propertyId}/announcements/${announcementId}/pin`, { pinned: pin }),
    type: 'PIN_ANNOUNCEMENT',
  };
};

export const getPosts = (propertyId, page = 1, pageSize = 30, type = 'all') => {
  return {
    promise: client.get(
      `properties/${propertyId}/posts?page=${page}&pageSize=${pageSize}${
        type !== 'all' ? `&type=${type}` : ``
      }&includeDeleted=true`
    ),
    type: 'GET_POSTS',
  };
};

export const getPendingPosts = (propertyId, page = 1, pageSize = 30) => {
  return {
    promise: client.get(`properties/${propertyId}/posts/pending?page=${page}&pageSize=${pageSize}`),
    type: 'GET_PENDING_POSTS',
  };
};

export const getPost = (propertyId, postId) => {
  return {
    promise: client.get(`properties/${propertyId}/posts/${postId}`),
    type: 'GET_POST',
  };
};

export const createPost = (propertyId, type, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/posts/${type}`, payload),
    type: 'CREATE_POST',
  };
};

export const createComment = (propertyId, postId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/posts/${postId}/comment`, payload),
    type: 'CREATE_POST',
  };
};

export const deletePost = postId => {
  return {
    promise: client.delete(`posts/${postId}`),
    type: 'DELETE_POST',
  };
};

export const rejectPost = (propertyId, postId, payload) => {
  return {
    promise: client.patch(`${propertyId}/posts/${postId}/reject`, payload),
    postId,
    type: 'REJECT_POST',
  };
};

export const approvePost = (propertyId, postId, payload) => {
  return {
    promise: client.patch(`${propertyId}/posts/${postId}/approve`, payload),
    postId,
    type: 'APPROVE_POST',
  };
};

export const approvePendingPosts = propertyId => {
  return {
    promise: client.patch(`posts/${propertyId}/approve-all`),
    type: 'APPROVE_PENDING_POSTS',
  };
};

export const deleteComment = commentId => {
  return {
    promise: client.delete(`comments/${commentId}`),
    type: 'DELETE_COMMENT',
  };
};

export const banUserForPost = postId => {
  return { promise: client.put(`posts/${postId}/ban`), type: 'BAN_USER' };
};

export const banUserForComment = commentId => {
  return { promise: client.put(`comments/${commentId}/ban`), type: 'BAN_USER' };
};

export const unbanUser = userId => {
  return {
    promise: client.delete(`users/${userId}/ban`),
    type: 'UNBAN_USER',
    meta: { userId },
  };
};

export const getMessage = (propertyId, messageId) => {
  return {
    promise: client.get(`properties/${propertyId}/messages/${messageId}`),
    type: 'GET_MESSAGE',
  };
};

export const addPropertyManager = (propertyId, data) => {
  const payload = {
    clientId: config.auth.clientId,
    redirectUri: config.auth.redirectUri,
    scope: config.auth.scope,
    responseType: config.auth.responseType,
    ...data,
  };

  return {
    promise: client.post(`properties/${propertyId}/property-managers`, payload),
    type: 'CREATE_PROPERTY_MANAGER',
  };
};

export const editPropertyManager = (propertyId, propertyManagerId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/property-managers/${propertyManagerId}`, payload),
    type: 'EDIT_PROPERTY_MANAGER',
    meta: {
      propertyId,
    },
  };
};

export const removePropertyManager = (propertyId, propertyManagerId) => {
  return {
    promise: client.delete(`properties/${propertyId}/property-managers/${propertyManagerId}`),
    type: 'REMOVE_PROPERTY_MANAGER',
    propertyManagerId,
  };
};

export const resendInvite = (propertyId, userId, userType, unitId) => {
  const payload = {
    clientId: config.auth.clientId,
    scope: config.auth.scope,
    responseType: config.auth.responseType,
  };

  if (unitId) {
    payload.unitId = unitId;
  }

  return {
    promise: client.post(`properties/${propertyId}/users/${userId}/resend-invite/${userType}`, payload),
    type: 'RESEND_INVITE',
  };
};

export const getCommunityDevices = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/community-devices`),
    type: 'GET_COMMUNITY_DEVICES',
  };
};

export const createMailHub = (propertyId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/mail-hubs`, payload),
    type: 'CREATE_MAIL_HUB',
  };
};

export const editMailHub = (propertyId, mailHubId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/mail-hubs/${mailHubId}`, payload),
    type: 'EDIT_MAIL_HUB',
  };
};

export const getMailHubs = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/mail-hubs`),
    type: 'GET_MAIL_HUBS',
  };
};

export const removeMailHub = (propertyId, mailHubId) => {
  return {
    promise: client.delete(`properties/${propertyId}/mail-hubs/${mailHubId}`),
    type: 'DELETE_MAIL_HUB',
  };
};

export const setVacantUnitAutomation = (propertyId, payload) => {
  return {
    promise: client.put(`properties/${propertyId}/vacant-units`, payload),
    type: 'SET_VACANT_UNIT_AUTOMATION',
  };
};

export const getVacantUnitAutomation = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/vacant-units`),
    type: 'GET_VACANT_UNIT_AUTOMATION',
  };
};

export const uploadUnitCsv = (propertyId, file) => {
  const data = new FormData();
  data.append('file', file, file.name);
  return {
    promise: client.post(`properties/${propertyId}/units/upload`, data),
    type: 'UPLOAD_UNIT_CSV',
  };
};

export const getDevicesForProperty = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/devices`),
    type: 'GET_PROPERTY_DEVICES',
  };
};
export const getAllResidents = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/allResidents`),
    type: 'GET_ALL_RESIDENTS',
  };
};
export const getResidents = (propertyId, query, page, pageSize, autocomplete) => {
  query = query || {};
  if (page >= 0 && pageSize) {
    query.page = page;
    query.page_size = pageSize;
  }
  if (autocomplete) {
    query.autocomplete = 'true';
  }
  return {
    promise: client.get(`properties/${propertyId}/residents`, query),
    type: 'GET_RESIDENTS',
  };
};
export const acceptOrDeclineDeviceSwitch = (residentId, acceptOrDecline, propertyId) => {
  let payload = {
    acceptOrDecline: acceptOrDecline,
  };
  return {
    promise: client.patch(`properties/${propertyId}/residents/${residentId}/device-switch`, payload),
    type: 'ACCEPT_OR_DECLINE_DEVICE_SWITCH',
  };
};
export const addPropertyGuest = (propertyId, guestData) => {
  return {
    promise: client.post(`properties/${propertyId}/guests`, guestData),
    type: 'ADD_PROPERTY_GUEST',
  };
};

export const editPropertyGuest = (propertyId, guestId, guestData) => {
  return {
    promise: client.patch(`properties/${propertyId}/guests/${guestId}`, guestData),
    type: 'EDIT_PROPERTY_GUEST',
  };
};

export const getPropertyGuests = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/guests`),
    type: 'GET_PROPERTY_GUESTS',
  };
};

export const getUnitGuestList = (propertyId, query) => {
  return {
    promise: client.get(`properties/${propertyId}/guest-list`, query),
    type: 'GET_UNIT_GUEST_LIST',
  };
};

export const revokeUnitGuestCommunityAccess = (propertyId, guestId, reason) => {
  return {
    promise: client.post(`properties/${propertyId}/guest-list/guests/${guestId}/revoke-community-access`, {
      reason: reason || '',
    }),
    type: 'REVOKE_UNIT_GUEST_COMMUNITY_ACCESS',
    propertyId,
    guestId,
  };
};

export const removePropertyGuest = (propertyId, guestId) => {
  return {
    promise: client.delete(`properties/${propertyId}/guests/${guestId}`),
    type: 'REMOVE_PROPERTY_GUEST',
    guestId,
  };
};

export const addArea = (propertyId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/access-control/areas`, payload),
    type: 'CREATE_PROPERTY_AREA',
    payload,
  };
};

export const editArea = (propertyId, areaId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/access-control/areas/${areaId}`, payload),
    type: 'EDIT_PROPERTY_AREA',
  };
};

export const removeArea = (propertyId, area) => {
  return {
    promise: client.delete(`properties/${propertyId}/access-control/areas/${area.get('id')}`),
    type: 'REMOVE_PROPERTY_AREA',
    area,
  };
};

export const getPropertyAreas = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/access-control/areas`),
    type: 'GET_PROPERTY_AREAS',
  };
};

// Fetches areas from the panel directly
export const getPropertyPanelAreas = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/access-control/panel-areas`),
    type: 'GET_PROPERTY_PANEL_AREAS',
  };
};

export const removePanelArea = (propertyId, areaNumber) => {
  return {
    promise: client.delete(`properties/${propertyId}/access-control/panel-areas/${areaNumber}`),
    type: 'REMOVE_PROPERTY_PANEL_AREA',
    areaNumber,
  };
};

export const addDoor = (propertyId, payload, importing) => {
  return {
    promise: client.post(
      `properties/${propertyId}/access-control/doors?importing=${importing ? 'true' : 'false'}`,
      payload
    ),
    type: 'CREATE_PROPERTY_DOOR',
    payload,
  };
};

export const editDoor = (propertyId, doorId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/access-control/doors/${doorId}`, payload),
    type: 'EDIT_PROPERTY_DOOR',
  };
};

export const removeDoor = (propertyId, door) => {
  return {
    promise: client.delete(`properties/${propertyId}/access-control/doors/${door.get('id')}`),
    type: 'REMOVE_PROPERTY_DOOR',
    door,
  };
};

export const getDoors = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/access-control/doors`),
    type: 'GET_PROPERTY_DOORS',
  };
};

// Fetches doors from the panel directly
export const getPanelDoors = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/access-control/panel-doors`),
    type: 'GET_PROPERTY_PANEL_DOORS',
  };
};

export const removePanelDoor = (propertyId, doorNumber) => {
  return {
    promise: client.delete(`properties/${propertyId}/access-control/panel-doors/${doorNumber}`),
    type: 'REMOVE_PROPERTY_PANEL_DOOR',
    doorNumber,
  };
};

export const addSchedule = (propertyId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/access-control/schedules`, payload),
    type: 'CREATE_PROPERTY_SCHEDULE',
    payload,
  };
};

export const editSchedule = (propertyId, scheduleId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/access-control/schedules/${scheduleId}`, payload),
    type: 'EDIT_PROPERTY_SCHEDULE',
  };
};

export const removeSchedule = (propertyId, schedule) => {
  return {
    promise: client.delete(`properties/${propertyId}/access-control/schedules/${schedule.get('id')}`),
    type: 'REMOVE_PROPERTY_SCHEDULE',
    schedule,
  };
};

export const getPropertySchedules = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/access-control/schedules`),
    type: 'GET_PROPERTY_SCHEDULES',
  };
};

// Fetches schedules from the panel directly
export const getPropertyPanelSchedules = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/access-control/panel-schedules`),
    type: 'GET_PROPERTY_PANEL_SCHEDULES',
  };
};

export const removePanelSchedule = (propertyId, scheduleNumber) => {
  return {
    promise: client.delete(`properties/${propertyId}/access-control/panel-schedules/${scheduleNumber}`),
    type: 'REMOVE_PROPERTY_PANEL_SCHEDULE',
    scheduleNumber,
  };
};

export const addProfile = (propertyId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/access-control/profiles`, payload),
    type: 'CREATE_PROPERTY_PROFILE',
    payload,
  };
};

export const editProfile = (propertyId, profileId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/access-control/profiles/${profileId}`, payload),
    type: 'EDIT_PROPERTY_PROFILE',
    profileId,
  };
};

export const removeProfile = (propertyId, profile) => {
  return {
    promise: client.delete(`properties/${propertyId}/access-control/profiles/${profile.get('id')}`),
    type: 'REMOVE_PROPERTY_PROFILE',
    profile,
  };
};

export const getProfiles = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/access-control/profiles`),
    type: 'GET_PROPERTY_ACCESS_PROFILES',
  };
};

// Fetches profiles from the panel directly
export const getPanelProfiles = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/access-control/panel-profiles`),
    type: 'GET_PROPERTY_PANEL_PROFILES',
  };
};

export const removePanelProfile = (propertyId, profileNumber) => {
  return {
    promise: client.delete(`properties/${propertyId}/access-control/panel-profiles/${profileNumber}`),
    type: 'REMOVE_PROPERTY_PANEL_PROFILE',
    profileNumber,
  };
};

export const addAccessPoint = (propertyId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/access-points`, payload),
    type: 'CREATE_ACCESS_POINT',
    payload,
  };
};

export const editAccessPoint = (propertyId, accessPointId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/access-points/${accessPointId}`, payload),
    type: 'EDIT_ACCESS_POINT',
  };
};

export const removeAccessPoint = (propertyId, accessPoint) => {
  return {
    promise: client.delete(`properties/${propertyId}/access-points/${accessPoint.get('id')}`),
    type: 'REMOVE_ACCESS_POINT',
    accessPoint,
  };
};

export const getAccessPoints = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/access-points`),
    type: 'GET_PROPERTY_ACCESS_POINTS',
  };
};

export const getAccessRequests = (propertyId, query) => {
  return {
    promise: client.get(`properties/${propertyId}/access-requests`, query),
    type: 'GET_PROPERTY_ACCESS_REQUESTS',
  };
};

export const createFeatureDetailsRequest = (propertyId, feature) => {
  return {
    promise: client.post(`properties/${propertyId}/feature-details-requests`, { feature }),
    type: 'CREATE_FEATURE_DETAILS_REQUEST',
  };
};

export const getPropertyManager = (property_id, property_manager_id) => {
  return {
    promise: client.get(`properties/${property_id}/property-managers/${property_manager_id}`),
    type: 'GET_PROPERTY_MANAGER',
  };
};

export const resetPropertyManager = () => {
  return {
    type: 'GET_PROPERTY_MANAGER_RESET',
  };
};

export const getMRIConfiguration = communityId => {
  return {
    promise: client.get(`properties/${communityId}/integrations/mri`),
    type: 'GET_MRI_CONFIGURATION',
  };
};

export const saveMRIConfiguration = (communityId, mriValues) => {
  return {
    promise: client.post(`properties/${communityId}/integrations/mri`, mriValues),
    type: 'SAVE_MRI_CONFIGURATION',
  };
};

export const updateMRIConfiguration = (communityId, mriValues) => {
  return {
    promise: client.patch(`properties/${communityId}/integrations/mri`, mriValues),
    type: 'UPDATE_MRI_CONFIGURATION',
  };
};

export const testMRIConfiguration = (communityId, mriValues) => {
  return {
    promise: client.post(`properties/${communityId}/integrations/mri/test`, mriValues),
    type: 'TEST_MRI_CONFIGURATION',
  };
};

export const setCommunityAccessCodeOnCommunityGuest = (communityGuestId, communityAccessCode) => {
  return {
    type: 'SET_COMMUNITY_ACCESS_CODE_ON_COMMUNITY_GUEST',
    communityGuestId,
    communityAccessCode,
  };
};

export const setCommunityAccessCodeOnCommunityStaff = (communityStaffId, communityAccessCode) => {
  return {
    type: 'SET_COMMUNITY_ACCESS_CODE_ON_COMMUNITY_STAFF',
    communityStaffId,
    communityAccessCode,
  };
};

export const getVendors = communityId => {
  return {
    promise: client.get(`community-management/community/${communityId}/vendors`),
    type: 'GET_VENDORS',
  };
};

export const addVendor = (communityId, name, email) => {
  const payload = {
    community_id: communityId,
    name,
    email,
  };

  return {
    promise: client.post(`community-management/community/${communityId}/vendors`, payload),
    type: 'ADD_VENDOR',
  };
};

export const deleteVendor = (vendorId, communityId) => {
  return {
    promise: client.delete(`community-management/community/${communityId}/vendors/${vendorId}`),
    type: 'DELETE_VENDOR',
  };
};

export const updateVendor = (vendorId, updatedData, communityId) => {
  return {
    promise: client.patch(`community-management/community/${communityId}/vendors/${vendorId}`, updatedData),
    type: 'UPDATE_VENDOR',
  };
};
