import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { addPropertyGuest, getProfiles } from '../../../../../../redux/actions';
import {
  selectAccessControlProfileOptions,
  selectDefaultAccessControlProfilesForUserType,
} from '../../../../../../redux/selectors';
import AddPropertyGuest from './AddPropertyGuest';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlProfilesLoaded: state.property.accessControlProfilesLoaded,
    accessControlProfileOptions: selectAccessControlProfileOptions(state),
    accessControlProfileDefaults: selectDefaultAccessControlProfilesForUserType(state, 'property_guest'),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    addPropertyGuest: values => dispatch(addPropertyGuest(props.match.params.propertyId, values)),
    getProfiles: () => dispatch(getProfiles(props.match.params.propertyId)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(AddPropertyGuest);
