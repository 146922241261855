import { useState } from 'react';

export const useGuestImages = imageUrl => {
  const [imgSrc, setImgSrc] = useState('');
  const [activeModal, setActiveModal] = useState(false);
  const images = imageUrl.split(';');

  const showPicture = e => {
    setImgSrc(e.target.src);
    setActiveModal(true);
  };

  return {
    images,
    activeModal,
    imgSrc,
    showPicture,
    handleCancelModal: () => setActiveModal(false),
  };
};
