import React from 'react';
import {
  SGPageHeader,
  SGSortSearchRow,
  SGTdContact,
  SGTdName,
  SGTdStatus,
  SGTdText,
  SGTdTour,
  useFetchTourGuests,
  useSortSearch,
  useToDetail,
  usePhoneFormating,
} from '../../../common';

import Loading from '../../../../../../../../../common/Loading';

export const SGPastTab = () => {
  const { isLoad, dataLoaded } = useFetchTourGuests({
    search: '',
    sort_by: 0,
    type: 'past',
  });
  const { dataResult, total, ...rest } = useSortSearch(dataLoaded);
  const { goToDetail } = useToDetail();
  const getFormattedPhone = usePhoneFormating();

  if (isLoad) return <Loading />;

  return (
    <div className="sg-tab-content container m-b30">
      <SGPageHeader
        title="Past Self-Guided Tours"
        subTitle="This is a log of self-guided tours that have already happened. You can
        view the access log for each one."
      />

      <SGSortSearchRow total={`${total} past`} {...rest} />

      <div className="tab-content">
        <table className="tab-table">
          <thead>
            <tr>
              <th>NAME</th>
              <th>CONTACT</th>
              <th>STATUS</th>
              <th>TOUR</th>
              <th className="w350">NOTES</th>
            </tr>
          </thead>
          <tbody>
            {dataResult.map(({ id, first_name, last_name, phone, email, tour, apartment_messages, access_log }) => (
              <tr key={id} className="tab-tr" onClick={() => goToDetail('past', id)}>
                <td className="tab-td">
                  <SGTdName name={`${first_name} ${last_name}`} identification />
                </td>
                <td className="tab-td">
                  <SGTdContact phone={getFormattedPhone(phone)} email={email} />
                </td>
                <td className="tab-td">
                  <SGTdStatus status={!!access_log.length} />
                </td>
                <td className="tab-td">
                  <SGTdTour units={tour.units} />
                </td>
                <td className="tab-td">
                  <SGTdText text={apartment_messages} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {!dataResult.length && <div className="empty-text">There are no tours at the moment.</div>}
      </div>
    </div>
  );
};
