import { connect } from 'react-redux';
import PropertyGuests from './PropertyGuests';
import { getPropertyGuests } from '../../../../redux/actions';
import { getCredentialTypesForProperty } from '../../../../../../redux/actions';

const mapStateToProps = state => {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPropertyGuests: () => dispatch(getPropertyGuests(ownProps.match.params.propertyId)),
  getCredentialTypes: () => dispatch(getCredentialTypesForProperty(ownProps.match.params.propertyId)),
});

const PropertyGuestsContainer = connect(mapStateToProps, mapDispatchToProps)(PropertyGuests);

export default PropertyGuestsContainer;
