import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputGeneral = ({ regex, name, onChange, maxLength, value, placeholder, error }) => {
  const onInputChange = e => {
    if (regex.test(e.target.value)) {
      onChange(e);
    }
  };

  return (
    <div
      className={classNames({
        'error-input': error,
      })}
    >
      <input
        type="text"
        name={name}
        maxLength={maxLength}
        onChange={onInputChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

InputGeneral.propTypes = {
  regex: PropTypes.instanceOf(RegExp),
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  value: PropTypes.string,
};

export default InputGeneral;
