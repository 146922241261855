import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AmenitiesKebab from './AmenitiesKebab';
import AmenitiesForm from './AmenitiesForm';
import Delete from '../../../../../../../../common/Delete';
class AmenityItem extends Component {
  state = {
    mode: 'view',
    showRemove: false,
    showingMenu: false,
    shortenItem: true,
  };

  toggleShowingMenu = () => this.setState(state => ({ showingMenu: !state.showingMenu }));

  toggleShortenItem = () => this.setState(state => ({ shortenItem: !state.shortenItem }));

  toggleRemove = () => {
    if (this.state.shortenItem && this.props.data.get('description')) {
      this.toggleShortenItem();
    }
    return this.setState(state => ({ showRemove: !state.showRemove, showingMenu: false }));
  };

  switchMode = mode => this.setState(state => ({ mode }));

  handleRemoveAmenity = () => this.props.removeAmenities(this.props.data.get('property_id'), this.props.data.get('id'));

  handleCancel = () => this.switchMode('view');

  render() {
    const { createAmenities, data, editAmenities, validationSchema, match, isAdmin } = this.props;
    const { mode, shortenItem, showingMenu, showRemove } = this.state;
    const itemClassNames = classNames({
      'amenity-item': true,
      'amenity-item--has-description': !!data.get('description') || !!data.get('terms_conditions'),
    });
    const display = (
      <div
        className={itemClassNames}
        onClick={data.get('description') || data.get('terms_conditions') ? this.toggleShortenItem : null}
      >
        <div className="amenity-item__row">
          <div className="amenity-item__name">{data.get('name')}</div>

          <AmenitiesKebab
            mode={mode}
            toggleShowingMenu={this.toggleShowingMenu}
            shortenItem={shortenItem}
            showingMenu={showingMenu}
            switchMode={this.switchMode}
            toggleRemove={this.toggleRemove}
            toggleShortenItem={this.toggleShortenItem}
            descriptionExists={data.get('description') || data.get('terms_conditions') ? true : false}
            match={match}
            amenityId={data.get('id')}
          />

          {shortenItem ? null : (
            <div>
              {data.get('description') && (
                <div>
                  <p className="amenity-item__desc">Description</p>
                  <p>{data.get('description')}</p>
                </div>
              )}
              {data.get('terms_conditions') && (
                <div>
                  <p className="amenity-item__desc">Terms and Conditions</p>
                  <p>{data.get('terms_conditions')}</p>
                </div>
              )}
            </div>
          )}
        </div>
        {showRemove && (
          <div className="paper__footer">
            <Delete
              message={
                <span>
                  Are you sure you want to <strong className="strong">remove this amenity</strong>?
                </span>
              }
              deleteFunc={this.handleRemoveAmenity}
              onCancel={this.toggleRemove}
            />
          </div>
        )}
      </div>
    );

    return (
      <div>
        {mode === 'edit' ? (
          <AmenitiesForm
            data={data}
            mode={mode}
            shortenItem={shortenItem}
            editAmenities={editAmenities}
            createAmenities={createAmenities}
            showingMenu={showingMenu}
            switchMode={this.switchMode}
            toggleRemove={this.toggleRemove}
            toggleShowingMenu={this.toggleShowingMenu}
            toggleShortenItem={this.toggleShortenItem}
            validationSchema={validationSchema}
            isAdmin={isAdmin}
            amenityId={data.get('id')}
            match={match}
          />
        ) : (
          display
        )}
      </div>
    );
  }
}

AmenityItem.propTypes = {
  data: PropTypes.object.isRequired,
  createAmenities: PropTypes.func.isRequired,
  removeAmenities: PropTypes.func.isRequired,
  editAmenities: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default AmenityItem;
