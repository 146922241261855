import { connect } from 'react-redux';
import { getCredentialTypes, getUnpairedPropertyPanels } from '../../redux/actions';
import { getProperty, editProperty, getDevicesForProperty } from '../Property/redux/actions';
import { selectCredentialTypes } from '../../redux/selectors';
import { adminEditedVideoDoorbellFeature } from '../Property/views/PropertyDetails/views/Devices/redux/actions';
import EditProperty from './EditProperty';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    videoDoorbells: state.property.propertyDeviceList.filter(device => device.get('dmp_hardware_type') === 'DOORBELL'),
    credentialTypes: selectCredentialTypes(state),
    credentialTypesLoaded: state.credentialTypesLoaded,
    unpairedPropertyPanels: state.unpairedPropertyPanels,
    unpairedPropertyPanelsLoaded: state.unpairedPropertyPanelsLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editProperty: (values, propertyId) => dispatch(editProperty(values, propertyId)),
    adminEditedVideoDoorbellFeature: (doorbells, on) => dispatch(adminEditedVideoDoorbellFeature(doorbells, on)),
    getProperty: propertyId => dispatch(getProperty(propertyId)),
    getDevicesForProperty: propertyId => dispatch(getDevicesForProperty(propertyId)),
    getCredentialTypes: () => dispatch(getCredentialTypes()),
    getUnpairedPropertyPanels: propertyId => dispatch(getUnpairedPropertyPanels(propertyId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getProperty: () => dispatchProps.getProperty(ownProps.match.params.propertyId),
    getDevicesForProperty: () => dispatchProps.getDevicesForProperty(ownProps.match.params.propertyId),
    adminEditedVideoDoorbellFeature: on =>
      dispatchProps.adminEditedVideoDoorbellFeature({
        doorbellIds: stateProps.videoDoorbells.map(vdb => {
          return vdb.get('id');
        }),
        on,
      }),
    editProperty: values => dispatchProps.editProperty(values, ownProps.match.params.propertyId),
    getUnpairedPropertyPanels: () => dispatchProps.getUnpairedPropertyPanels(ownProps.match.params.propertyId),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditProperty);
