import React from 'react';

function Phone() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.786 1.01L6.964 1C5.884 1 5 1.9 5 3v18c0 1.1.884 2 1.964 2h9.822c1.08 0 1.964-.9 1.964-2V3c0-1.1-.884-1.99-1.964-1.99zm.13 18.323H6.834V5.583h10.084v13.75z" />
    </svg>
  );
  /* eslint-enable */
}

export default Phone;
