import VacantUnits from './VacantUnits';
import { connect } from 'react-redux';
import { getVacantUnitAutomation } from '../../../../redux/actions';
import { getUserPermission } from '../../../../../../redux/selectors';

const mapStateToProps = (state, ownProps) => ({
  propertyId: ownProps.match.params.propertyId,
  vacantUnitAutomationLoaded: state.property.vacantUnitAutomationLoaded,
  vacantUnitAutomationLoading: state.property.vacantUnitAutomationLoading,
  vacantUnitAutomation: state.property.vacantUnitAutomation,
  userPermissionList: getUserPermission(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVacantUnitAutomation: () => dispatch(getVacantUnitAutomation(ownProps.match.params.propertyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VacantUnits);
