import { connect } from 'react-redux';

import AccessRequests from './AccessRequests';
import { getDoors, getAccessRequests } from '../../../../redux/actions';
import { getTelephoneEntryDevices } from '../../../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    doors: state.property.accessControlDoors,
    doorsLoaded: state.property.accessControlDoorsLoaded,
    accessRequests: state.property.accessRequests,
    accessRequestsLoaded: state.property.accessRequestsLoaded,
    accessRequestsTotal: state.property.accessRequestsTotal,
    telephoneEntryDevices: state.telephoneEntryDevices,
    telephoneEntryDevicesLoaded: state.telephoneEntryDevicesLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getDoors: () => dispatch(getDoors(ownProps.match.params.propertyId)),
    getAccessRequests: query => dispatch(getAccessRequests(ownProps.match.params.propertyId, query)),
    getTelephoneEntryDevices: () =>
      dispatch(getTelephoneEntryDevices({ property_filter: ownProps.match.params.propertyId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessRequests);
