import React from 'react';
import { SGButton } from '../../shared';
import { SGModal } from '../SGModal';

export const TourChangeConfirmModal = ({ visible, handleConfirm, handleCancel, subtitle }) => {
  const subTitleText = subtitle?.isTimeDateDaysChanged
    ? 'All scheduled tours will now only have access during the new tour time frames. '
    : 'All scheduled tours will now only have access to the new selected units.' || '';

  return (
    <SGModal visible={visible} width={940} handleCancel={handleCancel}>
      <div className="detail-modal-popup-container text-center">
        <h2 className="h2 strong">Are you sure you want to change your tour availability?</h2>
        <p className="help-text m-b30 p-lr30">
          {subTitleText}
          <br />
          Potential residents will be notified of these changes.
        </p>
        <div className="button-container btns-row">
          <SGButton
            className="button-positive btn-approve p-lr36"
            title="Yes, change availability"
            handleClick={handleConfirm}
          />
          <SGButton className="button-no" title="No, don’t change availability" handleClick={handleCancel} />
        </div>
      </div>
    </SGModal>
  );
};
