import React, { Component } from 'react';
import Select from 'react-select';
import { Formik } from 'formik';
import InputScaffold from '../../../../common/forms/InputScaffold';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Yup from 'yup';
import PropTypes from 'prop-types';
import config from '../../../../common/config';
import localstorage from 'store2';
import querystring from 'query-string';
import Pager from '../../../../common/Pager';
import PageHeader from '../../../../common/PageHeader';
import moment from 'moment/moment';

const validationSchema = Yup.object().shape({
  start_date: Yup.date().nullable(),
  end_date: Yup.date().nullable(),
  property_id: Yup.string().trim(),
  unit_id: Yup.string().trim(),
  ip_address: Yup.string()
    .trim()
    .test('valid-ip', 'Must be a valid IP Address', value => {
      const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return value ? ipRegex.test(value) : true;
    })
    .max(15),
  user_id: Yup.string()
    .trim()
    .max(36)
    .min(36),
  user_email: Yup.string()
    .trim()
    .email('Must be a valid email address')
    .max(255),
});

class ActivityLog extends Component {
  state = { unitList: [], selectedProperty: '' };
  componentDidMount() {
    this.props.getProperties();
    const search = querystring.parse(this.props.location.search);
    if (Object.keys(search).length > 2) {
      this.props.getActivityLog(search);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (prevState.selectedProperty.length > 0 && this.state.selectedProperty.length === 0) {
      this.props.resetUnits();
    }
    const locationObject = querystring.parse(location.search);
    const oldLocationObject = querystring.parse(prevProps.location.search);
    if (
      oldLocationObject.page !== null &&
      locationObject.page !== oldLocationObject.page &&
      Object.keys(locationObject).length > 2
    ) {
      this.props.getActivityLog(locationObject);
    }
  }
  generateCSV = (values, errors, resetForm) => {
    if (Object.keys(errors).length === 0) {
      const queryParams = querystring.stringify(values);
      window.location = `${config.apiUrl}activity-log/csv?${queryParams}&token=${localstorage.get('idToken')}`;
      resetForm();
    }
  };
  handlePropertySelectChange = (setFieldValue, values, value) => {
    if (value === null) {
      setFieldValue('property_id', '');
    } else {
      setFieldValue('property_id', value);
    }
    setFieldValue('unit_id', '');
    if (value) {
      this.setState({ selectedProperty: value });
      this.props.browseUnits(value);
    } else {
      this.setState({ selectedProperty: '' });
    }
  };
  handleUnitSelectChange = (setFieldValue, value) => {
    if (value === null) {
      setFieldValue('unit_id', '');
    } else {
      setFieldValue('unit_id', value);
    }
  };
  handleSubmit = (values, formActions) => {
    const { history, location } = this.props;
    this.props.getActivityLog(values).then(action => {
      if (action.response.ok) {
        formActions.setSubmitting(false);
        formActions.resetForm({ ...values });
      }
    });
    history.push({ pathname: location.pathName, search: querystring.stringify(values) });
  };
  handleFromChange = (setFieldValue, from) => {
    setFieldValue('start_date', from);
  };
  handleToChange = (setFieldValue, to) => {
    this.setState({ to });
    setFieldValue('end_date', to);
  };
  renderLogTable = () => {
    const { activityLog } = this.props;
    if (activityLog.size === 0) {
      return <h6 className="no-results activity-log--empty"> Theres are no results for your search criteria. </h6>;
    }
    return (
      <table className="table-list">
        <thead>
          <tr>
            <th>Ip Address</th>
            <th>Method</th>
            <th>Params</th>
            <th>Payload</th>
            <th>Query</th>
            <th>Property ID</th>
            <th>Property Name</th>
            <th>Unit ID</th>
            <th>Unit Number</th>
            <th>User ID</th>
            <th>User Email</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {activityLog.map(record => {
            return (
              <tr key={record.get('id')}>
                <td>{record.get('ip_address')}</td>
                <td>{record.get('method')}</td>
                <td>{JSON.stringify(record.get('params'))}</td>
                <td>{JSON.stringify(record.get('payload'))}</td>
                <td>{JSON.stringify(record.get('query'))}</td>
                <td>{record.get('property_id')}</td>
                <td>{record.get('property_name')}</td>
                <td>{record.get('unit_id')}</td>
                <td>{record.get('unit_number')}</td>
                <td>{record.get('user_id')}</td>
                <td>{record.get('email')}</td>
                <td>{new Date(record.get('created_at')).toISOString()}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  render() {
    const { location, activityLogTotal, activityLogLoading } = this.props;
    const locationObj = querystring.parse(location.search);
    let initialValues = {
      start_date: null,
      end_date: null,
      property_id: '',
      unit_id: '',
      ip_address: '',
      user_id: '',
      user_email: '',
    };
    if (Object.keys(locationObj).length > 2) {
      initialValues = Object.assign({}, locationObj);
      delete initialValues.page;
      delete initialValues.page_size;
      if (locationObj.start_date) {
        initialValues.start_date = new Date(moment.utc(new Date(locationObj.start_date)).format('MMM D, YYYY'));
      }
      if (locationObj.end_date) {
        initialValues.end_date = new Date(moment.utc(new Date(locationObj.end_date)).format('MMM D, YYYY'));
      }
    }
    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={this.handleSubmit}
          render={({
            values,
            errors,
            handleChange,
            resetForm,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            submitForm,
          }) => {
            return (
              <div className="admin--reports">
                <form onSubmit={handleSubmit}>
                  <PageHeader title="Activity Log" backLink="./" />
                  <div className="container">
                    <div className="paper radius-top-left radius-top-right">
                      <section className="react-select-scaffold">
                        <span className="label">Select Property</span>
                        <Select
                          closeOnSelect={true}
                          onChange={this.handlePropertySelectChange.bind(this, setFieldValue, values)}
                          options={this.props.propertiesList}
                          placeholder={
                            this.props.propertiesList.length ? 'Select a property' : 'No Properties Available'
                          }
                          value={values.property_id}
                          simpleValue={true}
                        />
                      </section>
                      <section className="react-select-scaffold">
                        <span className="label">Select Unit</span>
                        <Select
                          closeOnSelect={true}
                          onChange={this.handleUnitSelectChange.bind(this, setFieldValue)}
                          options={this.props.unitList}
                          placeholder={this.props.unitList.length ? 'Select a unit' : 'No Units Available'}
                          value={values.unit_id}
                          simpleValue={true}
                        />
                      </section>
                      <section className="react-select-scaffold">
                        <InputScaffold label="IP Address" validation={touched.ip_address && errors.ip_address}>
                          <input
                            type="text"
                            name="ip_address"
                            maxLength="15"
                            onChange={({ target }) => {
                              setFieldValue('ip_address', target.value.replace(/[^\d.]/g, ''));
                            }}
                            value={values.ip_address}
                          />
                        </InputScaffold>
                        <InputScaffold label="User UUID" validation={touched.user_id && errors.user_id}>
                          <input
                            type="text"
                            name="user_id"
                            maxLength="36"
                            onChange={handleChange}
                            value={values.user_id}
                          />
                        </InputScaffold>
                        <InputScaffold label="User Email" validation={touched.user_email && errors.user_email}>
                          <input
                            type="text"
                            name="user_email"
                            maxLength="36"
                            onChange={handleChange}
                            value={values.user_email}
                          />
                        </InputScaffold>
                      </section>
                      <section className="paper__section">
                        <div className="from-to__wrapper">
                          <InputScaffold label="Start Date" validation={touched.start_date && errors.start_date}>
                            <DayPickerInput
                              inputProps={{
                                autoComplete: 'false',
                              }}
                              name="test"
                              value={values.start_date ? values.start_date : ''}
                              placeholder="From"
                              format="LL"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                selectedDays: [values.start_date, { from: values.start_date, to: values.end_date }],
                                disabledDays: { after: values.end_date },
                                toMonth: values.end_date,
                                modifiers: { start: values.start_date, end: values.end_date },
                                numberOfMonths: 1,
                              }}
                              onDayChange={this.handleFromChange.bind(this, setFieldValue)}
                            />
                          </InputScaffold>

                          <InputScaffold label="End Date" validation={touched.start_date && errors.end_date}>
                            <DayPickerInput
                              inputProps={{
                                autoComplete: 'false',
                              }}
                              value={values.end_date ? values.end_date : ''}
                              placeholder="To"
                              format="LL"
                              formatDate={formatDate}
                              parseDate={parseDate}
                              dayPickerProps={{
                                selectedDays: [values.start_date, { from: values.start_date, to: values.end_date }],
                                disabledDays: { before: values.start_date },
                                modifiers: { start: values.start_date, end: values.end_date },
                                month: values.start_date ? values.start_date : null,
                                fromMonth: values.start_date ? values.start_date : null,
                                numberOfMonths: 1,
                              }}
                              onDayChange={this.handleToChange.bind(this, setFieldValue)}
                            />
                          </InputScaffold>
                        </div>
                      </section>
                    </div>
                    <div className="paper__footer form-actions__wrapper radius-bottom-left radius-bottom-right">
                      <button
                        disabled={isSubmitting}
                        type="button"
                        onClick={() => {
                          submitForm();
                          this.generateCSV(values, errors, resetForm);
                        }}
                        className="button"
                      >
                        Download CSV
                      </button>
                      <button disabled={isSubmitting} type="submit" className="button">
                        Get Activity Log
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            );
          }}
        />
        <div className="container admin--reports">
          {this.renderLogTable()}
          {activityLogLoading ? null : (
            <Pager
              history={this.props.history}
              initialPage={parseInt(locationObj.page, 10) || 0}
              location={this.props.location}
              pageSize={30}
              totalItems={activityLogTotal}
            />
          )}
        </div>
      </div>
    );
  }
}
ActivityLog.propTypes = {
  propertiesList: PropTypes.array.isRequired,
  getProperties: PropTypes.func.isRequired,
};

export default ActivityLog;
