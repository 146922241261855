import { connect } from 'react-redux';
import { createMessage } from '../../../../../../redux/actions';
import { selectGroupOptions } from '../../../../../../redux/selectors';
import AddAnnouncement from './AddAnnouncement';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    groupOptions: selectGroupOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createMessage: (values, propertyId) => dispatch(createMessage(values, propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAnnouncement);
