export const startTimeDefault = '00:00';
export const endTimeDefault = '23:59';

export const REVOKE_ACCESS = 'revoke';
export const ENABLE_ACCESS = 'noRevoke';

export const timePeriod = [
  { number: 1, value: '4', label: '1 day', type: 'day' },
  { number: 1, value: '1', label: '1 week', type: 'week' },
  { number: 2, value: '2', label: '2 weeks', type: 'week' },
  { number: 3, value: '3', label: '3 weeks', type: 'week' },
];

export const weekDays = [
  { id: '1', title: 'Monday', code: 'mo' },
  { id: '2', title: 'Tuesday', code: 'tu' },
  { id: '3', title: 'Wednesday', code: 'we' },
  { id: '4', title: 'Thursday', code: 'th' },
  { id: '5', title: 'Friday', code: 'fr' },
  { id: '6', title: 'Saturday', code: 'sa' },
  { id: '7', title: 'Sunday', code: 'su' },
];
