import React from 'react';
import { Link } from 'react-router-dom';
import PartyPopper from '../../../../../../../common/icons/PartyPopper';
import { CopyLinkBtn } from '../../../../../../../common/forms/CopyLinkBtn';
import config from '../../../../../../../common/config';
import { useFetchTour } from '../common';
import Loading from '../../../../../../../common/Loading';
import Icon from '../../../../../../../common/icons/icon';
import { useSelector } from 'react-redux';
import { selectTourToken } from '../../../../../redux/selectors';

export const SGTourSuccessfully = ({ match }) => {
  const { propertyId } = match.params;
  const { isLoad } = useFetchTour(propertyId);
  const token = useSelector(selectTourToken);

  if (!isLoad) return <Loading />;

  return (
    <div className="container tours_block sg-container">
      <section className="paper radius-top-left radius-top-right m-t50">
        <div className="tour-successfully-img">
          <PartyPopper />
        </div>
        <h1 className="tour-successfully-title">Your Self-Guided Tours are now set up!</h1>
        <h3 className="tour-successfully-subtitle m-t10 m-b20">
          Your staff will be notified through email when a potential resident signs up through the web form.
        </h3>
        <p className="text-center font-light">
          The potential resident will be given access once their background check is approved.
        </p>

        <div className="divider horizontal m-t40 m-b40"></div>
        <h4 className="h3 strong text-center m-b30">
          Don’t forget to add your tour registration form link to your marketing platforms
        </h4>
        <div className="copy-link m-b20">
          <CopyLinkBtn title="FILLME-TOURS-DOMAIN" link={`${config.tourFormUrl}/self-guided-tours/${token}`} />
        </div>
        <p className="tour-successfully-message m-t30">
          Use this link in emails or on your website to allow prospective residents to sign up for self-guided tours.
        </p>
        <div className="divider horizontal m-t40 m-b40"></div>
        <div className="tour-successfully-buttons">
          <div className="button-right">
            <Link
              className="button button--icon self-guided_button"
              to={`/properties/${propertyId}/units/tours/automated/edit`}
            >
              <div className="button--children">
                <span>Continue to tour settings</span>
                <Icon icon="ArrowShortRight" />
              </div>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};
