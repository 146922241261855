import React from 'react';
import SubHeader from '../../common/SubHeader';
import { EventListItem } from '../Property/views/Community/views/Events/EventList/EventListItem';
import { useVendorDetails, useVendorEventList } from './hooks';

const VendorEventsList = ({ userId }) => {
  const vendorDetails = useVendorDetails(userId);
  const vendorEventList = useVendorEventList(vendorDetails?.data?.id);

  return (
    <div className="container">
      <SubHeader title="Events" />
      {vendorEventList.isLoading && 'Loading...'}

      {vendorEventList.data && (
        <table className="table-units-list">
          <thead>
            <tr>
              <th className="first-column">Event Name</th>
              <th>Date</th>
              <th>Start/End Time</th>
              <th>Registrants</th>
              <th>Max Attendees</th>
              <th>Status</th>
            </tr>
          </thead>
          {vendorEventList.data.length > 0 && (
            <tbody>
              {vendorEventList.data.map(event => {
                return <EventListItem key={event.id} event={event} pageContext="vendorEventList" />;
              })}
            </tbody>
          )}
        </table>
      )}
    </div>
  );
};

export default VendorEventsList;
