import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SGCheckboxListItem } from './SGCheckboxListItem';

export const SGCheckboxList = ({ onChange, items, isRadio, renderItem, classItemName, title }) => {
  const [dataItems, setDataItems] = useState(items);

  useEffect(() => {
    setDataItems(items);
  }, [items]);

  const onChangeHandler = ({ target: { value, checked } }) => {
    const newData = !isRadio
      ? dataItems.map(item => (item.value !== value ? item : { ...item, checked }))
      : dataItems.map(item => {
          if (item.value !== value) return { ...item, checked: false };
          return { ...item, checked };
        });

    const active = newData.find(({ checked }) => checked);

    onChange({
      active,
      selectedList: newData.filter(({ checked }) => checked),
    });

    setDataItems(newData);
  };

  return (
    <div className="checkbox-list">
      <h6 className="checkbox-list-title">{title}</h6>
      <div className="checkbox-list-container">
        <ul className="checkbox-list-fields">
          {dataItems.map(item =>
            !renderItem ? (
              <SGCheckboxListItem
                key={`${item.name}-${item.value}`}
                onChange={onChangeHandler}
                className={classItemName}
                {...item}
              />
            ) : (
              <Fragment key={`${item.name}-${item.value}`}>{renderItem({ ...item, onChangeHandler })}</Fragment>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

SGCheckboxList.propTypes = {
  classItemName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isRadio: PropTypes.bool,
  isNotRender: PropTypes.bool,
  renderItem: PropTypes.func,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      title: PropTypes.string,
      subTitle: PropTypes.string,
    })
  ),
};
