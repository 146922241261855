import React from 'react';

function ApproveIcon() {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2295_2100)">
        <path
          d="M3.53721 12.8899C3.53721 11.703 3.53877 10.5177 3.53564 9.33073C3.53564 9.13132 3.59345 8.95249 3.78406 8.91135C4.28401 8.80373 4.54336 8.45398 4.73396 8.02194C5.09174 7.20533 5.612 6.50267 6.21819 5.86014C6.76033 5.28725 7.37277 4.79982 8.0024 4.3298C8.74607 3.77273 9.11635 3.02101 9.15385 2.08729C9.16478 1.81825 9.17416 1.54605 9.23821 1.28018C9.34445 0.849719 9.61942 0.59809 10.0647 0.526874C10.599 0.442997 10.9084 0.556943 11.1396 0.977907C11.688 1.97493 11.7317 3.00835 11.2818 4.05443C10.9818 4.75551 10.6584 5.44709 10.3615 6.14976C10.0866 6.80178 10.2491 7.04707 10.9443 7.04233C11.6489 7.03758 12.352 7.00751 13.0566 7.00276C13.7253 6.99801 14.3815 7.07873 15.0126 7.31928C15.5173 7.51235 15.7579 7.8985 15.8563 8.41125C15.936 8.83222 15.8766 9.22628 15.6876 9.6061C15.6641 9.65516 15.6501 9.70896 15.6204 9.75327C15.447 10.0176 15.4689 10.2423 15.6423 10.5272C16.1953 11.4419 16.1172 11.8628 15.3236 12.5798C15.2126 12.6795 15.1954 12.7428 15.2798 12.8804C15.7626 13.6543 15.6845 14.3554 15.0001 14.9235C14.708 15.1673 14.5877 15.4379 14.5721 15.7924C14.5643 15.9633 14.5174 16.1279 14.4768 16.2925C14.3408 16.8353 13.9924 17.1486 13.4659 17.2832C12.8285 17.4462 12.177 17.4367 11.5286 17.462C10.3272 17.5095 9.12572 17.53 7.9274 17.4177C6.89938 17.3211 5.87292 17.2151 4.84645 17.1122C4.5418 17.0822 4.2387 17.0442 3.93561 17.0078C3.71844 16.9809 3.59345 16.8511 3.56533 16.6343C3.55127 16.5267 3.54658 16.4175 3.54658 16.3099C3.54658 15.1704 3.54658 14.031 3.54658 12.8899C3.54346 12.8899 3.54189 12.8899 3.53877 12.8899H3.53721Z"
          fill="white"
        />
        <path
          d="M9.75035e-05 12.9928C9.75035e-05 11.7837 9.75035e-05 10.5747 9.75035e-05 9.36715C9.75035e-05 8.98734 0.118836 8.86706 0.490675 8.86548C1.19373 8.86548 1.89835 8.86548 2.60141 8.86548C2.96856 8.86548 3.08417 8.98259 3.08417 9.34816C3.08417 11.7727 3.08417 14.1987 3.08417 16.6233C3.08417 17.0141 2.98106 17.1186 2.59516 17.1202C1.88429 17.1218 1.17186 17.1233 0.46099 17.1202C0.115711 17.1202 -0.00146484 16.9967 -0.00146484 16.6391C-0.00146484 15.4221 -0.00146484 14.2067 -0.00146484 12.9897L9.75035e-05 12.9928Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2295_2100">
          <rect width="15" height="17" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ApproveIcon;
