export const waitForConnection = async (panelId, connectionType, checkPanelEasyConnectStatus) => {
  console.log('Waiting on initial connection to panel');

  if (connectionType === 'persistent' || connectionType === 'persistent_w_cell_backup') {
    // Persistent panels use EASYConnect
    console.log('Using EASYConnect');
    let easyConnectSuccess = false;
    while (!easyConnectSuccess) {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000));

        console.log('Checking EASYConnect status');

        const action = await checkPanelEasyConnectStatus(panelId);

        easyConnectSuccess = action.json && action.json.status === 'OK';
      } catch (e) {
        console.error(e);
      }
    }
    console.log('EASYConnect success');
  } else {
    // Network/Cell panels do not use EASYConnect
    console.log('Not using EASYConnect');
  }

  console.log('Made initial connection to panel');

  return true;
};

export const initializePanel = async (panelId, initializePanelAction) => {
  // artificial delay because the DMP API fails to initialize if we do this too quickly after creating the panel
  await new Promise(resolve => setTimeout(resolve, 5000));

  console.log('Initialize started');
  const result = await initializePanelAction(panelId);

  if (result.response.ok) {
    return result;
  } else {
    console.log('Initialize panel failed', result);
    throw new Error((result.json && result.json.message) || 'Failed to initialize panel');
  }
};

export const waitForInitializeSuccess = async (panelId, groupId, checkInitializePanelStatus) => {
  let initializeSuccess = false;
  let result;

  while (!initializeSuccess) {
    await new Promise(resolve => setTimeout(resolve, 1000));

    console.log('Checking initialization status');

    result = await checkInitializePanelStatus(panelId, groupId);
    initializeSuccess = result.json && result.json.icStatus && result.json.icStatus.JobStatus === 'success';

    if (
      !result.json ||
      !result.json.icStatus ||
      !['new', 'started', 'running', 'success', 'acquired'].includes(result.json.icStatus.JobStatus)
    ) {
      console.log('Initialization failed', result);
      const message =
        (result.json && result.json.icStatus && result.json.icStatus.JobMessage) || 'Initialization failed.';
      throw new Error(message);
    }
  }

  console.log('Initialize finished');
  return result;
};

export const refreshCommunicationOptions = async (panelId, refreshPanelCommunicationOptions, getJobStatus) => {
  console.log('Refreshing communication options');

  const jobRequestResult = await refreshPanelCommunicationOptions(panelId);
  if (jobRequestResult.response.ok && jobRequestResult.json.job) {
    console.log('Got refresh job');

    let refreshing = true;
    while (refreshing) {
      await new Promise(resolve => setTimeout(resolve, 1000));

      console.log('Checking refresh communication options status');

      const jobStatusResult = await getJobStatus(jobRequestResult.json.job.uuid);

      if (jobStatusResult.response.ok && jobStatusResult.json) {
        if (jobStatusResult.json.status === 'success') {
          console.log('Finished refreshing communication options');
          return jobStatusResult.json;
        } else if (jobStatusResult.json.status === 'pending') {
          refreshing = true;
        } else {
          refreshing = false;
        }
      }
    }
  }

  console.log('Refresh communication options failed');
  throw new Error('Refresh communication options failed');
};

export const setCommunicationOptions = async (panelId, setPanelCommunicationOptions, getJobStatus) => {
  console.log('Setting communication options');

  const jobRequestResult = await setPanelCommunicationOptions(panelId);

  if (jobRequestResult.response.ok && jobRequestResult.json.job) {
    console.log('Got set communications job');

    let refreshing = true;
    while (refreshing) {
      await new Promise(resolve => setTimeout(resolve, 1000));

      console.log('Checking set communication options status');

      const jobStatusResult = await getJobStatus(jobRequestResult.json.job.uuid);

      if (jobStatusResult.response.ok && jobStatusResult.json) {
        if (jobStatusResult.json.status === 'success') {
          console.log('Finished set communication options');
          return jobStatusResult.json;
        } else if (jobStatusResult.json.status === 'pending') {
          refreshing = true;
        } else {
          refreshing = false;
        }
      }
    }
  }

  console.log('Set communication options failed');
  throw new Error('Set communication options failed');
};

export const setSystemOptions = async (panelId, systemOptions, setPanelSystemOptions, getJobStatus) => {
  console.log('Setting system options');

  const jobRequestResult = await setPanelSystemOptions(panelId, systemOptions);

  if (jobRequestResult.response.ok && jobRequestResult.json.job) {
    console.log('Got set system options job');

    let refreshing = true;
    while (refreshing) {
      await new Promise(resolve => setTimeout(resolve, 1000));

      console.log('Checking set system options status');

      const jobStatusResult = await getJobStatus(jobRequestResult.json.job.uuid);

      if (jobStatusResult.response.ok && jobStatusResult.json) {
        if (jobStatusResult.json.status === 'success') {
          console.log('Finished set system options');
          return jobStatusResult.json;
        } else if (jobStatusResult.json.status === 'pending') {
          refreshing = true;
        } else {
          refreshing = false;
        }
      }
    }
  }

  console.log('Set system options failed');
  throw new Error('Set system options failed');
};

export const setNetworkOptions = async (panelId, networkOptions, setPanelNetworkOptions, getJobStatus) => {
  console.log('Setting network options');

  const jobRequestResult = await setPanelNetworkOptions(panelId, networkOptions);

  if (jobRequestResult.response.ok && jobRequestResult.json.job) {
    console.log('Got set network options job');

    let refreshing = true;
    while (refreshing) {
      await new Promise(resolve => setTimeout(resolve, 1000));

      console.log('Checking set network options status');

      const jobStatusResult = await getJobStatus(jobRequestResult.json.job.uuid);

      if (jobStatusResult.response.ok && jobStatusResult.json) {
        if (jobStatusResult.json.status === 'success') {
          console.log('Finished set network options');
          return jobStatusResult.json;
        } else if (jobStatusResult.json.status === 'pending') {
          refreshing = true;
        } else {
          refreshing = false;
        }
      }
    }
  }

  console.log('Set network options failed');
  throw new Error('Set network options failed');
};
