import React from 'react';

function Outlet({ width, height, style, fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22">
      <g id="outlet" transform="translate(-3.375 -5.626)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M6.772,6.614a2.455,2.455,0,0,1,1.983-.988H22a2.463,2.463,0,0,1,1.983.988,17.034,17.034,0,0,1,3.4,10.012,17.044,17.044,0,0,1-3.4,10.012A2.457,2.457,0,0,1,22,27.626H8.754a2.457,2.457,0,0,1-1.983-.988,17.056,17.056,0,0,1-3.4-10.013,17.044,17.044,0,0,1,3.4-10.012ZM8.755,7.626a.67.67,0,0,0-.552.252,14.943,14.943,0,0,0-2.982,8.748A14.948,14.948,0,0,0,8.2,25.373a.676.676,0,0,0,.554.252H21.993a.676.676,0,0,0,.554-.252,14.943,14.943,0,0,0,2.982-8.748,14.948,14.948,0,0,0-2.982-8.748.669.669,0,0,0-.554-.252H8.757Z"
          fill="#7a8a99"
          fill-rule="evenodd"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M13.324,12.375a.949.949,0,0,1,.949.949v2.848a.949.949,0,0,1-1.9,0V13.324A.949.949,0,0,1,13.324,12.375Zm7.595,0a.949.949,0,0,1,.949.949v2.848a.949.949,0,0,1-1.9,0V13.324A.949.949,0,0,1,20.919,12.375Z"
          transform="translate(-1.747 -0.661)"
          fill="#7a8a99"
          fill-rule="evenodd"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M15.75,22.149v1.9h3.8v-1.9a1.9,1.9,0,1,0-3.8,0Z"
          transform="translate(-2.274 -1.479)"
          fill="#7a8a99"
        />
      </g>
    </svg>
  );
}
export default Outlet;
