import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import EmptyPlaceholder from '../../../../common/EmptyPlaceholder';
import { selectorIsAdmin } from '../../../../redux/selectors';

const NoCredentialTypesPlaceholder = ({ isAdmin, propertyId }) => {
  return isAdmin ? (
    <EmptyPlaceholder buttonText="Edit Community" linkTo={`/properties/${propertyId}/edit`}>
      <div>This community has no credential types enabled.</div>
    </EmptyPlaceholder>
  ) : (
    <EmptyPlaceholder>
      <div>This community has no credential types enabled.</div>
      <div>Contact an administrator about enabling credential types for this community.</div>
    </EmptyPlaceholder>
  );
};

export default compose(
  withRouter,
  connect((state, props) => ({ isAdmin: selectorIsAdmin, propertyId: props.match.params.propertyId }))
)(NoCredentialTypesPlaceholder);
