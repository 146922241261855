import { connect } from 'react-redux';

import EditArea from './EditArea';
import { editArea, getPropertyAreas } from '../../../../redux/actions';

function mapStateToProps(state, ownProps) {
  return {
    property: state.property.activeProperty,
    matchingArea: state.property.accessControlAreas.find(area => area.get('id') === ownProps.match.params.areaId),
    accessControlAreasLoaded: state.property.accessControlAreasLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    editArea: payload => dispatch(editArea(ownProps.match.params.propertyId, ownProps.match.params.areaId, payload)),
    getPropertyAreas: () => dispatch(getPropertyAreas(ownProps.match.params.propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditArea);
