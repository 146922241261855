import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';
import InputScaffold from './InputScaffold';
import { trimAndSetNullFalsyValues } from '../utils/helpers';
import DeleteWithPrompt from '../DeleteWithPrompt';

const addValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please enter a vendor name'),
  email: Yup.string()
    .trim()
    .email()
    .required('Please enter a vendor email'),
});

const editValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please enter a vendor name'),
});

const MODES = {
  ADD: 'add',
  EDIT: 'edit',
};

const validationErrors = {
  VendorAlreadyExistsOnCommunity: 'A Vendor with this email already exists in this Community.',
};

const VendorDetailsForm = props => {
  const [submitError, setSubmitError] = useState('');
  const {
    submitFunction,
    deleteFunction,
    initialValues,
    history,
    mode,
    isAssignedEvents,
    editRemovePermission,
  } = props;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={mode === MODES.ADD ? addValidationSchema : editValidationSchema}
      onSubmit={(values, actions) => {
        return submitFunction(trimAndSetNullFalsyValues(values)).catch(err => {
          actions.setSubmitting(false);
          return setSubmitError(err);
        });
      }}
      render={({ values, errors, handleChange, handleSubmit, touched, isSubmitting, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="paper radius-top-left radius-top-right">
                <section>
                  <div className="section-header h4">{mode === MODES.ADD ? 'Add Vendor' : 'Edit Vendor'}</div>
                  <InputScaffold label="Vendor Name" required validation={touched.name && errors.name}>
                    <input type="text" name="name" maxLength={64} onChange={handleChange} value={values.name} />
                  </InputScaffold>

                  {mode === MODES.ADD && (
                    <InputScaffold label="Vendor E-mail" required validation={touched.email && errors.email}>
                      <input type="email" name="email" onChange={handleChange} value={values.email} />
                    </InputScaffold>
                  )}
                </section>
              </div>
              <div className="container paper__footer radius-bottom-left radius-bottom-right">
                {mode === MODES.ADD ? (
                  <button className="button">Add Vendor</button>
                ) : (
                  <>{editRemovePermission && <button className="button">Save</button>}</>
                )}
                <button className="button button--secondary" type="button" onClick={history.goBack}>
                  Cancel
                </button>
                {mode === MODES.EDIT && !isAssignedEvents && editRemovePermission && (
                  <DeleteWithPrompt
                    disabled={false}
                    message={
                      <span>
                        Are you sure you want to <strong className="strong">remove this Vendor</strong>?
                      </span>
                    }
                    buttonLabel="Remove Vendor"
                    deleteFunc={deleteFunction}
                  />
                )}
              </div>
              {submitError && (
                <div className="form-error__wrapper">
                  <span className="form-error--label">Error:&nbsp;</span>
                  {validationErrors[submitError] ? validationErrors[submitError] : submitError}
                </div>
              )}
            </div>
          </form>
        );
      }}
    />
  );
};

VendorDetailsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  submitFunction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default VendorDetailsForm;
