import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Yup from 'yup';

import Switch from '../../../../../../../../common/forms/Switch';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import ToggleScaffold from '../../../../../../../../common/forms/ToggleScaffold';
import NotificationStatusIndicator from '../../../../../../../../common/forms/NotificationStatusIndicator';
import MaskedInput from 'react-input-mask';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(120, 'Device label must be 120 characters or less')
    .required('Please enter a device label'),
  mastercode: Yup.number().nullable(true),
});

class DeviceForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func.isRequired,
  };
  render() {
    const { initialValues, onCancel, onSubmit } = this.props;
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          setStatus(null);
          return onSubmit(values).then(action => {
            setSubmitting(false);
            if (action.response.ok) {
              this.props.onSuccess();
            } else {
              const error = action.json.message.split(':');
              switch (action.json && action.json.message && error[0]) {
                case 'device_id_exists':
                  setStatus('Device UID is not unique.');
                  break;
                case 'mastercode_in_use':
                  setStatus('The Master Code entered is already in use.');
                  break;
                case 'device_update_issue':
                  setStatus('Device could not be updated. ' + error[2]);
                  break;
                default:
                  setStatus(
                    'An unknown error occurred' + (action.json && action.json.message ? ': ' + action.json.message : '')
                  );
                  break;
              }
            }
          });
        }}
        render={({ values, errors, handleChange, handleSubmit, isSubmitting, resetForm, setFieldValue, status }) => (
          <form onSubmit={handleSubmit}>
            <div className="device-form--body">
              <section>
                <InputScaffold label="Device Label" required validation={errors.name}>
                  <input
                    type="text"
                    name="name"
                    maxLength="120"
                    autoFocus
                    onChange={handleChange}
                    value={values.name}
                  />
                </InputScaffold>
                <div className="radio--input__wrapper">
                  <div className="input__wrapper--label">Area</div>
                  <div className="radio--container">
                    <ToggleScaffold label="Unit">
                      <input
                        type="radio"
                        name="is_community_device"
                        onChange={() => {
                          setFieldValue('is_community_device', false);
                        }}
                        checked={values.is_community_device === false}
                      />
                    </ToggleScaffold>
                    <ToggleScaffold label="Community">
                      <input
                        type="radio"
                        name="is_community_device"
                        onChange={() => {
                          setFieldValue('is_community_device', true);
                        }}
                        checked={values.is_community_device === true}
                      />
                    </ToggleScaffold>
                  </div>
                </div>
                {values.dmp_hardware_type === 'LOCK' ? (
                  <InputScaffold label="Master Code" validation={errors.mastercode}>
                    <MaskedInput
                      mask={'99999999'}
                      name="mastercode"
                      maskChar={null}
                      onChange={event => {
                        setFieldValue('mastercode', event.target.value.replace(/[^0-9]+/g, ''));
                      }}
                      value={values.mastercode}
                    />
                  </InputScaffold>
                ) : null}
                <Switch
                  label="Hide Battery Status"
                  input={{
                    name: 'hide_battery_status',
                    value: !!values.hide_battery_status,
                    onChange: value => setFieldValue('hide_battery_status', value),
                  }}
                  disabled={false}
                />
              </section>
            </div>
            <div className="device-form--footer">
              <NotificationStatusIndicator type="Failure" message={status} hideNotification={!status} />
              <div className="button--error__wrapper">
                <button
                  className="button button--secondary"
                  type="button"
                  onClick={() => {
                    resetForm();
                    onCancel();
                  }}
                >
                  Cancel
                </button>
                <button disabled={isSubmitting || Object.keys(errors).length} className="button">
                  {isSubmitting ? 'Saving...' : 'Save Device'}
                </button>
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

export default DeviceForm;
