import client from '../../../../common/client';

export const getCommunityStripeConnectAccount = communityId => {
  return {
    promise: client.get(`stripe-integration/communities/${communityId}/account`),
    type: 'GET_COMMUNITY_STRIPE_CONNECT_ACCOUNT',
  };
};

export const createCommunityStripeConnectAccount = communityId => {
  return {
    promise: client.post(`stripe-integration/communities/${communityId}/connect-account`),
    type: 'CREATE_COMMUNITY_STRIPE_CONNECT_ACCOUNT',
  };
};

// TODO FIXME I don't think there should be a separate route just to retrieve the business name,
// it should be returned with the original request.  Possible refactor going forward
export const getStripeAccount = stripeAccountId => {
  return {
    promise: client.get(`stripe-integration/account/${stripeAccountId}`),
    type: 'GET_STRIPE_ACCOUNT',
  };
};

export const clearStripeAccountLink = () => {
  return {
    type: 'CLEAR_STRIPE_ACCOUNT_LINK',
  };
};
