import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Kebab from '../../../../../../../../common/Kebab';
import Icon from '../../../../../../../../common/icons/icon';
import classNames from 'classnames';

class AmenitiesKebab extends Component {
  handleEdit = () => {
    this.props.switchMode('edit');
    this.props.toggleShowingMenu();
  };

  render() {
    const {
      descriptionExists,
      mode,
      toggleShowingMenu,
      toggleRemove,
      toggleShortenItem,
      shortenItem,
      showingMenu,
    } = this.props;
    const actionToggleClasses = {
      'amenity-item__actions--toggle': true,
      'amenity-item__actions--toggle--active': !shortenItem,
    };
    return (
      <div className="amenity-item__actions">
        <Kebab
          onClick={e => {
            e.stopPropagation();
            toggleShowingMenu();
          }}
          onDisengage={toggleShowingMenu}
          menuOpened={showingMenu}
        >
          <div className="kebab__menu">
            <div className="kebab__menu--item" onClick={this.handleEdit}>
              <Icon icon="Edit" /> Edit
            </div>
            <div className="kebab__menu--item" onClick={toggleRemove}>
              <Icon icon="Remove" /> Remove
            </div>
          </div>
        </Kebab>
        {descriptionExists ? (
          <button
            type="button"
            onClick={mode === 'edit' ? toggleShortenItem : null}
            className={classNames(actionToggleClasses)}
          >
            <Icon icon="CaretDown" />
          </button>
        ) : null}
      </div>
    );
  }
}

AmenitiesKebab.propTypes = {
  descriptionExists: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  toggleShowingMenu: PropTypes.func.isRequired,
  showingMenu: PropTypes.bool.isRequired,
  switchMode: PropTypes.func.isRequired,
  toggleRemove: PropTypes.func.isRequired,
  toggleShortenItem: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default AmenitiesKebab;
