import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

function Icon(props) {
  const { icon, className, style, ...rest } = props;

  const IconComponent = require(`./${icon}`).default;

  return (
    <span className={classNames(['icon', className])} style={style} {...rest}>
      <IconComponent />
    </span>
  );
}

Icon.displayName = 'Icon';
Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};
Icon.defaultProps = {
  icon: 'Placeholder',
  className: null,
};

export default Icon;
