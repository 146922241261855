export default {
  back: 'Назад',
  next: 'Далее',
  cancel: 'Отменить',
  confirm: 'Подтвердить',
  saving: 'Сохранение...',
  save: 'Сохранить',
  ok: 'OK',
  verify: 'Проверить',
  yes: 'Да',
  tryAgain: 'Попробуйте снова',
  remove: 'Удалить',
  apply: 'Применить',
  edit: 'Редактировать',
  update: 'Обновить',
};
