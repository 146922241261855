import React, { Fragment } from 'react';
import Dinero from 'dinero.js';
import cn from 'classnames';
import Icon from '../../../../../../../../common/icons/icon';
import { useAdminFinancials } from './hooks';
import AlertMessage from '../../../../../../../../common/AlertMessage';

export const FinancialSummary = ({ priceInCents, communityFee, hasVendor, t }) => {
  const adminEventFinancials = useAdminFinancials();

  const communityFeeInteger = !isNaN(communityFee) ? Number(communityFee) : 0;
  const hasAdminEventFinancialsLoaded = adminEventFinancials.isFetched;

  console.log(adminEventFinancials.data?.find(item => item.type === 'EVENT'));
  const eventFinancialsData = adminEventFinancials.data?.find(item => item.type === 'EVENT');
  const smartExperienceFee =
    hasAdminEventFinancialsLoaded && eventFinancialsData?.current ? parseFloat(eventFinancialsData.current.fee) : 0;
  console.log('fee is', smartExperienceFee);

  const combinedFees = smartExperienceFee + communityFeeInteger;

  let displayPricePerTicket = '--';
  let displayRevenuePerAttendee = '--';
  let displayCommunityFee = '--';
  let displaySmartExperienceFee = '--';
  let validPercentage = combinedFees <= 100;

  if (hasAdminEventFinancialsLoaded) {
    displaySmartExperienceFee = smartExperienceFee ? `${smartExperienceFee.toFixed(2)}%` : `0.00%`;
    displayCommunityFee = communityFeeInteger ? `${communityFeeInteger.toFixed(2)}%` : '0.00%';

    if (priceInCents) {
      const pricePerTicket = Dinero({ amount: priceInCents });

      if (validPercentage) {
        const [revenuePerAttendee] = pricePerTicket.allocate([100 - combinedFees, combinedFees]);
        displayRevenuePerAttendee = revenuePerAttendee.toFormat('$0,0.00');
      }

      displayPricePerTicket = pricePerTicket.toFormat('$0,0.00');
    }
  }

  return (
    <Fragment>
      <div className="financials-bits__wrapper">
        <div className="financials-bit">
          <div className="financials__label">{t('eventSummary.price')}</div>
          <div className="financials__value">{displayPricePerTicket}</div>
        </div>

        {hasVendor && (
          <div className="financials-bit">
            <div className="financials__label">{t('eventSummary.communityFee')}</div>
            <div className={cn('financials__value', !validPercentage && 'invalid-percentage')}>
              {displayCommunityFee}
            </div>
          </div>
        )}

        <div className="financials-bit">
          <div className="financials__label">{t('eventSummary.SEfee')}</div>
          <div className="financials__value">{displaySmartExperienceFee}</div>
        </div>
      </div>

      <div className="financials__estimated-revenue">
        <Icon icon="SubArrow" />
        <div className="financials-bit">
          <div className="financials__label">{t('eventSummary.revenue')}</div>
          <div className="financials__value">{displayRevenuePerAttendee}</div>
        </div>
      </div>

      {!validPercentage && <AlertMessage type="error" msg={t('eventSummary.bigFeeError')} />}
    </Fragment>
  );
};
