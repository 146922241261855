import React, { useContext, useState } from 'react';

export const SGFormContext = React.createContext();

export const useSGFormContext = () => {
  const { formBlocks, setFormBlocks } = useContext(SGFormContext);

  return { formBlocks, setFormBlocks };
};

export const SGFormProvider = ({ children }) => {
  const [formBlocks, setFormBlocks] = useState([]);

  const setErrorsHandler = blocks => {
    setFormBlocks([...blocks]);
  };

  return (
    <SGFormContext.Provider
      value={{
        formBlocks,
        setFormBlocks: setErrorsHandler,
      }}
    >
      {children}
    </SGFormContext.Provider>
  );
};
