import React from 'react';
import classNames from 'classnames';

export const SGCheckbox = ({ children, className, id, name, handleChange, checked, disabled }) => (
  <label className={classNames(className, { disabled: disabled })}>
    <input
      id={id}
      type="checkbox"
      name={name}
      className="margin-clear-all"
      value={id}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    />
    {children}
  </label>
);
