import React from 'react';
import { any, string, bool } from 'prop-types';
import Tippy from '@tippyjs/react';

export const SGTooltip = ({ content, children, placement, disabled }) => (
  <Tippy placement={placement} content={content} disabled={disabled}>
    {children}
  </Tippy>
);

SGTooltip.propTypes = {
  content: any.isRequired,
  children: any.isRequired,
  placement: string.isRequired,
  disabled: bool,
};
