import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OfferForm from '../../common/forms/OfferForm';
import Loading from '../../common/Loading/index';
import { trimAndPruneFalsyValues } from '../../common/utils/helpers';

export default class AddOffer extends Component {
  componentDidMount() {
    this.props.getProperties();
  }
  render() {
    const { createOffer, history, propertyListLoaded } = this.props;

    if (propertyListLoaded) {
      return (
        <OfferForm
          initialValues={{
            company_name: '',
            teaser_copy: '',
            details: '',
            offer_code: '',
            website: '',
            phone: '',
            address: '',
            first_name: '',
            last_name: '',
            email: '',
            contact_phone: '',
            properties: [],
            start_date: null,
            end_date: null,
            image_size: 0,
          }}
          saveFunction={values => {
            const submitValues = values;
            if (!submitValues.image_id) {
              submitValues.image_id = null;
            }
            return createOffer(trimAndPruneFalsyValues(values)).then(action => {
              if (action.response.ok) {
                history.push('/offers');
              }
            });
          }}
          imageUrl=""
          {...this.props}
        />
      );
    } else {
      return <Loading />;
    }
  }
}

AddOffer.propTypes = {
  createOffer: PropTypes.func.isRequired,
  getProperties: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  propertyList: PropTypes.array.isRequired,
  propertyListLoaded: PropTypes.bool.isRequired,
};
