import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';

import SpinnerLoading from '../../../../../../common/Loading/SpinnerLoading';
import PageHeader from '../../../../../../common/PageHeader';
import DoorForm from '../../components/DoorForm';

class AddDoor extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    property: PropTypes.instanceOf(Map),
    accessControlAreas: PropTypes.instanceOf(List).isRequired,
  };

  componentDidMount() {
    this.props.getPropertyAreas();
  }

  handleSuccess = () => {
    this.props.history.push('./');
  };

  handleCancel = () => {
    this.props.history.push('./');
  };

  render() {
    const { addDoor, accessControlAreas, accessControlAreasLoaded } = this.props;

    return (
      <div>
        <PageHeader title="Doors" white backLink="./" />
        <div className="container">
          {accessControlAreasLoaded ? (
            <DoorForm
              submitFunc={addDoor}
              onCancel={this.handleCancel}
              onSuccess={this.handleSuccess}
              areas={accessControlAreas.toJS()}
            />
          ) : (
            <SpinnerLoading />
          )}
        </div>
      </div>
    );
  }
}

export default AddDoor;
