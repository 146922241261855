import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Geosuggest from 'react-geosuggest';

import InputScaffold from '../../common/forms/InputScaffold';
import CurrencyInput from '../../common/CurrencyInput';
import Switch from '../../common/forms/Switch';
import Select from 'react-select';

const getMasterPanelPlaceholderText = (hasDmpCustomerId, hasUnpairedPropertyPanels) => {
  if (!hasDmpCustomerId) return 'Enter a DMP Customer ID Before Selecting a Panel';
  if (!hasUnpairedPropertyPanels) return 'No Unpaired Panels Available';
  return 'Select a Master Panel';
};

class EditPropertyForm extends Component {
  handlePropertySelectChange = (setFieldValue, value) => {
    setFieldValue('levelup_property_id', value);
  };

  handleMasterPanelChange = (setFieldValue, value) => {
    setFieldValue('clubhouse_panel_id', value);
  };
  renderToggleSwitch(values, setFieldValue, label, name) {
    return (
      <Switch
        label={label}
        input={{
          value: values[name],
          name,
          onChange: this.handleToggle.bind(this, setFieldValue, name),
        }}
      />
    );
  }
  handleToggle = (setFieldValue, field, val) => {
    // const toggledFeature = field.substr(field.indexOf('_') + 1);
    // window.ga('send', 'event', 'property feature', val ? 'enabled' : 'disabled', toggledFeature);
    setFieldValue(field, val);
  };

  render() {
    const {
      initialValues,
      onCancel,
      submitError,
      submitFunc,
      validationSchema,
      credentialTypes,
      unpairedPropertyPanels,
    } = this.props;

    const AddressString = `${initialValues.address}, ${initialValues.city} ${initialValues.state}, ${initialValues.zip}`;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          submitFunc(values, actions);
        }}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          validateForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="paper radius-top-left radius-top-right">
              <div className="property-details--form">
                {submitError && (
                  <div className="form-error__wrapper">
                    <span className="form-error--label">Error:&nbsp;</span>
                    {submitError}
                  </div>
                )}
                <InputScaffold label="Name" required validation={touched.name && errors.name}>
                  <input type="text" name="name" maxLength="64" onChange={handleChange} value={values.name} />
                </InputScaffold>

                <InputScaffold
                  label="Property Type"
                  required
                  validation={touched.property_type && errors.property_type}
                >
                  <Select
                    name="property_type"
                    options={[
                      { value: 'billable', label: 'Billable' },
                      { value: 'demo', label: 'Demo' },
                      { value: 'internal', label: 'Internal' },
                    ]}
                    onChange={value => {
                      setFieldValue('property_type', value);
                    }}
                    value={values.property_type}
                    simpleValue={true}
                    clearable={false}
                  />
                </InputScaffold>

                <InputScaffold label="DMP Customer Id">
                  <input
                    disabled={initialValues.dmp_customer_id}
                    type="text"
                    name="dmp_customer_id"
                    maxLength="64"
                    onChange={event => {
                      if (!event.currentTarget.value || !event.currentTarget.value.trim()) {
                        this.handleMasterPanelChange(setFieldValue, null);
                      }
                      handleChange(event);
                    }}
                    value={values.dmp_customer_id}
                  />
                </InputScaffold>

                <div className="form-address">
                  <InputScaffold label="Address" required validation={touched.address && errors.address}>
                    <Geosuggest
                      disabled
                      autoComplete={Math.random()
                        .toString(36)
                        .substring(3, 9)}
                      initialValue={AddressString}
                    />
                  </InputScaffold>
                </div>

                <CurrencyInput
                  label="Total deferred payment limit"
                  value={values.total_deferred_monthly_limit}
                  validation={touched.total_deferred_monthly_limit && errors.total_deferred_monthly_limit}
                  handleValueUpdate={value => {
                    setFieldValue('total_deferred_monthly_limit', value);
                  }}
                />

                <CurrencyInput
                  label="Monthly deferred payment limit"
                  validation={touched.monthly_deferred_payment_limit && errors.monthly_deferred_payment_limit}
                  value={values.monthly_deferred_payment_limit}
                  handleValueUpdate={value => {
                    setFieldValue('monthly_deferred_payment_limit', value);
                  }}
                />

                <div className="react-select-scaffold">
                  <div className="label">Master Panel</div>
                  <Select
                    closeOnSelect={true}
                    onChange={this.handleMasterPanelChange.bind(this, setFieldValue)}
                    options={unpairedPropertyPanels.map(panel => ({ value: panel.id, label: panel.dmp_serial_number }))}
                    placeholder={getMasterPanelPlaceholderText(
                      !!values.dmp_customer_id,
                      !!unpairedPropertyPanels.length
                    )}
                    value={values.clubhouse_panel_id}
                    simpleValue={true}
                    disabled={!values.dmp_customer_id}
                  />
                </div>

                {credentialTypes.length ? (
                  <div className="property-details__form__credentials-list">
                    {' '}
                    <h4 className="h4">Credential Types</h4>
                    {credentialTypes.map(type => {
                      const isCurrentlyToggled = !!values.credential_types.find(val => val.id === type.id);
                      return (
                        <Switch
                          key={type.id}
                          label={type.name}
                          input={{
                            value: isCurrentlyToggled,
                            name: type.id,
                            onChange: () => {
                              setFieldValue(
                                'credential_types',
                                isCurrentlyToggled
                                  ? values.credential_types.filter(val => val.id !== type.id)
                                  : values.credential_types.concat([type])
                              );
                            },
                          }}
                        />
                      );
                    })}
                  </div>
                ) : null}
              </div>
              <section className="paper__section">
                <h4 className="h4">Enable Features</h4>
                <Fragment>
                  <Switch
                    label={'Smart Home'}
                    input={{
                      value: values.admin_enabled_unit_automation,
                      name: 'admin_enabled_unit_automation',
                      onChange: selectedValue => setFieldValue('admin_enabled_unit_automation', selectedValue),
                    }}
                    disabled={false}
                  />
                  <Switch
                    label={'Smart Access'}
                    input={{
                      value: values.admin_enabled_access_control,
                      name: 'admin_enabled_access_control',
                      onChange: selectedValue => setFieldValue('admin_enabled_access_control', selectedValue),
                    }}
                    disabled={false}
                  />
                  <Switch
                    label={'Smart Community'}
                    input={{
                      value: values.admin_enabled_community,
                      name: 'admin_enabled_community',
                      onChange: selectedValue => setFieldValue('admin_enabled_community', selectedValue),
                    }}
                    disabled={false}
                  />
                  <Switch
                    label={'MRI Integration'}
                    input={{
                      value: values.admin_enabled_mri,
                      name: 'admin_enabled_mri',
                      onChange: selectedValue => setFieldValue('admin_enabled_mri', selectedValue),
                    }}
                    disabled={false}
                  />
                  {values.admin_enabled_community && (
                    <Switch
                      label={'Community Views'}
                      input={{
                        value: values.admin_enabled_community_views && values.admin_enabled_community,
                        name: 'admin_enabled_community_views',
                        onChange: selectedValue => setFieldValue('admin_enabled_community_views', selectedValue),
                      }}
                      disabled={!values.admin_enabled_community}
                    />
                  )}
                  {
                    <Switch
                      label={'Mail Hubs'}
                      input={{
                        value: values.admin_enabled_mailhubs,
                        name: 'admin_enabled_mailhubs',
                        onChange: selectedValue => setFieldValue('admin_enabled_mailhubs', selectedValue),
                      }}
                    />
                  }
                  {
                    <Switch
                      label={'SELF GUIDED TOURS'}
                      input={{
                        value: values.admin_enabled_self_guided_tour,
                        name: 'admin_enabled_self_guided_tour',
                        onChange: selectedValue => setFieldValue('admin_enabled_self_guided_tour', selectedValue),
                      }}
                    />
                  }
                </Fragment>
              </section>
              <section className="paper__section">
                <h4 className="h4">Resident Device Tracking</h4>
                {this.renderToggleSwitch(
                  values,
                  setFieldValue,
                  'Enable Unique Resident Device Tracker',
                  'enable_tracking'
                )}
                {values.enable_tracking && (
                  <Fragment>
                    {this.renderToggleSwitch(
                      values,
                      setFieldValue,
                      'Always Request Device Change',
                      'enabled_new_device_notifications'
                    )}
                    {!values.enabled_new_device_notifications && (
                      <InputScaffold
                        label="Time period between device change (days)"
                        validation={errors.device_change_length}
                      >
                        <input
                          type="numeric"
                          name="device_change_length"
                          onChange={event => {
                            setFieldValue(
                              'device_change_length',
                              Number(event.target.value.replace(/[^0-9]+/g, '')).toString()
                            );
                          }}
                          value={values.device_change_length}
                        />
                      </InputScaffold>
                    )}
                  </Fragment>
                )}
              </section>
              <section className="paper__section">
                <h4 className="h4">Video Doorbells</h4>
                {this.renderToggleSwitch(
                  values,
                  setFieldValue,
                  'Enable Video Doorbell Devices',
                  'enable_video_doorbells'
                )}
              </section>
            </div>

            <div className="paper__footer radius-bottom-left radius-bottom-right">
              <button type="submit" disabled={isSubmitting} className="button">
                Save Property
              </button>
              <button type="button" className="button button--secondary" onClick={onCancel}>
                Cancel
              </button>
            </div>
          </form>
        )}
      />
    );
  }
}

EditPropertyForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  submitError: PropTypes.string,
  submitFunc: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  credentialTypes: PropTypes.array.isRequired,
};

export default EditPropertyForm;
