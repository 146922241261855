import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UnrecognizedProfiles from './UnrecognizedProfiles';
import { getPanelProfiles, addProfile, removePanelProfile } from '../../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlProfiles: state.property.accessControlProfiles,
    accessControlProfilesLoaded: state.property.accessControlProfilesLoaded,
    accessControlPanelProfiles: state.property.accessControlPanelProfiles.filter(
      p =>
        !state.property.accessControlProfiles.find(a => parseInt(p.get('number'), 10) === a.get('dmp_profile_number'))
    ),
    accessControlPanelProfilesLoaded: state.property.accessControlPanelProfilesLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPanelProfiles: () => dispatch(getPanelProfiles(ownProps.match.params.propertyId)),
    addProfile: payload => dispatch(addProfile(ownProps.match.params.propertyId, payload)),
    removePanelProfile: profileNumber => dispatch(removePanelProfile(ownProps.match.params.propertyId, profileNumber)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnrecognizedProfiles));
