import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import Yup from 'yup';
import InputScaffold from '../../common/forms/InputScaffold';
import states from '../../shared/state-abbreviations';
import Geosuggest from 'react-geosuggest';
import Switch from '../../common/forms/Switch';
import CurrencyInput from '../../common/CurrencyInput';
import { createProperty } from './redux/actions';
import { trimAndPruneFalsyValues } from '../../common/utils/helpers';
import Select from 'react-select';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(64, 'Name must be 64 characters or less')
    .required('Please enter a name'),
  property_type: Yup.string().required('Please select a property type'),
  dmp_customer_id: Yup.number()
    .typeError('ID must be a number')
    .moreThan(0, 'DMP Customer ID must be a number greater than 0'),
  address: Yup.string()
    .trim()
    .max(255, 'Address must be 255 characters or less')
    .required('Please enter an address'),
  city: Yup.string()
    .trim()
    .max(255, 'City must be 255 characters or less'),
  state: Yup.string().trim(),
  zip: Yup.string().min(5, 'Invalid ZIP'),
  total_deferred_monthly_limit: Yup.number()
    .typeError('The limit must be a number')
    .min(0)
    .max(99999999)
    .nullable(true),
  monthly_deferred_payment_limit: Yup.number()
    .typeError('The limit must be a number')
    .min(0)
    .max(99999999)
    .nullable(true),
  device_change_length: Yup.number()
    .integer()
    .min(1, 'Cannot be less than 1 day')
    .max(999, 'Cannot be more than 999 days'),
});

class AddProperty extends Component {
  state = { submitError: null };
  onSuggestSelect = (values, validateForm, suggest) => {
    if (suggest && suggest.location) {
      values.lat = suggest.location.lat;
      values.lng = suggest.location.lng;
    }
    const componentsArr = [
      'subpremise',
      'street_number',
      'route',
      'locality',
      'administrative_area_level_1',
      'postal_code',
    ];
    let street = [];
    if (suggest && suggest.gmaps) {
      suggest.gmaps.address_components.forEach(component => {
        component.types.forEach(type => {
          if (componentsArr.indexOf(type) >= 0) {
            switch (type) {
              case 'subpremise':
                street[2] = '#' + component.short_name;
                break;
              case 'street_number':
                street[0] = component.short_name;
                break;
              case 'route':
                street[1] = component.short_name;
                break;
              case 'locality':
                values.city = component.short_name;
                break;
              case 'administrative_area_level_1':
                values.state = component.short_name;
                break;
              case 'postal_code':
                values.zip = component.short_name;
                break;
              default:
                break;
            }
            values.address = street.join(' ');
            validateForm();
          }
        });
      });
    }
  };
  handlePropertySelectChange = (setFieldValue, value) => {
    setFieldValue('levelup_property_id', value);
  };
  renderToggleSwitch(values, setFieldValue, label, name) {
    return (
      <Switch
        label={label}
        input={{
          value: values[name],
          name,
          onChange: this.handleToggle.bind(this, setFieldValue, name),
        }}
      />
    );
  }
  handleToggle = (setFieldValue, field, val) => {
    // const toggledFeature = field.substr(field.indexOf('_') + 1);
    // window.ga('send', 'event', 'property feature', val ? 'enabled' : 'disabled', toggledFeature);
    setFieldValue(field, val);
  };

  handleCancel = () => this.props.history.goBack();

  renderHeader() {
    return (
      <div className="page-header">
        <div className="container">
          <div className="page-header__title">
            <h1 className="h1">Property Details</h1>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { submitError } = this.state;
    return (
      <div className="add-property--page">
        {this.renderHeader()}
        <div className="container">
          <Formik
            initialValues={{
              name: '',
              property_type: 'billable',
              address: '',
              city: '',
              state: states[0],
              zip: '',
              dmp_customer_id: '',
              monthly_deferred_payment_limit: '',
              total_deferred_monthly_limit: '',
              levelup_property_id: null,
              enable_tracking: false,
              enable_video_doorbells: false,
              enabled_new_device_notifications: false,
              device_change_length: 1,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.props.createProperty(trimAndPruneFalsyValues(values)).then(res => {
                if (res.response.ok) {
                  return this.props.history.push(`/properties/${res.json.id}`);
                }
                actions.setSubmitting(false);
                return this.setState(state => ({
                  submitError: res.json.message,
                }));
              });
            }}
            render={({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              validateForm,
            }) => (
              <form className="property-details--form" onSubmit={handleSubmit}>
                {submitError && (
                  <div className="form-error__wrapper">
                    <span className="form-error--label">Error:&nbsp;</span>
                    {submitError}
                  </div>
                )}
                <InputScaffold label="Name" required validation={touched.name && errors.name}>
                  <input type="text" name="name" maxLength="64" onChange={handleChange} value={values.name} />
                </InputScaffold>

                <InputScaffold
                  label="Property Type"
                  required
                  validation={touched.property_type && errors.property_type}
                >
                  <Select
                    name="property_type"
                    options={[
                      { value: 'billable', label: 'Billable' },
                      { value: 'demo', label: 'Demo' },
                      { value: 'internal', label: 'Internal' },
                    ]}
                    onChange={value => {
                      setFieldValue('property_type', value);
                    }}
                    value={values.property_type}
                    searchable={false}
                    clearable={false}
                  />
                </InputScaffold>

                <InputScaffold label="DMP Customer Id" validation={touched.dmp_customer_id && errors.dmp_customer_id}>
                  <input
                    type="text"
                    name="dmp_customer_id"
                    maxLength="64"
                    onChange={handleChange}
                    value={values.dmp_customer_id}
                  />
                </InputScaffold>

                <div className="form-address">
                  <InputScaffold label="Address" required validation={touched.address && errors.address}>
                    <Geosuggest
                      autoComplete={Math.random()
                        .toString(36)
                        .substring(3, 9)}
                      onSuggestSelect={this.onSuggestSelect.bind(this, values, validateForm)}
                    />
                  </InputScaffold>
                </div>

                <CurrencyInput
                  label="Total deferred payment limit"
                  value={values.total_deferred_monthly_limit}
                  validation={touched.total_deferred_monthly_limit && errors.total_deferred_monthly_limit}
                  handleValueUpdate={value => {
                    setFieldValue('total_deferred_monthly_limit', value);
                  }}
                />

                <CurrencyInput
                  label="Monthly deferred payment limit"
                  validation={touched.monthly_deferred_payment_limit && errors.monthly_deferred_payment_limit}
                  value={values.monthly_deferred_payment_limit}
                  handleValueUpdate={value => {
                    setFieldValue('monthly_deferred_payment_limit', value);
                  }}
                />
                {this.renderToggleSwitch(
                  values,
                  setFieldValue,
                  'Enable Unique Resident Device Tracker',
                  'enable_tracking'
                )}
                {values.enable_tracking && (
                  <Fragment>
                    {this.renderToggleSwitch(
                      values,
                      setFieldValue,
                      'Always Request Device Change',
                      'enabled_new_device_notifications'
                    )}
                    {!values.enabled_new_device_notifications && (
                      <InputScaffold
                        label="Time period between device change (days)"
                        validation={errors.device_change_length}
                      >
                        <input
                          type="numeric"
                          name="device_change_length"
                          onChange={event => {
                            setFieldValue(
                              'device_change_length',
                              Number(event.target.value.replace(/[^0-9]+/g, '')).toString()
                            );
                          }}
                          value={values.device_change_length}
                        />
                      </InputScaffold>
                    )}
                  </Fragment>
                )}
                {this.renderToggleSwitch(
                  values,
                  setFieldValue,
                  'Enable Video Doorbell Devices',
                  'enable_video_doorbells'
                )}
                <div className="form--footer">
                  <button disabled={isSubmitting} className="button">
                    Save Property
                  </button>
                  <button type="button" className="button button--secondary" onClick={this.handleCancel}>
                    Cancel
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default connect(null, dispatch => ({
  createProperty: values => dispatch(createProperty(values)),
}))(AddProperty);
