export default {
  main: {
    resident: 'Житель',
    residents: 'Жители',
    placeholder: 'Поиск жителей',
    clearSearch: 'Очистить поиск',
    registeredDevices: 'Зарегистрированные девайсы',
    unregisteredDevices: 'незарегистрированные девайсы',
    tableHeaders: {
      resident: 'ЖИТЕЛЬ',
      unit: 'АПАРТАМЕНТЫ',
      smartHome: 'УМНЫЙ ДОМ',
      roommates: 'СОСЕДИ ПО КОМНАТЕ', //?
      linkedDevice: 'Запрос на переключение связанного устройства',
    },

    noRoommates: 'Нет соседей по комнате',
    acceptDevice: 'Принять устройство',
    rejectDevice: 'Отклонить устройство',
    anErrorOccurred: 'Произошла ошибка',
    noResults: 'По вашим критериям поиска результатов нет.',
  },

  edit: {
    title: 'Редактировать жителя',
    success: 'Житель отредактирован успешно!',
    error: 'Проблема с редактированием жителя',
    informationTab: {
      title: 'Информация о жителе',
    },
    accessCodesTab: {
      title: 'Коды доступа',
      generate: 'Сгенерировать',
      show: 'Показать',
      hide: 'Спрятать',
      sendReminder: 'Отправить напоминание',
      reset: 'Сбросить',
      remove: 'Удалить',

      modalAlert: {
        title: 'Удалить код доступа?',
        body: 'Вы уверены, что хотите удалить этот код доступа?',
        confirmButtonTitle: 'Удалить код доступа',
      },

      unit: {
        name: 'Код доступа к апартаментам',
        disabledText: 'У апартаментов нет умного замка',
        noCodePlaceholder: 'Нет кода доступа к апартаментам',
      },

      community: {
        name: 'Код доступа к жилому комплексу',
        noCodePlaceholder: 'Нет кода доступа к жилому комплексу',
      },
    },
    credentialsTab: {
      title: 'Учетные данные',
      confirmLeaving: 'Вы уверены, что хотите выйти? У вас есть несохраненные изменения.',
      save: 'Сохранить учетные данные',
      saving: 'Сохранение учетных данных...',
      success: 'Учетные данные отредактированы успешно!',
      error: 'Проблема с редактированием учетных данных',
      addCredential: 'Добавить учетные данные',
      credentialType: 'ТИП УЧЕТНЫХ ДАННЫХ',
      errorLength: 'Код учетных данных должен содержать 5 символов',
      code: 'КОД',
      errorRequired: 'Введите код пожалуйста',
      note: 'ЗАМЕТКА',
      leave: 'Оставьте заметку',
      remove: 'Удалить',
      savingCredentials: 'Сохраняются учетные данные...',
    },
  },
};
