import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { scheduleTourToPast } from '../../../../../../../redux/actions/scheduledAction';

export const useSGToPast = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { propertyId, visitorId } = useParams();

  const handleToPast = async () => {
    await dispatch(scheduleTourToPast(propertyId, visitorId));
    history.push(`/properties/${propertyId}/units/tours/edit/past-tours`);
  };

  return {
    handleToPast,
  };
};
