import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Loading from '../../../../../../../../../../common/Loading';

import Icon from '../../../../../../../../../../common/icons/icon';

class AddDevice extends Component {
  componentDidMount() {
    this.props.getProperties();
  }
  render() {
    const { propertiesLoaded } = this.props;

    if (!propertiesLoaded) {
      return <Loading />;
    }
    return (
      <div className="add-device--wizard">
        <div className="page-header page-header__custom">
          <div className="container">
            <Link to={`./`} className="back-arrow">
              <Icon icon="ArrowLeft" />
              Back
            </Link>
            <h1 className="h1">Devices &bull; {this.props.property.get('name')}</h1>
          </div>
        </div>
        <div className="container">
          <div className="paper radius-top-left radius-top-right">
            <div className="page-header subheader underline">
              <h4 className="h4">Select Device Type</h4>
            </div>
            <div className="device-form--body">
              <div className="device-select__wrapper">
                <Link to="./add-zwave-device" className="button">
                  ZWave Device
                </Link>
                <Link to="./add-wireless-device" className="button">
                  SmartExperience Wireless Device
                </Link>
                <Link to="./add-wired-device" className="button">
                  SmartExperience Wired Device
                </Link>
                {this.props.property.get('enable_video_doorbells') && (
                  <Link to="./add-video-doorbell" className="button">
                    Video Doorbell
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddDevice);
