import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

const RevokeModel = ({ name, visible, onCancel, textareaValue, setTextAreaValue, handleEnable }) => {
  const history = useHistory();
  const { propertyId } = useParams();

  return (
    <>
      {visible && (
        <div className="modal modal--visible revokeModel-div">
          <div className="modal__children">
            <div className="modal__body">
              <div className="modal__body--text">
                <div className="paper radius-top-left radius-top-right revokeModel-top-div">
                  <div className="revokeModel-top-title1">
                    Are you sure you want to revoke access from
                    {name}?
                  </div>
                  <div className="revokeModel-top-title2">They will no longer be able take a self-guided tour.</div>
                  <div className="revokeModel-top-title3">
                    An automated email will be sent to the prospective resident letting them know their access has been
                    revoked.
                  </div>
                </div>
              </div>
              <div className="revokeModel-button-container">
                <button className="button revokeModel-button-cancel" type="button" onClick={onCancel}>
                  No, Don't Revoke
                </button>
                <button
                  className="button revokeModel-button-revoke"
                  type="button"
                  onClick={async () => {
                    await handleEnable('revoke');
                    history.push(`/properties/${propertyId}/units/tours/automated/edit/rejected`);
                  }}
                >
                  Revoke Access
                </button>
              </div>
              <div className="revokeModel-bottom-div">
                <div className="revokeModel-bottom-title-div radius-top-left radius-top-right">
                  <div className="revokeModel-bottom-title1">Please list the reason for revoking access</div>
                  <div className="revokeModel-bottom-title2">This will only be stored inside the manager portal.</div>
                </div>
                <textarea
                  className="sg-message-textarea"
                  rows="9"
                  maxLength="255"
                  placeholder="Type message here…"
                  value={textareaValue}
                  onChange={e => setTextAreaValue(e.target.value)}
                />
              </div>
            </div>
          </div>
          <button type="button" className="modal__overlay" onClick={onCancel} />
        </div>
      )}
    </>
  );
};

export default RevokeModel;
