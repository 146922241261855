import { connect } from 'react-redux';
import { getProperties, getActivityLog } from '../../../../redux/actions';
import { getProperty, browseUnits, resetUnits } from '../../../../views/Property/redux/actions';
import { selectPropertiesForDropdown, selectUnitsForDropdown } from '../../../../redux/selectors';
import ActivityLog from './ActivityLog';

function mapStateToProps(state, props) {
  return {
    propertiesList: selectPropertiesForDropdown(state),
    unitList: selectUnitsForDropdown(state),
    property: state.property.activeProperty,
    activityLog: state.selectedActivityLog,
    activityLogTotal: state.activityLogTotal,
    activityLogLoading: state.activityLogLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProperties: () => dispatch(getProperties()),
    getProperty: propertyId => dispatch(getProperty(propertyId)),
    browseUnits: propertyId => dispatch(browseUnits(propertyId)),
    getActivityLog: payload => dispatch(getActivityLog(payload)),
    resetUnits: () => dispatch(resetUnits()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);
