import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../../../../common/icons/icon';
import Avatar from '../../../../../../../../common/Avatar';
import { formatPhoneNumber } from '../../../../../../../../common/utils/helpers';
import Kebab from '../../../../../../../../common/Kebab';
import SubHeader from '../../../../../../../../common/SubHeader/index';
import Immutable from 'immutable';
import Delete from '../../../../../../../../common/Delete/index';

const EmptyPropertyManagerList = ({ onAddPropertyManager, addNewPMUser, t }) => (
  <div className="zero-content">
    {addNewPMUser ? (
      <button onClick={onAddPropertyManager} className="button">
        {t('main.addPortalUser')}
      </button>
    ) : (
      ''
    )}
  </div>
);

class PropertyManagersList extends Component {
  state = {
    openKebabUserId: null,
    deleteConfirmationUserId: null,
    tooltip: '',
    bottomHeight: 0,
    offsetHeight: 0,
  };

  onMouseOver = (id, e) => {
    this.setState({ tooltip: id });
  };
  onMouseOut = e => {
    this.setState({ tooltip: '', bottomHeight: 0, offsetHeight: 0 });
  };

  onAddClick = () => {
    const { match, history } = this.props;
    history.push(`${match.url}/add`);
  };

  onKebabClick = userId => this.setState({ openKebabUserId: userId });

  closeKebab = () => this.setState({ openKebabUserId: null });

  renderDeleteConfirmation = propertyManagerId => {
    const { removePropertyManager, removePropertyManagerLoading, t } = this.props;
    return (
      <div className="paper__footer radius-bottom-left radius-bottom-right t-removef-btn" style={{ display: 'block' }}>
        <Delete
          disabled={removePropertyManagerLoading}
          onCancel={() => this.setState({ deleteConfirmationUserId: null })}
          deleteFunc={() =>
            removePropertyManager(propertyManagerId).then(action => {
              if (action.response.ok) {
                this.setState({ deleteConfirmationUserId: null });
              }
            })
          }
          message={<span dangerouslySetInnerHTML={{ __html: t('main.removeConfirmation') }} />}
        />
      </div>
    );
  };

  renderKebab = manager => {
    const { match, history, userPermissionList, user, t } = this.props;
    const { openKebabUserId } = this.state;
    const userPermission = {
      addNewPMUser: userPermissionList.includes('ADD_NEW_PM_USER'),
      deletePMUser: userPermissionList.includes('DELETE_PM_USER'),
      editPMUserRole: userPermissionList.includes('EDIT_PM_USER_ROLE'),
      editOtherPMUserDetails: userPermissionList.includes('EDIT_OTHER_PM_USER_DETAILS'),
      editTheirPMOwnDetails: userPermissionList.includes('EDIT_THEIR_OWN_PM_DETAILS'),
    };

    if (!userPermission.deletePMUser && !userPermission.editOtherPMUserDetails) {
      if (!userPermission.editTheirPMOwnDetails) {
        return '';
      }
      if (userPermission.editTheirPMOwnDetails && user.get('id') !== manager.get('user_id')) {
        return '';
      }
    }

    if (
      !userPermission.deletePMUser &&
      !userPermission.editTheirPMOwnDetails &&
      user.get('id') === manager.get('user_id')
    ) {
      return '';
    }

    const editUrl = `${match.url}/${manager.get('id')}/edit`;
    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(manager.getIn(['user', 'id']))}
          onDisengage={this.closeKebab}
          menuOpened={manager.getIn(['user', 'id']) === openKebabUserId}
        >
          <div className="kebab__menu">
            {((user.get('id') === manager.get('user_id') && userPermission.editTheirPMOwnDetails) ||
              (user.get('id') !== manager.get('user_id') && userPermission.editOtherPMUserDetails)) && (
              <div
                className="kebab__menu--item"
                onClick={() => {
                  history.push(editUrl);
                  this.closeKebab();
                }}
              >
                <Icon icon="Edit" />
                {t('edit', { ns: 'buttons' })}
              </div>
            )}
            {userPermission.deletePMUser && (
              <div
                className="kebab__menu--item"
                onClick={() => {
                  this.setState({ deleteConfirmationUserId: manager.getIn(['user', 'id']) });
                  this.closeKebab();
                }}
              >
                <Icon icon="Remove" />
                {t('remove', { ns: 'buttons' })}
              </div>
            )}
          </div>
        </Kebab>
      </div>
    );
  };

  renderIdentificationColumn = manager => {
    const first_name =
      manager.getIn(['user', 'first_name']).length > 12
        ? manager.getIn(['user', 'first_name']).substr(0, 12) + '...'
        : manager.getIn(['user', 'first_name']);
    const last_name =
      manager.getIn(['user', 'last_name']).length > 20
        ? manager.getIn(['user', 'last_name']).substr(0, 20) + '...'
        : manager.getIn(['user', 'last_name']);
    const fullName = first_name + ' ' + last_name;

    return (
      <div className="pm-list__name-column">
        <Avatar name={fullName} imageSource={manager.getIn(['user', 'profile', 'picture'])} />
        <div>
          <div className="pm-list__name-column__name">
            {fullName} {manager.get('approved_purchaser') && <Icon icon="Purchaser" />}
          </div>
          <div className="pm-list__name-column__email">{manager.getIn(['user', 'email'])}</div>
        </div>
      </div>
    );
  };

  render() {
    const { propertyManagers, history, userPermissionList, t } = this.props;
    const { deleteConfirmationUserId, tooltip } = this.state;
    const userPermission = {
      addNewPMUser: userPermissionList.includes('ADD_NEW_PM_USER'),
      deletePMUser: userPermissionList.includes('DELETE_PM_USER'),
      editPMUserRole: userPermissionList.includes('EDIT_PM_USER_ROLE'),
      editOtherPMUserDetails: userPermissionList.includes('EDIT_OTHER_PM_USER_DETAILS'),
      editTheirPMOwnDetails: userPermissionList.includes('EDIT_THEIR_OWN_PM_DETAILS'),
    };

    // const sty = {
    //   position: 'absolute',
    //   maxHeight: '287px',
    //   width: '200px',
    // };

    return (
      <div className="container">
        <SubHeader
          title={t('main.headerTitle')}
          actions={
            propertyManagers.size && userPermission.addNewPMUser
              ? [
                  <button className="button" onClick={this.onAddClick}>
                    {t('main.addPortalUser')}
                  </button>,
                ]
              : null
          }
        />
        {propertyManagers.size ? (
          <table className="table-list table-list__m-bottom">
            <thead>
              <tr>
                <th>{t('main.tableHeader.name')}</th>
                <th>{t('main.tableHeader.role')}</th>
                <th>{t('main.tableHeader.phoneNumber')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {propertyManagers.map(manager => {
                return (
                  <tr key={manager.get('user_id')}>
                    <td>{this.renderIdentificationColumn(manager)}</td>
                    <td>
                      {manager.get('user').get('role').size > 0 ? (
                        manager
                          .get('user')
                          .get('role')
                          .map((role, i) => (
                            <div
                              style={{ width: '200px' }}
                              key={i}
                              onMouseEnter={this.onMouseOver.bind(this, manager.get('user').get('id'))}
                              onMouseLeave={this.onMouseOut.bind(this)}
                            >
                              <div className="RP-User-Table-Role PM-RP-User-Table-Role" key={i}>
                                {role.get('role_name')}
                              </div>
                              {tooltip === manager.get('user').get('id') && (
                                <div
                                  className="RP-User-Table-Tips"
                                  style={{
                                    position: 'absolute',
                                    maxHeight: '287px',
                                    width: '200px',
                                  }}
                                >
                                  <b className="RP-User-Table-Tips-Title">
                                    {manager.get('user').get('first_name')}&nbsp;{manager.get('user').get('last_name')}
                                  </b>
                                  {role.get('group').size > 0 &&
                                    role.get('group').map((group, x) => (
                                      <div className="RP-User-Table-Tips-Group" key={x}>
                                        <div className="RP-User-Table-Tips-GroupTitle">{group.get('group')}</div>
                                        {group.get('permissions').size &&
                                          group.get('permissions').map((permissions, y) => (
                                            <div className="RP-User-Table-Tips-GroupContent" key={y}>
                                              {permissions.get('permission_name')}
                                            </div>
                                          ))}
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          ))
                      ) : (
                        <div style={{ width: '100px' }}>
                          <div className="RP-User-Table-Role">&nbsp;</div>
                        </div>
                      )}
                    </td>
                    <td>
                      <div>{formatPhoneNumber(manager.getIn(['user', 'phone']))}</div>
                    </td>
                    <td className="table__align-right">
                      {deleteConfirmationUserId === manager.getIn(['user', 'id'])
                        ? this.renderDeleteConfirmation(manager.get('id'))
                        : this.renderKebab(manager)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <EmptyPropertyManagerList
            addNewPMUser={userPermission.addNewPMUser}
            onAddPropertyManager={() => history.push(`property-managers/add`)}
            t={t}
          />
        )}
      </div>
    );
  }
}

PropertyManagersList.propTypes = {
  property: PropTypes.instanceOf(Immutable.Map).isRequired,
  propertyManagers: PropTypes.object.isRequired,
  removePropertyManager: PropTypes.func.isRequired,
};

export default PropertyManagersList;
