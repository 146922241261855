import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Yup from 'yup';
import { Formik } from 'formik';
import InputScaffold from '../../../../common/forms/InputScaffold';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Select from 'react-select';
import localstorage from 'store2';
import config from '../../../../common/config';
import PageHeader from '../../../../common/PageHeader';

const validationSchema = Yup.object().shape({
  start_date: Yup.date().required('Please enter a start date.'),
  end_date: Yup.date().required('Please enter an end date.'),
  property_id: Yup.string().required('Please select a Property'),
});

class DailyReport extends Component {
  componentDidMount() {
    this.props.getProperties();
  }
  handleFromChange = (setFieldValue, from) => {
    setFieldValue('start_date', from);
  };
  handleToChange = (setFieldValue, to) => {
    this.setState({ to });
    setFieldValue('end_date', to);
  };
  handlePropertySelectChange = (setFieldValue, value) => {
    setFieldValue('property_id', value);
  };
  generateCSV = (values, { actions, resetForm }) => {
    values.start_date = values.start_date.toISOString();
    values.end_date = values.end_date.toISOString();

    window.location = `${config.apiUrl}reports/daily_report?start_date=${values.start_date}&end_date=${
      values.end_date
    }&property_id=${values.property_id}&token=${localstorage.get('idToken')}`;

    resetForm();
  };
  render() {
    return (
      <div>
        <Formik
          initialValues={{
            start_date: null,
            end_date: null,
            property_id: '',
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={this.generateCSV}
          render={({
            values,
            errors,
            handleChange,
            resetForm,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setStatus,
            status,
            setValues,
            touched,
            dirty,
          }) => {
            return (
              <div className="admin--reports">
                <form onSubmit={handleSubmit}>
                  <PageHeader title="Daily Report" backLink="./" />
                  <div className="container">
                    <p className="p help-text">
                      Data in the reports are logged each night at midnight, property time. Select the desired property
                      and date range, and then download the CSV report. The report will contain the data for each day
                      within your selected date range.
                    </p>
                  </div>

                  <div className="container" style={{ paddingBottom: '100px' }}>
                    <section className="paper">
                      <section className="react-select-scaffold">
                        <span className="label">
                          Select Property <span>*</span>
                        </span>
                        <Select
                          closeOnSelect={true}
                          onChange={this.handlePropertySelectChange.bind(this, setFieldValue)}
                          options={this.props.propertiesList}
                          placeholder={
                            this.props.propertiesList.length ? 'Select a property' : 'No Properties Available'
                          }
                          value={values.property_id}
                          simpleValue={true}
                        />
                      </section>
                      <div className="from-to__wrapper">
                        <InputScaffold label="Start Date" required validation={touched.start_date && errors.start_date}>
                          <DayPickerInput
                            inputProps={{
                              autoComplete: 'false',
                            }}
                            style={{ marginBottom: '300px' }}
                            name="test"
                            value={values.start_date ? values.start_date : ''}
                            placeholder="From"
                            format="LL"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              selectedDays: [values.start_date, { from: values.start_date, to: values.end_date }],
                              disabledDays: { after: values.end_date },
                              toMonth: values.end_date,
                              modifiers: { start: values.start_date, end: values.end_date },
                              numberOfMonths: 1,
                            }}
                            onDayChange={this.handleFromChange.bind(this, setFieldValue)}
                          />
                        </InputScaffold>

                        <InputScaffold label="End Date" required validation={touched.start_date && errors.end_date}>
                          <DayPickerInput
                            inputProps={{
                              autoComplete: 'false',
                            }}
                            value={values.end_date ? values.end_date : ''}
                            placeholder="To"
                            format="LL"
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                              selectedDays: [values.start_date, { from: values.start_date, to: values.end_date }],
                              disabledDays: { before: values.start_date },
                              modifiers: { start: values.start_date, end: values.end_date },
                              month: values.start_date ? values.start_date : null,
                              fromMonth: values.start_date ? values.start_date : null,
                              numberOfMonths: 1,
                            }}
                            onDayChange={this.handleToChange.bind(this, setFieldValue)}
                          />
                        </InputScaffold>
                      </div>
                    </section>
                    <div className="paper__footer">
                      <button
                        disabled={!dirty || isSubmitting || Object.keys(errors).length}
                        type="submit"
                        className="button"
                      >
                        Get Report
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            );
          }}
        />
      </div>
    );
  }
}
DailyReport.propTypes = {
  propertiesList: PropTypes.array.isRequired,
  getProperties: PropTypes.func.isRequired,
};

export default DailyReport;
