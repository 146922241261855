import React from 'react';
import { SGModal } from '../../modals/SGModal';
import Icon from '../../../../../../../../../../common/icons/icon';
import { SGButton } from '../../shared';

export const ResidentApproveModal = ({ width, visitorName, visible, handleCancel, handleApprove }) => (
  <SGModal visible={visible} width={width} handleCancel={handleCancel}>
    <div className="detail-modal-popup-container">
      <h1 className="detail-modal-popup-title">Do you want to approve {visitorName} for a Self-Guided Tour?</h1>
      <p className="text-center detail-modal-popup-message">
        An automated email will be sent to the prospective resident with their access <br /> codes. You can view their
        tour in the Scheduled Tours tab.
      </p>
      <div className="button-container btns-row">
        <SGButton
          className="button-positive btn-approve"
          title="Approve Tour"
          iconComponent={<Icon icon="ApproveIcon" />}
          handleClick={handleApprove}
        />
        <button className="button-no" onClick={handleCancel} type="button">
          No, Don’t Approve
        </button>
      </div>
      <p className="text-center detail-modal-popup-message">
        You can view new tours in the Scheduled Tours tab. At any point in time, <br />
        you can revoke their access if necessary.
      </p>
    </div>
  </SGModal>
);
