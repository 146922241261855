import React, { Component } from 'react';
import PropertyGuestForm from '../../components/PropertyGuestForm';
import Loading from '../../../../../../../../common/Loading/index';
import { PropTypes } from 'prop-types';
import { CommunityGuestAccessCodes } from '../../../../../../../../common/AccessCodes/CommunityGuestAccessCodes';
class EditPropertyGuest extends Component {
  componentDidMount() {
    this.props.getProfiles();
  }

  render() {
    const {
      accessControlProfileOptions,
      accessControlProfilesLoaded,
      editPropertyGuest,
      property,
      propertyGuest,
      propertyGuestsLoaded,
      credentialTypes,
      removePropertyGuest,
    } = this.props;

    if (!propertyGuestsLoaded || !accessControlProfilesLoaded) {
      return <Loading />;
    }

    return (
      <div>
        <PropertyGuestForm
          initialValues={{
            first_name: propertyGuest.get('first_name'),
            last_name: propertyGuest.get('last_name'),
            email: propertyGuest.get('email') || '',
            phone: propertyGuest.get('phone') || '',
            phone_country_code: '1',
            property_credentials: propertyGuest.get('property_credentials').toJS(),
            profiles: [
              propertyGuest.get('access_control_profile_id1'),
              propertyGuest.get('access_control_profile_id2'),
              propertyGuest.get('access_control_profile_id3'),
              propertyGuest.get('access_control_profile_id4'),
            ].filter(p => p !== null),
          }}
          removePropertyGuest={removePropertyGuest}
          profileOptions={accessControlProfileOptions}
          credentialTypes={credentialTypes}
          editing={true}
          handleSubmit={editPropertyGuest}
          propertyHasMasterPanel={property.get('clubhouse_panel_id') !== null}
        >
          <CommunityGuestAccessCodes communityGuestId={propertyGuest.get('id')} />
        </PropertyGuestForm>
      </div>
    );
  }
}

EditPropertyGuest.propTypes = {
  editPropertyGuest: PropTypes.func.isRequired,
  propertyGuest: PropTypes.object,
  propertyGuestsLoaded: PropTypes.bool.isRequired,
  credentialTypes: PropTypes.array.isRequired,
};
export default EditPropertyGuest;
