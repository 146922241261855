import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import querystring from 'query-string';
import Icon from '../icons/icon';

class Pager extends Component {
  onPageChange = page => {
    const { history, location, pageSize } = this.props;
    const searchQuery = querystring.parse(location.search);
    searchQuery.page = page.selected;
    searchQuery.page_size = pageSize;
    const newSearch = querystring.stringify(searchQuery);
    return history.push({ pathname: location.pathName, search: newSearch });
  };
  render() {
    const { initialPage, pageSize, totalItems, ...restProps } = this.props;
    const totalPages = Math.ceil(totalItems / pageSize);

    return (
      <div className="paginate__wrapper">
        {totalPages <= 1 ? null : (
          <ReactPaginate
            onPageChange={this.onPageChange}
            initialPage={initialPage}
            pageCount={totalPages}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            breakLabel={`...`}
            previousLabel={<Icon icon="ChevronLeft" />}
            nextLabel={<Icon icon="ChevronRight" />}
            disabledClassName={'paginate-disabled'}
            containerClassName={'paginate'}
            pageClassName={'paginate-page'}
            breakClassName={'paginate-break'}
            activeClassName={'paginate-active'}
            disableInitialCallback={true}
            {...restProps}
          />
        )}
      </div>
    );
  }
}

Pager.propTypes = {
  initialPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default withRouter(Pager);
