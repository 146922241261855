import CreatePost from './CreatePost';
import { createPost } from '../../../../../../redux/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => ({
  property: state.property.activeProperty,
});

const mapDispatchToProps = dispatch => ({
  createPost: (propertyId, type, payload) => dispatch(createPost(propertyId, type, payload)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    createPost: (type, payload) => dispatchProps.createPost(stateProps.property.get('id'), type, payload),
  });

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreatePost);
