import store from 'store2';
import config from '../config';
import { useQueryClient } from 'react-query';

const Logout = () => {
  useQueryClient().clear();

  // See: https://github.com/synapsestudios/littlebird/issues/4109 for more info about why this setTimeout is here
  setTimeout(() => {
    store.clearAll();
    window.location.href = [
      config.auth.domain,
      `/user/logout?post_logout_redirect_uri=`,
      config.auth.postLogoutRedirectUri,
    ].join('');
  }, 0);

  return null;
};

export default Logout;
