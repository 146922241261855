import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';

import Loading from '../../../../../../common/Loading';
import Delete from '../../../../../../common/Delete';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../common/Kebab/index';
import Icon from '../../../../../../common/icons/icon';
import UnrecognizedSchedules from './UnrecognizedSchedules';

class SchedulesList extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    accessControlSchedules: PropTypes.instanceOf(List),
    accessControlSchedulesLoaded: PropTypes.bool.isRequired,
    getPropertySchedules: PropTypes.func.isRequired,
    getPropertyPanelSchedules: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getPropertySchedules();
  }

  state = {
    openKebabDoorId: null,
    deleteConfirmationScheduleId: null,
  };

  onKebabClick = scheduleId => this.setState({ openKebabScheduleId: scheduleId });

  closeKebab = () => this.setState({ openKebabScheduleId: null });

  renderKebab(schedule) {
    const { match, history } = this.props;
    const { openKebabScheduleId } = this.state;

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(schedule.get('id'))}
          onDisengage={this.closeKebab}
          menuOpened={schedule.get('id') === openKebabScheduleId}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={() => {
                history.push(`${match.url}/${schedule.get('id')}`);
                this.closeKebab();
              }}
            >
              <Icon icon="Edit" />
              Edit
            </div>
            <div
              className="kebab__menu--item"
              onClick={() =>
                this.setState({ deleteConfirmationScheduleId: schedule.get('id'), openKebabScheduleId: null })
              }
            >
              <Icon icon="Remove" />
              Delete
            </div>
          </div>
        </Kebab>
      </div>
    );
  }

  renderDeleteConfirmation = schedule => {
    const { removeSchedule } = this.props;

    const isDisabled = schedule.get('profiles') && schedule.get('profiles').size > 0;

    return (
      <div>
        <Delete
          disabled={isDisabled}
          onCancel={() => this.setState({ deleteConfirmationScheduleId: null, openKebabScheduleId: null })}
          deleteFunc={async () => {
            const action = await removeSchedule(schedule);
            if (!action.response.ok) {
              this.setState({
                error: (action.json && action.json.message) || 'Unknown error occurred while deleting schedule',
                deleteConfirmationScheduleId: null,
                openKebabScheduleId: null,
              });
            }
          }}
          message={
            <div>
              {!isDisabled ? (
                <div>
                  Are you sure you want to <strong className="strong">remove this schedule</strong>?
                </div>
              ) : (
                <div className="delete-reqs__wrapper">
                  <div className="delete-reqs--title">
                    Can't delete because these profiles still reference this schedule:
                  </div>
                  <div className="delete-reqs--list">
                    {schedule
                      .get('profiles')
                      .toJS()
                      .map(profile => (
                        <div className="delete-req" key={profile.id}>
                          • {profile.name}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          }
        />
      </div>
    );
  };

  render() {
    const { match, accessControlSchedules, accessControlSchedulesLoaded, getPropertyPanelSchedules } = this.props;
    const { deleteConfirmationScheduleId } = this.state;

    if (!accessControlSchedulesLoaded) {
      return <Loading />;
    }

    return (
      <div className="container schedules-list--page">
        <div className="page-header subheader">
          <div className="page-header__title">
            <h4 className="h4">Schedules</h4>
          </div>

          <div className="page-header__actions">
            <button
              type="button"
              className="button button--outline"
              onClick={() => {
                this.props.getPropertySchedules();
                getPropertyPanelSchedules();
              }}
            >
              Fetch Schedules
            </button>
            {accessControlSchedules.size > 0 && (
              <Link to={`${match.url}/add-schedule`} className="button">
                Add New Schedule
              </Link>
            )}
          </div>
        </div>

        <div className="schedules__container">
          <div className="schedules--recognized">
            <h4 className="h4">Recognized</h4>
            {accessControlSchedules.size > 0 ? (
              <table className="table-units-list">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {accessControlSchedules.map(schedule => (
                    <tr key={schedule.get('id')}>
                      <td>{schedule.get('name')}</td>
                      {
                        <td className="table__align-right">
                          {deleteConfirmationScheduleId === schedule.get('id')
                            ? this.renderDeleteConfirmation(schedule)
                            : this.renderKebab(schedule)}
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <EmptyPlaceholder buttonText="Add Schedule" linkTo={`${match.url}/add-schedule`} />
            )}
          </div>

          <div className="schedules--unrecognized">
            <h4 className="h4">Unrecognized</h4>
            <UnrecognizedSchedules />
          </div>
        </div>
      </div>
    );
  }
}

export default SchedulesList;
