import React, { useState, useEffect } from 'react';

import NotificationStatusIndicator from '../../../../../../../../../../common/forms/NotificationStatusIndicator';

export const SGNotification = ({ submitSettings }) => {
  const [hideNotification, setHideNotification] = useState(false);

  const timer = () => setTimeout(() => setHideNotification(false), 3000);

  useEffect(() => {
    if (
      submitSettings.type === 'Success' ||
      submitSettings.type === 'Failure' ||
      submitSettings.type === 'Informational'
    ) {
      setHideNotification(true);
      timer();
    }
    return () => clearTimeout(timer());
  }, [submitSettings]);

  if (!submitSettings.type) return <></>;

  return (
    <NotificationStatusIndicator
      hideNotification={!hideNotification}
      message={submitSettings.message}
      type={submitSettings.type}
    />
  );
};
