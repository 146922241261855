import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getScheduleTourUnitGuestById } from '../../../../../../redux/actions/scheduledAction';

export const useFetchGuest = () => {
  const [isLoad, setIsLoad] = useState(true);
  const dispatch = useDispatch();
  const { propertyId, visitorId } = useParams();
  const guest = useSelector(state => state.property.guest);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    await dispatch(getScheduleTourUnitGuestById(propertyId, visitorId));
    setIsLoad(false);
  };

  return {
    isLoad,
    guest,
  };
};
