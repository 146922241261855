const validationBlockSchema = {
  units: (touched, errors) => ({
    error: !!(errors.units && touched.units),
  }),
  profile: (touched, errors) => ({
    error: !!(errors.profile && touched.profile),
  }),
  availabilityTimes: (touched, errors) => ({
    error: !!(
      (errors.weekdays && touched.weekdays) ||
      (errors.start_time && touched.start_time) ||
      (errors.end_time && touched.end_time) ||
      (errors.tour_count && touched.tour_count)
    ),
  }),
  pretourMessage: (touched, errors) => ({
    error: !!((touched.pre_message && errors.pre_send_type) || (touched.pre_send_type && errors.pre_message)),
  }),
  registrationLink: () => ({
    error: false,
  }),
  notifiedStaff: (touched, errors) => ({
    error: !!(errors.notified_staff && touched.notified_staff),
  }),
  default: () => ({
    error: false,
  }),
};

export const useValidationBlockSchema = () => validationBlockSchema;
