import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';

import SpinnerLoading from '../../../../../../common/Loading/SpinnerLoading';
import PageHeader from '../../../../../../common/PageHeader';
import DoorForm from '../../components/DoorForm';

class EditDoor extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    property: PropTypes.instanceOf(Map),
    accessControlAreas: PropTypes.instanceOf(List).isRequired,
    getDoors: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.getPropertyAreas();
    this.props.getDoors();
  }

  handleSuccess = () => {
    this.props.history.push('./');
  };

  handleCancel = () => {
    this.props.history.push('./');
  };

  render() {
    const { editDoor, accessControlAreas, accessControlAreasLoaded, matchingDoor } = this.props;

    return (
      <div>
        <PageHeader title="Doors" white backLink="./" />
        <div className="container">
          {accessControlAreasLoaded && matchingDoor ? (
            <DoorForm
              initialValues={{
                name: matchingDoor.get('name'),
                dmp_device_number: matchingDoor.get('dmp_device_number'),
                dmp_device_communication_type: matchingDoor.get('dmp_device_communication_type'),
                strike_delay: matchingDoor.get('strike_delay'),
                strike_time: matchingDoor.get('strike_time'),
                areas: matchingDoor
                  .get('areas')
                  .map(area => area.get('id'))
                  .toJS(),
              }}
              submitFunc={editDoor}
              onCancel={this.handleCancel}
              onSuccess={this.handleSuccess}
              areas={accessControlAreas.toJS()}
            />
          ) : (
            <SpinnerLoading />
          )}
        </div>
      </div>
    );
  }
}

export default EditDoor;
