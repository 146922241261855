import { connect } from 'react-redux';
import { compose } from 'redux';
import AdminUserList from './AdminUserList';
import { withRouter } from 'react-router-dom';
import { getAdmins, removeAdmin } from '../../../../redux/actions';
import { getUserPermission } from '../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    admins: state.adminList,
    loading: state.adminListLoading,
    user: state.user,
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getAdmins: () => dispatch(getAdmins()),
    removeAdmin: id => dispatch(removeAdmin(id)),
  };
}
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(AdminUserList);
