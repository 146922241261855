import React from 'react';

function Add() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter x="-25%" y="-20%" width="150%" height="150%" filterUnits="objectBoundingBox" id="a">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.688377491 0 0 0 0 0.688377491 0 0 0 0 0.688377491 0 0 0 0.479594624 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <circle id="b" cx="12" cy="12" r="10" />
      </defs>
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill="#FFF" xlinkHref="#b" />
      <path fill="#1E90FF" d="M10.333 14.317L8.017 12l-.79.783 3.106 3.106L17 9.222l-.783-.783z" />
    </svg>
  );
  /* eslint-enable */
}

export default Add;
