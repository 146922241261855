import { connect } from 'react-redux';

import AddArea from './AddArea';
import { addArea } from '../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addArea: payload => dispatch(addArea(ownProps.match.params.propertyId, payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddArea);
