import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import AdminUserList from './views/AdminUserList';
import EditAdminUser from './views/EditAdminUser';
import AddAdminUser from './views/AddAdminUser';

class AdminManagement extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}/`} component={AdminUserList} />
        <Route exact path={`${match.path}/add`} component={AddAdminUser} />
        <Route exact path={`${match.path}/:adminId/edit`} component={EditAdminUser} />
      </Switch>
    );
  }
}

AdminManagement.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AdminManagement;
