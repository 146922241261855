import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import ModalAlert from '../ModalAlert';

export const RouteLeavingGuard = ({ when, navigate, modalAlertProps }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ModalAlert
        {...modalAlertProps}
        visible={modalVisible}
        handleCancel={closeModal}
        handleConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};
export default RouteLeavingGuard;
