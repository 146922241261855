export default {
  main: {
    title: 'Property Guests',
    addButtonTitle: 'Add Property Guest',
    tableHeaders: {
      name: 'NAME',
      phone: 'PHONE NUMBER',
    },

    removeConfirmation: 'Are you sure you want to <strong className="strong">remove this guest</strong>?', //!
  },

  property: {
    addTitle: 'Add Property Guest',
    editTitle: 'Edit Property Guest',
    guestInformation: 'Guest Information',

    fields: {
      firstName: 'FIRST NAME',
      lastName: 'LAST NAME',
      phoneNumber: 'PHONE NUMBER',
      emailAddress: 'EMAIL ADDRESS',
    },

    errors: {
      enterFirstName: 'Please enter a first name',
      maxFirstName: 'First Name must be 64 characters or less', //!
      enterLastName: 'Please enter a last name',
      maxLastName: 'Last Name must be 64 characters or less', //!
      enterPhoneNumber: 'Please enter a phone number',
      invalidPhoneNumber: 'Must be a valid phone number', //!
      invalidEmailAddress: 'Must be a valid email address',
      enterCode: 'Please enter a code', //!
      invalidCodeLength: 'Credential code must be 5 characters', //!
      invalidCodeNumber: 'Code must be between 00000 and 65535', //!
      selectType: 'Please select a credential type', //!
      invalidNoteLength: 'Credential note must be 255 characters or less', //!
      uniqueProperty: 'Code must be unique', //!
    },

    accessProfiles: 'Access Profiles',
    savePropertyGuest: 'Save Property Guest',
    savingPropertyGuest: 'Saving Property Guest...', //!

    removeGuest: 'Remove Guest',
  },
};
