import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import querystring from 'query-string';

class SearchList extends Component {
  state = { search: 'none', parsedUrl: '' };

  componentDidMount() {
    const { location, name } = this.props;
    const parsedUrl = querystring.parse(location.search);
    this.setState({ parsedUrl, search: parsedUrl[name] });
  }

  componentDidUpdate(prevProps) {
    const { location, name } = this.props;
    if (prevProps.location !== location) {
      const parsedUrl = querystring.parse(location.search);
      if (parsedUrl[name]) {
        return this.setState({ parsedUrl, search: parsedUrl[name] });
      }
      return this.setState({ search: '' });
    }
    return true;
  }

  onChangeSelect = event => {
    const { history, location, name } = this.props;
    const currentUrl = querystring.parse(location.search);
    this.setState({ search: event.value });
    const newSearch = Object.assign(currentUrl, { [name]: event.value, page: 0 });
    return history.push({ pathname: location.pathName, search: querystring.stringify(newSearch) });
  };

  render() {
    const { options, placeHolder, searchable } = this.props;
    const { search } = this.state;
    return (
      <div className="react-select-scaffold select--filter" style={{ minWidth: '215px' }}>
        <Select
          clearable={false}
          closeOnSelect={true}
          placeHolder={placeHolder}
          name="search"
          searchable={searchable}
          value={search}
          onChange={this.onChangeSelect}
          options={options}
        />
      </div>
    );
  }
}
SearchList.defaultProps = {
  searchable: false,
};
SearchList.propTypes = {
  placeHolder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  searchable: PropTypes.bool,
};

export default withRouter(SearchList);
