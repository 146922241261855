import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useBlockErrors } from '../hooks';

export const SGBlockErrors = ({ className }) => {
  const { blockErrors, isErrors, isDelLength } = useBlockErrors();

  if (!isErrors) return <></>;

  return (
    <span className={classNames('sg-block-errors error-text', className)}>
      {blockErrors.length < 2 ? '* Please fill out section' : '* Please fill out sections'}{' '}
      {blockErrors.map(({ number }, index) => `${number}${index < isDelLength ? ', ' : ''} `)}
    </span>
  );
};

SGBlockErrors.propTypes = {
  className: PropTypes.string,
};
