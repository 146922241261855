import React from 'react';

function PartyPopper() {
  /* eslint-disable max-len */
  return (
    <svg id="Icons" height="100%" viewBox="0 0 60 60" width="100%" xmlns="http://www.w3.org/2000/svg">
      <path d="m48.327 43.965-44.671 14.927a2.013 2.013 0 0 1 -2.548-2.548l14.927-44.671z" fill="#fdc70e" />
      <path
        d="m48.327 43.967s-6.579-1.49-18.684-13.607c-11.784-11.773-13.524-18.34-13.607-18.683v-.012s6.591 1.491 18.7 13.6 13.591 18.702 13.591 18.702z"
        fill="#d39518"
      />
      <path
        d="m19.46 53.61-6.62 2.21c-1.39-1.24-2.87-2.63-4.45-4.21s-2.97-3.06-4.21-4.45l2.21-6.62c1.68 2.03 3.73 4.3 6.25 6.82s4.79 4.57 6.82 6.25z"
        fill="#2167d8"
      />
      <path
        d="m37.48 47.59-7.11 2.37a82.594 82.594 0 0 1 -10.93-9.4 82.594 82.594 0 0 1 -9.4-10.93l2.37-7.11a74.4 74.4 0 0 0 11.28 13.79 74.4 74.4 0 0 0 13.79 11.28z"
        fill="#d3374e"
      />
      <path
        d="m25.533 20.406a1 1 0 0 1 -.707-1.707 12.2 12.2 0 0 0 2.36-14.675 1 1 0 1 1 1.714-1.024 14.247 14.247 0 0 1 -2.66 17.118.993.993 0 0 1 -.707.288z"
        fill="#2167d8"
      />
      <path
        d="m23.86 10.366a1 1 0 0 1 -.707-.293l-1.674-1.673a1 1 0 1 1 1.415-1.414l1.673 1.673a1 1 0 0 1 -.707 1.707z"
        fill="#23af6f"
      />
      <path d="m35.573 8.693a1 1 0 0 1 -1-1v-1.673a1 1 0 0 1 2 0v1.673a1 1 0 0 1 -1 1z" fill="#d3374e" />
      <path d="m37.247 15.387h-1.674a1 1 0 0 1 0-2h1.674a1 1 0 0 1 0 2z" fill="#23af6f" />
      <path
        d="m40.593 35.467a1 1 0 0 1 -.707-1.707 14.248 14.248 0 0 1 17.119-2.66 1 1 0 0 1 -1.027 1.715 12.067 12.067 0 0 0 -14.678 2.359 1 1 0 0 1 -.707.293z"
        fill="#2167d8"
      />
      <path
        d="m52.307 38.813a1 1 0 0 1 -.707-.292l-1.674-1.674a1 1 0 0 1 1.414-1.414l1.674 1.673a1 1 0 0 1 -.707 1.707z"
        fill="#23af6f"
      />
      <path d="m53.979 25.427h-1.672a1 1 0 0 1 0-2h1.672a1 1 0 0 1 0 2z" fill="#d3374e" />
      <path d="m45.613 25.427a1 1 0 0 1 -1-1v-1.674a1 1 0 0 1 2 0v1.674a1 1 0 0 1 -1 1z" fill="#d3374e" />
      <path
        d="m37.247 22.08a1 1 0 0 1 -.707-1.707 4.061 4.061 0 0 1 3.216-1.128h.022a2.1 2.1 0 0 0 1.779-.542 2.119 2.119 0 0 0 .542-1.8 3.94 3.94 0 0 1 4.346-4.348 2 2 0 0 0 2.355-2.355 4.067 4.067 0 0 1 1.131-3.218 1 1 0 1 1 1.409 1.418 2.128 2.128 0 0 0 -.545 1.8 3.942 3.942 0 0 1 -4.349 4.349 1.993 1.993 0 0 0 -2.346 2.351 4.054 4.054 0 0 1 -1.128 3.216 4.105 4.105 0 0 1 -3.216 1.128h-.023a2.094 2.094 0 0 0 -1.778.542 1 1 0 0 1 -.708.294z"
        fill="#fdc70e"
      />
      <g fill="#d3374e">
        <path d="m40.594 10.366a1 1 0 0 1 -.925-1.381 9.272 9.272 0 0 0 .042-7.515 1 1 0 0 1 1.764-.944 11.275 11.275 0 0 1 .044 9.221 1 1 0 0 1 -.925.619z" />
        <path d="m50.633 20.406a1 1 0 0 1 -.381-1.925 11.266 11.266 0 0 1 9.222.044 1 1 0 0 1 -.944 1.764 9.267 9.267 0 0 0 -7.516.042 1.006 1.006 0 0 1 -.381.075z" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default PartyPopper;
