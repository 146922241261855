import { connect } from 'react-redux';
import { compose } from 'redux';
import AddPropertyManager from './AddPropertyManager';
import { withRouter } from 'react-router-dom';
import { addPropertyManager, getProfiles } from '../../../../../../redux/actions';
import {
  selectAccessControlProfileOptions,
  selectDefaultAccessControlProfilesForUserType,
} from '../../../../../../redux/selectors';
import { selectRoleOptions2 } from '../../../../../../../RolesAndPermissions/redux/selectors';
import { getRolesByProperty } from '../../../../../../../RolesAndPermissions/redux/actions';
import { getUserPermission } from '../../../../../..../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    propertyManagers: state.property.activeProperty.get('staff'),
    accessControlProfilesLoaded: state.property.accessControlProfilesLoaded,
    accessControlProfileOptions: selectAccessControlProfileOptions(state),
    accessControlProfileDefaults: selectDefaultAccessControlProfilesForUserType(state, 'staff'),
    roleOptions: selectRoleOptions2(state),
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    addPropertyManager: values => dispatch(addPropertyManager(props.match.params.propertyId, values)),
    getProfiles: () => dispatch(getProfiles(props.match.params.propertyId)),
    getRolesByProperty: propertyId => dispatch(getRolesByProperty(propertyId)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(AddPropertyManager);
