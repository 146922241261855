import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../../../../common/PageHeader';
import CredentialTypeForm from '../../../../../../common/forms/CredentialTypeForm';

class AddCredentialType extends Component {
  render() {
    const { createCredentialType, history } = this.props;

    return (
      <div>
        <PageHeader title="Add Credential Type" backLink="./" />
        <div className="container">
          <CredentialTypeForm
            initialValues={{
              name: '',
              internal_part_number: '',
              manufacturer_part_number: '',
              credential_payload_length: '',
              user_code_start_position: '',
              user_code_length: '',
              site_code_required: false,
              site_code_start_position: '',
              site_code_length: '',
            }}
            onSubmitClick={submitValues => {
              return createCredentialType(submitValues).then(res => {
                if (res.response.ok) {
                  return history.push(`/access-control/credential-types/`);
                }
                throw res.json.message;
              });
            }}
            onCancelClick={() => history.goBack()}
          />
        </div>
      </div>
    );
  }
}

AddCredentialType.propTypes = {
  history: PropTypes.object.isRequired,
};

export default AddCredentialType;
