import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RadioFormField = ({ className, ...props }) => {
  const [fields, setFields] = useState(
    props.fields.map(field => ({
      ...field,
      isChecked: field.value === props.activeValue,
    }))
  );

  const onChange = e => {
    const isChecked = e.target.checked;
    const value = e.target.value;

    const activeField = {
      ...fields.find(field => field.value === value),
      isChecked,
    };

    setFields(
      fields.map(field => ({
        ...field,
        isChecked: value === field.value ? isChecked : false,
      }))
    );

    props.onChange(activeField.isChecked ? activeField : '');
  };

  return (
    <div className={className}>
      {fields.reduce((data, { id, name, value, isChecked, label, labelText, disabled }, index) => {
        data.push(
          <label
            className={classNames('label-radio-field', {
              disabled: disabled,
            })}
            key={id}
          >
            <input
              type="checkbox"
              name={name}
              value={value}
              onChange={onChange}
              checked={isChecked}
              disabled={disabled}
            />
            <div>
              <span
                className={classNames('label-strong', {
                  blue: props.isLabelСolor && isChecked,
                })}
              >
                {label}
              </span>
              <br />
              <span className="label-text" dangerouslySetInnerHTML={{ __html: labelText }}></span>
            </div>
          </label>
        );

        if (props.delimiter && index < fields.length - 1) data.push(props.delimiter());

        return data;
      }, [])}
      {props.error ? <div className="input-validation warning">{props.error}</div> : null}
    </div>
  );
};

RadioFormField.propTypes = {
  activeValue: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      name: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
      labelText: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
};

export default RadioFormField;
