import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import AddAnnouncement from './views/AddAnnouncement';
import EditAnnouncement from './views/EditAnnouncement';
import NotFound from '../../../../common/NotFound';
import AnnouncementList from './views/AnnouncementList';

class Messages extends Component {
  render() {
    const { match, property } = this.props;

    if (!property.get('enabled_messages') || !property.get('admin_enabled_community')) {
      return <NotFound />;
    }

    return (
      <div>
        <Switch>
          <Route exact path={`${match.path}/add`} component={AddAnnouncement} />
          <Route path={`${match.path}/:messageId`} component={EditAnnouncement} />
          <Route exact path={`${match.path}/`} component={AnnouncementList} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

Messages.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Messages;
