import React from 'react';
import PropTypes from 'prop-types';
import { SGModal } from '../../modals/SGModal';
import { useGuestImages } from '../hooks/useGuestImages';

export const SGDetailImages = ({ imageUrl }) => {
  const { images, activeModal, imgSrc, showPicture, handleCancelModal } = useGuestImages(imageUrl);

  return (
    <>
      <div className="detail-label detail-identification-header">IDENTIFICATION</div>
      <div className="detail-identification">
        {images.map((img, idx) => (
          <div key={idx}>
            <img className="id-img" onClick={showPicture} src={img} alt="IDENTIFICATION" />
          </div>
        ))}
      </div>
      <SGModal width={1000} visible={activeModal} handleCancel={handleCancelModal}>
        <div className="identification-image-modal">
          <img className="images" src={imgSrc} alt="IDENTIFICATION" />
        </div>
      </SGModal>
    </>
  );
};

SGDetailImages.propTypes = {
  imageUrl: PropTypes.string,
};
