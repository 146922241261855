import React, { useEffect } from 'react';
import {
  saveMaintenanceConfiguration,
  editMaintenanceConfiguration,
  getMaintenanceConfiguration,
  getMRIConfiguration,
} from '../../../../redux/actions';
import MaintenanceForm from './MaintenanceForm';
import NotFound from '../../../../../NotFound';
import { useDispatch, useSelector } from 'react-redux';

export default function Maintenance(props) {
  const dispatch = useDispatch();
  const community = useSelector(state => state.property.activeProperty);
  const mriConfiguration = useSelector(state => state.property.mriConfiguration);
  const maintenanceConfiguration = useSelector(state => state.property.maintenanceConfiguration);

  useEffect(() => {
    dispatch(getMaintenanceConfiguration(community.get('id')));
    dispatch(getMRIConfiguration(community.get('id')));
  }, [dispatch, community]);

  if (!community.get('enabled_maintenance')) {
    return <NotFound />;
  }

  return (
    <MaintenanceForm
      community={community}
      maintenanceConfiguration={maintenanceConfiguration}
      mriConfiguration={mriConfiguration}
      saveMaintenanceConfiguration={saveMaintenanceConfiguration}
      editMaintenanceConfiguration={editMaintenanceConfiguration}
    />
  );
}
