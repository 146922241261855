import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import PropertyManagersList from './views/PropertyManagersList';
import EditPropertyManager from './views/EditPropertyManager';
import AddPropertyManager from './views/AddPropertyManager';

class PropertyManagers extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}/`} component={PropertyManagersList} />
        <Route exact path={`${match.path}/:propertyManagerId/edit`} component={EditPropertyManager} />
        <Route exact path={`${match.path}/add`} component={AddPropertyManager} />
      </Switch>
    );
  }
}

PropertyManagers.propTypes = {
  match: PropTypes.object.isRequired,
};

export default PropertyManagers;
