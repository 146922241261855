import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import Icon from '../../common/icons/icon';

const xAxisValueForHour = (hour, startHour, width, numHours) => ((hour - startHour) / numHours) * width;
export function HourBasedBarGraph({ data, width, height, startHour, endHour, labeledHours }) {
  const canvasRef = useRef(null);
  const numHours = endHour - startHour;
  const barMargin = 3;
  const hourWidth = width / numHours;
  const barWidth = hourWidth - barMargin * 2;

  const [highlightedHour, setHiglightedHour] = useState(new Date().getHours());

  const hourFromXAxisValue = useCallback(x => Math.floor(x / hourWidth) + startHour, [hourWidth, startHour]);

  function onMouseOver(event) {
    const canvas = canvasRef.current;
    var rect = canvas.getBoundingClientRect();
    const mouseX = ((event.clientX - rect.left) / (rect.right - rect.left)) * canvas.width;
    const hourMouseIsOver = hourFromXAxisValue(mouseX);
    setHiglightedHour(hourMouseIsOver);
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const maxAverage = Object.values(data).reduce((max, hourAverage) => (hourAverage > max ? hourAverage : max), 0);

    context.clearRect(0, 0, canvas.width, canvas.height);
    Object.entries(data).forEach(([hour, hourAverage]) => {
      const x = xAxisValueForHour(hour, startHour, width, numHours);
      context.fillStyle = parseInt(hour, 10) === highlightedHour ? '#1b79fe' : '#96c9ff';
      context.fillRect(x, height * (1 - hourAverage / maxAverage), barWidth, height);
    });
  }, [data, height, startHour, numHours, width, hourWidth, highlightedHour, barWidth]);

  return (
    <div>
      <canvas ref={canvasRef} onMouseMove={onMouseOver} width={width} height={height} />
      {useMemo(
        () => (
          <Timeline {...{ width, startHour, numHours, labeledHours }} />
        ),
        [width, startHour, numHours, labeledHours]
      )}
      <div className="activity-indicator-people-count">
        <Icon icon="Person" />
        <span>{Math.round(data[highlightedHour] ?? 0)} People</span>
      </div>
    </div>
  );
}

function Timeline({ width, labeledHours, startHour, numHours }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the line along the top of the timeline
    context.fillStyle = '#5B5E63';
    context.moveTo(0, 0);
    context.lineTo(width, 0);
    context.stroke();

    labeledHours.forEach(hour => {
      const displayHour = hour % 12 === 0 ? 12 : hour % 12;
      const formattedHour = hour >= 12 ? `${displayHour}pm` : `${displayHour}am`;

      const x = xAxisValueForHour(hour, startHour, width, numHours);
      context.moveTo(x, 0);
      context.lineTo(x, 7);
      context.stroke();
      context.fillStyle = '#929295';
      context.font = 'bold 12px Roboto';
      context.fillText(formattedHour, x - 10, 20);
    });
  }, [width, numHours, startHour, labeledHours]);

  return (
    <div className="activity-indicator-timeline">
      <canvas ref={canvasRef} height={25} width={width} />
    </div>
  );
}
