import { connect } from 'react-redux';
import {
  getPanelDevices,
  requestRemoveZwaveDevice,
  cancelPanelJobs,
  getJobStatus,
  removePanelDevice,
} from '../../../../redux/actions';
import RemoveDevice from './RemoveDevice';

function mapStateToProps(state, props) {
  return {
    panel: state.devices.panelDevices,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPanelDevices: () => dispatch(getPanelDevices(ownProps.match.params.propertyId, ownProps.match.params.panelId)),
    requestRemoveZwaveDevice: payload =>
      dispatch(requestRemoveZwaveDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    cancelPanelJobs: payload =>
      dispatch(cancelPanelJobs(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    getJobStatus: payload => {
      return dispatch(getJobStatus(ownProps.match.params.propertyId, payload));
    },
    removePanelDevice: id =>
      dispatch(removePanelDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, id)),
  };
}

const RemoveDeviceContainer = connect(mapStateToProps, mapDispatchToProps)(RemoveDevice);

export default RemoveDeviceContainer;
