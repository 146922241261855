import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const SGDetailHeader = ({ title, name, children, info, status }) => (
  <div className={classNames('sg-detail-header', { '--disabled-tour': !status && info })}>
    <div className="sg-detail-header-left">
      <div className="detail-label header-detail-label">{title}</div>
      <div className="detail-name">{name}</div>
      {info}
    </div>
    {children && <div className={classNames('sg-detail-header-right', { 'm-b25': !status && info })}>{children}</div>}
  </div>
);

SGDetailHeader.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
  info: PropTypes.node,
  status: PropTypes.bool,
};
