import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from '../../common/NotFound';
import VacantUnits from './views/VacantUnits';
import EditVacantUnits from './views/EditVacantUnits';
import BatteryLevels from './views/BatteryLevels';

class UnitAutomation extends Component {
  render() {
    const { match, property, userPermissionList } = this.props;

    if (!property.get('admin_enabled_unit_automation')) {
      return <NotFound />;
    }
    const userPermission = {
      // Smart Home
      BatteryLevels: userPermissionList.includes('VIEW_DEVICE_BATTERY_PAGE'),
      VacantUnits: userPermissionList.includes('VIEW_VACANT_UNIT_AUTOMATION_PAGE'),
    };

    return (
      <div>
        <Switch>
          <Route exact path={`${match.path}/vacant-units`} component={VacantUnits} />
          <Route path={`${match.path}/vacant-units/edit`} component={EditVacantUnits} />
          <Route exact path={`${match.path}/battery-levels`} component={BatteryLevels} />
          <Redirect to={userPermission.BatteryLevels ? 'automation/battery-levels' : 'automation/vacant-units'} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

UnitAutomation.propTypes = {
  match: PropTypes.object.isRequired,
};

export default UnitAutomation;
