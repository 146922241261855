import client from '../../../../../../../../../common/client';

export const getGuestList = (event_id, keyword) => {
  return {
    promise: client.get(`events/get-resident-events-to-registration/${event_id}/${keyword}`),
    type: 'GET_GUESTLIST',
  };
};

export const uploadEventCsv = (EventId, file) => {
  const data = new FormData();
  data.append('file', file, file.name);
  return {
    promise: client.post(`events/${EventId}/registration/get-residents-from-csv`, data),
    type: 'UPLOAD_EVENT_CSV',
  };
};

export const eventRegister = (EventId, state, payload) => {
  return {
    promise: client.post(`events/${EventId}/register-with-valid/${state}`, payload),
    type: 'EVENT_RESGISTER',
  };
};
