import { connect } from 'react-redux';
import AddTelephoneEntryDevice from './AddTelephoneEntryDevice';
import { selectPropertyOptions } from '../../../../../../redux/selectors';
import { createTelephoneEntryDevice, getProperties } from '../../../../../../redux/actions';

function mapStateToProps(state, props) {
  return {
    propertyOptions: selectPropertyOptions(state, props),
    telephoneEntryDevicesLoaded: state.telephoneEntryDevicesLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    createTelephoneEntryDevice: payload => dispatch(createTelephoneEntryDevice(payload)),
    getProperties: () => dispatch(getProperties()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTelephoneEntryDevice);
