import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Kebab from '../../../../../../../../common/Kebab';
import Icon from '../../../../../../../../common/icons/icon';
import { trimAndSetNullFalsyValues } from '../../../../../../../../common/utils/helpers';
import DeviceForm from './DeviceForm';

class Device extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    subtype: PropTypes.string.isRequired,
    isCommunityDevice: PropTypes.bool,
    hideBatteryStatus: PropTypes.bool,
    submitAction: PropTypes.func.isRequired,
    removeAction: PropTypes.func,
    isMissing: PropTypes.bool,
    zone: PropTypes.number,
  };
  state = {
    isFormExpanded: false,
    isMenuShowing: false,
  };
  toggleFormExpanded = () => {
    this.setState(state => ({ isFormExpanded: !state.isFormExpanded }));
  };
  toggleMenuShowing = () => {
    this.setState(state => ({ isMenuShowing: !state.isMenuShowing }));
  };
  handleEditDevice = () => {
    this.toggleFormExpanded();
    this.toggleMenuShowing();
  };
  handleRemoveDevice = () => {
    const { removeAction, id } = this.props;
    removeAction(id);
  };
  handleUnpairVideoDoorbellDevice = () => {
    const { unpairVideoDoorbellDevice, id } = this.props;
    unpairVideoDoorbellDevice(id);
  };
  handleSubmit = payload => {
    return this.props.submitAction(trimAndSetNullFalsyValues(payload));
  };
  renderExpanded = () => {
    const { name, isCommunityDevice, hideBatteryStatus, type, subtype, id, zone, mastercode } = this.props;
    if (this.state.isFormExpanded) {
      return (
        <DeviceForm
          initialValues={{
            id: id,
            name: name || '',
            is_community_device: isCommunityDevice === null ? true : isCommunityDevice,
            hide_battery_status: !!hideBatteryStatus,
            zone: zone,
            dmp_hardware_type: type,
            dmp_hardware_subtype: subtype,
            mastercode: mastercode || '',
          }}
          onSubmit={this.handleSubmit}
          onCancel={() => this.setState(state => ({ isFormExpanded: false }))}
          onSuccess={() => this.setState(state => ({ isFormExpanded: false }))}
        />
      );
    }
  };
  render() {
    const {
      id,
      type,
      subtype,
      isCommunityDevice,
      name,
      removeAction,
      isMissing,
      zone,
      removeDeviceFromSmartHomeController,
    } = this.props;
    let deviceTypeIcon = '';

    switch (type) {
      case 'LOCK':
        deviceTypeIcon = 'Door';
        break;
      case 'THERMOSTAT':
        deviceTypeIcon = 'Thermostat';
        break;
      case 'LIGHT':
        deviceTypeIcon = 'Light';
        break;
      case 'BARRIER_OPERATOR':
        deviceTypeIcon = 'GarageClosed';
        break;
      case 'OUTLET':
        deviceTypeIcon = 'Outlet';
        break;
      case 'DOORBELL':
        deviceTypeIcon = 'Doorbell';
        break;
      default:
        deviceTypeIcon = 'Robot';
    }

    return (
      <div className="device">
        <div className="device--details-wrapper">
          <div className="device__name">
            <div className="device__name--icon-type">
              <Icon icon={deviceTypeIcon} />
            </div>
            <div className="device__name--pretty-name">{name || '—'}</div>
          </div>
          <div className="device__uid">
            <div className="device__detail--label">UID</div>
            <div className="device__detail--value">{id}</div>
          </div>
          <div className="device__type">
            <div className="device__detail--label">DEVICE TYPE</div>
            {type === 'DOORBELL' ? (
              <div className="device__detail--value" style={{ textTransform: 'none', wordBreak: 'normal' }}>
                Video&nbsp;Doorbell
              </div>
            ) : (
              <div className="device__detail--value">{type}</div>
            )}
          </div>
          {type !== 'DOORBELL' && (
            <div className="device__subtype">
              <div className="device__detail--label">DEVICE SUBTYPE</div>
              <div className="device__detail--value">{subtype}</div>
            </div>
          )}
          <div className="device__zone">
            <div className="device__detail--label">ZONE</div>
            <div className="device__detail--value">{zone || '—'}</div>
          </div>
          <div className="device__area">
            <div className="device__detail--label">AREA</div>
            <div className="device__detail--value">
              {isCommunityDevice === null ? '—' : isCommunityDevice ? 'Community' : 'Unit'}
            </div>
          </div>
          <Kebab
            onClick={e => {
              e.stopPropagation();
              this.toggleMenuShowing();
            }}
            disabled={this.state.isFormExpanded}
            onDisengage={this.toggleMenuShowing}
            menuOpened={this.state.isMenuShowing}
          >
            <div className="kebab__menu">
              {type !== 'DOORBELL' ? (
                <div className="kebab__menu--item" onClick={this.handleEditDevice}>
                  <Icon icon="Edit" /> Edit Device
                </div>
              ) : (
                <div className="kebab__menu--item" onClick={this.handleUnpairVideoDoorbellDevice}>
                  <Icon icon="Unpair" /> Unpair From Unit
                </div>
              )}
              {removeDeviceFromSmartHomeController && removeAction ? (
                <div className="kebab__menu--item" onClick={this.handleRemoveDevice}>
                  <Icon icon="Remove" /> Remove Device
                </div>
              ) : null}
            </div>
          </Kebab>
        </div>
        <div className="device-form__wrapper">{this.renderExpanded()}</div>
        {isMissing ? (
          <div className="device--alert">
            <Icon icon="Warning" />
            Device is in our system for this panel SN but not currently paired to the panel.
          </div>
        ) : null}
      </div>
    );
  }
}

export default Device;
