import React from 'react';

function RPicongray() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 268 249">
      <path d="M 30.5,-0.5 C 79.5,-0.5 128.5,-0.5 177.5,-0.5C 187.994,5.14729 190.827,13.4806 186,24.5C 183.736,27.4335 180.902,29.6001 177.5,31C 131.5,31.3333 85.5,31.6667 39.5,32C 35,32.5 32.5,35 32,39.5C 31.3333,95.8333 31.3333,152.167 32,208.5C 32.6898,211.196 34.1898,213.363 36.5,215C 94.5,215.667 152.5,215.667 210.5,215C 212.286,214.215 213.786,213.049 215,211.5C 215.333,181.167 215.667,150.833 216,120.5C 219.053,110.25 225.886,106.083 236.5,108C 242.333,109.833 246.167,113.667 248,119.5C 248.667,151.5 248.667,183.5 248,215.5C 243.652,233.014 232.818,244.014 215.5,248.5C 154.167,248.5 92.8333,248.5 31.5,248.5C 15.2377,243.904 4.57106,233.571 -0.5,217.5C -0.5,155.167 -0.5,92.8333 -0.5,30.5C 4.16667,14.5 14.5,4.16667 30.5,-0.5 Z" />
      <path d="M 238.5,11.5 C 254.489,9.65625 261.655,16.6563 260,32.5C 259,34.5 258,36.5 257,38.5C 216.167,79.3333 175.333,120.167 134.5,161C 128,165.147 121.333,165.481 114.5,162C 101,148.5 87.5,135 74,121.5C 68.2162,107.062 72.8829,98.3956 88,95.5C 91.8172,95.6558 95.3172,96.8224 98.5,99C 107,107.5 115.5,116 124,124.5C 161.756,86.2425 199.923,48.5758 238.5,11.5 Z" />
    </svg>
  );
  /* eslint-enable */
}

export default RPicongray;
