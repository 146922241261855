import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubHeader from '../../../../../../../common/SubHeader';
import Immutable from 'immutable';
import Loading from '../../../../../../../common/Loading';
import EmptyPlaceholder from '../../../../../../../common/EmptyPlaceholder';
import InputScaffold from '../../../../../../../common/forms/InputScaffold';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';

class TelephoneEntryDeviceAuthTokens extends Component {
  state = {
    expirationDate: null,
    showDeleteTokenId: null,
  };

  onGenerateTokenClick = () => {
    const { createToken } = this.props;
    const { expirationDate } = this.state;
    createToken(expirationDate);
  };

  renderTokenRow = token => {
    const { onRevokeClick } = this.props;
    const { showDeleteTokenId } = this.state;

    return (
      <tr key={token.get('id')}>
        <td style={{ minWidth: '350px' }}>{token.get('id')}</td>
        <td>
          {token.get('expires_at') === null ? (
            <i>Never</i>
          ) : (
            moment(token.get('expires_at')).format('dddd, MMMM DD, YYYY')
          )}
        </td>
        <td className="table__align-right">
          {showDeleteTokenId === token.get('id') ? (
            <div>
              <p className="font-small text-error">
                Are you sure you want to <strong className="strong">revoke this token?</strong>
              </p>
              <button
                className="button button--destructive"
                style={{ marginRight: '12px' }}
                onClick={() => onRevokeClick(token.get('id'))}
              >
                Yes
              </button>
              <button
                type="button"
                className="button button--secondary--destructive"
                onClick={() => {
                  this.setState({ showDeleteTokenId: null });
                }}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="button button--destructive"
              onClick={() => {
                this.setState({ showDeleteTokenId: token.get('id') });
              }}
            >
              Revoke
            </button>
          )}
        </td>
      </tr>
    );
  };

  render() {
    const { telephoneEntryDeviceAuthTokens, telephoneEntryDeviceAuthTokensLoaded } = this.props;
    const { date } = this.state;

    if (!telephoneEntryDeviceAuthTokensLoaded) {
      return <Loading />;
    }

    return (
      <div>
        <div className="paper paper__end">
          <SubHeader
            underline
            title={`${telephoneEntryDeviceAuthTokens.size} ${
              telephoneEntryDeviceAuthTokens.size === 1 ? 'Auth Token' : 'Auth Tokens'
            }`}
          />
          <div className="input-scaffold__inline m-bottom">
            <div>
              <InputScaffold label="Expiry Date (Optional)">
                <DayPickerInput
                  name="Expiry Date"
                  value={date}
                  placeholder="From"
                  format="M/D/YYYY"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    disabledDays: { before: new Date() },
                  }}
                  onDayChange={date => this.setState({ expirationDate: date })}
                />
              </InputScaffold>
            </div>
            <button onClick={this.onGenerateTokenClick} className="button">
              Generate Token
            </button>
          </div>
          {telephoneEntryDeviceAuthTokens.size > 0 ? (
            <div>
              <table className="table-list">
                <thead>
                  <tr>
                    <th style={{ minWidth: '350px' }}>Token</th>
                    <th>Expires</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{telephoneEntryDeviceAuthTokens.map(this.renderTokenRow)}</tbody>
              </table>
            </div>
          ) : (
            <EmptyPlaceholder>No tokens are available</EmptyPlaceholder>
          )}
        </div>
      </div>
    );
  }
}

TelephoneEntryDeviceAuthTokens.propTypes = {
  authTokens: PropTypes.instanceOf(Immutable.List),
};

export default TelephoneEntryDeviceAuthTokens;
