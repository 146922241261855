import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Kebab from '../../common/Kebab';
import Icon from '../../common/icons/icon';

class OfferKebab extends Component {
  handleEdit = () => {
    this.props.toggleShowingMenu();
  };

  switchView = () => this.props.switchMode('view');

  render() {
    const { toggleShowingMenu, toggleRemove, showingMenu } = this.props;
    return (
      <div className="group-item__actions">
        <Kebab onClick={toggleShowingMenu} onDisengage={toggleShowingMenu} menuOpened={showingMenu}>
          <div className="kebab__menu">
            <div className="kebab__menu--item" onClick={toggleRemove}>
              <Icon icon="Remove" />
              Delete Offer
            </div>
          </div>
        </Kebab>
      </div>
    );
  }
}

OfferKebab.propTypes = {
  toggleShowingMenu: PropTypes.func.isRequired,
  toggleRemove: PropTypes.func.isRequired,
  showingMenu: PropTypes.bool.isRequired,
};

export default OfferKebab;
