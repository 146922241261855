import { fromJS, Map } from 'immutable';
import { combineReducers } from 'redux';
import { getApiLoadedReducer, getApiLoadingReducer } from '../../../../../../../redux/get-reducers';

const setThermostatError = (state = false, action) => {
  switch (action.type) {
    case 'GET_THERMOSTAT_LATEST_REQUEST':
    case 'GET_THERMOSTAT_LATEST_SUCCESS':
      return false;
    case 'GET_THERMOSTAT_LATEST_FAILURE':
      return action.json;
    default:
      return state;
  }
};

const activeUnit = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_UNIT_SUCCESS':
    case 'EDIT_UNIT_SUCCESS':
      return fromJS(action.json);
    case 'SUBMIT_PANEL_REQUEST_SUCCESS':
      return state.set('panel_request_sent', action.json.panel_request_sent);
    case 'CREATE_RESIDENT_SUCCESS':
      return state.set('residents', state.get('residents').push(fromJS(action.json)));
    case 'EDIT_RESIDENT_SUCCESS':
      const index = state.get('residents').findIndex(resident => resident.get('id') === action.json.id);
      return state.mergeIn(['residents', index], fromJS(action.json));
    case 'UPDATE_RESIDENT_CREDENTIALS_SUCCESS':
      const indexOfResidentWithUpdatedCredentials = state
        .get('residents')
        .findIndex(resident => resident.get('id') === action.residentId);
      if (indexOfResidentWithUpdatedCredentials > -1) {
        return state.setIn(
          ['residents', indexOfResidentWithUpdatedCredentials, 'property_credentials'],
          fromJS(action.json)
        );
      }
      return state;
    case 'REMOVE_RESIDENT_SUCCESS':
      if (!state.get('residents')) {
        return state;
      }
      return state.set(
        'residents',
        state.get('residents').filter(resident => resident.get('id') !== action.userId)
      );
    case 'RESET_RESIDENT_PROPERTY_CODE_SUCCESS':
      const indexToUpdate = state.get('residents').findIndex(resident => resident.get('id') === action.residentId);

      return state.update('residents', resident =>
        resident.update(indexToUpdate, resident =>
          resident.set('property_access_code', fromJS(action.json)).set('property_access_code_id', action.json.id)
        )
      );
    case 'WS_DEVICE_STATUS_UPDATED':
      if (!state.getIn(['panel', 'devices'])) {
        return state;
      }
      const zoneResults = state.getIn(['panel', 'devices']).map(device => {
        if (device.get('id') === action.meta.deviceId) {
          return device.merge(
            fromJS({
              last_status: action.meta.success ? action.payload : null,
              last_update_time: new Date(),
            })
          );
        }
        return device;
      });
      return state.setIn(['panel', 'devices'], zoneResults);
    case 'UPDATE_LOCK_SUCCESS':
    case 'UPDATE_BARRIER_SUCCESS':
    case 'UPDATE_LIGHT_SUCCESS':
    case 'GET_THERMOSTAT_LATEST_SUCCESS':
      if (!state.getIn(['panel', 'devices'])) {
        return state;
      }
      const results = state.getIn(['panel', 'devices']).map(device => {
        if (device.get('id') === action.json.name) {
          return device.set('last_status', fromJS(action.json));
        }
        return device;
      });
      return state.setIn(['panel', 'devices'], results);
    case 'VDB_LOADING_SUCCESS':
    case 'EDIT_VIDEO_DOORBELL_DEVICE_SETTINGS_SUCCESS':
      if (!state.getIn(['panel', 'devices'])) {
        return state;
      }
      const doorbellResults = state.getIn(['panel', 'devices']).map(device => {
        if (device.get('id') === action.meta.doorbellId) {
          return device.set('doorbellSettings', fromJS(action.json));
        }
        return device;
      });
      return state.setIn(['panel', 'devices'], doorbellResults);
    case 'VDB_LOADING_FAILURE':
    case 'EDIT_VIDEO_DOORBELL_DEVICE_SETTINGS_FAILURE':
      if (!state.getIn(['panel', 'devices'])) {
        return state;
      }
      const doorbellErrorResults = state.getIn(['panel', 'devices']).map(device => {
        if (device.get('id') === action.meta.doorbellId) {
          return device.set('doorbellSettings', null);
        }
        return device;
      });
      return state.setIn(['panel', 'devices'], doorbellErrorResults);
    case 'UPDATE_LOCK_FAILURE':
    case 'UPDATE_BARRIER_FAILURE':
    case 'UPDATE_LIGHT_FAILURE':
    case 'GET_THERMOSTAT_LATEST_FAILURE':
      if (!state.getIn(['panel', 'devices'])) {
        return state;
      }
      const errorResults = state.getIn(['panel', 'devices']).map(device => {
        if (device.get('id') === action.meta.deviceId) {
          return device.set('last_status', null);
        }
        return device;
      });
      return state.setIn(['panel', 'devices'], errorResults);
    case 'GET_UNIT_SCHEDULES_SUCCESS':
      return state.set('schedules', fromJS(action.json));
    case 'EDIT_DEVICE_SCHEDULE_SUCCESS':
    case 'CREATE_DEVICE_SCHEDULE_SUCCESS':
      const deviceIdEdited = action.json[0].device_id;
      return state.setIn(['schedules', deviceIdEdited], fromJS(action.json));
    case 'DELETE_DEVICE_SCHEDULE_REQUEST':
      state = state.set('rollback', state);
      return state.set(
        'schedules',
        state
          .get('schedules')
          .map(schedules => schedules.filter(schedule => schedule.get('id') !== action.meta.scheduleId))
      );
    case 'DELETE_DEVICE_SCHEDULE_SUCCESS':
      return state.delete('rollback');
    case 'DELETE_DEVICE_SCHEDULE_FAILURE':
      return state.get('rollback');
    case 'GET_UNIT_RESET':
      return Map();
    case 'SET_UNIT_ACCESS_CODE_ON_RESIDENT':
      if (!state.get('residents')) return state;
      const indexOfResidentToSetUnitCode = state
        .get('residents')
        .findIndex(resident => resident.get('id') === action.residentId);
      if (indexOfResidentToSetUnitCode === -1) return state;
      return state
        .setIn(
          ['residents', indexOfResidentToSetUnitCode, 'unit_access_code_id'],
          action.unitAccessCode ? action.unitAccessCode.id : null
        )
        .setIn(['residents', indexOfResidentToSetUnitCode, 'unit_access_code'], fromJS(action.unitAccessCode));
    case 'SET_COMMUNITY_ACCESS_CODE_ON_RESIDENT':
      if (!state.get('residents')) return state;
      const indexOfResidentToSetCommunityCode = state
        .get('residents')
        .findIndex(resident => resident.get('id') === action.residentId);
      if (indexOfResidentToSetCommunityCode === -1) return state;
      return state
        .setIn(
          ['residents', indexOfResidentToSetCommunityCode, 'property_access_code_id'],
          action.communityAccessCode ? action.communityAccessCode.id : null
        )
        .setIn(
          ['residents', indexOfResidentToSetCommunityCode, 'property_access_code'],
          fromJS(action.communityAccessCode)
        );
    default:
      return state;
  }
};

const activeUnitLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_UNIT_SUCCESS':
      return true;
    case 'GET_UNIT_REQUEST':
    case 'GET_UNIT_RESET':
      return false;
    default:
      return state;
  }
};

const activeUnitError = (state = false, action) => {
  switch (action.type) {
    case 'GET_UNIT_FAILURE':
      return true;
    case 'GET_UNIT_REQUEST':
    case 'GET_UNIT_RESET':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  activeUnit,
  activeUnitLoaded,
  activeUnitError,
  removeResidentLoading: getApiLoadingReducer('REMOVE_RESIDENT'),
  setThermostatError,
  unitSchedulesLoaded: getApiLoadedReducer('GET_UNIT_SCHEDULES'),
});
