import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import DailyReport from './views/DailyReport';
import ReportList from './views/ReportList';
import ActivityLog from './views/ActivityLog';

class Reports extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Switch>
          <Route exact path={`${match.path}/`} component={ReportList} />
          <Route exact path={`${match.path}/daily`} component={DailyReport} />
          <Route exact path={`${match.path}/activity-log`} component={ActivityLog} />
        </Switch>
      </div>
    );
  }
}

Reports.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Reports;
