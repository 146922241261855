import React from 'react';
import PropTypes from 'prop-types';
import { usePhoneFormating } from '../../../hooks';

export const SGDetailInfo = ({
  phone,
  email,
  street_address,
  apt_address,
  city,
  province_state,
  postal_code,
  apartment_messages,
  guest_status_message,
  tabName,
}) => {
  const getFormattedPhone = usePhoneFormating();
  const isRejected = tabName === 'rejected';

  return (
    <div>
      <div className="detail-label">PHONE</div>
      <div className="detail-value">{getFormattedPhone(phone)}</div>
      <div className="detail-label">EMAIL</div>
      <div className="detail-value">{email}</div>
      <div className="detail-label">CURRENT ADDRESS</div>
      <div className="detail-value">
        <div>{street_address}</div>
        <div>{apt_address}</div>
        <div>
          {city}, {province_state} {postal_code}
        </div>
      </div>
      <div className="detail-label">NOTES</div>
      <div className="detail-notes">{apartment_messages || 'No notes added by potential resident.'}</div>
      {isRejected && guest_status_message && (
        <>
          <div className="detail-label">REVOCATION REASON</div>
          <div className="detail-notes">{guest_status_message}</div>
        </>
      )}
    </div>
  );
};

SGDetailInfo.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
  street_address: PropTypes.string,
  apt_address: PropTypes.string,
  city: PropTypes.string,
  province_state: PropTypes.string,
  postal_code: PropTypes.string,
  apartment_messages: PropTypes.string,
  tabName: PropTypes.string,
};
