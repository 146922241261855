import { combineReducers } from 'redux';
import { fromJS, List } from 'immutable';

const GuestList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_GUESTLIST_SUCCESS':
      const list = fromJS(action.json);
      return list;
    default:
      return state;
  }
};

export default combineReducers({
  GuestList,
});
