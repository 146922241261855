import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { editPropertyGuest, removePropertyGuest, getProfiles } from '../../../../../../redux/actions';
import EditPropertyGuest from './EditPropertyGuest';
import {
  selectAccessControlProfileOptions,
  selectActivePropertyGuest,
  selectCredentialTypesForProperty,
} from '../../../../../../redux/selectors';

function mapStateToProps(state, props) {
  return {
    accessControlProfilesLoaded: state.property.accessControlProfilesLoaded,
    accessControlProfileOptions: selectAccessControlProfileOptions(state),
    property: state.property.activeProperty,
    propertyGuest: selectActivePropertyGuest(state, props.match.params.guestId),
    propertyGuestsLoaded: state.property.propertyGuestsLoaded,
    credentialTypes: selectCredentialTypesForProperty(state, props),
  };
}

function mapDispatchToProps(dispatch, props) {
  const { guestId, propertyId } = props.match.params;
  return {
    editPropertyGuest: values => dispatch(editPropertyGuest(propertyId, guestId, values)),
    getProfiles: () => dispatch(getProfiles(props.match.params.propertyId)),
    removePropertyGuest: () => dispatch(removePropertyGuest(propertyId, guestId)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(EditPropertyGuest);
