import React from 'react';

function Decline() {
  /* eslint-disable max-len */
  return (
    <svg id="Capa_1" height="512" viewBox="0 0 497 497" width="512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="248.5" cy="248.5" fill="#ffb09e" r="248.5" />
        <path
          d="m248.5 0c-5.04 0-10.04.168-15.006.464 130.253 7.761 227.115 115.833 227.115 248.036s-96.862 240.275-227.116 248.036c4.967.296 9.967.464 15.007.464 137.243 0 248.5-111.257 248.5-248.5s-111.257-248.5-248.5-248.5z"
          fill="#ffa78f"
        />
        <g>
          <circle cx="248.5" cy="248.5" fill="#f07281" r="212.109" />
          <path
            d="m248.5 36.391c-5.046 0-10.048.189-15.006.535 110.141 7.7 197.103 99.476 197.103 211.574 0 112.099-86.962 203.874-197.103 211.574 4.958.347 9.96.535 15.006.535 117.145 0 212.109-94.965 212.109-212.109s-94.964-212.109-212.109-212.109z"
            fill="#eb5569"
          />
          <path
            d="m349.727 301.095-39.211-39.211c-3.978-3.978-3.978-10.428 0-14.407l39.211-39.211c7.771-7.771 7.771-20.371 0-28.142l-26.671-26.671c-7.771-7.771-20.371-7.771-28.142 0l-39.211 39.211c-3.978 3.978-10.428 3.978-14.407 0l-39.211-39.211c-7.771-7.771-20.371-7.771-28.142 0l-26.671 26.671c-7.771 7.771-7.771 20.371 0 28.142l39.211 39.211c3.978 3.978 3.978 10.428 0 14.407l-39.211 39.211c-7.771 7.771-7.771 20.371 0 28.142l26.671 26.671c7.771 7.771 20.371 7.771 28.142 0l39.211-39.211c3.978-3.978 10.428-3.978 14.407 0l39.211 39.211c7.771 7.771 20.371 7.771 28.142 0l26.671-26.671c7.771-7.771 7.771-20.37 0-28.142z"
            fill="#ffeee6"
          />
          <g fill="#ffdfcf">
            <path d="m183.008 164.788 40.974 42.9c3.978 3.978 10.428 3.978 14.407 0l10.112-12.039c-2.607 0-5.214-.995-7.203-2.984l-39.211-39.211c-7.771-7.771-20.371-7.771-28.142 0l-5.53 5.53c5.265-.137 10.574 1.785 14.593 5.804z" />
            <path d="m222.812 303.238-41.754 43.68c-2.997 2.997-6.714 4.825-10.593 5.511l3.479 3.479c7.771 7.771 20.371 7.771 28.142 0l39.211-39.211c1.989-1.989 4.596-2.984 7.203-2.984l-11.282-10.475c-3.978-3.978-10.428-3.978-14.406 0z" />
            <path d="m310.516 261.885c-3.978-3.978-3.978-10.428 0-14.406l39.211-39.211c7.771-7.771 7.771-20.371 0-28.142l-26.671-26.671c-7.771-7.771-20.371-7.771-28.142 0l-7.855 7.855c6.314-1.273 13.124.532 18.02 5.428l14.636 13.387c7.771 7.771 7.771 20.371 0 28.142l-39.211 39.211c-3.978 3.978-3.978 10.428 0 14.406l39.211 39.211c7.771 7.771 7.771 20.371 0 28.142l-13.466 13.001c-5.449 5.449-13.267 7.062-20.135 4.869l8.8 8.801c7.771 7.771 20.371 7.771 28.142 0l26.671-26.671c7.771-7.771 7.771-20.371 0-28.142z" />
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Decline;
