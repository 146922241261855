import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { editAdminUser, getAdmins, removeAdmin } from '../../../../redux/actions';
import { getUserPermission, selectCurrentAdmin } from '../../../../redux/selectors';

import EditAdminUser from './EditAdminUser';
import { selectRoleOptions2 } from '../../../RolesAndPermissions/redux/selectors';
import { getRolesByRoleLevel } from '../../../RolesAndPermissions/redux/actions';

function mapStateToProps(state, props) {
  const adminId = props.match.params.adminId;
  return {
    adminId,
    admin: selectCurrentAdmin(state, props),
    adminList: state.adminList,
    roleOptions: selectRoleOptions2(state),
    user: state.user,
    userPermissionList: getUserPermission(state),
    loading: state.adminListLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editAdminUser: (id, payload) => dispatch(editAdminUser(id, payload)),
    getAdmins: () => dispatch(getAdmins()),
    removeAdmin: id => dispatch(removeAdmin(id)),
    getRolesByRoleLevel: name => dispatch(getRolesByRoleLevel(name)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    editAdminUser: payload => dispatchProps.editAdminUser(stateProps.adminId, payload),
    removeAdmin: () => dispatchProps.removeAdmin(stateProps.adminId),
  });
}

const EditAdminUserContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditAdminUser);

export default withRouter(EditAdminUserContainer);
