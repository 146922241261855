import React from 'react';
import {
  ResidentRevokeModal,
  useResidentRevokeModal,
  SGBtnBack,
  SGButton,
  useFetchGuest,
  SGDetailHeader,
  SGDetailInfo,
  SGDetailImages,
  SGLinkToTour,
  useResidentAccessDetail,
} from '../../../common';
import { useSGToPast } from '../hooks';

import Loading from '../../../../../../../../../common/Loading';
import Icon from '../../../../../../../../../common/icons/icon';

export const SGScheduledDetails = () => {
  const { isLoad, guest } = useFetchGuest();

  const { isOpen, message, handleCancel, handleChangeMessage, handleOpen } = useResidentRevokeModal();
  const { handleRevokeAccess } = useResidentAccessDetail({
    guestId: guest?.id,
    reason: message,
    historyRevokeAccessReplaceTo: `/automated/detail/rejected/${guest?.id}`,
  });
  const { handleToPast } = useSGToPast();
  const isTestFunctionShow = process.env.REACT_APP_ENV !== 'production';

  if (isLoad) return <Loading />;

  const { first_name, last_name, imageUrl } = guest;

  return (
    <>
      <div className="m-t20 p-l20">
        <SGBtnBack btnTitle="Return to previous page" />
      </div>
      <div className="detail-page-content">
        <SGDetailHeader title="Scheduled Tour" name={`${first_name} ${last_name}`}>
          <div className="btns-row">
            {isTestFunctionShow && (
              <SGButton className="button detail-button-to-past" title="TO PAST" handleClick={handleToPast} />
            )}
            <SGButton
              className="detail-button-revoke"
              title="Revoke Access"
              iconComponent={<Icon icon="DenyIcon" />}
              handleClick={handleOpen}
            />
          </div>
        </SGDetailHeader>
        <SGDetailInfo {...guest} />
        <SGDetailImages imageUrl={imageUrl} />
        <SGLinkToTour />
        <ResidentRevokeModal
          isOpen={isOpen}
          message={message}
          onRevoke={handleRevokeAccess}
          onChangeMessage={handleChangeMessage}
          onCancel={handleCancel}
          name={`${first_name} ${last_name}`}
        />
      </div>
    </>
  );
};
