import React from 'react';

function Image() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M21.4 5.1c.2.2.5.6.6 1 .2.4.3.8.3 1.2v15.4c0 .4-.1.7-.4.9s-.5.4-.9.4H3c-.4 0-.7-.1-.9-.4s-.4-.6-.4-.9V1.3c0-.4.1-.7.4-.9S2.6 0 3 0h12c.4 0 .8.1 1.2.3.4.2.8.4 1 .6l4.2 4.2zm-6-3.3v5h5c-.1-.3-.2-.4-.3-.5L16 2.1c-.1-.1-.3-.2-.6-.3zm5.2 20.5V8.6H15c-.4 0-.7-.1-.9-.4-.2-.2-.4-.6-.4-.9V1.7H3.4v20.6h17.2zm-1.7-6v4.3H5.1V18l2.6-2.6 1.7 1.7 5.1-5.1 4.4 4.3zM7.7 13.7c-.7 0-1.3-.2-1.8-.8s-.8-1.1-.8-1.8.2-1.3.8-1.8c.5-.5 1.1-.7 1.8-.7s1.3.3 1.8.8.8 1.1.8 1.8-.2 1.3-.8 1.8-1.1.7-1.8.7z" />
    </svg>
  );
  /* eslint-enable */
}

export default Image;
