import React, { Component } from 'react';
import { List } from 'immutable';
import { trimAndSetNullFalsyValues } from '../../../../../../../../../../../common/utils/helpers';
import ResidentForm from '../../../../../../../../../common/ResidentForm';
import Loading from '../../../../../../../../../../../common/Loading';

class ResidentInformation extends Component {
  state = {
    residentFormSubmitMessage: '',
    residentFormSubmitStatus: null,
    invalidCodeDetails: null,
  };

  componentDidMount() {
    const { getProfiles, hasPanel, userPermissionList } = this.props;
    const userPermission = {
      removeResident: userPermissionList.includes('REMOVE_RESIDENT'),
      editResidentPersonalDetails: userPermissionList.includes('EDIT_RESIDENT_PERSONAL_DETAILS'),
      ChangResidentAccessControlProfile: userPermissionList.includes("CHANGE_A_RESIDENT'S_ACCESS_CONTROL_PROFILE"),
    };
    this.setState({ userPermission: userPermission });
    if (hasPanel) getProfiles();
  }

  getInitialValues = resident => ({
    first_name: resident.user.first_name || '',
    last_name: resident.user.last_name || '',
    phone: resident.user.phone || '',
    phone_country_code: '1',
    email: resident.user.email || '',
    directory_phone_country_code: '1',
    directory_display_name: resident?.directory_display_name || '',
    directory_sort_name: resident?.directory_sort_name || '',
    directory_phone: resident?.directory_phone || '',
    enable_directory_listing: resident.enable_directory_listing,
    enable_guest_community_access: resident.enable_guest_community_access,
    profiles: [
      resident.access_control_profile_id1,
      resident.access_control_profile_id2,
      resident.access_control_profile_id3,
      resident.access_control_profile_id4,
    ].filter(p => p !== null),
  });

  handleRemoveResident = () => {
    const { removeResident, resident, history, match } = this.props;
    const { propertyId, unitId } = match.params;

    removeResident(resident.get('id')).then(action => {
      if (action.response.ok) {
        history.push(`/properties/${propertyId}/units/${unitId}`);
      }
    });
  };

  onEditResident = (values, actions) => {
    const { editResident, resident, t } = this.props;
    const payload = { ...values };
    delete payload.email;

    return editResident(resident.get('id'), trimAndSetNullFalsyValues(payload)).then(action => {
      if (action.response.ok) {
        actions.resetForm({ values: this.getInitialValues(action.json) });
        this.setState({
          residentFormSubmitMessage: t('edit.success'),
          residentFormSubmitStatus: 'Success',
          invalidCodeDetails: action.json.invalidCodeDetails,
        });
      } else {
        actions.setSubmitting(false);
        this.setState({
          residentFormSubmitMessage: t('edit.error'),
          residentFormSubmitStatus: 'Failure',
          invalidCodeDetails: action.json.invalidCodeDetails,
        });
      }
    });
  };

  onCancel = () => {
    const { history, match } = this.props;
    const { propertyId, unitId } = match.params;

    history.push(`/properties/${propertyId}/units/${unitId}`);
  };

  render() {
    const {
      resident,
      property,
      accessControlProfilesLoaded,
      hasPanel,
      match,
      unit,
      accessControlProfileOptions,
      removeResidentLoading,
      userPermissionList,
    } = this.props;
    const { propertyId } = match.params;

    const userPermission = {
      removeResident: userPermissionList.includes('REMOVE_RESIDENT'),
      editResidentPersonalDetails: userPermissionList.includes('EDIT_RESIDENT_PERSONAL_DETAILS'),
      ChangResidentAccessControlProfile: userPermissionList.includes("CHANGE_A_RESIDENT'S_ACCESS_CONTROL_PROFILE"),
    };
    if (!resident || (hasPanel && !accessControlProfilesLoaded)) {
      return <Loading />;
    }

    return (
      <ResidentForm
        editing={true}
        resident={resident}
        submitFunc={this.onEditResident}
        onCancel={this.onCancel}
        submitMessage={this.state.residentFormSubmitMessage}
        submitStatus={this.state.residentFormSubmitStatus}
        unitId={unit.get('id')}
        propertyId={propertyId}
        handleRemoveResident={this.handleRemoveResident}
        initialValues={this.getInitialValues(resident?.toJS())}
        profileOptions={hasPanel ? accessControlProfileOptions : new List()}
        propertyHasMasterPanel={property.get('clubhouse_panel_id') !== null}
        propertyHasAccessControl={property.get('admin_enabled_access_control')}
        propertyHasResidentGuestCommunityAccess={property.get('enabled_resident_community_guests')}
        isRemoving={removeResidentLoading}
        userPermission={userPermission}
      />
    );
  }
}

export default ResidentInformation;
