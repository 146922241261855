import React from 'react';
import classNames from 'classnames';
import { useConfigFormBlock } from '../hooks';

export const SGFormBlock = ({ children, title, subTitle, required, type, number }) => {
  const { isError } = useConfigFormBlock({ type, number });

  return (
    <div className="container tours_block sg-container">
      <div className={classNames('paper radius-top-left radius-top-right', { error: isError })}>
        <section className="paper__section resident-app-features">
          <div className="tours_title">
            <h1 className="h1 strong">
              {title}
              {required && <span className="input-required">*</span>}
            </h1>
          </div>
          {subTitle && <p className="font-small margin-clear-all sg-message-title">{subTitle}</p>}
          <div>{children}</div>
        </section>
      </div>
    </div>
  );
};
