import { connect } from 'react-redux';
import Roles from './Roles';
import {
  getGroupPermission,
  getRolePermission,
  getRoles,
  getRolesByName,
  getPermissionByConditions,
  getRoleByPermission,
  addRoles,
  getRoleById,
  editRoles,
  removeRoles,
  addRolesPermission,
  removeRolesPermission,
  removeRolePermissionByRolePermissionId,
  getRolesLevel,
  getUserRolePermission,
  getPropertys,
} from '../../../redux/actions';
import { selectRolesOptions, selectPropertyOptions } from '../../../redux/selectors';
import { getUserPermission } from '../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    rolesList: state.RolesAndPermissions.rolesList,
    inRolesList: state.RolesAndPermissions.inRolesList,
    permissionList: state.RolesAndPermissions.permissionList,
    inPermissionList: state.RolesAndPermissions.inPermissionList,
    rolePermissionList: state.RolesAndPermissions.rolePermissionList,
    rolesInfo: state.RolesAndPermissions.rolesInfo,
    userRolePermission: state.RolesAndPermissions.userRolePermission,
    rolesListLoading: state.RolesAndPermissions.rolesListLoading,
    user: state.user,
    rolesOptions: selectRolesOptions(state),
    propertyOptions: selectPropertyOptions(state),
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoles: () => dispatch(getRoles()),
    getGroupPermission: () => dispatch(getGroupPermission()),
    getRolePermission: role => dispatch(getRolePermission(role)),
    getRolesByName: name => dispatch(getRolesByName(name)),
    getRoleByPermission: permission => dispatch(getRoleByPermission(permission)),
    getPermissionByConditions: (role, permission) => dispatch(getPermissionByConditions(role, permission)),

    getRoleById: role => dispatch(getRoleById(role)),
    addRoles: payload => dispatch(addRoles(payload)),
    editRoles: (role, payload) => dispatch(editRoles(role, payload)),
    removeRole: role => dispatch(removeRoles(role)),
    addRolesPermission: payload => dispatch(addRolesPermission(payload)),
    removeRolesPermission: id => dispatch(removeRolesPermission(id)),
    removeRolePermissionByRolePermissionId: (role, permissionId) =>
      dispatch(removeRolePermissionByRolePermissionId(role, permissionId)),

    getRolesLevel: () => dispatch(getRolesLevel()),
    getPropertys: () => dispatch(getPropertys()),
    getUserRolePermission: roleId => dispatch(getUserRolePermission(roleId)),
  };
}

const RolesAndPermissionsMainContainer = connect(mapStateToProps, mapDispatchToProps)(Roles);

export default RolesAndPermissionsMainContainer;
