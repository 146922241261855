import * as React from 'react';
import classNames from 'classnames';
import Icon from '../icons/icon';

export const TabsContainer = ({ children, noBorder, kebab }) => {
  return (
    <div className={`tabs-container__outer ${!noBorder && 'border'}`}>
      <div className="tabs-container">{children}</div>
      {kebab}
    </div>
  );
};

export const Tab = ({ isActive, label, onPress, showErrorBadge }) => {
  const tabClasses = {
    tab: true,
    'tab--active': isActive,
  };

  return (
    <div
      className={classNames(tabClasses)}
      onClick={() => {
        if (!isActive) onPress();
      }}
    >
      <div className="tab-label">{label}</div>
      {showErrorBadge ? (
        <div className="tab--error-badge">
          <Icon icon="ErrorExclamation" />
        </div>
      ) : null}
    </div>
  );
};
