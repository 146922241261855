import React, { useState } from 'react';
import { Formik } from 'formik';
import InputScaffold from './InputScaffold';
import Yup from 'yup';
import ModalAlert from '../ModalAlert';
import Icon from '../icons/icon';
import NotificationStatusIndicator from './NotificationStatusIndicator';
import AlertMessage from '../AlertMessage';
import moment from 'moment';
import { cloneDeep } from 'lodash';

export const AdminFinancialsForm = ({ title, adminFinancials, submitAdminFinancials, updateMessage }) => {
  const [isShowingConfirmation, setShowingConfirmation] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const feeValidationMessage = 'Fee must be a number between 0 and 100';
  const validationSchema = Yup.object().shape({
    fee: Yup.number()
      .min(0, feeValidationMessage)
      .max(100, feeValidationMessage)
      .positive(feeValidationMessage)
      .typeError(feeValidationMessage)
      .required(feeValidationMessage),
  });

  const eventCurrentFee = adminFinancials?.getIn(['current', 'fee']);
  const eventPendingFee = adminFinancials?.getIn(['pending', 'fee']);

  const effectiveAtDate = moment(adminFinancials?.getIn(['pending', 'effective_at'])).format('MM/DD/YYYY [at] hh:mm A');

  const renderPending = financialsData => {
    if (financialsData?.get('pending')) {
      return (
        <AlertMessage
          icon
          msg={`Pending ${financialsData?.get('type')?.toLowerCase()} fee: ${financialsData?.getIn([
            'pending',
            'fee',
          ])}%. Takes effect on ${effectiveAtDate}`}
        />
      );
    }
  };

  return (
    <div className="container">
      <Formik
        initialValues={{
          fee: eventPendingFee ?? eventCurrentFee ?? 0,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={values => {
          setSaved(false);
          if (isShowingConfirmation) {
            const submitValues = cloneDeep(values);

            submitAdminFinancials(submitValues.fee).then(action => {
              if (action.response.ok) {
                setShowingConfirmation(false);
                setSaved(true);
              }
            });
          } else {
            setShowingConfirmation(true);
          }
        }}
        render={({ values, errors, dirty, setFieldValue, handleSubmit, submitForm }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius top-right">
                <div>{renderPending(adminFinancials)}</div>

                <div className="paper__grid">
                  <div className="paper__section--grid-item">
                    <section className="paper__section">
                      <h4 className="section-header h4">{title}</h4>
                      {eventCurrentFee ? (
                        <h5 className="current-event-fee">
                          Current events fee: <span className="strong">{eventCurrentFee}</span>
                        </h5>
                      ) : null}
                      <InputScaffold
                        label={`${adminFinancials?.get('type')} SmartExperience Fee`}
                        validation={errors.fee}
                        helperText="Will change to this after 30 days"
                      >
                        <div className="input-wrapper__suffix">
                          <input
                            type="text"
                            name="fee"
                            onChange={({ target }) => {
                              if (/^[0-9]*.?[0-9]?[0-9]?$/.test(target.value)) {
                                setFieldValue('fee', target.value.replace(/[^0-9.]+/g, ''));
                              }
                            }}
                            value={values.fee}
                          />
                          <div className="input--prefix-suffix-icon__wrapper">
                            <Icon icon="Percent" />
                          </div>
                        </div>
                      </InputScaffold>
                    </section>
                  </div>
                </div>
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <button className="button" type="submit" disabled={!dirty}>
                  Save
                </button>
                <NotificationStatusIndicator
                  hideNotification={!isSaved}
                  message="SmartExperience Fees Updated Successfully!"
                  type="Success"
                />
              </div>
              <ModalAlert
                title="Update SmartExperience Fees"
                destructive
                body={updateMessage}
                confirmButtonTitle="Confirm"
                cancelButtonTitle="Cancel"
                visible={isShowingConfirmation}
                handleConfirm={() => {
                  submitForm();
                }}
                handleCancel={() => {
                  setShowingConfirmation(false);
                }}
              />
            </form>
          );
        }}
      />
    </div>
  );
};
