import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import store from 'store2';
import { connect } from 'react-redux';
import { getFeatureFlags, getUser } from './redux/actions';
import { selectorIsAdmin, selectorIsVendor } from './redux/selectors';
import config from './common/config';
import Login from './common/auth/Login';
import Logout from './common/auth/Logout';
import ForceReloadPrompt from './views/ForceReloadPrompt';
import AddProperty from './views/AddProperty';
import AdminLayout from './layouts/AdminLayout';
import NotFound from './views/NotFound';
import Property from './views/Property';
import PropertiesList from './views/PropertiesList';
import OffersList from './views/OffersList';
import AddOffer from './views/AddOffer';
import EditOffer from './views/EditOffer';
import EditProperty from './views/EditProperty';
import CreateDemo from './views/CreateDemo';
import NoAccess from './common/auth/NoAccess';
import Report from './views/Report';
import AdminManagement from './views/AdminManagement';
import Devices from './views/Property/views/PropertyDetails/views/Devices';
import AccessControl from './views/AccessControl';
import PrivateRoute from './common/utils/PrivateRoute';
import * as Sentry from '@sentry/browser';
import Financials from './views/Financials';
import VendorEventsList from './views/VendorEventsList';
import VendorStripeSettings from './views/VendorStripeSettings/VendorStripeSettings';
import VendorEventDetails from './views/VendorEventDetails';
import RolesAndPermissions from './views/RolesAndPermissions';

import 'tippy.js/dist/tippy.css';

class App extends Component {
  componentDidMount() {
    const token = store.get(config.auth.idTokenKey);
    this.unlisten = this.props.history.listen(() => {
      Sentry.addBreadcrumb();
    });
    if (token) {
      this.props.dispatch(getUser(token)).then(res => {
        if (res.response?.ok) {
          // Sentry.configureScope(scope => {
          //   scope.setUser({ id: res.json.id, email: res.json.oidcUser.email });
          // });
          // window.ga('set', 'dimension2', res.json.roles.map(x => x.role).join());
          // return window.ga('send', 'pageview', this.props.location.pathname);
        }
      });
      this.props.dispatch(getFeatureFlags());
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // window.ga('set', 'dimension2', this.props.roles.join());
      // window.ga('send', 'pageview', this.props.location.pathname);
    }
  }
  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    const { user, roles, shouldUpdate, isAdmin, isVendor } = this.props;

    if (!store.get(config.auth.idTokenKey)) {
      return <Login />;
    }
    if (shouldUpdate) {
      return <ForceReloadPrompt />;
    }
    if (!user) {
      return <h1>no User</h1>;
    }
    if (!roles.size) {
      return <NoAccess />;
    }
    const userPermissionList = user && user.get('permissions').toJS();
    const userPermission = {
      properties: userPermissionList.includes('VIEW_PROPERTY_PAGE'),
      accessControl: userPermissionList.includes('VIEW_ACCESS_CONTROL_PAGE'),
      offers: userPermissionList.includes('VIEW_OFFERS_PAGE'),
      financials: userPermissionList.includes('VIEW_FINANCIALS_PAGE'),
      reports: userPermissionList.includes('VIEW_REPORTS_PAGE'),
      rolesAndPermissions: userPermissionList.includes('VIEW_ROLES_AND_PERMISSIONS_PAGE'),
      // userPermissionList.includes('VIEW_ROLE') ||
      // userPermissionList.includes('VIEW_PERMISSION') ||
      // userPermissionList.includes('VIEW_USER'),
      adminManagement: userPermissionList.includes('VIEW_ADMIN_MANAGEMENT_PAGE'),
      editResidentPersonalDetails: userPermissionList.includes('EDIT_RESIDENT_PERSONAL_DETAILS'),
    };
    return (
      <Switch>
        <Route exact path="/logout" component={Logout} />
        <Route
          exact
          path="/"
          render={() => {
            if (isVendor) {
              return <Redirect to="/events" />;
            }

            return user.get('properties') && user.get('properties').size === 1 && !isAdmin ? (
              <Redirect to={`/properties/${user.getIn(['properties', 0, 'id'])}`} />
            ) : userPermission.properties ? (
              <Redirect to="/properties" />
            ) : userPermission.accessControl ? (
              <Redirect to="/access-control" />
            ) : userPermission.offers ? (
              <Redirect to="/offers" />
            ) : userPermission.financials ? (
              <Redirect to="/financials" />
            ) : userPermission.reports ? (
              <Redirect to="/reports" />
            ) : userPermission.rolesAndPermissions ? (
              <Redirect
                to={
                  userPermissionList.includes('VIEW_ROLE')
                    ? '/roles-and-permissions'
                    : userPermissionList.includes('VIEW_PERMISSION')
                    ? '/roles-and-permissions/permissions'
                    : userPermissionList.includes('VIEW_USER')
                    ? '/roles-and-permissions/user'
                    : ''
                }
              />
            ) : userPermission.adminManagement ? (
              <Redirect to="/admin-management" />
            ) : (
              <NoAccess />
            );
          }}
        />
        <Route exact path="/properties/:propertyId/edit" component={EditProperty} />
        <Route exact path="/properties/:propertyId/create-demo" component={CreateDemo} />
        <Route path="/properties/:propertyId" component={Property} />
        <Route>
          <AdminLayout>
            <Switch>
              <Route exact path="/properties" component={PropertiesList} />
              <Route exact path="/add-property" component={AddProperty} />
              <PrivateRoute roles={['ADMIN']} path="/devices" component={Devices} />
              <PrivateRoute roles={['ADMIN']} path="/access-control" component={AccessControl} />
              <PrivateRoute roles={['ADMIN']} exact path="/offers" component={OffersList} />
              <PrivateRoute roles={['ADMIN']} exact path="/offers/add" component={AddOffer} />
              <PrivateRoute roles={['ADMIN']} exact path={`/offers/:offerId`} component={EditOffer} />
              <PrivateRoute roles={['ADMIN']} path="/reports" component={Report} />
              <PrivateRoute roles={['ADMIN']} path="/admin-management" component={AdminManagement} />
              <PrivateRoute roles={['ADMIN']} path="/roles-and-permissions" component={RolesAndPermissions} />
              <PrivateRoute roles={['ADMIN']} path="/financials" component={Financials} />
              <PrivateRoute roles={['VENDOR']} exact path="/events" component={VendorEventsList} />
              <PrivateRoute roles={['VENDOR']} exact path="/events/:eventId" component={VendorEventDetails} />
              <PrivateRoute roles={['VENDOR']} path="/stripe-settings" component={VendorStripeSettings} />
              <Route component={NotFound} />
            </Switch>
          </AdminLayout>
        </Route>
      </Switch>
    );
  }
}

export default connect(state => ({
  user: state.user,
  roles: state.roles,
  shouldUpdate: state.shouldUpdate,
  isAdmin: selectorIsAdmin(state),
  isVendor: selectorIsVendor(state),
}))(App);
