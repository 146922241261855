import React from 'react';

export const SGWebFormPopup = () => {
  return (
    <div className="web-form-container">
      <div className="paper radius-top-left radius-top-right web-form-container-shadow">
        <h1 className="h4 strong text-center">Thank you for signing up for a tour!</h1>
        <p className="text-center h5 strong">
          You should receive an email confirmation with your
          <br /> tour time.
        </p>
      </div>
    </div>
  );
};
