import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../../../../../common/Loading';
import MailHubKebab from './MailHubKebab';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import SubHeader from '../../../../../../common/SubHeader';

class MailHubs extends Component {
  state = {
    showingKebabMenu: false,
  };

  componentDidMount() {
    this.props.getMailHubs();
  }

  navigateToEditMailHub(mailHubId) {
    this.props.history.push(`/properties/${this.props.propertyId}/property-details/mail-hubs/edit/${mailHubId}`);
  }

  getCreateMailHubPath() {
    return `/properties/${this.props.propertyId}/property-details/mail-hubs/create`;
  }

  renderZeroContent() {
    return <EmptyPlaceholder buttonText="Add Mail Hub" linkTo={this.getCreateMailHubPath()} />;
  }

  removeMailHub(mailHubId) {
    return () => this.props.removeMailHub(mailHubId);
  }

  renderMailHubItem = mailHub => {
    return (
      <tr key={mailHub.get('id')}>
        <td>{mailHub.get('name')}</td>
        <td className="group-item__actions">
          <MailHubKebab
            removeMailHub={this.removeMailHub(mailHub.get('id'))}
            onEdit={this.navigateToEditMailHub.bind(this, mailHub.get('id'))}
            showingMenu={this.state.showingKebabMenu === mailHub.get('id')}
            toggleShowingMenu={() =>
              this.setState(prevState => {
                if (this.state.showingKebabMenu === mailHub.get('id')) {
                  return { showingKebabMenu: false };
                } else {
                  return { showingKebabMenu: mailHub.get('id') };
                }
              })
            }
          />
        </td>
      </tr>
    );
  };

  renderMailHubList() {
    const mailHubs = this.props.mailHubs;
    return (
      <table className="table-groups-list">
        <thead>
          <tr>
            <th>Name</th>
            <th />
          </tr>
        </thead>
        <tbody>{mailHubs.map(this.renderMailHubItem)}</tbody>
      </table>
    );
  }

  render() {
    const { mailHubs, mailHubsLoaded } = this.props;

    if (!mailHubsLoaded) {
      return <Loading />;
    }

    return (
      <div className="mail-alerts__page container">
        <SubHeader
          title="Mail Hubs"
          actions={[
            mailHubs.size > 0 && (
              <Link className="button" to={this.getCreateMailHubPath()}>
                Add Mail Hub
              </Link>
            ),
          ]}
        />
        {mailHubs.size === 0 ? this.renderZeroContent() : this.renderMailHubList()}
      </div>
    );
  }
}

export default MailHubs;
