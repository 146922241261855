import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminFinancialsForm } from '../../common/forms/AdminFinancialsForm';
import { getAdminFinancials, createAdminFinancial, updateAdminFinancial } from '../../redux/actions';

const Financials = () => {
  const dispatch = useDispatch();

  const adminFinancials = useSelector(state => state.adminFinancials);
  const adminEventFinancials = adminFinancials.find(item => item?.get('type') === 'EVENT');
  const adminAmenityFinancials = adminFinancials.find(item => item?.get('type') === 'AMENITY');

  useEffect(() => {
    dispatch(getAdminFinancials());
  }, [dispatch]);

  const updateMessage = adminFinancials => {
    const baseMessage = (
      <p class="destructive-text">
        Clicking confirm will notify all SmartExperience Portal Users of the fee change. Only SmartExperience executive
        management is authorized to make this change.
      </p>
    );

    if (adminFinancials?.get('pending')) {
      return (
        <>
          <p>
            There is already a pending events fee change to {adminFinancials?.getIn(['pending', 'fee'])}%. Are you sure
            you want to override that pending change?
          </p>{' '}
          {baseMessage}
        </>
      );
    }

    return (
      <>
        <p>Updated fees will take effect in 30 days.</p>
        {baseMessage}
      </>
    );
  };

  return (
    <>
      <AdminFinancialsForm
        title="Events SmartExperience Fee"
        adminFinancials={adminEventFinancials}
        updateMessage={updateMessage(adminEventFinancials)}
        submitAdminFinancials={fee => {
          const eventFeeAlreadyExists = !!adminEventFinancials?.get('pending');

          if (eventFeeAlreadyExists) {
            return dispatch(updateAdminFinancial(adminEventFinancials?.getIn(['pending', 'id']), fee));
          }
          return dispatch(createAdminFinancial('EVENT', fee));
        }}
      />

      <AdminFinancialsForm
        title="Amenities SmartExperience Transaction Fee"
        adminFinancials={adminAmenityFinancials}
        updateMessage={updateMessage(adminAmenityFinancials)}
        submitAdminFinancials={fee => {
          const amenityFeeAlreadyExists = !!adminAmenityFinancials?.get('pending');

          if (amenityFeeAlreadyExists) {
            return dispatch(updateAdminFinancial(adminAmenityFinancials?.getIn(['pending', 'id']), fee));
          }
          return dispatch(createAdminFinancial('AMENITY', fee));
        }}
      />
    </>
  );
};

export default Financials;
