import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BatteryIndicator from '../../../../../../common/BatteryIndicator';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import { GenerateCSVBtn } from '../../../Community/views/Events/EditEvent/Registrations/GenerateCSVBtn';
import SubHeader from '../../../../../../common/SubHeader';

class BatteryLevels extends Component {
  state = {
    userPermission: {},
  };
  componentDidMount() {
    const { userPermissionList } = this.props;
    const userPermission = {
      resetBatteryLevel: userPermissionList.includes('RESET_BATTERY_LEVEL'),
      ViewUnit: userPermissionList.includes('VIEW_UNIT'),
    };
    this.setState({ userPermission: userPermission });
    this.props.getDevices();
  }

  resetBatteryLevel = device => {
    this.props.updatePanelDevice(device.getIn(['panel', 'id']), device.get('id'), {
      battery_level: 100,
      dmp_hardware_subtype: device.get('dmp_hardware_subtype'),
      dmp_hardware_type: device.get('dmp_hardware_type'),
    });
  };

  renderDevice = device => {
    const { userPermission } = this.state;
    return device.get('hide_battery_status') ? null : (
      <tr key={device.get('id')}>
        <td>{device.get('name')}</td>
        <td>{device.get('dmp_hardware_type')}</td>
        <td>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <BatteryIndicator percentage={device.get('battery_level')} />
            {device.get('battery_level') === 100
              ? null
              : userPermission.resetBatteryLevel && (
                  <button
                    className="button button--secondary--plain"
                    style={{ marginTop: '2px', marginLeft: '5px' }}
                    type="button"
                    onClick={this.resetBatteryLevel.bind(this, device)}
                  >
                    Reset
                  </button>
                )}
          </div>
        </td>
        <td>
          {userPermission.ViewUnit ? (
            <Link
              to={`/properties/${device.getIn(['panel', 'unit', 'property_id'])}/units/${device.getIn([
                'panel',
                'unit',
                'id',
              ])}`}
            >
              {device.getIn(['panel', 'unit', 'number'])}
            </Link>
          ) : (
            <>{device.getIn(['panel', 'unit', 'number'])}</>
          )}
        </td>
      </tr>
    );
  };

  render() {
    const { devices, property } = this.props;
    const { userPermission } = this.state;
    const showCSVbutton = devices.size > 0 && userPermission.ViewUnit;

    return (
      <div className="container">
        <SubHeader
          title="Battery Levels"
          actions={[
            <>
              {showCSVbutton && (
                <GenerateCSVBtn filePath={`properties/${property.get('id')}/devices/battery-levels-csv-list.csv`} />
              )}
            </>,
          ]}
        />
        <div>
          {devices.size === 0 ? (
            <EmptyPlaceholder>Any devices with battery levels will appear here.</EmptyPlaceholder>
          ) : (
            <table className="table-list">
              <thead>
                <tr>
                  <th>Device Name</th>
                  <th>Type</th>
                  <th>Battery Level</th>
                  <th>Unit #</th>
                </tr>
              </thead>
              <tbody>
                {devices
                  .sort((a, b) => a.get('battery_level') - b.get('battery_level'))
                  .filter(device => device.get('dmp_hardware_type') !== 'LIGHT')
                  .map(this.renderDevice)}
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

export default BatteryLevels;
