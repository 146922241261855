import { combineReducers } from 'redux';
import { List, fromJS, Map } from 'immutable';

const rolesList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_ROLES_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const inRolesList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_ROLES_NAME_SUCCESS':
      return fromJS(action.json);
    case 'GET_ROLE_BY_PERMISSION_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const permissionList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_GROUP_PERMISSION_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const inPermissionList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PERMISSION_BY_CONDITIONS_SUCCESS':
      return fromJS(action.json);
    case 'GET_GROUP_PERMISSION_BY_NAME_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const rolePermissionList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_ROLE_PERMISSION_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};
const rolesInfo = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_ROLE_BY_ID_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const userRolePermission = (state = List(), action) => {
  switch (action.type) {
    case 'GET_USER_ROLE_PERMISSION_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const rolesListLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_ROLES_NAME_REQUEST':
    case 'GET_ROLES_REQUEST':
    case 'GET_ROLE_BY_ID_REQUEST':
    case 'GET_PERMISSION_BY_CONDITIONS_REQUEST':
    case 'GET_GROUP_PERMISSION_REQUEST':
    case 'GET_ROLE_PERMISSION_REQUEST':
    case 'GET_USER_ROLE_PERMISSION_REQUEST':
      return false;
    case 'GET_ROLES_NAME_SUCCESS':
    case 'GET_ROLES_SUCCESS':
    case 'GET_ROLE_BY_ID_SUCCESS':
    case 'GET_PERMISSION_BY_CONDITIONS_SUCCESS':
    case 'GET_GROUP_PERMISSION_SUCCESS':
    case 'GET_ROLE_PERMISSION_SUCCESS':
    case 'GET_USER_ROLE_PERMISSION_SUCCESS':
      return true;
    default:
      return true;
  }
};
//Permission
const permissionInfo = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_PERMISSION_BY_ID_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const permissionListLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_PERMISSION_BY_ID_REQUEST':
    case 'GET_GROUP_PERMISSION_BY_NAME__REQUEST':
    case 'GET_GROUP_PERMISSION_REQUEST':
      return false;
    case 'GET_PERMISSION_BY_ID_SUCCESS':
    case 'GET_GROUP_PERMISSION_BY_NAME_SUCCESS':
    case 'GET_GROUP_PERMISSION_SUCCESS':
      return true;
    default:
      return true;
  }
};

const groups = (state = List(), action) => {
  switch (action.type) {
    case 'GET_GROUP_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const users = (state = List(), action) => {
  switch (action.type) {
    case 'GET_USERS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const roleSelect = (state = List(), action) => {
  switch (action.type) {
    case 'GET_ROLES_NAME_BY_LEVEL_SUCCESS':
      return fromJS(action.json);
    case 'GET_ROLES_NAME_BY_USER_SUCCESS':
      return fromJS(action.json);
    case 'GET_ROLES_NAME_BY_PROPERTY_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const roleLevels = (state = List(), action) => {
  switch (action.type) {
    case 'GET_ROLES_LEVEL_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const propertys = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTYS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

export default combineReducers({
  rolesListLoading,
  rolesList,
  inRolesList,
  permissionList,
  inPermissionList,
  rolePermissionList,
  rolesInfo,
  userRolePermission,
  permissionInfo,
  permissionListLoading,
  groups,
  users,
  roleSelect,
  roleLevels,
  propertys,
});
