import client from '../../../common/client';

export const getVendorStripeConnectAccount = vendorId => {
  return {
    promise: client.get(`stripe-integration/vendors/${vendorId}/account`),
    type: 'GET_VENDOR_STRIPE_CONNECT_ACCOUNT',
  };
};

export const createVendorStripeConnectAccount = vendorId => {
  return {
    promise: client.post(`stripe-integration/vendors/${vendorId}/connect-account`),
    type: 'CREATE_VENDOR_STRIPE_CONNECT_ACCOUNT',
  };
};

export const clearStripeAccountLink = () => {
  return {
    type: 'CLEAR_STRIPE_ACCOUNT_LINK',
  };
};
