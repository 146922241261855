import React from 'react';
import { useSelector } from 'react-redux';
import { SGCheckboxList } from './SGCheckboxList';
import { useFormikContext } from 'formik';
import Loading from '../../../../../../../../../../common/Loading';

export const SGFormNotifiedStaff = () => {
  const { setFieldValue, values } = useFormikContext();
  const { staffMembers } = useSelector(state => state.property);
  if (!staffMembers) return <Loading />;

  const staffMembersData = staffMembers.toJS();

  return (
    <div className="sg-units-staff">
      <SGCheckboxList
        title="STAFF MEMBERS"
        onChange={({ selectedList }) =>
          setFieldValue(
            'notified_staff',
            selectedList.map(({ value }) => value)
          )
        }
        items={staffMembersData.map(({ id, last_name, first_name, role }) => ({
          name: id,
          value: id,
          title: `${first_name} ${last_name}`,
          subTitle: role[0]?.role_name,
          checked: values.notified_staff.includes(id),
        }))}
      />
    </div>
  );
};
