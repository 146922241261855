import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../../../../common/icons/icon';
import Spinner from '../../../../../../../../common/Loading/SpinnerLoading';

class InitializeStatusBar extends Component {
  static propTypes = {
    status: PropTypes.oneOf(['PENDING', 'STARTED', 'SUCCESS', 'FAILURE']),
    renderButton: PropTypes.func,
    onButtonClick: PropTypes.func,
  };

  render() {
    const { status, children, onButtonClick } = this.props;
    let statusIcon = '';

    switch (status) {
      case 'PENDING':
        statusIcon = 'Clock';
        break;
      case 'SUCCESS':
        statusIcon = 'Check';
        break;
      case 'FAILURE':
        statusIcon = 'ErrorExclamation';
        break;
      default:
        statusIcon = 'Clock';
    }

    return (
      <div className="initialize-status-bar">
        <div className={`initialize-status-bar--inner ${status.toLowerCase()}`}>
          <div className="initialize--flex">
            <div>{children}</div>
            {status !== 'STARTED' && <Icon icon={statusIcon} />}
            {status === 'STARTED' && <Spinner />}
          </div>
        </div>

        {status === 'FAILURE' && (
          <button className="button" onClick={onButtonClick} type="button">
            Try Again
          </button>
        )}
      </div>
    );
  }
}

export default InitializeStatusBar;
