import moment from 'moment';
import React from 'react';

const RefundPolicy = ({ event }) => {
  return (
    <div className="container">
      <div className="paper radius-top-left radius top-right">
        <div className="paper__grid">
          <div className="paper__section--grid-item">
            <section className="paper__section">
              <h4 className="section-header h4">Refund Policy</h4>
              <div className="details--read-only__wrapper">
                <div className="details--read-only">
                  <div className="read-only--label">Refund Cut-Off Date</div>
                  <div className="read-only--value">
                    {moment(event.refund_cutoff_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                  </div>
                </div>

                <div className="details--read-only">
                  <div className="read-only--label">Refund Cut-Off Time</div>
                  <div className="read-only--value">{moment(event.refund_cutoff_time, 'HH:mm').format('hh:mma')}</div>
                </div>

                <div className="details--read-only">
                  <div className="read-only--label">Cancelation Fee %</div>
                  <div className="read-only--value">{event.cancel_fee_percent}%</div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
