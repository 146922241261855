import { connect } from 'react-redux';

import EditProfile from './EditProfile';
import { editProfile, getPropertyAreas, getPropertySchedules, getProfiles } from '../../../../redux/actions';

function mapStateToProps(state, ownProps) {
  return {
    property: state.property.activeProperty,
    accessControlAreas: state.property.accessControlAreas,
    accessControlAreasLoaded: state.property.accessControlAreasLoaded,
    accessControlSchedules: state.property.accessControlSchedules,
    accessControlSchedulesLoaded: state.property.accessControlSchedulesLoaded,
    matchingProfile: state.property.accessControlProfiles.find(
      profile => profile.get('id') === ownProps.match.params.profileId
    ),
    user: state.user,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    editProfile: payload =>
      dispatch(editProfile(ownProps.match.params.propertyId, ownProps.match.params.profileId, payload)),
    getPropertyAreas: () => dispatch(getPropertyAreas(ownProps.match.params.propertyId)),
    getPropertySchedules: () => dispatch(getPropertySchedules(ownProps.match.params.propertyId)),
    getProfiles: () => dispatch(getProfiles(ownProps.match.params.propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
