import { connect } from 'react-redux';
import { createOffer, getProperties } from '../../redux/actions';
import { selectProperties } from '../../redux/selectors';
import AddOffer from './AddOffer';

function mapStateToProps(state) {
  return {
    propertyList: selectProperties(state),
    propertyListLoaded: state.getPropertiesLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createOffer: values => dispatch(createOffer(values)),
    getProperties: () => dispatch(getProperties()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOffer);
