import React, { Component } from 'react';

export default class Loading extends Component {
  render() {
    return (
      <div className="page-loading__wrapper">
        <div className="page-loading">
          <svg width="121" height="122" viewBox="0 0 121 122" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2_2602)">
              <path
                d="M38 26C11.0474 28.159 1.43642 48.2329 0 58C7.20824 39.1855 28.3368 34.739 38 34.8675V26Z"
                fill="#0496E1"
              />
              <path
                d="M38 39C11.0474 41.159 1.43642 61.2329 0 71C7.20824 52.1855 28.3368 47.739 38 47.8675V39Z"
                fill="#0496E1"
              />
              <path
                d="M38 51C11.0474 53.159 1.43642 73.2329 0 83C7.20824 64.1855 28.3368 59.739 38 59.8675V51Z"
                fill="#0496E1"
              />
              <path
                d="M38 63C11.0474 65.159 1.43642 85.2329 0 95C7.20824 76.1855 28.3368 71.739 38 71.8675V63Z"
                fill="#0496E1"
              />
              <path
                d="M38 75C11.0474 77.159 1.43642 97.2329 0 107C7.20824 88.1855 28.3368 83.739 38 83.8675V75Z"
                fill="#0496E1"
              />
              <path
                d="M38 87C11.0474 89.159 1.43642 109.233 0 119C7.20824 100.186 28.3368 95.739 38 95.8675V87Z"
                fill="#0496E1"
              />
              <path
                d="M38 99C11.0474 101.159 1.43642 121.233 0 131C7.20824 112.186 28.3368 107.739 38 107.867V99Z"
                fill="#0496E1"
              />
              <path
                d="M38 111C11.0474 113.159 1.43642 133.233 0 143C7.20824 124.186 28.3368 119.739 38 119.867V111Z"
                fill="#0496E1"
              />
            </g>
            <g clipPath="url(#clip1_2_2602)">
              <path
                d="M94 54C113.151 55.5518 119.979 69.9799 121 77C115.878 63.4771 100.866 60.2811 94 60.3735V54Z"
                fill="#0496E1"
              />
              <path
                d="M94 63C113.151 64.5518 119.979 78.9799 121 86C115.878 72.4771 100.866 69.2811 94 69.3735V63Z"
                fill="#0496E1"
              />
              <path
                d="M94 72C113.151 73.5518 119.979 87.9799 121 95C115.878 81.4771 100.866 78.2811 94 78.3735V72Z"
                fill="#0496E1"
              />
              <path
                d="M94 81C113.151 82.5518 119.979 96.9799 121 104C115.878 90.4771 100.866 87.2811 94 87.3735V81Z"
                fill="#0496E1"
              />
              <path
                d="M94 90C113.151 91.5518 119.979 105.98 121 113C115.878 99.4771 100.866 96.2811 94 96.3735V90Z"
                fill="#0496E1"
              />
              <path
                d="M94 99C113.151 100.552 119.979 114.98 121 122C115.878 108.477 100.866 105.281 94 105.373V99Z"
                fill="#0496E1"
              />
              <path
                d="M94 108C113.151 109.552 119.979 123.98 121 131C115.878 117.477 100.866 114.281 94 114.373V108Z"
                fill="#0496E1"
              />
              <path
                d="M94 117C113.151 118.552 119.979 132.98 121 140C115.878 126.477 100.866 123.281 94 123.373V117Z"
                fill="#0496E1"
              />
            </g>
            <g clipPath="url(#clip2_2_2602)">
              <path
                d="M41.4999 3.05176e-05C75.8999 2.80003 88.1666 28.8334 89.9999 41.5C80.7999 17.1 53.8333 11.3334 41.4999 11.5V3.05176e-05Z"
                fill="#0A6CAD"
              />
              <path
                d="M41.4999 16C75.8999 18.8 88.1666 44.8333 89.9999 57.5C80.7999 33.1 53.8333 27.3333 41.4999 27.5V16Z"
                fill="#0A6CAD"
              />
              <path
                d="M41.4999 32C75.8999 34.8 88.1666 60.8333 89.9999 73.5C80.7999 49.1 53.8333 43.3333 41.4999 43.5V32Z"
                fill="#0A6CAD"
              />
              <path
                d="M41.4999 47.9999C75.8999 50.7999 88.1666 76.8333 89.9999 89.4999C80.7999 65.0999 53.8333 59.3333 41.4999 59.4999V47.9999Z"
                fill="#0A6CAD"
              />
              <path
                d="M41.4999 63.9999C75.8999 66.7999 88.1666 92.8332 89.9999 105.5C80.7999 81.0999 53.8333 75.3332 41.4999 75.4999V63.9999Z"
                fill="#0A6CAD"
              />
              <path
                d="M41.4999 79.9999C75.8999 82.7999 88.1666 108.833 89.9999 121.5C80.7999 97.0999 53.8333 91.3332 41.4999 91.4999V79.9999Z"
                fill="#0A6CAD"
              />
              <path
                d="M41.4999 95.9998C75.8999 98.7998 88.1666 124.833 89.9999 137.5C80.7999 113.1 53.8333 107.333 41.4999 107.5V95.9998Z"
                fill="#0A6CAD"
              />
              <path
                d="M41.4999 112C75.8999 114.8 88.1666 140.833 89.9999 153.5C80.7999 129.1 53.8333 123.333 41.4999 123.5V112Z"
                fill="#0A6CAD"
              />
            </g>
            <defs>
              <clipPath id="clip0_2_2602">
                <rect width="38" height="93" fill="white" transform="translate(0 26)" />
              </clipPath>
              <clipPath id="clip1_2_2602">
                <rect width="27" height="68" fill="white" transform="translate(94 54)" />
              </clipPath>
              <clipPath id="clip2_2_2602">
                <rect width="49" height="121" fill="white" transform="translate(41)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    );
  }
}
