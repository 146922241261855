export default {
  main: {
    title: 'Гости собственности', //?
    addButtonTitle: 'Добавить гостя собственности', //?
    tableHeaders: {
      name: 'ИМЯ',
      phone: 'НОМЕР ТЕЛЕФОНА',
    },

    removeConfirmation: 'Вы уверены что хотите <strong className="strong">удалить этого гостя</strong>?',
  },

  property: {
    addTitle: 'Добавить гостя собственности',
    editTitle: 'Редактировать гостя собственности',
    guestInformation: 'Информация о госте',

    fields: {
      firstName: 'ИМЯ',
      lastName: 'ФАМИЛИЯ',
      phoneNumber: 'НОМЕР ТЕЛЕФОНА',
      emailAddress: 'ЭЛЕКТРОННАЯ ПОЧТА',
    },

    errors: {
      enterFirstName: 'Введите имя пожалуйста',
      maxFirstName: 'Имя должно содержать не более 64 символов',
      enterLastName: 'Введите фамилию пожалуйста',
      maxLastName: 'Фамилия должна содержать не более 64 символов',
      enterPhoneNumber: 'Введите номер телефона пожалуйста',
      invalidPhoneNumber: 'Должен быть действительный номер телефона',
      invalidEmailAddress: 'Должна быть действительная электронная почта',
      enterCode: 'Введите код',
      invalidCodeLength: 'Код учетной записи должен состоять из 5 символов.',
      invalidCodeNumber: 'Код должен находиться в диапазоне от 00000 до 65535.',
      selectType: 'Выберите тип учетных данных',
      invalidNoteLength: 'Заметка к учетным данным должны содержать не более 255 символов.',
      uniqueProperty: 'Код должен быть уникальным',
    },

    accessProfiles: 'Доступ к профилям',
    savePropertyGuest: 'Сохранить гостя собственности', //?
    savingPropertyGuest: 'Сохранение гостя собственности...',

    removeGuest: 'Удалить гостя',
  },
};
