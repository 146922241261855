import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../../../../common/PageHeader';
import Immutable from 'immutable';
import TelephoneEntryDeviceForm from '../../../../../../common/forms/TelephoneEntryDeviceForm';
import Loading from '../../../../../../common/Loading/index';
import TelephoneEntryDeviceAuthTokens from './TelephoneEntryDeviceAuthTokens';

class EditTelephoneEntryDevice extends Component {
  componentDidMount() {
    this.props.getTelephoneEntryDevices();
    this.props.getProperties();
    this.props.getTelephoneEntryDeviceTokens();
  }

  render() {
    const {
      telephoneEntryDevice,
      telephoneEntryDevicesLoaded,
      editTelephoneEntryDevice,
      history,
      propertyOptions,
    } = this.props;

    if (!telephoneEntryDevicesLoaded || !telephoneEntryDevice) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeader title="Edit Telephone Entry Device" backLink="../" />
        <div className="container">
          <TelephoneEntryDeviceForm
            initialValues={{
              name: telephoneEntryDevice.get('name'),
              id: telephoneEntryDevice.get('id'),
              property_id: telephoneEntryDevice.get('property_id') || '',
              enable_resident_directory: telephoneEntryDevice.get('enable_resident_directory'),
              enable_numeric_codes: telephoneEntryDevice.get('enable_numeric_codes'),
              enable_qr_codes: telephoneEntryDevice.get('enable_qr_codes'),
              enable_call_manager: telephoneEntryDevice.get('enable_call_manager'),
              enable_video_calls: telephoneEntryDevice.get('enable_video_calls'),
            }}
            onSubmitClick={submitValues => {
              return editTelephoneEntryDevice(submitValues, telephoneEntryDevice.get('id')).then(res => {
                if (res.response.ok) {
                  return history.push(`../`);
                }
                throw res.json.message;
              });
            }}
            onCancelClick={() => history.replace('../')}
            disableIdField={true}
            propertyOptions={propertyOptions}
          />
          <TelephoneEntryDeviceAuthTokens telephoneEntryDeviceId={telephoneEntryDevice.get('id')} />
        </div>
      </div>
    );
  }
}

EditTelephoneEntryDevice.propTypes = {
  telephoneEntryDevices: PropTypes.instanceOf(Immutable.List),
  getTelephoneEntryDevices: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditTelephoneEntryDevice;
