import React from 'react';
import Icon from '../../../../../../../../../../common/icons/icon';

export const ResidentAccessModal = ({ visible, onCancel, name, onEnable, onConfirm }) =>
  visible && (
    <div className="modal modal--visible revokeModel-div revokeEnableModel-div">
      <div className="modal__children">
        <div className="modal__body">
          <div className="modal__body--text">
            <div className="paper radius-top-left radius-top-right revokeModel-top-div">
              <div className="revokeModel-top-title1">Are you sure you want to enable access from {name}?</div>
              <div className="revokeModel-top-title2">They will be able take a self-guided tour.</div>
              <div className="revokeModel-top-title3">
                An automated email will be sent to the prospective resident letting them know their access has been
                enabled.
              </div>
            </div>
          </div>
          <div className="btns-row button-container-deny">
            <button
              className="button btn-approve button-positive"
              type="button"
              onClick={() => {
                if (onEnable) onEnable();
                if (onConfirm) onConfirm(true);
              }}
            >
              <Icon icon="EnableAccessWhite" />
              Enable Access
            </button>
            <button
              className="button-no"
              type="button"
              onClick={() => {
                onCancel();
                if (onConfirm) onConfirm(false);
              }}
            >
              No, Don’t Enable
            </button>
          </div>
        </div>
      </div>
      <button type="button" className="modal__overlay" onClick={onCancel} />
    </div>
  );
