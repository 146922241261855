import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Link } from 'react-router-dom';
import querystring from 'query-string';

import { SearchText, SearchList } from '../../common/Search';
import OffersTable from './OffersTable';
import PageHeader from '../../common/PageHeader';

class OffersList extends Component {
  state = {
    parsedLocation: '',
    userPermission: {},
  };
  componentDidMount() {
    const { userPermissionList } = this.props;
    const userPermission = {
      createNewOffer: userPermissionList.includes('CREATE_NEW_OFFER'),
      editOffer: userPermissionList.includes('EDIT_OFFER'),
      deleteOffer: userPermissionList.includes('DELETE_OFFER'),
    };
    this.setState({ userPermission: userPermission });
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      return this.updateData();
    }
  }

  updateData = () => {
    const { getOffers, location } = this.props;
    const search = querystring.parse(location.search);
    this.setState({ parsedLocation: search });
    getOffers(search);
  };

  onClickClearSearch = () => {
    const { history } = this.props;
    return history.push('/offers');
  };

  render() {
    const { searching, list, loading, location, removeOffer, total } = this.props;
    const { userPermission } = this.state;

    return (
      <div className="pm-offers">
        <PageHeader>
          {(searching || (!searching && total > 0)) && <SearchText name="search" placeHolder="Search Offers" />}
          {searching ? (
            <button type="button" className="button" onClick={this.onClickClearSearch} style={{ marginTop: '10px' }}>
              Clear Search
            </button>
          ) : null}
        </PageHeader>

        <div className="container pm-offers__content">
          <div className="page-header subheader">
            <div className="page-header__title">
              <h4 className="h4">{`${total} Offer${total !== 1 ? 's' : ''}`}</h4>
            </div>
            {(list.size > 0 || searching) && (
              <div className="page-header__actions">
                <div className="status-filter__container">
                  <span className="status-filter--label">Filter by status:</span>
                  <SearchList
                    name="filter"
                    placeHolder="Status..."
                    options={[
                      { value: '', label: 'None' },
                      { value: 'active', label: 'Active' },
                      { value: 'inactive', label: 'Inactive' },
                    ]}
                  />
                </div>
                {userPermission.createNewOffer && (
                  <Link className="button" to="offers/add">
                    Create Offer
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="table-units-list__wrapper">
          <div className="container">
            <OffersTable
              userPermission={userPermission}
              location={location}
              removeOffer={removeOffer}
              list={list}
              loading={loading}
              total={total}
              searching={searching}
            />
          </div>
        </div>
      </div>
    );
  }
}

OffersList.propTypes = {
  getOffers: PropTypes.func.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  loading: PropTypes.bool.isRequired,
  removeOffer: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};
export default OffersList;
