import React from 'react';
import VendorDetailsForm from '../../../../../../../../common/forms/VendorDetailsForm';

const AddVendor = ({ property, addVendor, history }) => {
  return (
    <div>
      <VendorDetailsForm
        submitFunction={values => {
          return addVendor(property.get('id'), values.name, values.email).then(res => {
            if (res.response.ok) {
              return history.push(`/properties/${property.get('id')}/community/vendors`);
            }
            throw res.json.message;
          });
        }}
        history={history}
        mode="add"
        initialValues={{
          name: '',
          email: '',
        }}
      />
    </div>
  );
};

export default AddVendor;
