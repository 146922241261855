import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class SubHeader extends Component {
  render() {
    const { title, actions, underline, paperSubheader, nested, className, required, size } = this.props;

    return (
      <div
        className={classNames([
          'page-header',
          'subheader',
          underline && 'underline',
          paperSubheader && 'paper-subheader',
          nested && 'nested',
          className,
        ])}
      >
        <h4 className="h4" style={{ fontSize: size && size }}>
          {title}
          {required ? <span className="subheader-required">*</span> : null}
        </h4>
        {actions && (
          <div className="page-header__actions">
            {actions.map((action, i) => (
              <div key={i}>{action}</div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

SubHeader.propTypes = {
  title: PropTypes.node.isRequired,
  actions: PropTypes.array,
  size: PropTypes.number,
};

export default SubHeader;
