import React from 'react';
import cn from 'classnames';

export const TextToggle = ({ value, name, disabled, onChange, uncheckedText, checkedText }) => {
  const toggleClasses = {
    'text-toggle__inner': true,
    'toggle-icon--checked': !!value,
  };

  return (
    <div className={cn('text-toggle__wrapper', disabled && 'disabled')}>
      <input
        id={name}
        type="checkbox"
        checked={!!value}
        onChange={() => {
          onChange(!value);
        }}
        disabled={disabled}
        style={{ display: 'none' }}
      />
      <div className={cn(toggleClasses)}>
        <div className={`text-toggle__option ${!value && 'checked'}`}>{uncheckedText}</div>
        <div className={`text-toggle__option ${value && 'checked'}`}>{checkedText}</div>
      </div>
    </div>
  );
};
