import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Icon from '../../../../../../../../../../common/icons/icon';
import Spinner from '../../../../../../../../../../common/Loading/SpinnerLoading';

class RemoveDevice extends Component {
  static propTypes = {
    getPanelDevices: PropTypes.func.isRequired,
    panel: PropTypes.object,
    requestRemoveZwaveDevice: PropTypes.func.isRequired,
    cancelPanelJobs: PropTypes.func.isRequired,
    removePanelDevice: PropTypes.func.isRequired,
  };
  state = {
    jobStatus: '',
    pairingError: '',
    deviceType: '',
  };

  componentWillMount() {
    this.props.getPanelDevices();
  }

  componentDidMount() {
    this.unpairingMode();
  }

  componentWillUnmount() {
    if (this.interval) {
      this.cancelZwave();
    }
  }

  unpairingMode() {
    const { panel } = this.props;
    this.setState({ showUnpairing: true });
    this.props
      .requestRemoveZwaveDevice({
        dmp_account_number: panel.get('dmp_account_number'),
        dmp_account_prefix: panel.get('dmp_account_prefix'),
      })
      .then(action => {
        if (action.response.ok) {
          this.setState({
            jobStatus: '',
            pairingError: '',
          });
          if (action.json.status === 'pending') {
            this.interval = setInterval(() => this.jobStatusLoop(action.json.job_number, 'remove'), 1000);
          }
        } else {
          this.setState({ pairingError: 'Failed to enter unpairing mode' });
        }
      });
  }

  cancelZwave = () => {
    const { cancelPanelJobs, panel } = this.props;
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = false;
    }
    cancelPanelJobs({
      dmp_account_number: panel.get('dmp_account_number'),
      dmp_account_prefix: panel.get('dmp_account_prefix'),
    });
  };

  jobStatusLoop = (jobId, jobType) => {
    const { createPanelDevice, removePanelDevice, panel } = this.props;
    this.props
      .getJobStatus(jobId)
      .then(action => {
        if (action.json.status === 'error') {
          this.cancelZwave();
          this.setState({
            pairingError: 'Panel returned error - ' + action.json.error_code + ': ' + action.json.error_message,
          });
        }
        const success = action.json && action.json.status === 'success' ? true : false;
        let stage = action.json && action.json.stage ? action.json.stage : 'none';

        if (
          !success &&
          !['none', 'listen_ready', 'device_found', 'device_removed'].some(substring => stage.includes(substring))
        ) {
          this.cancelZwave();
          this.setState({ pairingError: 'Unexpected Stage - ' + stage });
        } else if (success) {
          clearInterval(this.interval);
          this.interval = false;
          const dmpDevice =
            action.json.response && action.json.response.nodes && action.json.response.nodes.length > 0
              ? action.json.response.nodes[0]
              : false;
          if (!dmpDevice) {
            this.setState({ pairingError: 'Orphaned device successfully excluded' });
          }
          this.setState({ deviceType: dmpDevice.node_type });
          if (jobType === 'add') {
            createPanelDevice({
              id: dmpDevice.name,
              zone: dmpDevice.zone,
              name: this.state.newDeviceJSON.name,
              mastercode: this.state.newDeviceJSON.mastercode,
              dmp_hardware_type: dmpDevice.node_type,
              dmp_hardware_subtype: dmpDevice.node_subtype,
              is_community_device: this.state.newDeviceJSON.is_community_device,
              hide_battery_status: this.state.newDeviceJSON.hide_battery_status,
            })
              .then(() => {
                this.setState({ jobStatus: 'success' });
              })
              .catch(err => {
                this.setState({ pairingError: 'Failed to recognize device' });
              });
          } else if (jobType === 'remove') {
            if (
              panel
                .get('devices')
                .toJS()
                .some(device => device.id === dmpDevice.name)
            ) {
              removePanelDevice(dmpDevice.name)
                .then(() => {
                  this.setState({ jobStatus: 'success' });
                })
                .catch(err => {
                  this.setState({ pairingError: 'Failed to remove device' });
                });
            } else {
              this.setState({ jobStatus: 'success' });
            }
          }
        }
        if (!success && this.state.jobStatus !== stage) {
          this.setState({ jobStatus: stage });
        }
      })
      .catch(err => {
        this.cancelZwave();
        this.setState({ pairingError: 'Failed to get job status' });
      });
  };

  renderHeader = () => {
    return (
      <div className="page-header page-header__custom">
        <div className="container">
          <Link to={`./`} className="back-arrow">
            <Icon icon="ArrowLeft" />
            Back
          </Link>
          <h1 className="h1">Remove Z-Wave Device</h1>
        </div>
      </div>
    );
  };

  renderPairingError = () => {
    return <div className="input-validation pairing-error">Failed to unpair device: {this.state.pairingError}.</div>;
  };

  render() {
    return (
      <div className="removing-device--screen">
        {this.renderHeader()}
        <div className="container">
          {this.state.pairingError !== '' && this.renderPairingError()}
          <div className="paper radius-top-left radius-top-right pairing-screen">
            <div className="page-header subheader underline">
              <h4 className="h4">Removing Z-Wave Device</h4>
            </div>
            <div className="font-light">
              Prepare your z-wave device for unpairing following the instructions provided with the device
            </div>
            <div className="scanning__wrapper">
              {this.state.jobStatus !== 'success' ? (
                <div className="scanning-message font-light">
                  Scanning for Z-Wave device to remove. Deactivate the device
                  <br />
                  following the instructions provided with the device.
                </div>
              ) : null}
              {this.state.jobStatus !== 'success' ? <Spinner /> : <Icon icon="Finished" />}
              {this.state.jobStatus === '' && <div className="job-status">Loading...</div>}
              {this.state.jobStatus === 'listen_ready' && <div className="job-status">Scanning...</div>}
              {this.state.jobStatus === 'device_found' && <div className="job-status">Device Found...</div>}
              {this.state.jobStatus === 'success' && (
                <div className="job-status added">Your {this.state.deviceType} device has been removed!</div>
              )}
            </div>
          </div>
          <div className="paper__footer radius-bottom-left radius-bottom-right">
            <div className="button--error__wrapper">
              {this.state.jobStatus !== 'success' ? (
                <Fragment>
                  <button disabled={true} className="button">
                    Remove Device
                  </button>
                  <Link className="button" to={`./`}>
                    Cancel
                  </Link>
                </Fragment>
              ) : (
                <Fragment>
                  <button
                    className="button"
                    type="button"
                    onClick={() => {
                      this.setState(state => ({ jobStatus: '' }));
                      if (this.interval) {
                        this.cancelZwave();
                      }
                      this.unpairingMode();
                    }}
                  >
                    Remove Another Device
                  </button>
                  <Link className="button" to={`./`}>
                    Finish
                  </Link>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RemoveDevice;
