export default {
  main: {
    headerTitle: 'Portal Users',
    addPortalUser: 'Add Portal User',

    tableHeader: {
      name: 'NAME',
      role: 'ROLE',
      phoneNumber: 'PHONE NUMBER',
    },

    removeConfirmation: 'Are you sure you want to <strong className="strong">remove this user</strong>?',
  },

  details: {
    addPortalUser: 'Add Portal User',
    editPortalUser: 'Edit Portal User',

    staffInformation: 'Staff Information',
    emailSettings: 'Email Settings',
    accessProfiles: 'Access Profiles',
    accessCodes: 'Access Codes',
    saveButton: 'Save Portal User',
    removeButton: 'Remove Portal User',

    removeConfirmation: 'Are you sure you want to <strong className="strong">remove this user</strong>?',

    fields: {
      firstName: 'FIRST NAME',
      lastName: 'LAST NAME',
      role: 'ROLE',
      selectRole: 'Select a role', //!
      unavailableRole: 'No role available', //!
      roles: {
        unassigned: 'Unassigned',
      },
      email: 'EMAIL ADDRESS',
      phoneNumber: 'PHONE NUMBER  ',
      companyName: 'COMPANY NAME',
      approvedPurchaser: 'APPROVED PURCHASER',
      enableDiscussionBoardEmails: 'ENABLE DISCUSSION BOARD EMAILS',
      enableDailyDiscussionBoardActivityEmails: 'ENABLE DAILY DISCUSSION BOARD ACTIVITY EMAILS',
    },

    errors: {
      requiredFirstName: 'Please enter a first name',
      maxFirstName: 'First Name must be 64 characters or less', //!
      requiredLastName: 'Please enter a last name',
      maxLastName: 'Last Name must be 64 characters or less', //!
      requiredEmail: 'Please enter an email address',
      invalidEmail: 'Must be a valid email address', //!
      invalidPhoneNumber: 'Must be a valid phone number',

      enterCode: 'Please enter a code', //!
      invalidCodeLength: 'Credential code must be 5 characters', //!
      invalidCodeNumber: 'Code must be between 00000 and 65535', //!
      selectType: 'Please select a credential type', //!
      invalidNoteLength: 'Credential note must be 255 characters or less', //!
      uniqueProperty: 'Code must be unique', //!
      profiles: 'At least one access profile option must be selected', //!
    },
  },
};
