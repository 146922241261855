import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icons/icon';
function DoorbellSearch({ suggestions, onSelectSuggestion, onChange }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  const [touched, setTouched] = useState(false);
  useEffect(() => {
    let filtered = suggestions.filter(doorbell => doorbell.id.toUpperCase().includes(searchTerm.toUpperCase()));
    filtered = filtered.sort((doorbell1, doorbell2) =>
      doorbell1.id.toUpperCase() > doorbell2.id.toUpperCase()
        ? 1
        : doorbell2.id.toUpperCase() > doorbell1.id.toUpperCase()
        ? -1
        : 0
    );
    setFilteredSuggestions(filtered);
  }, [searchTerm, suggestions]);
  return (
    <div
      onFocus={() => {
        setTouched(true);
      }}
    >
      <div
        className="doorbell-search-input-container"
        style={
          touched
            ? {
                border: 'solid 1px #1e90ff',
              }
            : {
                border: 'solid 1px #d4dce6',
              }
        }
      >
        <input
          type="text"
          style={{ outline: '0', border: 'white 1px solid', appearance: 'none' }}
          value={searchTerm}
          placeholder="Type here..."
          onChange={event => {
            setSearchTerm(event.target.value);
            onChange(event.target.value);
          }}
        />
        <Icon icon="Search" style={{ marginRight: '5px', cursor: 'initial' }} />
      </div>
      {searchTerm !== '' && touched && (
        <div className="doorbell-search-scroll">
          {filteredSuggestions.map(suggestion => {
            return (
              <div
                key={suggestion.id}
                className="doorbell-search-suggestion"
                onClick={() => {
                  setSearchTerm(suggestion.id);
                  onSelectSuggestion(suggestion.id);
                }}
                onBlur={() => {
                  setTouched(false);
                }}
              >
                {suggestion.id}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

DoorbellSearch.propTypes = {
  onSelectSuggestion: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
};
DoorbellSearch.defaultProps = {
  suggestions: [],
};

export default DoorbellSearch;
