import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import querystring from 'query-string';

import Pager from '../../../../../../common/Pager';
import { SearchText } from '../../../../../../common/Search';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import PropertyPageHeader from '../../../../../../common/PropertyPageHeader';
import Loading from '../../../../../../common/Loading';
import Icon from '../../../../../../common/icons/icon';

class ResidentList extends Component {
  state = {
    userPermission: {},
    allResidents: [],
  };
  componentDidMount() {
    const { propertyId, location, userPermissionList } = this.props;
    const userPermission = {
      editResidentPersonalDetails: userPermissionList.includes('EDIT_RESIDENT_PERSONAL_DETAILS'),
      viewUnit: userPermissionList.includes('VIEW_UNIT'),
      addResidentToUnit: userPermissionList.includes('ADD_RESIDENT_TO_UNIT'),
      removeResident: userPermissionList.includes('REMOVE_RESIDENT'),
    };
    this.setState({ userPermission: userPermission });

    this.updateData(propertyId, location);

    this.setAllResidents();
  }

  componentDidUpdate(prevProps) {
    const { propertyId, location } = this.props;
    if (prevProps.location.search !== location.search) {
      return this.updateData(propertyId, location);
    }
  }

  updateData = (propertyId, location) => {
    if (location.search) {
      const search = querystring.parse(location.search);
      this.props.getResidents(propertyId, search);
    } else {
      this.props.getResidents(propertyId, null);
    }
  };

  onClickClearSearch = () => {
    const { history, location } = this.props;
    return history.push(location.pathname);
  };

  renderPlaceholder() {
    const { error, t } = this.props;

    if (error) {
      return (
        <EmptyPlaceholder buttonText="Refresh" onClick={() => window.location.reload()}>
          {t('main.anErrorOccurred')}
        </EmptyPlaceholder>
      );
    }

    return (
      <div className="container">
        <EmptyPlaceholder>{t('main.noResults')}</EmptyPlaceholder>
      </div>
    );
  }

  renderTable() {
    const { propertyId, property, residentsList, userPermissionList, t } = this.props;
    const { userPermission } = this.state;

    const tabIndexStr = userPermissionList.includes('VIEW_RESIDENT_CONTACT_INFORMATION') ? '' : '/access-codes';

    return (
      <div className="table-units-list__wrapper unit-table--container">
        <div className="container">
          <table className="table-units-list">
            <thead>
              <tr>
                <th className="first-column">{t('main.tableHeaders.resident')}</th>
                <th>{t('main.tableHeaders.unit')}</th>
                {property.get('admin_enabled_unit_automation') && <th>{t('main.tableHeaders.smartHome')}</th>}
                <th>{t('main.tableHeaders.roommates')}</th>
                {property.get('enable_tracking') && <th>{t('main.tableHeaders.linkedDevice')}</th>}

                <th />
              </tr>
            </thead>

            <tbody>
              {residentsList.map(resident => {
                const roommates = resident.getIn(['unit', 'residents']).filter(r => r.get('id') !== resident.get('id'));

                return (
                  <tr key={resident.get('id')}>
                    <td className="first-column">
                      <div>{`${resident.getIn(['user', 'first_name'])} ${resident.getIn(['user', 'last_name'])} `}</div>
                      {property.get('enable_tracking') && (
                        <div style={{ fontSize: '12px', color: 'gray', marginTop: '5px' }}>
                          {resident.get('unique_device_id') ? t('main.registeredDevices') : ''}
                        </div>
                      )}
                    </td>
                    <td>
                      {userPermission.viewUnit ? (
                        <Link to={`/properties/${propertyId}/units/${resident.getIn(['unit', 'id'])}`}>
                          {resident.getIn(['unit', 'number'])}
                        </Link>
                      ) : (
                        <div style={{ fontWeight: 'bold' }}>{resident.getIn(['unit', 'number'])}</div>
                      )}
                    </td>
                    {property.get('admin_enabled_unit_automation') && (
                      <td>
                        <div className="active-state__wrapper">
                          {resident.getIn(['unit', 'panel_id']) ? (
                            <Icon className="active-state--active" icon="CircleSolid" />
                          ) : (
                            <Icon className="active-state--inactive" icon="CircleSolid" />
                          )}
                        </div>
                      </td>
                    )}
                    <td>
                      {roommates.size ? (
                        roommates
                          .sortBy(r => r.getIn(['user', 'first_name']).toLowerCase())
                          .map(r => {
                            return (
                              <div key={r.get('id')} className="table-units-list__resident">
                                {`${r.getIn(['user', 'first_name'])} ${r.getIn(['user', 'last_name'])}`}
                              </div>
                            );
                          })
                      ) : (
                        <div>{t('main.noRoommates')}</div>
                      )}
                    </td>
                    {property.get('enable_tracking') && (
                      <td>
                        {resident.get('device_switch_requested') && (
                          <div>
                            <button
                              style={{
                                margin: '5px',
                                backgroundColor: '#5AAD55',
                                color: 'white',
                                borderRadius: '4px',
                                border: '0px',
                                height: '1.5rem',
                                fontSize: '12px',
                                font: 'inherit',
                              }}
                              onClick={() =>
                                this.props.acceptOrDeclineDeviceSwitch(resident.get('id'), true, this.props.propertyId)
                              }
                            >
                              {t('main.acceptDevice')}
                            </button>

                            <button
                              style={{
                                margin: '5px',
                                backgroundColor: '#EB4444',
                                color: 'white',
                                borderRadius: '4px',
                                border: '0px',
                                height: '1.5rem',
                                fontSize: '12px',
                                font: 'inherit',
                              }}
                              onClick={() =>
                                this.props.acceptOrDeclineDeviceSwitch(resident.get('id'), false, this.props.propertyId)
                              }
                            >
                              {t('main.rejectDevice')}
                            </button>
                          </div>
                        )}
                      </td>
                    )}
                    <td className="table__align-right last-column">
                      {/*{userPermission.editResidentPersonalDetails && (*/}
                      <Link
                        className="link--edit"
                        to={`/properties/${propertyId}/units/${resident.getIn(['unit', 'id'])}/residents/${resident.get(
                          'id'
                        )}/edit${tabIndexStr}`}
                      >
                        <Icon icon="Edit" />
                      </Link>
                      {/*)}*/}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  async setAllResidents() {
    this.setState({
      allResidents: await this.props.getAllResidents(this.props.propertyId).then(function(results) {
        return results.json;
      }),
    });
  }
  render() {
    const { loading, history, location, residentsList, residentsListTotal, property, t } = this.props;

    const locationObj = querystring.parse(location.search);

    if (loading) {
      return <Loading />;
    }

    const registeredDevices = this.state.allResidents.reduce((counter, resident) => {
      if (resident.unique_device_id) {
        return counter + 1;
      }

      return counter;
    }, 0);

    return (
      <div>
        <PropertyPageHeader>
          <SearchText name="q" placeHolder={t('main.placeholder')} />
          {locationObj.q && (
            <button type="button" className="button" onClick={this.onClickClearSearch} style={{ marginTop: '10px' }}>
              {t('main.clearSearch')}
            </button>
          )}
        </PropertyPageHeader>
        <div className="container pm-units__content">
          <div className="properties-table__title">
            <div className="page-header__title" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <h4 className="h4" style={{ marginLeft: '12px' }}>
                {`${residentsListTotal} ${residentsListTotal !== 1 ? t('main.residents') : t('main.resident')}`}
              </h4>
              {property.get('enable_tracking') && (
                <h6 className="h6" style={{ color: 'gray', fontSize: '12px', marginTop: '6px' }}>{`${t(
                  'main.registeredDevices'
                )}: ${registeredDevices}`}</h6>
              )}
              {property.get('enable_tracking') && (
                <h6 className="h6" style={{ color: 'gray', fontSize: '12px', marginTop: '6px' }}>{`${t(
                  'main.unregisteredDevices'
                )}: ${residentsListTotal - registeredDevices}`}</h6>
              )}
            </div>
          </div>
        </div>
        {residentsList.size > 0 ? this.renderTable() : this.renderPlaceholder()}
        {residentsList.size > 0 && !loading ? (
          <Pager
            history={history}
            initialPage={parseInt(locationObj.page, 10) || 0}
            location={location}
            pageSize={parseInt(locationObj.page_size, 10) || 10}
            totalItems={residentsListTotal}
          />
        ) : null}
      </div>
    );
  }
}

export default ResidentList;
