import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import InputScaffold from '../../common/forms/InputScaffold';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

class CreateDemoForm extends Component {
  handleDateChange = (setFieldValue, date) => {
    setFieldValue('destruction_date', date);
  };

  render() {
    const { initialValues, onCancel, submitError, submitFunc, validationSchema } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          submitFunc(values, actions);
        }}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          validateForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="paper radius-top-left radius-top-right">
              <div className="property-details--form">
                {submitError && (
                  <div className="form-error__wrapper">
                    <span className="form-error--label">Error:&nbsp;</span>
                    {submitError}
                  </div>
                )}
                <InputScaffold label="Name" required validation={touched.name && errors.name}>
                  <input type="text" name="name" maxLength="64" onChange={handleChange} value={values.name} />
                </InputScaffold>

                <InputScaffold
                  label="Destruction Date"
                  required
                  validation={touched.destruction_date && errors.destruction_date}
                >
                  <DayPickerInput
                    inputProps={{
                      autoComplete: 'false',
                    }}
                    name="destruction_date"
                    value={values.destruction_date ? values.destruction_date : ''}
                    placeholder="Destruction Date"
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      modifiers: { disabled: [{ before: new Date() }] },
                    }}
                    onDayChange={this.handleDateChange.bind(this, setFieldValue)}
                  />
                </InputScaffold>
              </div>
            </div>

            <div className="paper__footer radius-bottom-left radius-bottom-right">
              <button disabled={isSubmitting} className="button">
                Create Demo Property
              </button>
              <button type="button" className="button button--secondary" onClick={onCancel}>
                Cancel
              </button>
            </div>
          </form>
        )}
      />
    );
  }
}

CreateDemoForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  submitError: PropTypes.string,
  submitFunc: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default CreateDemoForm;
