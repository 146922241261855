import Yup from 'yup';

const amenityCloseFields = {
  announcement_title: Yup.string()
    .trim()
    .max(120, 'Title must be less than 120 characters.'),
  announcement_message: Yup.string()
    .trim()
    .max(2000, 'Message must be less than 2000 characters.')
    .when('announcement_title', {
      is: val => !!val,
      then: Yup.string()
        .required('Announcement message must be provided.')
        .trim()
        .max(2000, 'Message must be less than 2000 characters.'),
    }),
};

export const closeAmenityValidationSchema = Yup.object().shape(amenityCloseFields);
