import React from 'react';

function Money() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M10 22V20H6V16H14V14H8C7.46957 14 6.96086 13.7893 6.58579 13.4142C6.21071 13.0391 6 12.5304 6 12V5.99997C6 5.46954 6.21071 4.96083 6.58579 4.58576C6.96086 4.21068 7.46957 3.99997 8 3.99997H10V1.99997H14V3.99997H18V7.99997H10V9.99997H16C16.5304 9.99997 17.0391 10.2107 17.4142 10.5858C17.7893 10.9608 18 11.4695 18 12V18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20H14V22H10Z" />
    </svg>
  );
  /* eslint-enable */
}

export default Money;
