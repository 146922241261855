import React, { Component } from 'react';
import UploadField from '../../../../../../../../common/forms/UploadField';
import NotificationStatusIndicator from '../../../../../../../../common/forms/NotificationStatusIndicator';

class UploadModel extends Component {
  state = {
    file: null,
    error: false,
  };

  fileSelected = file => {
    this.setState({ file });
  };

  uploadCsv = () => {
    const { onSubmit } = this.props;
    const res = onSubmit(this.state.file);
    if (res) {
      res.then(json => {
        this.setState({
          error: json.message,
        });
      });
    }
  };

  renderStatus() {
    return (
      <div>
        <NotificationStatusIndicator
          hideNotification={!this.state.error}
          message={this.state.error}
          type="Failure"
          updateFunc={() => this.setState({ error: false })}
        />
      </div>
    );
  }

  onCancel = () => {
    const { onCancel } = this.props;
    this.setState({
      file: null,
      error: false,
    });
    onCancel();
  };

  render() {
    const { visible, t } = this.props;

    if (!visible) return null;

    return (
      <div className="modal modal--visible">
        <div className="modal__children">
          <div className="modal__header">
            <h3>{t('guestRegistrationTab.importCsvModal.title')}</h3>
          </div>
          <div className="modal__body">
            <div className="modal__body--text">
              <div className="paper radius-top-left radius-top-right guest-registeration-uploadfile">
                <h4 className="h4" style={{ marginBottom: 24 }}>
                  {t('guestRegistrationTab.importCsvModal.label')}
                </h4>
                <UploadField onChange={this.fileSelected} />
                <div class="guest-registeration_csv_import_tip">
                  {t('guestRegistrationTab.importCsvModal.helperText')}
                </div>
                {this.renderStatus()}
              </div>
            </div>
          </div>
          <div className="modal__button--container">
            <button className="button" type="button" disabled={this.state.file ? false : true} onClick={this.uploadCsv}>
              {t('guestRegistrationTab.importCsvModal.submit')}
            </button>
            <button className="button button--secondary" type="button" onClick={this.onCancel}>
              {t('cancel', { ns: 'buttons' })}
            </button>
          </div>
        </div>
        <button type="button" className="modal__overlay" onClick={this.onCancel} />
      </div>
    );
  }
}

export default UploadModel;
