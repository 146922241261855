import { useEffect, useState } from 'react';

export const useResidentRevokeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isConfirmRevoke, setIsConfirmRevoke] = useState(false);

  useEffect(() => {
    if (!isOpen && isConfirmRevoke) {
      setIsConfirmRevoke(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    isOpen,
    message,
    handleChangeMessage: e => setMessage(e.target.value),
    handleCancel: () => setIsOpen(false),
    handleOpen: () => setIsOpen(true),
    handleConfirmRevoke: type => setIsConfirmRevoke(type),
    isConfirmRevoke,
  };
};
