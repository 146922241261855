import { connect } from 'react-redux';
import { getTelephoneEntryDevices } from '../../../../../../redux/actions';
import TelephoneEntryDevicesList from './TelephoneEntryDevicesList';
import { getTelephoneEntryDeviceTokens, getProperties, updateVideoRooms } from '../../../../../../redux/actions';
import { selectPropertyFilterOptions } from '../../../../../../redux/selectors';
import { getUserPermission } from '../../../../../../redux/selectors';

function mapStateToProps(state, props) {
  return {
    telephoneEntryDevices: state.telephoneEntryDevices,
    telephoneEntryDevicesLoaded: state.telephoneEntryDevicesLoaded,
    telephoneEntryDevicesCount: state.telephoneEntryDevicesCount,
    propertyOptions: selectPropertyFilterOptions(state, props),
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTelephoneEntryDevices: query => dispatch(getTelephoneEntryDevices(query)),
    getTelephoneEntryDeviceTokens: () => dispatch(getTelephoneEntryDeviceTokens()),
    getProperties: () => dispatch(getProperties()),
    updateVideoRooms: () => dispatch(updateVideoRooms()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TelephoneEntryDevicesList);
