import moment from 'moment';
import Yup from 'yup';

const validCharacters = /^[A-Za-z А-Яа-я 0-9? . ]+$/;
const leadingZeros = /^0[0-9].*$/;

const individualDayValidation = t =>
  Yup.object()
    .test('is-valid', t('errors.amenityAvailabilityFields.closingTimes'), value => {
      let res = true;
      if (!value) return res;
      let checkIndividualDays = moment(value.open).format('LTS') === moment(value.close).format('LTS');
      if (checkIndividualDays || moment(value.close).isBefore(moment(value.open))) {
        res = false;
      }
      return res;
    })
    .test('is-valid', 'Please select an opening and closing time for each day', value => {
      let res = true;
      if (!value) return res;
      if (!value.open || !value.close) {
        res = false;
      }
      return res;
    });

const amenityDetailsFields = t => ({
  name: Yup.string()
    .trim()
    .matches(validCharacters, 'Please use only uppercase or lowercase letters (A-Z) or numbers (0-9)')
    .max(64, 'Name must be 64 characters or fewer')
    .required(t('errors.amenityDetailsFields.enterTitle')),
  description: Yup.string()
    .trim()
    .max(800, 'Description must be 800 characters or fewer')
    .required(t('errors.amenityDetailsFields.enterDescription')),
  terms_of_use: Yup.string()
    .trim()
    .max(2000, 'Terms of use must be 2000 characters or fewer'),
  max_occupancy: Yup.string()
    .min(0, 'Please enter a number between 0-1000')
    .max(1000, 'Please enter a number between 0-1000')
    .test('no-leading-zero', 'Please remove leading zeros', function(value) {
      return !leadingZeros.test(value);
    }),
});

const amenityAvailabilityFields = t => ({
  availability: Yup.string()
    .trim()
    .required('Amenity availibity is a required field and must be set.')
    .test('is-valid', 'Invalid availability selection.  Please select one of the following options.', value =>
      ['NONE', '24/7', 'DAILY', 'CUSTOM'].includes(value)
    ),
  availability_schedule: Yup.object()
    .nullable()
    .when('availability', {
      is: val => val === 'CUSTOM',
      then: Yup.object()
        .shape({
          monday: individualDayValidation(t),
          tuesday: individualDayValidation(t),
          wednesday: individualDayValidation(t),
          thursday: individualDayValidation(t),
          friday: individualDayValidation(t),
          saturday: individualDayValidation(t),
          sunday: individualDayValidation(t),
        })
        .test('is-valid', t('errors.amenityAvailabilityFields.selectDays'), value => (value = {}) =>
          Object.keys(value).length
        ),
    })
    .when('availability', {
      is: val => val === 'DAILY',
      then: Yup.object()
        .test('is-valid', t('errors.amenityAvailabilityFields.closingTimes'), value => {
          let res = true;
          if (!value) return res;
          let checkSameTime = moment(value.monday?.open).format('LTS') === moment(value.monday?.close).format('LTS');
          if (checkSameTime || moment(value.monday?.close).isBefore(moment(value.monday?.open))) {
            res = false;
          }
          return res;
        })
        .test('is-valid', 'Please select an opening and closing time for each day', value => {
          let res = true;
          if (!value) return res;
          if (!value.monday?.open || !value.monday?.close) {
            res = false;
          }
          return res;
        }),
    }),
});

const amenityReservationTypeFields = t => ({
  reservation_type: Yup.string()
    .trim()
    .required('A reservation type is required.')
    .test('is-valid', 'Invalid reservation type selection.  Please select one of the following options.', value =>
      ['NON_RESERVABLE', 'FREE', 'PAID'].includes(value)
    ),
  min_reservation: Yup.number()
    .nullable()
    .when('reservation_type', {
      is: val => ['FREE', 'PAID'].includes(val),
      then: Yup.number()
        .min(15)
        .required(t('errors.amenityReservationTypeFields.minimumReservation')),
    }),
  max_reservation: Yup.number()
    .nullable()
    .when('reservation_type', {
      is: val => ['FREE', 'PAID'].includes(val),
      then: Yup.number()
        .min(15)
        .required(t('errors.amenityReservationTypeFields.maximumReservation'))
        .test('is-valid', 'Maximum reservation length must be equal to or longer than the minimum.', function(value) {
          return value >= this.parent.min_reservation;
        }),
    }),
  cancelation_policy: Yup.string()
    .nullable()
    .when('reservation_type', {
      is: val => ['FREE', 'PAID'].includes(val),
      then: Yup.string()
        .max(2000)
        .required(t('errors.amenityReservationTypeFields.cancelationPolicyMustBeSet')),
    }),
  refund_policy: Yup.string()
    .nullable()
    .when('reservation_type', {
      is: val => ['PAID'].includes(val),
      then: Yup.string()
        .max(2000)
        .required(t('errors.amenityReservationTypeFields.refundPolicyMustBeSet')),
    }),
  price: Yup.string()
    .nullable()
    .test('no-leading-zero', 'Please remove leading zeros', function(value) {
      return !leadingZeros.test(value);
    })
    .when('reservation_type', {
      is: val => ['PAID'].includes(val),
      then: Yup.string().required(t('errors.amenityReservationTypeFields.pricePerTimeIncrement')),
    }),
  refund_cutoff_time: Yup.string()
    .nullable()
    .test('no-leading-zero', 'Please remove leading zeros', function(value) {
      return !leadingZeros.test(value);
    }),
  cancel_fee_percent: Yup.string()
    .nullable()
    .test('no-leading-zero', 'Please remove leading zeros', function(value) {
      return !leadingZeros.test(value);
    })
    .when('reservation_type', {
      is: val => ['PAID'].includes(val),
      then: Yup.string().required(t('errors.amenityReservationTypeFields.cancelationFeeProviding')),
    }),
});
export const amenityDetailsValidationSchema = t => Yup.object().shape(amenityDetailsFields(t));
export const amenityAvailabilityValidationSchema = t => Yup.object().shape(amenityAvailabilityFields(t));
export const amenityReservationTypeValidationSchema = t => Yup.object().shape(amenityReservationTypeFields(t));
export const addAmenityValidationSchema = t =>
  Yup.object().shape({
    ...amenityDetailsFields(t),
    ...amenityAvailabilityFields(t),
    ...amenityReservationTypeFields(t),
  });
