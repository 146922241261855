import { connect } from 'react-redux';
import Community from './Community';
import { getUserPermission } from '../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    isAmenitiesV2Enabled: state.featureFlags.get('amenities_v2'),
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
    userPermissionList: getUserPermission(state),
  };
}

const CommunityContainer = connect(mapStateToProps)(Community);

export default CommunityContainer;
