import React from 'react';
import ArrowLongRight from '../../../../../../../../common/icons/icon';
import RoboticArm from '../../../../../../../../common/icons/RoboticArm';
import United from '../../../../../../../../common/icons/United';
import { Link } from 'react-router-dom';
import { ToursSelectorCard } from './views/ToursSelectorCard';
import { SGBtnBack, SGPageHeader } from '../../common';

export const SGToursSelector = ({ location }) => (
  <>
    <SGPageHeader topRow={<SGBtnBack btnTitle="Return to previous page" />} />
    <div className="container tours-selector m-b50">
      <div className="paper radius-top-left radius-top-right">
        <section className="paper__section resident-app-features tours-selector-container">
          <h1 className="h1 text-center strong m-b40">How would you like your tours set up?</h1>

          <div className="tour-cards-container">
            <ToursSelectorCard>
              <div className="tour-card-img">
                <RoboticArm />
              </div>
              <h1 className="tour-card-title h1 m-t15 text-center strong">HANDS OFF</h1>
              <p className="tour-subtitle text-center">No staff need to manage tours</p>
              <p className="tour-subtitle m-t30 text-center">What happens:</p>
              <ol className="tour-list">
                <li>
                  <span>1.</span> System approves background checks
                </li>
                <li>
                  <span>2.</span> Access is given automatically
                </li>
                <li>
                  <span>3.</span> Prospective resident has access to all model units
                </li>
              </ol>
              <p className="tour-subtitle text-center m-t40">Staff doesn’t have to:</p>
              <p className="text-center">Approve tours, grant access, and assign units to prospective residents.</p>
              <Link className="button button--icon m-tb30 self-guided_button" to={`${location.pathname}/automated`}>
                <div className="button--children">
                  <span>Configure self-guided tour</span>
                  <ArrowLongRight icon="ArrowLongRight" />
                </div>
              </Link>
            </ToursSelectorCard>

            <div className="tour-or-box">OR</div>

            <ToursSelectorCard>
              <div className="tour-card-img">
                <United />
              </div>
              <h1 className="tour-card-title h1 m-t15 text-center strong">HANDS ON</h1>
              <p className="tour-subtitle text-center">Staff needed to manage tours</p>
              <p className="tour-subtitle m-t30 text-center">What happens:</p>
              <ol className="tour-list">
                <li>
                  <span>1.</span> System notifies management of new tour
                </li>
                <li>
                  <span>2.</span> Staff views and approves background checks
                </li>
                <li>
                  <span>3.</span> Staff assigns units to tour for each prospective resident
                </li>
              </ol>
              <p className="tour-subtitle text-center m-t40">Prospective resident has:</p>
              <p className="text-center">More engagement with staff members and a more custom tour experience.</p>
              <Link className="button button--icon m-tb30 self-guided_button" to={`${location.pathname}/no-automated`}>
                <div className="button--children">
                  <span>Configure self-guided tour</span>
                  <ArrowLongRight icon="ArrowLongRight" />
                </div>
              </Link>
            </ToursSelectorCard>
          </div>
        </section>
      </div>
    </div>
  </>
);
