import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import querystring from 'querystring';
import client from '../../../../../../common/client';

export const USE_GET_VENDORS = 'getVendors';
export const USE_EVENT_LIST_KEY = 'eventList';
export const USE_EVENT_BY_ID_KEY = 'eventById';
export const USE_EVENT_REGISTRATION_LIST_KEY = 'eventRegistrationList';
export const USE_EVENT_REVENUE_DETAILS_KEY = 'eventRevenueDetails';
export const USE_EVENT_WAIT_LIST_KEY = 'eventWaitList';

export const useEventList = communityId => {
  return useQuery(
    [USE_EVENT_LIST_KEY, communityId],
    async () => {
      const queryParams = `?${querystring.stringify({ community_id: communityId })}`;
      const response = await client.get(`events${queryParams}`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      staleTime: 30000,
      enabled: typeof communityId === 'string',
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );
};

export const useRefetchEventList = communityId => {
  const client = useQueryClient();
  return () => client.refetchQueries([USE_EVENT_LIST_KEY, communityId]);
};

export const useEventRegistrationList = eventId => {
  return useQuery(
    [USE_EVENT_REGISTRATION_LIST_KEY, eventId],
    async () => {
      //   const response = await client.get(`events/registrants/${eventId}`);
      const response = await client.get(`events/registrants-attendees-list/${eventId}`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      enabled: typeof eventId === 'string',
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
    }
  );
};

export const useRefetchEventRegistrationList = eventId => {
  const client = useQueryClient();
  return () => client.refetchQueries([USE_EVENT_REGISTRATION_LIST_KEY, eventId]);
};

export const useEventWaitList = eventId => {
  return useQuery(
    [USE_EVENT_WAIT_LIST_KEY, eventId],
    async () => {
      const response = await client.get(`events/registrants-wait-list/${eventId}`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      enabled: typeof eventId === 'string',
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
    }
  );
};

export const useRefetchEventWaitList = eventId => {
  const client = useQueryClient();
  return () => client.refetchQueries([USE_EVENT_WAIT_LIST_KEY, eventId]);
};

export const useEventById = eventId => {
  return useQuery(
    [USE_EVENT_BY_ID_KEY, eventId],
    async () => {
      const response = await client.get(`events/${eventId}`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      staleTime: 30000,
      enabled: typeof eventId === 'string',
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};

export const useRefetchEventById = eventId => {
  const client = useQueryClient();
  return () => client.refetchQueries([USE_EVENT_BY_ID_KEY, eventId]);
};

export const useEventRevenueDetails = eventId => {
  return useQuery(
    [USE_EVENT_REVENUE_DETAILS_KEY, eventId],
    async () => {
      const response = await client.get(`events/${eventId}/revenue-details`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      staleTime: 30000,
      enabled: typeof eventId === 'string',
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};

export const useGetVendors = communityId => {
  return useQuery(
    [USE_GET_VENDORS, communityId],
    async () => {
      const response = await client.get(`community-management/community/${communityId}/vendors`);
      if (!response.ok) {
        const json = await response.json();
        throw new Error(json.message);
      }
      return response.json();
    },
    {
      staleTime: 30000,
      enabled: typeof communityId === 'string',
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};

export const useCreateEvent = communityId => {
  return useMutation(async formValues => {
    const response = await client.post(`events`, {
      ...formValues,
      community_id: communityId,
    });

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useUpdateEvent = eventId => {
  return useMutation(async formValues => {
    const response = await client.patch(`events/${eventId}`, formValues);

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useCancelEvent = eventId => {
  return useMutation(async formValues => {
    const response = await client.post(`events/${eventId}/cancel`, formValues);
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }
    return response.json();
  });
};

export const useCancelRegistration = (eventId, residentId) => {
  return useMutation(async () => {
    const response = await client.post(`events/${eventId}/cancel-registration`, { resident_id: residentId });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }
    return response.json();
  });
};

export const useCancelWaitList = (eventId, residentId) => {
  return useMutation(async () => {
    const response = await client.post(`events/${eventId}/cancel-waitlist`, { resident_id: residentId });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }
    return response.json();
  });
};

export const useNavigateToEditPage = id => {
  const history = useHistory();
  const { propertyId } = useParams();

  return useCallback(() => {
    history.push(`/properties/${propertyId}/community/events/${id}`);
  }, [id, propertyId, history]);
};

export const useNavigateToVendorEventDetailsPage = id => {
  const history = useHistory();

  return useCallback(() => {
    history.push(`events/${id}`);
  }, [id, history]);
};

export const useNavigateToEventListPage = () => {
  const history = useHistory();
  const { propertyId } = useParams();

  return useCallback(() => {
    history.push(`/properties/${propertyId}/community/events`);
  }, [propertyId, history]);
};

export const useCancelEventSuccessFromEditPage = eventTitle => {
  const history = useHistory();
  const { propertyId } = useParams();

  return useCallback(() => {
    history.push({
      pathname: `/properties/${propertyId}/community/events`,
      state: { canceledEventTitle: eventTitle },
    });
  }, [eventTitle, propertyId, history]);
};

export const useNavigateToEventListPageIfEventIsCanceled = isCanceled => {
  const navigateToEventListPage = useNavigateToEventListPage();
  return useEffect(() => {
    if (isCanceled) {
      navigateToEventListPage();
    }
  }, [isCanceled, navigateToEventListPage]);
};

export const useSendEventCommunication = eventId => {
  return useMutation(async formValues => {
    const response = await client.post(`events/${eventId}/communication`, {
      ...formValues,
      event_id: eventId,
    });

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }

    return response.json();
  });
};

export const useMoveToTop = () => {
  return useMutation(async register_id => {
    const response = await client.patch(`events/registrantsToTop/${register_id}`);
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.message);
    }
    return response.json();
  });
};
