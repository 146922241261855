import { connect } from 'react-redux';
import { addVendor } from '../../../../../../redux/actions';
import AddVendor from './AddVendor';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addVendor: (communityId, name, email) => dispatch(addVendor(communityId, name, email)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    //getMessages: () => dispatchProps.getMessages(stateProps.property.get('id')),
  });
}

const AddVendorContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddVendor);

export default AddVendorContainer;
