export default {
  'Discussion Board': 'Discussion Board',
  Posts: 'Posts',
  Post: 'Post',
  HEADLINE: 'HEADLINE',
  TYPE: 'TYPE',
  AUTHOR: 'AUTHOR',
  'POSTED DATE': 'POSTED DATE',
  'Filter by type': 'Filter by type',
  All: 'All',
  General: 'General',
  'For Sale': 'For Sale',
  Event: 'Event',
  Recommendation: 'Recommendation',
  'Recommendation Type': 'Recommendation Type',
  'Download CSV': 'Download CSV',
  'Add Post': 'Add Post',
  Back: 'Back',
  'SELECT POST TYPE': 'SELECT POST TYPE',
  BODY: 'BODY',
  LINK: 'LINK',
  Price: 'Price',
  'PRICE (IN DOLLARS)': 'PRICE (IN DOLLARS)',
  Start: 'Start',
  'START DATE': 'START DATE',
  'START TIME': 'START TIME',
  End: 'End',
  'END DATE': 'END DATE',
  'END TIME': 'END TIME',
  LOCATION: 'LOCATION',
  Request: 'Request',
  Offer: 'Offer',
  'Upload Discussion Post Images': 'Upload Discussion Post Images',
  'Upload an image or multiple to show off this Discussion Post':
    'Upload an image or multiple to show off this Discussion Post',
  'Drop your files here, or click to browse': 'Drop your files here, or click to browse',
  'SUPPORTED FILES': 'SUPPORTED FILES',
  'PNG or JPG | 1MB or less': 'PNG or JPG | 1MB or less',
  Submit: 'Submit',
  'Crop your uploaded image': 'Crop your uploaded image',
  Confirm: 'Confirm',
  Cancel: 'Cancel',
  'Posted by': 'Posted by',
  Type: 'Type',
  Link: 'Link',
  Comments: 'Comments',
  'ADD A COMMENT': 'ADD A COMMENT',
  'Add a comment': 'Add a comment',
  'Comment text': 'Comment text',
  Comment: 'Comment',
  'MM/DD/YYYY, hh:mm:ss A': 'MM/DD/YYYY, hh:mm:ss A',
  'MMM D, YYYY [at] hh:mm A': 'MMM D, YYYY [at] hh:mm A',
  'MMM DD, YYYY': 'MMM DD, YYYY',
  'h:mm a': 'h:mm a',
};
