import client from '../../client';
import { useMutation } from 'react-query';

export const useCommunityAccessCodeMutations = (entity, accessCodeKey, setAccessCodeReduxAction, payload) => {
  const create = useCreateCommunityAccessCode();
  const remove = useRemoveCommunityAccessCode();
  const resetCode = useResetCommunityAccessCode();
  const sendReminder = useSendCommunityAccessCodeReminder();

  const resetMutations = () => {
    create.reset();
    remove.reset();
    resetCode.reset();
    sendReminder.reset();
  };

  return {
    error: create.error || remove.error || resetCode.error || sendReminder.error,
    isError: create.isError || remove.isError || resetCode.isError || sendReminder.isError,
    isLoading: create.isLoading || remove.isLoading || resetCode.isLoading || sendReminder.isLoading,
    isSuccess: create.isSuccess || remove.isSuccess || resetCode.isSuccess || sendReminder.isSuccess,

    create: () => {
      resetMutations();
      create.mutate(payload, {
        onSuccess: data => {
          setAccessCodeReduxAction(entity.get('id'), data);
        },
      });
    },
    remove: () => {
      resetMutations();
      remove.mutate(entity.get(accessCodeKey), {
        onSuccess: () => {
          setAccessCodeReduxAction(entity.get('id'), null);
        },
      });
    },
    resetCode: () => {
      resetMutations();
      resetCode.mutate(entity.get(accessCodeKey), {
        onSuccess: data => {
          setAccessCodeReduxAction(entity.get('id'), data);
        },
      });
    },
    sendReminder: () => {
      resetMutations();
      sendReminder.mutate(entity.get(accessCodeKey));
    },
  };
};

export const useCreateCommunityAccessCode = () => {
  return useMutation(async payload => {
    const response = await client.post(`community-access-codes`, payload);
    if (!response.ok) throw new Error('There was an error when attempting to create the code.');
    return response.json();
  });
};

const useRemoveCommunityAccessCode = () => {
  return useMutation(async communityAccessCodeId => {
    const response = await client.delete(`community-access-codes/${communityAccessCodeId}`);
    if (!response.ok) throw new Error('There was an error when attempting to remove the code.');
  });
};

const useResetCommunityAccessCode = () => {
  return useMutation(async communityAccessCodeId => {
    const response = await client.post(`community-access-codes/${communityAccessCodeId}/reset`);
    if (!response.ok) throw new Error('There was an error when attempting to reset the code.');
    return response.json();
  });
};

const useSendCommunityAccessCodeReminder = () => {
  return useMutation(async communityAccessCodeId => {
    const response = await client.post(`community-access-codes/${communityAccessCodeId}/reminder`);
    if (!response.ok) throw new Error('There was an error when attempting to send the reminder.');
  });
};
