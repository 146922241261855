import { fromJS } from 'immutable';

export const communityStripeIntegrationReducers = {
  communityStripeConnectAccount: (state = null, action) => {
    switch (action.type) {
      case 'GET_COMMUNITY_STRIPE_CONNECT_ACCOUNT_SUCCESS':
        return fromJS(action.json);
      case 'GET_COMMUNITY_STRIPE_CONNECT_ACCOUNT_FAILURE':
        return null;
      default:
        return state;
    }
  },
  communityStripeConnectAccountLoaded: (state = false, action) => {
    switch (action.type) {
      case 'GET_COMMUNITY_STRIPE_CONNECT_ACCOUNT_REQUEST':
        return false;
      case 'GET_COMMUNITY_STRIPE_CONNECT_ACCOUNT_SUCCESS':
      case 'GET_COMMUNITY_STRIPE_CONNECT_ACCOUNT_FAILURE':
        return true;
      default:
        return state;
    }
  },
  stripeAccountInfo: (state = null, action) => {
    switch (action.type) {
      case 'GET_STRIPE_ACCOUNT':
      case 'GET_STRIPE_ACCOUNT_SUCCESS':
        return fromJS(action.json);
      case 'GET_STRIPE_ACCOUNT_FAILURE':
        return null;
      default:
        return state;
    }
  },
  communityStripeConnectAccountLink: (state = null, action) => {
    switch (action.type) {
      case 'CREATE_COMMUNITY_STRIPE_CONNECT_ACCOUNT_SUCCESS':
        return fromJS(action.json);
      case 'CLEAR_STRIPE_ACCOUNT_LINK':
        return null;
      default:
        return state;
    }
  },
};
