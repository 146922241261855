import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import SpinnerLoading from '../../../../../../../common/Loading/SpinnerLoading';
import EmptyPlaceholder from '../../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../../common/Kebab/index';
import Icon from '../../../../../../../common/icons/icon';
import Delete from '../../../../../../../common/Delete';

class UnrecognizedSchedules extends Component {
  static propTypes = {
    accessControlPanelSchedules: PropTypes.instanceOf(List),
    accessControlPanelSchedulesLoaded: PropTypes.bool.isRequired,
    accessControlSchedulesLoaded: PropTypes.bool.isRequired,
    getPropertyPanelSchedules: PropTypes.func.isRequired,
    addSchedule: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const action = await this.props.getPropertyPanelSchedules();

    if (!action.response.ok) {
      this.setState({
        error: (action.json && action.json.message) || 'Unknown error occurred while getting panel devices',
      });
    }
  }

  state = {
    openKebabScheduleNumber: null,
    deleteConfirmationScheduleNumber: null,
    error: false,
  };

  onKebabClick = scheduleNumber => this.setState({ openKebabScheduleNumber: scheduleNumber });

  renderKebab(schedule) {
    const { addSchedule } = this.props;
    const { openKebabScheduleNumber } = this.state;

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(schedule.get('number'))}
          onDisengage={() => this.setState({ openKebabScheduleNumber: null })}
          menuOpened={schedule.get('number') === openKebabScheduleNumber}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={async () => {
                const action = await addSchedule({
                  name: schedule.get('name'),
                  dmp_schedule_number: schedule.get('number'),
                  // these values will be filled in serverside
                  begins_at: null,
                  ends_at: null,
                  is_temporary: false,
                  sun_open: null,
                  sun_close: null,
                  mon_open: null,
                  mon_close: null,
                  tue_open: null,
                  tue_close: null,
                  wed_open: null,
                  wed_close: null,
                  thu_open: null,
                  thu_close: null,
                  fri_open: null,
                  fri_close: null,
                  sat_open: null,
                  sat_close: null,
                });
                if (!action.response.ok) {
                  this.setState({
                    error: (action.json && action.json.message) || 'Unknown error occurred while adding panel schedule',
                  });
                }
              }}
            >
              <Icon icon="Add" />
              Import
            </div>
            <div
              className="kebab__menu--item"
              onClick={() =>
                this.setState({ deleteConfirmationScheduleNumber: schedule.get('number'), openKebabScheduleId: null })
              }
            >
              <Icon icon="Remove" />
              Delete
            </div>
          </div>
        </Kebab>
      </div>
    );
  }

  renderDeleteConfirmation = schedule => {
    const { removePanelSchedule } = this.props;

    return (
      <div>
        <Delete
          onCancel={() => this.setState({ deleteConfirmationScheduleNumber: null, openKebabScheduleId: null })}
          deleteFunc={async () => {
            const action = await removePanelSchedule(schedule.get('number'));
            if (!action.response.ok) {
              this.setState({
                error: (action.json && action.json.message) || 'Unknown error occurred while removing panel schedule',
              });
            }
          }}
          message={
            <div>
              Are you sure you want to <strong className="strong">remove this schedule</strong>?
            </div>
          }
        />
      </div>
    );
  };

  render() {
    const { accessControlPanelSchedules, accessControlPanelSchedulesLoaded, accessControlSchedulesLoaded } = this.props;
    const { deleteConfirmationScheduleNumber } = this.state;

    if (!accessControlPanelSchedulesLoaded || !accessControlSchedulesLoaded) {
      return <SpinnerLoading />;
    }

    return (
      <div>
        {accessControlPanelSchedules.size > 0 ? (
          <table className="table-units-list">
            <thead>
              <tr>
                <th>Name</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {accessControlPanelSchedules.map(schedule => (
                <tr key={schedule.get('number')}>
                  <td>{schedule.get('name')}</td>
                  {
                    <td className="table__align-right">
                      {deleteConfirmationScheduleNumber === schedule.get('number')
                        ? this.renderDeleteConfirmation(schedule)
                        : this.renderKebab(schedule)}
                    </td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyPlaceholder>All schedules have been imported.</EmptyPlaceholder>
        )}
      </div>
    );
  }
}

export default UnrecognizedSchedules;
