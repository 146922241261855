import React from 'react';

export const useSGSettingsHeaderTitles = (status, onDelete) => {
  const isTestFunctionShow = process.env.REACT_APP_ENV !== 'production';
  const title = status ? 'Self-Guided Tour Settings' : 'The Self-Guided Tours feature is disabled.';
  const subTitleText = status
    ? 'This is all your tour settings. You can adjust your units, time frames, and messages all from this page. Any changes you make will be reflected immediately to the tour form and to current potential residents.'
    : 'Prospective residents can’t register for self-guided tours. Any already-scheduled tours have been revoked.';
  const subTitle = (
    <>
      <span>{subTitleText}</span>
      {status && isTestFunctionShow && (
        <p>
          <span onClick={onDelete}>Delete tour testing function</span>
        </p>
      )}
    </>
  );

  return { title, subTitle };
};
