import { connect } from 'react-redux';
import Unit from './Unit';
import { getUnit, resetUnit } from './redux/actions';

function mapStateToProps(state) {
  return {
    loaded: state.property.unit.activeUnitLoaded,
    property: state.property.activeProperty,
    unit: state.property.unit.activeUnit,
    unitError: state.property.unit.activeUnitError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUnit: (propertyId, unitId) => dispatch(getUnit(propertyId, unitId)),
    resetUnit: () => dispatch(resetUnit()),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getUnit: () => dispatchProps.getUnit(ownProps.match.params.propertyId, ownProps.match.params.unitId),
  });
}

const UnitContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Unit);

export default UnitContainer;
