import { connect } from 'react-redux';
import {
  createPanelDevice,
  cancelPanelJobs,
  requestZwaveDevice,
  getJobStatus,
  getPanelDevices,
} from '../../../../redux/actions';
import AddZWaveDevice from './AddZWaveDevice';

function mapStateToProps(state, props) {
  return {
    panel: state.devices.panelDevices,
    recognizedDevices: state.devices.panelDevices.get('devices'),
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPanelDevices: () => dispatch(getPanelDevices(ownProps.match.params.propertyId, ownProps.match.params.panelId)),
    createPanelDevice: payload =>
      dispatch(createPanelDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    cancelPanelJobs: payload =>
      dispatch(cancelPanelJobs(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    requestZwaveDevice: payload =>
      dispatch(requestZwaveDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    getJobStatus: payload => {
      return dispatch(getJobStatus(ownProps.match.params.propertyId, payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddZWaveDevice);
