import { connect } from 'react-redux';
import ResidentsAndDevicesList from './ResidentsAndDevicesList';
import {
  getUserPermission,
  selectorIsAdmin,
  selectorIsPropertyManager,
} from '../../../../../../../../../../redux/selectors';
import {
  removeResident,
  updateLock,
  updateBarrier,
  updateLight,
  getThermostatLatest,
  getLockStatus,
  getLightStatus,
  getBarrierStatus,
  updateThermostat,
  getUnitSchedules,
  createDeviceSchedule,
  editDeviceSchedule,
  deleteDeviceSchedule,
  updateZoneStatusFromWS,
  submitPanelRequest,
  getVideoDoorbellDevice,
  editVideoDoorbellDeviceSettings,
} from '../../../../redux/actions';
import { resendInvite } from '../../../../../../../../redux/actions';

function mapStateToProps(state) {
  return {
    isVideoDoorbellsV2Enabled: state.featureFlags.get('video_doorbells_v2'),
    loaded: state.property.unit.activeUnitLoaded,
    property: state.property.activeProperty,
    unit: state.property.unit.activeUnit,
    isAdmin: selectorIsAdmin(state),
    isPropertyManager: selectorIsPropertyManager(state),
    userPermissionList: getUserPermission(state),
    userId: state.user.get('id'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { propertyId, unitId } = ownProps.match.params;
  return {
    getVideoDoorbellDevice: doorbellId => dispatch(getVideoDoorbellDevice(doorbellId)),
    editVideoDoorbellDeviceSettings: (doorbellId, payload) =>
      dispatch(editVideoDoorbellDeviceSettings(doorbellId, payload)),
    removeResident: userId => dispatch(removeResident(propertyId, unitId, userId)),
    resendInvite: userId => dispatch(resendInvite(propertyId, userId, 'resident', unitId)),
    updateLock: (propertyId, unitId, lockId, lockStatus) =>
      dispatch(updateLock(propertyId, unitId, lockId, lockStatus)),
    updateBarrier: (propertyId, unitId, barrierId, barrierStatus) =>
      dispatch(updateBarrier(propertyId, unitId, barrierId, barrierStatus)),
    getLockStatus: (propertyId, unitId, lockId) => dispatch(getLockStatus(propertyId, unitId, lockId)),
    getBarrierStatus: (propertyId, unitId, barrierId) => dispatch(getBarrierStatus(propertyId, unitId, barrierId)),
    updateLight: (propertyId, unitId, lightId, lightLevel) =>
      dispatch(updateLight(propertyId, unitId, lightId, lightLevel)),
    getLightStatus: (propertyId, unitId, lightId) => dispatch(getLightStatus(propertyId, unitId, lightId)),
    getThermostatLatest: (propertyId, unitId, thermostatId) =>
      dispatch(getThermostatLatest(propertyId, unitId, thermostatId)),
    updateThermostat: (propertyId, unitId, thermostatId, payload) =>
      dispatch(updateThermostat(propertyId, unitId, thermostatId, payload)),
    updateZoneStatusFromWS: data => dispatch(updateZoneStatusFromWS(data)),
    getUnitSchedules: () => dispatch(getUnitSchedules(propertyId, unitId)),
    createDeviceSchedule: (deviceId, payload) => dispatch(createDeviceSchedule(propertyId, unitId, deviceId, payload)),
    editDeviceSchedule: (scheduleId, payload) => dispatch(editDeviceSchedule(propertyId, unitId, scheduleId, payload)),
    deleteDeviceSchedule: scheduleId => dispatch(deleteDeviceSchedule(propertyId, unitId, scheduleId)),
    submitPanelRequest: payload => dispatch(submitPanelRequest(propertyId, unitId, payload)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    updateLock: (lockId, lockStatus) =>
      dispatchProps.updateLock(ownProps.match.params.propertyId, ownProps.match.params.unitId, lockId, lockStatus),
    updateBarrier: (barrierId, barrierStatus) =>
      dispatchProps.updateBarrier(
        ownProps.match.params.propertyId,
        ownProps.match.params.unitId,
        barrierId,
        barrierStatus
      ),
    getLockStatus: lockId =>
      dispatchProps.getLockStatus(ownProps.match.params.propertyId, ownProps.match.params.unitId, lockId),
    getVideoDoorbellDevice: doorbellId => dispatchProps.getVideoDoorbellDevice(doorbellId),
    editVideoDoorbellDeviceSettings: (doorbellId, payload) =>
      dispatchProps.editVideoDoorbellDeviceSettings(doorbellId, payload),
    getBarrierStatus: barrierId =>
      dispatchProps.getBarrierStatus(ownProps.match.params.propertyId, ownProps.match.params.unitId, barrierId),
    updateLight: (lightId, lightLevel) =>
      dispatchProps.updateLight(ownProps.match.params.propertyId, ownProps.match.params.unitId, lightId, lightLevel),
    getLightStatus: lightId =>
      dispatchProps.getLightStatus(ownProps.match.params.propertyId, ownProps.match.params.unitId, lightId),
    getThermostatLatest: thermostatId =>
      dispatchProps.getThermostatLatest(ownProps.match.params.propertyId, ownProps.match.params.unitId, thermostatId),
    updateThermostat: (thermostatId, payload) =>
      dispatchProps.updateThermostat(
        ownProps.match.params.propertyId,
        ownProps.match.params.unitId,
        thermostatId,
        payload
      ),
  });
}

const ResidentsAndDevicesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ResidentsAndDevicesList);

export default ResidentsAndDevicesListContainer;
