import i18n from '../i18n';

export function nearestMinutes(interval, someMoment) {
  if (!someMoment) {
    return someMoment;
  }

  const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment
    .clone()
    .minute(roundedMinutes)
    .second(0);
}

export const getDateTimeFormat = () => {
  return {
    date: i18n.language === 'en' ? 'MMMM D, YYYY' : 'D MMMM YYYY',
    time: i18n.language === 'en' ? 'h:mm a' : 'HH:mm',
  };
};
