import client from '../../../../../../../common/client';

export const getUnit = (propertyId, unitId) => {
  return {
    promise: client.get(`properties/${propertyId}/units/${unitId}`),
    type: 'GET_UNIT',
  };
};

export const resetUnit = () => {
  return {
    type: 'GET_UNIT_RESET',
  };
};

export const editUnit = (propertyId, unitId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/units/${unitId}`, payload),
    type: 'EDIT_UNIT',
  };
};

export const deleteUnit = (propertyId, unitId) => {
  return {
    promise: client.delete(`properties/${propertyId}/units/${unitId}`),
    type: 'DELETE_UNIT',
  };
};

export const addResident = (unitId, data) => {
  const payload = {
    unit_id: unitId,
    ...data,
  };

  return {
    promise: client.post(`community-management/residents`, payload),
    type: 'CREATE_RESIDENT',
  };
};

export const editResident = (residentId, payload) => {
  return {
    promise: client.put(`community-management/residents/${residentId}`, payload),
    type: 'EDIT_RESIDENT',
  };
};

export const updateResidentCommunityCredentials = (residentId, communityCredentials) => {
  return {
    promise: client.post(`community-access-control/community-credentials`, { residentId, communityCredentials }),
    type: 'UPDATE_RESIDENT_CREDENTIALS',
    residentId,
  };
};

export const removeResident = (propertyId, unitId, userId) => {
  return {
    promise: client.delete(`properties/${propertyId}/units/${unitId}/residents/${userId}`),
    type: 'REMOVE_RESIDENT',
    userId,
  };
};

export const updateLock = (propertyId, unitId, lockId, lockStatus) => {
  return {
    promise: client.post(`properties/${propertyId}/units/${unitId}/panel/devices/locks/${lockId}`, lockStatus),
    type: 'UPDATE_LOCK',
    meta: {
      deviceId: lockId,
    },
  };
};

export const updateBarrier = (propertyId, unitId, barrierId, barrierStatus) => {
  return {
    promise: client.post(`properties/${propertyId}/units/${unitId}/panel/devices/barriers/${barrierId}`, barrierStatus),
    type: 'UPDATE_BARRIER',
    meta: {
      deviceId: barrierId,
    },
  };
};

export const getLockStatus = (propertyId, unitId, lockId) => {
  return {
    promise: client.get(`properties/${propertyId}/units/${unitId}/panel/devices/locks/${lockId}`),
    type: 'UPDATE_LOCK',
    meta: { deviceId: lockId },
  };
};

export const getBarrierStatus = (propertyId, unitId, barrierId) => {
  return {
    promise: client.get(`properties/${propertyId}/units/${unitId}/panel/devices/barriers/${barrierId}`),
    type: 'UPDATE_BARRIER',
    meta: { deviceId: barrierId },
  };
};

export const updateLight = (propertyId, unitId, lightId, level) => {
  return {
    promise: client.post(`properties/${propertyId}/units/${unitId}/panel/devices/lights/${lightId}`, level),
    type: 'UPDATE_LIGHT',
    meta: {
      deviceId: lightId,
    },
  };
};

export const getLightStatus = (propertyId, unitId, lightId) => {
  return {
    promise: client.get(`properties/${propertyId}/units/${unitId}/panel/devices/lights/${lightId}`),
    type: 'UPDATE_LIGHT',
    meta: { deviceId: lightId },
  };
};

export const getThermostatLatest = (propertyId, unitId, thermostatId) => {
  return {
    promise: client.get(`properties/${propertyId}/units/${unitId}/panel/devices/thermostats/${thermostatId}`),
    type: 'GET_THERMOSTAT_LATEST',
    meta: { deviceId: thermostatId },
  };
};

export const updateThermostat = (propertyId, unitId, thermostatId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/units/${unitId}/panel/devices/thermostats/${thermostatId}`, payload),
    type: 'GET_THERMOSTAT_LATEST',
    meta: { deviceId: thermostatId },
  };
};

export const getUnitSchedules = (propertyId, unitId) => {
  return {
    promise: client.get(`properties/${propertyId}/units/${unitId}/schedules`),
    type: 'GET_UNIT_SCHEDULES',
  };
};

export const createDeviceSchedule = (propertyId, unitId, deviceId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/units/${unitId}/device/${deviceId}/schedules`, payload),
    type: 'CREATE_DEVICE_SCHEDULE',
  };
};

export const editDeviceSchedule = (propertyId, unitId, scheduleId, payload) => {
  return {
    promise: client.put(`properties/${propertyId}/units/${unitId}/schedules/${scheduleId}`, payload),
    type: 'EDIT_DEVICE_SCHEDULE',
  };
};
export const getVideoDoorbellDevice = doorbellId => {
  return {
    promise: client.get(`devices/doorbell/v1/${doorbellId}`),
    type: 'VDB_LOADING',
    meta: { doorbellId: doorbellId },
  };
};
export const editVideoDoorbellDeviceSettings = (doorbellId, payload) => {
  return {
    promise: client.put(`devices/doorbell/v2/${doorbellId}`, payload),
    type: 'EDIT_VIDEO_DOORBELL_DEVICE_SETTINGS',
    meta: { doorbellId: doorbellId },
  };
};
export const deleteDeviceSchedule = (propertyId, unitId, scheduleId) => {
  return {
    promise: client.delete(`properties/${propertyId}/units/${unitId}/schedules/${scheduleId}`),
    type: 'DELETE_DEVICE_SCHEDULE',
    meta: { scheduleId },
  };
};

export const updateZoneStatusFromWS = data => {
  return {
    type: 'WS_DEVICE_STATUS_UPDATED',
    meta: { deviceId: data.name, success: true },
    payload: data,
  };
};

export const submitPanelRequest = (propertyId, unitId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/units/${unitId}/panel-request`, payload),
    type: 'SUBMIT_PANEL_REQUEST',
  };
};

export const setUnitAccessCodeOnResident = (residentId, unitAccessCode) => {
  return {
    type: 'SET_UNIT_ACCESS_CODE_ON_RESIDENT',
    residentId,
    unitAccessCode,
  };
};

export const setCommunityAccessCodeOnResident = (residentId, communityAccessCode) => {
  return {
    type: 'SET_COMMUNITY_ACCESS_CODE_ON_RESIDENT',
    residentId,
    communityAccessCode,
  };
};
