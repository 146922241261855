import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';

import SpinnerLoading from '../../../../../../common/Loading/SpinnerLoading';
import PageHeader from '../../../../../../common/PageHeader';
import AccessPointForm from '../../components/AccessPointForm';

class EditAccessPoint extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    property: PropTypes.instanceOf(Map),
    accessControlDoors: PropTypes.instanceOf(List).isRequired,
    getAccessPoints: PropTypes.func.isRequired,
    hasMasterPanel: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.getAccessPoints();
    if (this.props.hasMasterPanel) {
      this.props.getDoors();
    }
  }

  handleSuccess = () => {
    this.props.history.push('./');
  };

  handleCancel = () => {
    this.props.history.push('./');
  };

  render() {
    const {
      editAccessPoint,
      accessControlDoors,
      accessControlDoorsLoaded,
      matchingAccessPoint,
      user,
      hasMasterPanel,
    } = this.props;

    const isLoaded = (!hasMasterPanel || accessControlDoorsLoaded) && matchingAccessPoint;

    return (
      <div>
        <PageHeader title="Profiles" white backLink="./" />
        <div className="container">
          {isLoaded ? (
            <AccessPointForm
              initialValues={{
                name: matchingAccessPoint.get('name'),
                is_nfc_required: matchingAccessPoint.get('is_nfc_required'),
                device_id: matchingAccessPoint.get('device_id'),
                door_id: matchingAccessPoint.get('door_id'),
              }}
              submitFunc={editAccessPoint}
              onCancel={this.handleCancel}
              onSuccess={this.handleSuccess}
              doors={accessControlDoors.toJS()}
              user={user.toJS()}
              enableAccessControlDoorInput={hasMasterPanel}
            />
          ) : (
            <SpinnerLoading />
          )}
        </div>
      </div>
    );
  }
}

export default EditAccessPoint;
