import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import { shouldUpdateReducer } from '@synapsestudios/fetch-client-goalie';
import { List, fromJS, Map } from 'immutable';
import property from '../views/Property/redux/reducers';
import RolesAndPermissions from '../views/RolesAndPermissions/redux/reducers';
import devices from '../views/Property/views/PropertyDetails/views/Devices/redux/reducers';
import vendorStripeConnect from '../views/VendorStripeSettings/redux/reducers';
// const ga = window.ga;

const user = (state = null, action) => {
  switch (action.type) {
    case 'GET_USER_SUCCESS':
      // ga('set', 'userId', action.json.id);
      return fromJS(action.json);
    default:
      return state;
  }
};

const roles = (state = List(), action) => {
  if (action.type === 'GET_USER_SUCCESS') {
    return fromJS(action.json.roles.map(obj => obj.role));
  }
  return state;
};

const featureFlags = (state = Map(), action) => {
  if (action.type === 'GET_FEATURE_FLAGS_SUCCESS') {
    return fromJS(action.json);
  }
  return state;
};

const offerList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_OFFERS_SEARCH_SUCCESS':
    case 'GET_OFFERS_SUCCESS':
      return fromJS(action.json.results);
    case 'EDIT_OFFER_SUCCESS':
      const index = state.findIndex(offer => offer.get('id') === action.json.id);
      return state.mergeIn([state, index], fromJS(action.json));
    case 'REMOVE_OFFER_SUCCESS':
      return state.filter(offer => offer.get('id') !== action.offerId);
    default:
      return state;
  }
};

const offersLoading = (state = true, action) => {
  switch (action.type) {
    case 'GET_OFFERS_SEARCH_REQUEST':
    case 'GET_OFFERS_REQUEST':
      return true;
    case 'GET_OFFERS_SEARCH_SUCCESS':
    case 'GET_OFFERS_SUCCESS':
    case 'GET_OFFERS_SEARCH_FAILURE':
    case 'GET_OFFERS_FAILURE':
      return false;
    default:
      return state;
  }
};

const offersTotal = (state = 0, action) => {
  switch (action.type) {
    case 'GET_OFFERS_SEARCH_SUCCESS':
    case 'GET_OFFERS_SUCCESS':
      return fromJS(action.json.total);
    case 'CREATE_OFFER_SUCCESS':
      return state + 1;
    case 'REMOVE_OFFER_SUCCESS':
      return state - 1;
    default:
      return state;
  }
};

const offersSearching = (state = false, action) => {
  switch (action.type) {
    case 'GET_OFFERS_SEARCH_SUCCESS':
      return true;
    case 'GET_OFFERS_SUCCESS':
      return false;
    default:
      return state;
  }
};

const activeOffer = (state = Map(), action) => {
  switch (action.type) {
    case 'GET_OFFER_SUCCESS':
      return fromJS(action.json);
    case 'EDIT_OFFER_SUCCESS':
      return state.merge(action.json);
    default:
      return state;
  }
};

const activeOfferLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_OFFER_REQUEST':
    case 'EDIT_OFFER_REQUEST':
    case 'GET_OFFER_FAILURE':
    case 'EDIT_OFFER_FAILURE':
      return false;
    case 'GET_OFFER_SUCCESS':
    case 'EDIT_OFFER_SUCCESS':
      return true;
    default:
      return state;
  }
};

const offerListLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_OFFERS_REQUEST':
      return true;
    case 'GET_OFFERS_SUCCESS':
    case 'GET_OFFERS_FAILURE':
      return false;
    default:
      return state;
  }
};

const properties = (state = List(), action) => {
  switch (action.type) {
    case 'GET_PROPERTIES_SEARCH_SUCCESS':
    case 'GET_PROPERTIES_SUCCESS':
      return fromJS(action.json.results);
    case 'EDIT_PROPERTY_SETTINGS_SUCCESS':
      const index = state.findIndex(property => property.get('id') === action.json.id);
      return state.mergeIn([state, index], fromJS(action.json));
    case 'CREATE_PROPERTY_SUCCESS':
      return state.push(fromJS(action.json));
    default:
      return state;
  }
};

const propertiesTotal = (state = 0, action) => {
  switch (action.type) {
    case 'GET_PROPERTIES_SEARCH_SUCCESS':
    case 'GET_PROPERTIES_SUCCESS':
      return fromJS(action.json.total);
    case 'CREATE_PROPERTY_SUCCESS':
      return state + 1;
    default:
      return state;
  }
};

const propertiesSearching = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTIES_SEARCH_SUCCESS':
      return true;
    case 'GET_PROPERTIES_SUCCESS':
      return false;
    default:
      return state;
  }
};

const getPropertiesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTIES_REQUEST':
      return false;
    case 'GET_PROPERTIES_SEARCH_SUCCESS':
    case 'GET_PROPERTIES_SUCCESS':
    case 'GET_PROPERTIES_FAILURE':
      return true;
    default:
      return state;
  }
};

const propertiesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_PROPERTIES_REQUEST':
      return false;
    case 'GET_PROPERTIES_SEARCH_SUCCESS':
    case 'GET_PROPERTIES_SUCCESS':
      return true;
    default:
      return state;
  }
};

const unpairedPropertyUnits = (state = null, action) => {
  switch (action.type) {
    case 'GET_UNPAIRED_PROPERTY_UNITS_REQUEST':
      return null;
    case 'GET_UNPAIRED_PROPERTY_UNITS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const selectedActivityLog = (state = List(), action) => {
  switch (action.type) {
    case 'GET_ACTIVITY_LOG_SUCCESS':
      return fromJS(action.json.results);
    default:
      return state;
  }
};

const activityLogTotal = (state = 0, action) => {
  switch (action.type) {
    case 'GET_ACTIVITY_LOG_SUCCESS':
      return fromJS(action.json.total);
    default:
      return state;
  }
};

const activityLogLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_ACTIVITY_LOG_REQUEST':
      return true;
    case 'GET_ACTIVITY_LOG_SUCCESS':
    case 'GET_ACTIVITY_LOG_FAILURE':
      return false;
    default:
      return state;
  }
};

const adminList = (state = List(), action) => {
  switch (action.type) {
    case 'GET_ADMINS_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_ADMIN_USER_SUCCESS':
      return state.push(fromJS(action.json));
    case 'REMOVE_ADMIN_SUCCESS':
      return state.filter(admin => admin.get('id') !== action.json.id);
    case 'EDIT_ADMIN_USER_SUCCESS':
      return state.set(
        state.findIndex(admin => admin.get('id') === action.json.id),
        fromJS(action.json)
      );
    default:
      return state;
  }
};

const adminListLoading = (state = false, action) => {
  switch (action.type) {
    case 'GET_ADMINS_REQUEST':
    case 'CREATE_ADMIN_USER':
    case 'REMOVE_ADMIN_REQUEST':
    case 'EDIT_ADMIN_USER_REQUEST':
      return true;
    case 'GET_ADMINS_SUCCESS':
    case 'CREATE_ADMIN_USER_SUCCESS':
    case 'REMOVE_ADMIN_SUCCESS':
    case 'EDIT_ADMIN_USER_SUCCESS':
    case 'GET_ADMINS_FAILURE':
    case 'CREATE_ADMIN_USER_FAILURE':
    case 'REMOVE_ADMIN_FAILURE':
    case 'EDIT_ADMIN_USER_FAILURE':
      return false;
    default:
      return state;
  }
};

const credentialTypes = (state = List(), action) => {
  switch (action.type) {
    case 'GET_CREDENTIAL_TYPES_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const credentialTypesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_CREDENTIAL_TYPES_REQUEST':
    case 'GET_CREDENTIAL_TYPES_FAILURE':
      return false;
    case 'GET_CREDENTIAL_TYPES_SUCCESS':
      return true;
    default:
      return state;
  }
};

const telephoneEntryDevices = (state = List(), action) => {
  switch (action.type) {
    case 'GET_TELEPHONE_ENTRY_DEVICES_SUCCESS':
      return fromJS(action.json.results);
    case 'ADD_TELEPHONE_ENTRY_DEVICE_SUCCESS':
      return state.push(fromJS(action.json));
    default:
      return state;
  }
};

const telephoneEntryDevicesCount = (state = 0, action) => {
  switch (action.type) {
    case 'GET_TELEPHONE_ENTRY_DEVICES_SUCCESS':
      return fromJS(action.json.total);
    default:
      return state;
  }
};

const telephoneEntryDevicesLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_TELEPHONE_ENTRY_DEVICES_REQUEST':
    case 'GET_TELEPHONE_ENTRY_DEVICES_FAILURE':
      return false;
    case 'GET_TELEPHONE_ENTRY_DEVICES_SUCCESS':
      return true;
    default:
      return state;
  }
};

const telephoneEntryDeviceAuthTokensLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_TELEPHONE_ENTRY_DEVICE_AUTH_TOKENS_REQUEST':
    case 'GET_TELEPHONE_ENTRY_DEVICE_AUTH_TOKENS_FAILURE':
      return false;
    case 'GET_TELEPHONE_ENTRY_DEVICE_AUTH_TOKENS_SUCCESS':
      return true;
    default:
      return state;
  }
};

const telephoneEntryDeviceAuthTokens = (state = List(), action) => {
  switch (action.type) {
    case 'GET_TELEPHONE_ENTRY_DEVICE_AUTH_TOKENS_SUCCESS':
      return fromJS(action.json);
    case 'ADD_TELEPHONE_ENTRY_DEVICE_AUTH_TOKEN_SUCCESS':
      return state.push(fromJS(action.json));
    case 'REMOVE_TELEPHONE_ENTRY_DEVICE_AUTH_TOKEN_SUCCESS':
      return state.filter(token => token.get('id') !== action.id);
    default:
      return state;
  }
};

const unpairedPropertyPanels = (state = List(), action) => {
  switch (action.type) {
    case 'GET_UNPAIRED_PROPERTY_PANELS_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

const unpairedPropertyPanelsLoaded = (state = false, action) => {
  switch (action.type) {
    case 'GET_UNPAIRED_PROPERTY_PANELS_REQUEST':
    case 'GET_UNPAIRED_PROPERTY_PANELS_FAILURE':
      return false;
    case 'GET_UNPAIRED_PROPERTY_PANELS_SUCCESS':
      return true;
    default:
      return state;
  }
};

const adminFinancials = (state = List(), action) => {
  switch (action.type) {
    case 'GET_ADMIN_FINANCIALS_SUCCESS':
      return fromJS(action.json);
    case 'CREATE_ADMIN_FINANCIAL_SUCCESS':
      return fromJS(action.json);
    case 'UPDATE_ADMIN_FINANCIAL_SUCCESS':
      return fromJS(action.json);
    default:
      return state;
  }
};

export default combineReducers({
  browser: createResponsiveStateReducer(),
  properties,
  propertiesLoaded,
  propertiesSearching,
  propertiesTotal,
  property,
  roles,
  user,
  featureFlags,
  offerList,
  offersTotal,
  offersLoading,
  offersSearching,
  offerListLoading,
  activeOffer,
  activeOfferLoaded,
  getPropertiesLoaded,
  selectedActivityLog,
  activityLogTotal,
  activityLogLoading,
  devices,
  unpairedPropertyUnits,
  shouldUpdate: shouldUpdateReducer,
  adminList,
  adminListLoading,
  credentialTypes,
  credentialTypesLoaded,
  telephoneEntryDevices,
  telephoneEntryDevicesLoaded,
  telephoneEntryDeviceAuthTokensLoaded,
  telephoneEntryDeviceAuthTokens,
  telephoneEntryDevicesCount,
  unpairedPropertyPanels,
  unpairedPropertyPanelsLoaded,
  adminFinancials,
  vendorStripeConnect,
  RolesAndPermissions,
});
