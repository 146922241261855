import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

class Sidebar extends Component {
  render() {
    const { children, className } = this.props;

    return <div className={classNames('sidebar__navigation', className)}>{children}</div>;
  }
}

Sidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Sidebar;
