import EditVacantUnits from './EditVacantUnits';
import { connect } from 'react-redux';
import { getVacantUnitAutomation, setVacantUnitAutomation } from '../../../../redux/actions';

const mapStateToProps = (state, ownProps) => ({
  propertyId: ownProps.match.params.propertyId,
  vacantUnitAutomationLoaded: state.property.vacantUnitAutomationLoaded,
  vacantUnitAutomation: state.property.vacantUnitAutomation,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setVacantUnitAutomation: payload => dispatch(setVacantUnitAutomation(ownProps.match.params.propertyId, payload)),
  getVacantUnitAutomation: () => dispatch(getVacantUnitAutomation(ownProps.match.params.propertyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditVacantUnits);
