/* eslint-disable no-restricted-globals */
import merge from './utils/merge';

const process_env = JSON.parse(process.env.REACT_APP_ENV_VARS || null) || process.env;

const {
  REACT_APP_CS_PROJECT,
  REACT_APP_CS_REPO,
  REACT_APP_CS_BRANCH,
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_RESIDENT_APP_URL,
  REACT_APP_OIDC_URL,
  REACT_APP_OIDC_CLIENT_ID,
  REACT_APP_GA_ID,
  REACT_APP_PUSHER_KEY,
  REACT_APP_PUSHER_CLUSTER,
  REACT_APP_TOUR_FORM_URL,
} = process_env;

const defaults = {
  auth: {
    clientId: REACT_APP_OIDC_CLIENT_ID,
    redirectUri: `${location.protocol}//${location.host}`,
    postLogoutRedirectUri: `${location.protocol}//${location.host}`,
    domain: REACT_APP_OIDC_URL,
    scope: 'openid email profile',
    responseType: 'code id_token token',
    idTokenKey: 'idToken',
    accessTokenKey: 'accessToken',
  },
  apiUrl: REACT_APP_API_URL,
  residentAppUrl: REACT_APP_RESIDENT_APP_URL,
  tourFormUrl: REACT_APP_TOUR_FORM_URL,
  gaData: {
    id: REACT_APP_GA_ID,
  },
  pusher: {
    key: REACT_APP_PUSHER_KEY,
    cluster: REACT_APP_PUSHER_CLUSTER,
  },
};

const MAX_LEN = 64 - 10;
const getQaUrl = app => {
  let qaUrlBase = `${REACT_APP_CS_PROJECT}-${REACT_APP_CS_REPO}`;
  let oidcBase = `${qaUrlBase}-${app}-${REACT_APP_CS_BRANCH}`;
  oidcBase = oidcBase.toLowerCase().substr(0, MAX_LEN);
  return oidcBase + '.synsit.es';
};

const environments = {
  development: {},
  qa: {
    apiUrl: `https://${getQaUrl('api')}/`,
    auth: {
      domain: `https://${getQaUrl('oidc')}`,
    },
  },
  staging: {},
  production: {},
};

export default merge(defaults, environments[REACT_APP_ENV]);
