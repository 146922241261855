import { useMemo, useState } from 'react';

const sortOptions = [
  { value: 0, label: 'Newest to oldest (default)' },
  { value: 1, label: 'Oldest to newest' },
  { value: 2, label: 'A to Z' },
  { value: 3, label: 'Z to A' },
];

export const useSortSearch = dataLoaded => {
  const [searchValue, setSearchValue] = useState('');
  const [sortOption, setSortOption] = useState(() => sortOptions[0]);

  const dataFiltered = useMemo(
    () =>
      dataLoaded?.filter(
        ({ first_name, last_name, email, phone }) =>
          first_name.toLowerCase().includes(searchValue.toLowerCase()) ||
          last_name.toLowerCase().includes(searchValue.toLowerCase()) ||
          email.toLowerCase().includes(searchValue.toLowerCase()) ||
          phone.includes(searchValue)
      ),
    [dataLoaded, searchValue]
  );

  const dataSorted = useMemo(() => {
    if (!dataFiltered) return;

    const sortersDictionary = {
      0: (a, b) => (b.created_at > a.created_at ? 1 : -1),
      1: (a, b) => (a.created_at > b.created_at ? 1 : -1),
      2: (a, b) => (a.first_name > b.first_name ? 1 : -1),
      3: (a, b) => (b.first_name > a.first_name ? 1 : -1),
    };

    const sorter = sortersDictionary[sortOption?.value];

    return sorter ? dataFiltered.sort(sortersDictionary[sortOption?.value]) : dataFiltered;
  }, [dataFiltered, sortOption]);

  const handleInputSearchChange = ({ target }) => {
    setSearchValue(target.value);
  };

  const handleInputClearValue = () => {
    setSearchValue('');
  };

  const handleChangeSortOption = option => {
    setSortOption(option);
  };

  return {
    searchValue,
    sortOption,
    sortOptions,
    handleInputSearchChange,
    handleInputClearValue,
    handleChangeSortOption,
    dataResult: dataSorted,
    total: dataSorted?.length,
  };
};
