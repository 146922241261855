import React, { Component } from 'react';
class CSVImportResultModel extends Component {
  render() {
    const { onCancel, visible, onVerify, importResultList, t } = this.props;

    if (!visible) return null;

    return (
      <div className="modal modal--visible">
        <div className="modal__children">
          <div className="modal__header">
            <h3>{t('guestRegistrationTab.importCsvResModal.title')}</h3>
          </div>
          <div className="modal__body">
            <div className="modal__body--text guestRegistration-csv-model-body">
              {importResultList.map(item => {
                let success_str = 'Succeeded';
                if (item.status !== 'Attendees' && item.status !== 'WaitList' && item.status !== 'NoSpot') {
                  success_str = 'Failed';
                }
                return (
                  <div className="csv_import_body_item">
                    <span className="csv_import_body_item_email">
                      {item.email} ({item.tickets})
                    </span>
                    <span className="csv_import_body_item_success">{success_str}</span>
                    <span className="csv_import_body_item_status">{item.status}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal__button--container">
            <button className="button" type="button" onClick={onVerify}>
              {t('verify', { ns: 'buttons' })}
            </button>
            <button className="button" type="button" onClick={onCancel}>
              {t('ok', { ns: 'buttons' })}
            </button>
          </div>
        </div>
        <button type="button" className="modal__overlay" onClick={onCancel} />
      </div>
    );
  }
}

export default CSVImportResultModel;
