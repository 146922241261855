import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MRIIntegrationForm from '../../../../../../common/forms/MRIIntegrationForm';
import {
  getMRIConfiguration,
  saveMRIConfiguration,
  testMRIConfiguration,
  updateMRIConfiguration,
} from '../../../../redux/actions';
import SubHeader from '../../../../../../common/SubHeader';
import Loading from '../../../../../../common/Loading';

export default function Integrations(props) {
  const dispatch = useDispatch();
  const community = useSelector(state => state.property.activeProperty);

  const mriConfiguration = useSelector(state => state.property.mriConfiguration);
  const mriConfigurationTestResult = useSelector(state => state.property.mriIntegrationTest);
  const mriConfigurationTestLoading = useSelector(state => state.property.mriIntegrationTestLoading);
  const mriConfigurationLoading = useSelector(state => state.property.mriIntegrationTestLoading);

  useEffect(() => {
    dispatch(getMRIConfiguration(community.get('id')));
  }, [dispatch, community]);

  return (
    <div className="container">
      <SubHeader title="Integrations" />
      {mriConfigurationLoading && <Loading />}
      {!mriConfigurationLoading && (
        <div>
          <MRIIntegrationForm
            community={community}
            configuration={mriConfiguration}
            saveMRIConfiguration={values => {
              values.community_id = community.get('id');
              if (!mriConfiguration) {
                dispatch(saveMRIConfiguration(community.get('id'), values));
              }
              dispatch(updateMRIConfiguration(community.get('id'), values));
            }}
            testMRIConfiguration={values => {
              dispatch(testMRIConfiguration(community.get('id'), values));
            }}
            testConfigurationResult={mriConfigurationTestResult}
          />
          {mriConfigurationTestLoading && (
            // DESIGN - loading indicator
            <p>Testing MRI Integration</p>
          )}
        </div>
      )}
    </div>
  );
}
