import React from 'react';
import { useSelector } from 'react-redux';

export const useTourTimePeriod = timePeriod => {
  const property = useSelector(state => state.property.activeProperty);
  const daysLimit = property.get('scheduled_unit_guest_max_duration');

  const tourTimePeriod = timePeriod.map(option => {
    const { number, type } = option;
    const isValidDuration = number * (type === 'day' ? 1 : 7) > daysLimit;

    return { ...option, disabled: isValidDuration };
  });

  const LimitInfo = tourTimePeriod.some(({ disabled }) => disabled) && (
    <p className="sg-times-days-limit-text margin-clear-all help-text">
      The maximum tour length is restricted in Settings &gt; Resident Feature{' '}
      <span className="strong">({daysLimit} days)</span>.
    </p>
  );

  return { tourTimePeriod, LimitInfo };
};
