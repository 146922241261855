import { connect } from 'react-redux';
import {
  createPanelDevice,
  createDMPDevice,
  getJobStatus,
  getPanelDevices,
  refreshPanelZones,
} from '../../../../redux/actions';
import { selectActiveProperty } from '../../../../../../../../../../redux/selectors';
import AddDMPDevice from './AddDMPDevice';

function mapStateToProps(state, props) {
  return {
    panel: state.devices.panelDevices,
    property: selectActiveProperty(state, props),
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPanelDevices: () => dispatch(getPanelDevices(ownProps.match.params.propertyId, ownProps.match.params.panelId)),
    refreshPanelZones: () =>
      dispatch(refreshPanelZones(ownProps.match.params.propertyId, ownProps.match.params.panelId)),
    createPanelDevice: payload =>
      dispatch(createPanelDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    createDMPDevice: payload =>
      dispatch(createDMPDevice(ownProps.match.params.propertyId, ownProps.match.params.panelId, payload)),
    getJobStatus: payload => {
      return dispatch(getJobStatus(ownProps.match.params.propertyId, payload));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDMPDevice);
