import { connect } from 'react-redux';

import { getProperty, resetProperty } from './redux/actions';
import Property from './Property';
import { selectorIsAdmin, getUserPermission } from '../../redux/selectors';
import { getTour } from './redux/actions/sgToursActions';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
    propertyCount: state.user.get('properties').size,
    isAdmin: selectorIsAdmin(state),
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProperty: propertyId => dispatch(getProperty(propertyId)),
    resetProperty: () => dispatch(resetProperty()),
    getTour: propertyId => dispatch(getTour(propertyId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getProperty: () => dispatchProps.getProperty(ownProps.match.params.propertyId),
    getTour: () => dispatchProps.getTour(ownProps.match.params.propertyId),
  });
}

const PropertyContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Property);

export default PropertyContainer;
