import React from 'react';
import { connect } from 'react-redux';
import { Tab, TabsContainer } from '../../../../common/Tabs';
import { Route, Switch } from 'react-router-dom';
import Roles from './Roles';
import Permissions from './Permissions/views/PermissionsList';
import User from './User';

const RolesAndPermissionsMain = ({ property, location, history, match }) => {
  let UserPermission = property;
  return (
    <div>
      <TabsContainer>
        {UserPermission.includes('VIEW_ROLE') && (
          <Tab
            label="Roles"
            isActive={location.pathname === match.url}
            onPress={() => {
              history.push(`${match.url}`);
            }}
          />
        )}
        {UserPermission.includes('VIEW_PERMISSION') && (
          <Tab
            label="Permissions"
            isActive={location.pathname === `${match.url}/permissions`}
            onPress={() => {
              history.push(`${match.url}/permissions`);
            }}
          />
        )}
        {UserPermission.includes('VIEW_USER') && (
          <Tab
            label="Users"
            isActive={location.pathname === `${match.url}/permissions/user`}
            onPress={() => {
              history.push(`${match.url}/permissions/user`);
            }}
          />
        )}
      </TabsContainer>
      <Switch>
        {UserPermission.includes('VIEW_ROLE') && <Route exact path={`${match.path}`} component={Roles} />}
        {UserPermission.includes('VIEW_PERMISSION') && (
          <Route exact path={`${match.path}permissions`} component={Permissions} />
        )}
        {UserPermission.includes('VIEW_USER') && (
          <Route exact path={`${match.path}permissions/user`} component={User} />
        )}
      </Switch>
    </div>
  );
};

export default connect(state => ({ property: state.user.get('permissions') }))(RolesAndPermissionsMain);
