import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import SpinnerLoading from '../../../../../../../common/Loading/SpinnerLoading';
import EmptyPlaceholder from '../../../../../../../common/EmptyPlaceholder';
import Kebab from '../../../../../../../common/Kebab/index';
import Icon from '../../../../../../../common/icons/icon';
import Delete from '../../../../../../../common/Delete';

class UnrecognizedDoors extends Component {
  static propTypes = {
    accessControlPanelDoors: PropTypes.instanceOf(List),
    accessControlPanelDoorsLoaded: PropTypes.bool.isRequired,
    accessControlDoorsLoaded: PropTypes.bool.isRequired,
    getPanelDoors: PropTypes.func.isRequired,
    addDoor: PropTypes.func.isRequired,
    removePanelDoor: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const action = await this.props.getPanelDoors();

    if (!action.response.ok) {
      this.setState({
        error: (action.json && action.json.message) || 'Unknown error occurred while getting panel devices',
      });
    }
  }

  state = {
    openKebabDoorNumber: null,
    deleteConfirmationDoorNumber: null,
    error: false,
  };

  onKebabClick = doorNumber => this.setState({ openKebabDoorNumber: doorNumber });

  renderKebab(door) {
    const { addDoor } = this.props;
    const { openKebabDoorNumber } = this.state;

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(door.get('number'))}
          onDisengage={() => this.setState({ openKebabDoorNumber: null })}
          menuOpened={door.get('number') === openKebabDoorNumber}
        >
          <div className="kebab__menu">
            <div
              className="kebab__menu--item"
              onClick={async () => {
                const action = await addDoor({
                  name: door.get('name'),
                  dmp_device_number: door.get('lx_number') || door.get('number'),
                  dmp_device_communication_type: door.get('comm_type'),
                  strike_delay: door.get('strike_dly'),
                  strike_time: door.get('strk_time'),
                  areas: [],
                });
                if (!action.response.ok) {
                  this.setState({
                    error: (action.json && action.json.message) || 'Unknown error occurred while adding panel device',
                  });
                }
              }}
            >
              <Icon icon="Add" />
              Import
            </div>
            <div
              className="kebab__menu--item"
              onClick={() => this.setState({ deleteConfirmationDoorNumber: door.get('number'), openKebabDoorId: null })}
            >
              <Icon icon="Remove" />
              Delete
            </div>
          </div>
        </Kebab>
      </div>
    );
  }

  renderDeleteConfirmation = door => {
    const { removePanelDoor } = this.props;

    return (
      <div>
        <Delete
          onCancel={() => this.setState({ deleteConfirmationDoorNumber: null, openKebabDoorNumber: null })}
          deleteFunc={async () => {
            const action = await removePanelDoor(door.get('number'));
            if (!action.response.ok) {
              this.setState({
                error: (action.json && action.json.message) || 'Unknown error occurred while removing panel device',
              });
            }
          }}
          message={
            <div>
              Are you sure you want to <strong className="strong">remove this door</strong>?
            </div>
          }
        />
      </div>
    );
  };

  render() {
    const { accessControlPanelDoors, accessControlPanelDoorsLoaded, accessControlDoorsLoaded } = this.props;
    const { deleteConfirmationDoorNumber } = this.state;

    if (!accessControlPanelDoorsLoaded || !accessControlDoorsLoaded) {
      return <SpinnerLoading />;
    }

    return (
      <div>
        {accessControlPanelDoors.size > 0 ? (
          <table className="table-units-list">
            <thead>
              <tr>
                <th>Name</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {accessControlPanelDoors.map(door => (
                <tr key={door.get('number')}>
                  <td>{door.get('name')}</td>
                  {
                    <td className="table__align-right">
                      {deleteConfirmationDoorNumber === door.get('number')
                        ? this.renderDeleteConfirmation(door)
                        : this.renderKebab(door)}
                    </td>
                  }
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyPlaceholder>All doors have been imported.</EmptyPlaceholder>
        )}
      </div>
    );
  }
}

export default UnrecognizedDoors;
