export default {
  adminNavigation: {
    property: 'Собственности', //?
    accessControl: 'Контроль доступа', //?
    offers: 'Предложения', //?
    financials: 'Финансы', //?
    reports: 'Отчеты', //?
    rolesAndPermissions: 'Роли и права доступа', //?
    adminManagement: 'Управление администраторами', //?
    events: 'События', //?
    stripeSettings: 'Настройки Stripe', //?
  },

  propertyNavigation: {
    backToAdmin: 'Назад к администратору',
    backToPropertiesList: 'Назад к списку свойств',

    units: {
      title: 'Апартаменты и жители',
      subRoutes: {
        unitList: 'Список апартаментов',
        residentList: 'Список жителей',
        selfGuidedTours: 'Самостоятельные экскурсии',
      },
    },

    smartHome: {
      title: 'Умный дом',
      subRoutes: {
        batteryLevels: 'Уровни заряда батареи',
        vacantUnits: 'Свободные апартаменты',
      },
    },

    residentMessages: {
      title: 'Сообщения от резидентов',
      subRoutes: {
        maintenanceRequests: 'Запросы на техническое обслуживание',
        helpFromManagement: 'Помощь от менеджеров',
      },
    },

    smartAccess: {
      title: 'Умный доступ',
      subRoutes: {
        propertyGuests: 'Гости собственности', //?
        residentGuests: 'Гости жителя',
        accessLog: 'Журнал доступа',
        areas: 'Зоны',
        doors: 'Двери',
        schedules: 'Расписания',
        accessProfiles: 'Профили доступа',
        accessPoints: 'Точки доступа',
      },
    },

    smartCommunity: {
      title: 'Умное сообщество',
      subRoutes: {
        amenities: 'Удобства',
        announcements: 'Объявления',
        events: 'События',
        vendors: 'Поставщики',
        discussion: 'Обсуждение',
        pendingDiscussions: 'Ожидающие обсуждения',
      },
    },

    communityViews: {
      title: 'Виды сообщества',
      subRoutes: {
        cameras: 'Камеры',
        archivedVideo: 'Архивное видео',
        notifications: 'Уведомления',
        settings: 'Настройки',
      },
    },

    settings: {
      title: 'Настройки',
      subRoutes: {
        portalUsers: 'Пользователи вебсайта',
        communitySettings: 'Настройки сообщества',
        residentFeatures: 'Функции жителей',
        maintenance: 'Техническое обслуживание',
        integrations: 'Интеграции',
        unitGroups: 'Группы апартаментов',
        mailHubs: 'Почтовые хабы',
        devices: 'Устройства',
      },
    },
  },
};
