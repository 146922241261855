import React, { useState } from 'react';
import Icon from '../../../../../../../common/icons/icon';
import InputScaffold from '../../../../../../../common/forms/InputScaffold';
import { CANCEL_ALL_RESERVATIONS_MODAL_TYPES } from '../constants/cancelAllReservationsModalTypes';

export const CancelAllReservationsModal = ({
  amenityId,
  reservationId,
  onClose,
  onCancelReservation,
  visible,
  cancelModalType,
}) => {
  const [cancelReason, setCancelReason] = useState('');

  if (!visible) return null;

  const cancelDescription = {
    [CANCEL_ALL_RESERVATIONS_MODAL_TYPES.NON_RESERVABLE]: 'when changing to a Non-Reservable type',
    [CANCEL_ALL_RESERVATIONS_MODAL_TYPES.NO_AVAILABILITY]: 'when making an amenity unavailabile',
    [CANCEL_ALL_RESERVATIONS_MODAL_TYPES.PAID]: 'when changing to a Paid type',
  };

  return (
    <div className="modal modal--visible">
      <div className="modal__children">
        <div className="modal__header">
          <h3>Cancel All Reservations</h3>
          <div className="modal__close" onClick={onClose}>
            <Icon icon="Clear" />
          </div>
        </div>
        <div className="modal__body">
          <p className="modal__body--text">
            {`All active reservations for this amenity will be canceled ${cancelDescription[cancelModalType] ??
              ' if this change is made'}. To cancel
            these reservations, please provide a reason for cancelation, which will be emailed to the residents affected
            by these cancelations.`}
          </p>
          <InputScaffold label="Cancelation Reason" required>
            <textarea
              name="reason"
              maxLength="2000"
              onChange={e => setCancelReason(e.target.value)}
              value={cancelReason}
              rows={6}
            />
          </InputScaffold>
        </div>
        <div className="modal__button--container">
          <button className="button" onClick={() => onCancelReservation(cancelReason)} disabled={!cancelReason}>
            Cancel All Reservations
          </button>
          <button className="button button--secondary" type="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
      <button type="button" className="modal__overlay" onClick={onClose} />
    </div>
  );
};
