import React, { useCallback } from 'react';
import PropertyPageHeader from '../../../../../common/PropertyPageHeader';
import { CameraForm } from './common/CameraForm';
import { useDispatch, useSelector } from 'react-redux';
import { propertySelector } from '../../../redux/selectors';
import { addCommunityViewsCamera } from '../../../redux/actions/communityViewsActions';

export function AddCamera({ history }) {
  const dispatch = useDispatch();
  const property = useSelector(propertySelector);

  const onSubmit = useCallback(
    (values, actions) => {
      dispatch(addCommunityViewsCamera(values, property.get('id').toString()));
      history.goBack();
    },
    [dispatch, history, property]
  );

  const onCancel = useCallback(() => history.goBack(), [history]);

  return (
    <div>
      <PropertyPageHeader title="Add Camera" backLink="./" />
      <CameraForm onCancel={onCancel} onSubmit={onSubmit} />
    </div>
  );
}
