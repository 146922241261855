import React, { useCallback, useEffect } from 'react';
import SubHeader from '../../../../../common/SubHeader/index';
import { useDispatch, useSelector } from 'react-redux';
import { CameraForm } from './common/CameraForm';
import { communityViewsCamerasSelector, propertySelector } from '../../../redux/selectors';
import { editCommunityViewsCamera, getCommunityViewsCameras } from '../../../redux/actions/communityViewsActions';

export function EditCamera({ match, history }) {
  const dispatch = useDispatch();
  const property = useSelector(propertySelector);
  const { cameraId } = match.params;
  const cameras = useSelector(communityViewsCamerasSelector);
  const camera = cameras?.find(cam => cam.id === cameraId);
  const initialValues = camera ?? {};

  useEffect(() => {
    dispatch(getCommunityViewsCameras(property.get('id').toString()));
  }, [property, dispatch]);

  const onSubmit = useCallback(
    (values, actions) => {
      dispatch(editCommunityViewsCamera(values, property.get('id').toString()));
      history.goBack();
    },
    [dispatch, history, property]
  );

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div className="container">
      <SubHeader title="Edit Camera" />
      <CameraForm onSubmit={onSubmit} onCancel={onCancel} initialValues={initialValues} />
    </div>
  );
}
