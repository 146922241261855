import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Yup from 'yup';
import { trimAndSetNullFalsyValues } from '../../../../common/utils/helpers';
import InputScaffold from '../../../../common/forms/InputScaffold';
import AlertMessage from '../../../../common/AlertMessage';

const validationSchema = Yup.object().shape({
  group: Yup.string()
    .trim()
    .max(32, 'group must be 64 characters or less')
    .required('Please enter a group name'),
});

class GroupForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    updatePermissionList: PropTypes.func,
  };

  state = {
    confirmDelete: false,
    confirmSave: false,
  };

  onSubmit = (values, { setSubmitting }) => {
    const { onSubmit } = this.props;
    this.setState({ confirmSave: true });
    onSubmit(trimAndSetNullFalsyValues(values)).catch(res => {
      setSubmitting(false);
    });
  };

  navigateToList = () => this.props.updatePermissionList();

  render() {
    const { initialValues, error, isAdd } = this.props;
    const { confirmSave } = this.state;

    return (
      <div>
        <Formik
          enbaleReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          render={({ values, errors, handleChange, handleSubmit, touched, isSubmitting, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="container property-manager-form">
              {error && <AlertMessage msg={error} type="error" />}
              <div>
                <div className="paper radius-top-left radius-top-right RolesAndPermissions-Form-Top">
                  <InputScaffold label="GROUP NAME" required validation={touched.group && errors.group}>
                    <input
                      type="text"
                      name="group"
                      maxLength="32"
                      onChange={({ target }) => {
                        setFieldValue('group', target.value);
                        if (isSubmitting) {
                          this.setState({ confirmSave: false });
                        }
                      }}
                      value={values.group}
                    />
                  </InputScaffold>
                </div>
              </div>

              <div className="paper__footer radius-bottom-left radius-bottom-right RolesAndPermissions-Form-bottom">
                <div className="From-Save-Button">
                  <button type="submit" disabled={confirmSave} className="button button-min160">
                    {isAdd === 'edit' ? 'Save' : 'Create'}
                  </button>
                  <button type="button" className="button button--secondary" onClick={this.navigateToList}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withRouter(GroupForm);
