import React from 'react';
import PropTypes from 'prop-types';
import { useTablesTextTruncate } from '../details';

export const SGTdContact = ({ phone, email }) => {
  const truncatedText = useTablesTextTruncate(email);

  return (
    <div className="td-contact">
      <span>{phone}</span>
      {truncatedText}
    </div>
  );
};

SGTdContact.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
};
