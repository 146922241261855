import { connect } from 'react-redux';
import Reports from './Reports';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
  };
}

const ReportsContainer = connect(mapStateToProps)(Reports);

export default ReportsContainer;
