import React from 'react';
import RoboticArm from '../../../../../../../../../common/icons/RoboticArm';
import United from '../../../../../../../../../common/icons/United';

export const SGHandsOff = () => (
  <div className="container tours_block sg-container">
    <div className="paper radius-top-left radius-top-right">
      <section className="paper__section resident-app-features">
        <div className="tours_title">
          <h1 className="h1 strong ">Your tours are set up to be HANDS OFF</h1>
        </div>
        <p className="font-small margin-clear-all sg-message-title">
          The system approves background checks and gives access automatically to all model units after potential
          residents fill out the tour form.
        </p>
        <div className="setting-tour-container">
          <div className="setting-tour-first-block m-tb30">
            <div className="setting-tour-img">{<RoboticArm />}</div>
            <p className="setting-tour-first-subtitle text-center">Tours are automated without any staff management</p>
          </div>
        </div>
        <p className="title-setting-tour-two">
          If you would like management staff involved in tours, select from the option below.
        </p>
        <div className="setting-tour-two-block ">
          <div className="setting-container-left">
            <div className="setting-tour-img">{<United />}</div>
            <span className="text-center m-t10">Tours are managed by staff</span>
          </div>
          <div className="separator"></div>
          <div className="setting-text-block">
            <p>
              Management approves background checks and assigns specific units to tour for each prospective resident.
              Giving prospective resident a more engaged, custom experience.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
);
