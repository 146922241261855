import React from 'react';

function Add() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M12 2a6 6 0 00-6 6v8a6 6 0 1012 0V8a6 6 0 00-6-6M8 6h2v2H8V6m3 0h2v2h-2V6m3 0h2v2h-2V6M8 9h2v2H8V9m3 0h2v2h-2V9m3 0h2v2h-2V9m-6 3h2v2H8v-2m3 0h2v2h-2v-2m3 0h2v2h-2v-2m-2 4a2 2 0 110 4 2 2 0 010-4z" />
    </svg>
  );
  /* eslint-enable */
}

export default Add;
