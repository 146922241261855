import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import InputScaffold from '../../common/forms/InputScaffold';

class CurrencyInput extends Component {
  state = { value: null };
  handleValueUpdate = values => {
    this.setState({ value: values.formattedValue });
    const updateValue = values.floatValue || 0;
    this.props.handleValueUpdate(updateValue);
  };
  render() {
    return (
      <InputScaffold required={this.props.required} label={this.props.label} validation={this.props.validation}>
        <CurrencyFormat
          allowNegative={false}
          required={this.props.required}
          maxLength={this.props.maxLength}
          value={this.state.value || this.props.value || ''}
          thousandSeparator={true}
          prefix={'$'}
          onValueChange={this.handleValueUpdate}
          disabled={this.props.disabled}
        />
      </InputScaffold>
    );
  }
}

CurrencyInput.propTypes = {
  required: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
};

CurrencyInput.defaultProps = {
  required: false,
  validation: false,
  disabled: false,
};

export default CurrencyInput;
