import React from 'react';

export const SGDisableTourDialog = () => (
  <>
    <div className="title-popup">
      <h1 className="m-b10">Are you sure you want to disable tours?</h1>
      <h2 className="m-b10">
        Disabling tours will prevent prospective residents from registering for self-guided tours and will revoke access
        for all scheduled self-guided tours.
      </h2>
      <h3 className="m-b10">
        You can re-enable self-guided tours at any time. The previous settings will be retained.
      </h3>
    </div>
    <div className="divider horizontal m-t15 m-b15"></div>
    <div className="subtitle-popup m-b20">
      <h4>Would you like to notify all the potential residents?</h4>
      <h5>
        This email will be sent to the potential residents notifying them that their tour has been canceled and they no
        longer will have access to the property.
      </h5>
    </div>
  </>
);
