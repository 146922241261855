import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getScheduleTourUnitGuest } from '../../../../../../redux/actions/scheduledAction';

export const useFetchTourGuests = query => {
  const [isLoad, setIsLoad] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(null);
  const dispatch = useDispatch();
  const { propertyId } = useParams();

  const loadData = async () => {
    const result = await dispatch(getScheduleTourUnitGuest(propertyId, query));
    setDataLoaded(result.json);
    setIsLoad(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoad,
    dataLoaded,
    propertyId,
    refetch: loadData,
  };
};
