import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../../../../common/PageHeader';
import { Link } from 'react-router-dom';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import SubHeader from '../../../../../../common/SubHeader/index';
import Immutable from 'immutable';
import Loading from '../../../../../../common/Loading/index';
import querystring from 'query-string';
import { SearchText } from '../../../../../../common/Search';
import Select from 'react-select';
import Pager from '../../../../../../common/Pager';
class TelephoneEntryDevicesList extends Component {
  componentDidMount() {
    this.searchTelephoneEntryDevices();

    this.props.getProperties();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location.search !== location.search) {
      return this.searchTelephoneEntryDevices();
    }
  }

  searchTelephoneEntryDevices = () => {
    const { location, getTelephoneEntryDevices } = this.props;

    let query = querystring.parse(location.search);

    if (!query.page) query.page = 0;

    getTelephoneEntryDevices(query);
  };

  onPropertyFilterChange = property_filter => {
    const { location, history } = this.props;
    const query = querystring.parse(location.search);

    return history.push({ pathname: location.pathname, search: querystring.stringify({ ...query, property_filter }) });
  };

  render() {
    const {
      telephoneEntryDevices,
      telephoneEntryDevicesLoaded,
      location,
      propertyOptions,
      history,
      telephoneEntryDevicesCount,
      userPermissionList,
    } = this.props;
    const query = querystring.parse(location.search);

    if (!telephoneEntryDevicesLoaded) {
      return <Loading />;
    }

    const userPermission = {
      telephoneEntry: userPermissionList.includes('UPDATE_TELEPHONE_ENTRY_DEVICES'),
    };
    return (
      <div>
        <PageHeader backLink="./">
          <SearchText name="searchText" placeHolder="Search Telephone Entry Devices" />
          {query.searchText || query.property_filter ? (
            <Link to="/access-control/telephone-entry-devices?" style={{ paddingTop: '10px' }}>
              Clear Search
            </Link>
          ) : null}
        </PageHeader>
        <div className="container">
          <SubHeader
            title={`${telephoneEntryDevices.size} Telephone Entry Device${telephoneEntryDevices.size > 1 ? 's' : ''}`}
            actions={[
              <div className="react-select-scaffold__inline">
                <div className="label">Filter By Property:</div>
                <Select
                  closeOnSelect={true}
                  onChange={this.onPropertyFilterChange}
                  options={propertyOptions}
                  placeholder="Select..."
                  value={query.property_filter}
                  simpleValue={true}
                  clearable={false}
                />
              </div>,
              telephoneEntryDevices.size > 0 && (
                <Link className="button" to="telephone-entry-devices/add">
                  Add TE Device
                </Link>
              ),
              userPermission.telephoneEntry && (
                <button className="button" type="button" onClick={() => this.props.updateVideoRooms()}>
                  Update Video Rooms
                </button>
              ),
            ]}
          />
          {telephoneEntryDevices.size ? (
            <div className="table__wrapper">
              <table className="table-list">
                <thead>
                  <tr>
                    <th>Associated Property</th>
                    <th>Name</th>
                    <th style={{ minWidth: '310px' }}>UUID</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {telephoneEntryDevices.map(device => {
                    return (
                      <tr key={device.get('id')}>
                        <td>
                          <div>
                            {device.get('property') ? (
                              device.getIn(['property', 'name'])
                            ) : (
                              <div style={{ color: '#EB586B' }}> Not Yet Associated </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div>{device.get('name')}</div>
                        </td>
                        <td style={{ minWidth: '310px' }}>
                          <div>{device.get('id')}</div>
                        </td>
                        <td className="table__align-right">
                          <Link
                            className="table-row__action-button"
                            to={`telephone-entry-devices/${device.get('id')}/edit`}
                          >
                            Edit
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <EmptyPlaceholder buttonText="Add TE Device" linkTo={`telephone-entry-devices/add`} />
          )}
          <Pager
            history={history}
            initialPage={parseInt(query.page, 10) || 0}
            location={location}
            pageSize={10}
            totalItems={telephoneEntryDevicesCount}
          />
        </div>
      </div>
    );
  }
}

TelephoneEntryDevicesList.propTypes = {
  telephoneEntryDevices: PropTypes.instanceOf(Immutable.List),
  getTelephoneEntryDevices: PropTypes.func.isRequired,
};

export default TelephoneEntryDevicesList;
