import { fromJS } from 'immutable';

export const scheduledReducers = {
  tourGuestList: (state = [], action) => {
    switch (action.type) {
      case 'GET_RESIDENT_GUEST':
      case 'GET_RESIDENT_GUEST_SUCCESS':
        return fromJS(action.json);
      case 'GET_RESIDENT_GUEST_FAILURE':
        return [];
      default:
        return state;
    }
  },
};
