import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import PageHeader from '../../../../../../common/PageHeader';
import Loading from '../../../../../../common/Loading';
import ScheduleForm from '../../components/ScheduleForm';
import moment from 'moment';

class EditSchedule extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    property: PropTypes.instanceOf(Map),
  };

  componentDidMount() {
    this.props.getPropertySchedules();
  }

  handleSuccess = () => {
    this.props.history.push('./');
  };

  handleCancel = () => {
    this.props.history.push('./');
  };

  render() {
    const { editSchedule, accessControlSchedulesLoaded, matchingSchedule } = this.props;

    if (!accessControlSchedulesLoaded || !matchingSchedule) {
      return <Loading />;
    }

    const initialValues = {
      name: matchingSchedule.get('name'),
      begins_at: matchingSchedule.get('begins_at') ? new Date(matchingSchedule.get('begins_at')) : null,
      ends_at: matchingSchedule.get('ends_at') ? new Date(matchingSchedule.get('ends_at')) : null,
      is_temporary: matchingSchedule.get('is_temporary'),
    };

    for (const key of [
      'sun_open',
      'sun_close',
      'mon_open',
      'mon_close',
      'tue_open',
      'tue_close',
      'wed_open',
      'wed_close',
      'thu_open',
      'thu_close',
      'fri_open',
      'fri_close',
      'sat_open',
      'sat_close',
    ]) {
      initialValues[key] = matchingSchedule.get(key)
        ? moment()
            .set('hour', matchingSchedule.get(key).split(':')[0])
            .set('minute', matchingSchedule.get(key).split(':')[1])
        : null;
    }

    return (
      <div>
        <PageHeader title="Schedules" white backLink="./" />
        <div className="container">
          <ScheduleForm
            initialValues={initialValues}
            submitFunc={editSchedule}
            onCancel={this.handleCancel}
            onSuccess={this.handleSuccess}
          />
        </div>
      </div>
    );
  }
}

export default EditSchedule;
