import { connect } from 'react-redux';

import AreasList from './AreasList';
import { getPropertyAreas, getPropertyPanelAreas, removeArea } from '../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlAreas: state.property.accessControlAreas,
    accessControlAreasLoaded: state.property.accessControlAreasLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPropertyAreas: () => dispatch(getPropertyAreas(ownProps.match.params.propertyId)),
    getPropertyPanelAreas: () => dispatch(getPropertyPanelAreas(ownProps.match.params.propertyId)),
    removeArea: areaId => dispatch(removeArea(ownProps.match.params.propertyId, areaId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AreasList);
