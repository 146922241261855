import React from 'react';

function RoboticArm() {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 512 512"
      height="100%"
      viewBox="0 0 512 512"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path d="m384 168v24c0 8.837 7.164 16 16 16h16v-40z" fill="#463c4b" />
        </g>
        <g>
          <path d="m440 168v40h16c8.836 0 16-7.163 16-16v-24z" fill="#463c4b" />
        </g>
        <g>
          <path
            d="m174.059 46.863h67.882v226.274h-67.882z"
            fill="#555a6e"
            transform="matrix(-.707 -.707 .707 -.707 241.941 420.215)"
          />
        </g>
        <g>
          <path
            d="m312 360-120-120c0-35.346-28.654-64-64-64h-32c-35.346 0-64 28.654-64 64 0 35.346 28.654 64 64 64h32l120 120z"
            fill="#faa019"
          />
        </g>
        <g>
          <path d="m416 120h24v24h-24z" fill="#463c4b" />
        </g>
        <g>
          <path d="m160 240c0-35.346-28.654-64-64-64s-64 28.654-64 64 28.654 64 64 64l136 136 64-64z" fill="#ffcd05" />
        </g>
        <g>
          <path d="m312 352h-48c-30.928 0-56 25.072-56 56v40h160v-40c0-30.928-25.072-56-56-56z" fill="#463c4b" />
        </g>
        <g>
          <path d="m264 352c-30.928 0-56 25.072-56 56v40h112v-40c0-30.928-25.072-56-56-56z" fill="#555a6e" />
        </g>
        <g>
          <circle cx="264" cy="408" fill="#ffcd05" r="24" />
        </g>
        <g>
          <path d="m368 448h-160c-17.673 0-32 14.327-32 32h224c0-17.673-14.327-32-32-32z" fill="#463c4b" />
        </g>
        <g>
          <circle cx="96" cy="240" fill="#555a6e" r="32" />
        </g>
        <g>
          <path
            d="m288 128h-32c-26.51 0-48-21.49-48-48 0-26.51 21.49-48 48-48h32c26.51 0 48 21.49 48 48 0 26.51-21.49 48-48 48z"
            fill="#faa019"
          />
        </g>
        <g>
          <path
            d="m416 56h-118.437c-8.301-14.343-23.799-24-41.563-24-26.51 0-48 21.49-48 48s21.49 48 48 48c17.764 0 33.262-9.657 41.563-24h118.437z"
            fill="#ffcd05"
          />
        </g>
        <g>
          <circle cx="256" cy="80" fill="#555a6e" r="24" />
        </g>
        <g>
          <path
            d="m440 120h-24c-22.091 0-40-17.909-40-40 0-22.091 17.909-40 40-40h24c22.091 0 40 17.909 40 40 0 22.091-17.909 40-40 40z"
            fill="#463c4b"
          />
        </g>
        <g>
          <circle cx="416" cy="80" fill="#555a6e" r="40" />
        </g>
        <g>
          <circle cx="416" cy="80" fill="#ffcd05" r="16" />
        </g>
        <g>
          <path d="m456 144h-56c-8.837 0-16 7.163-16 16v8h88v-8c0-8.837-7.163-16-16-16z" fill="#555a6e" />
        </g>
      </g>
    </svg>
  );
}

export default RoboticArm;
