import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { browseUnits } from '../../../../redux/actions';
import UnitsList from './UnitsList';
import compose from 'lodash/flowRight';
import { getUserPermission } from '../../../../../../redux/selectors';

function mapStateToProps(state, props) {
  return {
    units: state.property.unitList,
    unitListTotal: state.property.unitListTotal,
    residentsListTotal: state.property.residentsListTotal,
    searching: state.property.unitListSearching,
    loading: state.property.unitListLoading,
    loaded: state.property.unitListLoaded,
    property: state.property.activeProperty,
    propertyId: state.property.activeProperty.get('id'),
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    browseUnits: (propertyId, params) => dispatch(browseUnits(propertyId, params)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(UnitsList);
