export default {
  main: {
    title: 'Resident Guests',
    downloadCsv: 'Download CSV',
    filters: 'Filters',
  },

  list: {
    noResidents: 'There are no Resident Guests',

    tableHeaders: {
      residentName: 'RESIDENT NAME',
      residentUnit: 'RESIDENT UNIT',
      guestName: 'GUEST NAME',
      guestAccessDateRange: 'Guest Access Date Range',
      scheduleType: 'Schedule Type',
      status: 'STATUS',
    },

    revoke: 'Revoke',
    accessLog: 'Access Log',
  },

  filter: {
    residentName: 'RESIDENT NAME',
    residentUnit: 'RESIDENT UNIT',
    guestName: 'GUEST NAME',
    status: 'STATUS',
    guestsAccessStart: 'GUEST ACCESS START',
    guestsAccessEnd: 'GUEST ACCESS END',
    statuses: {
      active: 'Active',
      inactive: 'Inactive',
      future: 'Future',
      revoked: 'Revoked',
    },

    dateError: 'Start date must be before end date',
  },

  alertModal: {
    title: 'Revoke Community Access?',
    body: "Enter a comment to your resident to let them know why their guest's community access was revoked.",
    confirmButtonTitle: 'Revoke',
  },
};
