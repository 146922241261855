import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { SearchText } from '../../common/Search';
import querystring from 'query-string';
import PropertiesTable from './PropertiesTable';
import PageHeader from '../../common/PageHeader';

class PropertiesList extends Component {
  state = {
    parsedLocation: '',
    userPermission: {},
  };
  async componentDidMount() {
    const { userPermissionList } = this.props;
    const userPermission = {
      accessPropertyPortal: userPermissionList.includes('ACCESS_PROPERTY_PORTAL'),
      showHideDemos: userPermissionList.includes('SHOW_HIDE_DEMOS'),
      createANewProperty: userPermissionList.includes('CREATE_A_NEW_PROPERTY'),
      editAProperty: userPermissionList.includes('EDIT_A_PROPERTY'),
      deleteAProperty: userPermissionList.includes('DELETE_A_PROPERTY'),
      createADemoProperty: userPermissionList.includes('CREATE_A_DEMO_PROPERTY'),
    };
    this.setState({ userPermission: userPermission });
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const { location, history, isAdmin, total, loaded, list, searching } = this.props;
    if (prevProps.location.search !== location.search) {
      if (!isAdmin && loaded && total === 1 && !searching) {
        history.push(`/properties/${list.getIn(['0', 'id'])}`);
      }
      return this.updateData();
    }
    return true;
  }

  updateData = () => {
    const { getProperties, location } = this.props;
    const search = querystring.parse(location.search);
    this.setState({ parsedLocation: search });
    return getProperties(search);
  };

  onClickClearSearch = () => {
    const { history } = this.props;
    return history.push('/properties');
  };

  renderPropertyHeader() {
    const { searching } = this.props;

    return (
      <PageHeader>
        <SearchText name="name" placeHolder="Search Properties" />
        {searching ? (
          <Link to="/properties" style={{ paddingTop: '10px' }}>
            Clear Search
          </Link>
        ) : null}
      </PageHeader>
    );
  }

  render() {
    const { history, isAdmin, list, loaded, total, filteredTotal } = this.props;
    return (
      <div className="properties-list--page">
        {this.renderPropertyHeader()}
        <div className="table-units-list__wrapper">
          <div className="container">
            <PropertiesTable
              userPermission={this.state.userPermission}
              history={history}
              isAdmin={isAdmin}
              list={list}
              loaded={loaded}
              locationObj={this.state.parsedLocation}
              total={total}
              filteredTotal={filteredTotal}
            />
          </div>
        </div>
      </div>
    );
  }
}

PropertiesList.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  list: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
  searching: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  filteredTotal: PropTypes.number.isRequired,
};

export default PropertiesList;
