export default {
  searchUnits: 'Search Units',
  searchTitle: 'SEARCH',
  clearSearchBtn: 'Clear Search',
  counterUnits: 'Units',
  counterResidents: 'Residents',
  filterUnits: 'Filter by:',
  filterOptionsNone: 'None',
  filterOptionsActive: 'Active',
  filterOptionsInactive: 'Inactive',
  filterOptionsOccupied: 'Occupied',
  filterOptionsUnoccupied: 'Unoccupied',
  importUnitsBtn: 'Import Units',
  addUnitBtn: 'Add Unit',
  moreUnit: 'more',
  importUnitsTitle: 'Import Units',
  importUnitsSubTitle: 'Import Using CSV File',
  importUnitsChooseFile: 'Choose a CSV file...',
  importUnitsChooseBtn: 'Choose File',
  importUnitsText: 'Click here to download a sample template',
  importUnitsSubmitBtn: 'Submit',
  detailsUnitsTitle: 'Unit Details',
  informationUnitsTitle: 'Unit Information',
  unitNumber: 'UNIT NUMBER',
  unitNumberPlaceholder: 'Add the name or number of this unit',
  unitGroups: 'Groups',
  unitSquareFootage: 'SQUARE FOOTAGE',
  unitSquareFootagePlaceholder: 'Square footage of this unit',
  detailsSaveBtn: 'Save Unit',
  detailsCancelBtn: 'Cancel',
  unitRemoveBtn: 'Remove Unit',
  unitResidentsTitle: 'Residents',
  unitAddResidentBtn: 'Add Resident',
  unitResidentColumnNumber: 'NAME',
  unitResidentColumnPhone: 'PHONE NUMBER',
  unitResidentInformation: 'Resident Information',
  residentFirstName: 'FIRST NAME',
  residentFirstNameError: 'Please enter a name',
  residentLastName: 'LAST NAME',
  residentLastNameError: 'Please enter a name',
  residentEmail: 'EMAIL',
  residentEmailError: 'Please enter an email address',
  residentPhone: 'PHONE',
  residentPhoneError: 'Must be a valid phone number',
  residentHelperText: `By entering the resident's phone number and agreeing to the EULA, the resident agrees to
  receive SmartExperience SMS notifications. To opt-out of receiving SMS notifications, either remove the phone number here or
  unselect SMS in notifications in the resident app. Message and data rates may apply.`,
  residentCheckbox: "Enable Resident's Guest Community Access",
  enableDirectory: 'Enable Directory Listing',
  saveResidentBtn: 'Save Resident',
  saveResidentBtn_1: 'Saving Resident...',
  unitDevicesTitle: 'Devices',
  unitDevicesHelperText: 'No Devices Found',
  unitDevicesTextBtn: 'Request Panel Activation',
  removeResident: 'Remove Resident',
  removalConfirmation: 'Are you sure you want to <strong className="strong">remove this Resident</strong>?',
};
