import React from 'react';

export const SGFooter = () => (
  <footer className="sg-footer m-t">
    <div className="sg-footer-title-container">
      <span className="footer-subtitle">Powered by</span>
      <span className="footer-title">SmartExperience</span>
    </div>
  </footer>
);
