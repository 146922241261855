import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Kebab from '../../../../../../common/Kebab';
import Icon from '../../../../../../common/icons/icon';
import ModalAlert from '../../../../../../common/ModalAlert';
class MailHubKebab extends Component {
  static propTypes = {
    toggleShowingMenu: PropTypes.func.isRequired,
    showingMenu: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    removeMailHub: PropTypes.func.isRequired,
  };

  state = {
    removeMailHubModalOpen: false,
  };

  render() {
    const { toggleShowingMenu, showingMenu } = this.props;
    return (
      <div className="mailhub-item__actions">
        <Kebab onClick={toggleShowingMenu} onDisengage={toggleShowingMenu} menuOpened={showingMenu}>
          <div className="kebab__menu">
            <div className="kebab__menu--item" onClick={this.props.onEdit}>
              <Icon icon="Edit" /> Edit
            </div>
            <div
              className="kebab__menu--item"
              onClick={() =>
                this.setState({
                  removeMailHubModalOpen: true,
                })
              }
            >
              <Icon icon="Remove" /> Remove
            </div>
          </div>
        </Kebab>
        <ModalAlert
          title="Remove Mail Hub?"
          body="Are you sure you want to remove this Mail Hub?"
          confirmButtonTitle="Remove Mail Hub"
          cancelButtonTitle="Cancel"
          handleConfirm={() => {
            this.props.removeMailHub();
            this.setState({
              removeMailHubModalOpen: false,
            });
          }}
          handleCancel={() => {
            this.setState({
              removeMailHubModalOpen: false,
            });
          }}
          visible={this.state.removeMailHubModalOpen}
        />
      </div>
    );
  }
}

export default MailHubKebab;
