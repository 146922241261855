import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch, Route } from 'react-router-dom';

import NotFound from '../../common/NotFound';
import AreasList from './views/AreasList';
import AddArea from './views/AddArea';
import EditArea from './views/EditArea';
import DoorsList from './views/DoorsList';
import AddDoor from './views/AddDoor';
import EditDoor from './views/EditDoor';
import SchedulesList from './views/SchedulesList';
import AddSchedule from './views/AddSchedule';
import EditSchedule from './views/EditSchedule';
import ProfilesList from './views/ProfilesList';
import AddProfile from './views/AddProfile';
import EditProfile from './views/EditProfile';
import AccessPointsList from './views/AccessPointsList';
import AddAccessPoint from './views/AddAccessPoint';
import EditAccessPoint from './views/EditAccessPoint';
import AccessRequests from './views/AccessRequests';
import PropertyGuests from './views/PropertyGuests';
import ResidentGuests from './views/ResidentGuests';
import AccessControlPlaceholder from '../AccessControlPlaceholder';

class AccessControl extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
  };

  render() {
    const { match, property, userPermissionList } = this.props;
    const userPermission = {
      // SmartAccess
      PropertyGuests: userPermissionList.includes('VIEW_PROPERTY_GUESTS'),
      ResidentGuests: userPermissionList.includes('VIEW_UNIT_GUESTS'),
      AccessLog: userPermissionList.includes('VIEW_ACCESS_LOG_PAGE'),
      Areas: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      Doors: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      Schedules: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      AccessProfiles: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      AccessPoints: userPermissionList.includes('VIEW_ACCESS_POINTS'),
      editResidentPersonalDetails: userPermissionList.includes('EDIT_RESIDENT_PERSONAL_DETAILS'),
    };
    let url = '';
    if (userPermission.PropertyGuests) {
      url = match.url + '/property-guests';
    } else if (userPermission.ResidentGuests) {
      url = match.url + '/resident-guests';
    } else if (userPermission.AccessLog) {
      url = match.url + '/access-requests';
    } else if (userPermission.Areas && property.get('clubhouse_panel_id')) {
      url = match.url + '/areas';
    } else {
      url = match.url + '/default';
    }
    return (
      <Switch>
        <Route exact path={match.path} render={() => <Redirect to={url} />} />
        {userPermission.ResidentGuests && (
          <Route exact path={`${match.path}/resident-guests`} component={ResidentGuests} />
        )}
        {userPermission.AccessLog && <Route exact path={`${match.path}/access-requests`} component={AccessRequests} />}
        {userPermission.PropertyGuests && <Route path={`${match.path}/property-guests`} component={PropertyGuests} />}
        <Route path={`${match.path}/default`} component={AccessControlPlaceholder} />
        <Route exact path={`${match.path}/access-points`} component={AccessPointsList} key="access-points" />
        <Route
          exact
          path={`${match.path}/access-points/add-access-point`}
          component={AddAccessPoint}
          key="add-access-point"
        />
        ,
        <Route
          exact
          path={`${match.path}/access-points/:accessPointId`}
          component={EditAccessPoint}
          key="access-point"
        />
        ,
        {!!property.get('clubhouse_panel_id')
          ? [
              <Route exact path={`${match.path}/areas`} component={AreasList} key="areas" />,
              <Route exact path={`${match.path}/areas/add-area`} component={AddArea} key="add-area" />,
              <Route exact path={`${match.path}/areas/:areaId`} component={EditArea} key="area" />,
              <Route exact path={`${match.path}/doors`} component={DoorsList} key="doors" />,
              <Route exact path={`${match.path}/doors/add-door`} component={AddDoor} key="add-door" />,
              <Route exact path={`${match.path}/doors/:doorId`} component={EditDoor} key="door" />,
              <Route exact path={`${match.path}/schedules`} component={SchedulesList} key="schedules" />,
              <Route exact path={`${match.path}/schedules/add-schedule`} component={AddSchedule} key="add-schedule" />,
              <Route exact path={`${match.path}/schedules/:scheduleId`} component={EditSchedule} key="schedule" />,
              <Route exact path={`${match.path}/profiles`} component={ProfilesList} key="profiles" />,
              <Route exact path={`${match.path}/profiles/add-profile`} component={AddProfile} key="add-profile" />,
              <Route exact path={`${match.path}/profiles/:profileId`} component={EditProfile} key="profile" />,
            ]
          : null}
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default AccessControl;
