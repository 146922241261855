import { useState } from 'react';

export const useResidentGuestLimitModal = () => {
  const [isLimitOpen, setIsLimitOpen] = useState(false);

  return {
    handleLimitModalOpen: () => setIsLimitOpen(true),
    handleLimitModalClose: () => setIsLimitOpen(false),
    isLimitOpen,
  };
};
