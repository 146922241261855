import React from 'react';
import Dinero from 'dinero.js';
import { useEventRevenueDetails, useGetVendors } from '../../hooks';
import Loading from '../../../../../../../../common/Loading';

const MONEY_FORMAT = '$0,0.00';

export const RevenueDetails = ({ event }) => {
  const revenueDetails = useEventRevenueDetails(event.id);
  const vendors = useGetVendors(event.community_id);

  if (!revenueDetails.isFetchedAfterMount || !vendors.isFetched) {
    return <Loading />;
  }

  if (!revenueDetails.data) {
    return <div>There was a problem loading the revenue details. Please reload the page.</div>;
  }

  const isVendorEvent = !!event.vendor_id;
  const vendor = isVendorEvent ? vendors.data.find(vendor => vendor.id === event.vendor_id) : undefined;

  const { hasBeenDisbursed, activeRegistrations, cancelations, fees, amountAllocations } = revenueDetails.data;

  return (
    <div className="container">
      <div className="paper radius-top-left radius top-right">
        <div className="paper__grid">
          <div className="paper__section--grid-item">
            <section className="paper__section">
              <h4 className="section-header h4">Financials</h4>
              <div className="financials-bits__multi-wrapper">
                <div className="financials-bits__wrapper">
                  <div className="financials-bit">
                    <div className="financials__label">Price Per Ticket</div>
                    <div className="financials__value">
                      {Dinero({ amount: activeRegistrations.rate }).toFormat(MONEY_FORMAT)}
                    </div>
                  </div>

                  {isVendorEvent && (
                    <div className="financials-bit">
                      <div className="financials__label">Community Fee</div>
                      <div className="financials__value">{`${fees.communityFeePercent.toFixed(2)}%`}</div>
                    </div>
                  )}

                  <div className="financials-bit">
                    <div className="financials__label">SmartExperience Fee</div>
                    <div className="financials__value">{`${parseFloat(fees.adminFeePercent.toString()).toFixed(
                      2
                    )}%`}</div>
                  </div>
                </div>

                {isVendorEvent && (
                  <div className="financials-bits__wrapper">
                    <div className="financials-bit">
                      <div className="financials__label">Vendor</div>
                      <div className="financials__value">{vendor.name || 'N/A'}</div>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="paper radius-top-left radius top-right">
        <div className="paper__section">
          <h4 className="section-header h4">{!hasBeenDisbursed && 'Estimated '}Revenue Details</h4>
          <table className="display-table">
            <thead>
              <tr>
                <th></th>
                <th>Rate</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Registration</td>
                <td>{Dinero({ amount: activeRegistrations.rate }).toFormat(MONEY_FORMAT)}</td>
                <td>{activeRegistrations.quantity}</td>
                <td>{Dinero({ amount: activeRegistrations.total }).toFormat(MONEY_FORMAT)}</td>
              </tr>
              <tr className="table-space" />
              <tr className="table-divider" />
              <tr className="table-space" />
              {cancelations.quantity ? (
                <tr>
                  <td>
                    Cancelation Fees (<strong className="strong">{`${fees.cancelFeePercent}%`}</strong>)
                  </td>
                  <td>{Dinero({ amount: cancelations.rate }).toFormat(MONEY_FORMAT)}</td>
                  <td>{cancelations.quantity}</td>
                  <td>{Dinero({ amount: cancelations.total }).toFormat(MONEY_FORMAT)}</td>
                </tr>
              ) : null}
              <tr>
                <td>SmartExperience Fee</td>
                <td>{`${parseFloat(fees.adminFeePercent.toString()).toFixed(2)}%`}</td>
                <td>--</td>
                <td>{Dinero({ amount: amountAllocations.adminFeeAmount }).toFormat(MONEY_FORMAT)}</td>
              </tr>
              {isVendorEvent && (
                <tr>
                  <td>Community Fee</td>
                  <td>{`${fees.communityFeePercent.toFixed(2)}%`}</td>
                  <td>--</td>
                  <td>{Dinero({ amount: amountAllocations.communityAmount }).toFormat(MONEY_FORMAT)}</td>
                </tr>
              )}
              <tr className="table-space" />
              <tr className="table-divider large" />
              <tr className="table-space" />
              <tr className="display-table__total-row">
                <td>
                  Total {hasBeenDisbursed ? 'paid' : 'to be paid'} to {isVendorEvent ? 'Vendor' : 'Community'}
                </td>
                <td />
                <td />
                <td>
                  {Dinero({
                    amount: isVendorEvent ? amountAllocations.vendorAmount : amountAllocations.communityAmount,
                  }).toFormat(MONEY_FORMAT)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
