import React from 'react';

function SwitchOff() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="28" viewBox="0 0 52 28">
      <g fill="none" fillRule="evenodd">
        <rect width="51" height="27" x=".5" y=".5" fill="#DDE3EB" stroke="#DDE3EB" rx="13.5" />
        <circle cx="14" cy="14" r="12" fill="#FFF" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default SwitchOff;
