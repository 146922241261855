import React, { useState } from 'react';
import { useEventById, useEventRegistrationList } from '../Property/views/Community/views/Events/hooks';
import VendorEventDetailsTab from './VendorEventDetailsTab';
import { Tab, TabsContainer } from '../../common/Tabs';
import VendorEventRegistrationsTab from './VendorEventRegistrationsTab';
import PageHeader from '../../common/PageHeader';

const tabs = [
  {
    label: 'Event Details',
    component: VendorEventDetailsTab,
  },
  {
    label: 'Registrations',
    component: VendorEventRegistrationsTab,
  },
];

const VendorEventDetails = ({ match }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const eventId = match.params.eventId;
  const event = useEventById(eventId);
  const eventRegistrations = useEventRegistrationList(eventId);
  const eventDetails = event.data;

  if (!event?.data) {
    return null;
  }

  return (
    <>
      <PageHeader backLink="/events" title={eventDetails.title} white withTabs>
        <div className="event-address">
          <h5 className="h5">{eventDetails.location}</h5>
          <address>
            {eventDetails?.address}
            <br />
            {eventDetails?.city}, {eventDetails?.state} {eventDetails?.zip}
            <br />
            {eventDetails?.phone}
          </address>
        </div>
      </PageHeader>

      <TabsContainer>
        {tabs.map((tab, i) => (
          <Tab
            key={tab.label}
            label={tab.label}
            isActive={currentTabIndex === i}
            onPress={() => setCurrentTabIndex(i)}
          />
        ))}
      </TabsContainer>

      <div className="container">
        <div className="paper radius-top-left radius top-right">
          {tabs[currentTabIndex].component({
            eventRegistrations: eventRegistrations?.data ?? null,
            eventDetails,
          })}
        </div>
      </div>
    </>
  );
};

export default VendorEventDetails;
