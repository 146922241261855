import { connect } from 'react-redux';

import AddSchedule from './AddSchedule';
import { addSchedule } from '../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addSchedule: payload => dispatch(addSchedule(ownProps.match.params.propertyId, payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSchedule);
