import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UnrecognizedSchedules from './UnrecognizedSchedules';
import { getPropertyPanelSchedules, addSchedule, removePanelSchedule } from '../../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlSchedules: state.property.accessControlSchedules,
    accessControlSchedulesLoaded: state.property.accessControlSchedulesLoaded,
    accessControlPanelSchedules: state.property.accessControlPanelSchedules.filter(
      p =>
        !state.property.accessControlSchedules.find(s => parseInt(p.get('number'), 10) === s.get('dmp_schedule_number'))
    ),
    accessControlPanelSchedulesLoaded: state.property.accessControlPanelSchedulesLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPropertyPanelSchedules: () => dispatch(getPropertyPanelSchedules(ownProps.match.params.propertyId)),
    addSchedule: payload => dispatch(addSchedule(ownProps.match.params.propertyId, payload)),
    removePanelSchedule: scheduleNumber =>
      dispatch(removePanelSchedule(ownProps.match.params.propertyId, scheduleNumber)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnrecognizedSchedules));
