import { useParams, useHistory } from 'react-router-dom';

export const useToDetail = () => {
  const { propertyId } = useParams();
  const history = useHistory();

  const goToDetail = (type, id) => {
    history.push(`/properties/${propertyId}/units/tours/automated/detail/${type}/${id}`);
  };

  return {
    goToDetail,
  };
};
