import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import Yup from 'yup';

import InputScaffold from '../../../../../common/forms/InputScaffold';

class AccessPointForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    submitFunc: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    doors: PropTypes.array.isRequired,
    enableAccessControlDoorInput: PropTypes.bool.isRequired,
  };

  handleSubmit = async (values, { setSubmitting, setStatus }) => {
    const { submitFunc, onSuccess } = this.props;

    const submitValues = { ...values };
    if (!submitValues.door_id) {
      submitValues.door_id = null;
    }

    setStatus(null);
    const action = await submitFunc(submitValues);
    setSubmitting(false);

    if (action.response.ok) {
      onSuccess();
    } else {
      setStatus((action.json && action.json.message) || 'Unknown error');
    }
  };

  render() {
    const { initialValues, enableAccessControlDoorInput, onCancel, doors } = this.props;

    return (
      <Formik
        initialValues={{
          name: initialValues?.name || '',
          device_id: initialValues?.device_id || '',
          door_id: initialValues?.door_id || '',
          is_nfc_required: initialValues?.is_nfc_required || true,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .trim()
            .max(32)
            .label('Name')
            .required('Please enter an Access Point Name'),
          is_nfc_required: Yup.bool().required(),
          device_id: Yup.string()
            .trim()
            .label('Device ID')
            .required('Please enter a Device ID'),
          door_id: Yup.string()
            .trim()
            .label('Door'),
        })}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          status,
          handleChange,
          handleSubmit,
          touched,
          isSubmitting,
          isValid,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <form className="access-point--form" onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius-top-right">
                <div className="page-header subheader underline">
                  <h4 className="h4">Access Point Information</h4>
                </div>
                <div>
                  <InputScaffold label="Access Point Name" required validation={touched.name && errors.name}>
                    <Field type="text" name="name" onChange={handleChange} value={values.name} />
                  </InputScaffold>
                  {enableAccessControlDoorInput && (
                    <InputScaffold label="Access Point Door" validation={touched.door_id && errors.door_id}>
                      <Field component="select" name="door_id" onChange={handleChange} value={values.door_id}>
                        <option disabled value=""></option>
                        {doors.map(door => (
                          <option value={door.id} key={door.id}>
                            {door.name}
                          </option>
                        ))}
                      </Field>
                    </InputScaffold>
                  )}
                  <InputScaffold
                    label="Access Point Device ID"
                    required
                    validation={touched.device_id && errors.device_id}
                  >
                    <Field type="text" name="device_id" onChange={handleChange} value={values.device_id} />
                  </InputScaffold>
                  <div className="checkbox-list--item">
                    <label>
                      <input
                        type="checkbox"
                        checked={values.is_nfc_required}
                        onChange={() => {
                          setFieldTouched('is_nfc_required', !values.is_nfc_required);
                          setFieldValue('is_nfc_required', !values.is_nfc_required);
                        }}
                        name={'is_nfc_required'}
                      />
                      <span htmlFor={'is_nfc_required'} className="label">
                        NFC Tap Required?
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="button__wrapper paper__footer radius-bottom-left radius-bottom-right">
                {status && <div className="input-validation">{status}</div>}
                <div className="buttons">
                  <button
                    type="submit"
                    disabled={isSubmitting || Object.keys(errors).length || !isValid}
                    className="button"
                  >
                    Save Access Point
                  </button>
                  <button type="button" className="button button--secondary" onClick={onCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export default AccessPointForm;
