import React from 'react';
import PropTypes from 'prop-types';

export const SGModal = ({ visible, children, handleCancel, width }) => {
  return visible ? (
    <div className="sg-modal">
      <div className="sg-modal-body" onClick={() => handleCancel(false)}>
        <div style={{ flex: `0 1 ${width}px` }} className="sg-modal-content" onClick={e => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

SGModal.propTypes = {
  handleCancel: PropTypes.func,
  visible: PropTypes.bool,
};
