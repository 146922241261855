import { connect } from 'react-redux';
import User from './User';
import {
  getUsers,
  addUsers,
  editUsers,
  removeUsers,
  getRolesByRoleLevel,
  getGroupPermission,
  getRolesByUser,
  getRoles,
  getPropertys,
} from '../../../redux/actions';
import { selectRoleOptions2, selectRoleOptions3, selectPropertyOptions } from '../../../redux/selectors';
import { getUserPermission } from '../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.RolesAndPermissions.users,
    roleOptions: selectRoleOptions2(state),
    rolesListLoading: state.RolesAndPermissions.rolesListLoading,
    userPermissionList: getUserPermission(state),
    permissionList: state.RolesAndPermissions.permissionList,
    roleOptions2: selectRoleOptions3(state),
    propertyOptions: selectPropertyOptions(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoles: () => dispatch(getRoles()),
    getPropertys: () => dispatch(getPropertys()),
    getRolesByRoleLevel: name => dispatch(getRolesByRoleLevel(name)),
    getRolesByUser: userId => dispatch(getRolesByUser(userId)),
    getUsers: payload => dispatch(getUsers(payload)),
    addUsers: payload => dispatch(addUsers(payload)),
    editUsers: payload => dispatch(editUsers(payload)),
    removeUsers: id => dispatch(removeUsers(id)),

    getGroupPermission: () => dispatch(getGroupPermission()),
  };
}

const UserContainer = connect(mapStateToProps, mapDispatchToProps)(User);

export default UserContainer;
