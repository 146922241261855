import React from 'react';
import { SGBtnBack, SGButton, useFetchGuest, useFetchTourGuests, useResidentAccessDetail } from '../../../common';
import Loading from '../../../../../../../../../common/Loading';
import Icon from '../../../../../../../../../common/icons/icon';

import {
  SGDetailHeader,
  SGDetailInfo,
  SGDetailImages,
  SGLinkToTour,
  ResidentApproveModal,
  ResidentDenyModal,
  useResidentDenyModal,
} from '../../../common';
import { useResidentApproveModal } from '../../../common/views/modals/ResidentApproveModal';
import { useResidentGuestLimitModal } from '../../../common/views/modals/ResidentGuestLimitModal/hooks';
import { ResidentGuestLimitModal } from '../../../common/views/modals/ResidentGuestLimitModal';

export const SGPendingDetails = () => {
  const { isLoad, guest } = useFetchGuest();

  const { isLoad: isScheduledLoad, dataLoaded: scheduledDataLoaded } = useFetchTourGuests({
    search: '',
    sort_by: 0,
    type: 'scheduled',
  });

  const { handleDenyCancel, handleDenyOpen, isDenyOpen } = useResidentDenyModal();
  const { handleApproveCancel, handleApproveOpen, isApproveOpen } = useResidentApproveModal();
  const { handleLimitModalClose, handleLimitModalOpen, isLimitOpen } = useResidentGuestLimitModal();

  const { handleRevokeAccess, handleEnableAccess } = useResidentAccessDetail({
    guestId: guest?.id,
    historyEnableAccessRedirectTo: '/automated/edit/pending',
    historyRevokeAccessRedirectTo: '/automated/edit/pending',
  });

  if (isLoad || isScheduledLoad) return <Loading />;

  const { first_name, last_name, imageUrl } = guest;
  const handleApproveClick = scheduledDataLoaded.length < 19 ? handleApproveOpen : handleLimitModalOpen;

  return (
    <>
      <div className="m-t20 p-l20">
        <SGBtnBack btnTitle="Return to previous page" />
      </div>
      <div className="detail-page-content">
        <SGDetailHeader title="Pending Tour" name={`${first_name} ${last_name}`}>
          <div className="btns-row">
            <SGButton
              className="button-positive btn-approve"
              title="Approve Tour"
              iconComponent={<Icon icon="ApproveIcon" />}
              handleClick={handleApproveClick}
            />
            <SGButton
              className="detail-button-revoke"
              title="Deny Tour"
              iconComponent={<Icon icon="DenyIcon" />}
              handleClick={handleDenyOpen}
            />
          </div>
        </SGDetailHeader>
        <SGDetailInfo {...guest} />
        <SGDetailImages imageUrl={imageUrl} />
        <SGLinkToTour />
      </div>

      <ResidentApproveModal
        width={940}
        visitorName={`${first_name} ${last_name}`}
        handleApprove={handleEnableAccess}
        handleCancel={handleApproveCancel}
        visible={isApproveOpen}
      />

      <ResidentDenyModal
        width={940}
        visitorName={`${first_name} ${last_name}`}
        handleDeny={handleRevokeAccess}
        handleCancel={handleDenyCancel}
        visible={isDenyOpen}
      />

      <ResidentGuestLimitModal width={940} close={handleLimitModalClose} visible={isLimitOpen} />
    </>
  );
};
