import React from 'react';

function Accept() {
  /* eslint-disable max-len */
  return (
    <svg id="Capa_1" height="512" viewBox="0 0 497 497" width="512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="248.5" cy="248.5" fill="#bbec6c" r="248.5" />
        <path
          d="m248.5 0c-5.04 0-10.04.168-15.006.464 130.253 7.761 227.115 115.833 227.115 248.036s-96.862 240.275-227.116 248.036c4.967.296 9.967.464 15.007.464 137.243 0 248.5-111.257 248.5-248.5s-111.257-248.5-248.5-248.5z"
          fill="#a2e62e"
        />
        <circle cx="248.5" cy="248.5" fill="#4fc123" r="212.109" />
        <path
          d="m248.5 36.391c-5.046 0-10.048.189-15.006.535 110.141 7.7 197.103 99.476 197.103 211.574 0 112.099-86.962 203.874-197.103 211.574 4.958.347 9.96.535 15.006.535 117.145 0 212.109-94.965 212.109-212.109s-94.964-212.109-212.109-212.109z"
          fill="#47a920"
        />
        <path
          d="m376.8 205.85-135.39 135.4c-14.36 14.35-37.62 14.35-51.97 0l-69.24-69.24c-7.96-7.95-7.96-20.85 0-28.8l20.18-20.18v-.01l6.84-6.84c7.95-7.95 20.85-7.95 28.8 0l34.65 34.65c3.72 3.73 9.77 3.73 13.49 0l98.81-98.81c7.95-7.95 20.85-7.95 28.8 0l25.03 25.03c7.96 7.96 7.96 20.85 0 28.8z"
          fill="#d1f19e"
        />
        <g fill="#bbec6c">
          <path d="m224.16 250.83-11.47 11.47c-4.52 4.53-11.85 4.56-16.42.07l-35.07-34.43c-5.63-5.64-13.74-7.26-20.82-4.91v-.01l6.84-6.84c7.95-7.95 20.85-7.95 28.8 0l34.65 34.65c3.72 3.73 9.77 3.73 13.49 0z" />
          <path d="m376.804 177.053-25.032-25.032c-7.953-7.953-20.847-7.953-28.8 0l-6.472 6.472c5.826-.543 11.841 1.413 16.301 5.874l13.99 12.687c7.953 7.953 7.953 20.847 0 28.8l-124.168 125.798c-8.865 8.865-21.133 12.246-32.599 10.158 14.391 13.781 37.223 13.602 51.382-.557l135.399-135.399c7.952-7.953 7.952-20.848-.001-28.801z" />
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Accept;
