export default {
  adminNavigation: {
    property: 'Properties',
    accessControl: 'Access Control',
    offers: 'Offers',
    financials: 'Financials',
    reports: 'Properties',
    rolesAndPermissions: 'Roles and Permissions',
    adminManagement: 'Admin Management',
    events: 'Events',
    stripeSettings: 'Stripe Settings',
  },

  propertyNavigation: {
    backToAdmin: 'Back to Admin',
    backToPropertiesList: 'Back to Properties List',

    units: {
      title: 'Units & Residents',
      subRoutes: {
        unitList: 'Unit List',
        residentList: 'Resident List',
        selfGuidedTours: 'Self-Guided Tours',
      },
    },

    smartHome: {
      title: 'Smart Home',
      subRoutes: {
        batteryLevels: 'Battery Levels',
        vacantUnits: 'Vacant Units',
      },
    },

    residentMessages: {
      title: 'Resident Messages',
      subRoutes: {
        maintenanceRequests: 'Maintenance Requests',
        helpFromManagement: 'Help from Management',
      },
    },

    smartAccess: {
      title: 'Smart Access',
      subRoutes: {
        propertyGuests: 'Property Guests',
        residentGuests: 'Resident Guests',
        accessLog: 'Access Log',
        areas: 'Areas',
        doors: 'Doors',
        schedules: 'Schedules',
        accessProfiles: 'Access Profiles',
        accessPoints: 'Access Points',
      },
    },

    smartCommunity: {
      title: 'Smart Community',
      subRoutes: {
        amenities: 'Amenities',
        announcements: 'Announcements',
        events: 'Events',
        vendors: 'Vendors',
        discussion: 'Discussion',
        pendingDiscussions: 'Pending Discussions',
      },
    },

    communityViews: {
      title: 'Community Views',
      subRoutes: {
        cameras: 'Cameras',
        archivedVideo: 'Archived Video',
        notifications: 'Notifications',
        settings: 'Settings',
      },
    },

    settings: {
      title: 'Settings',
      subRoutes: {
        portalUsers: 'Portal Users',
        communitySettings: 'Community Settings',
        residentFeatures: 'Resident Features',
        maintenance: 'Maintenance',
        integrations: 'Integrations',
        unitGroups: 'Unit Groups',
        mailHubs: 'Mail Hubs',
        devices: 'Devices',
      },
    },
  },
};
