import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch, Route } from 'react-router-dom';

import NotFound from '../../common/NotFound';
import Announcements from './views/Announcements';
import Events from './views/Events';
import Amenities from './views/Amenities';
import LegacyAmenities from './views/LegacyAmenities';
import Discussion from './views/Discussion';
import PendingPostList from './views/PendingPostList';
import Vendors from './views/Vendors';

class Community extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
  };

  render() {
    const { match, property, isAmenitiesV2Enabled } = this.props;
    const AmenitiesComponent = isAmenitiesV2Enabled ? Amenities : LegacyAmenities;

    return (
      <Switch>
        <Route path={`${match.path}/announcements`} component={Announcements} />
        <Route
          exact
          path={match.path}
          render={() => (
            <Redirect
              to={property.get('enabled_announcements') ? `${match.url}/announcements` : `${match.url}/amenities`}
            />
          )}
        />
        <Route path={`${match.path}/events`} component={Events} />
        <Route path={`${match.path}/vendors`} component={Vendors} />
        <Route path={`${match.path}/amenities`} component={AmenitiesComponent} />
        <Route path={`${match.path}/discussion`} component={Discussion} />
        <Route exact path={`${match.path}/pending-discussions`} component={PendingPostList} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default Community;
