import moment from 'moment';
import React from 'react';
import Icon from '../../../common/icons/icon';
import { FinancialSummary } from '../../Property/views/Community/views/Events/AddEvent/Financials/FinancialSummary';

const VendorEventDetailsTab = ({ eventDetails }) => {
  const eventDate = moment(eventDetails.event_date).format('MM/DD/YYYY');
  const startTime = moment(eventDetails.start_time, 'hh:mm').format('hh:mma');
  const endTime = moment(eventDetails.end_time, 'hh:mm').format('hh:mma');

  return (
    <div>
      <div className="paper__grid">
        <div className="paper__section--grid-item">
          <section className="paper__section">
            <h4 className="section-header h4">Event Details</h4>
            <div className="details--read-only">
              <div className="read-only--label">Event Title</div>
              <div className="read-only--value title">{eventDetails.title}</div>
            </div>
            <div className="details--read-only__wrapper">
              <div className="details--read-only">
                <div className="read-only--label">Event Date</div>
                <div className="read-only--value">{eventDate}</div>
              </div>
              <div className="details--read-only">
                <div className="read-only--label">Event Start &amp; End Time</div>
                <div className="read-only--value flex">
                  <span>{startTime}</span>
                  <Icon icon="ArrowLongRight" />
                  <span>{endTime}</span>
                </div>
              </div>
              <h4 className="section-header h4">Financials</h4>
              <FinancialSummary
                priceInCents={eventDetails.price_amount ? eventDetails.price_amount : null}
                communityFee={eventDetails.community_fee_percent}
                hasVendor={true}
              />
            </div>
          </section>
        </div>

        <div className="paper__section--grid-item">
          <section className="paper__section">
            <h4 className="section-header h4">Event Image</h4>
            <div className="details--read-only__wrapper">
              <div className="details--read-only">
                <div className="read-only--label">Event Image</div>
                {eventDetails.image_url ? (
                  <img src={eventDetails.image_url} alt="Event Preview" />
                ) : (
                  <div className="no-image-provided">No Image Provided</div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default VendorEventDetailsTab;
