import { connect } from 'react-redux';
import AddResident from './AddResident';
import { selectorIsAdmin } from '../../../../../../../../../../redux/selectors';
import { addResident } from '../../../../redux/actions';
import { selectUnitHasSmartLock } from '../../../../redux/selectors';
import { getProfiles } from '../../../../../../../../redux/actions';
import {
  selectAccessControlProfileOptions,
  selectDefaultAccessControlProfilesForUserType,
} from '../../../../../../../../redux/selectors';
import { setUnitAccessCodeOnResident, setCommunityAccessCodeOnResident } from '../../../../redux/actions';
import { getUserPermission } from '../../../../../../../../../../redux/selectors';

function mapStateToProps(state, props) {
  return {
    loaded: state.property.unit.activeUnitLoaded,
    property: state.property.activeProperty,
    hasPanel: !!state.property.activeProperty.get('clubhouse_panel_id'),
    unit: state.property.unit.activeUnit,
    isAdmin: selectorIsAdmin(state),
    accessControlProfilesLoaded: state.property.accessControlProfilesLoaded,
    accessControlProfileOptions: selectAccessControlProfileOptions(state),
    accessControlProfileDefaults: selectDefaultAccessControlProfilesForUserType(state, 'resident'),
    unitHasSmartLock: selectUnitHasSmartLock(state),
    communityHasAccessControlEnabled: state.property.activeProperty.get('admin_enabled_access_control'),
    userPermissionList: getUserPermission(state),
  };
}

const AddResidentContainer = connect(mapStateToProps, {
  addResident,
  getProfiles,
  setUnitAccessCodeOnResident,
  setCommunityAccessCodeOnResident,
})(AddResident);

export default AddResidentContainer;
