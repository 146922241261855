export default {
  main: {
    subHeader: 'Events',
    addEventBtn: 'Add Event',
    csvBtn: 'Download CSV',
    canceledEventAlert: '{{event}} has been canceled',
    tabs: {
      name: 'Event Name',
      date: 'Date',
      time: 'Start/End Time',
      registrants: 'Registrants',
      attendees: 'Max Attendees',
      status: 'Status',
    },
    noStripeError: {
      part_1_created:
        'Paid Event cannot be created because the Stripe account for your property has not been connected. Please connect on the ',
      part_1_selected:
        'Paid Event cannot be selected because the Stripe account for your property has not been connected. Please connect on the ',
      part_2: 'Community Settings',
      part_3: ' page.',
    },
  },
  eventItem: {
    editEvent: 'Edit Event',
    cancelEvent: 'Cancel Event',
    activeStatus: 'Active',
    canceledStatus: 'Canceled',
    endedStatus: 'Ended',
    date: '{{date, MM/DD/YYYY}}',
  },
  modals: {
    cancelEvent: {
      header: 'Cancel Event',
      provideReason:
        'To cancel this event, please provide a reason for cancelation, which will be emailed to all current registrants',
      inputLabel: 'Cancelation Reason',
      cancelEventBtn: 'Cancel Event',
      cancelBtn: 'Cancel',
      alreadyCancelledErr: 'This event has already been canceled.',
      hasStartedErr: 'This event has started and cannot be canceled.',
      invalidLengthErr: 'Reason must be 2000 characters or fewer',
      noReasonErr: 'Please enter a reason',
    },
    cancelAddEvent: {
      title: 'Cancel Event',
      body: 'Are you sure you want to cancel creating this event?',
      confirmButtonTitle: 'Cancel Creating Event',
      cancelButtonTitle: 'Cancel',
    },
    cancelEventRegistration: {
      title: 'Cancel Registration',
      body: 'Are you sure you want to cancel this event registration?',
      submit: 'Cancel Registration',
      alertMessage: 'Registration for {{firstName}} {{lastName}} canceled successfully', //!
    },
    cancelEventRegistrationWaitList: {
      title: 'Cancel WaitList',
      body: 'Are you sure you want to cancel this event waitlist?',
      submit: 'Cancel WaitList',
      alertMessage: 'Waitlist for {{firstName}} {{lastName}} canceled successfully',
    },
    cancelEventRegistrationPending: {
      title: 'Cancel Pending',
      body: 'Are you sure you want to cancel this event Pending?',
      submit: 'Cancel Pending',
    },
  },
  addEvent: {
    title: 'Add Event',
    backBtn: 'Back',
    nextBtn: 'Next',
    cancelBtn: 'Cancel',
    savingBtn: 'Saving...',
    saveEventBtn: 'Save Event',
    tabs: {
      'Event Details': 'Event Details',
      Registrations: 'Registrations',
      Financials: 'Financials',
    },
    errors: {
      errorAddingEvent: 'There was an error when attempting to create the event. Please contact support.',
    },
  },
  addDetailsTab: {
    header: 'Event Details',
    attendeeSettings: 'Attendee Settings',
    dateFormat: '{{date, MM/DD/YYYY}}',
    inputs: {
      title: 'Title',
      descr: 'Description',
      date: 'Event Date',
      location: 'Event Location',
      startTime: 'Start Time',
      endTime: 'End Time',
      instructions: 'Instructions',
      atendeesMin: 'Minimum Attendees',
      atendeesMax: 'Maximum Attendees',
      waitList: 'Waitlist',
      pendingDuration: 'Pending Duration (HOURS)',
      rsvpDate: 'RSVP Cut-Off Date',
      rsvpTime: 'RSVP Cut-Off Time',
    },
    eventImage: {
      header: 'Event Image',
      dragImg: 'Drag an image here to upload or select a file from your device',
      uploadImg: 'Upload Image',
      subHeader: 'Event image will be displayed in Events section of the App.',
      remove: 'Remove Image',
    },
    instructionsHead: 'Confirmation Instructions',
    instructionsText: 'This will be sent to residents as an email after they have registered for this event.',
  },

  addRegisterTab: {
    title: 'Registration Settings',
    text: 'Limit registration to certain Unit Groups',
    allResidentsCheckbox: 'All Residents',
  },

  addFinancesTab: {
    title: 'Financials',
    eventType: 'EVENT TYPE',
    free: 'Free',
    paid: 'Paid',
  },
  eventSummary: {
    title: 'New Event Preview',
    subHeader: 'Preview this new event before saving.',
    details: 'Event Details',
    eventTitle: 'EVENT TITLE',
    description: 'Event Description',
    date: 'Event Date',
    location: 'Event Location',
    time: 'Event Start & End Time',
    type: 'Event Type',
    waitList: 'WAITLIST',
    free: 'FREE',
    paid: 'PAID',
    waitListON: 'ON',
    waitListOFF: 'OFF',
    rspvTime: 'RSVP Cutoff Time',
    settings: 'Registration Settings',
    groups: 'Unit Groups Able To Register',
    allResidents: 'All Residents',
    image: 'Event Image',
    noImage: 'No Image Provided',
    instructionsTitle: 'Confirmation Instructions',
    instructionsText: 'Instructions',
    noInstructions: 'None Provided',
    financials: 'Financials',
    vendor: 'Vendor',
    cutOffDate: 'Refund Cutoff Date',
    cutOffTime: 'Refund Cutoff Time',
    fee: 'Cancelation Fee Percent',
    savingBtn: 'Saving...',
    confirmBtn: 'Confirm',
    cancelBtn: 'Cancel',
    price: 'Price Per Ticket',
    communityFee: 'Community Fee',
    SEfee: 'SE fee',
    revenue: 'Estimated Revenue Per Attendee',
    bigFeeError: 'Fees cannot add to more than 100%. Please update before continuing.',
  },
  /*









*/
  editEvent: {
    cancelKebab: 'Cancel Event',
    tabs: {
      'Event Details': 'Event Details',
      'Revenue Details': 'Revenue Details',
      Registrations: 'Registrations',
      'Refund Policy': 'Refund Policy',
      Communications: 'Communications',
    },
    eventDetails: {
      header: 'Event Details',
      title: 'Title',
      descr: 'Description',
      location: 'Event Location',
      dateTitle: 'Event Date',
      date: '{{date, MM/DD/YYYY}}',
      timeTitle: 'Event Start & End Time',
      instructionsHead: 'Confirmation Instructions',
      instructionsContent: 'This will be sent to residents as an email after they have registered for this event.',
      instructionsTitle: 'Instructions',
      imageTitle: 'Event Image',
      imageContent: 'Event image will be displayed in Events section of the App.',
      removeImgage: 'Remove Image',
      attendeeSettings: 'Attendee Settings',
      minAttendees: 'Minimum Attendees',
      maxAttendees: 'Maximum Attendees',
      waitList: 'Waitlist',
      pendingDuration: 'Pending Duration (HOURS)',
      saveBtn: 'Save Event',
      cancelBtn: 'Cancel',
    },
    cancelEditModal: {
      title: 'Cancel Editing Event',
      body: 'Are you sure you want to cancel editing this event?',
      confirmButtonTitle: 'Cancel Editing Event',
      cancelButtonTitle: 'Cancel',
    },
    alertMinRegistrants: {
      body: 'This event does not have the minimum number of registrants. Do you want to cancel it?',
      cancel: 'Cancel Event',
    },
    closeWaitListModal: {
      header: 'Remove Waitlist',
      body: 'Are you sure you want to remove this event waitlist?',
      remove: 'Remove Waitlist',
      cancel: 'Cancel',
    },
    readOnlyDetails: {
      header: 'Event Details',
      time: 'Event Start & End Time',
      instructions: 'Confirmation Instructions',
      noInstructions: 'No confirmation instructions',
      image: 'Event Image',
      body: 'Event image will be displayed in Events section of the App.',
      noImage: 'No image was selected for this event.',
      attendees: 'Attendee Settings',
      rspv: 'RSVP Cut-off Date & Time',
      backBtn: 'Back',
      atendeesMax: 'MAX ATTENDEES',
      atendeesMin: 'MIN ATTENDEES',
      title: 'TITLE',
      descr: 'DESCRIPTION',
      location: 'EVENT LOCATION',
      dateTitle: 'EVENT DATE',
    },
    registrations: {
      header: 'Registration Settings',
      subHeader: 'Unit Groups able to register',
      allResidents: 'All Residents',
      downloadCSV: 'Download CSV',
      registerResidentsBtn: 'Register Residents',
      attendeesTitle: 'Attendees',
      resName: 'Resident Name',
      resDate: 'Date of Registration',
      attendees: 'Attendees',
      status: 'Status',
      waitListTitle: 'Waitlist',
      activeStatus: 'ACTIVE',
      canceledStatus: 'ADMIN_CANCELED',
      cancelKebab: 'Cancel Registration',
      cancelPendingKebab: 'Cancel Pending',
      cancelWaitlistKebab: {
        cancel: 'Cancel Waitlist', //!
        move: 'Move to Top', //!
      },
    },
  },
  guestRegistrationTab: {
    title: 'Guest Registration',
    body: 'Register guests by selecting each attending resident and adding additional guests',
    inputPlaceholder: 'Search Residents & Unit',
    importCsvButton: 'import CSV File',
    registerButton: 'Register',
    regName: 'NAME',
    regUnit: 'UNIT',
    regEmail: 'EMAIL',
    regAdditional: 'ADDITIONAL',

    importCsvModal: {
      title: 'Import CSV File',
      label: 'Import Using CSV File',
      helperText: 'File Columns: Email, Number of guests with you not included',
      submit: 'Submit',
    },
    importCsvResModal: {
      title: 'CSV imported successfully!',
    },
    registrationConfirmModal: {
      title: 'Confirm Registration?',
    },
    registrationResultModal: {
      successTitle: 'Register successfully!',
      successBody: 'The registration is completed.',
      errorTitle: 'Registration Error',
      duplicate: 'Duplicate submission exists. Click OK to refresh the page.', //!
      errorBody:
        'Maxinum attendens has been reached <br />' +
        'Would you like to increase the max attendens? <br />' +
        'OR add any additional guest to the waitlist',
      increaseMax: 'Increase Max',
      waitlist: 'Waitlist',
      deselect: 'No, go back and deselect',
    },
  },
  communicationsTab: {
    title: 'Communications',
    body:
      'You can send a custom message that will be communicated to all residents that have registered for this event.',
    message: 'MESSAGE',
    submit: 'Send Communication',
    successMessage: 'Message was successfully sent!',
    errorMessage: 'Message was not sent!', //!
    historyTitle: 'Communications History',
    dateSent: 'DATE SENT',
    show: 'Show Message',
    hide: 'Hide Message',
  },
};
