import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AmenityItem from './AmenityItem';
import EmptyPlaceholder from '../../../../../../../../common/EmptyPlaceholder';
import Loading from '../../../../../../../../common/Loading';
import SubHeader from '../../../../../../../../common/SubHeader';

import Yup from 'yup';
import { Formik } from 'formik';

import InputScaffold from '../../../../../../../../common/forms/InputScaffold';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max('64', 'Name may only be 64 characters or less')
    .required('Please enter a name'),
  description: Yup.string()
    .trim()
    .max('800', 'Description may only have 800 characters or less'),
  terms_conditions: Yup.string()
    .trim()
    .max('800', 'Terms and Conditions may only have 800 characters or less'),
});

class Amenities extends Component {
  state = {
    showAdd: false,
  };

  toggleShowAdd = () => this.setState(state => ({ showAdd: !state.showAdd }));

  componentWillMount() {
    this.props.getAmenities();
  }

  renderPlaceholder() {
    return (
      <EmptyPlaceholder buttonText="Add Amenity" onClick={this.toggleShowAdd}>
        <p>
          Keeping this area empty will hide the Amenities option in the SmartExperience mobile app. Once you add content
          here, Residents can see it in the app.
        </p>
      </EmptyPlaceholder>
    );
  }

  render() {
    const {
      property,
      amenitiesList,
      amenityListLoaded,
      editAmenities,
      removeAmenities,
      createAmenities,
      match,
      isAdmin,
    } = this.props;
    const { showAdd } = this.state;

    if (!amenityListLoaded) {
      return <Loading />;
    }

    const mappedList = amenitiesList.map(amenity => {
      return (
        <AmenityItem
          createAmenities={createAmenities}
          editAmenities={editAmenities}
          removeAmenities={removeAmenities}
          validationSchema={validationSchema}
          key={amenity.get('id')}
          data={amenity}
          match={match}
          isAdmin={isAdmin}
        />
      );
    });

    return (
      <div>
        <div className="container">
          <SubHeader
            title="Amenities"
            actions={[
              amenitiesList.size > 0 && (
                <button className="button" disabled={showAdd} onClick={this.toggleShowAdd}>
                  Add Amenity
                </button>
              ),
            ]}
          />
          {mappedList.size !== 0 && (
            <div className="page-desc">
              Keeping this area empty will hide the Amenities option in the SmartExperience mobile app. Once you add
              content here, Residents can see it in the app.
            </div>
          )}

          <div>
            {showAdd ? (
              <Formik
                initialValues={{
                  name: '',
                  description: '',
                  terms_conditions: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  const payload = {
                    name: values.name,
                    description: values.description,
                    terms_conditions: values.terms_conditions,
                  };
                  return createAmenities(property.get('id'), payload)
                    .then(() => {
                      actions.setSubmitting(false);
                      return this.toggleShowAdd();
                    })
                    .catch(err => actions.setErrors({ submitting: 'Submission Failed' }));
                }}
                render={({ values, errors, setFieldTouched, touched, handleChange, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} className="add-amenity-form">
                    <div className="paper radius-top-left radius-top-right">
                      <InputScaffold label="Amenity Name" required validation={touched.name && errors.name}>
                        <input
                          type="text"
                          name="name"
                          onChange={e => {
                            handleChange(e);
                            setFieldTouched('name');
                          }}
                          value={values.name}
                        />
                      </InputScaffold>

                      <InputScaffold label="Description" validation={errors.description}>
                        <textarea name="description" onChange={handleChange} value={values.description} />
                      </InputScaffold>

                      <InputScaffold label="Terms and Conditions" validation={errors.terms_conditions}>
                        <textarea name="terms_conditions" onChange={handleChange} value={values.terms_conditions} />
                      </InputScaffold>
                    </div>
                    <div className="paper__footer radius-bottom-left radius-bottom-right">
                      <div>{errors.submitting}</div>
                      <button type="submit" disabled={isSubmitting || Object.keys(errors).length} className="button">
                        Save
                      </button>
                      <button className="button button--secondary" onClick={this.toggleShowAdd}>
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
              />
            ) : null}
            {mappedList.size === 0 && !showAdd ? this.renderPlaceholder() : showAdd ? null : mappedList}
          </div>
        </div>
      </div>
    );
  }
}

Amenities.propTypes = {
  amenitiesList: PropTypes.object.isRequired,
  getAmenities: PropTypes.func.isRequired,
  createAmenities: PropTypes.func.isRequired,
  editAmenities: PropTypes.func.isRequired,
};

export default Amenities;
