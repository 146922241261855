import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import NotFound from '../../views/NotFound/index.js';

const PrivateRoute = ({ component: Component, roles, userRoles, location, ...rest }) => {
  const authorized = userRoles.some(role => roles.includes(role));

  return <Route {...rest} render={props => (authorized ? <Component {...props} /> : <NotFound />)} />;
};
const mapStateToProps = state => ({
  userRoles: state.roles,
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
