import React, { useState } from 'react';
import i18n, { languages } from '../i18n';

import Select from 'react-select';

export function LanguageSelector() {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

  const handleLanguageChange = selectedOption => {
    setLanguage(selectedOption.value);
    i18n.changeLanguage(selectedOption.value);
    localStorage.setItem('language', selectedOption.value);
  };

  return (
    <Select
      searchable={false}
      value={languages.find(lang => lang.value === language)}
      isClearable={false}
      options={languages}
      onChange={handleLanguageChange}
    />
  );
}
