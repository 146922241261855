import React from 'react';
import classNames from 'classnames';
import { SGTooltip } from './SGTooltip';

export const SGUnitCheckbox = ({ id, unitNumber, squareFootage, handleClick, checked }) => {
  const truncateUnitNumber = () => (unitNumber.length > 6 ? `${unitNumber.slice(0, 6)}...` : unitNumber);

  return (
    <SGTooltip placement="bottom" content={unitNumber} disabled={unitNumber.length <= 6}>
      <label className={classNames('sg-unit-checkbox', { green: checked })}>
        <div className="sg-unit-checkbox-left">
          <div className="sg-unit-checkbox-idx">{truncateUnitNumber()}</div>
          {squareFootage ? <div className="sg-unit-checkbox-sqft">{squareFootage} sqft</div> : null}
        </div>

        <div className="sg-unit-checkbox-right">
          <input
            id={id}
            type="checkbox"
            name="unit_id"
            className="margin-clear-all"
            value={id}
            checked={checked}
            onChange={handleClick}
          />
          <span>model unit</span>
        </div>
      </label>
    </SGTooltip>
  );
};
