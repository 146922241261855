import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Loading from '../../common/Loading';
import Pager from '../../common/Pager';
import EmptyPlaceholder from '../../common/EmptyPlaceholder';
import Kebab from '../../common/Kebab/index';
import Icon from '../../common/icons/icon';
import Select from 'react-select';
import querystring from 'query-string';

class PropertiesTable extends Component {
  state = {
    openKebabPropertyId: null,
  };

  onKebabClick = propertyId => this.setState({ openKebabPropertyId: propertyId });

  closeKebab = () => this.setState({ openKebabPropertyId: null });

  toggleDemoFilter = value => {
    const { history, location } = this.props;
    const parsedUrl = querystring.parse(location.search);

    let newParams = Object.assign(parsedUrl, { show_demos: value.value, page: 0 });
    return history.push({ pathname: location.pathName, search: querystring.stringify(newParams) });
  };

  renderKebab = property => {
    const { match, history, userPermission } = this.props;
    const { openKebabPropertyId } = this.state;

    const editUrl = `${match.url}/${property.get('id')}/edit`;
    const createDemoUrl = `${match.url}/${property.get('id')}/create-demo`;

    return (
      <div>
        {(userPermission.editAProperty || userPermission.createADemoProperty) && (
          <Kebab
            onClick={() => this.onKebabClick(property.get('id'))}
            onDisengage={this.closeKebab}
            menuOpened={property.get('id') === openKebabPropertyId}
          >
            <div className="kebab__menu">
              {userPermission.editAProperty && (
                <div
                  className="kebab__menu--item"
                  onClick={() => {
                    history.push(editUrl);
                    this.closeKebab();
                  }}
                >
                  <Icon icon="Edit" />
                  Edit
                </div>
              )}
              {userPermission.createADemoProperty && (
                <div
                  className="kebab__menu--item"
                  onClick={() => {
                    history.push(createDemoUrl);
                    this.closeKebab();
                  }}
                >
                  <Icon icon="Add" />
                  Create Demo
                </div>
              )}
            </div>
          </Kebab>
        )}
      </div>
    );
  };

  renderTable() {
    const { isAdmin, list, userPermission } = this.props;

    if (list.size === 0) {
      if (!isAdmin) {
        return <EmptyPlaceholder />;
      } else {
        if (userPermission.createANewProperty) {
          return <EmptyPlaceholder buttonText="Add Property" linkTo="/add-property" />;
        } else {
          return <div className="zero-content" />;
        }
      }
    }

    return (
      <table className="table-units-list">
        <thead>
          <tr>
            <th>Property Name</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(property => {
            return (
              <tr key={property.get('id')}>
                <td>
                  {userPermission.accessPropertyPortal ? (
                    <Link to={`/properties/${property.get('id')}`}>{property.get('name')}</Link>
                  ) : (
                    <div style={{ fontWeight: 'bold' }}>{property.get('name')}</div>
                  )}
                </td>
                {isAdmin ? <td className="table__align-right">{this.renderKebab(property)}</td> : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    const { isAdmin, list, loaded, locationObj, total, userPermission } = this.props;
    if (!loaded) {
      return <Loading />;
    }
    const { location } = this.props;
    const parsedUrl = querystring.parse(location.search);
    let showDemos = parsedUrl?.show_demos === 'true';

    return (
      <div className="properties-table__wrapper">
        <div className="properties-table__title">
          <h4 className="h4">{`${total} ${total !== 1 ? 'Properties' : 'Property'}`}</h4>
          <div className="page-header__actions">
            <div className="react-select-scaffold">
              {userPermission.showHideDemos && (
                <Select
                  clearable={false}
                  searchable={false}
                  inputProps={{ readOnly: true }}
                  options={[
                    { value: false, label: 'Hide Demos' },
                    { value: true, label: 'Show Demos' },
                  ]}
                  placeholder={'Display Demos...'}
                  onChange={value => this.toggleDemoFilter(value)}
                  value={showDemos}
                />
              )}
            </div>
            {isAdmin && list.size > 0
              ? userPermission.createANewProperty && (
                  <Link className="button" to="/add-property">
                    Add Property
                  </Link>
                )
              : null}
          </div>
        </div>
        {this.renderTable()}
        <div>
          <Pager initialPage={parseInt(locationObj.page, 10) || 0} pageSize={30} totalItems={total} />
        </div>
      </div>
    );
  }
}

PropertiesTable.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  list: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  filteredTotal: PropTypes.number.isRequired,
};

export default withRouter(PropertiesTable);
