import { connect } from 'react-redux';
import Announcements from './Announcements';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
  };
}

const MessagesContainer = connect(mapStateToProps)(Announcements);

export default MessagesContainer;
