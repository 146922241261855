import client from '../../../../../../../common/client';
import querystring from 'querystring';

export const getPropertyPanels = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/panels`),
    type: 'GET_PROPERTY_PANELS',
  };
};

export const createPanel = (propertyId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/panels`, payload),
    type: 'CREATE_PANEL',
  };
};

export const checkPanelEasyConnectStatus = (propertyId, panelId) => {
  return {
    promise: client.get(`properties/${propertyId}/panels/${panelId}/easyconnect-status`),
    type: 'CHECK_PANEL_EASYCONNECT_STATUS',
  };
};

export const checkOnboardPanelStatus = (propertyId, serialNumber, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${serialNumber}/onboard-panel-status`, payload),
    type: 'CHECK_ONBOARD_PANEL_STATUS',
  };
};

export const initializePanel = (propertyId, panelId) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${panelId}/initialize`),
    type: 'INITIALIZE_PANEL',
  };
};
export const findPanelById = (propertyId, panelId) => {
  return { promise: client.get(`properties/${propertyId}/panels/${panelId}`), type: 'FIND_PANEL_BY_ID' };
};

export const checkInitializePanelStatus = (propertyId, panelId, groupId) => {
  return {
    promise: client.get(`properties/${propertyId}/panels/${panelId}/initialize-status`, {
      group_id: groupId,
    }),
    type: 'CHECK_PANEL_INITIALIZE_STATUS',
  };
};

export const refreshPanelCommunicationOptions = (propertyId, panelId) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${panelId}/communication-options/refresh`),
    type: 'REFRESH_PANEL_COMMUNICATION_OPTIONS',
  };
};

export const updatePanel = (propertyId, panelId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/panels/${panelId}`, payload),
    type: 'UPDATE_PANEL_SUCCESS',
  };
};

export const setPanelCommunicationOptions = (propertyId, panelId) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${panelId}/communication-options`),
    type: 'SET_PANEL_COMMUNICATION_OPTIONS',
  };
};

export const setPanelSystemOptions = (propertyId, panelId, payload) => {
  return {
    promise: client.put(`properties/${propertyId}/panels/${panelId}/system-options`, payload),
    type: 'SET_PANEL_SYSTEM_OPTIONS',
  };
};

export const setPanelNetworkOptions = (propertyId, panelId, payload) => {
  return {
    promise: client.put(`properties/${propertyId}/panels/${panelId}/network-options`, payload),
    type: 'SET_PANEL_NETWORK_OPTIONS',
  };
};

export const createPropertyPanel = (propertyId, dmpSerialNumber) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/existing`, {
      dmp_serial_number: dmpSerialNumber,
    }),
    type: 'CREATE_PROPERTY_PANEL',
  };
};

export const getPanelDevices = (propertyId, panelId) => {
  return {
    promise: client.get(`properties/${propertyId}/panels/${panelId}/panel-devices`),
    type: 'GET_PANEL_DEVICES',
  };
};

export const createPanelDevice = (propertyId, panelId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${panelId}/devices`, payload),
    type: 'CREATE_DEVICE',
  };
};

export const refreshPanelZones = (propertyId, panelId) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${panelId}/zone-refresh`),
    type: 'REFRESH_PANEL_ZONES',
  };
};

export const createDMPDevice = (propertyId, panelId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${panelId}/dmp-devices`, payload),
    type: 'CREATE_DMP_DEVICE',
  };
};

export const requestZwaveDevice = (propertyId, panelId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${panelId}/zwave-devices`, payload),
    type: 'REQUEST_ZWAVE_DEVICE',
  };
};

export const requestRemoveZwaveDevice = (propertyId, panelId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${panelId}/zwave-devices/remove`, payload),
    type: 'REQUEST_REMOVE_ZWAVE_DEVICE',
  };
};

export const cancelPanelJobs = (propertyId, panelId, payload) => {
  return {
    promise: client.post(`properties/${propertyId}/panels/${panelId}/cancel-jobs`, payload),
    type: 'CANCEL_PANEL_JOBS',
  };
};

export const getJobStatus = (propertyId, jobId) => {
  return {
    promise: client.get(`properties/${propertyId}/job-statuses/${jobId}`),
    type: 'REQUEST_JOB_STATUS',
  };
};

export const browsePanels = (propertyId, params = {}) => {
  const queryParams = params ? '?' + querystring.stringify(params) : '';
  return {
    promise: client.get(`properties/${propertyId}/panels${queryParams}`),
    type: 'GET_PANELS_SEARCH',
  };
};

export const updatePanelDevice = (propertyId, panelId, deviceId, payload) => {
  return {
    promise: client.patch(`properties/${propertyId}/panels/${panelId}/devices/${deviceId}`, payload),
    type: 'UPDATE_DEVICE',
  };
};

export const removePanelDevice = (propertyId, panelId, deviceId) => {
  return {
    promise: client.delete(`properties/${propertyId}/panels/${panelId}/devices/${deviceId}`),
    type: 'REMOVE_DEVICE',
    deviceId,
  };
};

export const unpairVideoDoorbellDevice = doorbellId => {
  return {
    promise: client.put(`devices/doorbell/v1/${doorbellId}/unpair`),
    type: 'UNPAIR_DOORBELL',
    doorbellId,
  };
};

export const adminEditedVideoDoorbellFeature = payload => {
  return {
    promise: client.put(`devices/doorbell/v1/edit-doorbell-feature`, payload),
    type: 'ADMIN_EDITED_DOORBELL_FEATURE',
  };
};

export const removeVideoDoorbellDevice = doorbellId => {
  return {
    promise: client.delete(`devices/doorbell/v1/${doorbellId}/delete`),
    type: 'DELETE_DOORBELL',
    doorbellId,
  };
};

export const deletePanel = (propertyId, panelId) => {
  return {
    promise: client.delete(`properties/${propertyId}/panels/${panelId}`),
    type: 'DELETE_PANEL',
    panelId,
  };
};

export const pairPanelToUnit = (panelId, unitId) => {
  return {
    promise: client.post(`dmp-panels/${panelId}/unit-association`, { unitId }),
    type: 'PAIR_PANEL_TO_UNIT',
  };
};

export const unpairPanelFromUnit = (panelId, unitId) => {
  return {
    promise: client.delete(`dmp-panels/${panelId}/unit-association/${unitId}`),
    type: 'UNPAIR_PANEL_FROM_UNIT',
  };
};
export const getUnassignedVideoDoorbells = () => {
  return {
    promise: client.get(`devices/doorbell/v1/getUnassigned`),
    type: 'GET_UNASSIGNED_DOORBELLS',
  };
};

export const assignVideoDoorbellToUnit = payload => {
  return {
    promise: client.post(`devices/doorbell/v1/linkToUnit`, payload),
    type: 'ASSIGN_VIDEO_DOORBELL_TO_UNIT',
  };
};

export const resetUnitPairingStatus = () => ({ type: 'RESET_UNIT_PAIRING_STATUS' });
