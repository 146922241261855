import React from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { EventRsvpCutoffInput } from './EventRsvpCutoffInput';
import { EventImageInput } from '../../../../../../common/EventImageInput';
import Switch from '../../../../../../../../common/forms/Switch';

export const EventDetails = ({ errors, handleChange, isSubmitting, setFieldValue, touched, values, t, tTime }) => {
  return (
    <div className="paper__grid">
      <div className="paper__section--grid-item">
        <section className="paper__section">
          <div className="section-header h4">{t('addDetailsTab.header')}</div>
          <InputScaffold label={t('addDetailsTab.inputs.title')} required validation={touched.title && errors.title}>
            <input
              autoComplete="off"
              type="text"
              name="title"
              maxLength="120"
              disabled={isSubmitting}
              onChange={handleChange}
              value={values.title}
            />
          </InputScaffold>
          <InputScaffold
            label={t('addDetailsTab.inputs.descr')}
            required
            validation={touched.description && errors.description}
          >
            <textarea
              className="event-description"
              name="description"
              maxLength="2000"
              disabled={isSubmitting}
              onChange={handleChange}
              value={values.description}
            />
          </InputScaffold>

          <InputScaffold
            label={t('addDetailsTab.inputs.date')}
            required
            validation={touched.event_date && errors.event_date}
          >
            <DayPickerInput
              inputProps={{
                autoComplete: 'false',
                disabled: isSubmitting,
              }}
              name="date"
              value={values.event_date}
              placeholder=""
              format={tTime('dateFormat')}
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                numberOfMonths: 1,
                disabledDays: date => moment(date).isBefore(moment().subtract(1, 'day')),
              }}
              onDayChange={date => setFieldValue('event_date', date)}
              disabled={isSubmitting}
            />
          </InputScaffold>

          <InputScaffold
            label={t('addDetailsTab.inputs.location')}
            required
            validation={touched.location && errors.location}
          >
            <input
              type="text"
              name="location"
              autoComplete="off"
              maxLength="120"
              disabled={isSubmitting}
              onChange={handleChange}
              value={values.location}
            />
          </InputScaffold>

          <div className="double-input__wrapper">
            <InputScaffold
              className="double-input-item"
              label={t('addDetailsTab.inputs.startTime')}
              required
              validation={touched.start_time && errors.start_time}
            >
              <TimePicker
                name="start_time"
                showSecond={false}
                defaultValue={values.start_time}
                onChange={time => setFieldValue('start_time', time)}
                format={tTime('timeFormat')}
                allowEmpty={false}
                use12Hours={tTime('is12HoursTime')}
                inputReadOnly
                className="time-picker"
                disabled={isSubmitting}
              />
            </InputScaffold>
            <InputScaffold
              className="double-input-item"
              label={t('addDetailsTab.inputs.endTime')}
              required
              validation={touched.end_time && errors.end_time}
            >
              <TimePicker
                name="end_time"
                showSecond={false}
                defaultValue={values.end_time}
                onChange={time => setFieldValue('end_time', time)}
                format={tTime('timeFormat')}
                allowEmpty={false}
                use12Hours={tTime('is12HoursTime')}
                inputReadOnly
                disabled={isSubmitting}
              />
            </InputScaffold>
          </div>

          {/* @Design I duct taped the styles together, please help :)
          https://www.figma.com/file/i067e1YgrlOegT0HAg2yUt/Littlebird-Admin?node-id=589%3A201
          */}
          <div style={{ marginTop: 32 }}>
            <div className="section-header h4">{t('addDetailsTab.instructionsHead')}</div>
            <p className="font-small">{t('addDetailsTab.instructionsText')}</p>
            <InputScaffold
              label={t('addDetailsTab.inputs.instructions')}
              validation={touched.confirmation_details && errors.confirmation_details}
            >
              <textarea
                name="confirmation_details"
                maxLength="2000"
                rows="4"
                cols="50"
                disabled={isSubmitting}
                onChange={handleChange}
                value={values.confirmation_details}
              />
            </InputScaffold>
          </div>
        </section>
      </div>
      <div className="paper__section--grid-item">
        <section className="paper__section">
          <EventImageInput
            errors={errors}
            values={values}
            onChange={image_data => {
              setFieldValue('image_data', image_data);
            }}
            touched={touched}
          />
        </section>
        <section className="paper__section">
          <div className="section-header h4">{t('addDetailsTab.attendeeSettings')}</div>
          <EventRsvpCutoffInput
            disabled={isSubmitting}
            errors={errors}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
          <div className="double-input__wrapper">
            <InputScaffold
              label={t('addDetailsTab.inputs.atendeesMin')}
              required
              validation={touched.min_attendees && errors.min_attendees}
              className="double-input-item"
            >
              <input
                type="text"
                name="min_attendees"
                autoComplete="off"
                disabled={isSubmitting}
                onChange={event => {
                  setFieldValue('min_attendees', event.target.value.replace(/[^0-9]+/g, ''));
                }}
                value={values.min_attendees}
              />
            </InputScaffold>
            <InputScaffold
              label={t('addDetailsTab.inputs.atendeesMax')}
              required
              validation={touched.max_attendees && errors.max_attendees}
              className="double-input-item"
            >
              <input
                type="text"
                name="max_attendees"
                autoComplete="off"
                disabled={isSubmitting}
                onChange={event => {
                  setFieldValue('max_attendees', event.target.value.replace(/[^0-9]+/g, ''));
                }}
                value={values.max_attendees}
              />
            </InputScaffold>
            <InputScaffold className="double-input-item">
              <Switch
                label={t('addDetailsTab.inputs.waitList')}
                input={{
                  value: values.waitlist_allowed,
                  name: 'waitlist_allowed',
                  onChange: value => {
                    setFieldValue('waitlist_allowed', value);
                  },
                }}
              />
            </InputScaffold>
            {values.waitlist_allowed && (
              <InputScaffold
                label={t('addDetailsTab.inputs.pendingDuration')}
                required
                validation={touched.pending_duration && errors.pending_duration}
                className="double-input-item"
              >
                <input
                  type="text"
                  name="pending_duration"
                  disabled={isSubmitting}
                  onChange={event => {
                    setFieldValue('pending_duration', event.target.value.replace(/[^0-9]+/g, ''));
                  }}
                  value={values.pending_duration}
                />
              </InputScaffold>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
