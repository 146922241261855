import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import TelephoneEntryDevicesList from './views/TelephoneEntryDevicesList';
import AddTelephoneEntryDevice from './views/AddTelephoneEntryDevice';
import EditTelephoneEntryDevice from './views/EditTelephoneEntryDevice';

class TelephoneEntryDevices extends Component {
  componentDidMount() {}

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}/`} component={TelephoneEntryDevicesList} />
        <Route exact path={`${match.path}/add`} component={AddTelephoneEntryDevice} />
        <Route exact path={`${match.path}/:telephoneEntryDeviceId/edit`} component={EditTelephoneEntryDevice} />
      </Switch>
    );
  }
}

TelephoneEntryDevices.propTypes = {
  match: PropTypes.object.isRequired,
};

export default TelephoneEntryDevices;
