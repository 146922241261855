import { connect } from 'react-redux';
import Units from './Units';
import { getUserPermission } from '../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    loaded: state.property.activePropertyLoaded,
    property: state.property.activeProperty,
    propertyError: state.property.activePropertyError,
    userPermissionList: getUserPermission(state),
  };
}

const UnitsContainer = connect(mapStateToProps)(Units);

export default UnitsContainer;
