import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProperties } from '../../redux/actions';
import PropertiesList from './PropertiesList';
import { getUserPermission, selectorIsAdmin } from '../../redux/selectors';

function mapStateToProps(state) {
  return {
    isAdmin: selectorIsAdmin(state),
    list: state.properties,
    loaded: state.propertiesLoaded,
    searching: state.propertiesSearching,
    total: state.propertiesTotal,
    filteredTotal: state.properties.filter(property => !property.get('demo')).size,
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProperties: params => dispatch(getProperties(params)),
  };
}

const PropertiesListContainer = connect(mapStateToProps, mapDispatchToProps)(PropertiesList);

export default withRouter(PropertiesListContainer);
