import React from 'react';
import Loading from '../../../../../../../../../common/Loading';

import {
  SGPageHeader,
  SGTdContact,
  SGTdName,
  SGTdButton,
  SGTdText,
  useFetchTourGuests,
  useSortSearch,
  SGSortSearchRow,
  useToDetail,
  ResidentAccessModal,
  useResidentAccessModal,
  ENABLE_ACCESS,
  useFetchListResidentAccess,
  usePhoneFormating,
} from '../../../common';
import Icon from '../../../../../../../../../common/icons/icon';

import { useGuest } from '../hooks';
import {
  ResidentGuestLimitModal,
  useResidentGuestLimitModal,
} from '../../../common/views/modals/ResidentGuestLimitModal';
import { useSelector } from 'react-redux';

export const SGRejectedTab = () => {
  const { status } = useSelector(state => state.property.tour).toJS();
  const { isLoad, dataLoaded, refetch } = useFetchTourGuests({
    search: '',
    sort_by: 0,
    type: 'rejected',
  });
  const {
    isLoad: isScheduledLoad,
    dataLoaded: scheduledDataLoaded,
    refetch: scheduledDataRefetch,
  } = useFetchTourGuests({
    search: '',
    sort_by: 0,
    type: 'scheduled',
  });

  const { dataResult, total, ...rest } = useSortSearch(dataLoaded);
  const { goToDetail } = useToDetail();

  const { handleCancel, handleConfirmAccess, handleOpen, isConfirmAccess, isOpen } = useResidentAccessModal();
  const { handleLimitModalClose, handleLimitModalOpen, isLimitOpen } = useResidentGuestLimitModal();

  const { guest, handleChangeGuest } = useGuest();
  const getFormattedPhone = usePhoneFormating();

  useFetchListResidentAccess({
    guestId: guest.id,
    isFetching: isConfirmAccess,
    onFetchSuccess: () => {
      refetch();
      scheduledDataRefetch();
      handleCancel();
    },
    type: ENABLE_ACCESS,
  });

  const enableAccessHandler = guest => {
    handleOpen();
    handleChangeGuest(guest);
  };

  if (isLoad || isScheduledLoad) return <Loading />;

  const onClickEnableAccessHandler = (e, guest) => {
    e.stopPropagation();
    scheduledDataLoaded?.length < 19 ? enableAccessHandler(guest) : handleLimitModalOpen();
  };

  return (
    <div className="sg-tab-content container m-b30">
      <SGPageHeader
        title="Rejected Tours"
        subTitle="This is a list of prospective residents that either failed their background
        check or their access has been revoked."
      />

      <SGSortSearchRow total={`${total} rejected`} {...rest} />

      <div className="tab-content">
        <table className="tab-table">
          <thead>
            <tr>
              <th>NAME</th>
              <th>CONTACT</th>
              <th>NOTES</th>
              <th>REASON</th>
              {status && <th width="225px"></th>}
            </tr>
          </thead>
          <tbody>
            {dataResult.map(({ id, first_name, last_name, phone, email, apartment_messages, guest_status_message }) => (
              <tr key={id} className="tab-tr" onClick={() => goToDetail('rejected', id)}>
                <td className="tab-td">
                  <SGTdName name={`${first_name} ${last_name}`} identification />
                </td>
                <td className="tab-td">
                  <SGTdContact phone={getFormattedPhone(phone)} email={email} />
                </td>
                <td className="tab-td">
                  <SGTdText text={apartment_messages} />
                </td>
                <td className="tab-td">
                  <SGTdText text={guest_status_message} />
                </td>
                {status && (
                  <td className="tab-td" onClick={e => onClickEnableAccessHandler(e, { id, first_name, last_name })}>
                    <SGTdButton className="td-enable" icon={<Icon icon="EnableAccessGreen" />} title="Enable Access" />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>

        <ResidentAccessModal
          onCancel={handleCancel}
          onConfirm={handleConfirmAccess}
          visible={isOpen}
          name={`${guest.first_name} ${guest.last_name}`}
        />

        <ResidentGuestLimitModal width={940} close={handleLimitModalClose} visible={isLimitOpen} withBackHandler />

        {!dataResult.length && <div className="empty-text">There are no tours at the moment.</div>}
      </div>
    </div>
  );
};
