import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import NotFound from '../../../../../../common/NotFound';
import ResidentsAndDevicesList from './views/ResidentsAndDevicesList';
import EditResident from './views/EditResident';
import AddResident from './views/AddResident';

class UnitDetails extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}/residents/add`} component={AddResident} />
        <Route exact path={`${match.path}/`} component={ResidentsAndDevicesList} />
        <Route path={`${match.path}/residents/:residentId/edit`} component={EditResident} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

UnitDetails.propTypes = {
  match: PropTypes.object.isRequired,
};
export default UnitDetails;
