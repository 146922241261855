import React from 'react';

function EnableAccessGreen() {
  /* eslint-disable max-len */
  return (
    <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3748 5.66628H10.5619V4.04751C10.5619 2.97404 10.1339 1.94454 9.3721 1.18549C8.6103 0.426433 7.57707 0 6.49971 0C5.42236 0 4.26181 0.240945 3.5 1C2.73819 1.75906 2.5 2.5 2.5 2.5H4C4.6386 1.88537 4.90577 1.77872 4.90577 1.77872C5.62733 1.4642 5.83795 1.57562 6.49942 1.57562C7.1609 1.57562 7.79582 1.83486 8.26709 2.29736C8.73835 2.75986 9.00811 3.3885 9.01813 4.04751V5.66686H1.62522C1.19466 5.66807 0.782072 5.83899 0.477565 6.14229C0.173058 6.44559 0.00137192 6.85663 0 7.28563V15.3807C0.00121957 15.8098 0.172839 16.2209 0.477362 16.5244C0.781885 16.8278 1.19456 16.9988 1.62522 17H11.3748C11.8053 16.9988 12.2179 16.8279 12.5224 16.5246C12.8269 16.2213 12.9986 15.8102 13 15.3812V7.28563C12.9988 6.85653 12.8272 6.44535 12.5226 6.14192C12.2181 5.8385 11.8054 5.6675 11.3748 5.66628ZM6.49971 13.0331C6.17825 13.0331 5.86401 12.9381 5.59673 12.7601C5.32945 12.5822 5.12114 12.3292 4.99815 12.0333C4.87516 11.7374 4.84301 11.4117 4.90577 11.0976C4.96852 10.7835 5.12337 10.4949 5.35071 10.2685C5.57806 10.042 5.8677 9.88785 6.183 9.82543C6.4983 9.76301 6.8251 9.79516 7.12205 9.91782C7.41901 10.0405 7.6728 10.2481 7.85131 10.5145C8.02982 10.7809 8.12504 11.094 8.12493 11.4143C8.12356 11.8433 7.95187 12.2544 7.64736 12.5577C7.34286 12.861 6.93027 13.0319 6.49971 13.0331Z"
        fill="#5AAD55"
      />
    </svg>
  );
  /* eslint-enable */
}

export default EnableAccessGreen;
