import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import MaintenanceRequestsList from './views/MaintenanceRequestsList';
import MaintenanceRequest from './views/MaintenanceRequest';
import NotFound from '../../../../common/NotFound';

const MaintenanceRequests = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={MaintenanceRequestsList} />
      <Route path={`${path}/:maintenanceRequestId`} component={MaintenanceRequest} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default MaintenanceRequests;
