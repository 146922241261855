import React, { Component } from 'react';
class ConfirmModel extends Component {
  render() {
    const { onCancel, visible, selectedGuestList, onConfirm, t } = this.props;

    if (!visible) return null;

    return (
      <div className="modal modal--visible">
        <div className="modal__children">
          <div className="modal__header">
            <h3>{t('guestRegistrationTab.registrationConfirmModal.title')}</h3>
          </div>
          <div className="modal__body">
            <div className="modal__body--text guestRegistration-confirm-model-body">
              {selectedGuestList.map(item => {
                return (
                  <div key={item.id}>
                    <span>
                      {item.first_name + ' ' + item.last_name} ({item.additionalNum})
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal__button--container">
            <button className="button" type="button" onClick={onConfirm}>
              {t('confirm', { ns: 'buttons' })}
            </button>
            <button className="button button--secondary" type="button" onClick={onCancel}>
              {t('cancel', { ns: 'buttons' })}
            </button>
          </div>
        </div>
        <button type="button" className="modal__overlay" onClick={onCancel} />
      </div>
    );
  }
}

export default ConfirmModel;
