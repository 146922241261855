import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../../../../common/PageHeader';
import Immutable from 'immutable';

import CredentialTypeForm from '../../../../../../common/forms/CredentialTypeForm';
import Loading from '../../../../../../common/Loading/index';

class EditCredentialType extends Component {
  componentDidMount() {
    this.props.getCredentialTypes();
  }

  render() {
    const { credentialType, credentialTypesLoaded, editCredentialType, history } = this.props;

    if (!credentialTypesLoaded) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeader title="Edit Credential Type" backLink="../" />
        <div className="container">
          <CredentialTypeForm
            initialValues={{
              name: credentialType.get('name'),
              internal_part_number: credentialType.get('internal_part_number'),
              manufacturer_part_number: credentialType.get('manufacturer_part_number'),
              credential_payload_length: credentialType.get('credential_payload_length'),
              user_code_start_position: credentialType.get('user_code_start_position'),
              user_code_length: credentialType.get('user_code_length'),
              site_code_required: credentialType.get('site_code_required'),
              site_code_start_position:
                credentialType.get('site_code_start_position') === null
                  ? ''
                  : credentialType.get('site_code_start_position'),
              site_code_length:
                credentialType.get('site_code_length') === null ? '' : credentialType.get('site_code_length'),
            }}
            onSubmitClick={submitValues => {
              return editCredentialType(submitValues, credentialType.get('id')).then(res => {
                if (res.response.ok) {
                  return history.push(`../`);
                }
                throw res.json.message;
              });
            }}
            onCancelClick={() => history.goBack()}
          />
        </div>
      </div>
    );
  }
}

EditCredentialType.propTypes = {
  credentialTypes: PropTypes.instanceOf(Immutable.List),
  getCredentialTypes: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default EditCredentialType;
