import { useDispatch } from 'react-redux';
import { addTour } from '../../../../../../../redux/actions/sgToursActions';
import { useState } from 'react';

export const useSGFormAutomatedCreate = (propertyId, history) => {
  const [isAlertCancel, setIsAlertCancel] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async values => {
    const { tour_count, ...dataValues } = values;

    const result = await dispatch(
      addTour(propertyId, {
        ...dataValues,
        time_count: Number(tour_count.number),
        time_type: tour_count.type,
      })
    );

    if (result.json.success) {
      history.push(`/properties/${propertyId}/units/tours/create/automated/successfully`);
    }
  };

  const onCancel = () => history.push(`/properties/${propertyId}/units/tours`);

  return { onSubmit, onCancel, isAlertCancel, setIsAlertCancel };
};
