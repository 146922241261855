import { connect } from 'react-redux';

import EditSchedule from './EditSchedule';
import { editSchedule, getPropertySchedules } from '../../../../redux/actions';

function mapStateToProps(state, ownProps) {
  return {
    property: state.property.activeProperty,
    matchingSchedule: state.property.accessControlSchedules.find(
      schedule => schedule.get('id') === ownProps.match.params.scheduleId
    ),
    accessControlSchedulesLoaded: state.property.accessControlSchedulesLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    editSchedule: payload =>
      dispatch(editSchedule(ownProps.match.params.propertyId, ownProps.match.params.scheduleId, payload)),
    getPropertySchedules: () => dispatch(getPropertySchedules(ownProps.match.params.propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSchedule);
