export default {
  title: 'Возможности приложения для жильцов',
  description:
    'Эти функции необходимо включить, чтобы ваши жильцы могли получить к ним доступ через мобильное приложение. Функции можно включить/отключить в любое время, но мы не рекомендуем регулярно менять эти настройки.',
  announcements: 'ВКЛЮЧИТЬ ОБЪЯВЛЕНИЯ',
  discussion: 'ВКЛЮЧИТЬ ДОСКУ ОБСУЖДЕНИЙ',
  discussionBoardReview: 'ВКЛЮЧИТЬ ОБЗОР ДОСКИ ОБСУЖДЕНИЙ',
  generalPosts: 'ВКЛЮЧИТЬ ОБЩИЕ СООБЩЕНИЯ',
  forSalePosts: 'ВКЛЮЧИТЬ СООБЩЕНИЯ ДЛЯ ПРОДАЖИ',
  recommendationPosts: 'ВКЛЮЧИТЬ РЕКОМЕНДАЦИИ',
  eventPosts: 'ВКЛЮЧИТЬ СООБЩЕНИЯ О СОБЫТИЯХ',
  events: 'ВКЛЮЧИТЬ СОБЫТИЯ',
  maintenance: 'ВКЛЮЧИТЬ ОБСЛУЖИВАНИЕ', //?
  residentCommunityGuests: 'ВКЛЮЧИТЬ ГОСТЕЙ ДЛЯ ЖИЛЬЦОВ', //?
  fullTimeGuestSchedules: 'ВКЛЮЧИТЬ КРУГЛОСУТОЧНОЕ РАСПИСАНИЕ ДЛЯ ГОСТЕЙ',
  scheduledGuestSchedules: 'ВКЛЮЧИТЬ РУЧНОЕ РАСПИСАНИЕ ДЛЯ ГОСТЕЙ',
  oneDayGuestSchedules: 'ВКЛЮЧИТЬ ОДНОДНЕВНОЕ РАСПИСАНИЕ ДЛЯ ГОСТЕЙ',
  maxGuestScheduleDuration: 'МАКСИМАЛЬНАЯ ПРОДОЛЖИТЕЛЬНОСТЬ РАСПИСАНИЯ ДЛЯ ГОСТЕЙ (ДНИ)',
  minDurationNote: 'Нельзя ввести менее 1 дня', //?
  maxDurationNote: 'Нельзя ввести более 999 дней', //?
  saveSettings: 'Сохранить настройки',
  savedConfirmation: 'Возможности жильцов сохранены!',

  modalTitle: 'Отключение просмотра доски обсуждений',
  modalBody:
    'Вы уверены, что хотите отключить обзоры на форумах? Все ожидающие рассмотрения советом директоров будут автоматически одобрены.',
  modalLinkTitle: 'Просмотрите ожидающие обсуждения',
};
