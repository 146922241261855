import client from '../../../../common/client';

export const createResidentGuest = (communityId, payload) => {
  return {
    promise: client.post(`properties/${communityId}/create_resident_guest`, payload),
    type: 'CREATE_RESIDENT_GUEST',
  };
};

export const getScheduleTourUnitGuest = (communityId, query) => {
  query = query || {};
  return {
    promise: client.get(`properties/${communityId}/schedule-tour-unit-guest-list`, query),
    type: 'GET_RESIDENT_GUEST',
  };
};

export const getScheduleTourUnitGuestById = (communityId, guest_id) => {
  return {
    promise: client.get(`properties/${communityId}/schedule-tour-unit-guest/${guest_id}`),
    type: 'GET_RESIDENT_BY_ID_GUEST',
  };
};

export const getScheduleTourUnitGuestRevoke = (communityId, guest_id, data) => {
  return {
    promise: client.post(`properties/${communityId}/schedule-tour-unit-guest/${guest_id}/revoke`, data),
    type: 'SCHEDULED_TOUR_UNIT_GUEST_REVOKE',
  };
};

export const scheduleTourToPast = (communityId, guest_id) => {
  return {
    promise: client.post(`properties/${communityId}/schedule-tour-unit-guest/${guest_id}/past`),
    type: 'SCHEDULED_TOUR_TO_PAST',
  };
};
