import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../common/icons/icon';
import Avatar from '../../../../common/Avatar';
import { formatPhoneNumber } from '../../../../common/utils/helpers';
import Kebab from '../../../../common/Kebab';
import SubHeader from '../../../../common/SubHeader/index';
import Delete from '../../../../common/Delete/index';
import Loading from '../../../../common/Loading';

const EmptyAdminUserList = ({ onAddAdminUser, userPermission }) => (
  <div className="zero-content">
    {userPermission.addNewAdminUser && (
      <button onClick={onAddAdminUser} className="button">
        Add Admin User
      </button>
    )}
  </div>
);

class AdminUserList extends Component {
  state = {
    openKebabUserId: null,
    deleteConfirmationUserId: null,
    userPermission: {},
  };

  componentDidMount() {
    const { userPermissionList } = this.props;
    const userPermission = {
      addNewAdminUser: userPermissionList.includes('ADD_NEW_ADMIN_USER'),
      editAdminUserRoleOrPermissions: userPermissionList.includes('EDIT_ADMIN_USER_ROLE_OR_PERMISSIONS'),
      editOtherAdminUserDetails: userPermissionList.includes('EDIT_OTHER_ADMIN_USER_DETAILS'),
      editTheirOwnDetails: userPermissionList.includes('EDIT_THEIR_OWN_DETAILS'),
      deleteAdminUser: userPermissionList.includes('DELETE_ADMIN_USER'),
    };
    this.setState({ userPermission: userPermission });
    this.props.getAdmins();
  }

  onAddClick = () => {
    const { match, history } = this.props;
    history.push(`${match.url}/add`);
  };

  onKebabClick = userId => this.setState({ openKebabUserId: userId });

  closeKebab = () => this.setState({ openKebabUserId: null });

  renderDeleteConfirmation = userId => {
    const { removeAdmin } = this.props;
    return (
      <div
        className="paper__footer radius-bottom-left radius-bottom-right t-removef-btn"
        style={{ display: 'block', left: '-7vw' }}
      >
        <Delete
          onCancel={() => this.setState({ deleteConfirmationUserId: null })}
          deleteFunc={() => removeAdmin(userId)}
          message={
            <span>
              Are you sure you want to <strong className="strong">remove this admin</strong>?
            </span>
          }
        />
      </div>
    );
  };

  renderKebab = admin => {
    const { match, history, user } = this.props;
    const { openKebabUserId, userPermission } = this.state;
    const editUrl = `${match.url}/${admin.get('id')}/edit`;

    if (!userPermission.deleteAdminUser && !userPermission.editOtherAdminUserDetails) {
      if (!userPermission.editTheirOwnDetails) {
        return '';
      }
      if (userPermission.editTheirOwnDetails && user.get('id') !== admin.get('id')) {
        return '';
      }
    }

    if (!userPermission.deleteAdminUser && !userPermission.editTheirOwnDetails && user.get('id') === admin.get('id')) {
      return '';
    }

    return (
      <div>
        <Kebab
          onClick={() => this.onKebabClick(admin.get('id'))}
          onDisengage={this.closeKebab}
          menuOpened={admin.get('id') === openKebabUserId}
        >
          <div className="kebab__menu" style={{ width: '110px' }}>
            {((user.get('id') !== openKebabUserId && userPermission.editOtherAdminUserDetails) ||
              (user.get('id') === openKebabUserId && userPermission.editTheirOwnDetails)) && (
              <div
                className="kebab__menu--item"
                onClick={() => {
                  history.push(editUrl);
                  this.closeKebab();
                }}
              >
                <Icon icon="Edit" />
                Edit
              </div>
            )}
            {userPermission.deleteAdminUser && (
              <div
                className="kebab__menu--item"
                onClick={() => {
                  this.setState({ deleteConfirmationUserId: admin.get('id') });
                  this.closeKebab();
                }}
              >
                <Icon icon="Remove" />
                Remove
              </div>
            )}
          </div>
        </Kebab>
      </div>
    );
  };

  renderIdentificationColumn = admin => {
    const first_name =
      admin.get('first_name').length > 12 ? admin.get('first_name').substr(0, 12) + '...' : admin.get('first_name');
    const last_name =
      admin.get('last_name').length > 20 ? admin.get('last_name').substr(0, 20) + '...' : admin.get('last_name');
    const fullName = first_name + ' ' + last_name;

    return (
      <div className="pm-list__name-column">
        <Avatar name={fullName} imageSource={admin.getIn(['user', 'profile', 'picture'])} />
        <div>
          <div className="pm-list__name-column__name textHidden">{fullName}</div>
          <div className="pm-list__name-column__email">{admin.get('email')}</div>
        </div>
      </div>
    );
  };

  render() {
    const { admins, history, match, loading } = this.props;
    const { deleteConfirmationUserId, userPermission } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <div className="container">
        <SubHeader
          title="Admin Users"
          actions={
            userPermission.addNewAdminUser && admins.size
              ? [
                  <button className="button" onClick={this.onAddClick}>
                    Add Admin User
                  </button>,
                ]
              : null
          }
        />
        {admins.size ? (
          <table className="table-list table-list__m-bottom">
            <thead>
              <tr>
                <th>Name</th>
                <th>Role</th>
                <th>Phone Number</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {admins.map(admin => {
                return (
                  <tr key={admin.get('id')}>
                    <td style={{ width: '550px' }}>{this.renderIdentificationColumn(admin)}</td>
                    <td>
                      <div>{formatPhoneNumber(admin.get('role_name'))}</div>
                    </td>
                    <td>
                      <div>{formatPhoneNumber(admin.get('phone'))}</div>
                    </td>
                    <td className="table__align-right">
                      {deleteConfirmationUserId === admin.get('id')
                        ? this.renderDeleteConfirmation(admin.get('id'))
                        : this.renderKebab(admin)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <EmptyAdminUserList onAddAdminUser={() => history.push(`${match.url}/add`)} userPermission={userPermission} />
        )}
      </div>
    );
  }
}

AdminUserList.propTypes = {
  admins: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  removeAdmin: PropTypes.func.isRequired,
};

export default AdminUserList;
