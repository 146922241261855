import React from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';
import { SGPageHeader, SGToggleTour, TourChangeConfirmModal } from '../../../common';
import ModalAlert from '../../../../../../../../../common/ModalAlert';
import { useSGFormAutomatedEdit, useSGSettingsHeaderTitles } from '../hooks';

import {
  SGTourForm,
  SGGroupButtons,
  SGBlockErrors,
  SGNotification,
  SGFormBlock,
  SGFormUnits,
  SGFormUnitsStaff,
  SGFormAvailableTimes,
  SGFormPreTourMessage,
  SGFormTourRegistration,
  SGFormNotifiedStaff,
} from '../../../common';
import Icon from '../../../../../../../../../common/icons/icon';
import { SGButton } from '../../../common';
import { useScrollToTop, useTourPeriod } from '../../../common';

const SGToursFormAutomatedEdit = ({ history, match }) => {
  const {
    onCancel,
    onDelete,
    onSubmit,
    confirmSubmit,
    closeTourChangedModal,
    tour: {
      units,
      tour_staff,
      profile_id,
      weekdays,
      start_time,
      end_time,
      time_count,
      time_type,
      pre_message,
      pre_send_type,
      post_message,
      post_send_type,
      notified_staff,
      status,
    },
    submitSettings,
    isAlertCancel,
    isTourChanged,
    tourChangeModalSubtitle,
    setIsAlertCancel,
  } = useSGFormAutomatedEdit(match.params.propertyId, history);
  const handleScroll = useScrollToTop();
  const getTourPeriod = useTourPeriod();
  const { title, subTitle } = useSGSettingsHeaderTitles(status, onDelete);

  return (
    <div className="container tour-settings-container">
      <SGPageHeader status={!status} title={title} subTitle={subTitle} rightColumn={<SGToggleTour />} />
      {status && (
        <SGTourForm
          submitSettings={submitSettings}
          onSubmit={onSubmit}
          onCancel={() => setIsAlertCancel(true)}
          values={{
            units,
            tour_staff,
            profile: profile_id,
            weekdays,
            start_time,
            end_time,
            tour_count: {
              number: time_count,
              type: time_type,
              // label: `${time_count} ${time_type === 'week' ? 'weeks' : 'month'}`,
              label: getTourPeriod(time_count, time_type),
            },
            pre_message,
            pre_send_type,
            post_message,
            post_send_type,
            notified_staff,
          }}
        >
          <SGFormBlock
            number="1"
            type="registrationLink"
            title="Copy self-guided tour registration form link"
            subTitle="Use this link in emails or on your website to allow prospective residents to sign up for self-guided tours. Link will generate after clicking “Save Self-Guided Tour Settings”."
          >
            <SGFormTourRegistration />
          </SGFormBlock>

          <SGFormBlock
            number="2"
            type="units"
            required
            title="Select which units you want to make model units"
            subTitle="The vacant units you select will become the model
         units potential residents can tour. You can also select any
         vacant units that you would like to make tourable.
         This units are subject to change frequently."
          >
            <SGFormUnits />
          </SGFormBlock>
          <SGFormBlock
            number="3"
            type="profile"
            required
            title="Select an access profile"
            subTitle="The access profile selected will define authorized and restricted areas on your property to the potential
            residents. You can select a default access profile or you can create a new one."
          >
            <SGFormUnitsStaff />
          </SGFormBlock>
          <SGFormBlock number="4" type="availabilityTimes" required title="Select self-guided tour availability times">
            <SGFormAvailableTimes />
          </SGFormBlock>
          <SGFormBlock
            number="5"
            type="pretourMessage"
            title="Write pre-tour message to prospective resident"
            subTitle="This message will be sent to the prospective resident when
        they register for a self-guided tour. You can add information they
        should know before the tour, such as directions, parking instructions,
         and community details."
          >
            <SGFormPreTourMessage />
          </SGFormBlock>

          <SGFormBlock
            required
            number="6"
            type="notifiedStaff"
            title="Choose which staff members get notified"
            subTitle="Select which staff members get notified through email when
         a new prospective residents fills out a tour form."
          >
            <SGFormNotifiedStaff />
          </SGFormBlock>
          <div className="sg-group-btns container">
            <div className="sg-paper-btns">
              <SGGroupButtons
                iconSaveComponent={<Icon icon="SaveIcon" />}
                iconCancelComponent={<Icon icon="CancelIcon" />}
                isCreateForm={false}
                submitButtonTitle="Save Self-Guided Tour Settings"
                cancelButtonTitle="Cancel Changes"
                onCancel={() => setIsAlertCancel(true)}
              >
                <SGNotification submitSettings={submitSettings} />
                <SGButton
                  title="Return to top"
                  iconComponent={<Icon icon="ScrollIcon" />}
                  className="btn-scroll"
                  handleClick={handleScroll}
                />
              </SGGroupButtons>

              <SGBlockErrors className="m-t15" />
            </div>
          </div>
        </SGTourForm>
      )}

      <ModalAlert
        title="Are you sure you want to cancel this Self-Guided Tour changes?"
        confirmButtonTitle="Yes, Cancel"
        cancelButtonTitle="No, Don’t Cancel"
        handleConfirm={onCancel}
        handleCancel={() => setIsAlertCancel(false)}
        visible={isAlertCancel}
        icon={<Icon icon="DenyIcon" />}
      />
      <TourChangeConfirmModal
        subtitle={tourChangeModalSubtitle}
        visible={isTourChanged}
        handleConfirm={confirmSubmit}
        handleCancel={closeTourChangedModal}
      />
    </div>
  );
};

export default compose(withRouter)(SGToursFormAutomatedEdit);
