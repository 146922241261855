import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../../../../common/PageHeader';
import EmptyPlaceholder from '../../../../../../common/EmptyPlaceholder';
import { Link } from 'react-router-dom';
import SubHeader from '../../../../../../common/SubHeader/index';
import Immutable from 'immutable';
import Loading from '../../../../../../common/Loading/index';

class CredentialTypesList extends Component {
  componentDidMount() {
    this.props.getCredentialTypes();
  }

  renderCredentialTypes() {
    const { credentialTypes } = this.props;

    return (
      <div className="table__wrapper">
        <table className="table-list">
          <thead>
            <tr>
              <th>Name</th>
              <th>Internal Part #</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {credentialTypes.map(credentialType => {
              return (
                <tr key={credentialType.get('id')}>
                  <td>
                    <div>{credentialType.get('name')}</div>
                  </td>
                  <td>
                    <div>{credentialType.get('internal_part_number')}</div>
                  </td>
                  <td className="table__align-right">
                    <Link className="table-row__action-button" to={`credential-types/${credentialType.get('id')}/edit`}>
                      Edit
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { credentialTypes, credentialTypesLoaded } = this.props;

    if (!credentialTypesLoaded) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeader title="Credential Types" backLink="./" />
        <div className="container">
          <SubHeader
            title={`${credentialTypes.size} ${credentialTypes.size === 1 ? 'Credential' : 'Credentials'}`}
            actions={
              credentialTypes.size
                ? [
                    <Link className="button" to="credential-types/add">
                      Add Credential Type
                    </Link>,
                  ]
                : null
            }
          />
          {credentialTypes.size > 0 ? (
            this.renderCredentialTypes()
          ) : (
            <EmptyPlaceholder buttonText="Add Credential Type" linkTo="credential-types/add" />
          )}
        </div>
      </div>
    );
  }
}

CredentialTypesList.propTypes = {
  credentialTypes: PropTypes.instanceOf(Immutable.List),
  getCredentialTypes: PropTypes.func.isRequired,
};

export default CredentialTypesList;
