import React from 'react';
import cn from 'classnames';
import Pinned from '../icons/Pinned';
import Unpinned from '../icons/Unpinned';

const Pin = props => {
  const { pinned, didPin } = props;
  return (
    <div className="pin__wrapper">
      <div
        className={cn('pin', pinned && 'pinned')}
        onClick={() => {
          didPin(!pinned);
        }}
      >
        {pinned ? <Pinned /> : <Unpinned />}
      </div>
    </div>
  );
};

export default Pin;
