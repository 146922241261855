import { connect } from 'react-redux';
import EditMailHub from './EditMailHub';
import {
  getCommunityDevices,
  getGroups,
  getMailHubs,
  createMailHub,
  editMailHub,
} from '../../../../../Property/redux/actions';
import { editGroups } from '../../../../redux/actions';

const mapStateToProps = (state, ownProps) => ({
  communityDevices: state.property.communityDevices,
  communityDevicesLoading: state.property.communityDevicesLoading,
  groupList: state.property.groupList,
  propertyId: ownProps.match.params.propertyId,
  mailHubs: state.property.mailHubs,
  mailHubsLoaded: state.property.mailHubsLoaded,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCommunityDevices: () => dispatch(getCommunityDevices(ownProps.match.params.propertyId)),
  getGroups: () => dispatch(getGroups(ownProps.match.params.propertyId)),
  editGroups: (groupId, payload) => dispatch(editGroups(ownProps.match.params.propertyId, groupId, payload)),
  createMailHub: payload => dispatch(createMailHub(ownProps.match.params.propertyId, payload)),
  editMailHub: payload =>
    dispatch(editMailHub(ownProps.match.params.propertyId, ownProps.match.params.mailHubId, payload)),
  getMailHubs: () => dispatch(getMailHubs(ownProps.match.params.propertyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMailHub);
