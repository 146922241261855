import { connect } from 'react-redux';
import Amenities from './Amenities';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
  };
}

const AmenitiesContainer = connect(mapStateToProps)(Amenities);

export default AmenitiesContainer;
