import React from 'react';
import NotFound from '../../../../../common/NotFound';
import { Reservations } from '../tabs/Reservations';
import { EditReservationTypeForm } from './EditReservationTypeForm';
import { EditAmenityAvailabilityForm } from './EditAmenityAvailabilityForm';
import { EditImagesForm } from './EditImagesForm';
import { EditAmenityDetailsForm } from './EditAmenityDetailsForm';
import { Switch, Route, Redirect } from 'react-router';

const EditAmenityTabRoutes = ({ match, amenity }) => {
  return (
    <Switch>
      <Route exact path={match.path} render={() => <Redirect to={`${match.url}/details`} />} />
      <Route path={`${match.path}/details`} render={props => <EditAmenityDetailsForm amenity={amenity} {...props} />} />
      <Route path={`${match.path}/images`} render={props => <EditImagesForm amenity={amenity} {...props} />} />
      <Route
        path={`${match.path}/availability`}
        render={props => <EditAmenityAvailabilityForm amenity={amenity} {...props} />}
      />
      <Route
        path={`${match.path}/reservation-type`}
        render={props => <EditReservationTypeForm amenity={amenity} {...props} />}
      />

      {amenity && amenity.reservation_type !== 'NON_RESERVABLE' && (
        <Route path={`${match.path}/reservations`} render={props => <Reservations amenity={amenity} {...props} />} />
      )}

      <Route component={NotFound} />
    </Switch>
  );
};

export default EditAmenityTabRoutes;
