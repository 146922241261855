export default {
  mainScreen: {
    title: 'Amenities',
    noAmenitiesText: "There are no Amenities added to your Community. Click 'Add Amenity' to get started.",
    addAmenityButton: 'Add Amenity',
    downloadCsvButton: 'Download CSV',
    free: 'Free',
    amenitiesTable: {
      amenityNameColumnTitle: 'AMENITY NAME',
      availabilityColumnTitle: 'AVAILABILITY',
      reservableColumnTitle: 'RESERVABLE',
      feeColumnTitle: 'FEE',
      maxOccupancyColumnTitle: 'MAX OCCUPANCY',
    },
  },

  amenityPage: {
    title: 'Add Amenity',
    simpleTitle: 'Amenity', //!
    saveButton: 'Save Amenity',
    loading: 'Loading...', //!
    tags: {
      open: 'Open',
      closed: 'Closed',
    },
    tabs: {
      amenityDetails: {
        title: 'Amenity Details',
        sections: {
          amenityDetails: {
            title: 'Amenity Details',
            nameField: 'NAME',
            descriptionField: 'DESCRIPTION',
            maxOccupancyField: 'MAXIMUM OCCUPANCY',
          },
          termsOfUse: {
            title: 'Terms of Use',
            termOfUseField: 'TERMS OF USE',
          },
        },
      },
      images: {
        title: 'Images',
        none: 'None',
        sections: {
          upload: {
            title: 'Upload Amenity Images',
            helperText: 'Upload an image or multiple to show off this amenity',
            dragAndDrop: {
              title: 'Drop your files here, or click to browse',
              subtitle: 'SUPPORTED FILES',
              condition: 'PNG or JPG | 1MB or less',
            },
          },
          cropperModal: {
            title: 'Crop your uploaded image',
          },
          amenity: {
            title: 'Amenity Images',
            subtitle:
              'You can reorder the images in the order you want them to appear in the app or remove them. Only a max of 5 images are allowed',
          },
        },
        errors: {
          custom: 'Could not accept {{fileName}}. {{message}}.',
          tooManyItems: 'Could not accept {{fileName}}. Too many files.',
        },
      },
      availability: {
        title: 'Availability',
        subtitle: 'Availability Schedule',
        weeks: {
          //!
          sun: 'sunday',
          mon: 'monday',
          tue: 'tuesday',
          wed: 'wednesday',
          thu: 'thursday',
          fri: 'friday',
          sat: 'saturday',
        },
        sections: {
          daysAndHours: {
            title: 'Days and Hours',
            subtitle: 'Choose the type of availability this amenity will have',
            radioOptions: {
              none: 'NONE',
              '24/7': '24/7',
              daily: 'DAILY',
              custom: 'CUSTOM',
            },
            subsections: {
              daily: {
                title: 'Daily',
                subtitle: 'Enter an Open Time and Close Time',
                openTimeField: 'OPEN TIME',
                closeTimeField: 'CLOSE TIME',
              },
              custom: {
                title: 'Custom',
                subtitle:
                  'Choose the days of the week this amenity will be open. Any day that isn’t selected, will be marked as “Closed”. Any day chosen will let you set a daily schedule.',
                daysOfWeek: {
                  sun: 'SUN',
                  mon: 'MON',
                  tue: 'TUE',
                  wed: 'WED',
                  thu: 'THU',
                  fri: 'FRI',
                  sat: 'SAT',
                },
                openingTimeField: 'OPENING TIME',
                closingTimeField: 'CLOSING TIME',
              },
            },
          },
          temporaryClosings: {
            title: 'Temporary Closings',
            subtitle: 'Temporary closings can be added once the Amenity has been created.',
            editSubtitle: 'Set any predetermined temporary closings by choosing a Closed Date and Open Date',
            saveClosingButton: 'Save Closing',
            addTemporaryClosingButton: 'Add Temporary Closing',
            nameField: 'Name of Closing',
            closeDateField: 'Close Date',
            openDateField: 'Open Date',
            closes: 'Closes', //!
            reopens: 'Reopens', //!
          },
        },
      },
      reservationType: {
        title: 'Reservation Type',
        subtitle: 'Choose whether this amenity will be able to be reserved by residents.',
        details: 'Reservation Details',
        limitedSubtitle: 'Change Availability Days and Hours for Reservations to become available',
        types: {
          default: {
            title: 'NON-RESERVABLE',
          },
          free: {
            title: 'FREE',
            minReservationField: 'MINIMUM RESERVATION LENGTH',
            minReservationPlaceholder: 'Select Minimum Reservation Length', //!
            maxReservationField: 'MAXIMUM RESERVATION LENGTH',
            maxReservationPlaceholder: 'Select Maximum Reservation Length', //!
            cancelationPolicy: {
              title: 'Cancelation Policy',
              subtitle: 'You can outline cancelation and refund policies here',
              field: 'CANCELATION POLICY',
            },
          },
          paid: {
            title: 'PAID',
            pricePerIncrementField: 'PRICE PER TIME INCREMENT',
            refundField: 'REFUND CUT-OFF TIME (HOURS)',
            cancelationFeeField: 'CANCELATION FEE %',
            cancelationPolicy: {
              title: 'Cancelation & Refund Policies',
              refundField: 'REFUND POLICY',
            },
          },
        },
      },
      reservations: {
        title: 'Reservations',
        failedStatus: 'There was an error when loading reservations.',
        noReservations: 'There are no reservations to show.',
        tableHeaders: {
          residentName: 'Resident Name',
          dateOfReservation: 'Date of Reservation',
          timeOfReservation: 'Time of Reservation',
        },
        cancelReservation: 'Cancel Reservation',
        past: 'past',
      },
    },
    errors: {
      amenityDetailsFields: {
        enterTitle: 'Please enter a title',
        enterDescription: 'Please enter a description',
      },
      amenityAvailabilityFields: {
        closingTimes: 'Closing times must occur after opening times.',
        selectDays: 'Please select day(s) and opening/closing times.',
      },
      amenityReservationTypeFields: {
        minimumReservation: 'A minimum reservation length must be set.',
        maximumReservation: 'A maximum reservation length must be set.',
        cancelationPolicyMustBeSet: 'A cancelation policy must be set.',
        pricePerTimeIncrement: 'A price-per-time-increment must be provided.',
        cancelationFeeProviding: 'A cancelation fee must be provided.',
        refundPolicyMustBeSet: 'A refund policy must be set.',
      },
    },
    modals: {
      cancelCreating: {
        title: 'Cancel Creating Amenity',
        subtitle: 'Are you sure you want to cancel creating this amenity?',
        confirmCanceling: 'Cancel Creating Amenity',
      },
      confirmCreating: {
        title: 'Temporary Closing', //!
        subtitle:
          'Are you sure you want to create this temporary closing? Any reservations during this time will be automatically canceled.', //!
        confirm: 'Create Temporary Closing', //!
      },
      preview: {
        title: 'New Amenity Preview',
        subtitle: 'Preview this new amenity before saving',
      },
      amenityListItem: {
        edit: 'Edit Amenity',
        delete: 'Delete Amenity',
      },
      deleteTemporaryClosing: {
        title: 'Delete Temporary Closing?', //!
        subtitle: 'Are you sure you want to delete this temporary closing', //!
        cancel: "No, don't delete", //!
        submit: 'Yes, delete temporary closing', //!
      },
      unsavedChanges: {
        title: 'Unsaved Changes',
        subtitle: 'Are you sure you want to leave this page? Any unsaved changes will be lost.',
        submit: "Yes, I'm sure",
      },
      delete: {
        title: 'Delete Amenity?',
        subtitle: 'Are you sure you want to delete this amenity?',
        cancel: "No, don't delete",
        submit: 'Yes, delete amenity',
      },
      close: {
        title: 'Close Amenity',
        subtitle:
          'You may provide an announcement to inform residents of this closure. Any existing reservations for this amenity will be canceled.',
        announcementTitleField: 'ANNOUNCEMENT TITLE',
        announcementField: 'ANNOUNCEMENT',
        submit: 'Close Amenity',
      },
      cancelReservation: {
        title: 'Cancel Reservation',
        body:
          "To cancel this resident's reservation, please provide a reason for cancelation, which will be emailed to the resident",
        field: 'Cancelation Reason',
        submit: 'Cancel Reservation',
        reasonErrors: {
          length: 'Reason must be 2000 characters or fewer',
          required: 'Please enter a reason',
        },
      },
    },
    messages: {
      edited: 'Amenity edited successfully!',
    },
  },
};
