import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class EmptyPlaceholder extends Component {
  render() {
    const { buttonText, onClick, linkTo, children } = this.props;

    return (
      <div className="zero-content">
        {children}
        {onClick && (
          <button onClick={onClick} className="button">
            {buttonText}
          </button>
        )}
        {linkTo && (
          <Link className="button" to={linkTo}>
            {buttonText}
          </Link>
        )}
      </div>
    );
  }
}

EmptyPlaceholder.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
  children: PropTypes.node,
};
export default EmptyPlaceholder;
