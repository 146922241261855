import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import AccessControlList from './views/AccessControlList';
import CredentialTypes from './views/CredentialTypes';
import TelephoneEntryDevices from './views/TelephoneEntryDevices';

class AccessControl extends Component {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.path}/`} component={AccessControlList} />
        <Route path={`${match.path}/credential-types`} component={CredentialTypes} />
        <Route path={`${match.path}/telephone-entry-devices`} component={TelephoneEntryDevices} />
      </Switch>
    );
  }
}

AccessControl.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AccessControl;
