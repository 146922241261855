export default {
  'Pending Discussions': 'Pending Discussions',
  'No Pending Discussions right now.': 'No Pending Discussions right now.',
  "Author's name": "Author's name",
  "Author's Unit #": "Author's Unit #",
  'Post type': 'Post type',
  Headline: 'Headline',
  Body: 'Body',
  'See Post Body': 'See Post Body',
  Type: 'Type',
  'Reject Post?': 'Reject Post?',
  'Enter a comment to your resident to let them know why their post was rejected.':
    'Enter a comment to your resident to let them know why their post was rejected.',
  Reject: 'Reject',
  Cancel: 'Cancel',
  'Former Resident': 'Former Resident',
  General: 'General',
  'For Sale': 'For Sale',
  Event: 'Event',
  Recommendation: 'Recommendation',
};
