import React from 'react';
import { FormikProvider } from 'formik';

import { useSGTourForm } from '../hooks';
import { SGFormProvider } from './SGFormProvider';

export const SGTourForm = ({ children, values, onSubmit }) => {
  const { form } = useSGTourForm(values, onSubmit);

  return (
    <SGFormProvider>
      <FormikProvider value={form}>{children}</FormikProvider>
    </SGFormProvider>
  );
};
