import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addProfile, getPropertySchedules } from '../../../../../../../../redux/actions';
import ProfileForm from '../../../../../../../AccessControl/components/ProfileForm';
import ModalAlert from '../../../../../../../../../../common/ModalAlert';
import Icon from '../../../../../../../../../../common/icons/icon';

export const SGProfileForm = () => {
  const dispatch = useDispatch();
  const [isVisable, setVisable] = useState(false);
  const user = useSelector(state => state.user);
  const { accessControlAreas, accessControlSchedules, activeProperty } = useSelector(({ property }) => property);
  const propertyId = activeProperty.toJS().id;

  const onSubmit = fields => dispatch(addProfile(propertyId, fields));
  const onSuccess = () => {
    setVisable(false);
    dispatch(getPropertySchedules(propertyId));
  };

  return (
    <div className="sg-units-staff">
      <div className="p-tb10">
        <button onClick={() => setVisable(true)} className="button button--icon m-b10" type="button">
          <div className="button--children">
            <Icon icon="AddIcon" />
            <span className="h5">Create a new Access Profile</span>
          </div>
        </button>
      </div>

      {ReactDOM.createPortal(
        <ModalAlert
          visible={isVisable}
          handleCancel={() => setVisable(false)}
          body={
            <ProfileForm
              submitFunc={onSubmit}
              onSuccess={onSuccess}
              onCancel={() => setVisable(false)}
              areas={accessControlAreas.toJS()}
              schedules={accessControlSchedules.toJS()}
              user={user.toJS()}
            />
          }
        />,
        document.querySelector('body')
      )}
    </div>
  );
};
