import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import Loading from '../../../../../../../../common/Loading/ComponentLoading';

const AddPanelRequestForm = ({ initialValues, validationSchema, onCancel, submitErrors, submitFunc }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        return submitFunc(values, actions);
      }}
      render={({
        values,
        errors,
        handleChange,
        handleSubmit,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => {
        if (isSubmitting) {
          return (
            <div className="add-resident-card__loading">
              <Loading />
              Submitting request, please wait.
            </div>
          );
        }

        return (
          <form onSubmit={handleSubmit}>
            <InputScaffold
              label="Serial Number"
              required
              validation={touched.serial_number && errors.serial_number}
              className="scaffold--request-panel"
            >
              <input
                type="text"
                name="serial_number"
                maxLength="64"
                onChange={handleChange}
                value={values.serial_number}
              />
            </InputScaffold>
            <InputScaffold
              label="Account Number"
              required
              validation={touched.account_number && errors.account_number}
              className="scaffold--request-panel"
            >
              <input
                type="text"
                name="account_number"
                maxLength="64"
                onChange={handleChange}
                value={values.account_number}
              />
            </InputScaffold>
            <div className="scaffold--request-panel-actions">
              <button disabled={isSubmitting || Object.keys(errors).length} className="button">
                Request Panel
              </button>
              <button type="button" className="button button--secondary" onClick={onCancel}>
                Cancel
              </button>
            </div>
          </form>
        );
      }}
    />
  );
};

AddPanelRequestForm.propTypes = {
  submitFunc: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default AddPanelRequestForm;
