import React, { Component } from 'react';
import { Formik } from 'formik';
import DropNCrop from '@synapsestudios/react-drop-n-crop';
import Yup from 'yup';
import PropTypes from 'prop-types';
import Icon from '../../../../../../common/icons/icon';
import MaskedInput from 'react-input-mask';
import '@synapsestudios/react-drop-n-crop/lib/react-drop-n-crop.min.css';
import InputScaffold from '../../../../../../common/forms/InputScaffold';
import NotificationStatusIndicator from '../../../../../../common/forms/NotificationStatusIndicator';
import { trimAndPruneFalsyValues, getPhoneMask } from '../../../../../../common/utils/helpers';
import SubHeader from '../../../../../../common/SubHeader';
import CommunityStripeIntegration from './CommunityStripeIntegration';
import { normalizeUrl } from '../../../../../../common/utils/helpers';
const maxImageBytes = 1000000;
const imageSizeRestriction = {
  width: 1500,
  height: 1000,
};

const emailSchema = Yup.string()
  .trim()
  .email('Must be a valid email address')
  .max('64', 'Email may only be 64 characters or less')
  .nullable(true);

const validationSchema = Yup.object().shape({
  public_name: Yup.string()
    .trim()
    .matches(/^[a-zA-Z0-9\s]*$/, 'Must be a valid community name')
    .required('A Community Name is required to save changes.'),
  public_image_data: Yup.string(),
  email: emailSchema,
  phone: Yup.string()
    .matches(/^[1]\d{10}|[0,2-9]\d{9}$/, 'Must be a valid phone number')
    .nullable(true),
  fax: Yup.string()
    .matches(/^[1]\d{10}|[0,2-9]\d{9}$/, 'Must be a valid phone number')
    .nullable(true),
  website: Yup.string()
    .trim()
    .transform(value => {
      return normalizeUrl(value);
    })
    .url('Must be a valid URL'),
});

const InstructionsComponent = () => (
  <div>
    <p>Drag an image here to upload or select a file from your device</p>
  </div>
);

class Settings extends Component {
  state = {
    public_image_data: '',
    formStatus: { message: '' },
    image_removed: false,
    imageData: {
      result: null,
      filename: null,
      filetype: null,
      src: '',
      error: null,
    },
  };

  componentDidMount() {
    if (this.props.property.getIn(['public_image', 'url'])) {
      this.setState(() => ({
        image: <img alt="Public App" src={this.props.property.getIn(['public_image', 'url'])} />,
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.property.getIn(['public_image', 'url']) !== this.props.property.getIn(['public_image', 'url']) &&
      this.props.property.getIn(['public_image', 'url'])
    ) {
      this.setState(() => ({
        image: <img alt="Public App" src={this.props.property.getIn(['public_image', 'url'])} />,
      }));
    }
  }
  removeImage = setFieldValue => {
    this.setState(() => ({
      image_removed: true,
      image: '',
      imageData: {
        result: null,
        filename: null,
        filetype: null,
        src: '',
        error: null,
      },
    }));
    setFieldValue('public_image_data', '');
  };

  onUpdateImage = value => this.setState({ imageData: value });

  render() {
    const { property } = this.props;
    return (
      <div>
        <div>
          <Formik
            initialValues={{
              public_image_data: '',
              public_name: property.get('public_name'),
              email: property.get('email') || '',
              website: property.get('website') || '',
              fax: property.get('fax') || '',
              phone: property.get('phone') || '',
            }}
            validationSchema={validationSchema}
            enableReinitialize
            validateOnMount
            onSubmit={(values, actions) => {
              this.setState(() => ({ formStatus: { message: '' } }));

              if (this.state.imageData.src) {
                values.public_image_data = this.state.imageData.result;
              }

              const submitValues = trimAndPruneFalsyValues(values);
              if (!submitValues.public_image_data && this.state.image_removed) {
                submitValues.public_image_id = null;
              }

              return this.props.editProperty(submitValues, this.props.property.get('id')).then(response => {
                actions.setSubmitting(false);
                if (response.response.ok) {
                  this.setState(() => ({ formStatus: { message: 'Community Settings saved!', status: 'Success' } }));
                } else {
                  this.setState(() => ({
                    formStatus: {
                      message: response.json.statusCode + ' - ' + response.json.message,
                      status: 'Failure',
                    },
                  }));
                }
              });
            }}
            render={({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setStatus,
              status,
            }) => {
              return (
                <div className="container community-settings__page">
                  <SubHeader title="Community Settings" />
                  <form onSubmit={handleSubmit}>
                    <div className="paper radius-top-left radius-top-right paper__grid">
                      <div className="paper__section--grid-item">
                        <section className="paper__section">
                          <h4 className="h4">Community Information</h4>
                          <InputScaffold
                            label="Community Name"
                            className="form-public__name"
                            validation={errors.public_name}
                          >
                            <p>
                              This is the name that will appear in the SmartExperience App. It can only contain letters
                              and numbers. Max length is 64 characters
                            </p>
                            <input
                              name="public_name"
                              maxLength={64}
                              onChange={handleChange}
                              value={values.public_name}
                            />
                          </InputScaffold>
                          <InputScaffold label="Fax Number" className="form-public__name" validation={errors.fax}>
                            <MaskedInput
                              mask={getPhoneMask(values.fax)}
                              name="fax"
                              onChange={event => {
                                setFieldValue('fax', event.target.value.replace(/[^0-9]+/g, ''));
                              }}
                              value={values.fax}
                            />
                          </InputScaffold>

                          <InputScaffold label="Website URL" className="form-public__name" validation={errors.website}>
                            <input
                              type="text"
                              name="website"
                              maxLength="64"
                              onChange={handleChange}
                              value={values.website}
                            />
                          </InputScaffold>

                          <InputScaffold label="Phone Number" className="form-public__name" validation={errors.phone}>
                            <MaskedInput
                              mask={getPhoneMask(values.phone)}
                              name="phone"
                              onChange={event => {
                                setFieldValue('phone', event.target.value.replace(/[^0-9]+/g, ''));
                              }}
                              value={values.phone}
                            />
                          </InputScaffold>

                          <InputScaffold label="Email" className="form-public__name" validation={errors.email}>
                            <input
                              type="text"
                              name="email"
                              maxLength="64"
                              onChange={handleChange}
                              value={values.email}
                            />
                          </InputScaffold>
                        </section>
                      </div>

                      <div className="paper__section--grid-item">
                        <section className="paper__section">
                          <h4 className="h4">SmartExperience App Image</h4>

                          <div className="image-uploader">
                            <span className="label">Upload Image</span>
                            <p className="font-small">
                              App image will be displayed in the community section of the SmartExperience App.
                            </p>
                            {this.state.image ? (
                              <div className="image--uploaded__wrapper">
                                {this.state.image}
                                <p>
                                  <button
                                    type="button"
                                    className="button"
                                    onClick={this.removeImage.bind(this, setFieldValue)}
                                  >
                                    Remove Image
                                  </button>
                                </p>
                              </div>
                            ) : (
                              <div>
                                <DropNCrop
                                  onChange={this.onUpdateImage}
                                  canvasHeight="240px"
                                  value={this.state.imageData}
                                  maxFileSize={maxImageBytes}
                                  instructions={<InstructionsComponent />}
                                  cropperOptions={{
                                    aspectRatio: imageSizeRestriction.width / imageSizeRestriction.height,
                                    guides: false,
                                    rotatable: false,
                                    scalable: false,
                                    minContainerheight: 300,
                                  }}
                                >
                                  <p className="image-validation">
                                    {status && status.imageValidation ? status.imageValidation : ''}
                                  </p>
                                  <Icon icon="Image" />
                                  <p>Drag an image here to upload or select a file from your device</p>
                                </DropNCrop>
                              </div>
                            )}
                          </div>

                          <input
                            type="hidden"
                            name="public_image_data"
                            onChange={handleChange}
                            value={values.public_image_data}
                          />
                        </section>
                        <section className="paper__section">
                          <CommunityStripeIntegration />
                        </section>
                      </div>
                    </div>

                    <div className="paper__footer radius-bottom-left radius-bottom-right">
                      <button disabled={isSubmitting || !!Object.keys(errors).length} type="submit" className="button">
                        Save Settings{' '}
                      </button>
                      <NotificationStatusIndicator
                        hideNotification={!this.state.formStatus.message}
                        message={this.state.formStatus.message}
                        type={this.state.formStatus.status}
                      />
                    </div>
                  </form>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}
Settings.propTypes = {
  property: PropTypes.object.isRequired,
  editProperty: PropTypes.func.isRequired,
};

export default Settings;
