import { connect } from 'react-redux';
import { editMessage, getMessage, removeMessage } from '../../../../../../redux/actions';
import { selectGroupOptions, selectMessageGroups } from '../../../../../../redux/selectors';
import EditAnnouncement from './EditAnnouncement';
import { getUserPermission } from '../../../../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    groupOptions: selectGroupOptions(state),
    loaded: state.property.activeMessageLoaded,
    chosenGroups: selectMessageGroups(state),
    message: state.property.activeMessage,
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editMessage: (values, messageId, propertyId) => dispatch(editMessage(values, messageId, propertyId)),
    removeMessage: (messageid, propertyId) => dispatch(removeMessage(messageid, propertyId)),
    getMessage: (propertyId, messageId) => dispatch(getMessage(propertyId, messageId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getMessage: () => dispatchProps.getMessage(ownProps.match.params.propertyId, ownProps.match.params.messageId),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditAnnouncement);
