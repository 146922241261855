import React from 'react';
import Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import InputScaffold from './InputScaffold';
import AlertMessage from '../AlertMessage';

export default function MRIIntegrationForm(props) {
  const { configuration, testConfigurationResult } = props;

  const initialValues = configuration
    ? {
        enabled: configuration.get('enabled'),
        web_domain: configuration.get('webDomain'),
        web_services_user: configuration.get('webServicesUser'),
        web_services_password: configuration.get('webServicesPassword'),
        client_id: configuration.get('clientId'),
        database_name: configuration.get('databaseName'),
        mix_partner_key: configuration.get('mixPartnerKey'),
        mri_property_id: configuration.get('mriPropertyId'),
      }
    : {
        enabled: false,
      };

  const validationSchema = Yup.object().shape({
    enabled: Yup.boolean().required('Please enable or disable MRI Integration'),
    web_domain: Yup.string()
      .trim()
      .required('Please enter your MRI Web Domain'),
    web_services_user: Yup.string()
      .trim()
      .required('Please enter your MRI Web Services Username'),
    web_services_password: Yup.string()
      .trim()
      .required('Please enter your MRI Web Services Password'),
    client_id: Yup.string()
      .trim()
      .required('Please enter your MRI Client ID'),
    database_name: Yup.string()
      .trim()
      .required('Please enter your MRI Database Name'),
    mix_partner_key: Yup.string()
      .trim()
      .required('Please enter your MIX Partner Key'),
    mri_property_id: Yup.string()
      .trim()
      .required('Please enter your MRI Property ID'),
  });

  return (
    <div>
      {/* @dev here's where the result is being returned. This alert component has a type prop that we can use
      to differentiate between error and sucecss like so: `type="error"` `type="success` */}
      {testConfigurationResult !== null && <AlertMessage msg={testConfigurationResult} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          props.saveMRIConfiguration(values);
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="paper radius-top-left radius-top-right mri-integration-form__wrapper">
              <h4 className="h4">MRI Integration</h4>
              <div>
                <div className="checkbox-list--item">
                  <label>
                    <Field
                      type="checkbox"
                      checked={values.enabled}
                      name="enabled"
                      onChange={() => {
                        setFieldValue('enabled', !values.enabled);
                      }}
                    />
                    Enable MRI Integration
                  </label>
                </div>
              </div>
              {values.enabled && (
                <div>
                  <InputScaffold
                    label="MRI Web Domain"
                    validation={touched.web_domain && errors.web_domain}
                    className="input-scaffold--large"
                    required
                  >
                    <Field type="text" name="web_domain" />
                  </InputScaffold>
                  <InputScaffold
                    label="MRI Web Services User"
                    validation={touched.web_services_user && errors.web_services_user}
                    className="input-scaffold--large"
                    required
                  >
                    <Field type="text" name="web_services_user" />
                  </InputScaffold>
                  <InputScaffold
                    label="MRI Web Services Password"
                    validation={touched.web_services_password && errors.web_services_password}
                    className="input-scaffold--large"
                    required
                  >
                    <Field type="text" name="web_services_password" />
                  </InputScaffold>
                  <InputScaffold
                    label="MRI Client ID"
                    validation={touched.client_id && errors.client_id}
                    className="input-scaffold--large"
                    required
                  >
                    <Field type="text" name="client_id" />
                  </InputScaffold>
                  <InputScaffold
                    label="MRI Database Name"
                    validation={touched.database_name && errors.database_name}
                    className="input-scaffold--large"
                    required
                  >
                    <Field type="text" name="database_name" />
                  </InputScaffold>
                  <InputScaffold
                    label="MRI MIX Partner Key"
                    validation={touched.mix_partner_key && errors.mix_partner_key}
                    className="input-scaffold--large"
                    required
                  >
                    <Field type="text" name="mix_partner_key" />
                  </InputScaffold>
                  <InputScaffold
                    label="MRI Property ID"
                    validation={touched.mri_property_id && errors.mri_property_id}
                    className="input-scaffold--large"
                    required
                  >
                    <Field type="text" name="mri_property_id" />
                  </InputScaffold>
                </div>
              )}
            </div>
            <div className="paper__footer radius-bottom-left radius-bottom-right">
              <button className="button" name="save" type="submit">
                Save
              </button>
              {true && (
                <button
                  className="button button--outline--primary"
                  name="test"
                  type="button"
                  onClick={() => props.testMRIConfiguration(values)}
                >
                  Test
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
