import React from 'react';

export const Registrations = ({ setFieldValue, values, unitGroupList, t }) => {
  const handleCheckboxToggle = (setFieldValue, values, e) => {
    const groupValues = values.event_group_ids || [];

    if (e.target.checked) {
      groupValues.push(e.target.value);
    } else {
      groupValues.splice(groupValues.indexOf(e.target.value), 1);
    }

    if (groupValues.length === 0) {
      setFieldValue('event_group_ids', []);
    } else {
      setFieldValue('event_group_ids', []);
    }

    setFieldValue('event_group_ids', groupValues);
  };

  const toggleAllResidents = setFieldValue => {
    setFieldValue('event_group_ids', []);
  };

  return (
    <div className="paper__grid">
      <div className="paper__section--grid-item">
        <section className="paper__section">
          <div className="section-header h4">{t('addRegisterTab.title')}</div>
          <p className="help-text tighter">{t('addRegisterTab.text')}</p>

          <div className="checkbox-list--item">
            <label>
              <input
                onChange={() => toggleAllResidents(setFieldValue)}
                type="checkbox"
                checked={values.event_group_ids.length === 0}
              />
              {t('addRegisterTab.allResidentsCheckbox')}
            </label>
          </div>
          {unitGroupList.map((option, index) => {
            return (
              <div key={index} className="checkbox-list--item">
                <label>
                  <input
                    onChange={e => handleCheckboxToggle(setFieldValue, values, e)}
                    type="checkbox"
                    checked={values.event_group_ids.indexOf(option.get('id')) >= 0}
                    value={option.get('id')}
                  />
                  {option.get('name')}
                </label>
              </div>
            );
          })}
        </section>
      </div>
    </div>
  );
};
