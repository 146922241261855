import TimePicker from 'rc-time-picker';
import React, { Fragment } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import InputScaffold from '../../../../../../../../common/forms/InputScaffold';
import { TextToggle } from '../../../../../../../../common/forms/TextToggle';
import AlertMessage from '../../../../../../../../common/AlertMessage';
import { FinancialSummary } from './FinancialSummary';
import Icon from '../../../../../../../../common/icons/icon';
import moment from 'moment';
import { dollarsToCents } from '../../util';
import { Link } from 'react-router-dom';

export const Financials = ({
  propertyId,
  errors,
  isSubmitting,
  setFieldValue,
  touched,
  values,
  stripeConnectAccountConnected,
  communityStripeConnectAccountLoaded,
  vendorsList,
  t,
}) => {
  const hasVendor = !!values.vendor_id;
  return (
    <div className="paper__grid">
      <div className="paper__section--grid-item">
        <section className="paper__section">
          <div className="section-header h4">{t('addFinancesTab.title')}</div>
          <InputScaffold label={t('addFinancesTab.eventType')}>
            <TextToggle
              name="is_paid_event"
              value={values.is_paid_event}
              onChange={value => {
                setFieldValue('is_paid_event', value);
                setFieldValue('price_amount', '0');
                setFieldValue('refund_cutoff_date', '');
                setFieldValue('refund_cutoff_time', null);
                setFieldValue('cancel_fee_percent', '');
                setFieldValue('community_fee_percent', '');
                setFieldValue('vendor_id', '');
              }}
              disabled={isSubmitting || !stripeConnectAccountConnected}
              checkedText={t('addFinancesTab.paid')}
              uncheckedText={t('addFinancesTab.free')}
            />
          </InputScaffold>
          {values.is_paid_event ? (
            <Fragment>
              <InputScaffold label="Ticket Price" required validation={touched.price_amount && errors.price_amount}>
                <div className="input-wrapper__prefix">
                  <div className="input--prefix-suffix-icon__wrapper">
                    <Icon icon="Money" />
                  </div>
                  <input
                    type="text"
                    name="price_amount"
                    maxLength="6"
                    disabled={isSubmitting}
                    onChange={({ target }) => {
                      if (/^[0-9]*.?[0-9]?[0-9]?$/.test(target.value)) {
                        setFieldValue('price_amount', target.value.replace(/[^0-9.]+/g, ''));
                      }
                    }}
                    value={values.price_amount}
                  />
                </div>
              </InputScaffold>
              <InputScaffold label="Vendor" validation={touched.vendor_id && errors.vendor_id}>
                <div className="input-wrapper__prefix">
                  <select
                    name="vendor_id"
                    value={values.vendor_id}
                    onChange={e => {
                      if (!e.target.value) {
                        setFieldValue('community_fee_percent', '');
                      }
                      setFieldValue('vendor_id', e.target.value);
                    }}
                  >
                    <option value={''}>No Vendor</option>
                    {vendorsList?.map((vendor, idx) => (
                      <option key={idx} value={vendor.id}>
                        {vendor.name}
                      </option>
                    ))}
                  </select>
                </div>
              </InputScaffold>
              {values.vendor_id && (
                <InputScaffold
                  label="Community Fee"
                  required
                  validation={touched.community_fee_percent && errors.community_fee_percent}
                >
                  <div className="input-wrapper__suffix">
                    <input
                      type="text"
                      name="community_fee_percent"
                      maxLength="3"
                      disabled={isSubmitting}
                      onChange={({ target }) => {
                        if (/^[0-9]*$/.test(target.value) && Number(target.value) <= 100) {
                          setFieldValue('community_fee_percent', target.value);
                        }
                      }}
                      value={values.community_fee_percent}
                    />
                    <div className="input--prefix-suffix-icon__wrapper">
                      <Icon icon="Percent" />
                    </div>
                  </div>
                </InputScaffold>
              )}
              <div className="double-input__wrapper">
                <InputScaffold
                  className="double-input-item"
                  label="Refund Cut-off Date"
                  required
                  validation={touched.refund_cutoff_date && errors.refund_cutoff_date}
                >
                  <DayPickerInput
                    inputProps={{
                      autoComplete: 'false',
                      disabled: isSubmitting,
                    }}
                    name="refund_cutoff_date"
                    value={values.refund_cutoff_date}
                    placeholder=""
                    format="MM/DD/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      numberOfMonths: 1,
                      disabledDays: date => moment(date).isBefore(moment().subtract(1, 'day')),
                    }}
                    onDayChange={date => setFieldValue('refund_cutoff_date', date)}
                    disabled={isSubmitting}
                  />
                </InputScaffold>
                <InputScaffold
                  className="double-input-item"
                  label="Refund Cut-Off Time"
                  required
                  validation={touched.refund_cutoff_time && errors.refund_cutoff_time}
                >
                  <TimePicker
                    name="refund_cutoff_time"
                    showSecond={false}
                    value={values.refund_cutoff_time}
                    onChange={time => setFieldValue('refund_cutoff_time', time)}
                    format="h:mm a"
                    allowEmpty={false}
                    use12Hours
                    inputReadOnly
                    disabled={isSubmitting}
                  />
                </InputScaffold>
              </div>
              <InputScaffold
                label="Cancelation Fee %"
                required
                validation={touched.cancel_fee_percent && errors.cancel_fee_percent}
              >
                <div className="input-wrapper__suffix">
                  <input
                    type="text"
                    name="cancel_fee_percent"
                    maxLength="3"
                    disabled={isSubmitting}
                    onChange={({ target }) => {
                      if (/^[0-9]*$/.test(target.value)) {
                        setFieldValue('cancel_fee_percent', target.value);
                      }
                    }}
                    value={values.cancel_fee_percent}
                  />
                  <div className="input--prefix-suffix-icon__wrapper">
                    <Icon icon="Percent" />
                  </div>
                </div>
              </InputScaffold>
            </Fragment>
          ) : null}
        </section>
      </div>
      {values.is_paid_event ? (
        <div className="paper__section--grid-item">
          <section className="paper__section">
            <FinancialSummary
              priceInCents={values.price_amount && !errors.price_amount ? dollarsToCents(values.price_amount) : null}
              communityFee={values.community_fee_percent}
              hasVendor={hasVendor}
            />
          </section>
        </div>
      ) : null}
    </div>
  );
};
