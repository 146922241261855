import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import NotFound from '../../../../common/NotFound';
import UnitDetails from './views/UnitDetails';
import Loading from '../../../../../../common/Loading';
import EditUnit from './views/EditUnit';

class Unit extends Component {
  componentDidMount() {
    this.props.getUnit();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.unitId !== this.props.match.params.unitId) {
      this.props.getUnit();
    }
  }

  componentWillUnmount() {
    this.props.resetUnit();
  }

  render() {
    const { unitError, loaded, match } = this.props;

    let routes;

    if (loaded) {
      routes = (
        <Switch>
          <Route exact path={`${match.path}/edit`} component={EditUnit} />
          <Route path={`${match.path}`} component={UnitDetails} />
          <Route component={NotFound} />
        </Switch>
      );
    } else if (unitError) {
      routes = <Route component={NotFound} />;
    } else {
      routes = <Loading />;
    }

    return routes;
  }
}

Unit.propTypes = {
  getUnit: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  resetUnit: PropTypes.func.isRequired,
  unitError: PropTypes.bool.isRequired,
};
export default Unit;
