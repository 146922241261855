import React from 'react';
import InputScaffold from '../../../../../../../../../../common/forms/InputScaffold';
import RadioFormField from '../../../../../../../../../../common/forms/RadioFormField';

export const SGFormPostTourMessage = ({ values, errors, handleChange, setFieldValue }) => (
  <div className="sg-message-container m-t30">
    <InputScaffold>
      <textarea
        className="sg-message-textarea"
        name="post_message"
        onChange={handleChange}
        value={values.post_message}
        rows="9"
        placeholder="Type follow-up message to prospective residents here…"
      />
    </InputScaffold>
    <div className="areas-available ">
      <div className="checkbox-list--item m-t30">
        <RadioFormField
          error={errors.send_type}
          isLabelСolor
          onChange={activeField => {
            setFieldValue('post_send_type', activeField ? activeField.value : activeField);
          }}
          fields={[
            {
              id: 1,
              name: 'post_send_type',
              value: 'all',
              label: 'Send as both email and text message',
              labelText: '',
            },
            {
              id: 2,
              name: 'post_send_type',
              value: 'message',
              label: 'Send as text message only',
              labelText: '',
            },
            {
              id: 3,
              name: 'post_send_type',
              value: 'email',
              label: 'Send as email only',
              labelText: '',
            },
          ]}
        />
      </div>
    </div>
  </div>
);
