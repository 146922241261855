export default {
  title: 'Resident App Features',
  description:
    "These features need to be enabled for your residents to access them through the mobile application. Features can be enabled/disabled at any time, but we don't recommend changing this setting regularly.",
  announcements: 'ENABLE ANNOUNCEMENTS',
  discussion: 'ENABLE DISCUSSION',
  discussionBoardReview: 'ENABLE DISCUSSION BOARD REVIEW',
  generalPosts: 'ENABLE GENERAL POSTS',
  forSalePosts: 'ENABLE FOR SALE POSTS',
  recommendationPosts: 'ENABLE RECOMMENDATION POSTS',
  eventPosts: 'ENABLE EVENT POSTS',
  events: 'ENABLE EVENTS',
  maintenance: 'ENABLE MAINTENANCE',
  residentCommunityGuests: 'ENABLE RESIDENT COMMUNITY GUESTS',
  fullTimeGuestSchedules: 'ENABLE FULL TIME COMMUNITY GUEST SCHEDULES',
  scheduledGuestSchedules: 'ENABLE SCHEDULED COMMUNITY GUEST SCHEDULES',
  oneDayGuestSchedules: 'ENABLE ONE DAY COMMUNITY GUEST SCHEDULES',
  maxGuestScheduleDuration: 'MAXIMUM SCHEDULED GUEST SCHEDULE DURATION (DAYS)',
  minDurationNote: 'Cannot be less than 1 day',
  maxDurationNote: 'Cannot be more than 999 days', //!
  saveSettings: 'Save Settings',
  savedConfirmation: 'Resident Features saved!',

  modalTitle: 'Disabling Discussion Board Review',
  modalBody:
    'Are you sure you want to disable discussion board reviews? All pending board reviews will be automatically approved.',
  modalLinkTitle: 'Review Pending Discussions',
};
