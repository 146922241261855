import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Yup from 'yup';

import InputScaffold from '../../../../../common/forms/InputScaffold';

class AccessRequestFilterForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    doors: PropTypes.object.isRequired,
    telephoneEntryDevices: PropTypes.object.isRequired,
    communityHasDMPMasterPanel: PropTypes.bool.isRequired,
  };

  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    const submitValues = {};

    Object.keys(values).forEach(key => {
      if (values[key] !== '') {
        if (values[key] instanceof Date) {
          submitValues[key] = moment(values[key]).toISOString();
        } else {
          submitValues[key] = values[key];
        }
      }
    });

    this.props.onSubmit(submitValues);
  };

  render() {
    const { initialValues, onCancel, doors, telephoneEntryDevices, communityHasDMPMasterPanel } = this.props;

    return (
      <Formik
        initialValues={
          initialValues || {
            name: '',
            user_code: '',
            start_date: '',
            end_date: '',
            door_id: '',
            telephone_entry_device_id: '',
          }
        }
        validationSchema={Yup.object().shape({
          user_code: Yup.number()
            .integer()
            .positive()
            .label('User Code')
            .typeError('User Code must be a number'),
        })}
        onSubmit={this.handleSubmit}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          touched,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
        }) => {
          return (
            <form className="access-request__filter-form" onSubmit={handleSubmit}>
              <div className="filter-top-row">
                <InputScaffold label="Name" validation={touched.name && errors.name}>
                  <Field type="text" name="name" onChange={handleChange} value={values.name} />
                </InputScaffold>
                <InputScaffold label="User Code" validation={touched.user_code && errors.user_code}>
                  <Field type="text" name="user_code" onChange={handleChange} value={values.user_code} />
                </InputScaffold>
                {communityHasDMPMasterPanel ? (
                  <InputScaffold label="Location" validation={touched.door_id && errors.door_id}>
                    <Field component="select" name="door_id" onChange={handleChange} value={values.door_id}>
                      <option value=""></option>
                      {doors.map(door => (
                        <option value={door.get('id')} key={door.get('id')}>
                          {door.get('name')}
                        </option>
                      ))}
                    </Field>
                  </InputScaffold>
                ) : (
                  <InputScaffold
                    label="Location"
                    validation={touched.telephone_entry_device_id && errors.telephone_entry_device_id}
                  >
                    <Field
                      component="select"
                      name="telephone_entry_device_id"
                      onChange={handleChange}
                      value={values.telephone_entry_device_id}
                    >
                      <option value=""></option>
                      {telephoneEntryDevices.map(device => (
                        <option value={device.get('id')} key={device.get('id')}>
                          {device.get('name')}
                        </option>
                      ))}
                    </Field>
                  </InputScaffold>
                )}
              </div>
              <div className="filter-date-picker">
                <InputScaffold label="Start Date" validation={touched.start_date && errors.start_date}>
                  <DatePicker
                    isClearable
                    onChange={date => {
                      setFieldTouched('start_date', true);
                      setFieldValue('start_date', date || '');
                    }}
                    selected={values.start_date || null}
                    showTimeSelect
                    timeFormat="h:mm aa"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </InputScaffold>
                <div className="dash">&ndash;</div>
                <InputScaffold label="End Date" validation={touched.end_date && errors.end_date}>
                  <DatePicker
                    isClearable
                    onChange={date => {
                      setFieldTouched('end_date', true);
                      setFieldValue('end_date', date || '');
                    }}
                    selected={values.end_date || null}
                    showTimeSelect
                    timeFormat="h:mm aa"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </InputScaffold>
              </div>

              <div className="button__wrapper">
                <button
                  type="button"
                  className="button button--secondary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Cancel
                </button>
                <button disabled={isSubmitting || Object.keys(errors).length} className="button button--secondary">
                  Apply
                </button>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export default AccessRequestFilterForm;
