import { createSelector } from 'reselect';

const offerSelector = state => state.activeOffer;
const propertiesSelector = state => state.properties;
const unitsSelector = state => state.property.unitList;
const roleSelector = state => state.roles;
const credentialTypeSelector = state => state.credentialTypes;
const telephoneEntryDevicesSelector = state => state.telephoneEntryDevices;

const permissions = state => state.user.get('permissions');
export const getUserPermission = createSelector(permissions, permissions => permissions || []);

export const selectorIsAdmin = createSelector(roleSelector, roleList => roleList.includes('ADMIN'));
export const selectorIsPropertyManager = createSelector(roleSelector, roleList =>
  roleList.includes('PROPERTY_MANAGER')
);

export const selectorIsVendor = createSelector(roleSelector, roleList => roleList.includes('VENDOR'));

export const selectCurrentAdmin = createSelector(
  (state, props) => props.match.params.adminId,
  state => state.adminList,
  (adminId, adminList) => adminList.find(admin => admin.get('id') === adminId)
);

export const selectActiveOffer = createSelector(offerSelector, offer => offer.toJS());

export const selectProperties = createSelector(propertiesSelector, property => property.toJS());
export const selectUnits = createSelector(unitsSelector, unit => unit.toJS());

export const selectPropertyOptions = createSelector(propertiesSelector, properties => {
  return properties
    .map(property => {
      return { value: property.get('id'), label: property.get('name') };
    })
    .insert(0, { value: '', label: 'Unassigned' })
    .toJS();
});

export const selectPropertiesForDropdown = createSelector(selectProperties, properties =>
  properties.map(property => {
    return { value: property.id, label: property.name };
  })
);

export const selectPropertyFilterOptions = createSelector(selectPropertiesForDropdown, properties => {
  properties.unshift({ value: 'unassigned', label: 'Not Yet Associated' });

  return properties;
});

export const selectUnitsForDropdown = createSelector(selectUnits, units =>
  units.map(unit => {
    return { value: unit.id, label: unit.number };
  })
);

export const selectActiveProperty = createSelector(
  state => state.properties,
  (state, props) => props.match.params.propertyId,
  (properties, propertyId) => properties.find(property => property.get('id') === propertyId)
);

export const selectActiveCredentialType = createSelector(
  credentialTypeSelector,
  (state, props) => props.match.params.credentialTypeId,
  (credentialTypes, credentialTypeId) =>
    credentialTypes.find(credentialType => credentialType.get('id') === credentialTypeId)
);

export const selectActiveTelephoneEntryDevice = createSelector(
  telephoneEntryDevicesSelector,
  (state, props) => props.match.params.telephoneEntryDeviceId,
  (telephoneEntryDevices, telephoneEntryDeviceId) =>
    telephoneEntryDevices.find(credentialType => credentialType.get('id') === telephoneEntryDeviceId)
);

export const selectCredentialTypes = createSelector(
  credentialTypeSelector,
  credentialTypes => credentialTypes && credentialTypes.toJS()
);
