export default {
  searchUnits: 'Поиск апартаментов',
  searchTitle: 'ПОИСК',
  clearSearchBtn: 'Очистить поиск',
  counterUnits: 'Апартаменты',
  counterResidents: 'Жители',
  filterUnits: 'Фильтр:',
  filterOptionsNone: 'Без фильтра',
  filterOptionsActive: 'Активные',
  filterOptionsInactive: 'Неактивные',
  filterOptionsOccupied: 'Занят',
  filterOptionsUnoccupied: 'Не занят',
  importUnitsBtn: 'Импортировать апартаменты',
  addUnitBtn: 'Добавить апартаменты',
  moreUnit: 'и более',
  importUnitsTitle: 'Импортировать апартаменты',
  importUnitsSubTitle: 'Импортировать используя CSV файл',
  importUnitsChooseFile: 'Выбрать CSV файл...',
  importUnitsChooseBtn: 'Выбрать файл',
  importUnitsText: 'Нажмите сюда, чтобы скачать пример',
  importUnitsSubmitBtn: 'Отправить',
  detailsUnitsTitle: 'Детали апартаментов',
  informationUnitsTitle: 'Информация об апартаментах',
  unitNumber: 'НОМЕР АПАРТАМЕНТОВ',
  unitNumberPlaceholder: 'Добавьте имя или номер апартаментов',
  unitGroups: 'Группы',
  unitSquareFootage: 'КВАДРАТНЫЕ МЕТРЫ',
  unitSquareFootagePlaceholder: 'Квадратные метры этих апартаментов',
  detailsSaveBtn: 'Сохранить апартаменты',
  unitRemoveBtn: 'Удалить апартаменты',
  detailsCancelBtn: 'Отменить',
  unitResidentsTitle: 'Жители',
  unitAddResidentBtn: 'Добавить жителя',
  unitResidentColumnNumber: 'ИМЯ',
  unitResidentColumnPhone: 'НОМЕР ТЕЛЕФОНА',
  unitResidentInformation: 'Информация о жителе',
  residentFirstName: 'ИМЯ',
  residentFirstNameError: 'Введите имя пожалуйста',
  residentLastName: 'ФАМИЛИЯ',
  residentLastNameError: 'Введите фамилию пожалуйста',
  residentEmail: 'ЭЛЕКТРОННАЯ ПОЧТА',
  residentEmailError: 'Введите электронную почту пожалуйста',
  residentPhone: 'НОМЕР ТЕЛЕФОНА',
  residentPhoneError: 'Должен быть действительный номер телефона',
  residentHelperText: `Вводя номер телефона жителя и соглашаясь с Лицензионным соглашением, житель соглашается получать
  SMS-уведомления. Чтобы отказаться от получения SMS-уведомлений, удалите номер телефона здесь или снимите флажок SMS
  в уведомлениях в приложении для жителей. Могут применяться тарифы на передачу сообщений и данных.`,
  residentCheckbox: 'Включить доступ жителю к гостевому сообществу',
  enableDirectory: 'Включить список каталогов',
  saveResidentBtn: 'Сохранить жителя',
  saveResidentBtn_1: 'Житель сохраняется...',
  unitDevicesTitle: 'Устройства',
  unitDevicesHelperText: 'Устройства не найдены',
  unitDevicesTextBtn: 'Запросить активацию панели',
  removeResident: 'Удалить жителя',
  removalConfirmation: 'Вы уверены что хотите <strong className="strong">удалить этого Жителя</strong>?',
};
