import { useState } from 'react';

export const useResidentDenyModal = () => {
  const [isDenyOpen, setIsDenyOpen] = useState(false);

  return {
    isDenyOpen,
    handleDenyCancel: () => setIsDenyOpen(false),
    handleDenyOpen: () => setIsDenyOpen(true),
  };
};
