import React from 'react';

function Search() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="136.871" height="136.87" viewBox="0 0 136.871 136.87">
      <path
        id="Icon_awesome-save"
        data-name="Icon awesome-save"
        d="M132.575,32.172,106.948,6.545a14.665,14.665,0,0,0-10.369-4.3H14.665A14.665,14.665,0,0,0,0,16.915V124.456a14.665,14.665,0,0,0,14.665,14.665H122.206a14.665,14.665,0,0,0,14.665-14.665V42.542a14.665,14.665,0,0,0-4.3-10.369Zm-64.14,87.4a19.553,19.553,0,1,1,19.553-19.553A19.553,19.553,0,0,1,68.435,119.568ZM97.765,26.532v30.71A3.666,3.666,0,0,1,94.1,60.909H23.219a3.666,3.666,0,0,1-3.666-3.666V25.469A3.666,3.666,0,0,1,23.219,21.8H93.035a3.665,3.665,0,0,1,2.592,1.074l1.063,1.063a3.665,3.665,0,0,1,1.074,2.592Z"
        transform="translate(0 -2.25)"
        fill="#fff"
      />
    </svg>
  );
  /* eslint-enable */
}

export default Search;
