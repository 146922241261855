import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../../common/icons/icon';

class PropertySubNavLink extends Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    className: PropTypes.string,
    showChildren: PropTypes.bool,
  };

  static defaultProps = {
    showChildren: true,
  };

  state = {
    collapsed: true,
  };

  componentDidMount() {
    this.checkToPathActive();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.checkToPathActive();
    }
  }

  checkToPathActive = () => {
    const { location, to } = this.props;

    const isToPathActive = !!matchPath(location.pathname, {
      path: to,
      exact: false,
      strict: false,
      location,
    });

    this.setState({
      collapsed: !isToPathActive,
    });
  };

  render() {
    const { children, to, icon, label, className, showChildren } = this.props;
    const { collapsed } = this.state;

    return (
      <div className="property-sub-nav--link">
        <NavLink
          className={className}
          to={to}
          activeClassName="current"
          onClick={() => {
            this.setState({ collapsed: false });
          }}
        >
          <span className="nav-link">
            <Icon icon={icon} />
            <span>{label}</span>
          </span>
          {showChildren && (
            <Icon
              className={classNames(['nav-arrow', collapsed ? 'collapsed' : 'expanded'])}
              icon="ChevronRight"
              onClick={e => {
                this.setState(state => ({ collapsed: !state.collapsed }));
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          )}
        </NavLink>
        {!collapsed && showChildren && <div className="sub-navigation">{children}</div>}
      </div>
    );
  }
}

export default withRouter(PropertySubNavLink);
