import React from 'react';

function Purchaser() {
  /* eslint-disable max-len */
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path strokeOpacity=".008" stroke="#000" strokeWidth=".5" d="M0 0h24v24H0z" />
        <path
          d="M23.2 11.66l-2.44 2.78.34 3.68-3.61.82-1.89 3.18-3.4-1.46-3.4 1.47-1.89-3.18-3.61-.82.34-3.69-2.44-2.78 2.44-2.79-.34-3.68 3.61-.81L8.8 1.2l3.4 1.46 3.4-1.46 1.89 3.18 3.61.82-.34 3.68 2.44 2.78zm-10.37-.393c-1.338-.394-1.768-.8-1.768-1.434 0-.726.595-1.233 1.591-1.233 1.05 0 1.438.567 1.474 1.4h1.302c-.041-1.147-.66-2.2-1.892-2.54V6H11.77v1.44c-1.143.28-2.063 1.12-2.063 2.407 0 1.54 1.126 2.306 2.77 2.753 1.474.4 1.768.987 1.768 1.607 0 .46-.288 1.193-1.591 1.193-1.214 0-1.692-.613-1.756-1.4H9.6c.07 1.46 1.037 2.28 2.169 2.553V18h1.768v-1.433c1.15-.247 2.063-1 2.063-2.367 0-1.893-1.432-2.54-2.77-2.933z"
          fill="#1E90FF"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Purchaser;
