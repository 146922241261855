import React from 'react';

function Tips() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1024 1024">
      <path
        d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m0 64C311.701333 149.333333 149.333333 311.701333 149.333333 512s162.368 362.666667 362.666667 362.666667 362.666667-162.368 362.666667-362.666667S712.298667 149.333333 512 149.333333z m0 256a32 32 0 0 1 32 32V661.333333H576a32 32 0 0 1 0 64h-128a32 32 0 0 1 0-64h32v-192H448a32 32 0 0 1 0-64h64z m0-128a42.666667 42.666667 0 1 1 0 85.333334 42.666667 42.666667 0 0 1 0-85.333334z"
        fill="#dadada"
      />
    </svg>
  );
  /* eslint-enable */
}

export default Tips;
