import { fromJS } from 'immutable';

const initialState = {
  stripeAccount: null,
  loadingStripeAccount: true,
  fetchingAccountLink: false,
};

const vendorStripeConnect = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_VENDOR_STRIPE_CONNECT_ACCOUNT_REQUEST':
      return { ...state, loadingStripeAccount: true };
    case 'GET_VENDOR_STRIPE_CONNECT_ACCOUNT_SUCCESS':
      return { ...state, stripeAccount: fromJS(action.json), loadingStripeAccount: false };
    case 'GET_VENDOR_STRIPE_CONNECT_ACCOUNT_FAILURE':
      return { ...state, stripeAccount: null, loadingStripeAccount: false };
    case 'CREATE_VENDOR_STRIPE_CONNECT_ACCOUNT_REQUEST':
      return { ...state, fetchingAccountLink: true };
    case 'CREATE_VENDOR_STRIPE_CONNECT_ACCOUNT_SUCCESS':
      return { ...state, stripeAccountLink: fromJS(action.json) };
    case 'CREATE_VENDOR_STRIPE_CONNECT_ACCOUNT_FAILURE':
      return { ...state, stripeAccountLink: null, fetchingAccountLink: false };
    case 'CLEAR_VENDOR_STRIPE_ACCOUNT_LINK':
      return { ...state, stripeAccountLink: null };
    default:
      return state;
  }
};

export default vendorStripeConnect;
