import React, { Component } from 'react';
import Loading from '../../../../../../../../common/Loading';

class RegisterResultModel extends Component {
  state = {
    disabled: false,
  };

  onIncreaseMax = () => {
    const { onIncreaseMax } = this.props;
    this.setState({
      disabled: true,
    });
    onIncreaseMax().then(() => {
      this.setState({
        disabled: false,
      });
    });
  };

  onWaitList = () => {
    const { onWaitList } = this.props;
    this.setState({
      disabled: true,
    });
    onWaitList().then(() => {
      this.setState({
        disabled: false,
      });
    });
  };

  render() {
    const { onCancel, visible, onVerify, success, duplicate, OnOk, event, t } = this.props;
    const { disabled } = this.state;
    if (!visible) return null;

    return (
      <div className="modal modal--visible">
        <div className="modal__children">
          <div className="modal__header">
            <h3>
              {success === true
                ? t('guestRegistrationTab.registrationResultModal.successTitle')
                : t('guestRegistrationTab.registrationResultModal.errorTitle')}
            </h3>
          </div>
          <div className="modal__body">
            <div className="modal__body--text">
              {disabled && (
                <div className="register_result_model_loading">
                  <Loading />
                </div>
              )}
              {success === true ? (
                t('guestRegistrationTab.registrationResultModal.successBody')
              ) : (
                <>
                  {duplicate === true ? (
                    t('guestRegistrationTab.registrationResultModal.duplicate')
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: t('guestRegistrationTab.registrationResultModal.errorBody') }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="modal__button--container">
            {success === true ? (
              <>
                <button className="button" type="button" onClick={onVerify}>
                  {t('verify', { ns: 'buttons' })}
                </button>
                <button className="button" type="button" onClick={onCancel}>
                  {t('ok', { ns: 'buttons' })}
                </button>
              </>
            ) : (
              <>
                {duplicate === true ? (
                  <button className="button" type="button" onClick={OnOk}>
                    {t('ok', { ns: 'buttons' })}
                  </button>
                ) : (
                  <>
                    <button className="button" type="button" onClick={this.onIncreaseMax} disabled={disabled}>
                      {t('guestRegistrationTab.registrationResultModal.increaseMax')}
                    </button>
                    {event.waitlist_allowed && (
                      <button className="button" type="button" onClick={this.onWaitList} disabled={disabled}>
                        {t('guestRegistrationTab.registrationResultModal.waitlist')}
                      </button>
                    )}
                    <a href="/#" onClick={onCancel} className={disabled ? 'register-disabled' : ''}>
                      {t('guestRegistrationTab.registrationResultModal.deselect')}
                    </a>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <button type="button" className="modal__overlay" onClick={onCancel} />
      </div>
    );
  }
}

export default RegisterResultModel;
