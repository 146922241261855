export default {
  typeOfMockData: 'RU',
  maintenanceRequestsTitle: 'Запросы на техническое обслуживание',
  helpFromManagementTitle: 'Помощь от менеджеров',

  tableHeaders: {
    authorID: 'ИД',
    authorName: 'ИМЯ АВТОРА',
    authorUnit: 'НОМЕР КВАРТИРЫ АВТОРА',
    requestType: 'ТИП ЗАПРОСА',
    status: 'СТАТУС',
    authorType: 'ТИП КЛИЕНТА',
  },

  statuses: {
    new: 'Новое',
    responded: 'Отвечено',
    pending: 'В ожидании',
    resolved: 'Решено',
    problem: 'Проблема',
  },

  details: {
    description: 'Описание',
    accessInstructions: 'Инструкции по доступу',
    permission: 'Разрешение на вход',
    photos: 'Фотографии',
    addResponsePlaceholder: 'Добавить ответ...',
    responseButton: 'Ответить',
    responseField: 'Ответ',
    yes: 'Есть',
    no: 'Нет',
  },
};
