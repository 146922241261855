import React from 'react';

function Doorbell() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" version="1.1" viewBox="0 0 40 40">
      <g>
        <path
          d="M11.125 36.667C10.347 36.667 9.68734 36.396 9.14601 35.854C8.60401 35.3127 8.33301 34.653 8.33301 33.875V6.12501C8.33301 5.34701 8.60401 4.68734 9.14601 4.14601C9.68734 3.60401 10.347 3.33301 11.125 3.33301H28.875C29.653 3.33301 30.3127 3.60401 30.854 4.14601C31.396 4.68734 31.667 5.34701 31.667 6.12501V33.875C31.667 34.653 31.396 35.3127 30.854 35.854C30.3127 36.396 29.653 36.667 28.875 36.667H11.125ZM11.125 33.875H28.875V6.12501H11.125V33.875ZM20 31.667C20.9167 31.667 21.7013 31.3407 22.354 30.688C23.0067 30.0347 23.333 29.2497 23.333 28.333C23.333 27.4163 23.0067 26.6317 22.354 25.979C21.7013 25.3263 20.9167 25 20 25C19.0833 25 18.2987 25.3263 17.646 25.979C16.9933 26.6317 16.667 27.4163 16.667 28.333C16.667 29.2497 16.9933 30.0347 17.646 30.688C18.2987 31.3407 19.0833 31.667 20 31.667ZM20 30.292C19.472 30.292 19.0137 30.0973 18.625 29.708C18.2363 29.3193 18.042 28.861 18.042 28.333C18.042 27.7777 18.2363 27.3127 18.625 26.938C19.0137 26.5627 19.472 26.375 20 26.375C20.5553 26.375 21.0207 26.5627 21.396 26.938C21.7707 27.3127 21.958 27.7777 21.958 28.333C21.958 28.861 21.7707 29.3193 21.396 29.708C21.0207 30.0973 20.5553 30.292 20 30.292ZM20 22.208C20.4167 22.208 20.75 22.083 21 21.833C21.25 21.583 21.375 21.2497 21.375 20.833H18.625C18.625 21.2497 18.75 21.583 19 21.833C19.25 22.083 19.5833 22.208 20 22.208ZM13.333 19.708H26.667V18.333H24.708V14.5C24.708 13.25 24.4233 12.1807 23.854 11.292C23.2847 10.4027 22.4167 9.79134 21.25 9.45801V8.87501C21.25 8.54167 21.132 8.25701 20.896 8.02101C20.66 7.78501 20.3613 7.66701 20 7.66701C19.6387 7.66701 19.34 7.78501 19.104 8.02101C18.868 8.25701 18.75 8.54167 18.75 8.87501V9.45801C17.5833 9.87467 16.7153 10.5067 16.146 11.354C15.5767 12.2013 15.292 13.25 15.292 14.5V18.333H13.333V19.708Z"
          id="Path_1"
          data-name="Path 1"
          fill="#7a8a99"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Doorbell;
