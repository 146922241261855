import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Icon from '../../../../../../../../common/icons/icon';
import BatteryIndicator from '../../../../../../../../common/BatteryIndicator';
import Loading from '../../../../../../../../common/Loading/ComponentLoading';
import moment from 'moment';
import {
  getDisplayTempForStatus,
  TEMP_RANGE_PLUS_OR_MINUS,
  MIN_THERMO_TEMP,
  MAX_THERMO_TEMP,
} from '../../../../../../../../common/utils/thermostat-utils';
import debounce from 'lodash/debounce';
import ScheduleDeviceRow, { getDisplayedBrightness } from './ScheduleDeviceRow';
import DetailBit from '../../../../../../../../common/DetailBit';
import Delete from '../../../../../../../../common/Delete';
import Switch from '../../../../../../../../common/forms/Switch';

class DeviceControlRow extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    onToggleExpanded: PropTypes.func.isRequired,
  };

  state = {
    disableTempControls: false,
    temperature: 0,
    setpoint_cooling: 78,
    setpoint_heating: 80,
    fan_mode: 'auto',
    currentMode: 'AUTO',
    currentDisplayTemp: '–',
    thermostatTempSettings: {},
    creatingSchedule: false,
    editingSchedule: null,
    removingSchedule: null,
    showMotion: false,
    vdbMotionNotifications: false,
    vdbLoiteringNotifications: false,
    volume: 0,
    api_domain: null,
    nginx_domain: null,
    night_vision: false,
  };

  constructor(props) {
    super(props);

    this.debouncedChangeThermostatTemp = debounce(this.changeThermostatTemp, 1500);
  }

  submitSchedule = (payload, schedule = null) => {
    if (schedule) {
      return this.props.editDeviceSchedule(schedule.get('id'), payload);
    }
    return this.props.createDeviceSchedule(this.props.device.get('id'), payload);
  };

  deleteSchedule = scheduleId => {
    this.props.deleteDeviceSchedule(scheduleId);
  };

  getDisplayTemp = thermostat => {
    switch (thermostat.get('mode')) {
      case 'OFF':
        return '--';
      case 'COOL':
        return parseInt(thermostat.get('setpoint_cooling'), 10);
      case 'HEAT':
        return parseInt(thermostat.get('setpoint_heating'), 10);
      case 'AUTO':
        return Math.floor(
          (parseInt(thermostat.get('setpoint_cooling'), 10) + parseInt(thermostat.get('setpoint_heating'), 10)) / 2
        );
      default:
        return '--';
    }
  };

  getDisplayTemperatureSetting = thermostat => {
    return (
      (thermostat.get('mode') !== 'OFF' && parseInt(this.state.thermostatTempSettings[thermostat.get('number')], 10)) ||
      this.getDisplayTemp(thermostat)
    );
  };

  _incrementTempSettingBy(thermostat, byValue) {
    this.setState(
      prevState => {
        const thermostatTempSettings = { ...prevState.thermostatTempSettings };
        if (!thermostatTempSettings[thermostat.get('number')]) {
          thermostatTempSettings[thermostat.get('number')] = this.getDisplayTemp(thermostat);
        }
        const [min, max] = this.getTempRangeForMode(thermostat.get('mode'));
        thermostatTempSettings[thermostat.get('number')] = this.clamp(
          parseInt(thermostatTempSettings[thermostat.get('number')], 10) + byValue,
          min,
          max
        );
        return { thermostatTempSettings };
      },
      () => this.debouncedChangeThermostatTemp(thermostat, this.state.thermostatTempSettings[thermostat.get('number')])
    );
  }

  incrementTempSetting = thermostat => {
    this.setState({ currentDisplayTemp: this.state.currentDisplayTemp + 1 });
    this._incrementTempSettingBy(thermostat, 1);
  };

  decrementTempSetting = thermostat => {
    this.setState({ currentDisplayTemp: this.state.currentDisplayTemp - 1 });
    this._incrementTempSettingBy(thermostat, -1);
  };

  getTempRangeForMode() {
    const mode = this.props.device.getIn(['last_status', 'mode']);
    const min = mode === 'AUTO' ? MIN_THERMO_TEMP + TEMP_RANGE_PLUS_OR_MINUS : MIN_THERMO_TEMP;
    const max = mode === 'AUTO' ? MAX_THERMO_TEMP - TEMP_RANGE_PLUS_OR_MINUS : MAX_THERMO_TEMP;
    return [min, max];
  }

  changeThermostatTemp(thermostat, currentTemp) {
    const { updateThermostat } = this.props;
    const payload = {
      mode: thermostat.get('mode'),
      setpoint_cooling: thermostat.get('setpoint_cooling'),
      setpoint_heating: thermostat.get('setpoint_heating'),
      fan_mode: thermostat.get('fan_mode'),
    };
    if (thermostat.get('mode') === 'AUTO') {
      payload.setpoint_cooling = currentTemp + TEMP_RANGE_PLUS_OR_MINUS;
      payload.setpoint_heating = currentTemp - TEMP_RANGE_PLUS_OR_MINUS;
    } else if (thermostat.get('mode') === 'COOL') {
      payload.setpoint_cooling = currentTemp;
      // TODO: This is a workaround for DMP Request issue. This can be removed when the API request to DMP is fixed.
      if (payload.setpoint_heating <= MIN_THERMO_TEMP + 3 || payload.setpoint_heating >= MAX_THERMO_TEMP - 3) {
        payload.setpoint_heating = payload.setpoint_cooling;
      }
    } else if (thermostat.get('mode') === 'HEAT') {
      payload.setpoint_heating = currentTemp;
      // TODO: This is a workaround for DMP Request issue. This can be removed when the API request to DMP is fixed.
      if (payload.setpoint_cooling <= MIN_THERMO_TEMP + 3 || payload.setpoint_cooling >= MAX_THERMO_TEMP - 3) {
        payload.setpoint_cooling = payload.setpoint_heating;
      }
    }
    this.props.setDeviceStatus(this.props.device.get('id'), true);
    updateThermostat(this.props.device.get('id'), payload).then(() =>
      this.props.setDeviceStatus(this.props.device.get('id'), false)
    );
  }

  changeThermostatMode(thermostat, mode) {
    const { updateThermostat } = this.props;

    const payload = {
      mode,
      setpoint_cooling: thermostat.get('setpoint_cooling'),
      setpoint_heating: thermostat.get('setpoint_heating'),
      fan_mode: thermostat.get('fan_mode'),
    };

    const currentMode = thermostat.get('mode');
    const currentDisplayTemp = this.getDisplayTemp(thermostat);

    if (mode === 'AUTO') {
      // If switching to auto, use a temperature range
      let midpoint = currentMode === 'COOL' ? thermostat.get('setpoint_cooling') : thermostat.get('setpoint_heating');
      if (midpoint < MIN_THERMO_TEMP + TEMP_RANGE_PLUS_OR_MINUS) {
        midpoint = MIN_THERMO_TEMP + TEMP_RANGE_PLUS_OR_MINUS;
      } else if (midpoint > MAX_THERMO_TEMP - TEMP_RANGE_PLUS_OR_MINUS) {
        midpoint = MAX_THERMO_TEMP - TEMP_RANGE_PLUS_OR_MINUS;
      }
      payload.setpoint_heating = parseInt(midpoint - TEMP_RANGE_PLUS_OR_MINUS, 10);
      payload.setpoint_cooling = parseInt(midpoint + TEMP_RANGE_PLUS_OR_MINUS, 10);
    } else if (mode === 'COOL') {
      if (currentMode === 'AUTO') {
        payload.setpoint_cooling = parseInt(currentDisplayTemp, 10);
      } else if (currentMode === 'HEAT') {
        payload.setpoint_cooling = parseInt(thermostat.get('setpoint_heating'), 10);
      }
    } else if (mode === 'HEAT') {
      if (currentMode === 'AUTO') {
        payload.setpoint_heating = parseInt(currentDisplayTemp, 10);
      } else if (currentMode === 'COOL') {
        payload.setpoint_heating = parseInt(thermostat.get('setpoint_cooling'), 10);
      }
    }
    payload.setpoint_heating = this.clamp(payload.setpoint_heating);
    payload.setpoint_cooling = this.clamp(payload.setpoint_cooling);
    this.props.setDeviceStatus(this.props.device.get('id'), true);
    updateThermostat(this.props.device.get('id'), payload).then(() =>
      this.props.setDeviceStatus(this.props.device.get('id'), false)
    );
  }

  changeThermostatFanMode(mode) {
    const payload = { fan_mode: mode };

    this.props.setDeviceStatus(this.props.device.get('id'), true);
    this.props
      .updateThermostat(this.props.device.get('id'), payload)
      .then(() => this.props.setDeviceStatus(this.props.device.get('id'), false));
  }

  clamp = (value, min = MIN_THERMO_TEMP, max = MAX_THERMO_TEMP) => {
    return Math.min(Math.max(value, min), max);
  };

  componentDidMount() {
    const {
      disableControls,
      device,
      getLockStatus,
      getLightStatus,
      getThermostatLatest,
      getVideoDoorbellDevice,
      setDeviceStatus,
    } = this.props;
    if (!disableControls) {
      if (device.get('dmp_hardware_type') === 'DOORBELL') {
        getVideoDoorbellDevice(device.get('id')).then(() => {
          this.setState({
            vdbMotionNotifications: this.props.device
              .get('doorbellSettings')
              .getIn(['admin_notification_settings', 'enabled_motion_notify']),
            vdbLoiteringNotifications: this.props.device
              .get('doorbellSettings')
              .getIn(['admin_notification_settings', 'enabled_loitering_notify']),
            volume: this.props.device.get('doorbellSettings').getIn(['device_settings', 'volume']),
            api_domain: this.props.device.get('doorbellSettings').getIn(['device_settings', 'api_domain']),
            nginx_domain: this.props.device.get('doorbellSettings').getIn(['device_settings', 'nginx_domain']),
            night_vision: this.props.device.get('doorbellSettings').getIn(['device_settings', 'night_vision']),
          });
        });
      }
      if (device.get('last_status')) {
        if (device.get('dmp_hardware_type') === 'THERMOSTAT') {
          this.getDisplayTemperatureSetting(device);
          this.setState({
            currentDisplayTemp: this.getDisplayTemp(device.get('last_status')),
            disableTempControls: this.props.device.getIn(['last_status', 'mode']) === 'OFF',
          });
        }
      } else {
        switch (device.get('dmp_hardware_type')) {
          case 'LOCK':
            setDeviceStatus(device.get('id'), true);
            getLockStatus(device.get('id')).then(() => setDeviceStatus(device.get('id'), false));
            break;
          case 'LIGHT':
          case 'OUTLET':
            setDeviceStatus(device.get('id'), true);
            getLightStatus(device.get('id')).then(() => setDeviceStatus(device.get('id'), false));
            break;
          case 'THERMOSTAT':
            setDeviceStatus(device.get('id'), true);
            getThermostatLatest(device.get('id')).then(() => setDeviceStatus(device.get('id'), false));
            break;
          case 'DOORBELL':
            setDeviceStatus(device.get('id'), true);
            break;
          default:
            break;
        }
      }
    }
  }
  getMotionStatus = sensor => {
    if (this.state[`showMotion_${sensor.get('id')}`]) {
      return 'Motion';
    }
    return 'No Motion';
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.device.get('id') === prevProps.device.get('id') &&
      this.props.device.get('last_status') &&
      prevProps.device.get('last_status') !== this.props.device.get('last_status')
    ) {
      if (this.props.device.get('dmp_hardware_type') === 'THERMOSTAT') {
        if (this.props.device.get('last_status') !== prevProps.device.get('last_status')) {
          this.setState({
            currentDisplayTemp: this.getDisplayTemp(this.props.device.get('last_status')),
            disableTempControls: this.props.device.getIn(['last_status', 'mode']) === 'OFF',
          });
        }
      }
    }

    if (this.props.device.get('is_motion_sensor')) {
      const oldDeviceTime = prevProps.device.get('last_update_time');
      const lastUpdateTime = this.props.device.get('last_update_time');
      if (oldDeviceTime !== lastUpdateTime && lastUpdateTime && new Date() - lastUpdateTime < 5000) {
        clearTimeout(this[`timer_${this.props.device.get('id')}`]);
        this.setState({ [`showMotion_${this.props.device.get('id')}`]: true });
        this[`timer_${this.props.device.get('id')}`] = setTimeout(() => {
          this.setState({ [`showMotion_${this.props.device.get('id')}`]: false });
        }, 5000);
      }
    }

    if (this.props.expanded === false && prevProps.expanded === true) {
      this.setState({ editingSchedule: null, creatingSchedule: false });
    }

    if (
      this.props.device.get('dmp_hardware_type') === 'THERMOSTAT' &&
      !prevProps.setThermostatError &&
      this.props.setThermostatError &&
      this.props.device.get('last_status')
    ) {
      this.setState({
        currentDisplayTemp: this.getDisplayTemp(this.props.device.get('last_status')),
        disableTempControls: false,
      });
    }
  }

  renderLock() {
    const { device, isLoading, updateLock } = this.props;
    const lockStatus = device.getIn(['last_status', 'status']);
    const batteryPercent = device.getIn(['last_status', 'battery_status']) || 'NA';
    return (
      <div className="device">
        <div className="device--details-wrapper">
          <div className="device__name">
            <div className="device__name--icon-type">
              <Icon icon="Door" />
            </div>
            <div className="device__name--pretty-name">{device.get('name')}</div>
            {!device.get('last_status') ? (
              <div className="device__no-signal">
                <Icon icon="NoSignal" />
              </div>
            ) : null}
          </div>
          <div className="device__uid">
            <div className="device__detail--label">UID</div>
            <div className="device__detail--value">{device.get('id')}</div>
          </div>
          {!device.get('hide_battery_status') ? (
            <div className="device__battery">
              {isLoading ? <Loading /> : <BatteryIndicator percentage={batteryPercent} />}
            </div>
          ) : null}
          <div className="device__action-display">
            <button
              className="button"
              disabled={this.props.isLoading}
              onClick={() => {
                this.props.setDeviceStatus(this.props.device.get('id'), true);
                updateLock(device.get('id'), { status: lockStatus === 'SECURED' ? 'UNSECURED' : 'SECURED' }).then(() =>
                  this.props.setDeviceStatus(this.props.device.get('id'), false)
                );
              }}
            >
              {lockStatus === 'SECURED' ? 'Unlock' : 'Lock'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderBarrier() {
    const { device, isLoading, updateBarrier } = this.props;
    const barrierStatus = device.getIn(['last_status', 'status']);
    return (
      <div className="device">
        <div className="device--details-wrapper">
          <div className="device__name">
            <div className="device__name--icon-type">
              <Icon icon="GarageClosed" />
            </div>
            <div className="device__name--pretty-name">{device.get('name')}</div>
            {!device.get('last_status') ? (
              <div className="device__no-signal">
                <Icon icon="NoSignal" />
              </div>
            ) : null}
          </div>
          <div className="device__uid">
            <div className="device__detail--label">UID</div>
            <div className="device__detail--value">{device.get('id')}</div>
          </div>
          <div className="device__battery">{isLoading ? <Loading /> : null}</div>
          <div className="device__action-display">
            <button
              className="button"
              disabled={this.props.isLoading}
              onClick={() => {
                this.props.setDeviceStatus(this.props.device.get('id'), true);
                updateBarrier(device.get('id'), { status: barrierStatus === 'CLOSED' ? 'OPEN' : 'CLOSED' }).then(() =>
                  this.props.setDeviceStatus(this.props.device.get('id'), false)
                );
              }}
            >
              {barrierStatus === 'CLOSED' ? 'Open' : 'Close'}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderZone() {
    const { device, isLoading } = this.props;
    let sensorStatus;
    switch (device.getIn(['last_status', 'status'])) {
      case 'N':
        sensorStatus = 'Closed';
        break;
      case 'O':
      case 'S':
        sensorStatus = 'Open';
        break;
      case 'M':
        sensorStatus = 'Missing';
        break;
      case null:
        sensorStatus = 'Disconnected';
        break;
      default:
        sensorStatus = 'Unknown';
        break;
    }

    const batteryPercent = device.get('battery_level') || 'NA';

    return (
      <div className="device">
        <div className="device--details-wrapper">
          <div className="device__name">
            <div className="device__name--icon-type">
              <Icon icon="Robot" />
            </div>
            <div className="device__name--pretty-name">{device.get('name')}</div>
            {!device.get('last_status') ? (
              <div className="device__no-signal">
                <Icon icon="NoSignal" />
              </div>
            ) : null}
          </div>
          <div className="device__uid">
            <div className="device__detail--label">UID</div>
            <div className="device__detail--value">{device.get('id')}</div>
          </div>
          {!device.get('hide_battery_status') ? (
            <div className="device__battery">
              {isLoading ? <Loading /> : <BatteryIndicator percentage={batteryPercent} />}
            </div>
          ) : null}
          {device.get('is_motion_sensor') ? (
            <div className="device__action-display">Status: {this.getMotionStatus(device)}</div>
          ) : (
            <div className="device__action-display">Status: {sensorStatus}</div>
          )}
        </div>
      </div>
    );
  }

  renderVideoDoorbell() {
    const { device, editVideoDoorbellDeviceSettings, isVideoDoorbellsV2Enabled } = this.props;
    return (
      <div className="device">
        <div className="device--details-wrapper">
          <div className="device__name">
            <div className="device__name--icon-type">
              <Icon icon={'Doorbell'} />
            </div>
            <div className="device__name--pretty-name">{device.get('name')}</div>
            {!device.get('doorbellSettings') ? (
              <div className="device__no-signal">
                <Icon icon="NoSignal" />
              </div>
            ) : null}
          </div>
          <div className="device__uid">
            <div className="device__detail--label">UID</div>
            <div className="device__detail--value">{device.get('id')}</div>
          </div>
          <div className="device__action-display">
            <button
              type="button"
              onClick={() => {
                const { volume, api_domain, nginx_domain, night_vision } = this.state;
                editVideoDoorbellDeviceSettings(device.get('id'), {
                  doorbell_id: device.get('id'),
                  device_settings: {
                    volume,
                    api_domain,
                    reset_token: true,
                    nginx_domain,
                    night_vision,
                  },
                });
              }}
              disabled={false}
              className="button"
            >
              Reset Device
            </button>
          </div>
          <div className="schedule-toggle" onClick={this.props.onToggleExpanded}>
            {this.props.expanded ? <Icon icon="CaretUp" /> : <Icon icon="CaretDown" />}
          </div>
        </div>
        {this.props.expanded && (
          <div>
            <hr className="section-hr" />
            <div className="schedule-row__wrapper doorbell-toggle-padding">
              <div>
                <div className="doorbell-toggle-heading">Motion Detection</div>
                <div className="doorbell-toggle-text">
                  The resident will have the option to enable motion detection notifications.
                </div>
              </div>
              <div>
                <Switch
                  id={'motion_notifications_toggle'}
                  label={''}
                  input={{
                    value: this.state.vdbMotionNotifications,
                    name: 'admin_enabled_motion_notify',
                    onChange: value => {
                      this.setState({ vdbMotionNotifications: value });
                      editVideoDoorbellDeviceSettings(device.get('id'), {
                        doorbell_id: device.get('id'),
                        admin_notification_settings: {
                          enabled_loitering_notify: this.state.vdbLoiteringNotifications,
                          enabled_motion_notify: value,
                        },
                      });
                    },
                  }}
                  disabled={false}
                />
              </div>
            </div>
            {isVideoDoorbellsV2Enabled && (
              <div>
                <hr className="section-hr" />
                <div className="schedule-row__wrapper doorbell-toggle-padding">
                  <div>
                    <div className="doorbell-toggle-heading">Loitering Detection</div>
                    <div className="doorbell-toggle-text">
                      The resident will have the option to enable loitering detection notifications.
                    </div>
                  </div>
                  <div>
                    <Switch
                      id={'loitering_notifications_toggle'}
                      label={''}
                      input={{
                        value: this.state.vdbLoiteringNotifications,
                        name: 'admin_enabled_loitering_notifications',
                        onChange: value => {
                          this.setState({ vdbLoiteringNotifications: value });
                          editVideoDoorbellDeviceSettings(device.get('id'), {
                            doorbell_id: device.get('id'),
                            admin_notification_settings: {
                              enabled_loitering_notify: value,
                              enabled_motion_notify: this.state.vdbMotionNotifications,
                            },
                          });
                        },
                      }}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  renderLight() {
    const { device, updateLight, canSchedule } = this.props;
    const lightLevel = device.getIn(['last_status', 'level']);
    return (
      <div className="device">
        <div className="device--details-wrapper">
          <div className="device__name">
            <div className="device__name--icon-type">
              <Icon icon={device.get('dmp_hardware_type') === 'OUTLET' ? 'Outlet' : 'Light'} />
            </div>
            <div className="device__name--pretty-name">{device.get('name')}</div>
            {!device.get('last_status') ? (
              <div className="device__no-signal">
                <Icon icon="NoSignal" />
              </div>
            ) : null}
          </div>
          <div className="device__uid">
            <div className="device__detail--label">UID</div>
            <div className="device__detail--value">{device.get('id')}</div>
          </div>
          <div className="device__action-display">
            <button
              className="button"
              disabled={this.props.isLoading}
              onClick={() => {
                this.props.setDeviceStatus(this.props.device.get('id'), true);
                updateLight(device.get('id'), { level: lightLevel > 0 ? 0 : 99 }).then(() => {
                  this.props.setDeviceStatus(this.props.device.get('id'), false);
                });
              }}
            >
              {lightLevel > 0 ? 'Turn Light Off' : 'Turn Light On'}
            </button>
          </div>
          {canSchedule ? (
            <div className="schedule-toggle" onClick={this.props.onToggleExpanded}>
              {this.props.expanded ? <Icon icon="CaretUp" /> : <Icon icon="CaretDown" />}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderThermostat() {
    const { device, isLoading, canSchedule } = this.props;

    const batteryPercent = device.getIn(['last_status', 'battery_status']) || 'NA';
    return (
      <div className="device">
        <div className="device--details-wrapper">
          <div className="device__name">
            <div className="device__name--icon-type">
              <Icon icon="Thermostat" />
            </div>
            <div className="device__name--pretty-name">{device.get('name')}</div>
            {!device.get('last_status') ? (
              <div className="device__no-signal">
                <Icon icon="NoSignal" />
              </div>
            ) : null}
          </div>
          <div className="device__uid">
            <div className="device__detail--label">UID</div>
            <div className="device__detail--value">{device.get('id')}</div>
          </div>
          {!device.get('hide_battery_status') ? (
            <div className="device__battery">
              {isLoading ? <Loading /> : <BatteryIndicator percentage={batteryPercent} />}
            </div>
          ) : null}
          <div className="device__action-display">
            {device.get('last_status') && (
              <div className="device__thermostat-controls">
                {device.getIn(['last_status', 'current_temperature']) ? (
                  <div className="device__thermostat--current-temp">
                    <div className="device__detail--label">Current</div>
                    <div className="thermostat-controls--temp-digits">
                      {parseInt(device.getIn(['last_status', 'current_temperature']), 10)}
                    </div>
                  </div>
                ) : null}
                <div className="device__thermostat-controls--temp-set">
                  <div className="device__detail--label">Set</div>
                  <div className="device__thermostat-controls--temp">
                    <button
                      className="button"
                      disabled={
                        this.props.isLoading ||
                        this.state.currentDisplayTemp <= this.getTempRangeForMode()[0] ||
                        this.state.disableTempControls
                      }
                      onClick={() => {
                        this.decrementTempSetting(device.get('last_status'));
                      }}
                    >
                      <div className="button--children">
                        <Icon icon="Minus" />
                      </div>
                    </button>
                    <div className="thermostat-controls--temp-digits">{this.state.currentDisplayTemp}</div>
                    <button
                      className="button"
                      disabled={
                        this.props.isLoading ||
                        this.state.currentDisplayTemp >= this.getTempRangeForMode()[1] ||
                        this.state.disableTempControls
                      }
                      onClick={() => {
                        this.incrementTempSetting(device.get('last_status'));
                      }}
                    >
                      <div className="button--children">
                        <Icon icon="Plus" />
                      </div>
                    </button>
                  </div>
                </div>
                <div className="device__thermostat-controls--mode">
                  <div className="input-scaffold">
                    <div className="input-label">Mode</div>
                    <select
                      disabled={this.props.isLoading}
                      value={device.getIn(['last_status', 'mode'])}
                      onChange={e => {
                        this.changeThermostatMode(device.get('last_status'), e.target.value);
                      }}
                    >
                      <option value="AUTO">Auto</option>
                      <option value="HEAT">Heat</option>
                      <option value="COOL">Cool</option>
                      <option value="OFF">Off</option>
                    </select>
                  </div>
                </div>
                <div className="device__thermostat-controls--fan">
                  <div className="input-scaffold">
                    <div className="input-label">Fan</div>
                    <select
                      disabled={this.props.isLoading}
                      value={device.getIn(['last_status', 'fan_mode'])}
                      onChange={e => {
                        this.changeThermostatFanMode(e.target.value);
                      }}
                    >
                      <option value="AUTO">Auto</option>
                      <option value="ON">On</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          {canSchedule ? (
            <div className="schedule-toggle" onClick={this.props.onToggleExpanded}>
              {this.props.expanded ? <Icon icon="CaretUp" /> : <Icon icon="CaretDown" />}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderDeviceSpecificFields(schedule) {
    switch (schedule.getIn(['device', 'dmp_hardware_type'])) {
      case 'LIGHT':
        return (
          <DetailBit label="Brightness" value={`${getDisplayedBrightness(schedule.getIn(['payload', 'level']))}%`} />
        );
      case 'THERMOSTAT':
        return [
          <DetailBit
            key="thermDetail1"
            label="Temperature"
            value={`${getDisplayTempForStatus(schedule.get('payload'))}°`}
          />,
          <DetailBit key="thermDetail2" label="Mode" value={schedule.getIn(['payload', 'mode'])} />,
          <DetailBit key="thermDetail3" label="Fan" value={schedule.getIn(['payload', 'fan_mode'])} />,
        ];
      default:
        return null;
    }
  }

  renderScheduleButtons(schedule) {
    if (this.state.removingSchedule === schedule.get('id')) {
      return (
        <Delete
          message={
            <span>
              Are you sure you want to <strong className="strong">remove this schedule</strong>?
            </span>
          }
          onCancel={() => this.setState({ removingSchedule: null })}
          deleteFunc={() => this.deleteSchedule(schedule.get('id'))}
        />
      );
    }
    return (
      <div className="schedule-buttons">
        <button
          className="button button--secondary--destructive"
          onClick={() => this.setState({ removingSchedule: schedule.get('id') })}
        >
          Remove
        </button>
        <button
          className="button"
          onClick={() => this.setState({ editingSchedule: schedule.get('id'), creatingSchedule: false })}
        >
          Edit
        </button>
      </div>
    );
  }

  renderSchedule = schedule => {
    if (this.state.editingSchedule === schedule.get('id')) {
      return (
        <ScheduleDeviceRow
          key={schedule.get('id')}
          deviceType={this.props.device.get('dmp_hardware_type')}
          schedule={schedule}
          onCancel={() => this.setState({ editingSchedule: null })}
          onSuccess={() => this.setState({ editingSchedule: null })}
          onSubmit={this.submitSchedule}
          editing={this.state.editingSchedule}
        />
      );
    }
    return (
      <div className="schedule-row__wrapper" key={schedule.get('id')}>
        <div className="schedule-row">
          <DetailBit
            label="Repeats"
            value={schedule.get('days').size === 7 ? 'Every Day' : schedule.get('days').join(' ')}
          />
          <DetailBit label="Set Time" value={moment(schedule.get('time'), 'HH:mm:ss').format('hh:mm A')} />
          {this.renderDeviceSpecificFields(schedule)}
        </div>
        {this.renderScheduleButtons(schedule)}
      </div>
    );
  };

  renderScheduleControls() {
    const { canSchedule, device, schedules } = this.props;
    if (!canSchedule || !schedules || !this.props.expanded) {
      return null;
    }

    if (this.state.creatingSchedule) {
      return (
        <ScheduleDeviceRow
          deviceType={device.get('dmp_hardware_type')}
          onCancel={() => this.setState({ creatingSchedule: null })}
          onSuccess={() => this.setState({ creatingSchedule: null })}
          onSubmit={this.submitSchedule}
        />
      );
    }

    return (
      <div className="device-schedule-form__wrapper">
        <div className="device-schedule-form__body">
          {schedules.size === 0 ? (
            <div className="schedule-form--empty">
              You haven't added any schedules yet. Click Add Schedule below to add a schedule.
            </div>
          ) : null}
          {schedules.map(this.renderSchedule)}
        </div>
        <div className="device-schedule-form__footer">
          <div
            className="device-schedule-form--add-schedule"
            onClick={() => this.setState({ creatingSchedule: true, editingSchedule: null })}
          >
            + Add Schedule
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { device, disableControls, isLoading } = this.props;
    let deviceTypeIcon = '';

    switch (device.get('dmp_hardware_type')) {
      case 'LOCK':
        deviceTypeIcon = 'Door';
        break;
      case 'THERMOSTAT':
        deviceTypeIcon = 'Thermostat';
        break;
      case 'LIGHT':
        deviceTypeIcon = 'Light';
        break;
      case 'BARRIER_OPERATOR':
        deviceTypeIcon = 'GarageClosed';
        break;
      case 'OUTLET':
        deviceTypeIcon = 'Outlet';
        break;
      case 'DOORBELL':
        deviceTypeIcon = 'Doorbell';
        break;
      default:
        deviceTypeIcon = 'Robot';
    }

    if (!disableControls) {
      let deviceControls;
      switch (device.get('dmp_hardware_type')) {
        case 'LOCK':
          deviceControls = this.renderLock();
          break;
        case 'BARRIER_OPERATOR':
          deviceControls = this.renderBarrier();
          break;
        case 'LIGHT':
        case 'OUTLET':
          deviceControls = this.renderLight();
          break;
        case 'THERMOSTAT':
          deviceControls = this.renderThermostat();
          break;
        case 'SENSOR':
          deviceControls = this.renderZone();
          break;
        case 'DOORBELL':
          deviceControls = this.renderVideoDoorbell();
          break;
        default:
          return null;
      }
      return (
        <div className="device-control-row__wrapper">
          {deviceControls}
          {this.renderScheduleControls()}
        </div>
      );
    } else {
      const batteryPercent = device.getIn(['last_status', 'battery_status']) || null;
      return (
        <div className="device device__no-actions">
          <div className="device--details-wrapper">
            <div className="device__name">
              <div className="device__name--icon-type">
                <Icon icon={deviceTypeIcon} />
              </div>
              <div className="device__name--pretty-name">{device.get('name')}</div>
              {!device.get('last_status') ? (
                <div className="device__no-signal">
                  <Icon icon="NoSignal" />
                </div>
              ) : null}
            </div>
            <div className="device__uid">
              <div className="device__detail--label">UID</div>
              <div className="device__detail--value">{device.get('id')}</div>
            </div>
            {device.get('dmp_hardware_type') !== 'LIGHT' && !device.get('hide_battery_status') ? (
              <div className="device__battery">
                {isLoading ? <Loading /> : <BatteryIndicator percentage={batteryPercent} />}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  }
}

export default connect((state, ownProps) => ({
  setThermostatError: state.property.unit.setThermostatError,
}))(DeviceControlRow);
