import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import NotFound from '../../common/NotFound';
import AddUnit from './views/AddUnit';
import UnitsList from './views/UnitsList';
import SGTours from './views/SGTours';
import ImportUnits from './views/ImportUnits';
import Unit from './views/Unit';
import ResidentList from './views/ResidentList';

class Units extends Component {
  render() {
    const { match, userPermissionList } = this.props;
    const userPermission = {
      // Units & Residents
      UnitList: userPermissionList.includes('VIEW_UNITS_LIST_PAGE'),
      ResidentList: userPermissionList.includes('VIEW_RESIDENT_LIST'),
      SelfGuidedTour: userPermissionList.includes('SHOW_SELF-GUIDED_TOURS'),
    };
    const urlStrict = match.url + '/' + (userPermission.UnitList ? 'unit-list' : 'resident-list');

    return (
      <div>
        <Switch>
          <Redirect strict exact from={match.path} to={urlStrict} />
          <Route exact path={`${match.path}/unit-list`} component={UnitsList} />
          <Route exact path={`${match.path}/add`} component={AddUnit} />
          <Route exact path={`${match.path}/import`} component={ImportUnits} />
          <Route exact path={`${match.path}/resident-list`} component={ResidentList} />
          <Route path={`${match.path}/tours`} component={SGTours} />
          <Route path={`${match.path}/:unitId`} component={Unit} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

Units.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Units;
