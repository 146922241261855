import React from 'react';
import { any, string, bool } from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';

export const UnitTooltip = ({ content, children, placement, disabled, offset, theme }) => (
  <Tippy
    allowHTML
    offset={offset}
    theme={theme}
    placement={placement}
    content={<span className="unit-tooltip">{content}</span>}
    disabled={disabled}
  >
    {children}
  </Tippy>
);

UnitTooltip.propTypes = {
  content: any.isRequired,
  children: any.isRequired,
  placement: string.isRequired,
  disabled: bool,
  offset: any.isRequired,
};
