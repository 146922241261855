import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Formik } from 'formik';
import InputScaffold from '../../../../../../common/forms/InputScaffold';
import InputGeneral from '../../../../../../common/forms/InputGeneral';
import Loading from '../../../../../../common/Loading';
import SubHeader from '../../../../../../common/SubHeader/';
import Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(64, 'Name is too long')
    .required('Name is required'),
  group_id: Yup.object().required('Group is required'),
  sensors: Yup.array().required('At least one sensor is required'),
});

const ALL_RESIDENTS_LABEL = 'All Residents';

class EditMailHub extends Component {
  static propTypes = {
    communityDevices: PropTypes.object.isRequired,
    communityDevicesLoading: PropTypes.bool.isRequired,
    groupList: PropTypes.object.isRequired,
    getCommunityDevices: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
    editGroups: PropTypes.func.isRequired,
    propertyId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.getGroups();
    this.props.getCommunityDevices();
    this.props.getMailHubs();
  }

  isEdit() {
    return !!this.props.match.params.mailHubId;
  }

  returnToMailHubList = () => {
    this.props.history.push(`/properties/${this.props.propertyId}/property-details/mail-hubs`);
  };

  submit = values => {
    const payload = {
      name: values.name,
      group_id: values.group_id.value ?? null,
      sensors: values.sensors.map(sensor => sensor.value),
    };

    const actionMethod = this.isEdit() ? this.props.editMailHub : this.props.createMailHub;
    actionMethod(payload).then(action => {
      if (action.response.status === 200) {
        this.returnToMailHubList();
      }
    });
  };

  getAvailableCommunitySensors() {
    return this.props.communityDevices.filter(device => device.get('dmp_hardware_type') === 'SENSOR');
  }

  getSensorOptions() {
    return this.getAvailableCommunitySensors()
      .filter(sensor => !sensor.get('mailHub'))
      .map(sensor => ({
        label: sensor.get('name'),
        value: sensor.get('id'),
      }))
      .toJS();
  }

  getGroupOptions() {
    return [
      {
        label: ALL_RESIDENTS_LABEL,
        value: null,
      },
    ].concat(
      this.props.groupList
        .filter(group => {
          // Filter out groups already assigned to a mail hub
          const mailHubAssignedToGroup = this.props.mailHubs.find(
            mailHub => mailHub.get('group_id') === group.get('id')
          );
          if (
            mailHubAssignedToGroup &&
            (!this.isEdit() || this.getMailHubBeingEdited().get('group_id') !== group.get('id'))
          ) {
            return false;
          }
          return true;
        })
        .map(group => ({
          label: group.get('name'),
          value: group.get('id'),
        }))
        .toJS()
    );
  }

  getMailHubBeingEdited() {
    if (!this.isEdit()) {
      throw new Error('Called getMailHubBeingEdited but mail hub is not being edited');
    }

    return this.props.mailHubs.find(mailHub => mailHub.get('id') === this.props.match.params.mailHubId);
  }

  getInitialValues() {
    const { communityDevices } = this.props;
    if (!this.isEdit() || this.props.communityDevicesLoading) {
      return { name: '', group_id: undefined, sensors: [] };
    }

    function getCommunityDeviceName(deviceId) {
      const device = communityDevices.find(device => device.get('id') === deviceId);
      return device?.get('name') ?? '';
    }
    const mailHub = this.getMailHubBeingEdited();
    const group = this.props.groupList.find(group => group.get('id') === mailHub.get('group_id'));

    return {
      name: mailHub.get('name'),
      group_id: {
        label: group?.get('name') ?? ALL_RESIDENTS_LABEL,
        value: group?.get('id') ?? null,
      },
      sensors: mailHub
        .get('devices')
        .map(sensor => ({ label: getCommunityDeviceName(sensor.get('device_id')), value: sensor.get('device_id') }))
        .toJS(),
    };
  }

  render() {
    if (this.props.communityDevicesLoading || !this.props.mailHubsLoaded) {
      return <Loading />;
    }

    if (this.isEdit() && !this.getMailHubBeingEdited()) {
      return (
        <div className="container">
          <h4 className="h4">Not Found</h4>
        </div>
      );
    }

    const groupOptions = this.getGroupOptions();
    const sensorOptions = this.getSensorOptions();

    return (
      <div className="container">
        <SubHeader title="Mail Hubs" />
        <Formik
          initialValues={this.getInitialValues()}
          onSubmit={this.submit}
          validationSchema={validationSchema}
          render={({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setStatus,
            status,
            touched,
          }) => {
            return (
              <form className="mail-hub__form" onSubmit={handleSubmit}>
                <div className="paper radius-top-left radius-top-right">
                  <h4 className="h4">{this.isEdit() ? 'Edit' : 'Add'} Mail Hub</h4>
                  <InputScaffold
                    label="Mail Hub Name"
                    validation={touched.name && errors.name}
                    helperText="Can only contain letters and numbers. Max length is 64 characters"
                    required
                  >
                    <InputGeneral
                      regex={/^[a-zA-Z0-9\s]*$/}
                      name="name"
                      maxLength={64}
                      onChange={handleChange}
                      value={values.name}
                    />
                  </InputScaffold>
                  <div className="react-select-scaffold">
                    <div className="label">
                      Group<span className="input-required">*</span>
                    </div>
                    <div className="input-helper-text">Please select one group to assign it to the Mail Hub</div>
                    <Select
                      options={groupOptions}
                      placeholder={groupOptions.length ? 'Select a group' : 'No groups available'}
                      onChange={value => setFieldValue('group_id', value)}
                      value={values.group_id}
                    />
                    {touched.group_id && errors.group_id && (
                      <div className="input-validation">{touched.group_id && errors.group_id}</div>
                    )}
                  </div>
                  <div className="react-select-scaffold">
                    <div className="label">
                      Mail Sensors<span className="input-required">*</span>
                    </div>
                    <div className="input-helper-text">
                      Please select at least one mail sensor to assign to the Mail Hub
                    </div>
                    <Select
                      options={sensorOptions}
                      placeholder={sensorOptions.length ? 'Select a sensor' : 'No sensors available'}
                      onChange={value => setFieldValue(`sensors`, value)}
                      value={values.sensors}
                      multi
                    />
                    {touched.sensors && errors.sensors && (
                      <div className="input-validation">{touched.sensors && errors.sensors}</div>
                    )}
                  </div>
                </div>
                <div className="container paper__footer radius-bottom-left radius-bottom-right">
                  <button type="submit" className="button" disabled={isSubmitting}>
                    {this.isEdit() ? 'Update' : 'Create'} Mail Hub
                  </button>
                  <button
                    type="button"
                    className="button button--secondary"
                    disabled={isSubmitting}
                    onClick={this.returnToMailHubList}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            );
          }}
        />
      </div>
    );
  }
}

export default EditMailHub;
