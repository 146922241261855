import { connect } from 'react-redux';

import EditAccessPoint from './EditAccessPoint';
import { editAccessPoint, getDoors, getAccessPoints } from '../../../../redux/actions';

function mapStateToProps(state, ownProps) {
  const matchingAccessPoint = state.property.accessPoints.find(
    accessPoint => accessPoint.get('id') === ownProps.match.params.accessPointId
  );

  return {
    property: state.property.activeProperty,
    accessControlDoors: state.property.accessControlDoors.filter(
      door =>
        (matchingAccessPoint && matchingAccessPoint.get('door_id') === door.get('id')) || !door.get('access_point')
    ),
    accessControlDoorsLoaded: state.property.accessControlDoorsLoaded,
    matchingAccessPoint,
    user: state.user,
    hasMasterPanel: !!state.property.activeProperty.get('clubhouse_panel_id'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    editAccessPoint: payload =>
      dispatch(editAccessPoint(ownProps.match.params.propertyId, ownProps.match.params.accessPointId, payload)),
    getDoors: () => dispatch(getDoors(ownProps.match.params.propertyId)),
    getAccessPoints: () => dispatch(getAccessPoints(ownProps.match.params.propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccessPoint);
