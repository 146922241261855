import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Delete from '../../common/Delete';
import OfferKebab from './OfferKebab';

class OfferItem extends Component {
  state = {
    showRemove: false,
    showingMenu: false,
  };

  toggleShowingMenu = () => this.setState(state => ({ showingMenu: !state.showingMenu }));

  toggleRemove = () => {
    return this.setState(state => ({ showRemove: !state.showRemove, showingMenu: false }));
  };

  removeOfferFunc = () => {
    const { removeOffer, offer } = this.props;
    return removeOffer(offer.get('id'));
  };

  render() {
    const { offer, userPermission } = this.props;
    const { showRemove, showingMenu } = this.state;
    const active = moment(offer.get('end_date')).diff(moment(), 'days') >= 0 ? 'Active' : 'Inactive';
    const contactName = `${offer.get('first_name') || '-'} ${offer.get('last_name') || '-'}`;

    return (
      <tr key={offer.get('id')} className="fake-table-row">
        <td>
          {userPermission.editOffer ? (
            <Link className="offer-item__company-name" to={`/offers/${offer.get('id')}`}>
              {offer.get('company_name')}
            </Link>
          ) : (
            <div>{offer.get('company_name')}</div>
          )}
          <div className="offer-item__company-address">{offer.get('address')}</div>
        </td>
        <td>
          <div className="offer-item__contact-name" title={contactName || '--'}>
            {contactName || '--'}
          </div>
          <div className="offer-item__contact-email" title={offer.get('email')}>
            {offer.get('email')}
          </div>
        </td>
        <td>{moment.utc(offer.get('start_date')).format('MM/DD/YYYY')}</td>
        <td>{moment.utc(offer.get('end_date')).format('MM/DD/YYYY')}</td>
        <td className={`offer-item__status status-${active}`}>{active}</td>
        <td className="td-kebab">
          {showRemove && (
            <Delete
              message={
                <span>
                  Are you sure you want to <strong className="strong">remove this offer</strong>?
                </span>
              }
              onCancel={this.toggleRemove}
              deleteFunc={this.removeOfferFunc}
            />
          )}
          {userPermission.deleteOffer && (
            <OfferKebab
              toggleShowingMenu={this.toggleShowingMenu}
              showingMenu={showingMenu}
              toggleRemove={this.toggleRemove}
            />
          )}
        </td>
      </tr>
    );
  }
}

OfferItem.propTypes = {
  offer: PropTypes.object.isRequired,
};

export default OfferItem;
