import React from 'react';
import ArrowLeft from '../../../../../../../../../common/icons/ArrowLeft';
import { useHistory } from 'react-router-dom';

export const SGBtnBack = ({ btnTitle, handler }) => {
  const history = useHistory();

  return (
    <button onClick={handler || history.goBack} className="back-arrow back-arrow-link m-b20">
      <span className="icon">
        <ArrowLeft icon="ArrowLeft" />
      </span>
      {btnTitle}
    </button>
  );
};
