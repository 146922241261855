import { connect } from 'react-redux';
import { getTelephoneEntryDevices, getProperties } from '../../../../../../redux/actions';
import EditTelephoneEntryDevice from './EditTelephoneEntryDevice';
import { selectActiveTelephoneEntryDevice, selectPropertyOptions } from '../../../../../../redux/selectors';
import { editTelephoneEntryDevice, getTelephoneEntryDeviceTokens } from '../../../../../../redux/actions';

function mapStateToProps(state, props) {
  return {
    telephoneEntryDevice: selectActiveTelephoneEntryDevice(state, props),
    telephoneEntryDevicesLoaded: state.telephoneEntryDevicesLoaded,
    propertyOptions: selectPropertyOptions(state, props),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { telephoneEntryDeviceId } = ownProps.match.params;

  return {
    getTelephoneEntryDevices: () => dispatch(getTelephoneEntryDevices()),
    editTelephoneEntryDevice: payload => dispatch(editTelephoneEntryDevice(telephoneEntryDeviceId, payload)),
    getProperties: () => dispatch(getProperties()),
    getTelephoneEntryDeviceTokens: () => dispatch(getTelephoneEntryDeviceTokens(telephoneEntryDeviceId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTelephoneEntryDevice);
