import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import Yup from 'yup';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

import InputScaffold from '../../../../../common/forms/InputScaffold';

class ScheduleForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    submitFunc: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  handleSubmit = async (values, { setSubmitting, setStatus }) => {
    const { submitFunc, onSuccess } = this.props;

    setStatus(null);

    const submitValues = { ...values };

    submitValues.begins_at =
      submitValues.begins_at === undefined || !submitValues.is_temporary ? null : submitValues.begins_at.toISOString();
    submitValues.ends_at =
      submitValues.ends_at === undefined || !submitValues.is_temporary ? null : submitValues.ends_at.toISOString();

    for (const key of [
      'sun_open',
      'sun_close',
      'mon_open',
      'mon_close',
      'tue_open',
      'tue_close',
      'wed_open',
      'wed_close',
      'thu_open',
      'thu_close',
      'fri_open',
      'fri_close',
      'sat_open',
      'sat_close',
    ]) {
      submitValues[key] = submitValues[key] ? submitValues[key].format('HH:mm') : null;
    }

    const action = await submitFunc(submitValues);
    setSubmitting(false);

    if (action.response.ok) {
      onSuccess();
    } else {
      setStatus((action.json && action.json.message) || 'Unknown error');
    }
  };

  render() {
    const { initialValues, onCancel } = this.props;

    return (
      <Formik
        initialValues={
          initialValues || {
            name: '',
            begins_at: null,
            ends_at: null,
            is_temporary: false,
            sun_open: null,
            sun_close: null,
            mon_open: null,
            mon_close: null,
            tue_open: null,
            tue_close: null,
            wed_open: null,
            wed_close: null,
            thu_open: null,
            thu_close: null,
            fri_open: null,
            fri_close: null,
            sat_open: null,
            sat_close: null,
          }
        }
        validationSchema={Yup.object().shape({
          is_temporary: Yup.boolean(),
          name: Yup.string()
            .trim()
            .min(3)
            .max(32)
            .label('Name')
            .required('Please enter a Schedule Name'),
          begins_at: Yup.date().when('is_temporary', {
            is: true,
            then: Yup.date()
              .test('is-in-future', 'Date must be today or later', value => {
                return moment(value).isSameOrAfter(moment().startOf('day'));
              })
              .nullable(false)
              .typeError('Please enter a valid Start Date')
              .required('Please enter a Start Date'),
            otherwise: Yup.date()
              .nullable()
              .notRequired(),
          }),
          ends_at: Yup.date().when('is_temporary', {
            is: true,
            then: Yup.date()
              .nullable(false)
              .min(Yup.ref('begins_at'), 'End Date cannot be before Start Date')
              .typeError('Please enter a valid End Date')
              .required('Please enter an End Date'),
            otherwise: Yup.date()
              .nullable()
              .notRequired(),
          }),
          sun_open: Yup.date().nullable(true),
          sun_close: Yup.date().when('sun_open', {
            is: time => !!time,
            then: Yup.date()
              .nullable(true)
              .required('Please enter an End time')
              .test('sun_open', 'End time cannot be before Start time', function(value) {
                return moment(this.parent.sun_open, 'h:mm a').isBefore(moment(value, 'h:mm a'));
              }),
            otherwise: Yup.date()
              .nullable(true)
              .oneOf([null], 'Please select a Start time'),
          }),
          mon_open: Yup.date().nullable(true),
          mon_close: Yup.date().when('mon_open', {
            is: time => !!time,
            then: Yup.date()
              .nullable(true)
              .required('Please enter an End time')
              .test('mon_open', 'End time cannot be before Start time', function(value) {
                return moment(this.parent.mon_open, 'h:mm a').isBefore(moment(value, 'h:mm a'));
              }),
            otherwise: Yup.date()
              .nullable(true)
              .oneOf([null], 'Please select a Start time'),
          }),
          tue_open: Yup.date().nullable(true),
          tue_close: Yup.date().when('tue_open', {
            is: time => !!time,
            then: Yup.date()
              .nullable(true)
              .required('Please enter an End time')
              .test('tue_open', 'End time cannot be before Start time', function(value) {
                return moment(this.parent.tue_open, 'h:mm a').isBefore(moment(value, 'h:mm a'));
              }),
            otherwise: Yup.date()
              .nullable(true)
              .oneOf([null], 'Please select a Start time'),
          }),
          wed_open: Yup.date().nullable(true),
          wed_close: Yup.date().when('wed_open', {
            is: time => !!time,
            then: Yup.date()
              .nullable(true)
              .required('Please enter an End time')
              .test('wed_open', 'End time cannot be before Start time', function(value) {
                return moment(this.parent.wed_open, 'h:mm a').isBefore(moment(value, 'h:mm a'));
              }),
            otherwise: Yup.date()
              .nullable(true)
              .oneOf([null], 'Please select a Start time'),
          }),
          thu_open: Yup.date().nullable(true),
          thu_close: Yup.date().when('thu_open', {
            is: time => !!time,
            then: Yup.date()
              .nullable(true)
              .required('Please enter an End time')
              .test('thu_open', 'End time cannot be before Start time', function(value) {
                return moment(this.parent.thu_open, 'h:mm a').isBefore(moment(value, 'h:mm a'));
              }),
            otherwise: Yup.date()
              .nullable(true)
              .oneOf([null], 'Please select a Start time'),
          }),
          fri_open: Yup.date().nullable(true),
          fri_close: Yup.date().when('fri_open', {
            is: time => !!time,
            then: Yup.date()
              .nullable(true)
              .required('Please enter an End time')
              .test('fri_open', 'End time cannot be before Start time', function(value) {
                return moment(this.parent.fri_open, 'h:mm a').isBefore(moment(value, 'h:mm a'));
              }),
            otherwise: Yup.date()
              .nullable(true)
              .oneOf([null], 'Please select a Start time'),
          }),
          sat_open: Yup.date().nullable(true),
          sat_close: Yup.date().when('sat_open', {
            is: time => !!time,
            then: Yup.date()
              .nullable(true)
              .required('Please enter an End time')
              .test('sat_open', 'End time cannot be before Start time', function(value) {
                return moment(this.parent.sat_open, 'h:mm a').isBefore(moment(value, 'h:mm a'));
              }),
            otherwise: Yup.date()
              .nullable(true)
              .oneOf([null], 'Please select a Start time'),
          }),
        })}
        onSubmit={this.handleSubmit}
        render={({ values, errors, status, handleChange, handleSubmit, touched, isSubmitting, setFieldValue }) => {
          const isFormComplete = Boolean(
            ((values.name && values.sun_open && values.sun_close) ||
              (values.mon_open && values.mon_close) ||
              (values.tue_open && values.tue_close) ||
              (values.wed_open && values.wed_close) ||
              (values.thu_open && values.thu_close) ||
              (values.fri_open && values.fri_close) ||
              (values.sat_open && values.sat_close)) &&
              (values.is_temporary === false || (values.begins_at && values.ends_at))
          );

          const isDisabled = isSubmitting || !isFormComplete;

          return (
            <form onSubmit={handleSubmit}>
              <div className="paper radius-top-left radius-top-right">
                <div className="page-header subheader underline">
                  <h4 className="h4">Schedule Information</h4>
                </div>
                <div className="schedule-form--body">
                  <div className="checkbox-list--item">
                    <label>
                      <input
                        type="checkbox"
                        checked={values.is_temporary}
                        onChange={() => {
                          setFieldValue('is_temporary', !values.is_temporary);
                        }}
                        name="is_temporary"
                      />
                      <span htmlFor="is_temporary" className="label">
                        Temporary Schedule
                      </span>
                    </label>
                  </div>
                  <InputScaffold label="Schedule Name" required validation={touched.name && errors.name}>
                    <Field type="text" name="name" onChange={handleChange} value={values.name} />
                  </InputScaffold>
                  {values.is_temporary && (
                    <div>
                      <InputScaffold label="Start Date" required validation={touched.begins_at && errors.begins_at}>
                        <DayPickerInput
                          inputProps={{
                            autoComplete: 'false',
                          }}
                          name="begins_at"
                          value={values.begins_at ? values.begins_at : ''}
                          placeholder=""
                          format="LL"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          dayPickerProps={{
                            selectedDays: [values.begins_at, { from: values.begins_at, to: values.ends_at }],
                            disabledDays: date => moment(date).isBefore(moment().subtract(1, 'day')),
                            toMonth: values.ends_at,
                            modifiers: { start: values.begins_at, end: values.ends_at },
                            numberOfMonths: 1,
                          }}
                          onDayChange={from => {
                            const date = from;
                            date.setHours(0);
                            setFieldValue('begins_at', date);
                          }}
                        />
                      </InputScaffold>
                      <InputScaffold label="End Date" required validation={touched.ends_at && errors.ends_at}>
                        <DayPickerInput
                          inputProps={{
                            autoComplete: 'false',
                          }}
                          name="ends_at"
                          value={values.ends_at ? values.ends_at : ''}
                          placeholder=""
                          format="LL"
                          formatDate={formatDate}
                          parseDate={parseDate}
                          dayPickerProps={{
                            selectedDays: [values.begins_at, { from: values.begins_at, to: values.ends_at }],
                            disabledDays: { before: values.begins_at },
                            modifiers: { start: values.begins_at, end: values.ends_at },
                            month: values.begins_at ? values.begins_at : null,
                            fromMonth: values.begins_at ? values.begins_at : null,
                            numberOfMonths: 1,
                          }}
                          onDayChange={to => {
                            const date = to;
                            date.setHours(0);
                            setFieldValue('ends_at', date);
                          }}
                        />
                      </InputScaffold>
                    </div>
                  )}

                  <div className="schedule-times-picker--container">
                    <div className="page-header subheader underline">
                      <h4 className="h4">Times</h4>
                    </div>
                    <div className="day-time--picker-input__container">
                      <div className="time-picker--list">
                        <h5 className="h5">Start</h5>
                        <div className="day-time--picker-input sun">
                          <div className="day-id">SUN</div>
                          <InputScaffold>
                            <TimePicker
                              name="sun_open"
                              showSecond={false}
                              value={values.sun_open}
                              onChange={time => {
                                setFieldValue('sun_open', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.sun_close && <div className="input-validation not-visible">{errors.sun_close}</div>}
                        <div className="day-time--picker-input mon">
                          <div className="day-id">MON</div>
                          <InputScaffold>
                            <TimePicker
                              name="mon_open"
                              showSecond={false}
                              value={values.mon_open}
                              onChange={time => {
                                setFieldValue('mon_open', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.mon_close && <div className="input-validation not-visible">{errors.mon_close}</div>}
                        <div className="day-time--picker-input tue">
                          <div className="day-id">TUE</div>
                          <InputScaffold>
                            <TimePicker
                              name="tue_open"
                              showSecond={false}
                              value={values.tue_open}
                              onChange={time => {
                                setFieldValue('tue_open', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.tue_close && <div className="input-validation not-visible">{errors.tue_close}</div>}
                        <div className="day-time--picker-input wed">
                          <div className="day-id">WED</div>
                          <InputScaffold>
                            <TimePicker
                              name="wed_open"
                              showSecond={false}
                              value={values.wed_open}
                              onChange={time => {
                                setFieldValue('wed_open', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.wed_close && <div className="input-validation not-visible">{errors.wed_close}</div>}
                        <div className="day-time--picker-input thu">
                          <div className="day-id">THU</div>
                          <InputScaffold>
                            <TimePicker
                              name="thu_open"
                              showSecond={false}
                              value={values.thu_open}
                              onChange={time => {
                                setFieldValue('thu_open', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.thu_close && <div className="input-validation not-visible">{errors.thu_close}</div>}
                        <div className="day-time--picker-input fri">
                          <div className="day-id">FRI</div>
                          <InputScaffold>
                            <TimePicker
                              name="fri_open"
                              showSecond={false}
                              value={values.fri_open}
                              onChange={time => {
                                setFieldValue('fri_open', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.fri_close && <div className="input-validation not-visible">{errors.fri_close}</div>}
                        <div className="day-time--picker-input sat">
                          <div className="day-id">SAT</div>
                          <InputScaffold>
                            <TimePicker
                              name="sat_open"
                              showSecond={false}
                              value={values.sat_open}
                              onChange={time => {
                                setFieldValue('sat_open', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.sat_close && <div className="input-validation not-visible">{errors.sat_close}</div>}
                      </div>
                      <div className="time-picker--list">
                        <h5 className="h5">End</h5>
                        <div className="day-time--picker-input sun">
                          <div className="day-id">SUN</div>
                          <InputScaffold>
                            <TimePicker
                              name="sun_close"
                              showSecond={false}
                              value={values.sun_close}
                              onChange={time => {
                                setFieldValue('sun_close', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.sun_close && (
                          <div className="input-validation input-schedule-form">{errors.sun_close}</div>
                        )}
                        <div className="day-time--picker-input mon">
                          <div className="day-id">MON</div>
                          <InputScaffold>
                            <TimePicker
                              name="mon_close"
                              showSecond={false}
                              value={values.mon_close}
                              onChange={time => {
                                setFieldValue('mon_close', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.mon_close && (
                          <div className="input-validation input-schedule-form">{errors.mon_close}</div>
                        )}
                        <div className="day-time--picker-input tue">
                          <div className="day-id">TUE</div>
                          <InputScaffold>
                            <TimePicker
                              name="tue_close"
                              showSecond={false}
                              value={values.tue_close}
                              onChange={time => {
                                setFieldValue('tue_close', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.tue_close && (
                          <div className="input-validation input-schedule-form">{errors.tue_close}</div>
                        )}
                        <div className="day-time--picker-input wed">
                          <div className="day-id">WED</div>
                          <InputScaffold>
                            <TimePicker
                              name="wed_close"
                              showSecond={false}
                              value={values.wed_close}
                              onChange={time => {
                                setFieldValue('wed_close', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.wed_close && (
                          <div className="input-validation input-schedule-form">{errors.wed_close}</div>
                        )}
                        <div className="day-time--picker-input thu">
                          <div className="day-id">THU</div>
                          <InputScaffold>
                            <TimePicker
                              name="thu_close"
                              showSecond={false}
                              value={values.thu_close}
                              onChange={time => {
                                setFieldValue('thu_close', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.thu_close && (
                          <div className="input-validation input-schedule-form">{errors.thu_close}</div>
                        )}
                        <div className="day-time--picker-input fri">
                          <div className="day-id">FRI</div>
                          <InputScaffold>
                            <TimePicker
                              name="fri_close"
                              showSecond={false}
                              value={values.fri_close}
                              onChange={time => {
                                setFieldValue('fri_close', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.fri_close && (
                          <div className="input-validation input-schedule-form">{errors.fri_close}</div>
                        )}
                        <div className="day-time--picker-input sat">
                          <div className="day-id">SAT</div>
                          <InputScaffold>
                            <TimePicker
                              name="sat_close"
                              showSecond={false}
                              value={values.sat_close}
                              onChange={time => {
                                setFieldValue('sat_close', time);
                              }}
                              format="h:mm a"
                              allowEmpty={true}
                              use12Hours
                              inputReadOnly
                              className="time-picker"
                              minuteStep={1}
                            />
                          </InputScaffold>
                        </div>
                        {errors.sat_close && (
                          <div className="input-validation input-schedule-form">{errors.sat_close}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="paper__footer radius-bottom-left radius-bottom-right">
                <button disabled={isDisabled} className="button">
                  Save Schedule
                </button>
                <button type="button" className="button button--secondary" onClick={onCancel}>
                  Cancel
                </button>
                {status && <div className="input-validation center">{status}</div>}
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export default ScheduleForm;
