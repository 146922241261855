import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../../../../../../../../../common/Loading';
import { SGCheckbox, SGUnitCheckbox, SGSearch } from '../../../../common';
import { selectModelUnits } from '../../../../../../../../redux/reducers/sgToursReducers';
import { useFormikContext } from 'formik';
import SGFormUnitsRemoveModel from './SGFormUnitsRemoveModel';

export const SGFormUnits = () => {
  const { values, setFieldValue } = useFormikContext();
  const [searchValue, setSearchValue] = useState('');
  const [removeModal, setRemoveModal] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const modelUnits = useSelector(selectModelUnits);
  const tour = useSelector(state => state.property.tour);

  const filtered = useMemo(
    () => modelUnits?.results.filter(({ number }) => number.toLowerCase().includes(searchValue.toLowerCase())),
    [modelUnits, searchValue]
  );

  if (!modelUnits) return <Loading />;

  const unitWord = modelUnits.total === 1 ? 'unit' : 'units';

  const checkedAll = filtered.length && filtered.every(item => values.units.includes(item.id));

  const handleSelectAll = ({ target }) => {
    if (target.checked) {
      setFieldValue(
        'units',
        filtered.map(item => item.id)
      );
    } else {
      setFieldValue('units', []);
    }
  };

  const confirmRemoveUnit = () => {
    setFieldValue(
      'units',
      values.units.filter(unit => unit !== selectedUnit.id)
    );
    setRemoveModal(false);
  };

  const cancelRemoveUnit = () => {
    setRemoveModal(false);
  };

  const handleClick = ({ target }) => {
    const { id, checked } = target;
    if (tour && !checked) {
      setSelectedUnit(filtered.find(unit => unit.id === id));
      setRemoveModal(true);
    } else {
      setFieldValue('units', checked ? [...values.units, id] : values.units.filter(unit => unit !== id));
    }
  };

  const handleInputSearchChange = ({ target }) => {
    setSearchValue(target.value);
  };

  const handleInputClearValue = () => {
    setSearchValue('');
  };

  return (
    <div className="sg-units">
      <SGFormUnitsRemoveModel
        item={selectedUnit}
        visible={removeModal}
        onCancel={cancelRemoveUnit}
        removeUnit={confirmRemoveUnit}
      />
      <div className="sg-units-row m-t10">
        <div className="sg-units-all">
          <div className="sg-units-all-vacant">
            {modelUnits.total} vacant {unitWord}
          </div>
          <div className="divider vertical"></div>
          <SGCheckbox
            className={'sg-checkbox sg-units-all-checkbox'}
            name="units"
            handleChange={handleSelectAll}
            checked={checkedAll}
          >
            <span>Select all units</span>
          </SGCheckbox>
        </div>

        <SGSearch
          searchValue={searchValue}
          handleInputSearchChange={handleInputSearchChange}
          handleInputClearValue={handleInputClearValue}
        />
      </div>

      <div className="sg-scroll-block sg-units-checkboxes-block m-tb20">
        {filtered.length ? (
          <div className="sg-scroll-container sg-units-checkboxes-container">
            {filtered.map(({ id, number, square }) => (
              <SGUnitCheckbox
                key={id}
                id={id}
                unitNumber={number}
                squareFootage={square}
                handleClick={handleClick}
                checked={values.units.includes(id)}
              />
            ))}
          </div>
        ) : (
          <div className="sg-unit-search-empty">
            There are no units with the name <span>“{searchValue}”</span>.
          </div>
        )}
      </div>
    </div>
  );
};
