export default {
  main: {
    subHeader: 'События',
    addEventBtn: 'Добавить мероприятие',
    csvBtn: 'Скачать CSV',
    canceledEventAlert: '{{event}} было отменено',
    tabs: {
      name: 'НАЗВАНИЕ СОБЫТИЯ',
      date: 'ДАТА',
      time: 'ДАТА НАЧАЛА/ОКОНЧАНИЯ',
      registrants: 'ЗАРЕГИСТРИРОВАННЫЕ',
      attendees: 'МАКС. ПОСЕТИТЕЛЕЙ',
      status: 'СТАТУС',
    },
    noStripeError: {
      part_1_created:
        'Невозможно создать платное событие, поскольку учетная запись Stripe для вашего сообщества не подключена. Пожалуйста, подключите её на странице ',
      part_1_selected:
        'Невозможно выбрать платное событие, поскольку учетная запись Stripe для вашего сообщества не подключена. Пожалуйста, подключите её на странице ',
      part_2: 'Настройки сообщества',
      part_3: '.',
    },
  },
  eventItem: {
    editEvent: 'Редактировать событие',
    cancelEvent: 'Отменить событие',
    activeStatus: 'Активно',
    canceledStatus: 'Отменено',
    endedStatus: 'Закончилось',
    date: '{{date, DD.MM.YYYY}}',
  },
  modals: {
    cancelEvent: {
      header: 'Отменить событие',
      provideReason:
        'Чтобы отменить это событие, пожалуйста, предоставьте причину отмены, которая будет отправлена на электронную почту всем текущим регистрантам',
      inputLabel: 'ПРИЧИНА ОТМЕНЫ',
      cancelEventBtn: 'Отменить событие',
      cancelBtn: 'Отмена',
      alreadyCancelledErr: 'Это событие уже отменено.',
      hasStartedErr: 'Это событие уже началось и не может быть отменено.',
      invalidLengthErr: 'Причина должна содержать не более 2000 символов.',
      noReasonErr: 'Пожалуйста, введите причину',
    },
    cancelAddEvent: {
      title: 'Отменить событие',
      body: 'Вы уверены, что хотите отменить создание этого события?',
      confirmButtonTitle: 'Отменить создание события',
      cancelButtonTitle: 'Отмена',
    },
    cancelEventRegistration: {
      title: 'Отменить регистрацию',
      body: 'Вы уверены, что хотите отменить регистрацию на событие?',
      submit: 'Отменить регистрацию',
      alertMessage: 'Регистрация для {{firstName}} {{lastName}} отменена успешно',
    },
    cancelEventRegistrationWaitList: {
      title: 'Отменить ожидание',
      body: 'Вы уверены, что хотите отменить ожидание на событие?',
      submit: 'Отменить ожидание',
      alertMessage: '{{firstName}} {{lastName}} был успешно убран из списка ожидания',
    },
    cancelEventRegistrationPending: {
      title: 'Отменить ожидание',
      body: 'Вы уверены, что хотите отменить ожидание на событие?', //?
      submit: 'Отменить ожидание',
    },
  },
  addEvent: {
    title: 'Добавить событие',
    backBtn: 'Назад',
    nextBtn: 'Вперед',
    cancelBtn: 'Отменить',
    savingBtn: 'Сохраняем...',
    saveEventBtn: 'Сохранить событие',
    tabs: {
      'Event Details': 'Детали события',
      Registrations: 'Регистрации',
      Financials: 'Финансы',
    },
    errors: {
      errorAddingEvent: 'При попытке создать событие произошла ошибка. Пожалуйста, свяжитесь со службой поддержки.',
    },
  },
  addDetailsTab: {
    header: 'Детали события',
    attendeeSettings: 'Настройки посетителей',
    dateFormat: '{{date, DD.MM.YYYY}}',
    inputs: {
      title: 'ЗАГОЛОВОК',
      descr: 'ОПИСАНИЕ',
      date: 'ДАТА СОБЫТИЯ',
      location: 'Расположение мероприятия',
      startTime: 'ВРЕМЯ НАЧАЛА',
      endTime: 'ВРЕМЯ ОКОНЧАНИЯ',
      instructions: 'ИНСТРУКЦИИ',
      atendeesMin: 'МИН. КОЛИЧЕСТВО ПОСЕТИТЕЛЕЙ',
      atendeesMax: 'МАКС. КОЛИЧЕСТВО ПОСЕТИТЕЛЕЙ',
      waitList: 'СПИСОК ОЖИДАНИЯ',
      pendingDuration: 'ПРОДОЛЖИТЕЛЬНОСТЬ ОЖИДАНИЯ (ЧАСЫ)',
      rsvpDate: 'ДАТА ОТКЛЮЧЕНИЯ ОТВЕТА НА ПРИГЛАШЕНИЕ',
      rsvpTime: 'ВРЕМЯ ОТКЛЮЧЕНИЯ ОТВЕТА НА ПРИГЛАШЕНИЕ',
    },
    eventImage: {
      header: 'Изображение события',
      dragImg: 'Перетащите изображение сюда, чтобы загрузить, или выберите файл на своем устройстве.',
      uploadImg: 'ЗАГРУЗИТЬ ИЗОБРАЖЕНИЕ',
      subHeader: 'Изображение события будет отображаться в разделе «События» приложения',
      remove: 'Удалить изображение',
    },
    instructionsHead: 'Инструкции по подтверждению',
    instructionsText:
      'Это будет отправлено жителям в качестве электронного письма после того, как они зарегистрировались на это событие.',
  },
  addRegisterTab: {
    title: 'Настройки регистрации',
    text: 'Ограничьте регистрации для определенных групп апартаментов',
    allResidentsCheckbox: 'Все жители',
  },

  addFinancesTab: {
    title: 'Финансы',
    eventType: 'ТИП СОБЫТИЯ',
    free: 'Бесплатно',
    paid: 'Платно',
  },
  eventSummary: {
    title: 'Предпросмотр нового события',
    subHeader: 'Просмотрите это новое событие перед тем как сохранить.',
    details: 'Детали события',
    eventTitle: 'ЗАГОЛОВОК СОБЫТИЯ',
    description: 'ОПИСАНИЕ СОБЫТИЯ',
    date: 'ДАТА СОБЫТИЯ',
    location: 'МЕСТОПОЛОЖЕНИЕ СОБЫТИЯ',
    time: 'ВРЕМЯ НАЧАЛА И ОКОНЧАНИЯ СОБЫТИЯ',
    type: 'ТИП СОБЫТИЯ',
    waitList: 'СПИСОК ОЖИДАНИЯ',
    free: 'Платно',
    paid: 'Бесплатно',
    waitListON: 'ВКЛ.',
    waitListOFF: 'ВЫКЛ.',
    rspvTime: 'ВРЕМЯ ОТКЛЮЧЕНИЯ ОТВЕТА НА ПРИГЛАШЕНИЕ',
    settings: 'Настройки регистрации',
    groups: 'ГРУППЫ АПАРТАМЕНТОВ КОТОРЫЕ МОГУТ ЗАРЕГИСТРИРОВАТЬСЯ',
    allResidents: 'Все жители',
    image: 'Изображение события',
    noImage: 'Изображение не предоставлено',
    instructionsTitle: 'Инструкции по подтверждению',
    instructionsText: 'ИНСТРУКЦИИ',
    noInstructions: 'Не предоставлены',
    financials: 'Финансы',
    vendor: 'ПРОДАВЕЦ',
    cutOffDate: 'ДАТА ОКОНЧАНИЯ ВОЗВРАТА СРЕДСТВ',
    cutOffTime: 'ВРЕМЯ ОКОНЧАНИЯ ВОЗВРАТА СРЕДСТВ',
    fee: 'ПРОЦЕНТ ЗА ОТМЕНУ',
    savingBtn: 'Сохраняю...',
    confirmBtn: 'Подтвердить',
    cancelBtn: 'Отменить',
    price: 'ЦЕНА ЗА БИЛЕТ',
    communityFee: 'ПЛАТА ЖИЛОМУ КОМПЛЕКСУ',
    SEfee: 'ПЛАТА SE',
    revenue: 'РАСЧЕТНЫЙ ДОХОД НА ПОСЕТИТЕЛЯ',
    bigFeeError: 'Комиссии не могут составлять более 100%. Пожалуйста, обновите, прежде чем продолжить.',
  },
  /*









*/
  editEvent: {
    cancelKebab: 'Отменить событие',
    tabs: {
      'Event Details': 'Детали события',
      'Revenue Details': 'Подробности о доходах',
      Registrations: 'Регистрации',
      'Refund Policy': 'Политика возврата',
      Communications: 'Связь',
    },
    eventDetails: {
      header: 'Детали события',
      title: 'ЗАГОЛОВОК',
      descr: 'ОПИСАНИЕ',
      location: 'РАСПОЛОЖЕНИЕ СОБЫТИЯ',
      dateTitle: 'ДАТА СОБЫТИЯ',
      date: '{{date, DD.MM.YYYY}}',
      timeTitle: 'ВРЕМЯ НАЧАЛА И ОКОНЧАНИЯ',
      instructionsHead: 'Инструкции по подтверждению',
      instructionsContent:
        'Это будет отправлено жителям в качестве электронного письма после того, как они зарегистрировались на это событие.',
      instructionsTitle: 'ИНСТРУКЦИИ',
      imageTitle: 'Изображение события',
      imageContent: 'Изображение события будет отображаться в разделе «События» приложения.',
      removeImgage: 'Удалить изображение',
      attendeeSettings: 'Настройки посетителей',
      minAttendees: 'МИН. КОЛИЧЕСТВО ПОСЕТИТЕЛЕЙ',
      maxAttendees: 'МАКС. КОЛИЧЕСТВО ПОСЕТИТЕЛЕЙ',
      waitList: 'СПИСОК ОЖИДАНИЯ',
      pendingDuration: 'ПРОДОЛЖИТЕЛЬНОСТЬ ОЖИДАНИЯ (ЧАСЫ)',
      saveBtn: 'Сохранить событие',
      cancelBtn: 'Отменить',
    },
    cancelEditModal: {
      title: 'Отменить редактирование событие',
      body: 'Вы уверены, что хотите отменить редактирование этого события?',
      confirmButtonTitle: 'Отменить редактирование события',
      cancelButtonTitle: 'Отмена',
    },
    alertMinRegistrants: {
      body: 'На этом мероприятии нет минимального количества зарегистрировавшихся. Хотите отменить его?',
      cancel: 'Отменить событие',
    },
    closeWaitListModal: {
      header: 'Отменить список ожидания',
      body: 'Вы уверены что хотите отменить список ожидания для этого события?',
      remove: 'Отменить список ожидания',
      cancel: 'Отмена',
    },
    readOnlyDetails: {
      header: 'Детали события',
      time: 'ВРЕМЯ НАЧАЛА И ОКОНЧАНИЯ',
      instructions: 'Инструкции по подтверждению',
      noInstructions: 'Не предоставлены',
      image: 'Изображение события',
      body: 'Изображение события будет отображаться в разделе «События» приложения.',
      noImage: 'Изображение не предоставлено',
      attendees: 'Настройки посетителей',
      rspv: 'ДАТА И ВРЕМЯ ОТКЛЮЧЕНИЯ ОТВЕТА НА ПРИГЛАШЕНИЕ',
      backBtn: 'Назад',
      atendeesMax: 'МАКС. КОЛИЧЕСТВО ПОСЕТИТЕЛЕЙ',
      atendeesMin: 'МИН. КОЛИЧЕСТВО ПОСЕТИТЕЛЕЙ',
      title: 'ЗАГОЛОВОК',
      descr: 'ОПИСАНИЕ',
      location: 'РАСПОЛОЖЕНИЕ СОБЫТИЯ',
      dateTitle: 'ДАТА СОБЫТИЯ',
    },
    registrations: {
      header: 'Настройки регистрации',
      subHeader: 'ГРУППЫ АПАРТАМЕНТОВ КОТОРЫЕ МОГУТ ЗАРЕГИСТРИРОВАТЬСЯ',
      allResidents: 'Все жители',
      downloadCSV: 'Скачать CSV',
      registerResidentsBtn: 'Зарегистрировать жителей',
      attendeesTitle: 'Участники',
      resName: 'ИМЯ ЖИТЕЛЯ',
      resDate: 'ДАТА РЕГИСТРАЦИИ',
      attendees: 'УЧАСТНИКИ',
      status: 'СТАТУС',
      waitListTitle: 'Список ожидания',
      activeStatus: 'АКТИВНО',
      canceledStatus: 'ОТМЕНЕНО АДМИНОМ',
      cancelKebab: 'Отменить регистрацию',
      cancelPendingKebab: 'Отменить ожидание',
      cancelWaitlistKebab: {
        cancel: 'Убрать из списка ожидания',
        move: 'Передвинуть в начало',
      },
    },
  },
  guestRegistrationTab: {
    title: 'Регистрация гостя',
    body: 'Зарегистрируйте гостей, выбрав каждого гостя и добавив дополнительных гостей.',
    inputPlaceholder: 'Поиск жильцов и апартаментов', //?
    importCsvButton: 'Импортировать CSV файл',
    registerButton: 'Зарегистрировать',
    regName: 'ИМЯ',
    regUnit: 'АПАРТАМЕНТЫ', //?
    regEmail: 'ЭЛЕКТРОННАЯ ПОЧТА',
    regAdditional: 'ДОПОЛНИТЕЛЬНО',

    importCsvModal: {
      title: 'Импортировать CSV файл',
      label: 'Импортировать используя CSV файл',
      helperText: 'Столбцы файла: электронная почта, количество гостей с вами не включено.', //?
      submit: 'Отправить', //?
    },
    importCsvResModal: {
      title: 'CSV импортирован успешно!',
    },
    registrationConfirmModal: {
      title: 'Подтвердить регистрацию?',
    },
    registrationResultModal: {
      successTitle: 'Регистрация успешна!',
      successBody: 'Регистрация завершена.',
      errorTitle: 'Ошибка регистрации',
      duplicate: 'Существует дубликат заявки. Нажмите OK, чтобы обновить страницу.',
      errorBody:
        'Достигнута максимальное количество посетителей <br />' +
        'Хотели бы вы увеличить максимальное количество посетителей? <br />' +
        'Или добавьте любого дополнительного гостя в список ожидания <br />',
      increaseMax: 'Увеличить максимальное количество посетителей', //?
      waitlist: 'Список ожидания',
      deselect: 'Нет, вернуться назад и отменить выбор',
    },
  },
  communicationsTab: {
    title: 'Связь',
    body: 'Вы можете отправить сообщение, которое будет доставлено всем жителям, зарегистрировавшихся на это событие.',
    message: 'СООБЩЕНИЕ',
    submit: 'Отправить сообщение', //?
    successMessage: 'Сообщение было успешно отправлено!',
    errorMessage: 'Сообщение не было отправлено!',
    historyTitle: 'История связи', //?
    dateSent: 'ДАТА ОТПРАВКИ',
    show: 'Показать сообщение',
    hide: 'Скрыть сообщение',
  },
};
