import { connect } from 'react-redux';

import DoorsList from './DoorsList';
import { getDoors, getPropertyPanelAreas, removeDoor, getPanelDoors } from '../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlDoors: state.property.accessControlDoors,
    accessControlDoorsLoaded: state.property.accessControlDoorsLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getDoors: () => dispatch(getDoors(ownProps.match.params.propertyId)),
    getPropertyPanelAreas: () => dispatch(getPropertyPanelAreas(ownProps.match.params.propertyId)),
    removeDoor: areaId => dispatch(removeDoor(ownProps.match.params.propertyId, areaId)),
    getPanelDoors: () => dispatch(getPanelDoors(ownProps.match.params.propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoorsList);
