import { connect } from 'react-redux';
import { compose } from 'redux';
import EditPropertyManager from './EditPropertyManager';
import { withRouter } from 'react-router-dom';
import {
  editPropertyManager,
  removePropertyManager,
  getPropertyManager,
  resetPropertyManager,
  getProfiles,
} from '../../../../../../redux/actions';
import { selectAccessControlProfileOptions, selectCredentialTypesForProperty } from '../../../../../../redux/selectors';
import { getCredentialTypes } from '../../../../../../../../redux/actions';
import { selectRoleOptions2 } from '../../../../../../../RolesAndPermissions/redux/selectors';
import { getRolesByProperty } from '../../../../../../../RolesAndPermissions/redux/actions';
import { getUserPermission } from '../../../../../..../../../../../redux/selectors';

function mapStateToProps(state, props) {
  return {
    property: state.property.activeProperty,
    propertyManager: state.property.propertyManager,
    propertyManagerLoaded: state.property.propertyManagerLoaded,
    credentialTypes: selectCredentialTypesForProperty(state, props),
    accessControlProfilesLoaded: state.property.accessControlProfilesLoaded,
    accessControlProfileOptions: selectAccessControlProfileOptions(state),
    roleOptions: selectRoleOptions2(state),
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch, props) {
  const { propertyId, propertyManagerId } = props.match.params;
  return {
    getPropertyManager: () => dispatch(getPropertyManager(propertyId, propertyManagerId)),
    resetPropertyManager: () => dispatch(resetPropertyManager()),
    editPropertyManager: values => dispatch(editPropertyManager(propertyId, propertyManagerId, values)),
    getCredentialTypes: () => dispatch(getCredentialTypes()),
    removePropertyManager: () => dispatch(removePropertyManager(propertyId, propertyManagerId)),
    getProfiles: () => dispatch(getProfiles(props.match.params.propertyId)),
    getRolesByProperty: propertyId => dispatch(getRolesByProperty(propertyId)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(EditPropertyManager);
