import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubHeader from '../../../../../common/SubHeader/index';
import Delete from '../../../../../common/Delete/index';
import {
  propertySelector,
  communityViewsCamerasSelector,
  communityViewsVideoProcessingServerSelector,
  videoProcessingServerConfigLoadingSelector,
  communityViewsCamerasLoadingSelector,
} from '../../../redux/selectors';
import { Link } from 'react-router-dom';

import Icon from '../../../../../common/icons/icon';
import Kebab from '../../../../../common/Kebab';
import EmptyPlaceholder from '../../../../../common/EmptyPlaceholder';
import {
  getCommunityViewsCameras,
  removeCommunityViewsCamera,
  requestVideoProcessingServerConfig,
} from '../../../redux/actions/communityViewsActions';

export function Cameras({ match, history }) {
  const dispatch = useDispatch();

  const property = useSelector(propertySelector);
  const cameras = useSelector(communityViewsCamerasSelector);
  const videoProcessingServer = useSelector(communityViewsVideoProcessingServerSelector);
  const videoProcessingServerLoading = useSelector(videoProcessingServerConfigLoadingSelector);
  const communityViewsCamerasLoading = useSelector(communityViewsCamerasLoadingSelector);

  const [openKebabCameraId, setOpenKebabCameraId] = useState();
  const [deleteConfirmationResidentId, setDeleteConfirmationResidentId] = useState();

  function closeKebab() {
    setOpenKebabCameraId(null);
  }

  function onKebabClick(cameraId) {
    return () => setOpenKebabCameraId(cameraId);
  }

  function onClickEdit(cameraId) {
    return () => {
      history.push(`${match.url}/${cameraId}/edit`);
      closeKebab();
    };
  }

  function onClickAddCamera() {
    history.push(`${match.url}/add`);
  }

  function onClickRemove(cameraId) {
    return () => dispatch(removeCommunityViewsCamera(cameraId, property.get('id').toString()));
  }

  function renderDeleteConfirmation(cameraId) {
    console.log('cameraId is ', cameraId);
    return (
      <div className="paper__footer radius-bottom-left radius-bottom-right t-removef-btn" style={{ display: 'block' }}>
        <Delete
          onCancel={() => setDeleteConfirmationResidentId(null)}
          deleteFunc={onClickRemove(cameraId)}
          message={
            <span>
              Are you sure you want to <strong className="strong">remove this Camera</strong>?
            </span>
          }
        />
      </div>
    );
  }

  useEffect(() => {
    dispatch(getCommunityViewsCameras(property.get('id').toString()));
    dispatch(requestVideoProcessingServerConfig(property.get('id').toString()));
  }, [property, dispatch]);

  return (
    <div className="container">
      <SubHeader
        title="Cameras"
        actions={
          cameras?.length > 0
            ? [
                <button className="button" onClick={onClickAddCamera}>
                  Add Camera
                </button>,
              ]
            : null
        }
      />
      {videoProcessingServerLoading && <span>Loading Community Views Configuration...</span>}
      {!videoProcessingServerLoading && !communityViewsCamerasLoading && cameras?.length > 0 ? (
        <table className="table-units-list cameras-list">
          <thead>
            <tr>
              <th>Name</th>
              <th>Last Reported Status</th>
              <th>People</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cameras
              ?.sort((a, b) => a.camera_name.localeCompare(b.camera_name))
              .map(camera => (
                <tr key={camera.id}>
                  <td>
                    <Link to={`${match.url}/${camera.id}/info`}>{camera.camera_name}</Link>
                  </td>
                  <td>
                    <div className="cameras--status">
                      {camera.last_reported_status === 'ONLINE' && (
                        <div className="status-online">
                          <Icon icon="CircleSolid" fill="#5AAD55" />
                        </div>
                      )}
                      {camera.last_reported_status === 'OFFLINE' && (
                        <div className="status-offline">
                          <Icon icon="CircleSolid" fill="#EB586B" />
                        </div>
                      )}
                      <span>{camera.last_reported_status?.toLowerCase()}</span>
                    </div>
                  </td>
                  <td>{camera.people_counts[0]?.count ?? 0}</td>
                  <td className="table__align-right">
                    {deleteConfirmationResidentId === camera.id ? (
                      renderDeleteConfirmation(camera.id)
                    ) : (
                      <Kebab
                        onClick={onKebabClick(camera.id)}
                        onDisengage={closeKebab}
                        menuOpened={camera.id === openKebabCameraId}
                      >
                        <div className="kebab__menu">
                          <div className="kebab__menu--item" onClick={onClickEdit(camera.id)}>
                            <Icon icon="Edit" />
                            Edit
                          </div>
                          <div
                            className="kebab__menu--item"
                            onClick={() => {
                              setDeleteConfirmationResidentId(camera.id);
                              closeKebab();
                            }}
                          >
                            <Icon icon="Remove" />
                            Remove
                          </div>
                        </div>
                      </Kebab>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : videoProcessingServer ? (
        <EmptyPlaceholder buttonText="Add Camera" onClick={onClickAddCamera} />
      ) : (
        <span>
          Please configure a valid Video Processing Server host under Settings before attempting to add Cameras
        </span>
      )}
    </div>
  );
}
