import React from 'react';

function Search() {
  /* eslint-disable max-len */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1024 1024">
      <path d="M706.048 615.552l235.2 235.2-90.496 90.496-235.2-235.2a352 352 0 1 1 90.496-90.496zM416 640a224 224 0 1 0 0-448 224 224 0 0 0 0 448z" />
    </svg>
  );
  /* eslint-enable */
}

export default Search;
