import { connect } from 'react-redux';

import AddAccessPoint from './AddAccessPoint';
import { addAccessPoint, getDoors } from '../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlDoors: state.property.accessControlDoors.filter(door => !door.get('access_point')),
    accessControlDoorsLoaded: state.property.accessControlDoorsLoaded,
    user: state.user,
    hasMasterPanel: !!state.property.activeProperty.get('clubhouse_panel_id'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    addAccessPoint: payload => dispatch(addAccessPoint(ownProps.match.params.propertyId, payload)),
    getDoors: () => dispatch(getDoors(ownProps.match.params.propertyId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAccessPoint);
