import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

export const SGGroupButtons = ({
  className,
  submitButtonTitle,
  cancelButtonTitle,
  onCancel,
  children,
  iconSaveComponent,
  iconCancelComponent,
  isCreateForm,
}) => {
  const { handleSubmit } = useFormikContext();

  return (
    <div className={classNames('btns-container', className)}>
      <button type="submit" onClick={handleSubmit} className="button btn-save">
        {isCreateForm ? (
          <div className="button--children">
            <span className="h5 title-left">{submitButtonTitle}</span>
            {iconSaveComponent}
          </div>
        ) : (
          <div className="button--children">
            {iconSaveComponent}
            <span className="h5 title-right">{submitButtonTitle}</span>
          </div>
        )}
      </button>
      <button onClick={onCancel} className="button button--secondary--destructive RP-From-Delete" type="button">
        {iconCancelComponent}
        <span className="h5">{cancelButtonTitle}</span>
      </button>
      {children}
    </div>
  );
};

SGGroupButtons.propTypes = {
  className: PropTypes.string,
  saveButton: PropTypes.string,
  cancelButton: PropTypes.string,
  titleModal: PropTypes.string,
  confirmButtonTitle: PropTypes.string,
  cancelButtonTitle: PropTypes.string,
  isCreateForm: PropTypes.bool,
};
