import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../../../../../common/icons/icon';

export const SGTdFakeBtn = ({ title }) => (
  <div className="td-fake-btn">
    <span className="h5">{title}</span>
    <Icon icon="ArrowShortRight" />
  </div>
);

SGTdFakeBtn.propTypes = {
  title: PropTypes.string,
};
