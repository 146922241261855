import React, { Component } from 'react';
import querystring from 'query-string';
import PropTypes from 'prop-types';

import Loading from '../../common/Loading';
import OfferItem from './OfferItem';
import Pager from '../../common/Pager';
import EmptyPlaceholder from '../../common/EmptyPlaceholder';

class OffersTable extends Component {
  render() {
    const { userPermission, loading, location, list, removeOffer, total, searching } = this.props;
    const locationObj = querystring.parse(location.search);

    if (loading) {
      return <Loading />;
    }

    if (searching && total === 0) {
      return <h6 className="no-results">There are no results for your search criteria.</h6>;
    }

    if (!list.size) {
      if (userPermission.createNewOffer) {
        return <EmptyPlaceholder buttonText="Create Offer" linkTo="offers/add" />;
      } else {
        return <div className="zero-content" />;
      }
    }

    return (
      <div>
        <table className="table-units-list offers-table">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Contact Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th className="td-kebab" />
            </tr>
          </thead>
          <tbody>
            {list.map(offer => (
              <OfferItem
                key={offer.get('id')}
                offer={offer}
                removeOffer={removeOffer}
                userPermission={userPermission}
              />
            ))}
          </tbody>
        </table>
        <div>
          <Pager initialPage={parseInt(locationObj.page, 10) || 0} pageSize={30} totalItems={total} />
        </div>
      </div>
    );
  }
}

OffersTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  list: PropTypes.object.isRequired,
  removeOffer: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  searching: PropTypes.bool.isRequired,
};

export default OffersTable;
