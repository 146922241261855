import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import Icon from '../../common/icons/icon';
import classNames from 'classnames';
import logo from '../../static/logo.png';
import Sidebar from '../../common/Sidebar';
import UserMenu from '../../common/UserMenu';
import PropertySubNavLink from './PropertySubNavLink';

const routes = [
  { route: `units`, title: 'Units' },
  { route: `property-details`, title: 'Property' },
  { route: 'announcements', title: 'Announcements' },
  { route: 'events', title: 'Events' },
  { route: 'battery-levels', title: 'Battery Levels' },
];

class PropertyLayout extends Component {
  state = {
    isMobileNavOpen: false,
  };

  componentDidMount() {
    document.body.classList.add('pm');
  }
  componentWillUnmount() {
    document.body.classList.remove('pm');
  }

  toggleMobileNav = () => {
    this.setState({
      isMobileNavOpen: !this.state.isMobileNavOpen,
    });
  };

  onCloseUnderlay = () => {
    this.setState({
      isMobileNavOpen: false,
    });
  };

  renderUnderlay() {
    if (this.state.isMobileNavOpen) {
      return <button className="underlay" onClick={this.onCloseUnderlay} />;
    }
  }

  renderMobileNavigation() {
    const mobileClasses = {
      drawer__nav: true,
      'drawer__nav--open': this.state.isMobileNavOpen,
    };

    return (
      <div className="navigation--mobile">
        <div className="hamburger" onClick={this.toggleMobileNav}>
          <Icon icon="Hamburger" />
        </div>
        <Link className="navigation--mobile__logo logo" to="/">
          <img src={logo} className="logo-image" alt="Smart Experience logo" />
        </Link>
        <nav className={classNames(mobileClasses)}>
          <div className="hamburger hamburger-open" onClick={this.toggleMobileNav}>
            <Icon icon="Clear" />
          </div>

          {this.renderNavItems(true)}
        </nav>
      </div>
    );
  }

  renderNavItems = isMobileMenu => {
    const { property, match, propertyCount, isAdmin, userPermissionList, user, t } = this.props;
    const userPermission = {
      // Units & Residents
      unitsAndResidents:
        userPermissionList.includes('VIEW_UNITS_LIST_PAGE') || userPermissionList.includes('VIEW_RESIDENT_LIST'),
      UnitList: userPermissionList.includes('VIEW_UNITS_LIST_PAGE'),
      ResidentList: userPermissionList.includes('VIEW_RESIDENT_LIST'),
      SelfGuidedTour: userPermissionList.includes('SHOW_SELF-GUIDED_TOURS'),
      // Smart Home
      BatteryLevels: userPermissionList.includes('VIEW_DEVICE_BATTERY_PAGE'),
      VacantUnits: userPermissionList.includes('VIEW_VACANT_UNIT_AUTOMATION_PAGE'),
      // SmartAccess
      PropertyGuests: userPermissionList.includes('VIEW_PROPERTY_GUESTS'),
      ResidentGuests: userPermissionList.includes('VIEW_UNIT_GUESTS'),
      AccessLog: userPermissionList.includes('VIEW_ACCESS_LOG_PAGE'),
      Areas: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      Doors: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      Schedules: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      AccessProfiles: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      AccessPoints: userPermissionList.includes('VIEW_ACCESS_POINTS'),
      AccessSetUp: userPermissionList.includes('VIEW_SMART_ACCESS_SETUP'),
      // Smart Community
      smartCommunity: userPermissionList.includes('VIEW_SMART_COMMUNITY'),
      Amenities: true,
      Announcements: true,
      Events: true,
      Vendors: true,
      // Community Views
      communityViews: userPermissionList.includes('VIEW_COMMUNITY_VIEWS'),
      // Settings
      PortalUsers: userPermissionList.includes('VIEW_PORTAL_USERS_PAGE'),
      CommunitySettings: userPermissionList.includes('VIEW_COMMUNITY_SETTINGS_PAGE'),
      ResidentFeatures: userPermissionList.includes('VIEW_RESIDENT_FEATURES_PAGE'),
      Maintenance: userPermissionList.includes('VIEW_MAINTENANCE'),
      UnitGroups: userPermissionList.includes('VIEW_UNIT_GROUPS'),
      MailHubs: userPermissionList.includes('VIEW_MAIL_HUBS'),
      Devices: userPermissionList.includes('VIEW_DEVICES_PAGE'),
      Settings:
        userPermissionList.includes('VIEW_PORTAL_USERS_PAGE') ||
        userPermissionList.includes('VIEW_COMMUNITY_SETTINGS_PAGE') ||
        userPermissionList.includes('VIEW_RESIDENT_FEATURES_PAGE') ||
        userPermissionList.includes('VIEW_MAINTENANCE') ||
        userPermissionList.includes('VIEW_UNIT_GROUPS') ||
        userPermissionList.includes('VIEW_MAIL_HUBS') ||
        userPermissionList.includes('VIEW_DEVICES_PAGE'),
    };
    const role = user.get('role');
    const isPM = user.get('isAdminOrPM') && user.get('isAdminOrPM').get('role') === 'PROPERTY_MANAGER';
    const isPMAndNoSigned = role.size === 0 && isPM;
    const mobileClasses = classNames({
      'drawer__nav--link': isMobileMenu,
    });

    const showSmartHome = userPermission.BatteryLevels || userPermission.VacantUnits;
    const smartAccessShowChildren =
      property.get('admin_enabled_access_control') &&
      (userPermission.ResidentGuests ||
        userPermission.PropertyGuests ||
        userPermission.AccessLog ||
        (property.get('clubhouse_panel_id') && userPermission.AccessSetUp));

    return (
      <Fragment>
        {isAdmin || (propertyCount > 1 && !isAdmin) ? (
          <div className="admin-back-link">
            <Link className="admin-back-link--inner" to="/">
              <Icon icon="ArrowLeft" />
              <span>{isAdmin ? t('backToAdmin') : t('backToPropertiesList')}</span>
            </Link>
          </div>
        ) : null}
        {userPermission.unitsAndResidents && (
          <PropertySubNavLink
            className={mobileClasses}
            to={`${match.url}/units`}
            icon="Person"
            label={t('units.title')}
          >
            {userPermission.UnitList && (
              <NavLink className={mobileClasses} to={`${match.url}/units/unit-list`} activeClassName="current">
                {t('units.subRoutes.unitList')}
              </NavLink>
            )}
            {userPermission.ResidentList && (
              <NavLink className={mobileClasses} to={`${match.url}/units/resident-list`} activeClassName="current">
                {t('units.subRoutes.residentList')}
              </NavLink>
            )}
            {userPermission.SelfGuidedTour && (
              <NavLink className={mobileClasses} to={`${match.url}/units/tours`} activeClassName="current">
                {t('units.subRoutes.selfGuidedTours')}
              </NavLink>
            )}
          </PropertySubNavLink>
        )}
        {showSmartHome && (
          <PropertySubNavLink
            className={mobileClasses}
            to={`${match.url}/automation`}
            icon="Home"
            label={t('smartHome.title')}
            showChildren={property.get('admin_enabled_unit_automation')}
          >
            {userPermission.BatteryLevels && (
              <NavLink
                className={mobileClasses}
                to={`${match.url}/automation/battery-levels`}
                activeClassName="current"
              >
                {t('smartHome.subRoutes.batteryLevels')}
              </NavLink>
            )}
            {userPermission.VacantUnits && (
              <NavLink className={mobileClasses} to={`${match.url}/automation/vacant-units`} activeClassName="current">
                {t('smartHome.subRoutes.vacantUnits')}
              </NavLink>
            )}
          </PropertySubNavLink>
        )}
        <PropertySubNavLink
          className={mobileClasses}
          to={`${match.url}/resident-messages`}
          icon="Message"
          label={t('residentMessages.title')}
        >
          <NavLink
            className={mobileClasses}
            to={`${match.url}/resident-messages/help-from-management`}
            activeClassName="current"
          >
            {t('residentMessages.subRoutes.helpFromManagement')}
          </NavLink>
          <NavLink
            className={mobileClasses}
            to={`${match.url}/resident-messages/maintenance-requests`}
            activeClassName="current"
          >
            {t('residentMessages.subRoutes.maintenanceRequests')}
          </NavLink>
        </PropertySubNavLink>
        <PropertySubNavLink
          className={mobileClasses}
          to={`${match.url}/access-control`}
          icon="DoorPad"
          label={t('smartAccess.title')}
          showChildren={smartAccessShowChildren}
        >
          {userPermission.PropertyGuests && (
            <NavLink
              className={mobileClasses}
              to={`/properties/${property.get('id')}/access-control/property-guests`}
              activeClassName="current"
            >
              {t('smartAccess.subRoutes.propertyGuests')}
            </NavLink>
          )}

          {userPermission.ResidentGuests && (
            <NavLink
              className={mobileClasses}
              to={`/properties/${property.get('id')}/access-control/resident-guests`}
              activeClassName="current"
            >
              {t('smartAccess.subRoutes.residentGuests')}
            </NavLink>
          )}
          {userPermission.AccessLog && (
            <NavLink
              className={mobileClasses}
              to={`/properties/${property.get('id')}/access-control/access-requests`}
              activeClassName="current"
            >
              {t('smartAccess.subRoutes.accessLog')}
            </NavLink>
          )}
          {property.get('clubhouse_panel_id') ? (
            <>
              {userPermission.Areas && (
                <NavLink
                  className={mobileClasses}
                  to={`/properties/${property.get('id')}/access-control/areas`}
                  activeClassName="current"
                >
                  {t('smartAccess.subRoutes.areas')}
                </NavLink>
              )}
              {userPermission.Doors && (
                <NavLink
                  className={mobileClasses}
                  to={`/properties/${property.get('id')}/access-control/doors`}
                  activeClassName="current"
                >
                  {t('smartAccess.subRoutes.doors')}
                </NavLink>
              )}
              {userPermission.Schedules && (
                <NavLink
                  className={mobileClasses}
                  to={`/properties/${property.get('id')}/access-control/schedules`}
                  activeClassName="current"
                >
                  {t('smartAccess.subRoutes.schedules')}
                </NavLink>
              )}
              {userPermission.AccessProfiles && (
                <NavLink
                  className={mobileClasses}
                  to={`/properties/${property.get('id')}/access-control/profiles`}
                  activeClassName="current"
                >
                  {t('smartAccess.subRoutes.accessProfiles')}
                </NavLink>
              )}
            </>
          ) : null}
          {userPermission.AccessPoints && (
            <NavLink
              className={mobileClasses}
              to={`/properties/${property.get('id')}/access-control/access-points`}
              activeClassName="current"
            >
              {t('smartAccess.subRoutes.accessPoints')}
            </NavLink>
          )}
        </PropertySubNavLink>
        {userPermission.smartCommunity && (
          <PropertySubNavLink
            className={mobileClasses}
            to={`${match.url}/community`}
            icon="Community"
            label={t('smartCommunity.title')}
            showChildren={property.get('admin_enabled_community')}
          >
            {userPermission.Amenities && (
              <NavLink
                className={mobileClasses}
                to={`/properties/${property.get('id')}/community/amenities`}
                activeClassName="current"
              >
                {t('smartCommunity.subRoutes.amenities')}
              </NavLink>
            )}
            {userPermission.Announcements && property.get('enabled_messages') ? (
              <NavLink className={mobileClasses} to={`${match.url}/community/announcements`} activeClassName="current">
                {t('smartCommunity.subRoutes.announcements')}
              </NavLink>
            ) : null}
            {property.get('enabled_events') ? (
              <Fragment>
                {userPermission.Events && (
                  <NavLink
                    className={mobileClasses}
                    to={`/properties/${property.get('id')}/community/events`}
                    activeClassName="current"
                  >
                    {t('smartCommunity.subRoutes.events')}
                  </NavLink>
                )}
                {userPermission.Vendors && (
                  <NavLink className={mobileClasses} to={`${match.url}/community/vendors`} activeClassName="current">
                    {t('smartCommunity.subRoutes.vendors')}
                  </NavLink>
                )}
              </Fragment>
            ) : null}

            {property.get('enabled_discussion') ? (
              <NavLink className={mobileClasses} to={`${match.url}/community/discussion`} activeClassName="current">
                {t('smartCommunity.subRoutes.discussion')}
              </NavLink>
            ) : null}
            {property.get('enabled_discussion_board_review') && (
              <NavLink
                className={mobileClasses}
                to={`${match.url}/community/pending-discussions`}
                activeClassName="current"
              >
                {t('smartCommunity.subRoutes.pendingDiscussions')}
              </NavLink>
            )}
          </PropertySubNavLink>
        )}
        {property.get('admin_enabled_community_views') && userPermission.communityViews && (
          <PropertySubNavLink
            className={mobileClasses}
            to={`${match.url}/community-views`}
            label={t('communityViews.title')}
            icon="Camera"
            showChildren={property.get('admin_enabled_community_views')}
          >
            <NavLink className={mobileClasses} to={`${match.url}/community-views/cameras`} activeClassName="current">
              {t('communityViews.subRoutes.cameras')}
            </NavLink>
            <NavLink
              className={mobileClasses}
              to={`${match.url}/community-views/archived-video`}
              activeClassName="current"
            >
              {t('communityViews.subRoutes.archivedVideo')}
            </NavLink>
            <NavLink
              className={mobileClasses}
              to={`${match.url}/community-views/notifications`}
              activeClassName="current"
            >
              {t('communityViews.subRoutes.notifications')}
            </NavLink>
            <NavLink
              className={mobileClasses}
              to={`/properties/${property.get('id')}/community-views/settings`}
              activeClassName="current"
            >
              {t('communityViews.subRoutes.settings')}
            </NavLink>
          </PropertySubNavLink>
        )}
        {userPermission.Settings && (
          <PropertySubNavLink
            className={mobileClasses}
            to={`${match.url}/property-details`}
            icon="Cog"
            label={t('settings.title')}
          >
            {userPermission.PortalUsers && (
              <NavLink
                className={mobileClasses}
                to={`/properties/${property.get('id')}/property-details/property-managers`}
                activeClassName="current"
              >
                {t('settings.subRoutes.portalUsers')}
              </NavLink>
            )}
            {userPermission.CommunitySettings && (
              <NavLink
                className={mobileClasses}
                to={`/properties/${property.get('id')}/property-details/settings`}
                activeClassName="current"
              >
                {t('settings.subRoutes.communitySettings')}
              </NavLink>
            )}
            {userPermission.ResidentFeatures && (
              <NavLink
                className={mobileClasses}
                to={`/properties/${property.get('id')}/property-details/resident-features`}
                activeClassName="current"
              >
                {t('settings.subRoutes.residentFeatures')}
              </NavLink>
            )}
            {userPermission.Maintenance && property.get('enabled_maintenance') ? (
              <NavLink
                className={mobileClasses}
                to={`/properties/${property.get('id')}/property-details/maintenance`}
                activeClassName="current"
              >
                {t('settings.subRoutes.maintenance')}
              </NavLink>
            ) : null}
            {property.get('admin_enabled_mri') && (
              <NavLink
                className={mobileClasses}
                to={`/properties/${property.get('id')}/property-details/integrations`}
                activeClassName="current"
              >
                {t('settings.subRoutes.integrations')}
              </NavLink>
            )}
            {userPermission.UnitGroups && (
              <NavLink
                className={mobileClasses}
                to={`/properties/${property.get('id')}/property-details/groups`}
                activeClassName="current"
              >
                {t('settings.subRoutes.unitGroups')}
              </NavLink>
            )}
            {userPermission.MailHubs && (
              <NavLink
                className={mobileClasses}
                to={`/properties/${property.get('id')}/property-details/mail-hubs`}
                activeClassName="current"
              >
                {t('settings.subRoutes.mailHubs')}
              </NavLink>
            )}
            {!isPMAndNoSigned && userPermission.Devices && (
              <NavLink
                className={mobileClasses}
                to={`/properties/${property.get('id')}/property-details/devices/properties`}
                activeClassName="current"
              >
                {t('settings.subRoutes.devices')}
              </NavLink>
            )}
          </PropertySubNavLink>
        )}
      </Fragment>
    );
  };

  render() {
    const { children, match, isAdmin, location, activeProperty, activeUnit } = this.props;

    const pathname = location.pathname.split('/')[3];
    const route = pathname && routes.find(route => route.route === pathname);
    let title = route ? route.title : null;

    if (activeUnit && !activeUnit.isEmpty()) {
      title = `Unit ${activeUnit.get('number')}`;
    } else if (activeProperty && !activeProperty.isEmpty()) {
      title = activeProperty.get('name');
    }

    return (
      <div className="app__container">
        {this.renderMobileNavigation()}
        <Sidebar>
          <div className="sidebar__header">
            <Link to={match.url} className="logo">
              <img src={logo} className="logo-image" alt="Smart Experience logo" />
            </Link>
          </div>
          {this.renderNavItems(false)}
        </Sidebar>
        <div className="content-pane">
          <UserMenu isAdmin={isAdmin} title={title} />
          {children}
        </div>
        {this.renderUnderlay()}
      </div>
    );
  }
}

PropertyLayout.propTypes = {
  children: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  propertyCount: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default PropertyLayout;
