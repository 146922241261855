import { connect } from 'react-redux';
import ResidentGuests from './ResidentGuests';

import { getUnitGuestList, revokeUnitGuestCommunityAccess } from '../../../../redux/actions';

const mapStateToProps = state => ({
  property: state.property.activeProperty,
  unitGuests: state.property.unitGuestList,
  unitGuestsLoaded: state.property.unitGuestListLoaded,
});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { propertyId },
    },
  }
) => ({
  getUnitGuestList: params => dispatch(getUnitGuestList(propertyId, params)),
  revokeUnitGuestCommunityAccess: (guestId, reason) =>
    dispatch(revokeUnitGuestCommunityAccess(propertyId, guestId, reason)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ResidentGuests);
