import { connect } from 'react-redux';
import { getPosts } from '../../../../../../redux/actions';
import PostList from './PostList';
import { getUserPermission } from '../../../../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    discussionList: state.property.discussionList,
    loading: state.property.postListLoading,
    property: state.property.activeProperty,
    postList: state.property.postList.get('results'),
    postListTotal: state.property.postList.get('total'),
    userPermissionList: getUserPermission(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPosts: (propertyId, page, pageSize, type) => dispatch(getPosts(propertyId, page, pageSize, type)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    getPosts: (page, pageSize, type) => dispatchProps.getPosts(stateProps.property.get('id'), page, pageSize, type),
  });
}

const PostListContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PostList);

export default PostListContainer;
