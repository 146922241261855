export default {
  addAnnouncementsBtn: 'Добавить объявления',
  pageTitle: 'Объявления',
  titleColumn: 'ЗАГОЛОВОК',
  groupColumn: 'ГРУППЫ',
  postedDateColumn: 'ДАТА ПУБЛИКАЦИИ',
  downloadCSVBtn: 'Скачать CSV',
  announcementDetailsTitle: 'Детали объявления',
  fieldTitleAnnouncement: 'НАЗВАНИЕ ОБЪЯВЛЕНИЯ',
  fieldAnnouncementDescription: 'ОПИСАНИЕ ОБЪЯВЛЕНИЯ',
  labelGroups: 'ГРУППЫ',
  allResidents: 'Все жители',
  helperAnnouncementText: 'После сохранения, уведомления об этом объявлении будут отправлены немедленно',
  postAnnouncementBtn: 'Опубликовать',
  cancelAnnouncementBtn: 'Отменить',
  postedAt: 'Опубликовано в',
  saveAnnouncementBtn: 'Сохранить объявление',
  removeAnnouncementBtn: 'Удалить объявление',
  deletePopupMassage_1: 'Вы уверены, что хотите',
  deletePopupMassage_2: 'удалить это объявление',
};
