import client from '../common/client';
import config from '../common/config';
import getDecodedToken from '../common/auth/utils/getDecodedToken';
import querystring from 'query-string';

export const getUser = token => {
  const decoded = getDecodedToken(token);
  return {
    promise: client.get(`users/${decoded.sub}`),
    type: 'GET_USER',
  };
};

export const getFeatureFlags = () => {
  return {
    promise: client.get('feature-flags'),
    type: 'GET_FEATURE_FLAGS',
  };
};

export const createOffer = payload => {
  return {
    promise: client.post(`offers`, payload),
    type: 'CREATE_OFFER',
  };
};

export const editOffer = (payload, offerId) => {
  return {
    promise: client.patch(`offers/${offerId}`, payload),
    type: 'EDIT_OFFER',
  };
};

export const getOffer = offerId => {
  return {
    promise: client.get(`offers/${offerId}`),
    type: 'GET_OFFER',
  };
};

export const removeOffer = offerId => {
  return {
    promise: client.delete(`offers/${offerId}`),
    type: 'REMOVE_OFFER',
    offerId,
  };
};

export const getOffers = (params = {}) => {
  const queryParams = querystring.stringify(params);
  return {
    promise: client.get(`offers?${queryParams}`),
    type: params.search || params.filter ? 'GET_OFFERS_SEARCH' : 'GET_OFFERS',
  };
};

export const getProperties = (params = {}) => {
  const queryParams = params ? '?' + querystring.stringify(params) : '';
  return {
    promise: client.get(`properties${queryParams}`),
    type: params.name ? 'GET_PROPERTIES_SEARCH' : 'GET_PROPERTIES',
  };
};

export const getUnpairedPropertyUnits = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/unpaired-units`),
    type: 'GET_UNPAIRED_PROPERTY_UNITS',
  };
};

export const getPanelUnitCodes = (propertyId, panelId) => {
  return {
    promise: client.get(`properties/${propertyId}/panels/${panelId}/failed-sync-codes`),
    type: 'GET_UNIT_CODES',
  };
};
export const getAdmins = () => {
  return {
    promise: client.get(`users/admin`),
    type: 'GET_ADMINS',
  };
};

export const addAdminUser = data => {
  const payload = {
    clientId: config.auth.clientId,
    redirectUri: config.auth.redirectUri,
    scope: config.auth.scope,
    responseType: config.auth.responseType,
    ...data,
  };

  return {
    promise: client.post(`users/admin`, payload),
    type: 'CREATE_ADMIN_USER',
  };
};

export const removeAdmin = adminId => {
  return {
    promise: client.delete(`users/${adminId}/admin`),
    type: 'REMOVE_ADMIN',
  };
};

export const editAdminUser = (adminId, payload) => {
  return {
    promise: client.patch(`users/${adminId}/admin`, payload),
    type: 'EDIT_ADMIN_USER',
  };
};

export const getActivityLog = (params = {}) => {
  const queryParams = querystring.stringify(params);
  return {
    promise: client.get(`activity-log?${queryParams}`),
    type: 'GET_ACTIVITY_LOG',
  };
};

export const getCredentialTypes = () => {
  return {
    promise: client.get(`credentials/types`),
    type: 'GET_CREDENTIAL_TYPES',
  };
};

export const getCredentialTypesForProperty = propertyId => {
  return {
    promise: client.get(`credentials/types/${propertyId}`),
    type: 'GET_CREDENTIAL_TYPES',
  };
};

export const editCredentialType = (credentialTypeId, payload) => {
  return {
    promise: client.patch(`credentials/types/${credentialTypeId}`, payload),
    type: 'EDIT_CREDENTIAL_TYPE',
  };
};

export const createCredentialType = payload => {
  return {
    promise: client.post(`credentials/types`, payload),
    type: 'ADD_CREDENTIAL_TYPE',
  };
};

export const getTelephoneEntryDevices = params => {
  const queryParams = params ? '?' + querystring.stringify(params) : '';
  return {
    promise: client.get(`telephone-entry/devices${queryParams}`),
    type: 'GET_TELEPHONE_ENTRY_DEVICES',
  };
};

export const createTelephoneEntryDevice = payload => {
  return {
    promise: client.post(`telephone-entry/devices`, payload),
    type: 'ADD_TELEPHONE_ENTRY_DEVICE',
  };
};

export const editTelephoneEntryDevice = (telephoneEntryDeviceId, payload) => {
  return {
    promise: client.patch(`telephone-entry/devices/${telephoneEntryDeviceId}`, payload),
    type: 'EDIT_TELEPHONE_ENTRY_DEVICE',
  };
};

export const createTelephoneEntryDeviceToken = (telephoneEntryDeviceId, expirationDate) => {
  return {
    promise: client.post(`telephone-entry/devices/${telephoneEntryDeviceId}/auth-tokens`, {
      expires_at: expirationDate,
    }),
    type: 'ADD_TELEPHONE_ENTRY_DEVICE_AUTH_TOKEN',
  };
};

export const revokeTelephoneEntryDeviceToken = (telephoneEntryDeviceId, id) => {
  return {
    promise: client.delete(`telephone-entry/devices/${telephoneEntryDeviceId}/auth-tokens/${id}`),
    type: 'REMOVE_TELEPHONE_ENTRY_DEVICE_AUTH_TOKEN',
    id,
  };
};

export const getTelephoneEntryDeviceTokens = telephoneEntryDeviceId => {
  return {
    promise: client.get(`telephone-entry/devices/${telephoneEntryDeviceId}/auth-tokens`),
    type: 'GET_TELEPHONE_ENTRY_DEVICE_AUTH_TOKENS',
  };
};

export const getUnpairedPropertyPanels = propertyId => {
  return {
    promise: client.get(`properties/${propertyId}/panels/unpaired`),
    type: 'GET_UNPAIRED_PROPERTY_PANELS',
  };
};

export const getAdminFinancials = () => {
  return {
    promise: client.get(`financials`),
    type: 'GET_ADMIN_FINANCIALS',
  };
};

export const createAdminFinancial = (type, fee) => {
  return {
    promise: client.post(`financials`, { type, fee }),
    type: 'CREATE_ADMIN_FINANCIAL',
  };
};

export const updateAdminFinancial = (id, fee) => {
  return {
    promise: client.patch(`financials/${id}`, { fee }),
    type: 'UPDATE_ADMIN_FINANCIAL',
  };
};

export const createResidentGuest = (communityId, payload) => {
  return {
    promise: client.post(`properties/${communityId}/create_resident_guest`, payload),
    type: 'CREATE_RESIDENT_GUEST',
  };
};

export const updateVideoRooms = () => {
  return {
    promise: client.post(`telephone-entry/update-rooms`),
    type: 'UPDATE_VIDEO_ROOMS',
  };
};
