import { connect } from 'react-redux';
import { deleteUnit, editUnit } from '../../redux/actions';
import EditUnit from './EditUnit';
import { selectGroupOptions, selectChosenGroupOptions } from '../../../../../../redux/selectors';
import { selectorIsAdmin } from '../../../../../../../../redux/selectors';

function mapStateToProps(state) {
  return {
    groupOptions: selectGroupOptions(state),
    isAdmin: selectorIsAdmin(state),
    property: state.property.activeProperty,
    selectedGroupOptions: selectChosenGroupOptions(state),
    unit: state.property.unit.activeUnit,
    tour: state.property.tour,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    editUnit: (propertyId, unitId, values) => dispatch(editUnit(propertyId, unitId, values)),
    deleteUnit: () => dispatch(deleteUnit(ownProps.match.params.propertyId, ownProps.match.params.unitId)),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    editUnit: values => dispatchProps.editUnit(ownProps.match.params.propertyId, ownProps.match.params.unitId, values),
  });
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditUnit);
