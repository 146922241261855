import React from 'react';

function Download() {
  /* eslint-disable max-len */
  return (
    <svg height="24" width="24" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
    </svg>
  );
  /* eslint-enable */
}

export default Download;
