import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import UnrecognizedAreas from './UnrecognizedAreas';
import { getPropertyPanelAreas, addArea, removePanelArea } from '../../../../../redux/actions';

function mapStateToProps(state) {
  return {
    property: state.property.activeProperty,
    accessControlAreas: state.property.accessControlAreas,
    accessControlAreasLoaded: state.property.accessControlAreasLoaded,
    accessControlPanelAreas: state.property.accessControlPanelAreas.filter(
      p => !state.property.accessControlAreas.find(a => parseInt(p.get('number'), 10) === a.get('dmp_area_number'))
    ),
    accessControlPanelAreasLoaded: state.property.accessControlPanelAreasLoaded,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    getPropertyPanelAreas: () => dispatch(getPropertyPanelAreas(ownProps.match.params.propertyId)),
    addArea: payload => dispatch(addArea(ownProps.match.params.propertyId, payload)),
    removePanelArea: areaNumber => dispatch(removePanelArea(ownProps.match.params.propertyId, areaNumber)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnrecognizedAreas));
