import React from 'react';

const VendorEventRegistrationsTab = ({ eventRegistrations }) => {
  const totalEventRegistrations =
    eventRegistrations.reduce((acc, cur) => {
      if (cur.status !== 'ACTIVE') {
        return acc;
      }

      return acc + cur.tickets;
    }, 0) ?? 'N/A';

  return (
    <div>
      <h4 className="h4">
        {totalEventRegistrations} Registration
        {totalEventRegistrations === 0 || totalEventRegistrations > 1 ? 's' : ''}
      </h4>

      <table className="table-units-list">
        <thead>
          <tr>
            <th className="first-column">Resident Name</th>
            <th>Attendees</th>
          </tr>
        </thead>
        <tbody>
          {eventRegistrations?.map((registration, index) => {
            if (registration.status !== 'ACTIVE') {
              return null;
            }

            return (
              <tr key={index}>
                <td>{registration.name}</td>
                <td>{registration.tickets}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default VendorEventRegistrationsTab;
