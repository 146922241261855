import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getScheduleTourUnitGuestRevoke } from '../../../../../../redux/actions/scheduledAction';

export const useFetchListResidentAccess = ({ isFetching, guestId, reason, type, onFetchSuccess, onFetchStart }) => {
  const dispatch = useDispatch();
  const { propertyId } = useParams();

  const handleDataFetch = async () => {
    if (onFetchStart) onFetchStart();

    const result = await dispatch(
      getScheduleTourUnitGuestRevoke(propertyId, guestId, {
        type,
        reason,
      })
    );

    if (onFetchSuccess) onFetchSuccess();

    return result;
  };

  useEffect(() => {
    if (isFetching) handleDataFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);
};
