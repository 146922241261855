import React from 'react';

function PersonCardIcon() {
  /* eslint-disable max-len */
  return (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#DDE3EB" cx="20" cy="20" r="20" />
        <path stroke="none" d="M8 8h24v24H8z" strokeOpacity=".008" strokeWidth="0" />
        <path
          d="M20 20c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          fill="#7A8A99"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default PersonCardIcon;
